// Dependencies
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';

// Sources
import DOMObject from '../../components/DOMObject.js';
import SessionController from '../../controllers/SessionController';
import { EXTERNAL_PAGES } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

class LogoutPlugin extends DOMObject {
  render() {
    return (
      <div className='h-100' style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          onClick={this.logoutButtonClicked.bind(this)}
          className='border-0 text-white bg-transparent taus-login-btn'
        >
          <FontAwesomeIcon className='ml-1' icon={faSignOutAlt} />
        </Button>
      </div>
    );
  }

  logoutButtonClicked() {
    this.userLogout()
      .then(() => {
        window.location.href = EXTERNAL_PAGES.HLP_CONTENT_HOME_PAGE.path();
      })
      .catch((e) => {
        this.errorHandler(e, true);
      });
  }

  userLogout() {
    return SessionController.logout();
  }
}

export default withRouter(LogoutPlugin);
