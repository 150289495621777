import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import ProjectCampaignController from '../../../controllers/ProjectCampaignsController';

const UserOverview = ({}) => {
  const { id } = useParams();
  const [codes, setCodes] = useState([]);
  const [isLoading, setLoading] = useState();
  const [campaigns, setCampaigns] = useState([]);

  const getProjectCampaigns = async () => {
    await ProjectCampaignController.list(id, { size: 1000 }).then((r) => console.log(r));
  };

  const getReferralCodes = async () => {
    // await ProjectCampaignController.list(id, { size: 1000 }).then((r) => console.log(r));
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([getProjectCampaigns(), getReferralCodes()]).then(() => {
      setLoading(false);
    });
  }, []);

  return <div>usrs</div>;
};

export default UserOverview;
