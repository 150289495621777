import { findOption } from '../../../utils/Select';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputWithValidation from '../../../components/forms/InputWithValidation';
import SelectWithValidation from '../../../components/forms/SelectWithValidation';

const ProjectListingFilters = ({ filters, handleFilterChange, handleClearFilters }) => {
  const statusOptions = [
    { value: 'ACTIVE', label: 'Active' },
    { value: 'INACTIVE', label: 'Inactive' },
  ];
  const statusDefaultOption = findOption(statusOptions, filters['status']);
  const visibilityOptions = [
    { value: 'PUBLIC', label: 'Public' },
    { value: 'PRIVATE', label: 'Private' },
  ];
  const visibilityDefaultOption = findOption(visibilityOptions, filters['visibility']);

  return (
    <div className='tw-p-2'>
      <div className='tw-relative tw-mb-4'>
        <span className='tw-font-medium tw-text-lg'>Filters</span>
        <div
          className='tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-right-0 tw-w-8 tw-h-8 tw-rounded tw-bg-[#fff] tw-border tw-border-solid tw-border-gray-300 tw-cursor-pointer'
          onClick={handleClearFilters}
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </div>
      </div>
      <div className='tw-grid tw-grid-cols-4 tw-gap-x-2'>
        <InputWithValidation
          label='ID'
          propertyName='id'
          placeholder='ID'
          defaultValue={filters.id ?? ''}
          onChange={handleFilterChange}
        />
        <InputWithValidation
          label='Name'
          propertyName='name'
          placeholder='Name'
          defaultValue={filters.name ?? ''}
          onChange={handleFilterChange}
        />
        <InputWithValidation
          label='Source Language'
          propertyName='sourceLanguage.name'
          placeholder='Source Language'
          defaultValue={filters['sourceLanguage.name'] ?? ''}
          onChange={handleFilterChange}
        />
        <InputWithValidation
          label='Target Language'
          propertyName='targetLanguage.name'
          placeholder='Target Language'
          defaultValue={filters['targetLanguage.name'] ?? ''}
          onChange={handleFilterChange}
        />
        <SelectWithValidation
          label='Status'
          placeholder='Status'
          propertyName='status'
          options={statusOptions}
          defaultOption={statusDefaultOption}
          onChange={handleFilterChange}
        />
        <SelectWithValidation
          label='Visibility'
          placeholder='Visibility'
          propertyName='visibility'
          options={visibilityOptions}
          defaultOption={visibilityDefaultOption}
          onChange={handleFilterChange}
        />
      </div>
    </div>
  );
};

export default ProjectListingFilters;
