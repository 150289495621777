import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ProjectSettingsView, { SETTINGS_ITEMS } from '../views/ProjectSettingsView';
import PROJECT from '../../../models/Project';
import ProjectController from '../../../controllers/ProjectController';
import Notifier from '../../../components/Notifier';

export default class ProjectEditSettingsState extends PureComponent {
  constructor(props) {
    super(props);
    this.state.itemsAreEditable =
      this.props?.project?.status === PROJECT.STATUS.INACTIVE
        ? [
            SETTINGS_ITEMS.BASIC,
            SETTINGS_ITEMS.WORKFLOW,
            SETTINGS_ITEMS.REVIEW_STAGE,
            SETTINGS_ITEMS.PAYMENTS,
          ]
        : [SETTINGS_ITEMS.WORKFLOW, SETTINGS_ITEMS.REVIEW_STAGE, SETTINGS_ITEMS.PAYMENTS];
  }

  static defaultProps = {
    project: null,
    metadata: {
      languageOptions: [],
    },
    metadataAreLoading: false,
    projectIsLoading: false,
  };

  static propTypes = {
    project: PropTypes.object.isRequired,
    onProjectUpdate: PropTypes.func.isRequired,
    metadata: PropTypes.shape({
      languageOptions: PropTypes.array.isRequired,
    }).isRequired,
    metadataAreLoading: PropTypes.bool,
    projectIsLoading: PropTypes.bool,
  };

  state = {
    itemsAreEditable: [],
    itemsAreEditing: [],
    itemsAreBusy: [],
  };

  render() {
    return (
      <ProjectSettingsView
        project={this.props?.project}
        itemsAreEditable={this.state?.itemsAreEditable}
        itemsAreEditing={this.state?.itemsAreEditing}
        itemsAreBusy={this.state?.itemsAreBusy}
        onEditButtonClick={this.onEditButtonClick.bind(this)}
        onSaveButtonClick={this.onSaveButtonClick.bind(this)}
        onCancelButtonClick={this.onCancelButtonClick.bind(this)}
        industries={this.props?.metadata?.industries}
        languageOptions={this.props?.metadata?.languageOptions}
        contentTypes={this.props?.metadata?.contentTypes}
        metadataLoading={this.props.metadataAreLoading}
      />
    );
  }

  async onSaveButtonClick(item = null, data = null, keepFormOpen = false) {
    if (!data) this.onCancelButtonClick(item);
    else {
      this.setState({ itemsAreBusy: [...this.state.itemsAreBusy, item] });
      try {
        switch (item) {
          case SETTINGS_ITEMS.BASIC: {
            let project = await ProjectController.update(this.props?.project?.id, data);
            this.props.onProjectUpdate(project);
            this.props.onProjectFilesUpdated();
            break;
          }
          case SETTINGS_ITEMS.WORKFLOW: {
            let settings = { ...this.props?.project?.settings, ...data };
            let project = await ProjectController.update(this.props?.project?.id, {
              settings: settings,
            });
            this.props.onProjectUpdate(project);
            break;
          }
          case SETTINGS_ITEMS.PAYMENTS: {
            let settings = { ...this.props?.project?.settings, ...data };
            let project = await ProjectController.update(this.props?.project?.id, {
              settings: settings,
            });
            this.props.onProjectUpdate(project);
            break;
          }
          default: {
            break;
          }
        }
      } catch (e) {
        Notifier.error(e.message);
      } finally {
        this.setState({
          itemsAreEditing: keepFormOpen
            ? this.state.itemsAreEditing
            : this.state.itemsAreEditing.filter((obj) => obj !== item),
          itemsAreBusy: this.state.itemsAreBusy.filter((obj) => obj !== item),
        });
      }
    }
  }

  async onCancelButtonClick(item = null) {
    this.setState({
      itemsAreEditing: this.state.itemsAreEditing.filter((obj) => obj !== item),
    });
  }

  async onEditButtonClick(item = null) {
    this.setState({
      itemsAreEditing: [...this.state.itemsAreEditing, item],
    });
  }
}
