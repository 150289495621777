import { get } from '../http';

const ACCESS_TOKEN = 'caa68fbe-6af8-4f11-94e6-890a27d4965f';
const TRANSFERWISE_API = 'https://api.sandbox.transferwise.tech';

const headers = {
  Authorization: `Bearer ${ACCESS_TOKEN}`,
  Accept: 'application/json',
};

const getBanks = async (countryCode) => {
  return get(`${TRANSFERWISE_API}/v1/banks?country=${countryCode}`, { headers });
};

const getBankBranches = async (countryCode, bankCode) => {
  return get(`${TRANSFERWISE_API}/v1/bank-branches?country=${countryCode}&bankCode=${bankCode}`, {
    headers,
  });
};

const getRegions = async (currencyCode) => {
  return get(
    `${TRANSFERWISE_API}/v1/account-requirements?source=EUR&target=${currencyCode}&sourceAmount=1000`,
    { headers }
  );
};

const getStates = async (countryCode) => {
  return get(`${TRANSFERWISE_API}/v1/countries/${countryCode}/states`, { headers });
};

const validateIban = async (iban) => {
  return get(`${TRANSFERWISE_API}/v1/validators/iban?iban=${iban}`, { headers });
};

const validateIfsc = async (ifsc) => {
  return get(`${TRANSFERWISE_API}/v1/validators/ifsc-code?ifscCode=${ifsc}`, { headers });
};

const validateIndianAccountNumber = async (accountNumber) => {
  return get(
    `${TRANSFERWISE_API}/v1/validators/indian-account-number?accountNumber=${accountNumber}`,
    { headers }
  );
};

const TransferWiseController = {
  getBanks,
  getBankBranches,
  getRegions,
  getStates,
  validateIban,
  validateIfsc,
  validateIndianAccountNumber,
};

export default TransferWiseController;
