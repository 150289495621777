// CSS
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

// Dependencies
import React from 'react';
import { Plugin, TemplateConnector } from '@devexpress/dx-react-core';
import {
  DataTypeProvider,
  PagingState,
  CustomPaging,
  SortingState,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableSelection,
  TableEditColumn,
  TableEditRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import * as yup from 'yup';

// Api dependencies
import Utils from '../../../utils/Utils';
import DOMObject from '../../../components/DOMObject';
import USERGROUP from '../../../models/UserGroup';
import { PAGES } from '../..';
import { TextFormatter } from '../../../components/tables/Formatters';
import { SelectHeaderCheckbox, SelectRowCheckbox } from '../../../components/tables/Select';
import {
  TableEditColumnOrderLast,
  TableEditAsyncColumnCommandComponent,
  EditingAsyncState,
} from '../../../components/tables/Editor';
import { TableHeaderRowSortLabelComponent } from '../../../components/tables/Sort';
import {
  TableColumnResponsiveState,
  TableColumnResponsive,
} from '../../../components/tables/Responsive';
import { Text } from '../../../components/parameters/TextParameter';
import { Link } from 'react-router-dom';

// ------------------------------------------------- Formatters
const NameEditorFormatter = (data) => {
  return (
    <Plugin>
      <TemplateConnector>
        {({ InValidEditingRows }) => {
          let isValid = Utils.isEmpty(InValidEditingRows?.[data?.row?.id]?.name);
          return (
            <Text
              key={data?.row?.id}
              isValid={isValid}
              placeholder='name (Required)'
              defaultValue={Utils.define(data?.value, '')}
              onChange={(value) => {
                data.onValueChange(value);
              }}
            />
          );
        }}
      </TemplateConnector>
    </Plugin>
  );
};

const LinkFormatter = ({ value, row }) => {
  return <Link to={`${PAGES.USER_GROUP_PAGE.path(row.id)}`}>{value}</Link>;
};

// ------------------------------------------------------ Edit Columns
const TableEditAsyncColumnCellComponent = (props) => {
  let ColumnCellComponent = (props) => {
    let permissions = USERGROUP.getUserPermission(props.row);
    let children = Array.from(props?.children)?.map((child) => {
      if (child?.props?.id === 'delete' && !permissions.includes(USERGROUP.PERMISSIONS.DELETE))
        return React.cloneElement(
          child,
          { ...child?.props, disabled: true, key: 'delete' },
          child?.props?.children
        );
      if (child?.props?.id === 'edit' && !permissions.includes(USERGROUP.PERMISSIONS.EDIT))
        return React.cloneElement(
          child,
          { ...child?.props, disabled: true, key: 'edit' },
          child?.props?.children
        );
      if (child?.props?.id === 'commit') {
        let errors = [];
        Object.values(Utils.define(props?.errors, {})).forEach((obj) => errors.push(...obj));
        return React.cloneElement(
          child,
          { ...child?.props, errors: errors, key: 'commit' },
          child?.props?.children
        );
      }
      return child;
    });
    return <TableEditColumn.Cell {...props} children={children} />;
  };

  return (
    <Plugin>
      <TemplateConnector>
        {({ InValidEditingRows }) => (
          <ColumnCellComponent
            {...props}
            errors={Utils.define(InValidEditingRows[props?.row?.id])}
          />
        )}
      </TemplateConnector>
    </Plugin>
  );
};

// ------------------------------------------------------------------------------

class UserGroupsTable extends DOMObject {
  static defaultProps = {
    data: [],
    defaultPageSize: 25,
    defaultStartPage: 0,
    onSelectionChange: null,
    onAddedRowsChange: null,
    addedRows: null,
  };

  tableColumns = [
    {
      name: 'id',
      title: 'Id',
      sort: true,
      editable: false,
      minWidth: 50,
      responsivePriority: 'always',
      getCellValue: (row) => {
        if (row.id === -1) {
          return '';
        }
        return row?.id;
      },
    },
    {
      name: 'name',
      title: 'Name',
      sort: true,
      editable: true,
      minWidth: 200,
      responsivePriority: 'always',
    },
    {
      name: 'ownerUsername',
      title: 'Owner',
      sort: true,
      editable: false,
      minWidth: 100,
      responsivePriority: 2,
      getCellValue: (row) => row?.owner?.username,
    },
    {
      name: 'memberNum',
      title: 'Members',
      sort: true,
      align: 'center',
      editable: false,
      minWidth: 100,
      responsivePriority: 3,
      getCellValue: (row) => row?.memberNum,
    },
  ];

  componentDidMount() {
    super.componentDidMount();
    try {
      this.DOMObject.querySelector('.table-responsive').style.overflow = 'unset';
    } catch (e) {
      this.errorHandler(e);
    }
  }

  render() {
    let props = this.getTableProps();
    return (
      <Grid
        rows={Utils.defineArray(this.props?.data, [])}
        columns={this.tableColumns}
        getRowId={(row) => row?.id}
      >
        <SortingState {...props?.SortingState} />
        <PagingState {...props.PagingState} />
        <SelectionState {...props?.SelectionState} />
        <EditingAsyncState {...props?.EditingAsyncState} />
        <TableColumnResponsiveState {...props.TableResponsiveState} />

        <DataTypeProvider
          for={['name']}
          formatterComponent={LinkFormatter}
          editorComponent={NameEditorFormatter}
        />
        <DataTypeProvider for={['owner', 'members']} formatterComponent={TextFormatter} />

        <CustomPaging totalCount={this.props?.totalSize} />
        <IntegratedSelection />

        <Table {...props?.Table} />
        <TableColumnResizing {...props?.TableColumnResizing} />
        <TableHeaderRow
          showSortingControls={true}
          sortLabelComponent={TableHeaderRowSortLabelComponent}
        />
        <TableSelection
          showSelectAll={true}
          cellComponent={SelectRowCheckbox}
          headerCellComponent={SelectHeaderCheckbox}
        />
        <TableEditColumn {...props.TableEditColumn} />
        <TableEditColumnOrderLast />

        <TableColumnResponsive />
        <TableEditRow />
        <PagingPanel pageSizes={[10, 25, 50, 100]} />
      </Grid>
    );
  }

  getTableProps() {
    return {
      SelectionState: {
        selection: Utils.defineArray(this.props?.selection, []),
        onSelectionChange: this.props.onSelectionChange,
      },
      SortingState: {
        columnExtensions: this.tableColumns?.map((obj) => ({
          columnName: obj.name,
          sortingEnabled: Utils.defineBoolean(obj.sort, false),
        })),
        sorting: this.props?.sorting,
        onSortingChange: this.props?.onSortingChange,
      },
      TableResponsiveState: {
        tableDOM: this.DOMObject,
        defaultMinWidth: 150,
        columnsPriority: this.tableColumns
          ?.filter((obj) => !Utils.isNull(obj.responsivePriority))
          .map((obj) => ({ columnName: obj.name, priority: obj.responsivePriority })),
      },
      PagingState: {
        currentPage: this.props?.page,
        pageSize: this.props?.pageSize,
        onCurrentPageChange: this.props?.onPageChange,
        onPageSizeChange: this.props?.onPageSizeChange,
      },
      EditingAsyncState: {
        columnExtensions: this.tableColumns?.map((obj) => ({
          columnName: obj.name,
          editingEnabled: Utils.define(obj.editable, false),
          createRowChange: obj.createRowChange,
        })),
        addedRows: Utils.defineArray(this.props?.addedRows),
        onAddedRowsInsert: this.props?.onAddedRowsInsert,
        onAddedRowsCancel: this.props?.onAddedRowsCancel,
        onAddedRowsUpdate: this.props?.onAddedRowsUpdate,
        onRowUpdateCommitAsync: this.props?.onRowUpdateCommitAsync,
        onRowDeleteCommitAsync: this.props?.onRowDeleteCommitAsync,
        onRowInsertCommitAsync: this.props?.onRowInsertCommitAsync,
        validationSchema: yup.object().shape({
          name: yup.string('username cannot be empty').required(),
        }),
      },

      Table: {
        columnExtensions: this.tableColumns
          ?.map((obj) => ({ columnName: obj.name, align: Utils.define(obj.align, 'left') }))
          .map((obj) =>
            Utils.isNull(obj.minWidth)
              ? { ...obj, width: 'auto' }
              : { ...obj, width: obj.minWidth + 'vmin' }
          ),
        messages: { noData: 'No user groups' },
      },
      TableColumnResizing: {
        defaultColumnWidths: this.tableColumns?.map((obj) => ({
          columnName: obj.name,
          width: Utils.define(obj.width, 'auto'),
        })),
        resizingMode: 'nextColumn',
        minColumnWidth: 150,
      },
      TableEditColumn: {
        width: 75,
        showEditCommand: true,
        showDeleteCommand: true,
        showAddCommand: false,
        commandComponent: (props) => <TableEditAsyncColumnCommandComponent {...props} />,
        cellComponent: (props) => <TableEditAsyncColumnCellComponent {...props} />,
      },
    };
  }
}

export default UserGroupsTable;
