import React from 'react';
import ListGroupItemWithBadge from '../components/ListGroupItemWithBadge';

import PROJECT from '../../../models/Project';
import TASK from '../../../models/TaskResult';
import { ListGroup } from 'react-bootstrap';

export const ProjectPaymentSettingsView = ({
  paymentsPolicy = null,
  paymentsMinPayableTaskEvaluationStatus = null,
}) => {
  const paymentsPolicyName = PROJECT.SETTINGS.PAYMENTS.POLICY?.[paymentsPolicy]?.name ?? 'N/A';
  const paymentsMinPayableTAskEvaluationStatusName =
    TASK.EVALUATION_STATUS?.[paymentsMinPayableTaskEvaluationStatus] ?? 'N/A';
  const isPaymentsMinPayableTaskEvaluationStatusEnabled =
    PROJECT.SETTINGS.PAYMENTS.POLICY.AUTO.sid === paymentsPolicy;
  return (
    <div className='row'>
      <ListGroup className='col-12 card-body list-group'>
        <ListGroupItemWithBadge
          key='policy'
          message={'Payments Policy'}
          value={paymentsPolicyName}
        />
        {!isPaymentsMinPayableTaskEvaluationStatusEnabled ? null : (
          <ListGroupItemWithBadge
            key='minPayableTaskEvaluationStatus'
            message={'Minimum Payable Task Evaluation Status'}
            value={paymentsMinPayableTAskEvaluationStatusName ?? 'N/A'}
          />
        )}
      </ListGroup>
    </div>
  );
};

export default ProjectPaymentSettingsView;
