import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Notifier from '../../../components/Notifier';
import ProjectController from '../../../controllers/ProjectController';
import FileController from '../../../controllers/FileController';
import ProjectFilesView from '../views/ProjectFilesView';

export default class ProjectFilesState extends PureComponent {
  static propTypes = {
    isBusy: PropTypes.bool.isRequired,
    project: PropTypes.object.isRequired,
    isEditable: PropTypes.bool,
    singleFile: PropTypes.bool,
    files: PropTypes.array,
    projectFiles: PropTypes.array,
    onProjectFilesUpdated: PropTypes.func.isRequired,
  };

  state = {
    isBusy: false,
  };

  async onProjectFileAdd(fileId = null) {
    if (this.props.singleFile && this.props.projectFiles.length > 0) {
      return;
    }
    try {
      this.setState({ isBusy: true });
      await this.addProjectFile(fileId).then(() => {
        const file = this.props.files.find((obj) => obj.id === fileId);
        const projectFiles = [...this.props.projectFiles, file];
        this.setState({ isBusy: false });
        this.props.onProjectFilesUpdated(projectFiles);
      });
    } catch (e) {
      this.errorHandler(e);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  async onProjectFileRemove(fileId = null) {
    try {
      this.setState({ isBusy: true });
      await this.removeProjectFile(fileId).then(() => {
        const projectFiles = this.props.projectFiles.filter((obj) => obj.id !== fileId);
        this.setState({ isBusy: false });
        this.props.onProjectFilesUpdated(projectFiles);
      });
    } catch (e) {
      this.errorHandler(e);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  async removeProjectFile(fileId = null) {
    return ProjectController.removeFile(this.props.project?.id, fileId);
  }

  async addProjectFile(fileId = null) {
    return ProjectController.addFile(this.props.project?.id, fileId);
  }

  async getFiles() {
    return FileController.getAll({ pageSize: 1000 }, { 'serialize-permission': false }).then(
      (res) => res?.page ?? []
    );
  }

  async getProjectFiles() {
    return ProjectController.getFiles(this.props?.project?.id, { 'serialize-permission': false });
  }

  render() {
    return (
      <ProjectFilesView
        project={this.props?.project}
        files={this.props.files}
        projectFiles={this.props.projectFiles}
        isBusy={this.state.isBusy || this.props.isBusy}
        isEditable={this.props?.isEditable ?? false}
        onAvailableFileClick={this.onProjectFileAdd.bind(this)}
        onProjectFileClick={this.onProjectFileRemove.bind(this)}
      />
    );
  }

  errorHandler(e) {
    console.log(e);
    Notifier.error(e['userMessage']);
  }
}
