import React, { memo } from 'react';

import TextToSpeechTargetSegment from './TextToSpeechTargetSegment';
import SourceSegment from '../SourceSegment';
import { createDescription } from '../../../../utils/SpeechRecording';

const TextToSpeechSegment = memo(
  ({
    segment,
    audio,
    sampleRate,
    validAudio,
    onAudioChanged,
    transcription,
    validTranscription,
    onTranscriptionChanged,
    segmentMetadata,
    task,
    errorMessages,
    isLoading,
    isLoadingLanguageQEMetadata,
    loadingLanguageQEMetadataFailed,
    onRetryLanguageQE,
    taskIsUpdated,
    handleSave,
    isChanged,
    shouldUpdateOriginalTranscription,
  }) => {
    const description = createDescription(
      segmentMetadata?.description
    );

    return (
      <div className='d-flex flex-wrap justify-content-between align-items-start mb-5 pb-5 border-bottom'>
        <SourceSegment
          className='taus-source-segment-single pb-3 pr-sm-3 col-12-xs col-sm-6'
          segment={segment}
          task={task}
          description={description}
        />
        <TextToSpeechTargetSegment
          segmentId={segment.id}
          audio={audio}
          sampleRate={sampleRate}
          validAudio={validAudio}
          onAudioChanged={onAudioChanged}
          transcription={transcription}
          validTranscription={validTranscription}
          onTranscriptionChanged={onTranscriptionChanged}
          segmentMetadata={segmentMetadata}
          task={task}
          errorMessages={errorMessages}
          isLoading={isLoading}
          isLoadingLanguageQEMetadata={isLoadingLanguageQEMetadata}
          loadingLanguageQEMetadataFailed={loadingLanguageQEMetadataFailed}
          onRetryLanguageQE={onRetryLanguageQE}
          taskIsUpdated={taskIsUpdated}
          handleSave={handleSave}
          isChanged={isChanged}
          shouldUpdateOriginalTranscription={shouldUpdateOriginalTranscription}
        />
      </div>
    );
  }
);

export default TextToSpeechSegment;
