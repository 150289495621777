import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Tooltip from '../../../components/Tooltip';
import { useMessageBox } from '../../../contexts/MessageBoxContext';
import ProjectMembersTabState from '../states/ProjectMembersTabState';
import ProjectApplicantsTabState from '../states/ProjectApplicantsTabState';

export const ProjectMembersView = ({
  project = [],
  isEditable = false,
  canSendMessage = false,
}) => {
  const defaultActiveTab = 1;
  let [activeTab, setActiveTab] = useState(defaultActiveTab ?? 1);
  return (
    <Tabs
      id='members-tabs'
      activeKey={activeTab}
      onSelect={(index) => setActiveTab(index)}
      mountOnEnter
    >
      <Tab title='Members' eventKey={1}>
        <ProjectMembersTabState
          project={project}
          isEditable={isEditable}
          canSendMessage={canSendMessage}
        />
      </Tab>
      <Tab title='Applicants' eventKey={2}>
        <ProjectApplicantsTabState project={project} canSendMessage={canSendMessage} />
      </Tab>
    </Tabs>
  );
};

export default ProjectMembersView;
