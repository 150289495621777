import * as Yup from 'yup';
/* Custom Validator functions */
const ibanCountryCodeValidator = (countryCode, schema) => {
  return schema.test({
    test: (iban) => {
      if (iban) {
        return iban.startsWith(countryCode);
      }
      return false;
    },
    message: "The first two digits should be your country's code",
  });
};

const ibanLengthValidator = (ibanLength, schema) => {
  return schema.test({
    test: (iban) => {
      if (iban) {
        let replaced = iban.replace(/ /g, '');
        return replaced.length === ibanLength;
      }
      return false;
    },
    message: `The IBAN of your country should be ${ibanLength} digits`,
  });
};

class PaymentFormValidation {
  static SCHEMA = Yup.object().shape({
    method: Yup.string().required('Please select a payment method').nullable(),
    // paypalEmail: Yup.string().when('method', {
    //     is: 'PayPal',
    //     otherwise: Yup.string().notRequired(),
    //     then: Yup.string()
    //         .email('Not valid email format')
    //         .required('Please enter your PayPal email')
    //         .nullable()
    // }),
    isConnected: Yup.bool().when('method', {
      is: 'Stripe',
      otherwise: Yup.bool().notRequired(),
      then: Yup.bool()
        .oneOf([true], 'Please connect with stripe')
        .required('Please connect with stripe'),
    }),
    iban: Yup.string()
      .nullable()
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) => method === 'TransferWise' && transferwiseType === 'iban',
        otherwise: Yup.string().notRequired(),
        then: Yup.string()
          .required('IBAN is required')
          .when('countryCode', ibanCountryCodeValidator)
          .when('ibanLength', ibanLengthValidator),
      })
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' && transferwiseType === 'turkish_earthport',
        otherwise: Yup.string().notRequired(),
        then: Yup.string()
          .required('IBAN is required for Turkish transactions')
          .when('countryCode', ibanCountryCodeValidator)
          .when('ibanLength', ibanLengthValidator),
      }),
    swift: Yup.string()
      .nullable()
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' && transferwiseType === 'southafrica',
        otherwise: Yup.string().notRequired(),
        then: Yup.string()
          .required('Please, add your SWIFT/BIC code')
          .min(8, 'SWIFT/BIC code must be at least 8 and no more than 11 characters in length')
          .max(11, 'SWIFT/BIC code must be at least 8 and no more than 11 characters in length'),
      })
      .matches(/^([A-Z]{4})([A-Z]{2})([A-Z0-9]{2})([A-Z0-9]{3})?$/, 'Invalid SWIFT/BIC format'),
    ifscCode: Yup.string()
      .nullable()
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' && transferwiseType === 'indian',
        otherwise: Yup.string().notRequired(),
        then: Yup.string().required('IFSC code is required for Indian transactions'),
      })
      .matches(
        /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
        'Should be an 11-character code with the first four alphabetic characters representing the bank name, and the last six characters representing the branch. The fifth character is always 0 (zero).'
      ),
    accountType: Yup.string()
      .nullable()
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) => method === 'TransferWise' && transferwiseType === 'aba',
        otherwise: Yup.string().notRequired(),
        then: Yup.string().required('Account type is required'),
      }),
    abartn: Yup.string()
      .nullable()
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) => method === 'TransferWise' && transferwiseType === 'aba',
        otherwise: Yup.string().notRequired(),
        then: Yup.string().required('ACH routing number (abartn) is required'),
      })
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) => method === 'TransferWise' && transferwiseType === 'aba',
        otherwise: Yup.string().notRequired(),
        then: Yup.string().test('len', 'ACH routing number must be exactly 9 characters', (val) =>
          val ? val.length === 9 : false
        ),
      })
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) => method === 'TransferWise' && transferwiseType === 'aba',
        otherwise: Yup.string().notRequired(),
        then: Yup.string().test(
          'abartn-validator',
          'Invalid Routing Number, please check again',
          (value) => {
            if (value?.length === 9) {
              return fetch(`https://api.transferwise.com/v1/validators/abartn?abartn=${value}`, {
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                },
              })
                .then((res) => {
                  return res.json();
                })
                .then((data) => {
                  if (data?.errors) {
                    return false;
                  } else {
                    return true;
                  }
                });
            }
          }
        ),
      }),
    bsbCode: Yup.string()
      .nullable()
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' && transferwiseType === 'australian',
        otherwise: Yup.string().notRequired(),
        then: Yup.string().required('Bank State Branch (BSB code) is required'),
      })
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' && transferwiseType === 'australian',
        otherwise: Yup.string().notRequired(),
        then: Yup.string().test(
          'len',
          'Bank State Branch (BSB code) must be exactly 6 characters',
          (val) => (val ? val.length === 6 : false)
        ),
      })
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' && transferwiseType === 'australian',
        otherwise: Yup.string().notRequired(),
        then: Yup.string().test(
          'bsbCode-validator',
          'Invalid BSB Code, please check again',
          (value) => {
            if (value?.length === 6) {
              return fetch(`https://api.transferwise.com/v1/validators/bsb-code?bsbCode=${value}`, {
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                },
              })
                .then((res) => {
                  return res.json();
                })
                .then((data) => {
                  if (data?.errors) {
                    return false;
                  } else {
                    return true;
                  }
                });
            }
          }
        ),
      }),
    accountNumber: Yup.string()
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' &&
          (transferwiseType === 'srilanka' ||
            transferwiseType === 'nepal' ||
            transferwiseType === 'indian' ||
            transferwiseType === 'southafrica' ||
            transferwiseType === 'russiarapida' ||
            transferwiseType === 'chinese_alipay' ||
            transferwiseType === 'aba' ||
            transferwiseType === 'australian'),
        otherwise: Yup.string().notRequired(),
        then: Yup.string().required('Account number is required'),
      })
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) => method === 'TransferWise' && transferwiseType === 'nepal',
        otherwise: Yup.string().notRequired(),
        then: Yup.string()
          .min(9, 'Account number must be at least 9 and no more than 20 characters in length')
          .max(20, 'Account number must be at least 9 and no more than 20 characters in length'),
      })
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' && transferwiseType === 'srilanka',
        otherwise: Yup.string().notRequired(),
        then: Yup.string()
          .min(6, 'Account number must be at least 6 and no more than 15 characters in length')
          .max(15, 'Account number must be at least 6 and no more than 15 characters in length'),
      })
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' && transferwiseType === 'indian',
        otherwise: Yup.string().notRequired(),
        then: Yup.string()
          .min(5, 'Account number must be at least 5 and no more than 20 characters in length')
          .max(20, 'Account number must be at least 5 and no more than 20 characters in length'),
      })
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' && transferwiseType === 'southafrica',
        otherwise: Yup.string().notRequired(),
        then: Yup.string()
          .min(7, 'Account number must be at least 7 and no more than 11 characters in length')
          .max(11, 'Account number must be at least 7 and no more than 11 characters in length'),
      })
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' && transferwiseType === 'russiarapida',
        otherwise: Yup.string().notRequired(),
        then: Yup.string().test('len', 'Account number must be exactly 20 characters', (val) =>
          val ? val.length === 20 : false
        ),
      })
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' && transferwiseType === 'chinese_alipay',
        otherwise: Yup.string().notRequired(),
        then: Yup.string().test(
          'check-account',
          'Account number must be your email or your phone number starting with 86-',
          (value) => {
            // Validate if value is phone number
            if (value && value.match(/^86-\d*/)) {
              return true;
              // Validate if value is email
            } else if (
              value &&
              value.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )
            ) {
              return true;
            } else {
              return false;
            }
          }
        ),
      })
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' &&
          (transferwiseType === 'south_korean_paygate' ||
            transferwiseType === 'south_korean_paygate_business'),
        otherwise: Yup.string().notRequired(),
        then: Yup.string()
          .min(10, 'Account number must be between 10 and 16 characters')
          .max(16, 'Account number must be between 10 and 16 characters'),
      }),
    bank: Yup.string()
      .nullable()
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' &&
          (transferwiseType === 'srilanka' ||
            transferwiseType === 'russiarapida' ||
            transferwiseType === 'nepal'),
        otherwise: Yup.string().notRequired(),
        then: Yup.string().required('Please select a bank'),
      }),
    branch: Yup.string()
      .nullable()
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' && transferwiseType === 'srilanka',
        otherwise: Yup.string().notRequired(),
        then: Yup.string().required("Please select your bank's branch"),
      }),
    region: Yup.string()
      .nullable()
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' && transferwiseType === 'russiarapida',
        otherwise: Yup.string().notRequired(),
        then: Yup.string().required('Please select your region'),
      }),
    state: Yup.string()
      .nullable()
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) => method === 'TransferWise' && transferwiseType === 'aba',
        otherwise: Yup.string().notRequired(),
        then: Yup.string().required('Please select your state'),
      }),
    legalType: Yup.string()
      .required('You must select your legal type')
      .nullable()
      .oneOf(['Private', 'Business', null]),
    addressLine: Yup.string().required('Please enter your address').nullable(),
    city: Yup.string().required('Please enter your city').nullable(),
    zipCode: Yup.string().required('Please enter your zip code').nullable(),
    country: Yup.string().required('Please select a country').nullable(),
    // name: Yup.string().required('Please enter your full name or organization').nullable(),
    name: Yup.string()
      .nullable()
      .required('Please enter your full name or organization')
      .when(['method', 'legalType'], {
        is: (method, legalType) => legalType === 'Private' && method === 'TransferWise',
        then: Yup.string()
          .matches(/^([^0-9]*)$/, 'Name should not contain numbers')
          .matches(
            /^[\s]*([^\s]{2,})[\s]+([^\s]{2,})/,
            'Your name must be at least 2 words (Name and surname), both at least 2 characters length'
          ),
      })
      .when(['method', 'legalType'], {
        is: (method, legalType) => legalType === 'Business' && method === 'TransferWise',
        then: Yup.string()
          .matches(/[^\s]{2,}/, 'Your business name must be at least 2 characters')
          .matches(/[a-zA-Z]+/, 'Your business name must contain at least one latin character'),
      })
      .when(['method', 'legalType', 'transferwiseType'], {
        is: (method, legalType, transferwiseType) =>
          method === 'TransferWise' &&
          legalType === 'Private' &&
          transferwiseType === 'russiarapida',
        then: Yup.string().test(
          'matc',
          'Your name must be written in Cyrillic exactly as it is registered in your Bank, including First name, Last name and Middle name (if your Middle name is also registered in the bank)',
          (val) => (val && !val.match(/[wu0430-u044f]+/gi) ? true : false)
        ),
      }),
    transactionMethod: Yup.string().nullable(),
    vatNumber: Yup.string()
      .nullable()
      .when(['legalType', 'vatStatus'], {
        is: (legalType, vatStatus) => legalType === 'Business' && vatStatus === 'EU',
        then: Yup.string().required('VAT Number is required for EU businesses'),
      })
      .when(['legalType', 'vatStatus'], {
        is: (legalType, vatStatus) => legalType === 'Business' && vatStatus === 'NL',
        then: Yup.string().required('VAT Number is required for NL businesses'),
      }),
    cocNumber: Yup.string().when('vatStatus', {
      is: 'NL',
      then: Yup.string().when('legalType', {
        is: 'Business',
        then: Yup.string()
          .required('CoC Number is required for NL businesses')
          .matches(/^\d{8}$/, 'Your CoC number should be exactly 8 digits'),
      }),
    }),
    email: Yup.string()
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' && transferwiseType === 'south_korean_paygate',
        otherwise: Yup.string().notRequired(),
        then: Yup.string()
          .email('Not valid email format')
          .required('Please enter your email')
          .nullable(),
      })
      .when('method', {
        is: 'PayPal',
        otherwise: Yup.string().notRequired(),
        then: Yup.string()
          .email('Not valid email format')
          .required('Please enter your PayPal email')
          .nullable(),
      }),
    dateOfBirth: Yup.string()
      .nullable()
      .when(['method', 'transferwiseType'], {
        is: (method, transferwiseType) =>
          method === 'TransferWise' && transferwiseType === 'south_korean_paygate',
        otherwise: Yup.string().notRequired(),
        then: Yup.string().test(
          'matc',
          'Please enter your date of birth in the form `0000-00-00` for Year-Month-Day accordingly',
          (val) => (val && val.match(/^(\d{4})([/-])(\d{1,2})\2(\d{1,2})$/) ? true : false)
        ),
      }),
    agreedToPaymentTerms: Yup.bool()
      .required()
      .oneOf([true], 'You must consent to Payment Terms in order to continue'),
  });
}
export default PaymentFormValidation;
