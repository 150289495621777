// CSS
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

// Dependencies
import React from 'react';
import { Plugin, TemplateConnector } from '@devexpress/dx-react-core';
import {
  DataTypeProvider,
  PagingState,
  CustomPaging,
  SortingState,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import * as yup from 'yup';

// Api dependencies
import Utils from '../../../utils/Utils';
import DOMObject from '../../../components/DOMObject';
import { PAGES } from '../..';
import { TextFormatter } from '../../../components/tables/Formatters';
import {
  TableEditColumnOrderLast,
  TableEditAsyncColumnCommandComponent,
  EditingAsyncState,
} from '../../../components/tables/Editor';
import { TableHeaderRowSortLabelComponent } from '../../../components/tables/Sort';
import { TableHeaderTooltip } from '../../../components/tables/Tooltip';
import {
  TableColumnResponsiveState,
  TableColumnResponsive,
} from '../../../components/tables/Responsive';
import { Text } from '../../../components/parameters/TextParameter';
import { Link } from 'react-router-dom';

// ------------------------------------------------- Formatters
const CodeEditorFormatter = (data) => {
  return (
    <Plugin>
      <TemplateConnector>
        {({ InValidEditingRows }) => {
          let isValid = Utils.isEmpty(InValidEditingRows?.[data?.row?.id]?.code);
          return (
            <Text
              key={data?.row?.id}
              isValid={isValid}
              placeholder='code (Required)'
              defaultValue={Utils.define(data?.value, '')}
              onChange={(value) => {
                data.onValueChange(value);
              }}
            />
          );
        }}
      </TemplateConnector>
    </Plugin>
  );
};

const LinkFormatter = ({ value, row }) => {
  return <Link to={`${PAGES.USER_REFEREES_PAGE.path(row.id)}`}>{value}</Link>;
};

// ------------------------------------------------------------------------------

class UserRefereesRefCampaignTable extends DOMObject {
  static defaultProps = {
    data: [],
    defaultPageSize: 10,
    defaultStartPage: 0,
    onSelectionChange: null,
    onAddedRowsChange: null,
    addedRows: null,
  };

  tableColumns = [
    {
      name: 'email',
      title: 'Email',
      sort: true,
      editable: true,
      minWidth: 200,
      responsivePriority: 'always',
      getCellValue: (row) => row?.email ?? '',
    },
    {
      name: 'fullName',
      title: 'Name',
      sort: true,
      editable: true,
      minWidth: 200,
      responsivePriority: 'always',
      getCellValue: (row) => row?.fullName ?? '',
    },
    {
      name: 'eligibleForPayment',
      title: 'Eligible for payment',
      editable: false,
      minWidth: 50,
      responsivePriority: 1,
      tooltip: 'This shows if the referred user\'s task is eligible for payment of the referral bonus. ' +
        '\'Yes\' means that a task was submitted by the referred user AND approved. ' +
        '\'No\' means that either no task was submitted, or that a submitted task was NOT approved',
      getCellValue: (row) => {
        if (row.paymentStatus) {
          return 'Yes';
        }
        else {
          return 'No';
        }
      },
    },
    {
      name: 'eligibleCredits',
      title: 'Credits for payment',
      editable: false,
      minWidth: 50,
      responsivePriority: 1,
      tooltip: 'This shows how many referral bonus credits are eligible for payment. 1 credit equals 1 Euro',
      getCellValue: (row) => {
        if (row.paymentStatus) {
          return row?.projectCampaignCredits ?? '-';
        }
        else {
          return '-';
        }
      },
    },
    {
      name: 'paymentStatus',
      title: 'Payment status',
      sort: true,
      editable: true,
      minWidth: 200,
      responsivePriority: 'always',
      getCellValue: (row) => {
        if (row.paymentStatus) {
          return row?.paymentStatus ?? '-';
        }
        else {
          return '-';
        }
      },
    },
  ];

  componentDidMount() {
    super.componentDidMount();
    try {
      this.DOMObject.querySelector('.table-responsive').style.overflow = 'unset';
    } catch (e) {
      this.errorHandler(e);
    }
  }

  render() {
    let props = this.getTableProps();
    return (
      <Grid
        rows={Utils.defineArray(this.props?.data, [])}
        columns={this.tableColumns}
        getRowId={(row) => row?.id}
      >
        <SortingState {...props?.SortingState} />
        <PagingState {...props.PagingState} />
        <SelectionState {...props?.SelectionState} />
        <EditingAsyncState {...props?.EditingAsyncState} />
        <TableColumnResponsiveState {...props.TableResponsiveState} />

        <DataTypeProvider
          for={['code']}
          formatterComponent={LinkFormatter}
          editorComponent={CodeEditorFormatter}
        />
        <DataTypeProvider for={['owner', 'members']} formatterComponent={TextFormatter} />

        <CustomPaging totalCount={this.props?.totalSize} />
        <IntegratedSelection />

        <Table {...props?.Table} />
        <TableColumnResizing {...props?.TableColumnResizing} />
        <TableHeaderRow
          showSortingControls={true}
          sortLabelComponent={TableHeaderRowSortLabelComponent}
          contentComponent={TableHeaderTooltip}
        />
        <TableEditColumn {...props.TableEditColumn} />
        <TableEditColumnOrderLast />

        <TableColumnResponsive />
        <PagingPanel pageSizes={[10, 25, 50, 100]} />
      </Grid>
    );
  }

  getTableProps() {
    return {
      SelectionState: {
        selection: Utils.defineArray(this.props?.selection, []),
        onSelectionChange: this.props.onSelectionChange,
      },
      SortingState: {
        columnExtensions: this.tableColumns?.map((obj) => ({
          columnName: obj.name,
          sortingEnabled: Utils.defineBoolean(obj.sort, false),
        })),
        sorting: this.props?.sorting,
        onSortingChange: this.props?.onSortingChange,
      },
      TableResponsiveState: {
        tableDOM: this.DOMObject,
        defaultMinWidth: 150,
        columnsPriority: this.tableColumns
          ?.filter((obj) => !Utils.isNull(obj.responsivePriority))
          .map((obj) => ({ columnName: obj.name, priority: obj.responsivePriority })),
      },
      PagingState: {
        currentPage: this.props?.page,
        pageSize: this.props?.pageSize,
        onCurrentPageChange: this.props?.onPageChange,
        onPageSizeChange: this.props?.onPageSizeChange,
      },
      EditingAsyncState: {
        columnExtensions: this.tableColumns?.map((obj) => ({
          columnName: obj.name,
          editingEnabled: Utils.define(obj.editable, false),
          createRowChange: obj.createRowChange,
        })),
        addedRows: Utils.defineArray(this.props?.addedRows),
        onAddedRowsInsert: this.props?.onAddedRowsInsert,
        onAddedRowsCancel: this.props?.onAddedRowsCancel,
        onAddedRowsUpdate: this.props?.onAddedRowsUpdate,
        onRowUpdateCommitAsync: this.props?.onRowUpdateCommitAsync,
        onRowDeleteCommitAsync: this.props?.onRowDeleteCommitAsync,
        onRowInsertCommitAsync: this.props?.onRowInsertCommitAsync,
        validationSchema: yup.object().shape({
          name: yup.string('username cannot be empty').required(),
        }),
      },

      Table: {
        columnExtensions: this.tableColumns
          ?.map((obj) => ({ columnName: obj.name, align: Utils.define(obj.align, 'left') }))
          .map((obj) =>
            Utils.isNull(obj.minWidth)
              ? { ...obj, width: 'auto' }
              : { ...obj, width: obj.minWidth + 'vmin' }
          ),
        messages: { noData: 'No user referees' },
      },
      TableColumnResizing: {
        defaultColumnWidths: this.tableColumns?.map((obj) => ({
          columnName: obj.name,
          width: Utils.define(obj.width, 'auto'),
        })),
        resizingMode: 'nextColumn',
        minColumnWidth: 150,
      },
      TableEditColumn: {
        width: 75,
        showEditCommand: false,
        showDeleteCommand: false,
        showAddCommand: false,
        commandComponent: (props) => <TableEditAsyncColumnCommandComponent {...props} />,
      },
    };
  }
}

export default UserRefereesRefCampaignTable;
