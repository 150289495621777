import React from "react";
import {FormText} from "reactstrap"
import PropTypes from "prop-types";

export const FormFieldHelpText = (props) => {
    return(<FormText color="muted">{props.value}</FormText>)
};

FormFieldHelpText.propTypes = {
    value: PropTypes.string.isRequired
};