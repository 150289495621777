import React from 'react';
import PropTypes from 'prop-types';
import DOMObject from './DOMObject';
import Utils from '../utils/Utils';

/**
 * Error handler inside JSX code on ReactJS render methods.
 * @property {string} errorMessage : Message to show on the user. Default is null.
 * @property {bool} hasError : True to imediatelly throw error. Default is false.
 */
export default class ErrorBoundary extends DOMObject {
  constructor(props) {
    super(props);
    this.state.hasError = props.hasError === true ? true : false;
  }

  static defaultProps = {
    errorMessage: null,
    hasError: false,
  };

  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    //try{ this.errorHandler(error); }catch(e){ console.error(e); }
  }

  render() {
    try {
      return this.state.hasError === true ? (
        <div style={{ color: 'red' }}>{Utils.define(this.props.errorMessage, '')}</div>
      ) : (
        Utils.define(this.props.children)
      );
    } catch (e) {
      this.errorHandler(e);
      return null;
    }
  }
}

ErrorBoundary.propTypes = {
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
};
