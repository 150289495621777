import alasql from 'alasql';
import { Bar } from 'react-chartjs-2';
import { Tooltip } from '@mui/material';
import Utils from '../../../utils/Utils';
import Project from '../../../models/Project';
import { useEffect, useRef, useState } from 'react';
import TASK_RESULT from '../../../models/TaskResult';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chart as ChartJS, CategoryScale, registerables } from 'chart.js';
ChartJS.register(CategoryScale, ChartDataLabels, ...registerables);

const OverviewBarChart = ({
  tasks,
  projectType,
  handleDownload,
  totalGeneratedReview,
  totalGeneratedPrimary,
}) => {
  const chartRef = useRef(null);
  const [barStats, setBarStats] = useState([]);
  const [barDatasets, setBarDatasets] = useState([]);

  const barOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      title: {
        display: true,
        text: '1a. Total task result statuses overview',
      },
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            if (projectType === Project.SETTINGS.TASKS.RESULTS.POLICY.ONE_TO_ONE.sid) {
              let label = [];
              const stage = String(context.dataset.label).toUpperCase();
              const status = String(context.label).toUpperCase();
              const datasetTasks = context.raw;
              const totalGenerated =
                stage === 'PRIMARY' ? totalGeneratedPrimary : totalGeneratedReview;

              const percentageOfTotal = Number((datasetTasks / totalGenerated) * 100).toFixed(2);

              label.push(`Total generated tasks: ${totalGenerated}`);
              label.push(`Total task results with status ${status}: ${datasetTasks}`);
              label.push(
                `% of ${status} task results on generated tasks: ${percentageOfTotal}%`
              );
              return label;
            }
          },
        },
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
        formatter: (value) => value,
      },
    },
  };

  const barData = {
    labels: [
      'Inactive',
      'Active',
      'Pending',
      'Submitted',
      'Pending Check',
      'Pending Review',
      'Rolled Back',
      'Reviewed',
      'Delivered',
    ],
    datasets: barDatasets,
  };

  const setData = async () => {
    const groupByStatus =
      `select stage, sum(case when taskResult->status=${TASK_RESULT.STATUS.INACTIVE} then 1 else 0 end) as inactive, ` +
      `sum(case when taskResult->status='${TASK_RESULT.STATUS.ACTIVE}' then 1 else 0 end) as active, ` +
      `sum(case when taskResult->status='${TASK_RESULT.STATUS.PENDING}' then 1 else 0 end) as pending, ` +
      `sum(case when taskResult->status='${TASK_RESULT.STATUS.SUBMITTED}' then 1 else 0 end) as submitted, ` +
      `sum(case when taskResult->status='${TASK_RESULT.STATUS.PENDING_CHECK}' then 1 else 0 end) as pendingCheck, ` +
      `sum(case when taskResult->status='${TASK_RESULT.STATUS.PENDING_REVIEW}' then 1 else 0 end) as pendingReview, ` +
      `sum(case when taskResult->status='${TASK_RESULT.STATUS.ROLLED_BACK}' then 1 else 0 end) as rolledBack, ` +
      `sum(case when taskResult->status='${TASK_RESULT.STATUS.REVIEWED}' then 1 else 0 end) as reviewed, ` +
      `sum(case when taskResult->status='${TASK_RESULT.STATUS.DELIVERED}' then 1 else 0 end) as delivered ` +
      'from ? ' +
      'group by stage';
    let stats = await alasql.promise(groupByStatus, [tasks]);
    setBarStats(stats);
  };

  const setBarChartData = () => {
    const barChartData = barStats.map((item) => ({
      label: Utils.textFirstOnlyUpper(item.stage),
      data: Object.keys(item)
        .filter((key) => key !== 'stage')
        .map((i) => item[i]),
      backgroundColor: item.stage === 'PRIMARY' ? '#00d41999' : '#5693f599',
      borderColor: item.stage === 'PRIMARY' ? '#00d419' : '#5693f5',
      borderWidth: 1,
      minBarLength: 1,
    }));

    setBarDatasets(barChartData);
  };

  useEffect(() => {
    setData();
  }, []);

  useEffect(() => {
    setBarChartData();
  }, [barStats]);

  return (
    <div className='tw-relative'>
      <div className='tw-absolute tw-right-0 tw-top-0 tw-rounded tw-bg-blue-400 tw-w-7 tw-h-7 tw-flex tw-items-center tw-justify-center tw-cursor-pointer'>
        <Tooltip title='Download chart as image'>
          <FontAwesomeIcon
            icon={faDownload}
            size='lg'
            onClick={() => handleDownload('OverviewBarChart', chartRef)}
            className='tw-text-white'
          />
        </Tooltip>
      </div>
      <Bar data={barData} options={barOptions} ref={chartRef} />
    </div>
  );
};

export default OverviewBarChart;
