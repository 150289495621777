import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

import React, { useState } from 'react';
import Tooltip from '../../../components/Tooltip';
import TextWithIcon from '../components/TextWithIcon';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  IntegratedSorting,
  FilteringState,
  SortingState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import { TableHeaderRowSortLabelComponent } from '../../../components/tables/Sort';
import { debounce } from 'throttle-debounce';

const INSTRUCTIONS =
  'The left table contains the available files with the same language that are not associated with the project. ' +
  "The right table contains the project's files. Double click on a file to move it from one table to another.";
export const ProjectFilesView = ({
  project = null,
  files = [],
  projectFiles = [],
  isBusy = false,
  onAvailableFileClick = null,
  onProjectFileClick = null,
}) => {
  const projectFilesIds = projectFiles?.map((obj) => obj.id) ?? [];
  const availableFiles =
    files
      ?.filter((obj) => !projectFilesIds?.includes(obj.id))
      ?.filter((obj) => obj?.sourceLanguage?.id === project?.sourceLanguage?.id)
      ?.filter(
        (obj) => obj?.targetLanguage?.id === project?.targetLanguage?.id || !obj?.targetLanguage
      ) ?? [];

  return (
    <>
      <Tooltip title={INSTRUCTIONS}>
        <TextWithIcon
          size={3}
          text={'Files'}
          icon={'fas fa-file'}
          outerClassName={'mt-5 mb-3'}
          innerClassName={'text-center'}
          cursor='help'
        />
      </Tooltip>
      <div className='row pb-4'>
        <div className='col-6'>
          <h3 className='text-center text-muted'>Available</h3>
          <FilesTable files={availableFiles} isBusy={isBusy} onSelect={onAvailableFileClick} />
        </div>
        <div className='col-6'>
          <h3 className='text-center text-muted'>Project</h3>
          <FilesTable files={projectFiles} isBusy={isBusy} onSelect={onProjectFileClick} />
        </div>
      </div>
    </>
  );
};

const FilesTableColumns = [
  { name: 'name', title: 'Name', sort: true, width: '40%', wordWrapEnabled: true },
  {
    name: 'sourceLanguage',
    title: 'Source',
    sort: true,
    align: 'center',
    width: '15%',
    getCellValue: (row) => row.sourceLanguage?.code ?? '',
  },
  {
    name: 'targetLanguage',
    title: 'Target',
    sort: true,
    align: 'center',
    width: '15%',
    getCellValue: (row) => row.targetLanguage?.code ?? '',
  },
  {
    name: 'tokenizationStatus',
    title: 'Tokens',
    sort: true,
    align: 'center',
    width: '10%',
    getCellValue: (row) =>
      row.tokenizationStatus === 'COMPLETED' ? <span>&#10003;</span> : <span>&#10006;</span>,
  },
  {
    name: 'segments',
    title: 'Size',
    sort: true,
    align: 'center',
    width: '10%',
    getCellValue: (row) => row.segmentsNum ?? '',
  },
];
const FilesTableColumnExtensions = FilesTableColumns.map((c) => ({
  columnName: c.name,
  width: c.width,
}));
const FilesTable = ({ files = [], onSelect = null, isBusy = false }) => {
  const [sorting, setSorting] = useState([{ columnName: 'name', direction: 'asc' }]);
  const [columnsWidth, setColumnsWidth] = useState(FilesTableColumnExtensions);
  const [searchInput, setSearchInput] = useState('');

  return (
    <div className={`border ${isBusy ? 'whirl line back-and-forth grow' : ''}`}>
      <SearchInput onChange={setSearchInput} />
      <FilesTableHeader onSortingChange={setSorting} onColumnWidthChange={setColumnsWidth} />
      <FilesTableBody
        files={files}
        sorting={sorting}
        columnsWidth={columnsWidth}
        onSelect={onSelect}
        searchValue={searchInput}
      />
    </div>
  );
};

const FilesTableHeader = ({ onSortingChange = null, onColumnWidthChange = null }) => {
  return (
    <Grid rows={[]} columns={FilesTableColumns} getRowId={(row) => row?.id}>
      <SortingState
        defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
        onSortingChange={(s) => onSortingChange(s)}
      />
      <Table bodyComponent={() => null} columnExtensions={FilesTableColumnExtensions} />
      <TableColumnResizing
        resizingMode='nextColumn'
        defaultColumnWidths={FilesTableColumnExtensions}
        onColumnWidthsChange={onColumnWidthChange}
      />
      <TableHeaderRow
        showSortingControls={true}
        sortLabelComponent={TableHeaderRowSortLabelComponent}
      />
    </Grid>
  );
};

const FilesTableBody = ({
  files = [],
  sorting = [],
  columnsWidth = [],
  onSelect = null,
  searchValue,
}) => {
  const filters = [{ columnName: 'name', value: searchValue ?? '' }];
  return (
    <div className='taus-scroll-bar' style={{ maxHeight: '400px', overflowY: 'auto' }}>
      <Grid rows={files ?? []} columns={FilesTableColumns} getRowId={(row) => row?.id}>
        <SortingState sorting={sorting} />
        <FilteringState filters={filters} />
        <IntegratedFiltering />
        <IntegratedSorting />
        <Table
          headComponent={() => null}
          rowComponent={(props) => <FilesTableRowComponent onDoubleClick={onSelect} {...props} />}
          columnExtensions={FilesTableColumnExtensions}
        />
        <TableColumnResizing resizingMode='nextColumn' columnWidths={columnsWidth} />
      </Grid>
    </div>
  );
};

const FilesTableRowComponent = ({ onDoubleClick = null, ...props }) => {
  return (
    <Tooltip title={props.row?.name ?? ''}>
      <Table.Row
        {...props}
        style={{ cursor: 'pointer' }}
        onDoubleClick={(e) => {
          onDoubleClick(props.row?.id ?? null);
        }}
      />
    </Tooltip>
  );
};

const SearchInput = ({ onChange = null }) => {
  const onChangeDebounced = !onChange ? null : debounce(500, onChange);
  const onValueChange = (e) => {
    if (!!onChangeDebounced) onChangeDebounced(e?.target?.value ?? '');
  };
  return (
    <div className='input-group'>
      <div className='input-group-prepend'>
        <span className='input-group-text'>
          <em className='fas fa-search' />
        </span>
      </div>
      <input
        onChange={(e) => onValueChange(e)}
        type='text'
        className='form-control'
        placeholder='Search by name'
      />
    </div>
  );
};

export default ProjectFilesView;
