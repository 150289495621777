import { get, post, put, remove } from '../http';
import { objectToQueryString } from '../utils/Url';

const BASE_URL = '/api/project';
const ADMIN_URL = '/api/admin/project';

const getAll = (parameters, headers = {}) => {
  return get(`${BASE_URL}/?${objectToQueryString(parameters)}`, { headers: { ...headers } });
};

const adminGetAll = (parameters = {}) => {
  return get(`${ADMIN_URL}?${objectToQueryString(parameters)}`);
};

const adminGetTestProjects = () => {
  return get(`${ADMIN_URL}?scope="WORKER_VALIDATION"`);
};

const adminGetProject = (id) => {
  if (!id) {
    throw new Error('Invalid project id (missing)');
  }
  return get(`${ADMIN_URL}/${id}`);
};

const getProject = (id) => {
  if (!id) {
    throw new Error('Invalid project id (missing)');
  }
  return get(`${BASE_URL}/${id}`);
};

const create = (project) => {
  if (!project) {
    throw new Error('Invalid create properties');
  }
  return post(`${BASE_URL}`, project);
};

const copy = (id, project) => {
  if (!id) {
    throw new Error('Invalid project id (Null)');
  }
  if (!project) {
    throw new Error('Invalid create properties');
  }
  return post(`${ADMIN_URL}/${id}/copy`, project);
};

const update = (id, properties) => {
  if (!id) {
    throw new Error('Invalid project id (Null)');
  }
  if (!properties) {
    throw new Error('Invalid update properties');
  }
  return put(`${BASE_URL}/${id}`, properties);
};

const deleteProject = (id) => {
  if (!id) {
    throw new Error('Invalid project id (Null)');
  }
  return remove(`${BASE_URL}/${id}`);
};

const initializeSegments = (id) => {
  if (!id) {
    throw new Error('Invalid project id (Null)');
  }
  return post(`${BASE_URL}/${id}/initialize/segments`);
};

const initializeCredits = (id = null) => {
  if (!id) {
    throw new Error('Invalid project id (Null)');
  }
  return post(`${BASE_URL}/${id}/initialize/credits`);
};

const getFiles = (projectId, headers) => {
  if (!projectId) {
    throw new Error('Invalid project id (Null)');
  }
  return get(`${BASE_URL}/${projectId}/file`, { headers: { ...headers } });
};

const addFile = (projectId = null, fileId = null) => {
  if (!projectId) {
    throw new Error('Invalid project id (Null)');
  }
  if (!fileId) {
    throw new Error('Invalid file id (Null)');
  }
  return post(`${BASE_URL}/${projectId}/file/${fileId}`);
};

const addFiles = (projectId, files) => {
  if (!projectId) {
    throw new Error('Invalid project id (Null)');
  }
  if (!Array.isArray(files)) {
    throw new Error('Invalid files (Not an array)');
  }
  return post(`${BASE_URL}/${projectId}/file`, files);
};

const removeFile = (projectId, fileId) => {
  if (!projectId) {
    throw new Error('Invalid project id (Null)');
  }
  if (!fileId) {
    throw new Error('Invalid file id (Null)');
  }
  return remove(`${BASE_URL}/${projectId}/file/${fileId}`);
};

const removeFiles = (projectId, files) => {
  if (!projectId) {
    throw new Error('Invalid project id (Null)');
  }
  if (!Array.isArray(files)) {
    throw new Error('Invalid file id (Not an array)');
  }
  return remove(`${BASE_URL}/${projectId}/file`, files);
};

const getMemberRoles = () => {
  return get(`${BASE_URL}/role`);
};

const getMembers = (id, headers) => {
  if (!id) {
    throw new Error('Missing project id');
  }
  return get(`${BASE_URL}/${id}/member`, { headers: { ...headers } });
};

const createMember = (projectId, memberId, roleSid, taskLimit = null) => {
  if (!projectId) {
    throw new Error('Missing project id');
  }
  if (!memberId) {
    throw new Error('Missing member id');
  }
  if (!roleSid) {
    throw new Error('Missing role sid');
  }

  let data = {
    userId: memberId,
    roleSid: roleSid,
    taskLimit: taskLimit,
  };

  return post(`${BASE_URL}/${projectId}/member`, data);
};

const createMembers = (projectId, roleSid, members, taskLimit = null) => {
  if (!projectId) {
    throw new Error('Missing project id');
  }
  if (!roleSid) {
    throw new Error('Missing role sid');
  }
  if (!Array.isArray(members)) {
    throw new Error('Invalid members (Not array)');
  }

  let data = {
    userIds: members,
    roleSid: roleSid,
    taskLimit: taskLimit,
  };

  return post(`${BASE_URL}/${projectId}/members`, data);
};

const deleteMember = (projectId, memberId) => {
  if (!projectId) {
    throw new Error('Missing project id');
  }
  if (!memberId) {
    throw new Error('Missing member id');
  }

  return remove(`${BASE_URL}/${projectId}/member/${memberId}`);
};

const deleteMembers = (projectId, members) => {
  if (!projectId) {
    throw new Error('Missing project id');
  }
  if (!Array.isArray(members)) {
    throw new Error('Invalid members (Not array)');
  }
  return remove(`${BASE_URL}/${projectId}/member`, members);
};

const createMembershipRequest = (projectId) => {
  if (!projectId) {
    throw new Error('Missing project id');
  }
  return post(`${BASE_URL}/${projectId}/membership/request`);
};

const updateMemberPosition = (projectId, memberId, position) => {
  if (!projectId) {
    throw new Error('Missing project id');
  }
  if (!memberId) {
    throw new Error('Missing member id');
  }
  return put(`${BASE_URL}/${projectId}/member/${memberId}/position`, position);
};

const updateMember = (projectId, memberId, memberIds = [], taskLimit = null) => {
  if (!projectId) {
    throw new Error('Missing project id');
  }
  if (!memberId) {
    throw new Error('Missing member id');
  }
  let data = {
    memberIds: memberIds,
    taskLimit: taskLimit,
  };
  return put(`${BASE_URL}/${projectId}/member/${memberId}`, data);
};

const getSegments = (projectId, statuses = []) => {
  return get(`${BASE_URL}/${projectId}/segment?statuses=${statuses}`);
};

const adminInvalidateScreenerStep = async (projectId = null, screenerStepId = null) => {
  if (!projectId) {
    throw new Error('Invalid project id (missing)');
  }
  if (!screenerStepId) {
    throw new Error('Invalid screener step id (missing)');
  }
  return get(`${ADMIN_URL}/${projectId}/screener-step/${screenerStepId}/invalidate`);
};

const completeAllScreenerSteps = (projectId = null, userId = null) => {
  if (!projectId) {
    throw new Error('Invalid project id (missing)');
  }
  if (!userId) {
    throw new Error('Invalid user id (missing)');
  }
  return post(`${BASE_URL}/${projectId}/applicant/${userId}/screener-step`);
};

const unCompleteAllScreenerSteps = (projectId = null, userId = null) => {
  if (!projectId) {
    throw new Error('Invalid project id (missing)');
  }
  if (!userId) {
    throw new Error('Invalid user id (missing)');
  }
  return remove(`${BASE_URL}/${projectId}/applicant/${userId}/screener-step`);
};

const getApplicants = (projectId = null, parameters = {}) => {
  if (!projectId) {
    throw new Error('Invalid project id (missing)');
  }
  return get(`${BASE_URL}/${projectId}/applicant?${objectToQueryString(parameters)}`);
};

const addApplicant = (projectId) => {
  if (!projectId) {
    throw new Error('Invalid project id (missing)');
  }
  return post(`${BASE_URL}/${projectId}/applicant`);
};

const adminGetScreenerSteps = (projectId = null) => {
  if (!projectId) {
    throw new Error('Invalid project id (missing)');
  }
  return get(`${ADMIN_URL}/${projectId}/screener-step`);
};

const adminAddScreenerStep = (projectId = null, screenerStepId = null, data = null) => {
  if (!projectId) {
    throw new Error('Invalid project id (missing)');
  }
  if (!screenerStepId) {
    throw new Error('Invalid screener step id (missing)');
  }
  return post(`${ADMIN_URL}/${projectId}/screener-step/${screenerStepId}/`, data);
};

const adminDeleteScreeningStep = (projectId = null, screenerStepId = null) => {
  if (!projectId) {
    throw new Error('Invalid project id (missing)');
  }
  if (!screenerStepId) {
    throw new Error('Invalid screener step id (missing)');
  }
  return remove(`${ADMIN_URL}/${projectId}/screener-step/${screenerStepId}/`);
};

const getScreenerSteps = (projectId) => {
  if (!projectId) {
    throw new Error('Invalid project id (missing)');
  }
  return get(`${BASE_URL}/${projectId}/screener-step`);
};

const getScreenerStep = (projectId, screenerStepId) => {
  if (!projectId) {
    throw new Error('Invalid project id (missing)');
  }
  if (!screenerStepId) {
    throw new Error('Invalid screener step id (missing)');
  }
  return get(`${BASE_URL}/${projectId}/screener-step/${screenerStepId}`);
};

const prepareWorker = (projectId = null, screenerStepId = null) => {
  if (!projectId) {
    throw new Error('Invalid project id (missing)');
  }
  if (!screenerStepId) {
    throw new Error('Invalid screener step id (missing)');
  }
  return get(`${BASE_URL}/${projectId}/screener-step/${screenerStepId}/prepare`);
};

const ProjectController = {
  adminGetProject,
  adminGetAll,
  adminGetTestProjects,
  adminInvalidateScreenerStep,
  adminGetScreenerSteps,
  adminAddScreenerStep,
  adminDeleteScreeningStep,
  getAll,
  getProject,
  create,
  copy,
  update,
  delete: deleteProject,
  initializeSegments,
  initializeCredits,
  getFiles,
  addFile,
  addFiles,
  removeFile,
  removeFiles,
  getMemberRoles,
  getMembers,
  createMember,
  createMembers,
  deleteMember,
  deleteMembers,
  createMembershipRequest,
  updateMember,
  updateMemberPosition,
  getSegments,
  getScreenerSteps,
  getScreenerStep,
  addApplicant,
  getApplicants,
  completeAllScreenerSteps,
  unCompleteAllScreenerSteps,
  prepareWorker,
};

export default ProjectController;
