import dayjs from 'dayjs';
import 'chartjs-adapter-moment';
import { Line } from 'react-chartjs-2';
import Utils from '../../../utils/Utils';
import Loader from '../../../components/Loader';
import { findOption } from '../../../utils/Select';
import { useEffect, useRef, useState } from 'react';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { randomColorGenerator } from '../../../utils/RandomColorGenerator';
import DateTimePickerInput from '../../../components/forms/DateTimePickerInput';
import SelectWithValidation from '../../../components/forms/SelectWithValidation';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  registerables,
} from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
ChartJS.register(CategoryScale, LinearScale, PointElement, TimeScale, ...registerables);

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const TimeseriesLineChart = ({ tasks, handleDownload }) => {
  const chartRef = useRef(null);
  const [toFilter, setToFilter] = useState(null);
  const [fromFilter, setFromFilter] = useState(null);
  const [granularity, setGranularity] = useState('month');
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const granularityFormat = 'DD/MM/YYYY';
  const granularityOptions = [
    { value: 'day', label: 'Day' },
    { value: 'week', label: 'Week' },
    { value: 'month', label: 'Month' },
    { value: 'year', label: 'Year' },
  ];
  const granularityDefaultOption = findOption(granularityOptions, granularity);

  const getGranularityFormat = () => {
    switch (true) {
      case granularity === 'day':
        return 'DD/MM/YYYY';
      case granularity === 'month':
        return 'MM/YYYY';
      case granularity === 'year':
        return 'YYYY';
      default:
        return 'DD/MM/YYYY';
    }
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        type: 'time',
        bounds: 'ticks',
        time: {
          unit: granularity,
          parser: 'DD/MM/YYYY',
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      title: {
        display: true,
        text: '1c. Task result statuses over time',
      },
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            const date = dayjs(context[0].parsed.x).format(getGranularityFormat());
            return date;
          },
        },
      },
      datalabels: {
        display: false,
        anchor: 'end', // Position of the labels (start, end, center, etc.)
        align: 'end', // Alignment of the labels (start, end, center, etc.)
        formatter: (value) => value,
      },
    },
  };

  useEffect(() => {
    const from = !Utils.isNull(fromFilter) ? dayjs(fromFilter).toISOString() : null;
    const to = !Utils.isNull(toFilter) ? dayjs(toFilter).toISOString() : null;

    let data = {};
    data['SUBMITTED'] = tasks
      .filter((t) => t.taskResult.timestampSubmitted)
      .filter((t) =>
        from
          ? dayjs(dayjs(t.taskResult.timestampSubmitted).toISOString()).isSameOrAfter(from)
          : true
      )
      .filter((t) =>
        to ? dayjs(dayjs(t.taskResult.timestampSubmitted).toISOString()).isSameOrBefore(to) : true
      )
      .map((t) => dayjs(t.taskResult.timestampSubmitted).format(granularityFormat))
      .sort((a, b) => dayjs(a, granularityFormat) - dayjs(b, granularityFormat));

    data['REVIEWED'] = tasks
      .filter((t) => t.taskResult.timestampReviewed)
      .filter((t) =>
        from ? dayjs(dayjs(t.taskResult.timestampReviewed).toISOString()).isSameOrAfter(from) : true
      )
      .filter((t) =>
        to ? dayjs(dayjs(t.taskResult.timestampReviewed).toISOString()).isSameOrBefore(to) : true
      )
      .map((t) => dayjs(t.taskResult.timestampReviewed).format(granularityFormat))
      .sort((a, b) => dayjs(a, granularityFormat) - dayjs(b, granularityFormat));

    data['PAID'] = tasks
      .filter((t) => t.taskResult.timestampPaid)
      .filter((t) =>
        from ? dayjs(dayjs(t.taskResult.timestampPaid).toISOString()).isSameOrAfter(from) : true
      )
      .filter((t) =>
        to ? dayjs(dayjs(t.taskResult.timestampPaid).toISOString()).isSameOrBefore(to) : true
      )
      .map((t) => dayjs(t.taskResult.timestampPaid).format(granularityFormat))
      .sort((a, b) => dayjs(a, granularityFormat) - dayjs(b, granularityFormat));

    data['DELIVERED'] = tasks
      .filter((t) => t.taskResult.timestampDelivered)
      .filter((t) =>
        from
          ? dayjs(dayjs(t.taskResult.timestampDelivered).toISOString()).isSameOrAfter(from)
          : true
      )
      .filter((t) =>
        to ? dayjs(dayjs(t.taskResult.timestampDelivered).toISOString()).isSameOrBefore(to) : true
      )
      .map((t) => dayjs(t.taskResult.timestampDelivered).format(granularityFormat))
      .sort((a, b) => dayjs(a, granularityFormat) - dayjs(b, granularityFormat));

    // Get only distinct timestamps across all keys (this might give duplicate timestamps as a date could be find in 2 keys or more)
    let distinctTimestamps = [];
    Object.keys(data).forEach((key) =>
      data[key]
        .filter((value, index, current_value) => current_value.indexOf(value) === index)
        .map((i) => distinctTimestamps.push(i))
    );

    // Get again distinct timestamps in order to remove duplicates from above
    distinctTimestamps = distinctTimestamps.filter(
      (value, index, current_value) => current_value.indexOf(value) === index
    );

    // Generate the labels based on the distinct timestamps sorted
    const labels = distinctTimestamps.sort(
      (a, b) => dayjs(a, granularityFormat) - dayjs(b, granularityFormat)
    );

    //
    let timestampsWithCounts = {};
    Object.keys(data).forEach((key) => {
      const newData = data[key].reduce((acc, val) => {
        acc[val] = acc[val] === undefined ? 1 : (acc[val] += 1);
        return acc;
      }, {});
      timestampsWithCounts[key] = newData;
    });

    Object.keys(timestampsWithCounts).forEach((key) => {
      const arr1 = new Set(distinctTimestamps);
      const arr2 = new Set(Object.keys(timestampsWithCounts[key]));
      const missingKeys = new Set(arr1.difference(arr2));
      missingKeys.forEach((k) => {
        timestampsWithCounts[key] = { ...timestampsWithCounts[key], [k]: 0 };
      });
    });

    const datasets = Object.keys(data).map((key, index) => {
      const color = randomColorGenerator(index);
      return {
        label: Utils.textFirstOnlyUpper(key),
        data: Object.keys(timestampsWithCounts[key])
          .sort((a, b) => dayjs(a, granularityFormat) - dayjs(b, granularityFormat))
          .map((t) => timestampsWithCounts[key][t]),
        backgroundColor: color,
        borderColor: color,
        lineTension: 0.3,
      };
    });

    setChartData({
      labels: labels,
      datasets: datasets,
    });
  }, [tasks, fromFilter, toFilter]);

  return (
    <div className='tw-grid tw-grid-cols-4 tw-gap-4'>
      <div className='tw-col-span-1'>
        <div className='tw-mb-4 tw-text-center'>
          <span className='tw-text-lg tw-font-medium'>Filters</span>
        </div>
        <div>
          <SelectWithValidation
            label='Granularity'
            placeholder='Granularity'
            propertyName='granularity'
            options={granularityOptions}
            defaultOption={granularityDefaultOption}
            onChange={(_, value) => setGranularity(value)}
          />
        </div>
        <div className='tw-mb-4'>
          <DateTimePickerInput
            label='From'
            isDateOnly={true}
            value={fromFilter}
            isClearable={true}
            format='DD/MM/YYYY'
            sx={{ width: '100%' }}
            handleChange={(value) => setFromFilter(value)}
          />
        </div>
        <div>
          <DateTimePickerInput
            label='To'
            value={toFilter}
            isDateOnly={true}
            isClearable={true}
            format='DD/MM/YYYY'
            sx={{ width: '100%' }}
            handleChange={(value) => setToFilter(value)}
          />
        </div>
      </div>
      <div className='tw-col-span-3'>
        {Utils.isDefined(chartData?.labels?.[0]) ? (
          <div className='tw-relative'>
            <div className='tw-absolute tw-right-0 tw-top-0 tw-rounded tw-bg-blue-400 tw-w-7 tw-h-7 tw-flex tw-items-center tw-justify-center tw-cursor-pointer'>
              <Tooltip title='Download chart as image'>
                <FontAwesomeIcon
                  icon={faDownload}
                  size='lg'
                  onClick={() => handleDownload('OverviewLineChart', chartRef)}
                  className='tw-text-white'
                />
              </Tooltip>
            </div>
            <Line data={chartData} options={chartOptions} ref={chartRef} />
          </div>
        ) : (
          <div className='tw-relative tw-h-40 tw-mt-4'>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeseriesLineChart;
