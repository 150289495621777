import {
  CustomPaging,
  EditingState,
  PagingState,
  SelectionState,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import { TableEditColumnOrderLast } from '../../../components/tables/Editor';
import { format } from 'date-fns';
import { useState } from 'react';

const ProjectCampaignsView = ({
  isCompact,
  campaigns,
  pagination,
  totalElements,
  onCurrentPageChange,
  onPageSizeChange,
  onNew,
  onCampaignSelected,
  onCampaignDeleted,
}) => {
  const hiddenColumnNames = isCompact ? ['roles', 'credits', 'enableNotifications', 'started'] : [];
  const [selection, setSelection] = useState([]);

  const toggleSelection = (rowId) => {
    const existingId = selection.find((x) => x === rowId);
    setSelection(existingId ? [] : [rowId]);
    onCampaignSelected(!existingId ? campaigns.find((x) => x.id === rowId) : null);
  };

  const onAddedRowsChange = () => {
    setSelection([]);
    onNew();
  };

  const commitChanges = ({ deleted }) => {
    if (deleted.length > 0) deleted.forEach((d) => onCampaignDeleted(d));
  };

  const tableColumns = [
    {
      name: 'id',
      title: 'Id',
      getCellValue: (row) => row.id,
    },
    {
      name: 'type',
      title: 'Type',
      getCellValue: (row) => row.type,
    },
    {
      name: 'name',
      title: 'Name',
    },
    {
      name: 'credits',
      title: 'Credits',
    },
    {
      name: 'roles',
      title: 'Roles',
      getCellValue: (row) =>
        row.roles.map((d) => (
          <span key={d.id} className={`badge badge-pill border mr-1`}>
            {d.name}
          </span>
        )),
    },
    {
      name: 'startDate',
      title: 'Start Date',
      getCellValue: (row) => format(new Date(row.startDate), 'dd/MM/yyyy kk:mm'),
    },
    {
      name: 'endDate',
      title: 'End Date',
      getCellValue: (row) => format(new Date(row.endDate), 'dd/MM/yyyy kk:mm'),
    },
    {
      name: 'enableNotifications',
      title: 'Notif.',
      getCellValue: (row) =>
        row.enableNotifications ? <i className='fa fa-check' /> : <i className='fa fa-times' />,
    },
    {
      name: 'started',
      title: 'Started',
      getCellValue: (row) =>
        row.started ? <i className='fa fa-check' /> : <i className='fa fa-times' />,
    },
  ];

  const TableRow = ({ tableRow, highlighted, onToggle, ...restProps }) => {
    return (
      <TableSelection.Row
        className={
          (tableRow.row.started ? 'tw-text-gray-700 tw-text-opacity-50 ' : '') +
          (highlighted ? 'tw-text-white tw-bg-blue-400' : '')
        }
        {...restProps}
        style={tableRow.row.started ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
        onToggle={() => {
          if (!tableRow.row.started) toggleSelection(tableRow.row.id);
        }}
      />
    );
  };

  return (
    <Grid rows={campaigns} columns={tableColumns} getRowId={(row) => row.id}>
      <PagingState
        currentPage={pagination.page}
        pageSize={pagination.size}
        onCurrentPageChange={onCurrentPageChange}
        onPageSizeChange={onPageSizeChange}
      />
      <CustomPaging totalCount={totalElements} />
      <SelectionState selection={selection} />

      <Table
        columnExtensions={[
          // { columnName: 'name', wordWrapEnabled: true, width: 150 },
          { columnName: 'id', width: 50 },
          { columnName: 'type', width: 90 },
          { columnName: 'enableNotifications', width: 63 },
          { columnName: 'started', width: 63 },
          { columnName: 'credits', width: 100 },
          { columnName: 'startDate', width: 150 },
          { columnName: 'endDate', width: 150 },
        ]}
      />
      <TableHeaderRow />
      <TableSelection
        selectByRowClick
        highlightRow
        showSelectionColumn={false}
        rowComponent={TableRow}
      />
      <TableColumnVisibility
        hiddenColumnNames={hiddenColumnNames}
        emptyMessageComponent={() => <span>{'All columns hidden'}</span>}
      />

      <EditingState
        onCommitChanges={commitChanges}
        onAddedRowsChange={onAddedRowsChange}
        addedRows={[]}
      />
      {!isCompact && <TableEditColumn showAddCommand showDeleteCommand />}

      <PagingPanel pageSizes={[10, 25, 50, 100]} />
    </Grid>
  );
};

export default ProjectCampaignsView;
