import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { format } from 'date-fns';
import { Form } from 'react-bootstrap';
import { Col, FormGroup, Input, Jumbotron, Label, Row } from 'reactstrap';
import { FormFieldFooter } from '../../../components/forms/FormFieldFooter';
import InputWithValidation from '../../../components/forms/InputWithValidation';
import SelectWithValidation from '../../../components/forms/SelectWithValidation';
import CheckboxWithValidation from '../../../components/forms/CheckboxWithValidation';

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Please select a type for the campaign!').nullable(),
  name: Yup.string().required('Please type a name for the campaign!').nullable(),
  startDate: Yup.string().required('Please select a start date for the campaign!').nullable(),
  endDate: Yup.string().required('Please select an end date for the campaign!').nullable(),
  credits: Yup.number()
    .min(1, 'Minimum allowed value for credits is 1')
    .required('Please set the credits!')
    .nullable(),
  projectRoleIds: Yup.array().required('Please select one or more roles!').nullable(),
});

const ProjectCampaignForm = ({
  roleOptions,
  selectedCampaign,
  onCampaignCreated,
  onCampaignUpdated,
  onCancel,
}) => {
  roleOptions = roleOptions.map((x) => {
    x.disabled = x.label !== 'Translator' && x.label !== 'Reviewer';
    return x;
  });

  const initialValues = {
    type: selectedCampaign ? selectedCampaign.type : 'BONUS',
    name: selectedCampaign ? selectedCampaign.name : '',
    startDate: selectedCampaign
      ? format(new Date(selectedCampaign.startDate), "yyyy-MM-dd'T'kk:mm")
      : '',
    endDate: selectedCampaign
      ? format(new Date(selectedCampaign.endDate), "yyyy-MM-dd'T'kk:mm")
      : '',
    credits: selectedCampaign ? selectedCampaign.credits : 0,
    enableNotifications: selectedCampaign ? selectedCampaign.enableNotifications : false,
    projectRoleIds: selectedCampaign ? selectedCampaign.roles.map((x) => x.id) : [],
  };

  const getRoleOptions = (ids) => {
    return roleOptions.filter((x) => !!ids && ids.includes(x.value));
  };

  const handleSubmit = (data, { setSubmitting, resetForm }) => {
    const body = {
      ...data,
      startDate: Date.parse(data.startDate),
      endDate: Date.parse(data.endDate),
    };
    const operation = () =>
      selectedCampaign ? onCampaignUpdated(selectedCampaign.id, body) : onCampaignCreated(body);

    setSubmitting(true);
    operation()
      .then(() => resetForm())
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(props) => {
        return (
          <Jumbotron className={'tw-bg-gray-50'}>
            <h4 className={'text-center'}>
              {(selectedCampaign ? 'Edit' : 'Create') + ' Campaign'}
            </h4>
            <Form onSubmit={props.handleSubmit}>
              <div className={'text-center mb-2'}>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type='radio'
                      name='type'
                      value={'BONUS'}
                      checked={props.values.type === 'BONUS'}
                      onChange={(e) => {
                        props.setFieldValue('type', e.currentTarget.value);
                      }}
                    />
                    {'Bonus'}
                  </Label>
                  <Label check>
                    <Input
                      type='radio'
                      name='type'
                      value={'REFERRAL'}
                      checked={props.values.type === 'REFERRAL'}
                      onChange={(e) => {
                        props.setFieldValue('type', e.currentTarget.value);
                      }}
                    />
                    {'Referral'}
                  </Label>
                </FormGroup>
                <FormFieldFooter
                  helpText={"The campaign's type"}
                  error={props.errors.type}
                  touched={props.touched.type}
                />
              </div>
              <Row>
                <Col>
                  <InputWithValidation
                    type='text'
                    propertyName='name'
                    label='Name'
                    defaultValue={props?.values?.name}
                    placeholder='Type a name...'
                    helpText='A name for the campaign'
                    touched={props?.touched?.name}
                    error={props?.errors?.name}
                    onChange={props?.setFieldValue}
                    onBlur={props?.setFieldTouched}
                  />
                </Col>
                <Col>
                  <InputWithValidation
                    propertyName={'credits'}
                    label={'Credits'}
                    helpText={
                      'The credits associated with the campaign. This has different semantics for each campaign type.'
                    }
                    min={0}
                    step={0.01}
                    type={'number'}
                    defaultValue={props.values.credits}
                    touched={props.touched.credits}
                    error={props.errors.credits}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputWithValidation
                    type='datetime-local'
                    propertyName='startDate'
                    label='Start Date'
                    defaultValue={props?.values?.startDate}
                    placeholder='Select a date...'
                    helpText='When the campaign starts'
                    touched={props?.touched?.startDate}
                    error={props?.errors?.startDate}
                    onChange={props?.setFieldValue}
                    onBlur={props?.setFieldTouched}
                  />
                </Col>
                <Col>
                  <InputWithValidation
                    type='datetime-local'
                    propertyName='endDate'
                    label='End Date'
                    defaultValue={props?.values?.endDate}
                    placeholder='Select a date...'
                    helpText='When the campaign ends'
                    touched={props?.touched?.endDate}
                    error={props?.errors?.endDate}
                    onChange={props?.setFieldValue}
                    onBlur={props?.setFieldTouched}
                  />
                </Col>
              </Row>
              <SelectWithValidation
                propertyName='projectRoleIds'
                label='Project Roles'
                defaultOption={getRoleOptions(props?.values?.projectRoleIds)}
                options={roleOptions}
                isMulti={true}
                error={props?.errors?.projectRoleIds}
                touched={props?.touched?.projectRoleIds}
                placeholder='Select associated role(s)'
                helpText='The project roles that will get associated with the campaign. This has different semantics for each campaign type.'
                onChange={props.setFieldValue}
                onBlur={props?.setFieldTouched}
              />
              <CheckboxWithValidation
                label={'Enable Notifications'}
                helpText={
                  'If this is checked, all users that match the roles defined above will get notified if there is a notification template set for that role.'
                }
                style={{ transform: 'scale(1.3)' }}
                propertyName={'enableNotifications'}
                defaultValue={props.values.enableNotifications}
                error={props.errors.enableNotifications}
                touched={props.touched.enableNotifications}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
              />

              <Row className='justify-content-center  mt-2'>
                <Col>
                  <button
                    key='cancel'
                    type='button'
                    color='secondary'
                    onClick={() => {
                      props.resetForm();
                      onCancel();
                    }}
                    className={'btn btn-secondary btn-block'}
                  >
                    Cancel
                  </button>
                </Col>
                <Col>
                  <button
                    key='save'
                    type='submit'
                    color='success'
                    disabled={props.isSubmitting}
                    className={'btn btn-success btn-block'}
                  >
                    {selectedCampaign ? 'Update' : 'Create'}
                  </button>
                </Col>
              </Row>
            </Form>
          </Jumbotron>
        );
      }}
    </Formik>
  );
};
export default ProjectCampaignForm;
