import React from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';
import Tooltip from '../Tooltip';

export const TableHeaderTooltip = ({ column, children, ...props }) => {
  return (
    <TableHeaderRow.Content column={column} {...props}>
      {children}
      {column.tooltip ? (
        <Tooltip key='column-info-tooltip' title={column.tooltip}>
          <i className='fa fa-info-circle mr-n4 mb-3' aria-hidden='true' />
        </Tooltip>
      ) : null}
    </TableHeaderRow.Content>
  );
  return <TableHeaderRow.Content {...props}>{props.tooltip}</TableHeaderRow.Content>;
};
