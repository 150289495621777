// External dependencies
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Internal Dependencies
import Utils from '../../../utils/Utils';
import DOMObject from '../../../components/DOMObject';
import ErrorBoundary from '../../../components/ErrorBoundary';
import ApiException from '../../../models/ApiException';
import Notifier from '../../../components/Notifier';
import Spinner from '../../../components/Spinner';
import TASK_RESULT from '../../../models/TaskResult';
import SEGMENT from '../../../models/Segment';
import Tooltip from '../../../components/Tooltip';
import Button from '../../../components/Button';
import SegmentController from '../../../controllers/SegmentController';
import TaskResultController from '../../../controllers/TaskResultController';

class TaskMSIView extends DOMObject {
  static propTypes = {
    task: TASK_RESULT.propTypes.isRequired,
    scope: PropTypes.oneOf(Object.values(TASK_RESULT.USER_SCOPE)),
    taskIsUpdated: PropTypes.func,
  };

  static defaultProps = {
    task: null,
    scope: TASK_RESULT.USER_SCOPE.WORKER,
    taskIsUpdated: null,
  };

  state = {
    isBusy: false,
  };

  // --------------------------------------------------------------------------------------
  // ---------------------------------- ReactJS Methods -----------------------------------
  // --------------------------------------------------------------------------------------

  render() {
    try {
      let thisObject = this;
      if (Utils.isNull(this.props?.task)) return <Spinner />;

      const canSubmit =
        this.props?.scope === TASK_RESULT.USER_SCOPE.WORKER &&
        TASK_RESULT.getUserPermission(this.props?.task).includes(TASK_RESULT.PERMISSIONS.DATA_EDIT);

      const showApplyReviewButton =
        [TASK_RESULT.STATUS.SUBMITTED, TASK_RESULT.STATUS.PENDING_CHECK].includes(
          this.props?.task?.status
        ) &&
        this.props?.scope === TASK_RESULT.USER_SCOPE.REVIEWER &&
        TASK_RESULT.getUserPermission(this.props?.task).includes(TASK_RESULT.PERMISSIONS.EDIT);

      const canApplyReview = this.props?.task?.targets?.every(
        (obj) => obj?.status !== SEGMENT.REVIEWS.UNREVIEWED
      );

      const segments = this.props.task.targets.map((seg) => {
        const canReview = this.props?.scope === TASK_RESULT.USER_SCOPE.REVIEWER;
        return <thisObject.Segment segment={seg} canReview={canReview} />;
      });

      return (
        <div className={this.state.isBusy ? 'whirl sphere' : ''}>
          {this.props?.scope === TASK_RESULT.USER_SCOPE.WORKER ? (
            <thisObject.TaskWorkerToolbar canSubmit={canSubmit} />
          ) : (
            <thisObject.TaskReviewerToolbar
              showApplyReviewButton={showApplyReviewButton}
              canApplyReview={canApplyReview}
            />
          )}
          {segments}
        </div>
      );
    } catch (e) {
      this.errorHandler(e);
      return <ErrorBoundary hasError={true} errorMessage={'Internal application error'} />;
    }
  }

  // --------------------------------------------------------------------------------------
  // ---------------------------------- Components ----------------------------------------
  // --------------------------------------------------------------------------------------
  Segment = ({ segment = null, canReview = false }) => {
    try {
      let thisObject = this;
      let taskUrl = segment?.source?.content?.data;
      let buttons = [];
      const isApproved = canReview && segment?.status === SEGMENT.REVIEWS.APPROVED;
      const isRejected = canReview && segment?.status === SEGMENT.REVIEWS.REJECTED;

      if (canReview) {
        buttons.push(
          <Button
            key='approve'
            label='Approve'
            priority='success'
            classes='mr-2 mb-2'
            disabled={isApproved}
            handleClick={thisObject.approveButtonClicked(segment?.id)}
          />
        );
        buttons.push(
          <Button
            key='reject'
            label='Reject'
            priority='danger'
            classes='mr-2 mb-2'
            disabled={isRejected}
            handleClick={thisObject.rejectButtonClicked(segment?.id)}
          />
        );
      }

      return (
        <ErrorBoundary errorMessage='Toolbar failed to render!'>
          <div className='card my-0' style={{ boxShadow: 'none' }}>
            <div className='card-body d-flex flex-wrap justify-content-between align-items-center'>
              <div className='mb-2'>
                <Link
                  onClick={() => {
                    window.open(taskUrl);
                  }}
                  to={'#'}
                >
                  {taskUrl}
                </Link>
              </div>
              <div className='d-flex justify-content-between align-items-center'>{buttons}</div>
            </div>
          </div>
        </ErrorBoundary>
      );
    } catch (e) {
      this.errorHandler(e);
      return <ErrorBoundary hasError={true} errorMessage='Toolbar failed to render!' />;
    }
  };

  TaskWorkerToolbar = ({ canSubmit = true }) => {
    try {
      let thisObject = this;

      return (
        <ErrorBoundary errorMessage='Toolbar failed to render!'>
          <div className='card' style={{ boxShadow: 'none' }}>
            <div className='card-body d-flex flex-wrap justify-content-end align-items-center'>
              <div className='d-flex justify-content-between align-items-center'>
                <Tooltip
                  key='submit'
                  title='Submit only when all tasks in the external platform is completed.'
                >
                  <Button
                    label='Submit'
                    priority='success'
                    classes='mr-2 mb-2'
                    disabled={!canSubmit}
                    handleClick={thisObject.submitButtonClicked.bind(thisObject)}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
    } catch (e) {
      this.errorHandler(e);
      return <ErrorBoundary hasError={true} errorMessage='Toolbar failed to render!' />;
    }
  };

  TaskReviewerToolbar = ({ showApplyReviewButton = true, canApplyReview = true }) => {
    try {
      let thisObject = this;

      return (
        <ErrorBoundary errorMessage='Toolbar failed to render!'>
          <div className='card' style={{ boxShadow: 'none' }}>
            <div className='card-body d-flex flex-wrap justify-content-end align-items-center'>
              <div className='d-flex justify-content-between align-items-center'>
                {!showApplyReviewButton ? null : (
                  <Tooltip
                    key='review'
                    title='Apply Review only when all tasks have been reviewed.'
                  >
                    <Button
                      label='Apply Review'
                      priority='primary'
                      classes='mr-2 mb-2'
                      disabled={!canApplyReview}
                      handleClick={thisObject.applyReviewButtonClicked.bind(thisObject)}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
    } catch (e) {
      this.errorHandler(e);
      return <ErrorBoundary hasError={true} errorMessage='Toolbar failed to render!' />;
    }
  };

  // -------------------------------------------------------------------
  // ------------------------------ Listeners --------------------------
  // -------------------------------------------------------------------
  async submitButtonClicked() {
    try {
      this.setState({ isBusy: true });
      let task = null;
      const isRolledback = this.props?.task?.version > 0;
      for (let target of this.props?.task?.targets ?? []) {
        task = await this.validateSegment(target?.id);
      }
      if (!TASK_RESULT.getUserCanSubmit(task)) {
        throw new ApiException('Not all tasks are completed');
      }
      task = await this.submitTask(this.props?.task?.id, isRolledback);
      this.triggerTaskUpdated(task, true);
    } catch (e) {
      this.errorHandler(e, true);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  async completeButtonClicked(id = null) {
    try {
      this.setState({ isBusy: true });
      let task = await this.validateSegment(id);
      this.triggerTaskUpdated(task);
    } catch (e) {
      this.errorHandler(e, true);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  async approveButtonClicked(id = null) {
    try {
      this.setState({ isBusy: true });
      await this.saveSegment({ id: id, status: SEGMENT.REVIEWS.APPROVED });
      const task = await this.getTask();
      this.triggerTaskUpdated(task);
    } catch (e) {
      this.errorHandler(e, true);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  async rejectButtonClicked(id = null) {
    try {
      this.setState({ isBusy: true });
      await this.saveSegment({ id: id, status: SEGMENT.REVIEWS.REJECTED });
      const task = await this.getTask();
      this.triggerTaskUpdated(task);
    } catch (e) {
      this.errorHandler(e, true);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  async applyReviewButtonClicked() {
    try {
      this.setState({ isBusy: true });
      const task = await this.updateTask({ status: TASK_RESULT.STATUS.REVIEWED });
      this.triggerTaskUpdated(task, true);
    } catch (e) {
      this.errorHandler(e, true);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  // --------------------------------------------------------------------------------------
  // ---------------------------------- Methods -------------------------------------------
  // --------------------------------------------------------------------------------------
  async triggerTaskUpdated(task = null, finalize = false) {
    return this.props?.taskIsUpdated(task, finalize);
  }

  // -------------------------------------------------- Http controllers
  async submitTask(id, isRolledback) {
    if (isRolledback) {
      return TaskResultController.resubmit(id);
    } else {
      return TaskResultController.submit(id);
    }
  }

  async validateSegment(id = null) {
    return TaskResultController.validateMSI(this.props?.task?.id, id);
  }

  async saveSegment(segment) {
    return SegmentController.update(segment);
  }

  async updateTask(data = {}) {
    return TaskResultController.update(this.props?.task?.id, { ...data });
  }

  async getTask() {
    return TaskResultController.get(this.props?.task?.id);
  }

  // --------------------------------------------------------------------------
  // ------------------------------ Errors ------------------------------------
  // --------------------------------------------------------------------------
  errorHandler(e, message = null) {
    try {
      e = ApiException.toApiException(e);
      if (typeof message === 'string') Notifier.error(message);
      else if (message === true) Notifier.error(e.userMessage);
    } finally {
      super.errorHandler(e);
    }
  }
}

export default withRouter(TaskMSIView);
