import TASK from './Task';

export default class Project {
  static PERMISSIONS = {
    READ: 'READ',
    EDIT: 'EDIT',
    CREATE: 'CREATE',
    DELETE: 'DELETE',
    TARGET_SEGMENT_EDIT: 'TARGET_SEGMENT_EDIT',
  };

  static STATUS = {
    INACTIVE: 'INACTIVE',
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    PAUSED: 'PAUSED',
  };

  static STAGES = {
    PRIMARY: 'PRIMARY',
    REVIEW: 'REVIEW',
  };

  static SCOPE = {
    CROWDSOURCING: { sid: 'CROWDSOURCING', name: 'Crowd Sourcing' },
    WORKER_VALIDATION: { sid: 'WORKER_VALIDATION', name: 'Worker Validation' },
  };

  static SETTINGS = {
    VISIBILITY: {
      PUBLIC: { sid: 'PUBLIC', name: 'Public' },
      PRIVATE: { sid: 'PRIVATE', name: 'Private' },
    },
    MEMBERSHIP_REQUEST_POLICY: {
      DENY: { sid: 'DENY', name: 'Deny' },
      ALLOW: { sid: 'ALLOW', name: 'Allow' },
      APPROVE_ALL: { sid: 'APPROVE_ALL', name: 'Approve All' },
    },
    TASK_POLICY: {
      PER_SEGMENT: { sid: 'PER_SEGMENT', name: 'Per Segment' },
      PER_WORKER: { sid: 'PER_WORKER', name: 'Per Worker' },
    },
    REVIEW_METHOD: {
      APPROVE_ALL: { sid: 'APPROVE_ALL', name: 'Approve All' },
      REVIEW_TASK: { sid: 'REVIEW_TASK', name: 'Create Review Task' },
      TAUS_TES: { sid: 'TAUS_TES', name: 'TAUS Test Evaluation System' },
    },
    PAYMENTS: {
      POLICY: {
        MANUAL: { sid: 'MANUAL', name: 'Manually' },
        AUTO: { sid: 'AUTO', name: 'Auto' },
        NO_PAYMENT: { sid: 'NO_PAYMENT', name: 'No Payments' },
      },
      MIN_PAYABLE_TASK_EVALUATION_STATUS: {
        NOT_RISKY: { sid: 'NOT_RISKY', name: 'Not Risky' },
        PASSED: { sid: 'PASSED', name: 'Passed' },
      },
    },
    SEGMENT_CHUNK: {
      POLICY: {
        PER_SEGMENT: { sid: 'PER_SEGMENT', name: 'Per Segment' },
        PER_WORD: { sid: 'PER_WORD', name: 'Per Word' },
      },
      SOURCE: {
        UNASSIGNED: { sid: 'UNASSIGNED', name: 'Unassigned Only' },
        REJECTED: { sid: 'REJECTED', name: 'Rejected Only' },
        UNASSIGNED_REJECTED: { sid: 'UNASSIGNED_REJECTED', name: 'Unassigned and Rejected' },
      },
    },
    TASKS: {
      RESULTS: {
        POLICY: {
          ONE_TO_ONE: { sid: 'ONE_TO_ONE', name: 'One to One' },
          ONE_TO_ANY: { sid: 'ONE_TO_ANY', name: 'One to Any' },
        },
      },
      CREDITS: {
        POLICY: {
          PER_TASK: { sid: 'PER_TASK', name: 'Per Task' },
          PER_SEGMENT: { sid: 'PER_SEGMENT', name: 'Per Segment' },
          PER_WORD: { sid: 'PER_WORD', name: 'Per Word' },
        },
      },
    },
  };

  static TEMPLATE = {
    CROWDSOURCE: {
      scope: Project.SCOPE.CROWDSOURCING.sid,
      SETTINGS: {
        visibility: Project.SETTINGS.VISIBILITY.PRIVATE.sid,
        membershipRequestPolicy: Project.SETTINGS.MEMBERSHIP_REQUEST_POLICY.DENY.sid,
        reviewMethod: Project.SETTINGS.REVIEW_METHOD.APPROVE_ALL.sid,
        paymentsPolicy: Project.SETTINGS.PAYMENTS.POLICY.MANUAL.sid,
        paymentsMinPayableTaskEvaluationStatus:
          Project.SETTINGS.PAYMENTS.MIN_PAYABLE_TASK_EVALUATION_STATUS.PASSED.sid,
        segmentChunkPolicy: Project.SETTINGS.SEGMENT_CHUNK.POLICY.PER_SEGMENT.sid,
        segmentChunkSource: Project.SETTINGS.SEGMENT_CHUNK.SOURCE.UNASSIGNED.sid,
        segmentChunkMin: 10,
        segmentChunkMax: 10,
        segmentChunkAutoReChunk: false,
        primaryStageTaskTypeSid: TASK.TYPE.TRANSLATION.sid,
        primaryStageTaskResultsPolicy: Project.SETTINGS.TASKS.RESULTS.POLICY.ONE_TO_ONE.sid,
        primaryStageTaskTimeout: 86400,
        primaryStageTaskWorkerInstructions: null,
        primaryStageTaskCreditsPolicy: Project.SETTINGS.TASKS.CREDITS.POLICY.PER_TASK.sid,
        primaryStageTaskCredits: 1,
        primaryStageTaskSubmitEvaluation: null,
        primaryStageTaskEvaluationPassThreshold: 0,
      },
    },
    WORKER_VALIDATION: {
      scope: Project.SCOPE.WORKER_VALIDATION.sid,
      SETTINGS: {
        visibility: Project.SETTINGS.VISIBILITY.PRIVATE.sid,
        membershipRequestPolicy: Project.SETTINGS.MEMBERSHIP_REQUEST_POLICY.APPROVE_ALL.sid,
        reviewMethod: Project.SETTINGS.REVIEW_METHOD.TAUS_TES.sid,
        paymentsPolicy: Project.SETTINGS.PAYMENTS.POLICY.NO_PAYMENT.sid,
        paymentsMinPayableTaskEvaluationStatus:
          Project.SETTINGS.PAYMENTS.MIN_PAYABLE_TASK_EVALUATION_STATUS.NOT_RISKY.sid,
        segmentChunkPolicy: Project.SETTINGS.SEGMENT_CHUNK.POLICY.PER_SEGMENT.sid,
        segmentChunkSource: Project.SETTINGS.SEGMENT_CHUNK.SOURCE.UNASSIGNED.sid,
        segmentChunkMin: 1,
        segmentChunkMax: 1000000000,
        segmentChunkAutoReChunk: false,
        primaryStageTaskTypeSid: TASK.TYPE.TRANSLATION.sid,
        primaryStageTaskResultsPolicy: Project.SETTINGS.TASKS.RESULTS.POLICY.ONE_TO_ONE.sid,
        primaryStageTaskTimeout: 86400,
        primaryStageTaskWorkerInstructions: null,
        primaryStageTaskCreditsPolicy: Project.SETTINGS.TASKS.CREDITS.POLICY.PER_TASK.sid,
        primaryStageTaskCredits: 0,
        primaryStageTaskSubmitEvaluation: null,
        primaryStageTaskEvaluationPassThreshold: 50,
      },
    },
  };

  static getUserPermission(project = null) {
    return Array.isArray(project?.permissions) ? project.permissions : [];
  }
}
