import React from 'react';
import { SourceSegmentSingle } from './SegmentView';

const SourceSegment = ({ task, segment, description, className }) => {
  let source = { ...segment.source };
  if (!!task?.glossary) source.glossary = task.glossary;
  if (!!description) source.metadata.description =  description;

  return <SourceSegmentSingle className={className} source={source} />;
};

export default SourceSegment;
