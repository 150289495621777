import { put } from '../http';

const update = async (segment = null) => {
  if (!segment) {
    throw new Error('Invalid segment (missing)');
  }
  if (!segment?.id) {
    throw new Error('Invalid segment (missing id)');
  }

  return put(`/api/segment/${segment.id}`, segment);
};

const updateAll = async (segments = []) => {
  if (!Array.isArray(segments) || segments?.length === 0)
    throw new Error('Invalid segments (missing)');

  return put(`/api/segments`, segments);
};

const SegmentController = { update, updateAll };

export default SegmentController;
