import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import MetadataController from '../../../controllers/MetadataController';
import Notifier from '../../../components/Notifier';
import UserController from '../../../controllers/UserController';
import UserReferralCodeController from '../../../controllers/UserReferralCodeController'
import PersonalProfileFormView from '../views/PersonalProfileFormView';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { PAGES } from '../../index';
import '../styles/RegisterPage.scss';
import ErrorBoundary from '../../../components/ErrorBoundary'
import ButtonSpinner from '../../../components/ButtonSpinner'
import GoogleLogin from '../../../components/GoogleLogin'
import FacebookLogin from '../../../components/FacebookLogin'

class RegisterPageState extends PureComponent {
  state = {
    isLoading: true,
    languages: [],
    countries: [],
    referrer: null,
    referrerCodeFound: false,
    showModal: false,
    errors: {},
    signUpWithEmail: false,
  };

  componentDidMount() {
    this.loadServerData();
  }

  async loadServerData() {
    try {
      this.setState({ isLoading: true });
      const queryParams = new URLSearchParams(window.location.search)
      const refCode = queryParams.get("ref")
      if (refCode !== null) {
        this.setState({referrerCodeFound: true})
        const referrer = await this.getReferrerWithCode(refCode);
        if (referrer !== null) this.setState({referrer: referrer, signUpWithEmail: true})
      }
      const [languages, countries] = await Promise.all([this.getLanguages(), this.getCountries()]);
      this.setState({
        languages: languages,
        countries: countries,
      });
    } catch (e) {
      this.errorHandler(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async getLanguages() {
    return MetadataController.getLanguages();
  }

  async getCountries() {
    return [];
  }

  async getReferrerWithCode(refCode){
    return UserReferralCodeController.findByCode(refCode)
  }

  async onRegisterButtonClick(data = null) {
    try {
      if (!data) return null;
      this.setState({ isLoading: true });
      await UserController.register(data);
      if (this.state.referrer || this.state.referrer !== null) {
        const refferalRelation = {
          "referralCodeId": this.state.referrer.id,
          "refereeSid": data.email
        }
        await UserReferralCodeController.createReferee(refferalRelation);
      }
      this.setState({ showModal: true });
    } catch (e) {
      this.errorHandler(e, true);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleClearApiErrors(key) {
    this.setState((prevState) => {
      if (!prevState.errors[key]) {
        return prevState;
      }

      const errors = { ...prevState.errors };
      delete errors[key];
      return { errors };
    });
  }

  handleButtonClick() {
    this.setState((prevState) => {
      return { signUpWithEmail: !prevState.signUpWithEmail };
    });
  }



  render() {
    try {
      if (this.state.referrerCodeFound && !this.state.referrer){
        return (
          <div className='register-page-wrapper'>
            <div className='container mt-2 form-wrapper'>
              <h1 className='title'>HLP user registration form</h1>
              The referral link you requested does not exist or is not active any more. You can still register directly to the HLP platform via this&nbsp;
              <a href={ PAGES.USER_REGISTER.path() }>
                link
              </a>
              .
            </div>
          </div>);
      }
      else {
        if (this.state?.signUpWithEmail) {
          return (
            <div className='register-page-wrapper'>
              <div className='container mt-2 form-wrapper'>
                {(!this.state.referrer) && (
                  <div className={ 'tw-float-right tw-mb-6 tw-cursor-pointer tw-font-medium' } onClick={ this.handleButtonClick.bind(this) }>
                    <span className={'tw-text-blue-500'}>Back to SignUp</span>
                  </div>
                )}
                <h1 className='title'>HLP user registration form</h1>
                <div>
                  { (this.state.referrer) ?
                    (<h3>
                        You have been invited to join HLP!
                      </h3>
                    ) : null
                  }
                </div>

                <PersonalProfileFormView
                  languages={ this.state.languages }
                  isLoading={ this.state?.isLoading }
                  referrer={ this.state?.referrer }
                  isRegistration={ true }
                  onSave={ this.onRegisterButtonClick.bind(this) }
                  apiErrors={ this.state.errors }
                  clearApiErrors={ this.handleClearApiErrors.bind(this) }
                />
              </div>

              { this.state.showModal ? (
                <Modal
                  contentClassName='border-0'
                  size='lg'
                  ref={ (ref) => {
                    this.modal = ref;
                  } }
                  isOpen={ this.state.showModal }
                  toggleModal={ this.toggleModal.bind(this) }
                >
                  <div className=' modal-header bg-taus text-white text-center font-weight-bold'>
                    <h4 className='w-100 m-0 mt-1'>
                      <span className='ml-4'>Registration success</span>
                    </h4>
                    <button className='close text-white' onClick={ this.toggleModal.bind(this) }>
                      &times;
                    </button>
                  </div>
                  <ModalBody>
                    <h3>Please click the activation link we sent to your email</h3>
                    <p>You have successfully completed your account registration.</p>
                    <p>
                      An email has been sent to your email address containing an activation link.
                      Please
                      click on the link to activate your account.
                    </p>
                    <p>
                      If you do not click the link your account will remain inactive and you will
                      not
                      be
                      able to login.
                    </p>
                    <p>
                      If you do not receive the email within a few minutes, please check your spam
                      folder.
                    </p>
                  </ModalBody>
                  <ModalFooter
                    className='d-flex flex-row justify-content-between align-items-start modal-footer'>
                    <div className='col-6 px-0'></div>
                    <div className='col-6 text-right'>
                      <button className='btn btn-outline-secondary'
                              onClick={ this.toggleModal.bind(this) }>
                        Close
                      </button>
                    </div>
                  </ModalFooter>
                </Modal>
              ) : null }
            </div>
          );
        } else {
          return(
            <div className='tw-bg-white tw-max-w-lg tw-p-6 tw-mx-auto tw-border tw-border-solid tw-border-gray-300 tw-rounded'>
              <h2 className='tw-text-black tw-mb-6'>Sign Up</h2>
              <div className='tw-grid tw-gap-4'>
                <div
                  className={`taus-btn-primary tw-relative tw-inline-flex tw-flex-row tw-items-center tw-px-5 tw-py-4 tw-transition tw-duration-100 tw-ease-in-out tw-border tw-rounded tw-cursor-pointer ${
                    this.state?.isLoading ? 'tw-bg-[#3b5998] tw-bg-opacity-80 tw-cursor-default' : 'hover:tw-bg-opacity-100'
                  }`}
                  onClick={this.handleButtonClick.bind(this)}
                >
                  <span className='tw-text-gray-100'> Sign Up with email and password</span>
                  {this.state?.isLoading && <ButtonSpinner />}
                </div>
                <GoogleLogin title={"Sign Up"} />
                <FacebookLogin title={"Sign Up"} />
              </div>
            </div>
          );
        }
      }
    } catch (e) {
      this.errorHandler(e);
      return <ErrorBoundary hasError={true} errorMessage='Api error! failed to render the page!' />;
    }
  }

  errorHandler(e = null, showMessage = true) {
    if (showMessage) {
      if (!!e?.userMessage) Notifier.error(e.userMessage);
      else if (!!e?.message) Notifier.error(e.message);
      else Notifier.error(e);
    } else {
      const errors = JSON.parse(e.message);
      this.setState({ errors });
    }
  }

  toggleModal() {
    try {
      this.setState({
        showModal: !this.state.showModal,
        isLoading: true,
      });

      setTimeout(() => {
        this.props.history.push(PAGES.HOME_PAGE.path());
      }, 500);
    } catch (e) {
      this.errorHandler(e);
    }
  }
}

export default withRouter(RegisterPageState);
