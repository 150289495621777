import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { PAGES } from '..';
import Spinner from '../../components/Spinner';
import ProjectController from '../../controllers/ProjectController';
import TaskController from '../../controllers/TaskController';
import TestLandingPage from './views/TestLandingPage';
import TaskResultController from '../../controllers/TaskResultController';
import Notifier from '../../components/Notifier';

const getProject = (id) => ProjectController.getProject(id);

const getTask = (projectId) => {
  return TaskController.getAll({ projectId }).then((res) => res?.page?.[0] ?? null);
};

const getWorkerTaskResult = (projectId, userId) => {
  return TaskResultController.getAll({ projectId, userId }).then((res) => res?.page?.[0] ?? null);
};

const requestProjectMembership = (id) => ProjectController.createMembershipRequest(id);

const requestTask = (id) => TaskController.request(id);

const startWorkerTest = async (projectId) => {
  // If worker is already a project member.
  let task = await getTask(projectId);
  if (!task?.id) {
    // If worker is not a member, then request to be a member and get the task.
    await requestProjectMembership(projectId);
    task = await getTask(projectId);
  }
  return await requestTask(task.id);
};

const TestLandingPageContainer = () => {
  const user = useSelector((state) => state?.session?.user ?? null);
  const { id } = useParams();
  const projectId = Number.parseInt(id);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState();

  const redirectToTask = useCallback(
    (id) => history.replace(PAGES.TASK_RESULT_PAGE.path(id)),
    [history]
  );

  const handleStartButtonClick = async () => {
    setLoading(true);
    try {
      const taskResult = await startWorkerTest(projectId);
      redirectToTask(taskResult.id);
    } catch (error) {
      Notifier.error('Unknown server error!');
      // TODO: handle this with a standardized error handling (not implemented yet)
      setLoading(false);
    }
  };

  useEffect(() => {
    const onLoad = async () => {
      try {
        if (user) {
          const taskResult = await getWorkerTaskResult(projectId, user.id);
          if (taskResult) {
            redirectToTask(taskResult.id);
          } else {
            const project = await getProject(projectId);
            setProject(project);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } catch (e) {
        Notifier.error('Unknown server error!');
        // TODO: handle this with a standardized error handling (not implemented yet)
        setLoading(false);
      }
    };

    onLoad();
  }, [user, projectId, redirectToTask]);

  return loading ? (
    <Spinner />
  ) : (
    <TestLandingPage test={project} onStart={handleStartButtonClick} isSignedIn={!!user} />
  );
};

export default TestLandingPageContainer;
