import { PAGES } from '.';
import User from '../models/User';
import Utils from '../utils/Utils';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import above_fold_img from '../media/hlp-banner-hp-smaller.png';

const Home = () => {
  const history = useHistory();
  const user = useSelector((state) => Utils.define(state?.session?.user));

  useEffect(() => {
    if (User.isAdmin(user)) {
      history.push(PAGES.PROJECTS_ADMIN_PAGE.path());
    }
  }, [history, user]);

  return (
    <div id='content-wrap' className='taus-content-wrap'>
      <div className='container col-xs-12'>
        <div
          className='col-xs-12 taus-vertical-align'
          style={{ overflow: 'hidden', padding: '40px 0' }}
        >
          <div className='col-xs-12 col-md-6 '>
            <img src={above_fold_img} alt='TAUS HLP' />
          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='col-xs-12'>
              <h1>
                Welcome to the Human <br /> Language Project
              </h1>
              <br />
              <p style={{ fontSize: 16 }}>
                Thanks for joining our community of crowdworkers! You can check our available{' '}
                <Link to={PAGES.PUBLIC_PROJECTS_LISTING_PAGE.path()}>projects</Link> and apply to
                those matching your skills. Or claim more work for projects where you're already a
                member.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
