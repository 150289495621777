import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { PAGES } from '../index';
import EnhancedFileForm from './FileForm';
import EnhancedContentForm from './ContentForm';
import MetadataController from '../../controllers/MetadataController';
import FileController from '../../controllers/FileController';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import USER from '../../models/User';
import USER_PERMISSION from '../../models/UserPermission';
import ErrorHandler from '../../utils/ErrorHandler';

class CreateFilePage extends React.Component {
  constructor(props) {
    super(props);

    // Permissions
    let user = this.props?.user;
    this.hasCreatePermission = USER.hasAnyPermission(user, USER_PERMISSION.TYPES.FILE, ['CREATE']);
    this.hasEditPermission = USER.hasAnyPermission(user, USER_PERMISSION.TYPES.FILE, ['EDIT']);
    this.hasSegmentCreatePermission = USER.hasAnyPermission(user, USER_PERMISSION.TYPES.SEGMENT, [
      'CREATE',
    ]);
    this.state = {
      key: '1',
      file: props.location.state ? props.location.state.file : null,
      languages: [],
      sourceFiles: [],
      languagesLoading: false,
      sourceFilesLoading: false,
    };

    this.isEditMode = !!this.state.file;

    this.onFileCreated = this.onFileCreated.bind(this);
    this.onFileUpdated = this.onFileUpdated.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  componentDidMount() {
    // Set all static selectors to loading state
    this.setState({ languagesLoading: true });

    // Get languages
    MetadataController.getLanguages()
      .then((result) => this.setState({ languages: result }))
      .catch((error) => ErrorHandler.errorHandle(error))
      .finally(() => this.setState({ languagesLoading: false }));

    if (!this.isEditMode) {
      this.getSourceFiles();
    }
  }

  getSourceFiles() {
    this.setState({ sourceFilesLoading: true });
    FileController.getAll({ pageSize: 1000 })
      .then((result) => this.setState({ sourceFiles: result?.page ?? [] }))
      .catch((error) => ErrorHandler.errorHandle(error))
      .finally(() => this.setState({ sourceFilesLoading: false }));
  }

  onFileCreated(file) {
    this.getSourceFiles();
    this.props.history.push(PAGES.FILE_EDIT_PAGE.path(file.id), { file });
  }

  onFileUpdated(file) {
    this.setState(() => ({ file: file }));
  }

  refreshFile = (id) => {
    FileController.get(id)
      .then((result) => this.setState({ file: result }))
      .catch((error) => ErrorHandler.errorHandle(error));
  };

  onBack() {
    this.props.history.push(PAGES.FILES_PAGE.path());
  }

  render() {
    let header = this.isEditMode ? this.state.file.name : 'Create File';
    let subHeader = this.isEditMode
      ? "Edit the file's name and language. You can also upload extra content and download the file's contents from here."
      : "After creating the file, you will be redirected to a page to upload it's content";

    let TabFileView;
    if (
      (!this.isEditMode && this.hasCreatePermission) ||
      (this.isEditMode && this.hasEditPermission)
    )
      TabFileView = (
        <EnhancedFileForm
          isEditMode={this.isEditMode}
          file={this.state.file}
          languages={this.state.languages}
          languagesLoading={this.state.languagesLoading}
          onFileCreated={this.onFileCreated}
          onFileUpdated={this.onFileUpdated}
        />
      );

    let TabContentView;
    if (this.hasSegmentCreatePermission && this.isEditMode)
      TabContentView = (
        <EnhancedContentForm
          isEditMode={this.isEditMode}
          file={this.state.file}
          languages={this.state.languages}
          languagesLoading={this.state.languagesLoading}
          sourceFiles={this.state.sourceFiles}
          filesLoading={this.state.sourceFilesLoading}
          refreshFile={this.refreshFile}
        />
      );

    return (
      <div className='container mt-2'>
        <div className='jumbotron'>
          <Row>
            <Col md='9'>
              <h1>{header}</h1>
            </Col>
            <Col md='3'>
              <Button className={'float-right'} color='secondary' size='lg' onClick={this.onBack}>
                Back
              </Button>
            </Col>
          </Row>
          <p className={'text-muted'}>{subHeader}</p>
          <hr />
          {TabFileView}
          {TabContentView}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state = {}, ownProps) => ({ ...ownProps, user: state?.session?.user });
export default connect(mapStateToProps, null)(withRouter(CreateFilePage));
