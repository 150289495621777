// Core
import React, { Component } from 'react'
// Dependencies
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
// Components
import PaymentDetailsDisplayView from './payment-details-display/PaymentDetailsDisplayView'
import PaymentDetailsFormView from './payment-details-form/PaymentDetailsFormView'
// Models
import UserProfile from '../../../../models/UserProfile'
// Utils
import CountryHelper from '../../../../utils/CountryHelper'
// Models
import PaymentFormValidation from '../../../../models/PaymentFormValidation'
import PaypalController from '../../../../controllers/PaypalController'


class UserPaymentInfoView extends Component {
  constructor (props) {
    super(props)

    this.handleCountryChange = this.handleCountryChange.bind(this)
    this.handleBankChange = this.handleBankChange.bind(this)
    this.toggleEditVisibility = this.toggleEditVisibility.bind(this)
    this.setUpStripeOnboarding = this.setUpStripeOnboarding.bind(this)
    this.disconnectFromStripe = this.disconnectFromStripe.bind(this)
  }

  state = {
    isEditable: false,
    selectedCountry: {},
    selectedCountryRequiredFields: [],
    selectedBank: {},
    selectedBranch: {},
    selectedRegion: {},
    selectedState: {},
    ibanCountries: [],
    ibanLength: null,
    transferWiseTransactionType: [],
    transferWiseTransactionTypeOptions: [],
    bankRoutingNumbersUS: [],
    errorDisplay: null,
  }

  componentDidMount () {
    // bankTransferOptions is being used to extract the IBAN length from the server response,
    // even though there is no support currently for BankTransfer transactions on the UI
    const {
      vatStatus,
      legalType,
      countryCode,
      bankCode,
      branchCode,
      region,
      state,
      transferWiseOptions,
      bankTransferOptions,
    } = this.props.paymentInfo || {}

    const {
      transferWiseBanks,
      transferWiseBankBranches,
      transferWiseRegions,
      transferWiseStates,
      hasReturnedFromStripe,
      isStripeOnBoardingComplete,
    } = this.props || {}

    const country = CountryHelper.getCountry(countryCode)
    const countryName = country ? country.name : null

    const transferWiseTransactionType = CountryHelper.setTransferWiseTransactionType(
      transferWiseOptions?.supportedCountries,
      countryCode,
    )

    if (transferWiseTransactionType && transferWiseTransactionType.length > 1) {
      const options = CountryHelper.setTransferWiseTransactionTypeOptions(
        transferWiseTransactionType,
      )
      this.setState({
        transferWiseTransactionTypeOptions: options,
      })
    }
    else {
      this.setState({
        transferWiseTransactionTypeOptions: [],
      })
    }

    const ibanCountries = [
      ...bankTransferOptions?.mandatoryIbanCountries,
      ...bankTransferOptions?.recommendedIbanCountries,
    ]
    const ibanLength = CountryHelper.setIbanSupportedLength(countryCode, ibanCountries)

    const bankName = transferWiseBanks.find((bank) => bank.code === bankCode)?.title || null
    const branchName =
      transferWiseBankBranches.find((branch) => branch.code === branchCode)?.title || null
    const regionName = transferWiseRegions.find((rg) => rg.key === region)?.name || null
    const stateName = transferWiseStates.find((st) => st.code === state)?.name || null

    const selectedCountryRequiredFields = transferWiseOptions?.supportedCountries.find(
      (country) => country.countryCode === countryCode,
    )?.requiredFields

    this.setState({
      vatStatus,
      legalType,
      ibanCountries,
      ibanLength,
      transferWiseTransactionType,
      selectedCountry: {
        value: countryCode,
        label: countryName,
      },
      selectedCountryRequiredFields: selectedCountryRequiredFields,
      selectedBank: {
        value: bankCode,
        label: bankName,
      },
      selectedBranch: {
        value: branchCode,
        label: branchName,
      },
      selectedRegion: {
        value: region,
        label: regionName,
      },
      selectedState: {
        value: state,
        label: stateName,
      },
      bankRoutingNumbersUS: transferWiseOptions.bankRoutingNumbersUS,
    })

    if (hasReturnedFromStripe) {
      this.setState({ isEditable: true })

      const stripeToasterContent = isStripeOnBoardingComplete
        ? 'You have unsaved changes. Please submit your payment details'
        : 'Stripe connection failed. You need to complete your account set up in order to accept payments with Stripe'

      toast.warn(stripeToasterContent, {
        position: 'top-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }

    const authorizationCode = this.getQueryVariable('code')

    if (authorizationCode) {
      PaypalController.getUserDetails(authorizationCode).then(res => {
          if (res.emailVerified) {
            this.props.paymentInfo.method = 'PayPal'
            this.props.paymentInfo.email = res.email
            this.props.paymentInfo.name = res.name
            this.props.paymentInfo.addressLine = res.streetAddress + ' ' + res.locality
            this.props.paymentInfo.city = res.region
            this.props.paymentInfo.zipCode = res.postalCode
            this.props.paymentInfo.countryCode = res.country
            this.props.paymentInfo.country  = CountryHelper.getCountry(res.country).name
            this.setState({ isEditable: true })
          }
          else {
            this.setState(
              { errorDisplay: 'Please verify your email address in Paypal and then try again!!' })
          }
        },
      ).catch(err => {
        this.setState({ errorDisplay: 'Something went wrong. Please try again later!!' })
        console.log(err)
      })
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.paymentInfo.region !== this.props.paymentInfo.region) {
      const { region } = this.props.paymentInfo
      const { transferWiseRegions } = this.props

      const regionName = transferWiseRegions.find((rg) => rg.key === region)?.name || null

      this.setState({
        selectedRegion: {
          value: region,
          label: regionName,
        },
      })
    }
    if (prevProps.paymentInfo.state !== this.props.paymentInfo.state) {
      const { state } = this.props.paymentInfo
      const { transferWiseStates } = this.props

      const stateName = transferWiseStates.find((st) => st.code === state)?.name || null

      this.setState({
        selectedState: {
          value: state,
          label: stateName,
        },
      })
    }
    if (prevProps.paymentInfo.bankCode !== this.props.paymentInfo.bankCode) {
      const { bankCode, branchCode } = this.props.paymentInfo
      const { transferWiseBanks, transferWiseBankBranches } = this.props

      const bankName = transferWiseBanks.find((bank) => bank.code === bankCode)?.title || null

      const branchName =
        transferWiseBankBranches.find((branch) => branch.code === branchCode)?.title || null

      this.setState({
        selectedBank: {
          value: bankCode,
          label: bankName,
        },
        selectedBranch: {
          value: branchCode,
          label: branchName,
        },
      })
    }
  }

  /* Switch between form and display views */
  toggleEditVisibility () {
    this.setState((prevState, props) => {
      // We need to manually reset the country state when closing the edit screen
      if (prevState.isEditable) {
        const { countryCode } = props.paymentInfo
        const country = CountryHelper.getCountry(countryCode)
        const countryName = country ? country.name : null
        return {
          isEditable: !prevState.isEditable,
          selectedCountry: {
            value: countryCode,
            label: countryName,
          },
        }
      }
      return { isEditable: !prevState.isEditable }
    })
  }

  /* Get the values from the form's select input event  and set the local state */
  handleCountryChange (country, transferWiseTransactionType, ibanLength, method) {
    this.setState({
      selectedCountry: country,
      transferWiseTransactionType: transferWiseTransactionType,
      ibanLength,
    })

    if (transferWiseTransactionType.length > 1) {
      const options = CountryHelper.setTransferWiseTransactionTypeOptions(
        transferWiseTransactionType,
      )
      this.setState({
        transferWiseTransactionTypeOptions: options,
      })
    }
    else {
      this.setState({
        transferWiseTransactionTypeOptions: [],
      })
    }

    if (method === 'TransferWise') {
      const selectedCountry = this.props.transferWiseSupportedCountries.find(
        (cntry) => cntry.countryCode === country.value,
      )

      this.setState({
        selectedCountryRequiredFields: selectedCountry.requiredFields,
      })

      if (selectedCountry.requiredFields.includes('bankCode')) {
        this.props.onGetTransferWiseBanks(country.value)
      }

      if (selectedCountry.requiredFields.includes('region')) {
        this.props.onGetTransferWiseRegions(selectedCountry.currencyCode)
      }

      if (selectedCountry.requiredFields.includes('state')) {
        this.props.onGetTransferWiseStates(selectedCountry.countryCode)
      }
    }
  }

  handleBankChange (countryCode, bankCode) {
    const selectedCountry = this.props.transferWiseSupportedCountries.find(
      (country) => country.countryCode === countryCode,
    )

    if (selectedCountry.requiredFields.includes('branchCode')) {
      this.props.onGetTransferWiseBankBranches(countryCode, bankCode)
    }
  }

  setUpStripeOnboarding () {
    this.props.onSetUpStripeOnboarding()
  }

  disconnectFromStripe () {
    this.props.onDisconnectFromStripe()
  }

  getQueryVariable (variable) {
    const search = window.location.search
    const searchParams = new URLSearchParams(search)
    return searchParams.get(variable)
  }

  render () {
    const {
      isEditable,
      selectedCountry,
      selectedBank,
      selectedBranch,
      selectedRegion,
      selectedState,
      ibanCountries,
      ibanLength,
      transferWiseTransactionType,
      transferWiseTransactionTypeOptions,
      // errorDisplay
    } = this.state
    const {
      userEmail,
      paymentInfo,
      stripeEmail,
      isStripeOnBoardingComplete,
      transferWiseBanks,
      transferWiseBankBranches,
      transferWiseRegions,
      transferWiseStates,
      transferWiseSupportedCountries,
      // onUpdatePaymentInfo,
      // onInitializeUserPaymentDetails,
      // onValidateTransferWiseFields
      isDisconnectingFromStripe,
    } = this.props || {}

    const validationSchema = PaymentFormValidation.SCHEMA

    const initialValues = {
      // paypalEmail: paymentInfo?.paypalEmail ? paymentInfo.paypalEmail : '',
      addressLine: paymentInfo?.addressLine ?? '',
      name: paymentInfo?.name ?? '',
      city: paymentInfo?.city ?? '',
      legalType: paymentInfo?.legalType ?? '',
      vatNumber: paymentInfo?.vatNumber ?? '',
      cocNumber: paymentInfo?.cocNumber ?? '',
      vatStatus: paymentInfo?.vatStatus ?? '',
      iban: paymentInfo?.iban ?? '',
      ibanLength: ibanLength ?? '',
      ifscCode: paymentInfo?.ifscCode ?? '',
      accountType: paymentInfo?.accountType ?? '',
      abartn: paymentInfo?.abartn ?? '',
      bsbCode: paymentInfo?.bsbCode ?? '',
      accountNumber: paymentInfo?.accountNumber ?? '',
      bank: selectedBank?.label ?? '',
      bankCode: paymentInfo?.bankCode ?? '',
      branch: selectedBranch?.label ?? '',
      branchCode: paymentInfo?.branchCode ?? '',
      region: selectedRegion?.label ?? '',
      regionCode: paymentInfo?.region ?? '',
      state: selectedState?.label ?? '',
      stateCode: paymentInfo?.state ?? '',
      transferwiseType: paymentInfo?.transferwiseType ?? '',
      transferWiseTypeOptions: transferWiseTransactionTypeOptions ?? [],
      transferWiseTransactionTypeOptions: this.state.transferWiseTransactionTypeOptions ?? [],
      zipCode: paymentInfo?.zipCode ?? '',
      agreedToPaymentTerms: false,
      method: paymentInfo?.method ?? '',
      isConnected: !!paymentInfo?.stripeId && isStripeOnBoardingComplete,
      country: selectedCountry?.label ?? '',
      countryCode: paymentInfo?.countryCode ?? '',
      transferwiseEmail: paymentInfo?.transferwiseEmail ?? '',
      swift: paymentInfo?.swift ?? '',
      email: paymentInfo?.email ?? '',
      dateOfBirth: paymentInfo?.dateOfBirth
        ? UserProfile.getFormattedDate(paymentInfo.dateOfBirth)
        : '',
    }

    /* Call the onValidateTransferWiseFields and on success call onUpdatePaymentInfo
     * with the form values to update the state on UserProfile component
     * */
    const onSubmit = async(values, { setSubmitting, setFieldError }) => {
      try {
        await this.props.onValidateTransferWiseFields(values).catch((e) => {
          throw e
        })
        await this.props.onUpdatePaymentInfo(values).catch((e) => {
          throw e
        })
        this.props.onInitializeUserPaymentDetails().then(() => {
          this.setState({ isEditable: false })
          toast.success('Payment details have been successfully updated.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        })
      }
      catch (error) {
        let errorMessage

        if (error.code === 708) {
          errorMessage = error.message
          // Set VAT field error
          setFieldError('vatNumber', 'Please add a valid VAT number')
        }
        else if (error.path) {
          errorMessage = `TransferWise: ${ error.message }`
          let field = error.path === 'IBAN' ? 'iban' : error.path
          // Set TransferWise fields error
          setFieldError(`${ field }`, `Please add a valid ${ error.path }`)
        }

        // Submission failure toaster
        if (errorMessage) {
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }
      finally {
        setSubmitting(false)
      }
    }

    const isPaymentDetailsEditable = !userEmail.includes('taus-hlp.net')

    return (
      <div className='tw-p-5'>
        {/* Box Action Icon */ }
        { isPaymentDetailsEditable && (
          <div className='box-action box-icon' onClick={ this.toggleEditVisibility }>
            <span className={ `fas ${ isEditable ? 'close-button' : 'edit-button' }` }></span>
          </div>
        ) }

        <span
          style={ {
            display: 'block',
            fontSize: '18px',
            lineHeight: '28px',
            letterSpacing: '0.3px',
            fontWeight: '500',
            color: '#1c1c28',
            marginBottom: '12px',
          } }
        >
          Payment Information
        </span>
        { isEditable ? (
          <Formik
            { ...{
              initialValues,
              validationSchema,
              onSubmit,
            } }
          >
            { ({
              values,
              getFieldProps,
              setFieldValue,
              isSubmitting,
              handleSubmit,
              handleReset,
              handleChange,
              touched,
              errors,
            }) => (
              <PaymentDetailsFormView
                values={ values }
                getFieldProps={ getFieldProps }
                setFieldValue={ setFieldValue }
                onHandleSubmit={ handleSubmit }
                onHandleReset={ handleReset }
                onHandleChange={ handleChange }
                touched={ touched }
                errors={ errors }
                onSetUpStripeOnboarding={ this.setUpStripeOnboarding }
                onDisconnectFromStripe={ this.disconnectFromStripe }
                onHandleCountryChange={ this.handleCountryChange }
                onHandleBankChange={ this.handleBankChange }
                isSubmitting={ isSubmitting }
                selectedCountryRequiredFields={ this.state.selectedCountryRequiredFields }
                transferWiseSupportedCountries={ transferWiseSupportedCountries }
                transferWiseTransactionType={ transferWiseTransactionType || '' }
                transferWiseTransactionTypeOptions={ transferWiseTransactionTypeOptions || '' }
                ibanCountries={ ibanCountries }
                transferWiseBanks={ transferWiseBanks }
                transferWiseBranches={ transferWiseBankBranches }
                transferWiseRegions={ transferWiseRegions }
                transferWiseStates={ transferWiseStates }
                stripeEmail={ stripeEmail }
                bankRoutingNumbersUS={ this.state.bankRoutingNumbersUS }
                isDisconnecting={ isDisconnectingFromStripe }
              />
            ) }
          </Formik>
        ) : (
          <PaymentDetailsDisplayView
            isPaymentDetailsEditable={ isPaymentDetailsEditable }
            paymentInfo={ this.props.paymentInfo }
            selectedCountryRequiredFields={ this.state.selectedCountryRequiredFields }
            stripeEmail={ stripeEmail }
            onToggleVisibility={ this.toggleEditVisibility }
            country={ selectedCountry?.label || '' }
            bank={ selectedBank?.label || '' }
            branch={ selectedBranch?.label || '' }
            errorDisplay={ this.state.errorDisplay }
          />
        ) }
      </div>
    )
  }
}

UserPaymentInfoView.propTypes = {
  userEmail: PropTypes.string.isRequired,
  paymentInfo: PropTypes.object.isRequired,
  stripeEmail: PropTypes.string.isRequired,
  isStripeOnBoardingComplete: PropTypes.bool.isRequired,
  isDisconnectingFromStripe: PropTypes.bool.isRequired,
  onSetUpStripeOnboarding: PropTypes.func.isRequired,
  onValidateTransferWiseFields: PropTypes.func.isRequired,
  onDisconnectFromStripe: PropTypes.func.isRequired,
  onUpdatePaymentInfo: PropTypes.func.isRequired,
  onInitializeUserPaymentDetails: PropTypes.func.isRequired,
}
export default UserPaymentInfoView
