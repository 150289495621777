import React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import UserFilters from './UserFilters';
import CreatableSelect from 'react-select/creatable';
import UserPreviewTable from './UserPreviewTable';
import Utils from '../../../utils/Utils';
import UserController from '../../../controllers/UserController';
import { debounce } from 'throttle-debounce';
import InputWithValidation from '../../../components/forms/InputWithValidation'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '../../../components/Tooltip';

const CriteriaBuilder = ({
  forceUpdate,
  criteriaOptions,
  isRowDisabled,
  onAddUser,
  onSaveCriteria,
  onChangeCriteria,
  onRemoveCriteria,
  selectedCriteria,
  selectedCriteriaFilters = {},
  isLoadingOptions,
  languageOptions,
  languageLevelOptions,
  userEthnicGroupOptions,
  userGenderOptions,
  userDialectOptions,
  geolocationOptions,
  yearOfBirthOptions,
  testOptions,
  evaluationScoreOptions,
  handleCriteriaNameChange
}) => {
  const [basicDropdownOpen, setBasicDropdownOpen] = useState(false);
  const [testsDropdownOpen, setTestsDropdownOpen] = useState(false);
  const [demographicsDropdownOpen, setDemographicsDropdownOpen] = useState(false);
  const toggle = (setStateFn) => () => setStateFn((prevState) => !prevState);
  const [isNameEdit, setIsNameEdit] = useState(false);

  const [filters, setFilters] = useState(() => {
    let mappedCriteriaFilters = {};
    Object.keys(selectedCriteriaFilters).forEach((key) => {
      if (!Utils.isEmpty(selectedCriteriaFilters[key])) {
        if (['dialects', 'ethnicGroups', 'genders', 'languages', 'languageLevels'].includes(key)) {
          mappedCriteriaFilters[`${key.slice(0, -1)}Ids`] = selectedCriteriaFilters[key].map(
            (obj) => obj.id
          );
        } else if (
          [
            'country',
            'fullName',
            'username',
            'yearOfBirthFrom',
            'yearOfBirthTo',
            'ungroupedOnly',
          ].includes(key)
        ) {
          mappedCriteriaFilters[key] = selectedCriteriaFilters[key];
        } else if (['geolocations'].includes(key)) {
          mappedCriteriaFilters[key] = selectedCriteriaFilters[key].map((item) => item.code);
        }
      }
    });
    return mappedCriteriaFilters;
  });
  const originalFilters = useRef(filters);

  const handleChange = (name, value) => {
    if (!value || value.length === 0) {
      setFilters((prev) => {
        const newState = { ...prev };
        delete newState[name];
        return newState;
      });
    } else {
      setFilters((prev) => ({ ...prev, [name]: value }));
    }
  };

  const hasBasicFilters =
    (!!filters.username && filters.username.length > 0) ||
    (!!filters.fullName && filters.fullName.length > 0) ||
    (!!filters.country && filters.country.length > 0) ||
    (!!filters.languageIds && filters.languageIds.length > 0) ||
    (!!filters.languageLevelIds && filters.languageLevelIds.length > 0);

  const hasTestFilters =
    (!!filters.projectIds && filters.projectIds.length > 0) ||
    (filters.evaluationScore !== null && filters.evaluationScore !== undefined);

  const hasDemographicsFilters =
    (!!filters.ethnicGroupIds && filters.ethnicGroupIds.length > 0) ||
    (!!filters.genderIds && filters.genderIds.length > 0) ||
    (!!filters.dialectIds && filters.dialectIds.length > 0) ||
    (!!filters.geolocations && filters.geolocations.length > 0) ||
    !!filters.yearOfBirthFrom ||
    !!filters.yearOfBirthTo;

  const handleSaveCriteria = () => {
    originalFilters.current = filters;
    onSaveCriteria(filters);
    setIsNameEdit(false);
  };

  const [users, setUsers] = useState([]);

  const searchUsers = useMemo(
    () =>
      debounce(500, async (filters) => {
        if (Object.keys(filters).length > 0) {
          const response = await UserController.search(filters);
          setUsers(response.content);
          setTotalElements(response.totalElements);
        } else {
          setUsers([]);
        }
      }),
    []
  );

  const [pagination, setPagination] = useState({ page: 0, size: 10 });
  const [totalElements, setTotalElements] = useState(0);

  const handleCurrentPageChange = (page) => {
    setPagination((prev) => ({ ...prev, page }));
  };
  const handlePageSizeChange = (size) => {
    setPagination({ page: 0, size });
  };

  useEffect(() => {
    // this is a hack to convert filters.fullName -> filters.fullname because
    // the backend responds with the fullName as a criteria property
    // but it requires fullname query parameter in order to search the users
    // we are creating a new object because we dont want to mutate the filters state
    if (Object.keys(filters).length === 0) {
      return;
    }
    const convertedFilters = { ...filters, ...pagination };
    if (convertedFilters.fullName) {
      convertedFilters.fullname = convertedFilters.fullName;
      delete convertedFilters.fullName;
    }
    searchUsers(convertedFilters);
  }, [filters, pagination, searchUsers, forceUpdate]);

  return (
    <div>
      <h4>Criteria builder</h4>
      <div className='tw-flex tw-mb-4'>
        { !isNameEdit && (
          <CreatableSelect
          isClearable
          isValidNewOption={(inputValue) =>
            inputValue.length > 0 &&
            criteriaOptions.every((option) => !option.__isNew__ && option.label !== inputValue)
          }
          className='tw-w-96 tw-mr-4 '
          options={criteriaOptions}
          onChange={onChangeCriteria}
          value={selectedCriteria}
          allowCreateWhileLoading={false}
        /> )}
        {selectedCriteria && (
          <>
            { isNameEdit && (
              <InputWithValidation
                propertyName='criteria-name'
                defaultValue={selectedCriteria.label ?? ''}
                onChange={handleCriteriaNameChange}
                className='tw-w-96 tw-mr-4'
              />
            )}
            <button
              disabled={
                (Object.keys(filters).length === 0 ||
                JSON.stringify(originalFilters.current) === JSON.stringify(filters) )&&
                !isNameEdit
              }
              onClick={handleSaveCriteria}
              className='btn btn-primary tw-mr-2'
            >
              {selectedCriteria.__isNew__ ? 'Create' : 'Update'}
            </button>
            <button onClick={onRemoveCriteria} className='btn btn-danger tw-mr-2'>
              Remove
            </button>
            <Tooltip title="Rename Criteria">
              <button
                onClick={() => setIsNameEdit(true)}
                className='btn btn-primary'
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
            </Tooltip>
          </>
        )}
      </div>
      {selectedCriteria && (
        <>
          <UserFilters
            filters={filters}
            resetFilters={() => setFilters({})}
            handleChange={handleChange}
            basicDropdownOpen={basicDropdownOpen}
            onBasicDropdownToggle={toggle(setBasicDropdownOpen)}
            testsDropdownOpen={testsDropdownOpen}
            onTestDropdownOpen={toggle(setTestsDropdownOpen)}
            demographicsDropdownOpen={demographicsDropdownOpen}
            onDemographicsDropdownToggle={toggle(setDemographicsDropdownOpen)}
            hasBasicFilters={hasBasicFilters}
            hasTestFilters={hasTestFilters}
            hasDemographicsFilters={hasDemographicsFilters}
            isLoadingOptions={isLoadingOptions}
            languageOptions={languageOptions}
            languageLevelOptions={languageLevelOptions}
            userEthnicGroupOptions={userEthnicGroupOptions}
            userGenderOptions={userGenderOptions}
            userDialectOptions={userDialectOptions}
            geolocationOptions={geolocationOptions}
            yearOfBirthOptions={yearOfBirthOptions}
            testOptions={testOptions}
            evaluationScoreOptions={evaluationScoreOptions}
          />
          <UserPreviewTable
            users={users}
            filters={filters}
            isRowDisabled={isRowDisabled}
            onAddUser={onAddUser}
            pagination={pagination}
            totalElements={totalElements}
            onCurrentPageChange={handleCurrentPageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </>
      )}
    </div>
  );
};

export default CriteriaBuilder;
