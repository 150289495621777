import React, { useState } from 'react'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import Segment from '../../../../models/Segment';
import Task from '../../../../models/TaskResult';
import TaskResult from '../../../../models/TaskResult';

import { dialectsAU, dialectsUK, dialectsUS } from '../../../forms/amazon/schema';
import ReviewerToolbar from '../ReviewerToolbar';
import WorkerDialectView from './WorkerDialectView';
import Notifier from '../../../../components/Notifier';
import TextToSpeechReviewSegment from './TextToSpeechReviewSegment';
import SegmentController from '../../../../controllers/SegmentController';
import TaskResultController from '../../../../controllers/TaskResultController';
import { useTTSTargets } from '../../hooks/useTTSTargets';
import { base64toBlob, blobToBase64, calculateAudioLength } from '../../../../utils/Recorder'
import Language from '../../../../models/Language';
import AdminTaskResultController from '../../../../controllers/AdminTaskResultController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileZipper } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../../components/Tooltip';


const TaskTextToSpeechReviewView = ({ task, scope, isAdmin, taskIsUpdated }) => {
  const sampleRate = task?.options.sampleRate;
  const { targets, changeTargets, onTranscriptionChanged, onAudioChanged } = useTTSTargets(
    task,
    sampleRate ?? 16000
  );

  const calculateTotalRecordingTime = (segmentIds = []) => {
    let updatedSegmentsAudioLength = 0;
    for( let j=0; j < segmentIds.length ;j++) {
      const segmentId = segmentIds[j];
      updatedSegmentsAudioLength += +calculateAudioLength(targets[segmentId].audio, sampleRate);
    }
    const restAudioLength = TaskResult.getTotalRecordedTime(task.targets.filter(t =>
      !(segmentIds.map(segmentId => +segmentId).includes(t.id))
    ))
    const totalRecordingDuration = +restAudioLength + +updatedSegmentsAudioLength;
    return totalRecordingDuration;
  }

  const handleSave =
    (segmentIds = [], review) =>
    async (data = null, hasNewData = false) => {
      changeTargets(segmentIds, { loadingData: true, changed: false });
      try {
        let save;
        let reviewAttribute;
        if (scope === Task.USER_SCOPE.WORKER) {
          save = saveAsWorker;
          reviewAttribute = 'review';
        } else {
          save = saveAsLeadReviewer;
          reviewAttribute = 'status';
        }

        const totalRecordingDuration = calculateTotalRecordingTime(segmentIds);

        const segments = await Promise.all(
          segmentIds.map(async (segmentId) => {
            let segment = {
              id: parseInt(segmentId),
              [reviewAttribute]: review,
            };
            if (hasNewData && data && review !== Segment.REVIEWS.REJECTED) {
              const { transcription, audio, wpm, score, length } = data;

              segment.data = JSON.stringify({
                audio: await blobToBase64(audio),
                transcription,
                wpm,
                score,
                length,
              });
            }
            return segment;
          })
        );
        const metadata = { totalRecordingDuration : parseFloat(totalRecordingDuration.toString()) };
        const newTask = await save(task, segments, metadata);
        taskIsUpdated(newTask);
      } catch (error) {
        Notifier.error(error.message);
      } finally {
        changeTargets(segmentIds, { loadingData: false });
      }
    };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const newTask = await submitTask(task.id);
      taskIsUpdated(newTask, true);
    } catch (error) {
      if (error.message.includes('ON_HOLD'))
        Notifier.error(
          'This task is currently on hold. Some changes are in progress. Please come back later.'
        );
      else Notifier.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResubmit = async () => {
    resubmitTask(task.id)
      .then(
        () => {
          TaskResultController.get(task.id).then(
            (taskResult) => taskIsUpdated(taskResult, false),
            (error) => Notifier.error(error.message)
          );
        },
        (error) => Notifier.error(error.message)
      )
      .finally(() => () => setIsSubmitting(false));
  };

  const handleRollback = async () => {
    try {
      setIsSubmitting(true);
      const newTask = await rollbackTask(task.id);
      await taskIsUpdated(newTask, true, true);
    } catch (error) {
      Notifier.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleComplete = async () => {
    try {
      setIsSubmitting(true);
      const newTask = await completeCheck(task.id);
      taskIsUpdated(newTask, true);
    } catch (error) {
      Notifier.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const canUpdateWorkerDialect = TaskResult.getUserPermission(task).some(
    (obj) => obj === TaskResult.PERMISSIONS.DATA_EDIT || obj === TaskResult.PERMISSIONS.EDIT
  );
  const handleDialectUpdate = async (dialect) => {
    try {
      const newTask = await reviewWorker(task.id, { spokenDialect: dialect });
      taskIsUpdated(newTask, false);
    } catch (error) {
      Notifier.error(error.message);
    }
  };

  const segmentIds = task.targets.map((target) => target.id.toString());
  const toolbar = (
    <ReviewerToolbar
      task={task}
      scope={scope}
      isAdmin={isAdmin}
      handleSubmit={handleSubmit}
      handleResubmit={handleResubmit}
      handleRollback={handleRollback}
      handleComplete={handleComplete}
      handleApproveAll={handleSave(segmentIds, Segment.REVIEWS.APPROVED)}
      handleRejectAll={handleSave(segmentIds, Segment.REVIEWS.REJECTED)}
      enabledHandleAllForWorkers
    />
  );

  const isWorkerReviewer = Task.getUserPermission(task).includes(Task.PERMISSIONS.DATA_EDIT);
  const workerDialect = task?.reviewTask?.worker?.userMetadata?.spokenDialect ?? 'Undefined';

  let DIALECTS = [];
  switch (true) {
    case Language.getCode(TaskResult.getSourceLanguage(task)) === 'EN-GB':
      DIALECTS = dialectsUK.enum;
      break;
    case Language.getCode(TaskResult.getSourceLanguage(task)) === 'EN-US':
      DIALECTS = dialectsUS.enum;
      break;
    case Language.getCode(TaskResult.getSourceLanguage(task)) === 'EN-AU':
      DIALECTS = dialectsAU.enum;
      break;
    default:
      break;
  }

  const handleFilesDownload = async () => {
    setIsBusy(true);
    try {
      const audioBlobs = task?.targets?.map(target => {
        return {
          audioBlob: target?.content?.data ? base64toBlob(JSON.parse(target?.content?.data)?.audio) : null,
          segmentId: target?.content?.id
        }
      }).filter(t => t.audioBlob !== null);

      if(audioBlobs.length <= 0) {
        throw new Error("No audio files available for download");
      }
      // Create a zip folder
      const zip = new JSZip();
      const folder = zip.folder("audio-files");

      for (let j = 0; j < audioBlobs.length; j++) {
        const audioFile = new File([audioBlobs[j].audioBlob],
          `audio-${ audioBlobs[j].segmentId }#${j+1}.wav`, { type: [audioBlobs[j].audioBlob].type });
        folder.file(audioFile.name, audioFile);
      }
      // Generate the zip file as a blob
      const zipBlob = await zip.generateAsync({ type: "blob" });
      // Trigger the download
      saveAs(zipBlob, `${ task.id }-audio-files.zip`);
    } catch(e) {
      Notifier.error(e.message);
    }
    finally {
      setIsBusy(false);
    }
  }

  let segmentsCounter = 0;
  let totalSegments = task?.targets?.length;

  return (
    <div className={`${(isSubmitting || isBusy) ? 'whirl sphere' : ''}`}>
      {toolbar}

        <div className='card' style={{ boxShadow: 'none' }}>
          <div className='card-body py-0 tw-flex tw-flex-row tw-justify-between'>
            {canUpdateWorkerDialect && (
              <WorkerDialectView
              dialects={DIALECTS}
              selectedDialect={workerDialect}
              isEditable={true}
              onSave={handleDialectUpdate}
            />)}
            <Tooltip title="Download files in a zip folder" >
              <button className="tw-border-[1px] tw-border-blue-700 tw-h-8 tw-px-6 tw-rounded-sm tw-bg-transparent tw-cursor-pointer" onClick={ handleFilesDownload }>
                <FontAwesomeIcon className="tw-text-lg tw-text-blue-700" icon={ faFileZipper }/>
              </button>
            </Tooltip>
          </div>
        </div>
      <div className='card' style={{ boxShadow: 'none' }}>
        <div className='card-body p-0'>
          {task?.targets.map((segment) => {
            segmentsCounter++;

            return (
              <div id={segment.id} key={segment.id} className='tw-flex tw-flex-row'>
                <div>
                  <span className='tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-border tw-border-solid tw-border-gray-200 tw-rounded tw-w-8 tw-h-7'>
                    {segmentsCounter}/{totalSegments}
                  </span>
                </div>
                <div className='tw-w-full tw-pt-1'>
                  <TextToSpeechReviewSegment
                    key={segment.id}
                    segment={segment}
                    task={task}
                    isReviewable={
                      isWorkerReviewer ||
                      Segment.getUserPermission(segment).includes(Segment.PERMISSIONS.EDIT)
                    }
                    handleApprove={handleSave([segment.id.toString()], Segment.REVIEWS.APPROVED)}
                    handleReject={handleSave([segment.id.toString()], Segment.REVIEWS.REJECTED)}
                    isLoading={targets[segment.id].loadingData}
                    audio={targets[segment.id].audio}
                    sampleRate={sampleRate}
                    transcription={targets[segment.id].transcription}
                    validTranscription={targets[segment.id].validTranscription}
                    onTranscriptionChanged={onTranscriptionChanged}
                    isChanged={targets[segment.id].changed}
                    errorMessages={targets[segment.id].errorMessages}
                    segmentMetadata={targets[segment.id].metadata}
                    languageQEMetadata={targets[segment.id].languageQEMetadata}
                    isAdmin={isAdmin}
                    onAudioChanged={onAudioChanged}
                    validAudio={targets[segment.id].validAudio}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {toolbar}
    </div>
  );
};

export default TaskTextToSpeechReviewView;

const saveAsWorker = async (task, segments, metadata) => {
  await updateMetadata(task.id, metadata);
  return await saveTask(task.id, segments);
};

const saveAsLeadReviewer = async (task, segments, metadata) => {
  const newSegments = await saveSegments(segments);
  const newTask = { ...task };
  newSegments.forEach((newSegment) => {
    for (let i = 0; i < task.targets.length; i++) {
      if (task.targets[i].id === newSegment.id) {
        newTask.targets[i] = newSegment;
        break;
      }
    }
  });
  await updateMetadata(task.id, metadata);
  return newTask;
};

const saveSegments = async (segments) => {
  return SegmentController.updateAll(segments);
}

const saveTask = (id, data) => {
  return TaskResultController.save(id, data);
};

const submitTask = (id) => {
  return TaskResultController.submit(id);
};

const resubmitTask = (id) => {
  return AdminTaskResultController.resubmit(id);
};

const rollbackTask = (id) => {
  return TaskResultController.rollback(id);
};

const completeCheck = async (id) => {
  return TaskResultController.update(id, {
    evaluationStatus: Task.EVALUATION_STATUS.NOT_RISKY,
  });
};

const reviewWorker = async (id, data) => {
  return TaskResultController.reviewWorker(id, data);
};

const updateMetadata = (id, metadata) => {
  return TaskResultController.updateMetadata(id, metadata);
};