import { faRedo, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'reactstrap';
import Tooltip from '../../../../components/Tooltip';
import LanguageDisplay from '../LanguageDisplay';
import MultiLevelSelect from '../MultiLevelSelect';

const AnnotationTargetSegmentHeader = ({
  targetLanguage,
  reviewStatus,
  canClearAll,
  onClearAll,
  onAnnotateAll,
  tagDefinitions,
  root,
  canUndo,
  onUndo,
  canRedo,
  onRedo,
  isExpanded,
  toggleExpanded,
}) => {
  return (
    <div className='d-flex border-bottom pb-1'>
      {reviewStatus}
      <label className='font-weight-bold flex-grow-1'>
        <LanguageDisplay language={targetLanguage} />
      </label>

      <Tooltip title='Controls the width of the tags'>
        <Button className='py-1 px-2 mr-2' onClick={toggleExpanded} size='sm'>
          {isExpanded ? 'Collapse tags' : 'Expand tags'}
        </Button>
      </Tooltip>

      <Tooltip title='Undo'>
        <Button className='py-1 px-2 mr-2' onClick={onUndo} disabled={!canUndo} size='sm'>
          <FontAwesomeIcon icon={faUndo} />
        </Button>
      </Tooltip>

      <Tooltip title='Redo'>
        <Button className='py-1 px-2 mr-2' onClick={onRedo} disabled={!canRedo} size='sm'>
          <FontAwesomeIcon icon={faRedo} />
        </Button>
      </Tooltip>

      <Tooltip title='Splits all groups and removes all tags'>
        <Button className='py-1 mr-2' onClick={onClearAll} disabled={!canClearAll} size='sm'>
          Clear all
        </Button>
      </Tooltip>

      <MultiLevelSelect
        optionsTree={tagDefinitions}
        root={root}
        onChange={onAnnotateAll}
        title={
          <Tooltip title='Groups all tokens and annotates them with the selected tag'>
            <Button className='p-1'>Annotate all</Button>
          </Tooltip>
        }
      />
    </div>
  );
};

export default AnnotationTargetSegmentHeader;
