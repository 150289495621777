export const paymentMethods = [
  { label: 'PayPal', value: 'PayPal' },
  { label: 'Stripe', value: 'Stripe', disabled: true },
  { label: 'Wise', value: 'TransferWise' },
];

export const accountTypes = (countryCode) => {
  switch (countryCode) {
    case 'JP':
      return [
        { label: 'Current', value: 'CURRENT' },
        { label: 'Savings', value: 'SAVINGS' },
        { label: 'Checking', value: 'CHECKING' },
      ];
    case 'US':
      return [
        { label: 'Checking', value: 'CHECKING' },
        { label: 'Savings', value: 'SAVINGS' },
      ];
    default:
      return [];
  }
};
