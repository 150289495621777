import { PAGES } from '../..';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import MUIModal from '@mui/material/Modal';
import Loader from '../../../components/Loader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const PublicProjectLobbyPageView = ({
  project,
  isMember,
  isLoading,
  isApplicant,
  screenerSteps,
  allStepsCompleted,
  handleScreenerStepRedirection,
}) => {
  const history = useHistory();
  const [isGeoModalOpen, setGeoModalOpen] = useState(false);

  const handleBack = () => {
    history.push(PAGES.PUBLIC_PROJECT_PAGE.path(project.id));
  };

  const handleAccept = (stepId, stepType, stepRequirementType) => {
    setGeoModalOpen(false);
    handleScreenerStepRedirection(project?.id, stepId, stepType, stepRequirementType);
  };

  return (
    <div className='jumbotron tw-relative'>
      <Loader isLoading={isLoading} />
      <h1 className='tw-border-b tw-border-solid tw-border-t-0 tw-border-x-0 tw-border-b-gray-200 tw-pb-2 tw-mb-8'>
        {project?.name}
      </h1>
      {!isApplicant ? (
        <div>
          <span className='tw-block tw-text-center'>
            You are trying to access a page which you don't have permission for. Please apply to the
            project first.
          </span>
          <Button className='btn-secondary tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2'>
            <em className='fas fa-hand-point-left tw-text-base'></em>
            <span className='tw-text-base' onClick={handleBack}>
              Go back
            </span>
          </Button>
        </div>
      ) : (
        screenerSteps?.length > 0 &&
        !allStepsCompleted && (
          <div className='tw-mt-14 tw-border tw-border-solid tw-border-gray-200 tw-rounded tw-p-4'>
            <h3 className='tw-border-b tw-border-solid tw-border-t-0 tw-border-x-0 tw-border-b-gray-200 tw-pb-2 tw-mb-4'>
              Project Requirements
            </h3>
            <div className='tw-flex tw-flex-col tw-gap-4'>
              {screenerSteps.map((step) => {
                const stepId = step.projectScreenerStep.screenerStep.id;
                const stepName = step.projectScreenerStep.screenerStep.name;
                const stepDesc = step.projectScreenerStep.screenerStep.description;
                const stepType = step.projectScreenerStep.screenerStep.type;
                const stepRequirementType = step.projectScreenerStep.screenerStep.requirementTypeId;
                const isStepCompleted = step.isCompleted;
                return (
                  <>
                    <div
                      className='tw-flex tw-gap-4 tw-justify-around tw-items-center tw-min-h-[40px]'
                      key={stepId}
                    >
                      <span className='tw-w-2/6'>{stepName}</span>
                      <span className='tw-w-3/6'>{stepDesc}</span>
                      <span className='tw-w-1/6 tw-w-full tw-flex tw-justify-end'>
                        {isStepCompleted ? (
                          <Button className='btn-secondary' disabled={true}>
                            <em className='fas fa-check'></em>
                          </Button>
                        ) : (
                          <Button
                            onClick={
                              stepType === 'ScreenerStepGeolocation'
                                ? () => setGeoModalOpen(true)
                                : () =>
                                    handleScreenerStepRedirection(
                                      project?.id,
                                      stepId,
                                      stepType,
                                      stepRequirementType
                                    )
                            }
                          >
                            Complete
                          </Button>
                        )}
                      </span>
                    </div>
                    {stepType === 'ScreenerStepGeolocation' && (
                      <MUIModal
                        open={isGeoModalOpen}
                        onClose={() => setGeoModalOpen(!isGeoModalOpen)}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                      >
                        <div
                          className={`tw-p-2 sm:tw-p-4 tw-absolute tw-max-h-[85vh] tw-overflow-y-scroll tw-top-28 md:tw-top-44 tw-left-1/2 tw-transform -tw-translate-x-1/2 tw-bg-gray-100 tw-rounded tw-shadow-lg`}
                        >
                          <div className='tw-text-center tw-mb-4'>
                            <span className='tw-text-2xl'>Thanks for applying!</span>
                          </div>
                          <div className='tw-text-lg'>
                            <div>
                              <span>
                                Due to our client's requirement, to be able to participate in the
                                project we need you to share your location.
                              </span>
                            </div>
                            <div className='tw-mb-4'>
                              <span>
                                This information is not going to be shared with the client, it will
                                only be used to match your profile against the project screening
                                criteria.
                              </span>
                            </div>
                            <div className='tw-mb-4'>
                              <span>
                                In case you want to change your location sharing preferences, you
                                can do that at any time in your browser's settings. How to manage
                                this depends on the browser you're using,{' '}
                                <a
                                  href='https://support.google.com/chrome/answer/142065?hl=en&co=GENIE.Platform%3DDesktop&oco=0'
                                  target='_BLANK'
                                >
                                  here
                                </a>{' '}
                                an example of how to do this in Chrome.
                              </span>
                            </div>
                            <div>
                              <span>
                                In case of issues please contact us at{' '}
                                <a href='mailto:hlp@taus.net'>hlp@taus.net</a>
                              </span>
                            </div>
                          </div>
                          <div className='tw-flex tw-justify-end tw-gap-2'>
                            <Button
                              className='btn-secondary'
                              onClick={() => setGeoModalOpen(false)}
                            >
                              Decline
                            </Button>
                            <Button
                              onClick={() => handleAccept(stepId, stepType, stepRequirementType)}
                            >
                              Accept
                            </Button>
                          </div>
                        </div>
                      </MUIModal>
                    )}
                  </>
                );
              })}
              {allStepsCompleted && (
                <div className='tw-text-center tw-pt-8'>
                  <span>You now fulfill all the requirements.</span>
                </div>
              )}
            </div>
          </div>
        )
      )}
      {allStepsCompleted && !isMember && isApplicant && (
        <div className='tw-mt-8 tw-text-center'>
          <span>
            We received your application and we are processing it. Soon you will receive
            instructions.
          </span>
        </div>
      )}
    </div>
  );
};

export default PublicProjectLobbyPageView;
