import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Project from '../../../models/Project';
import UserOverview from '../components/UserOverview';
import OverviewBarChart from '../components/OverviewBarChart';
import PaymentOverviewBarChart from '../components/PaymentOverviewBarChart';
import UserGroupOverviewBarChart from '../components/UserGroupOverviewBarChart';
import EvaluationOverviewBarChart from '../components/EvaluationOverviewBarChart';
import RolledBackTasksProgressBar from '../components/RolledBackTasksProgressBar';
import OverviewTaskStatusesOverTime from '../components/OverviewTaskStatusesOverTime';

const ProjectStatisticsView = ({ tasks, generatedTasks, projectType }) => {
  const totalReview = tasks.length > 0 ? tasks.filter((t) => t.stage === 'REVIEW').length : 0;
  const totalPrimary = tasks.length > 0 ? tasks.filter((t) => t.stage === 'PRIMARY').length : 0;
  const totalGeneratedReview =
    generatedTasks.length > 0 ? generatedTasks.filter((t) => t.stage === 'REVIEW').length : 0;
  const totalGeneratedPrimary =
    generatedTasks.length > 0 ? generatedTasks.filter((t) => t.stage === 'PRIMARY').length : 0;
  const [activeTab, setActiveTab] = useState(1);
  const [hasUsergroups, setHasUsergroups] = useState(false);

  const handleDownload = (title, ref) => {
    const link = document.createElement('a');
    link.download = title + '.jpeg';
    link.href = ref.current.toBase64Image('image/jpeg', 1);
    link.click();
  };

  useEffect(() => {
    setHasUsergroups(tasks.flatMap((i) => i.userGroups.flatMap((j) => j)).length > 0);
  }, [tasks]);

  return (
    <div>
      <Tabs
        id='project-tabs'
        activeKey={activeTab}
        onSelect={(index) => setActiveTab(index)}
        mountOnEnter
      >
        <Tab title='1. Task Overview' eventKey={1}>
          <div>
            <div className='tw-grid tw-grid-cols-2 tw-mb-2'>
              <div className='tw-col-span-1'>
                <div className='tw-text-base'>
                  <span className=''>Total PRIMARY task results:</span>
                  <span className='tw-font-bold tw-ml-1'>{totalPrimary}</span>
                </div>
                <div className='tw-text-base'>
                  <span className=''>Total REVIEW task results:</span>
                  <span className='tw-font-bold tw-ml-1'>{totalReview}</span>
                </div>
                <div className='tw-text-base'>
                  <span className=''>Total task results:</span>
                  <span className='tw-font-bold tw-ml-1'>{totalPrimary + totalReview}</span>
                </div>
              </div>
              {projectType === Project.SETTINGS.TASKS.RESULTS.POLICY.ONE_TO_ONE.sid && (
                <div className='tw-col-span-1'>
                  <div className='tw-text-base'>
                    <span className=''>Total generated PRIMARY tasks:</span>
                    <span className='tw-font-bold tw-ml-1'>{totalGeneratedPrimary}</span>
                  </div>
                  <div className='tw-text-base'>
                    <span className=''>Total generated REVIEW tasks:</span>
                    <span className='tw-font-bold tw-ml-1'>{totalGeneratedReview}</span>
                  </div>
                  <div className='tw-text-base'>
                    <span className=''>Total generated tasks:</span>
                    <span className='tw-font-bold tw-ml-1'>
                      {totalGeneratedPrimary + totalGeneratedReview}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className='tw-flex tw-flex-col 2xl:tw-flex-row tw-gap-4'>
              <div className='tw-w-full 2xl:tw-w-1/2'>
                <OverviewBarChart
                  tasks={tasks}
                  projectType={projectType}
                  handleDownload={handleDownload}
                  totalGeneratedReview={totalGeneratedReview}
                  totalGeneratedPrimary={totalGeneratedPrimary}
                />
              </div>
              {hasUsergroups && (
                <div className='tw-w-full 2xl:tw-w-1/2'>
                  <UserGroupOverviewBarChart tasks={tasks} handleDownload={handleDownload} />
                </div>
              )}
            </div>
            <hr />
            <div>
              <OverviewTaskStatusesOverTime tasks={tasks} handleDownload={handleDownload} />
            </div>
          </div>
        </Tab>
        <Tab title='2. Evaluation Overview' eventKey={2}>
          <div>
            <EvaluationOverviewBarChart tasks={tasks} handleDownload={handleDownload} />
          </div>
          <div className='tw-mt-2 tw-mb-10'>
            <RolledBackTasksProgressBar tasks={tasks} />
          </div>
        </Tab>
        <Tab title='3. Payment Overview' eventKey={3}>
          <div>
            <PaymentOverviewBarChart tasks={tasks} handleDownload={handleDownload} />
          </div>
        </Tab>
        <Tab title='4. User Overview' eventKey={4}>
          <div>
            <UserOverview />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ProjectStatisticsView;
