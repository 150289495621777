import alasql from 'alasql';
import { Bar } from 'react-chartjs-2';
import { Tooltip } from '@mui/material';
import Utils from '../../../utils/Utils';
import { useEffect, useRef, useState } from 'react';
import TASK_RESULT from '../../../models/TaskResult';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chart as ChartJS, CategoryScale, registerables } from 'chart.js';
ChartJS.register(CategoryScale, ...registerables);

const PaymentOverviewBarChart = ({ tasks, handleDownload }) => {
  const chartRef = useRef(null);
  const [barStats, setBarStats] = useState([]);
  const [barDatasets, setBarDatasets] = useState([]);
  const totalCredits = tasks.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.taskResult.credits;
  }, 0);
  const totalPrimaryCredits = tasks
    .filter((t) => t.stage === 'PRIMARY')
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue.taskResult.credits;
    }, 0);
  const totalReviewCredits = tasks
    .filter((t) => t.stage === 'REVIEW')
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue.taskResult.credits;
    }, 0);

  const barOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      title: {
        display: true,
        text: '3a. Total task result overview of payment statuses',
      },
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const status = String(context.label).toUpperCase();
            const stage = String(context.dataset.label).toUpperCase();
            const totalStageCredits = tasks
              .filter((t) => t.stage === stage)
              .reduce((accumulator, currentValue) => {
                return accumulator + currentValue.taskResult.credits;
              }, 0);
            const totalStageStatusCredits = tasks
              .filter((t) => t.stage === stage)
              .filter((t) => t.taskResult.paymentStatus === status)
              .reduce((accumulator, currentValue) => {
                return accumulator + currentValue.taskResult.credits;
              }, 0);
            const tasksCount = context.raw;
            const percentageOfTotal = Number(
              (totalStageStatusCredits / totalCredits) * 100
            ).toFixed(2);
            const percentageOfStageTotal = Number(
              (totalStageStatusCredits / totalStageCredits) * 100
            ).toFixed(2);

            let label = [];
            label.push(`${Utils.textFirstOnlyUpper(stage)}: ${tasksCount}`);
            label.push(`Total ${stage} ${status} credits: ${totalStageStatusCredits}`);
            label.push(`% of total: ${percentageOfTotal}%`);
            label.push(`% of ${stage} total: ${percentageOfStageTotal}%`);

            return label;
          },
        },
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
        formatter: (value) => value,
      },
    },
  };

  const barData = {
    labels: ['Inactive', 'Failed', 'Rejected', 'Pending', 'Submitted', 'No Payment', 'Completed'],
    datasets: barDatasets,
  };

  const setData = async () => {
    const groupByStatus =
      `select stage, ` +
      `sum(case when taskResult->paymentStatus='${TASK_RESULT.PAYMENT_STATUS.INACTIVE}' then 1 else 0 end) as inactive, ` +
      `sum(case when taskResult->paymentStatus='${TASK_RESULT.PAYMENT_STATUS.FAILED}' then 1 else 0 end) as failed, ` +
      `sum(case when taskResult->paymentStatus='${TASK_RESULT.PAYMENT_STATUS.REJECTED}' then 1 else 0 end) as rejected, ` +
      `sum(case when taskResult->paymentStatus='${TASK_RESULT.PAYMENT_STATUS.PENDING}' then 1 else 0 end) as pending, ` +
      `sum(case when taskResult->paymentStatus='${TASK_RESULT.PAYMENT_STATUS.SUBMITTED}' then 1 else 0 end) as submitted, ` +
      `sum(case when taskResult->paymentStatus='${TASK_RESULT.PAYMENT_STATUS.NO_PAYMENT}' then 1 else 0 end) as noPayment, ` +
      `sum(case when taskResult->paymentStatus='${TASK_RESULT.PAYMENT_STATUS.COMPLETED}' then 1 else 0 end) as completed ` +
      'from ? ' +
      'group by stage';
    let stats = await alasql.promise(groupByStatus, [tasks]);
    setBarStats(stats);
  };

  const setBarChartData = () => {
    const barChartData = barStats.map((item) => ({
      label: Utils.textFirstOnlyUpper(item.stage),
      data: Object.keys(item)
        .filter((key) => key !== 'stage')
        .map((i) => item[i]),
      backgroundColor: item.stage === 'PRIMARY' ? '#00d41999' : '#5693f599',
      borderColor: item.stage === 'PRIMARY' ? '#00d419' : '#5693f5',
      borderWidth: 1,
      minBarLength: 1,
    }));

    setBarDatasets(barChartData);
  };

  useEffect(() => {
    setData();
  }, []);

  useEffect(() => {
    setBarChartData();
  }, [barStats]);

  return (
    <div>
      <div className='tw-text-base'>
        <span>Total credits (PRIMARY and REVIEW stages combined for all statuses):</span>
        <span className='tw-font-bold tw-ml-1'>{totalCredits}</span>
      </div>
      <div className='tw-text-base'>
        <span>Total PRIMARY credits:</span>
        <span className='tw-font-bold tw-ml-1'>{totalPrimaryCredits}</span>
      </div>
      <div className='tw-text-base'>
        <span>Total REVIEW credits:</span>
        <span className='tw-font-bold tw-ml-1'>{totalReviewCredits}</span>
      </div>
      <div className='tw-w-full xl:tw-w-2/3 tw-mx-auto'>
        <div className='tw-relative'>
          <div className='tw-absolute tw-right-0 tw-top-0 tw-rounded tw-bg-blue-400 tw-w-7 tw-h-7 tw-flex tw-items-center tw-justify-center tw-cursor-pointer'>
            <Tooltip title='Download chart as image'>
              <FontAwesomeIcon
                icon={faDownload}
                size='lg'
                onClick={() => handleDownload('PaymentOverviewBarChart', chartRef)}
                className='tw-text-white'
              />
            </Tooltip>
          </div>
          <Bar data={barData} options={barOptions} ref={chartRef} />
        </div>
      </div>
    </div>
  );
};

export default PaymentOverviewBarChart;
