import React, { useEffect, useState } from 'react';

import useRecorder from '../../hooks/useRecorder';

import RecordingDisplay from '../recorder/RecordingDisplay';
import RecorderControls from '../recorder/RecorderControls';
import { TargetSegmentTranslationFooter as TargetSegmentFooter } from '../SegmentView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { isUnstructuredSpeechSegment } from '../../hooks/useTTSTargets';
import { NavLink } from 'react-bootstrap';
import Segment from '../../../../models/Segment'

const reviewToClass = {
  [Segment.REVIEWS.APPROVED]: 'text-success',
  [Segment.REVIEWS.REJECTED]: 'text-danger',
  [Segment.REVIEWS.UNREVIEWED]: '',
};

const TextToSpeechTargetSegment = ({
  segmentId,
  audio: defaultAudio,
  sampleRate,
  validAudio,
  onAudioChanged,
  transcription,
  validTranscription,
  onTranscriptionChanged,
  segmentMetadata,
  task,
  errorMessages,
  isLoading,
  isLoadingLanguageQEMetadata,
  loadingLanguageQEMetadataFailed,
  onRetryLanguageQE,
  handleSave,
  isChanged,
  shouldUpdateOriginalTranscription,
}) => {
  const { recorderState, ...handlers } = useRecorder(defaultAudio, sampleRate, 300);
  const { audio, audioBlob, unsavedAudio } = recorderState;

  const { segmentRecDurationMin, segmentRecDurationMax, segmentType, minNumberOfEdits } =
    segmentMetadata;

  const [originalTranscription, setOriginalTranscription] = useState();

  const reviewStatus = task?.targets.filter((target) => target.id === segmentId && (!!target.content))[0]?.status;

  useEffect(() => {
    if (unsavedAudio) {
      onAudioChanged(
        segmentId,
        audioBlob,
        segmentRecDurationMin,
        segmentRecDurationMax,
        segmentType
      );
    }
  }, [
    onAudioChanged,
    segmentId,
    audioBlob,
    unsavedAudio,
    segmentRecDurationMin,
    segmentRecDurationMax,
    segmentType,
  ]);

  useEffect(() => {
    if (shouldUpdateOriginalTranscription) {
      setOriginalTranscription(transcription);
    }
  }, [transcription])

  const handleTranscriptionChange = (e) => {
    onTranscriptionChanged(segmentId, e.target.value, {
      oldTranscription: originalTranscription,
      minNumberOfEdits,
    });
  };
  const showTranscription =
    !!audio && validAudio && isUnstructuredSpeechSegment(segmentMetadata.segmentType);

  const loadingText = isUnstructuredSpeechSegment(segmentMetadata.segmentType)
    ? 'Transcribing your recording - it can take up to 2 mins. Please do not refresh the page.'
    : 'Checking your recording';

  const errorMessage = (
    <>
      {errorMessages.join(' ')}
      {loadingLanguageQEMetadataFailed && (
        <NavLink className='p-0 ml-2 d-inline' onClick={onRetryLanguageQE}>
          Click to retry
        </NavLink>
      )}
    </>
  );

  return (
    <div className={`pb-3 pr-sm-3 col-12-xs col-sm-6 ${isLoading && 'whirl sphere'}`}>
      <div className={`${!!reviewStatus && 'tw-flex tw-flex-row tw-gap-2 tw-justify-between tw-items-center'}`}>
        {!!reviewStatus && <span className={reviewToClass[reviewStatus]}>Status: {reviewStatus}</span> }
        <RecorderControls
          segmentRecDurationMin={segmentRecDurationMin}
          segmentRecDurationMax={segmentRecDurationMax}
          recorderState={recorderState}
          handlers={handlers}
          recordingDisabled={isLoading || isLoadingLanguageQEMetadata}
        />
      </div>

      <RecordingDisplay audio={audio} isSaved={!isChanged} />
      {isLoadingLanguageQEMetadata && (
        <div className={`d-flex align-items-center justify-content-center mt-2`}>
          <FontAwesomeIcon icon={faSpinner} size='2x' spin={true} className='mr-2' />
          <div>{loadingText}</div>
        </div>
      )}
      {!isLoadingLanguageQEMetadata && (
        <div className='mt-2 pt-1'>
          {showTranscription && (
            <div className={`position-relative`}>
              <label
                className='position-absolute font-weight-bold ml-1 px-3 pt-2 pb-2 bg-white'
                style={{ width: '96%', marginTop: '1px' }}
              >
                Transcription:
              </label>
              <textarea
                rows='3'
                className='form-control pt-5 mb-2'
                onChange={handleTranscriptionChange}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                value={transcription ?? ''}
              />
            </div>
          )}
          <TargetSegmentFooter
            isValid={validAudio && validTranscription}
            errorMessage={errorMessage}
            isChanged={isChanged}
            onSaveButtonClick={handleSave}
          />
        </div>
      )}
    </div>
  );
};

export default TextToSpeechTargetSegment;
