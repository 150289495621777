import { get } from '../http';

const BASE_URL = '/api/request';

const getStatus = async (uuid) => {
  if (!uuid) {
    throw new Error('Invalid uuid (Null)');
  }
  return get(`${BASE_URL}/progress/${uuid}/status`);
};

const getResponse = async (uuid) => {
  if (!uuid) {
    throw new Error('Invalid uuid (Null)');
  }
  return get(`${BASE_URL}/progress/${uuid}/response`);
};

const RequestProgressController = { getStatus, getResponse };

export default RequestProgressController;
