import React, { useState } from 'react';
import ButtonSpinner from '../components/ButtonSpinner';
import sessionController from '../controllers/SessionController'

const GoogleLogin = ({ title }) => {
  const [isLoading, setLoading] = useState(false);
  const handleClick = async () => {
    const provider = 'hlp-google';
    if (!isLoading) {
      setLoading(true);
      await sessionController.authorize(provider)
      .then((resp) => {
        window.location.replace(resp);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  };

  return (
    <div
      className={`taus-btn-primary tw-relative tw-inline-flex tw-flex-row tw-items-center tw-px-5 tw-py-4 tw-transition tw-duration-100 tw-ease-in-out tw-border tw-rounded tw-cursor-pointer ${
        isLoading
          ? 'tw-bg-gray-300 tw-cursor-default'
          : 'tw-bg-gray-200 tw-bg-opacity-80 hover:tw-bg-opacity-60'
      }`}
      onClick={handleClick}
    >
      <img
        alt='facebook-icon'
        className='tw-inline tw-w-6 tw-h-6 tw-mr-4'
        src='https://a.storyblok.com/f/139507/394x401/7a263469c3/icons-social-media-google-colored.png'
      />
      <span className='tw-text-gray-700'>{title} with Google</span>
      {isLoading && <ButtonSpinner />}
    </div>
  );
};

export default GoogleLogin;