export const detectIOS = () => {
  const isIOS = /iPad|iPhone|iPod/i.test(navigator.userAgent);
  const isAppleDevice = navigator.userAgent.includes('Macintosh');
  const isDesktopSafari =
    /Safari/i.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor) &&
    !/Mobi|Android/i.test(navigator.userAgent);

  const hasTouchScreen = navigator.maxTouchPoints >= 1;

  return isIOS || isDesktopSafari || (isAppleDevice && (hasTouchScreen || detectIOSPre13()));
};

const detectIOSPre13 = () => {
  const audio = new Audio(); // temporary Audio object
  audio.volume = 0.5; // has no effect on iOS <= 12
  return audio.volume === 1;
};
