import React, { useEffect, useState } from 'react';
import ScreeningPageView from '../views/ScreeningPageView';
import ScreeningController from '../../../controllers/ScreeningController';
import Notifier from '../../../components/Notifier';
import ProjectController from '../../../controllers/ProjectController';
import MetadataController from '../../../controllers/MetadataController';
import Utils from '../../../utils/Utils';

const ScreeningPageState = () => {
  const [loading, setLoading] = useState(true);
  const [screeningSteps, setScreeningSteps] = useState([]);
  const [pagination, setPagination] = useState({});
  const [totalElements, setTotalElements] = useState(0);
  const [adminProjects, setAdminProjects] = useState([]);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [page, setPage] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [sorting, setSorting] = useState([{ columnName: 'id', direction: 'asc' }]);

  const getQuestionnaires = () => {
    try {
      MetadataController.getQuestionnaires().then((r) => {
        setQuestionnaires(r.map((q) => ({ value: q.id, label: q.code })));
      });
    } catch (e) {
      Notifier.error(e.message);
    }
  };

  const getScreeningSteps = (id = null) => {
    setLoading(true);
    try {
      let sortingParams = '';
      if (sorting.length > 0) {
        sorting.map((item) => {
          sortingParams = item.columnName + ',' + item.direction;
        });
      }

      ScreeningController.getAll({ page: page, size: pageSize, sort: sortingParams })
        .then((r) => {
          setScreeningSteps(r.content);
          setPagination({
            page: r.pageable.pageNumber,
            size: r.pageable.pageSize,
          });
          setTotalElements(r.totalElements);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      Notifier.error(e.message);
    }
  };

  const getAdminProjects = () => {
    try {
      ProjectController.adminGetTestProjects().then((r) => {
        const projects = r.page.map((item) => ({ value: item.id, label: item.name }));
        setAdminProjects(projects);
      });
    } catch (e) {
      Notifier.error(e.message);
    }
  };

  const createScreeningStep = async (data, isEditing) => {
    setLoading(true);
    try {
      if (isEditing) {
        let d = {
          name: data.name,
          description: data.description,
        };

        ScreeningController.update(d, data.id).then((r) => {
          getScreeningSteps();
        });
      } else {
        let d = {
          name: data.name,
          description: data.description,
          type: data.type,
          requirementTypeId: data.requirementTypeId,
        };
        ScreeningController.create(d).then((r) => {
          getScreeningSteps();
        });
      }
    } catch (e) {
      Notifier.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInvalidate = async (id) => {
    setLoading(true);
    try {
      await ScreeningController.invalidate(id);
    } catch (e) {
      Notifier.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await ScreeningController.delete(id).then(() => {
        getScreeningSteps(id);
      });
    } catch (e) {
      Notifier.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setPage(null);
    setPageSize(pageSize);
  };

  useEffect(() => {
    Utils.isEmpty(questionnaires) && getQuestionnaires();
    Utils.isEmpty(adminProjects) && getAdminProjects();
    getScreeningSteps();
  }, [sorting, page, pageSize]);

  return (
    <ScreeningPageView
      isLoading={loading}
      screeningSteps={screeningSteps}
      questionnaires={questionnaires}
      sorting={sorting}
      handleSorting={setSorting}
      pagination={pagination}
      totalElements={totalElements}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      adminProjects={adminProjects}
      createScreeningStep={createScreeningStep}
      handleInvalidate={handleInvalidate}
      handleDelete={handleDelete}
    />
  );
};

export default ScreeningPageState;
