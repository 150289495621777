import USER from './User';
import TASK from './Task';
import moment from 'moment';
import PropTypes from 'prop-types';
import Utils from '../utils/Utils';
import USER_PERMISSION from './UserPermission';

try {
  moment.locale(window.navigator.language);
} catch (e) {}

export default class TaskResult {
  static USER_SCOPE = {
    WORKER: 'WORKER',
    REVIEWER: 'REVIEWER',
    PROJECT: 'PROJECT',
    API: 'API',
  };

  static STATUS = {
    INACTIVE: 'INACTIVE',
    ACTIVE: 'ACTIVE',
    ROLLED_BACK: 'ROLLED_BACK',
    PENDING: 'PENDING',
    SUBMITTED: 'SUBMITTED',
    PENDING_SUBMISSION_QE: 'PENDING_SUBMISSION_QE',
    PENDING_CHECK: 'PENDING_CHECK',
    PENDING_REVIEW: 'PENDING_REVIEW',
    REVIEWED: 'REVIEWED',
    ON_HOLD: 'ON_HOLD',
    COMPLETED: 'COMPLETED',
    DELIVERED: 'DELIVERED',
  };

  static PERMISSIONS = {
    READ: 'READ',
    EDIT: 'EDIT',
    RELEASE: 'RELEASE',
    DATA_EDIT: 'DATA_EDIT',
  };

  static PAYMENT_STATUS = {
    INACTIVE: 'INACTIVE',
    PENDING: 'PENDING',
    SUBMITTED: 'SUBMITTED',
    NO_PAYMENT: 'NO_PAYMENT',
    COMPLETED: 'COMPLETED',
    REJECTED: 'REJECTED',
    FAILED: 'FAILED',
    UNKNOWN: 'UNKNOWN',
  };

  static EVALUATION_STATUS = {
    FAILED: 'FAILED',
    RISKY: 'RISKY',
    INACTIVE: 'INACTIVE',
    NOT_RISKY: 'NOT_RISKY',
    PASSED: 'PASSED',
  };

  static DELIVERY_STATUS = {
    FAILED: 'FAILED',
    PENDING: 'PENDING',
    DELIVERED: 'DELIVERED',
  };

  static propTypes = PropTypes.shape({
    id: PropTypes.number.isRequired,
    targets: PropTypes.array.isRequired,
    name: PropTypes.string,
    credits: PropTypes.number.isRequired,
    type: PropTypes.shape({
      id: PropTypes.number.isRequired,
      sid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.oneOf(Object.values(this.STATUS)).isRequired,
    version: PropTypes.number,
    paymentStatus: PropTypes.oneOf(Object.values(this.PAYMENT_STATUS)).isRequired,
    options: PropTypes.object.isRequired,
    timestampCreated: PropTypes.number.isRequired,
    timestampUpdated: PropTypes.number.isRequired,
    timestampRequested: PropTypes.number,
    timestampSubmitted: PropTypes.number,
    timestampReviewed: PropTypes.number,
    timestampCompleted: PropTypes.number,
    timestampPaid: PropTypes.number,
    timestampExpires: PropTypes.number,
    project: PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
      sourceLanguage: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      targetLanguage: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
    worker: PropTypes.shape({
      id: PropTypes.number.isRequired,
      username: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  });

  static getUserScope(task = null, user = null) {
    if (Utils.isNull(task) || Utils.isNull(user)) return TaskResult.USER_SCOPE.WORKER;
    if (TaskResult.getUserPermission(task).includes(TaskResult.PERMISSIONS.DATA_EDIT))
      return TaskResult.USER_SCOPE.WORKER;
    if (
      USER.hasAnyScopePermissionOfType(user, USER_PERMISSION.SCOPES.API, USER_PERMISSION.TYPES.TASK)
    )
      return TaskResult.USER_SCOPE.API;
    if (USER.hasAnyProjectPermissionOfType(user, task?.project?.id, USER_PERMISSION.TYPES.TASK))
      return TaskResult.USER_SCOPE.PROJECT;
    return TaskResult.USER_SCOPE.WORKER;
  }

  static getUserPermission(task = null) {
    return Utils.isNull(task) ? [] : task.permissions;
  }

  static getSourceLanguage(task = null) {
    return Utils.define(task?.sourceLanguage);
  }

  static getSourceLanguages(task = null) {
    let langs = [];
    langs.push(TaskResult.getSourceLanguage(task));
    return langs;
  }

  static getTargetLanguage(task = null) {
    if (Utils.isNull(task)) return '';
    return task?.targetLanguage?.name + ' (' + task?.targetLanguage?.code + ')';
  }

  static getUserCanSubmit(task = null) {
    if (!this.getUserPermission(task).includes(this.PERMISSIONS.DATA_EDIT)) return false;
    switch (task?.type?.policy ?? '') {
      case TASK.POLICY.INSERT: {
        return (
          task?.targets?.every((t) => (t?.content?.task?.id ?? -1) === (task?.id ?? -2)) ?? false
        );
      }
      case TASK.POLICY.EDIT: {
        return (
          task?.targets?.every((t) => (t?.content?.task?.id ?? -1) === (task?.id ?? -2)) ?? false
        );
      }
      case TASK.POLICY.REVIEW: {
        return (
          task?.targets?.every((t) => (t?.content?.review?.task?.id ?? -1) === (task?.id ?? -2)) ??
          false
        );
      }
      case TASK.POLICY.EDIT_REVIEW: {
        return (
          task?.targets?.every((t) => (t?.content?.review?.task?.id ?? -1) === (task?.id ?? -2)) ??
          false
        );
      }
      default: {
        return true;
      }
    }
  }

  static getTotalRecordedTime (targets = null) {
    return Utils.define(
      targets.map(
          (target) => !!target?.content?.data ? JSON.parse(target?.content?.data)?.length : 0).reduce(
          (acc, curr) => acc + curr, 0),
         0
    )
  }

  // --------------------------------------------------------------------
  // ----------------------- Status -------------------------------------
  // --------------------------------------------------------------------
  static getStatusForWorker(task = null) {
    if (!task) return '';
    let status = (task?.status ?? '').toUpperCase();

    if (status === TaskResult.STATUS.INACTIVE) return 'Inactive';
    if (status === TaskResult.STATUS.ACTIVE) return 'Active';
    if (status === TaskResult.STATUS.PENDING) return 'In Progress';
    if (status === TaskResult.STATUS.SUBMITTED) return 'Submitted';
    if (status === TaskResult.STATUS.PENDING_SUBMISSION_QE) return 'Pending QE';
    if (status === TaskResult.STATUS.PENDING_CHECK) return 'Pending Check';
    if (status === TaskResult.STATUS.PENDING_REVIEW) return 'Pending Review';
    if (status === TaskResult.STATUS.REVIEWED) return 'Reviewed';
    if (status === TaskResult.STATUS.ON_HOLD) return 'On Hold';
    if (status === TaskResult.STATUS.COMPLETED) return 'Completed';
    if (status === TaskResult.STATUS.DELIVERED) return 'Delivered';
    if (status === TaskResult.STATUS.ROLLED_BACK) return 'Rolled Back';
    return status;
  }

  static getStatusForAdmin(task = null) {
    if (!task) return '';
    let status = (task?.status ?? '').toUpperCase();

    if (status === TaskResult.STATUS.ACTIVE) return 'Active';
    if (status === TaskResult.STATUS.PENDING) return 'In Progress';
    if (status === TaskResult.STATUS.SUBMITTED) return 'Submitted';
    if (status === TaskResult.STATUS.PENDING_SUBMISSION_QE) return 'Pending QE';
    if (status === TaskResult.STATUS.PENDING_REVIEW) return 'Pending Review';
    if (status === TaskResult.STATUS.PENDING_CHECK) return 'Pending Check';
    if (status === TaskResult.STATUS.REVIEWED) return 'Reviewed';
    if (status === TaskResult.STATUS.ON_HOLD) return 'On Hold';
    if (status === TaskResult.STATUS.COMPLETED) return 'Completed';
    if (status === TaskResult.STATUS.DELIVERED) return 'Delivered';
    if (status === TaskResult.STATUS.ROLLED_BACK) return 'Rolled Back';
    return status;
  }

  // --------------------------------------------------------------------
  // ----------------------- PaymentStatus ------------------------------
  // --------------------------------------------------------------------
  static getPaymentStatusForWorker(task = null) {
    if (!task) return '';
    let status = (task?.paymentStatus ?? '').toUpperCase();

    if (status === this.PAYMENT_STATUS.INACTIVE) return 'Inactive';
    if (status === this.PAYMENT_STATUS.PENDING) return 'Pending';
    if (status === this.PAYMENT_STATUS.SUBMITTED) return 'Submitted';
    if (status === this.PAYMENT_STATUS.NO_PAYMENT) return 'No Payment';
    if (status === this.PAYMENT_STATUS.COMPLETED) return 'Completed';
    if (status === this.PAYMENT_STATUS.REJECTED) return 'Rejected';
    return status;
  }

  static getPaymentStatusForAdmin(task = null) {
    if (!task) return '';
    let status = (task?.paymentStatus ?? '').toUpperCase();

    if (status === this.PAYMENT_STATUS.INACTIVE) return 'Inactive';
    if (status === this.PAYMENT_STATUS.PENDING) return 'Pending';
    if (status === this.PAYMENT_STATUS.SUBMITTED) return 'Submitted';
    if (status === this.PAYMENT_STATUS.NO_PAYMENT) return 'No Payment';
    if (status === this.PAYMENT_STATUS.COMPLETED) return 'Completed';
    if (status === this.PAYMENT_STATUS.REJECTED) return 'Rejected';
    return status;
  }

  // --------------------------------------------------------------------
  // ----------------------- EvaluationStatus ------------------------------
  // --------------------------------------------------------------------
  static getEvaluationStatusForWorker(task = null) {
    if (!task) return '';
    let status = (task?.evaluationStatus ?? '').toUpperCase();

    if (status === this.EVALUATION_STATUS.INACTIVE) return 'Pending';
    if (status === this.EVALUATION_STATUS.PASSED) return 'Passed';
    if (status === this.EVALUATION_STATUS.FAILED) return 'Failed';
    if (status === this.EVALUATION_STATUS.RISKY) return 'Pending';
    if (status === this.EVALUATION_STATUS.NOT_RISKY) return 'Pending';
    return 'Pending';
  }

  static getEvaluationStatusForAdmin(task = null) {
    if (!task) return '';
    let status = (task?.evaluationStatus ?? '').toUpperCase();

    if (status === this.EVALUATION_STATUS.INACTIVE) return 'Inactive';
    if (status === this.EVALUATION_STATUS.PASSED) return 'Passed';
    if (status === this.EVALUATION_STATUS.FAILED) return 'Failed';
    if (status === this.EVALUATION_STATUS.RISKY) return 'Risky';
    if (status === this.EVALUATION_STATUS.NOT_RISKY) return 'Not Risky';
    return 'Unknown';
  }

  static getStatusOptions = () => {
    return Object.keys(this.STATUS).map((key) => ({
      value: key,
      label: (this.STATUS[key] === TaskResult.STATUS.PENDING)
        ? 'In Progress'
        : Utils.enumToCamelcase(this.STATUS[key]),
    }));
  };

  static getEvaluationStatusOptions = () => {
    return Object.keys(this.EVALUATION_STATUS).map((key) => ({
      value: key,
      label: Utils.enumToCamelcase(this.EVALUATION_STATUS[key]),
    }));
  };

  static getPaymentStatusOptions = () => {
    return Object.keys(this.PAYMENT_STATUS).map((key) => ({
      value: key,
      label: Utils.enumToCamelcase(this.PAYMENT_STATUS[key]),
    }));
  };

  static getStatusColor = (type, status) => {
    if (type === 'STATUS') {
      switch (true) {
        case status === this.STATUS.INACTIVE:
          return { bg: 'bg-gray-200', border: 'border-gray-300' };
        case status === this.STATUS.ROLLED_BACK:
          return { bg: 'bg-orange-200', border: 'border-orange-300' };
        case status === this.STATUS.PENDING_REVIEW:
          return { bg: 'bg-blue-200', border: 'border-blue-300' };
        case status === this.STATUS.REVIEWED:
          return { bg: 'bg-blue-500', border: 'border-blue-600', text: 'text-white' };
        case status === this.STATUS.ON_HOLD:
          return { bg: 'bg-yellow-200', border: 'border-yellow-300' };
        case status === this.STATUS.DELIVERED:
          return { bg: 'bg-green-200', border: 'border-green-300' };
        default:
          return { bg: '', border: '' };
      }
    } else if (type === 'EVALUATION_STATUS') {
      switch (true) {
        case status === this.EVALUATION_STATUS.FAILED:
          return { bg: 'bg-red-200', border: 'border-red-300' };
        case status === this.EVALUATION_STATUS.RISKY:
          return { bg: 'bg-orange-200', border: 'border-orange-300' };
        case status === this.EVALUATION_STATUS.INACTIVE:
          return { bg: 'bg-gray-200', border: 'border-gray-300' };
        case status === this.EVALUATION_STATUS.NOT_RISKY:
          return { bg: 'bg-blue-200', border: 'border-blue-300' };
        case status === this.EVALUATION_STATUS.PASSED:
          return { bg: 'bg-green-200', border: 'border-green-300' };
        default:
          return { bg: '', border: '' };
      }
    }
  };
}
