export default class Task {
  static TEMPLATE = {
    MSI: { name: 'MSI', requiresTokenizedFiles: false },
    LINGUISTIC: { name: 'LINGUISTIC', requiresTokenizedFiles: false },
    SPEECH_RECORDING: { name: 'SPEECH_RECORDING', requiresTokenizedFiles: false },
    TEXT_ANNOTATION: { name: 'TEXT_ANNOTATION', requiresTokenizedFiles: true },
  };

  static POLICY = {
    INSERT: 'INSERT',
    EDIT: 'EDIT',
    REVIEW: 'REVIEW',
    EDIT_REVIEW: 'EDIT_REVIEW',
  };

  static TYPE = {
    TRANSLATION: {
      sid: 'TRANSLATION',
      name: 'Translation',
      template: Task.TEMPLATE.LINGUISTIC,
      policy: Task.POLICY.INSERT,
    },
    REVIEW: {
      sid: 'REVIEW',
      name: 'Review',
      template: Task.TEMPLATE.LINGUISTIC,
      policy: Task.POLICY.EDIT_REVIEW,
    },
    MSI: {
      sid: 'DATA_EVALUATION',
      name: 'Data Evaluation',
      template: Task.TEMPLATE.MSI,
      policy: Task.POLICY.INSERT,
    },
    POST_EDITING: {
      sid: 'POST_EDITING',
      name: 'Post Editing',
      template: Task.TEMPLATE.LINGUISTIC,
      policy: Task.POLICY.EDIT,
    },
    SPEECH_RECORDING: {
      sid: 'SPEECH_RECORDING',
      name: 'Speech Recording',
      template: Task.TEMPLATE.SPEECH_RECORDING,
      policy: Task.POLICY.INSERT,
    },
    SPEECH_RECORDING_REVIEW: {
      sid: 'SPEECH_RECORDING_REVIEW',
      name: 'Speech Recording Review',
      template: Task.TEMPLATE.SPEECH_RECORDING,
      policy: Task.POLICY.REVIEW,
    },
    TEXT_ANNOTATION: {
      sid: 'TEXT_ANNOTATION',
      name: 'Text Annotation',
      template: Task.TEMPLATE.TEXT_ANNOTATION,
      policy: Task.POLICY.INSERT,
    },
    TEXT_ANNOTATION_REVIEW: {
      sid: 'TEXT_ANNOTATION_REVIEW',
      name: 'Text Annotation Review',
      template: Task.TEMPLATE.TEXT_ANNOTATION,
      policy: Task.POLICY.REVIEW,
    },
  };

  static STATUS = {
    INACTIVE: 'INACTIVE',
    ACTIVE: 'ACTIVE',
    PENDING: 'PENDING',
    SUBMITTED: 'SUBMITTED',
    PENDING_SUBMISSION_QE: 'PENDING_SUBMISSION_QE',
    PENDING_CHECK: 'PENDING_CHECK',
    PENDING_REVIEW: 'PENDING_REVIEW',
    REVIEWED: 'REVIEWED',
    COMPLETED: 'COMPLETED',
    ROLLED_BACK: 'ROLLED_BACK',
  };

  static PERMISSIONS = {
    READ: 'READ',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
    REQUEST: 'REQUEST',
    REQUEST_PRIMARY: 'REQUEST_PRIMARY',
    REQUEST_REVIEW: 'REQUEST_REVIEW',
    REVIEW: 'REVIEW',
  };

  static getUserPermission(model = null) {
    return !model ? [] : model?.permissions ?? [];
  }

  static getTaskTypeOptions = () => {
    return Object.keys(this.TYPE).map((key) => ({ value: key, label: this.TYPE[key].name }));
  };
}
