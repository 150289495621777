import Utils from '../utils/Utils';

export default class UserAuth {
  static PERMISSIONS = {
    READ: 'READ',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
    CREATE: 'CREATE',
  };

  static getUserPermission(object = null) {
    return Utils.defineArray(object?.permissions, []);
  }

  static getGroupsBelong(object = null) {
    return Utils.defineArray(object?.groupsBelong, []);
  }
}
