// External libs
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CardBody, ButtonGroup } from 'reactstrap';
import $ from 'jquery';
import moment from 'moment';
import debounce from 'debounce-promise';

// QD libs
import Utils from '../../utils/Utils';
import DOMObject from '../../components/DOMObject';
import { Select } from '../../components/parameters/SelectParameter';
import ApiException from '../../models/ApiException';
import Notifier from '../../components/Notifier';
import { PAGES } from '../index';
import TASK from '../../models/Task';
import TASK_RESULT from '../../models/TaskResult';
import DataTable from '../../components/datatable/DatatableAjax';
import DataTables from '../../utils/DataTables';
import { Text } from '../../components/parameters/TextParameter';
import { Label } from '../../components/parameters/ParameterLabel';
import DateParameter from '../../components/parameters/DateParameter';
import Tooltip from '../../components/Tooltip';
import { ACTIONS } from '../../redux/actions/pages';
import { objectToQueryString, parseQueryString } from '../../utils/Url';
import { getTaskRemainingDateForClient } from '../../utils/DateHelper';
import API_STORAGE from '../../storage/ApiStorage';
import TaskResultController from '../../controllers/TaskResultController';
import VisibleColumnsSelect from '../../components/tables/VisibleColumnsSelect';

// Setup moment
try {
  moment.locale(window.navigator.language);
} catch (e) {}

class UserTasks extends DOMObject {
  constructor(props = {}) {
    super(props);

    this.datatable = null;
    this.state = this.loadUserPreferences();

    try {
      this.state.filters = this.getUrlFilters();
      if (!Utils.isEmpty(this.state.filters)) {
        this.savePageState();
      } else {
        this.setProps({ filters: this.getPropsFilters() });
      }
    } catch (e) {
      this.errorHandler(e);
    }
  }

  static defaultProps = {
    tableColumnsFixed: ['timestampSubmitted'],
    tableColumnsVisible: [
      'id',
      'taskId',
      'projectName',
      'typeName',
      'sourceLanguageCode',
      'targetLanguageCode',
      'status',
      'evaluationStatus',
      'paymentStatus',
      'timestampCreated',
      'timestampSubmitted',
    ], //not visible segments,submitted
    tableColumnsVisibilityPriority: [
      'timestampSubmitted',
      'typeName',
      'status',
      'paymentStatus',
      'evaluation',
      'timestampCreated',
      'id',
      'taskId',
      'projectName',
      'targetLanguageCode',
      'sourceLanguageCode',
    ],
    tableSort: [['id', 'desc']],
    emptyTableMessage: 'User has not claimed any task',
    saveUserState: null,
    filters: {},
  };

  PAGE_SID = Utils.define(PAGES.USER_TASKS_PAGE.sid(), 'USER_TASK_PAGE');

  state = {
    filters: {},
    tableColumnsVisible: UserTasks.defaultProps.tableColumnsVisible,
  };

  filters = {
    id: {
      validate: (value = null) => {
        if (!this.filters.id.isValid(value)) {
          return null;
        }
        if (typeof value === 'number') {
          return value >= 0 ? value : null;
        }
        if (typeof value === 'string') {
          return Utils.isEmpty(value) ? null : parseInt(value);
        }
        return null;
      },
      isValid: (value = null) => {
        if (Utils.isNull(value)) {
          return true;
        }
        if (typeof value === 'number') {
          return value >= 0;
        }
        if (typeof value === 'string') {
          return Utils.isEmpty(value) ? true : Utils.isNull(value.trim().match(/[\D]/g));
        }
        return false;
      },
    },
    project: {
      validate: (value = null) => {
        return Utils.isEmpty(value) ? null : value;
      },
      isValid: (value = null) => true,
    },
    from: {
      validate: (value = null) => {
        return !this.filters.from.isValid(value) ? null : value;
      },
      isValid: (value = null) => {
        return Utils.isEmpty(value)
          ? true
          : moment(value, ['DD/MM/YYYY', 'D/MM/YYYY', 'DD/M/YYYY', 'D/M/YYYY'], true).isValid();
      },
    },
    to: {
      validate: (value = null) => {
        return !this.filters.to.isValid(value) ? null : value;
      },
      isValid: (value = null) => {
        return Utils.isEmpty(value)
          ? true
          : moment(value, ['DD/MM/YYYY', 'D/MM/YYYY', 'DD/M/YYYY', 'D/M/YYYY'], true).isValid();
      },
    },
    type: {
      validate: (value = null) => {
        if (!this.filters.type.isValid(value)) {
          return null;
        }
        if (Array.isArray(value)) {
          return Utils.isEmpty(value) ? null : value.map((obj) => obj.trim().toUpperCase());
        } else {
          return Utils.isNull(value) ? null : value.trim().toUpperCase();
        }
      },
      isValid: (value = null) => {
        try {
          if (Array.isArray(value)) {
            return value.every((obj) =>
              Object.values(TASK.TYPE)
                .map((obj) => obj.sid)
                .includes(obj.trim().toUpperCase())
            );
          } else {
            return Utils.isNull(value)
              ? true
              : Object.values(TASK.TYPE)
                  .map((obj) => obj.sid)
                  .includes(value.trim().toUpperCase());
          }
        } catch (e) {
          return false;
        }
      },
    },
    status: {
      validate: (value = null) => {
        //if( !this.filters.status.isValid(value) ) return null;
        if (Utils.isEmpty(value)) {
          return null;
        }
        if (Array.isArray(value)) {
          value = value
            .filter((obj) => this.filters.status.validate(obj))
            .map((obj) => obj.toUpperCase().trim())
            .map((obj) => (obj === 'IN PROGRESS' ? 'PENDING' : obj));
          if (value.length === 0) {
            value = null;
          }
        } else {
          if (!this.filters.status.isValid(value)) {
            return null;
          }
          value = value.toUpperCase();
          if (value === 'IN PROGRESS') {
            value = 'PENDING';
          }
        }
        return value;
      },
      isValid: (value = null) => {
        try {
          if (Array.isArray(value)) {
            return value.every((obj) =>
              ['SUBMITTED', 'PENDING', 'IN PROGRESS'].includes(obj.toUpperCase())
            );
          } else {
            return Utils.isNull(value)
              ? true
              : ['SUBMITTED', 'PENDING', 'IN PROGRESS'].includes(value.toUpperCase());
          }
        } catch (e) {
          return false;
        }
      },
    },
    paymentStatus: {
      validate: (value = null) => {
        if (Utils.isEmpty(value)) {
          return null;
        }
        if (Array.isArray(value)) {
          value = value
            .filter((obj) => this.filters.paymentStatus.validate(obj))
            .map((obj) => obj.toUpperCase().trim())
            .map((obj) => (obj === 'ON HOLD' ? 'ON_HOLD' : obj));
          if (value.length === 0) {
            value = null;
          }
        } else {
          if (!this.filters.paymentStatus.isValid(value)) {
            return null;
          }
          value = value.toUpperCase();
          if (value === 'ON HOLD') {
            value = 'ON_HOLD';
          }
        }
        return value;
      },
      isValid: (value = null) => {
        try {
          if (Array.isArray(value)) {
            return value.every((obj) =>
              [
                'INACTIVE',
                'PENDING',
                'SUBMITTED',
                'NO_PAYMENT',
                'COMPLETED',
                'FAILED',
                'REJECTED',
                'UNKNOWN',
              ].includes(obj.toUpperCase())
            );
          } else {
            return Utils.isNull(value)
              ? true
              : [
                  'INACTIVE',
                  'PENDING',
                  'SUBMITTED',
                  'NO_PAYMENT',
                  'COMPLETED',
                  'FAILED',
                  'REJECTED',
                  'UNKNOWN',
                ].includes(value.toUpperCase());
          }
        } catch (e) {
          return false;
        }
      },
    },
  };

  userPreferences = {
    tableColumnsVisible: [],
  };

  // --------------------------------------------------------------------------------------
  // ---------------------------------- ReactJS Methods -----------------------------------
  componentDidUpdate(prevProps, prevState, snapshot) {
    try {
      let newFilters = {};
      let oldFilters = {};
      Object.keys(this?.filters).forEach((key) => {
        newFilters[key] = this.filters?.[key]?.validate(this.state?.filters?.[key]);
        oldFilters[key] = this.filters?.[key]?.validate(prevState?.filters?.[key]);
      });
      let dataTableReload = Object.keys(this?.filters).some(
        (key) =>
          JSON.stringify(Utils.define(oldFilters[key])) !==
          JSON.stringify(Utils.define(newFilters[key]))
      );
      if (dataTableReload === true && !Utils.isNull(this.datatable)) {
        this.datatable.table.draw();
      }

      if (this.props?.location?.search !== prevProps?.location?.search) {
        this.setState({ filters: this.getUrlFilters() });
      }

      if (this.state.tableColumnsVisible !== prevState.tableColumnsVisible) {
        this.saveUserPreferences();
      }

      this.savePageState();
    } catch (e) {
      this.errorHandler(e);
    }
  }

  render() {
    let tableProps = {
      ref: (ref) => {
        this.datatable = ref;
      },
      data: this.state.tasks,
      tableProps: this.dataTableProps(),
    };

    let filterIsEmpty = Object.values(this.state?.filters).every((obj) => {
      return Utils.isNull(obj);
    });

    return (
      <CardBody className='col-12 p-0 mt-2 bg-white'>
        <CardBody className='col-12 b'>
          <CardBody className='col-12 row p-0 mx-0 justify-content-between'>
            <div className='col-xs-12 col-md-9 col-xl-10 px-0 pt-2'>
              <h4 className='col-12'>
                Filters
                {filterIsEmpty === true ? null : (
                  <Tooltip title='Clear Filters'>
                    <button
                      id='clearFilters'
                      className='btn btn-secondary border-0 p-0 ml-3'
                      onClick={this.clearFiltersButtonClicked.bind(this)}
                    >
                      <em className='far fa-trash-alt' style={{ fontSize: '17px' }} />
                    </button>
                  </Tooltip>
                )}
              </h4>
              {this.renderFilterPanel()}
            </div>
            <ButtonGroup className='col-xs-12 col-md-3 col-xl-2 btn-group justify-content-end pt-2'>
              <VisibleColumnsSelect
                tableColumnsOptions={this.dataTableProps().columns.filter(
                  (column) => column.name !== 'action'
                )}
                visibleColumns={this.state.tableColumnsVisible}
                tableVisibleColumnsSelectChanged={this.tableVisibleColumnsSelectChanged.bind(this)}
              />
            </ButtonGroup>
          </CardBody>
          <DataTable {...tableProps} className='col-12 px-0 taus-datatable pt-2 whirl sphere' />
        </CardBody>
      </CardBody>
    );
  }

  renderFilterPanel() {
    return (
      <>
        <div className='col-12 row mx-0 px-0'>
          {/* Project */}
          {Utils.isNull(this.filters.project) ? null : (
            <Label
              label='Project:'
              className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'
            >
              <Text
                className='col-9'
                onChange={this.filterChanged.bind(this, 'project')}
                placeholder='Project Name'
                value={Utils.define(this.state?.filters?.project, '')}
              />
            </Label>
          )}

          {/* Id */}
          {Utils.isNull(this.filters.id) ? null : (
            <Label
              label='TaskRes ID:'
              className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'
            >
              <Text
                className='col-9'
                onChange={this.filterChanged.bind(this, 'id')}
                placeholder='#TaskRes ID'
                value={Utils.define(this.state?.filters?.id, '')}
                isValid={this.filters?.id?.isValid(this.state?.filters?.id)}
              />
            </Label>
          )}

          {/* Task Type */}
          {Utils.isNull(this.filters.type) ? null : (
            <Label
              label='Type:'
              className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'
            >
              <Select className='col-8 px-0' {...this.filterTypeParameterProps()} />
            </Label>
          )}

          {/* Task Status */}
          {Utils.isNull(this.filters.status) ? null : (
            <Label
              label='Status:'
              className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'
            >
              <Select className='col-9 px-0' {...this.filterStatusParameterProps()} />
            </Label>
          )}
        </div>

        <div className='col-12 row mx-0 px-0'>
          {/* From */}
          {Utils.isNull(this.filters.from) ? null : (
            <Label
              label='From:'
              className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'
            >
              <DateParameter
                className='col-9 px-0'
                onChange={(param) => {
                  this.filterChanged('from', param.getValue());
                }}
                updateValueFromProps={true}
                value={this.state?.filters?.from}
              />
            </Label>
          )}

          {/* To */}
          {Utils.isNull(this.filters.to) ? null : (
            <Label label='To:' className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'>
              <DateParameter
                className='col-9 px-0'
                onChange={(param) => {
                  this.filterChanged('to', param.getValue());
                }}
                updateValueFromProps={true}
                value={this.state?.filters?.to}
              />
            </Label>
          )}

          {/* Payment Status */}
          {Utils.isNull(this.filters.paymentStatus) ? null : (
            <Label
              label='Payment:'
              className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'
            >
              <Select className='col-8 px-0' {...this.filterPaymentStatusParameterProps()} />
            </Label>
          )}
        </div>
      </>
    );
  }

  // --------------------------------------------------------------------------------------
  // ---------------------------------- Properties Methods --------------------------------
  // --------------------------------------------------------------------------------------
  filterTypeParameterProps() {
    return {
      options: [
        { value: TASK.TYPE.REVIEW.sid, name: TASK.TYPE.REVIEW.name },
        { value: TASK.TYPE.TRANSLATION.sid, name: TASK.TYPE.TRANSLATION.name },
        { value: TASK.TYPE.MSI.sid, name: TASK.TYPE.MSI.name },
        { value: TASK.TYPE.POST_EDITING.sid, name: TASK.TYPE.POST_EDITING.name },
        { value: TASK.TYPE.SPEECH_RECORDING.sid, name: TASK.TYPE.SPEECH_RECORDING.name },
        {
          value: TASK.TYPE.SPEECH_RECORDING_REVIEW.sid,
          name: TASK.TYPE.SPEECH_RECORDING_REVIEW.name,
        },
        { value: TASK.TYPE.TEXT_ANNOTATION.sid, name: TASK.TYPE.TEXT_ANNOTATION.name },
        {
          value: TASK.TYPE.TEXT_ANNOTATION_REVIEW.sid,
          name: TASK.TYPE.TEXT_ANNOTATION_REVIEW.name,
        },
      ],
      value: Utils.defineToArray(this.filters?.type?.validate(this.state?.filters?.type), []),
      onChange: this.filterChanged.bind(this, 'type'),
      typeOptions: { placeholder: 'Task Type', isMulti: false },
    };
  }

  filterStatusParameterProps() {
    return {
      options: [
        { value: TASK.STATUS.PENDING, name: 'In Progress' },
        { value: TASK.STATUS.SUBMITTED, name: 'Submitted' },
      ],
      value: Utils.defineToArray(this.filters?.status?.validate(this.state?.filters?.status), []),
      onChange: this.filterChanged.bind(this, 'status'),
      typeOptions: { placeholder: 'Task Status', isMulti: false },
    };
  }

  filterPaymentStatusParameterProps() {
    return {
      options: [
        { value: TASK_RESULT.PAYMENT_STATUS.INACTIVE, name: 'Inactive' },
        { value: TASK_RESULT.PAYMENT_STATUS.PENDING, name: 'Pending' },
        { value: TASK_RESULT.PAYMENT_STATUS.SUBMITTED, name: 'Submitted' },
        { value: TASK_RESULT.PAYMENT_STATUS.NO_PAYMENT, name: 'No payment' },
        { value: TASK_RESULT.PAYMENT_STATUS.COMPLETED, name: 'Completed' },
        { value: TASK_RESULT.PAYMENT_STATUS.FAILED, name: 'Failed' },
        { value: TASK_RESULT.PAYMENT_STATUS.REJECTED, name: 'Rejected' },
        { value: TASK_RESULT.PAYMENT_STATUS.UNKNOWN, name: 'Unknown' },
      ],
      value: Utils.defineToArray(
        this.filters?.paymentStatus?.validate(this.state?.filters?.paymentStatus),
        []
      ),
      onChange: this.filterChanged.bind(this, 'paymentStatus'),
      typeOptions: { placeholder: 'Payment Status', isMulti: true },
    };
  }

  dataTableProps() {
    let getVisiblePriority = (name) => {
      let index = this.props.tableColumnsVisibilityPriority.indexOf(name);
      return index < 0 ? 1000 : index + 1;
    };
    let getVisible = (name) => this.state.tableColumnsVisible.includes(name);

    let thisObject = this;
    let options = {
      responsive: { details: { type: 'none', target: 'tr' } },
      paging: true,
      pageLength: 25,
      ordering: true,
      autoWidth: true,
      scrollX: false,
      //order: [[10,"asc"]],
      rowId: (row) => {
        try {
          return row.id;
        } catch (e) {
          this.errorHandler(e);
        }
      },
      columns: [
        {
          name: 'id',
          title: 'TaskRes ID',
          className: 'text-center',
          responsivePriority: getVisiblePriority('id'),
          visible: getVisible('id'),
          data: (row, type, set, meta) => {
            return Utils.define(row.id, '');
          },
        },
        {
          name: 'taskId',
          title: 'Task ID',
          className: 'text-center',
          responsivePriority: getVisiblePriority('taskId'),
          visible: getVisible('taskId'),
          data: (row, type, set, meta) => row?.task?.id ?? null,
        },
        {
          name: 'typeName',
          title: 'Type',
          orderable: true,
          className: 'text-center',
          responsivePriority: getVisiblePriority('typeName'),
          visible: getVisible('typeName'),
          data: (row, type, set, meta) => row?.type?.name ?? '',
        },
        {
          name: 'projectName',
          title: 'Project',
          orderable: true,
          className: '',
          responsivePriority: getVisiblePriority('projectName'),
          visible: getVisible('projectName'),
          data: (row, type, set, meta) => {
            try {
              return Utils.define(row.project.name, '');
            } catch (e) {
              return '';
            }
          },
        },
        {
          name: 'sourceLanguageCode',
          title: 'Source Language',
          orderable: true,
          className: 'text-center',
          responsivePriority: getVisiblePriority('sourceLanguageCode'),
          visible: getVisible('sourceLanguageCode'),
          data: (row, type, set, meta) => row?.sourceLanguage?.code ?? '',
        },
        {
          name: 'targetLanguageCode',
          title: 'Target Language',
          orderable: true,
          className: 'text-center',
          responsivePriority: getVisiblePriority('targetLanguageCode'),
          visible: getVisible('targetLanguageCode'),
          data: (row, type, set, meta) => {
            try {
              return Utils.define(row?.targetLanguage?.code, '');
            } catch (e) {
              return '';
            }
          },
        },
        {
          name: 'segments',
          title: 'Segments',
          orderable: false,
          className: 'text-center',
          responsivePriority: getVisiblePriority('segments'),
          visible: getVisible('segments'),
          data: (row, type, set, meta) => {
            return Utils.define(row.segmentsNum, '');
          },
        },
        {
          name: 'credits',
          title: 'Credits',
          orderable: true,
          className: 'text-center',
          responsivePriority: getVisiblePriority('credits'),
          visible: getVisible('credits'),
          data: (row, type, set, meta) => {
            return Utils.define(row.credits, '');
          },
        },
        {
          name: 'status',
          title: 'Status',
          orderable: true,
          responsivePriority: getVisiblePriority('status'),
          visible: getVisible('status'),
          data: (row, type, set, meta) => {
            const version = row?.version;
            try {
              let status = TASK_RESULT.getStatusForWorker(row);
              if (
                status.toLocaleUpperCase() === 'IN PROGRESS' &&
                moment(row?.timestampExpires).isValid()
              ) {
                const timestampExpires = getTaskRemainingDateForClient(row?.timestampExpires);

                status =
                  status +
                  '<br/> (expires on ' +
                  moment(timestampExpires).format('YYYY-MM-DD HH:mm') +
                  ')';
              } else if (status.toLocaleUpperCase() === 'ROLLED BACK') {
                status =
                  `<Tooltip title='This task has been requested to be worked on again.'><span class='tw-underline tw-cursor-help tw-text-red-400'>` +
                  status +
                  '</span></Tooltip>';
              }

              return status + (version > 0 ? ' - ' + version : '');
            } catch (e) {
              this.errorHandler(e);
              return '';
            }
          },
        },
        {
          name: 'evaluationStatus',
          title: 'Evaluation',
          orderable: false,
          responsivePriority: getVisiblePriority('EvaluationStatus'),
          visible: getVisible('evaluationStatus'),
          data: (row, type, set, meta) => TASK_RESULT.getEvaluationStatusForWorker(row),
        },
        {
          name: 'paymentStatus',
          title: 'Payment Status',
          orderable: true,
          responsivePriority: getVisiblePriority('PaymentStatus'),
          visible: getVisible('paymentStatus'),
          data: (row, type, set, meta) => TASK_RESULT.getPaymentStatusForWorker(row),
        },
        {
          name: 'timestampCreated',
          title: 'Date Claimed',
          orderable: true,
          className: '',
          responsivePriority: getVisiblePriority('timestampCreated'),
          visible: getVisible('timestampCreated'),
          data: (row, type, set, meta) => {
            try {
              return Utils.define(row.timestampCreated, '');
            } catch (e) {
              return '';
            }
          },
          render: (data, type, row, meta) => {
            if (type !== 'display') {
              return Utils.define(data, 0);
            }
            return moment(data).format('YYYY-MM-DD HH:mm');
          },
        },
        {
          name: 'timestampSubmitted',
          title: 'Date Submitted',
          orderable: true,
          className: 'text-right',
          responsivePriority: getVisiblePriority('timestampSubmitted'),
          visible: true, // set to 'true' directly since we always need this column to get displayed
          data: (row, type, set, meta) => {
            if (type !== 'display') {
              return Utils.define(row.timestampSubmitted, 0);
            }
            return row.status === 'PENDING' || row.status === 'ROLLED_BACK'
              ? '<button class="task-action-button btn btn-primary py-2">Resume</button>'
              : moment(row.timestampSubmitted).format('YYYY-MM-DD HH:mm');
          },
        },
      ],
      language: {
        emptyTable: this.props.emptyTableMessage,
        zeroRecords: this.props.emptyTableMessage,
        lengthMenu: 'Tasks per pages :&nbsp;&nbsp; _MENU_',
        info: '&nbsp;&nbsp;_START_-_END_ of _TOTAL_',
        infoEmpty: '&nbsp;&nbsp;_START_-_END_ of _TOTAL_',
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        select: { rows: { _: '' } },
      },
      rowCallback: (row, data, displayNum, displayIndex, dataIndex) => {
        try {
          // Init the task claim buttons
          $(row).find('.task-action-button').off('click');
          $(row)
            .find('.task-action-button')
            .on('click', thisObject.continueButtonClicked.bind(thisObject, row.id));
          // Set classes for hidden columns
          if (!Utils.isNull(thisObject.table)) {
            if (thisObject.table.getScreenHiddenColumns().length > 0) {
              row.classList.add('taus-text-underline');
              row.classList.add('taus-pointer');
            } else {
              row.classList.remove('taus-text-underline');
              row.classList.remove('taus-pointer');
            }
          }
        } catch (e) {
          this.errorHandler(e);
        }
      },
      serverSide: true,
      ajax: function (data, callback, settings) {
        let filters = DataTable.getAjaxParameters(data);
        filters = { ...filters, ...thisObject.filtersMapping() };
        thisObject.setBusy(true, '.taus-datatable');
        thisObject
          .getTasks(filters)
          .then((tasks) => {
            thisObject.state.tasksTotalSize = tasks.totalSize;
            callback({
              draw: data.draw,
              recordsFiltered: tasks.totalSize,
              recordsTotal: tasks.totalSize,
              data: tasks.page,
            });
            thisObject.setBusy(false, '.taus-datatable');
          })
          .catch((e) => {
            thisObject.errorHandler(e);
            thisObject.setBusy(false, '.taus-datatable');
          });
      },
    };

    if (Array.isArray(this.props.tableSort)) {
      options = DataTables.setOptionsOrderByName(options, this.props.tableSort);
    }
    return options;
  }

  // --------------------------------------------------------------------------
  // ------------------------------ User Preferences --------------------------
  // --------------------------------------------------------------------------
  saveUserPreferences() {
    try {
      let updatedKeys = Object.keys(this.userPreferences).filter(
        (key) => this.userPreferences[key] !== this.state[key]
      );
      if (updatedKeys?.length > 0) {
        updatedKeys.forEach((key) => {
          this.userPreferences[key] = this.state[key];
        });
        API_STORAGE.USER_PREFERENCES.add({ [this.PAGE_SID]: this.userPreferences });
      }
    } catch (e) {
      this.errorHandler(e);
    }
  }

  loadUserPreferences() {
    try {
      let prefs = API_STORAGE.USER_PREFERENCES.read()?.[this.PAGE_SID];
      return Utils.isEmpty(prefs) ? this.state : { ...this.state, ...prefs };
    } catch (e) {
      this.errorHandler(e);
    }
  }

  // ------------------------------------------------------------------------------
  // -------------------------------- Listeners -----------------------------------
  // ------------------------------------------------------------------------------
  filterChanged(name, value) {
    let filter = this?.filters?.[name];
    if (Utils.isNull(filter)) {
      return;
    }

    let filters = { ...this.state.filters };
    if (Utils.isEmpty(value)) {
      delete filters?.[name];
    } else {
      filters[name] = value;
    }

    this.setProps({ filters: filters });
  }

  clearFiltersButtonClicked() {
    this.setProps({ filters: {} });
  }

  tableVisibleColumnsSelectChanged(value) {
    try {
      this.setState({ tableColumnsVisible: value });
      this.datatable.setUserVisibleColumns(this.props.tableColumnsFixed.concat(value));
    } catch (e) {
      this.errorHandler(e, 'Table columns update failed.');
    }
  }

  // Continue task button listener
  continueButtonClicked(rowId) {
    try {
      this.setBusy(true);
      this.userContinueTask(rowId)
        .then((task) => {
          this.props.history.push(PAGES.TASK_RESULT_PAGE.path(task.id), {
            task: task,
            back: this.props.location,
          });
        })
        .catch((e) => {
          this.errorHandler(e, true);
        })
        .finally(() => {
          this.setBusy(false);
        });
    } catch (e) {
      this.errorHandler(e, true);
    }
  }

  // --------------------------------------------------------------------------
  // ------------------------------ Methods ------------------------------------
  // --------------------------------------------------------------------------
  // User continue task method
  userContinueTask(id) {
    return TaskResultController.get(id);
  }

  getPropsFilters(props = null) {
    let filters = {};
    try {
      props = Utils.isNull(props) ? this.props : props;
      Object.keys(Utils.define(props?.filters, {})).forEach((key) => {
        if (!Utils.isEmpty(this.filters[key])) {
          filters[key] = props.filters[key];
        }
      });
    } catch (e) {
      this.errorHandler(e);
    }
    return filters;
  }

  getUrlFilters(location = null) {
    try {
      location = Utils.isNull(location) ? this.props?.location : location;
      return parseQueryString(location?.search);
    } catch (e) {
      this.errorHandler(e);
      return {};
    }
  }

  savePageState() {
    if (!Utils.isNull(this.props.saveUserState)) {
      this.props.saveUserState(this.state);
    }
  }

  setProps(props = {}) {
    if (Utils.isDefined(props.filters)) {
      this.props.history.replace({
        pathname: this.props?.location?.pathname,
        search: objectToQueryString(props?.filters),
      });
    }
  }

  filtersMapping() {
    let filters = {};
    Object.keys(Utils.define(this.state?.filters, {})).forEach((key) => {
      let filter = Utils.define(this.filters[key]);
      if (Utils.isNull(filter)) return;
      let value = filter.validate(this.state?.filters[key]);
      if (Utils.isNull(value)) return;

      if (key === 'status') {
        filters['sql_status'] = value === 'PENDING' ? 'status = PENDING' : 'status >= SUBMITTED';
      } else if (key === 'project') {
        filters['sql_project'] = 'projectName ilike "%' + value + '%"';
      } else if (key === 'from') {
        filters['sql_from'] =
          'timestampSubmitted >= ' + moment(value, 'DD/MM/YYYY').utc().valueOf();
      } else if (key === 'to') {
        filters['sql_to'] = 'timestampSubmitted < ' + moment(value, 'DD/MM/YYYY').utc().valueOf();
      } else if (key === 'type') {
        filters['typeSid'] = this.state.filters[key];
      } else filters[key] = value;
    });
    return filters;
  }

  // User get task results method
  getTasks = debounce(this.getTasksNotDebounced, 1000);
  getTasksNotDebounced(filters = {}) {
    return TaskResultController.getAll({ ...filters, userId: this.props?.user?.id });
  }

  // --------------------------------------------------------------------------
  // ------------------------------ Errors ------------------------------------
  // --------------------------------------------------------------------------
  errorHandler(e = null, message = null) {
    try {
      e = ApiException.toApiException(e, message);
      Notifier.error(Utils.isNull(message) ? e.userMessage : message);
    } finally {
      super.errorHandler(e);
    }
  }
}

// ---------------------------------------------------------------------------
// ------------------------------------ Redux --------------------------------
// ---------------------------------------------------------------------------
const mapStateToProps = (state = {}, ownProps = {}) => {
  return {
    ...ownProps,
    user: state?.session?.user,
    ...Utils.define(state?.pages?.[PAGES.USER_TASKS_PAGE.sid()], {}),
  };
};
const mapDispatchToProps = (dispatch = null, ownProps = {}) => {
  return {
    saveUserState: (state) =>
      dispatch(ACTIONS.saveUserState(PAGES.USER_TASKS_PAGE.sid(), { filters: state.filters })),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserTasks));
