import React, { useState } from 'react';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FormFieldFooter } from './FormFieldFooter';
import '../../css/forms/input.scss';
import Utils from '../../utils/Utils'

const InputWithValidation = (props) => {
  const handleChange = (e) => {
    props.onChange(props.propertyName, e.target.value);

    // If and extra callback is defined, call that as well
    if (props.onValueChange) {
      props.onValueChange(e.target.value);
    }
  };

  const handleBlur = () => {
    if (!Utils.isDefined(props.onBlur)) return;
    props.onBlur(props.propertyName, true);
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

  let type;

  if (showPassword) {
    type = 'text';
  } else {
    type = props.type ?? 'text';
  }

  return (
    <div className={`${props.className ? props.className : 'form-group'}`}>
      {props.label ? (
        <Label htmlFor={props.id ? props.id : 'frmInput'} className='tw-mb-0'>
          {props.label}
        </Label>
      ) : null}
      <div className='input-group'>
        <input
          id={props.id ? props.id : 'frmInput'}
          className={`form-control ${!props.isLogin && 'tw-py-[1.135rem]'} ${
            !props.forceDefaultStyle && !!(props.error && props.touched) ? 'is-invalid' : ''
          } ${!props.forceDefaultStyle && !props.error && props.touched ? 'is-valid' : ''}`}
          type={type}
          style={{ paddingRight: 48 }}
          placeholder={props.placeholder}
          value={props.defaultValue}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={!props.forceDefaultStyle && !!(props.error && props.touched) ? 1 : 0}
          valid={!props.forceDefaultStyle && !props.error && props.touched ? 1 : 0}
          disabled={props.disabled}
          step={props.step}
          precision={props.precision}
          min={props.min}
          max={props.max}
          rows={props.rows}
          name={props.propertyName}
          autoComplete={props.autoComplete}
        ></input>
        {props.addons}
        {props.type === 'password' && (
          <FontAwesomeIcon
            onClick={togglePassword}
            icon={showPassword ? faEyeSlash : faEye}
            className='password-icon'
            size='lg'
            color='gray'
          />
        )}
      </div>
      <FormFieldFooter helpText={props.helpText} error={props.error} touched={props.touched} />
    </div>
  );
};

export default InputWithValidation;

InputWithValidation.propTypes = {
  propertyName: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  touched: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  isLogin: PropTypes.bool,
  forceDefaultStyle: PropTypes.bool,
  disabled: PropTypes.bool,
  step: PropTypes.number,
  precision: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  rows: PropTypes.number,
  onValueChange: PropTypes.func,
  autoComplete: PropTypes.string,
  addons: PropTypes.element,
};
