import React, { useEffect, useState } from 'react';
import Notifier from '../../../components/Notifier';
import ProjectCampaignController from '../../../controllers/ProjectCampaignsController';
import ProjectCampaignsView from '../views/ProjectCampaignsView';
import TextWithIcon from '../components/TextWithIcon';
import Tooltip from '../../../components/Tooltip';
import { Col, Collapse, Row } from 'reactstrap';
import ProjectCampaignForm from '../forms/ProjectCampaignForm';
import UserGroupController from '../../../controllers/UserGroupController';

export const ProjectCampaignsState = ({ project, roleOptions = [] }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 0, size: 10, sort: ['id', 'desc'] });
  const [totalElements, setTotalElements] = useState(0);
  const [isCreating, setCreating] = useState(false);
  const [isCompact, setCompact] = useState(false);

  const getCampaigns = (projectId, pagination, handleLoading = true) => {
    if (handleLoading) setLoading(true);
    ProjectCampaignController.list(projectId, pagination)
      .then((response) => {
        setCampaigns(response.content);
        setTotalElements(response.totalElements);
        setSelectedCampaign(null);
        setCreating(false);
      })
      .finally(() => {
        if (handleLoading) setLoading(false);
      });
  };

  useEffect(() => {
    getCampaigns(project.id, pagination);
  }, [project.id, pagination]);

  useEffect(() => {
    setCompact(!!selectedCampaign || isCreating);
  }, [selectedCampaign, isCreating]);

  const handleNew = () => {
    setCreating(true);
    setSelectedCampaign(null);
  };

  const handleCancel = () => {
    setCreating(false);
    setSelectedCampaign(null);
  };

  const handleCreate = (body) => {
    return new Promise((resolve, reject) => {
      ProjectCampaignController.create(project.id, body).then(
        (response) => {
          Notifier.success('Campaign created!');
          getCampaigns(project.id, pagination, false);
          resolve(response);
        },
        (error) => {
          Notifier.error(error.message);
          reject(error.message);
        }
      );
    });
  };

  const handleUpdate = async (campaignId, body) => {
    return new Promise((resolve, reject) => {
      ProjectCampaignController.update(project.id, campaignId, body).then(
        (response) => {
          Notifier.success('Campaign updated!');
          getCampaigns(project.id, pagination, false);
          resolve(response);
        },
        (error) => {
          Notifier.error(error.message);
          reject(error.message);
        }
      );
    });
  };

  const handleDelete = async (campaignId) => {
    let message = 'Are you sure you want to delete this campaign?';
    const campaign = campaigns.find((x) => x.id === campaignId);
    if (campaign && campaign.started && Date.now()< Date.parse(campaign.endDate))
      message += ' The campaign is currently active!!!';
    if (window.confirm(message)) {
      setLoading(true);
      try {
        await ProjectCampaignController.delete(project.id, campaignId);
        Notifier.success('Campaign deleted!');
        await getCampaigns(project.id, pagination);
      } catch (error) {
        Notifier.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const INSTRUCTIONS =
    'View and manage project campaigns. Click on a campaign to edit it. Note that started campaigns cannot be edited. The campaign will start only when the project is set to active.';

  return (
    <div className={loading ? 'sphere whirl' : ''}>
      <Tooltip title={INSTRUCTIONS}>
        <TextWithIcon
          size={3}
          text='Campaigns'
          icon='fas fa-calendar'
          outerClassName='mt-2 mb-3'
          innerClassName='text-center'
          cursor='help'
        />
      </Tooltip>
      <Row>
        <Col md={isCompact ? 6 : 12}>
          <ProjectCampaignsView
            isCompact={isCompact}
            campaigns={campaigns}
            pagination={pagination}
            totalElements={totalElements}
            onCurrentPageChange={(page) => setPagination((prev) => ({ ...prev, page }))}
            onPageSizeChange={(size) => setPagination({ page: 0, size, sort: ['id', 'desc'] })}
            onNew={handleNew}
            onCampaignDeleted={handleDelete}
            onCampaignSelected={setSelectedCampaign}
          />
        </Col>
        <Col md={isCompact ? 6 : 0}>
          <Collapse isOpen={isCompact}>
            <ProjectCampaignForm
              roleOptions={roleOptions}
              selectedCampaign={selectedCampaign}
              onCancel={handleCancel}
              onCampaignCreated={handleCreate}
              onCampaignUpdated={handleUpdate}
            />
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectCampaignsState;
