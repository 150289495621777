// External libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import { json2excel } from 'js2excel';
import moment from 'moment';
import { debounce, throttle } from 'throttle-debounce';

// Internal libs
import Utils from '../../../utils/Utils';
import API_STORAGE from '../../../storage/ApiStorage';
import { PAGES } from '../../index';
import USER from '../../../models/User';
import USER_PERMISSION from '../../../models/UserPermission';
import USERAUTH from '../../../models/UserAuth';
import USERGROUP from '../../../models/UserGroup';
import LANGUAGE from '../../../models/Language';
import MetadataController from '../../../controllers/MetadataController';
import UsersPageView from '../views/UsersPageView';

import UserGroupController from '../../../controllers/UserGroupController';
import UserController from '../../../controllers/UserController';
import ApiException from '../../../models/ApiException';
import Notifier from '../../../components/Notifier';
import { objectToQueryString, parseQueryString } from '../../../utils/Url';
import { withMessageBox } from '../../../contexts/MessageBoxContext';
import ProjectController from '../../../controllers/ProjectController';
import Project from '../../../models/Project';
import ErrorHandler from '../../../utils/ErrorHandler';

class UsersPage extends Component {
  constructor(props) {
    super(props);

    this.state = this.loadUserPreferences();
    this.state = this.loadHistory();
    this.state.filters = this.getUrlFilters(this.props.location);
  }

  static defaultProps = {};

  PAGE_SID = Utils.define(PAGES.USERS_PAGE.sid(), 'USERS_PAGE');

  state = {
    isLoading: true,

    tablePageIndex: 0,
    tablePageSize: 25,
    tableTotalPage: null,
    tableTotalSize: null,
    tableOrder: [{ columnName: 'username', direction: 'asc' }],

    users: null,
    usersSelected: [],
    usersNewUncommitted: [],

    languages: null,
    userGroups: null,

    tests: [],
    hiddenColumns: [],

    filters: {
      username: null,
      fullName: null,
      country: null,
      groupsBelong: [],
      ungroupedOnly: false,
      apiRole: [],
      provenance: [],
      enabled: [],
      spokenLanguage: [],
      spokenLanguageLevel: [],
      tests: [],
      score: [],
      scoreComparator: [],
      ethnicGroups: [],
      gender: [],
      dialects: [],
      yearOfBirthStart: [],
      yearOfBirthEnd: [],
      referralCode: null,
      referralReferrer : null,
      isReferred : false,
    },

    groupMenuValue: null,
    groupMenuUncommittedValue: null,

    userExportStart: false,
    userExportProgressMessage: 'Users are downloading...',
  };

  userPreferences = {
    tablePageSize: 25,
    hiddenColumns: [],
  };

  history = {
    tablePageIndex: 0,
    tablePageSize: 25,
    tableOrder: [{ columnName: 'username', direction: 'asc' }],
    filters: {},
  };

  // --------------------------------------------------------------------------------------
  // ---------------------------------- ReactJS Methods -----------------------------------
  // --------------------------------------------------------------------------------------
  componentDidMount() {
    try {
      Promise.all([
        this.requestUsers(this.getRequestParametersFromFilters()),
        this.requestUserGroups(),
        this.requestLanguages(),
        this.requestTests(),
      ])
        .then((results) => {
          this.setState({
            tablePageSize: results[0]?.pageSize ?? null,
            tableTotalSize: results[0]?.totalSize ?? null,
            tableTotalPage: results[0]?.totalPage ?? null,
            tablePageIndex: results[0]?.pageIndex ?? 0,
            users: results[0]?.page ?? [],
            userGroups: results[1]?.sort((a, b) => a?.name?.localeCompare(b?.name)),
            languages: results[2],
            tests: results[3]?.page ?? [],
            isLoading: false,
          });
        })
        .catch((e) => {
          this.errorHandler(e);
          this.setState({ tableIsLoading: false });
        });
    } catch (e) {
      this.errorHandler(e);
    }
  }

  componentDidUpdate() {
    Promise.resolve().then(() => {
      this.saveUserPreferences();
      this.saveHistory();
    });
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    let stateKeys = Object.keys(this.state).filter((key) => this.state[key] !== nextState[key]);
    let propsKeys = Object.keys(this.props).filter((key) => this.props[key] !== nextProps[key]);

    // Avoid update on updating the url on browser
    if (propsKeys.includes('location') && nextProps.history?.action === 'REPLACE')
      propsKeys = propsKeys.filter((obj) => !['location', 'match', 'history'].includes(obj));

    // state.filters and state.groupMenuUncommittedValue are only used to save user's inputs.
    const ignoredStates = ['filters', 'groupMenuUncommittedValue'];
    // state.usersNewUncommitted update only when new user is added. Else is used only for saving user's inputs
    if (this.state.usersNewUncommitted?.length === nextState.usersNewUncommitted?.length)
      ignoredStates.push('usersNewUncommitted');
    stateKeys = stateKeys?.filter((key) => !ignoredStates.includes(key)) ?? [];

    return stateKeys.length > 0 || propsKeys.length > 0;
  }

  render() {
    const userCanCreateUsers = USER.hasAnyPermission(
      this.props?.user,
      USER_PERMISSION.TYPES.USER,
      USERAUTH.PERMISSIONS.CREATE
    );
    const userCanDeleteSelectedUsers = this.state.users
      ?.filter((obj) => this.state.usersSelected?.includes(obj.id))
      .every((obj) => USERAUTH.getUserPermission(obj).includes(USERAUTH.PERMISSIONS.DELETE));
    const userCanCreateMessages = USER.hasAnyPermission(
      this.props?.user,
      USER_PERMISSION.TYPES.MESSAGE,
      ['CREATE']
    );
    const isAdmin = USER.isAdmin(this.props?.user);

    const hasSelectedUsersNotOnCurrentPage =
      this.state.usersSelected.filter((id) => !this.state.users.map((user) => user.id).includes(id))
        .length > 0;

    return (
      <UsersPageView
        isLoading={this.state.isLoading}
        users={this.state.users}
        groups={this.state.userGroups}
        tests={this.state.tests}
        spokenLanguages={this.state.languages}
        canCreate={userCanCreateUsers}
        canDelete={userCanDeleteSelectedUsers && !hasSelectedUsersNotOnCurrentPage}
        selectedUsers={this.state.usersSelected}
        onSelectedUserChange={this.onUsersSelected.bind(this)}
        onSelectedUsersClear={this.onSelectedUsersClear.bind(this)}
        onAddUserToRole={this.addUserToRole.bind(this)}
        onRemoveUserFromRole={this.removeUserFromRole.bind(this)}
        newUsers={this.state.usersNewUncommitted}
        onNewUserUpdate={this.onNewUserChange.bind(this)}
        onNewUserSubmit={throttle(500, this.usersCommitCreate.bind(this))}
        onUserDelete={throttle(500, this.usersCommitDelete.bind(this))}
        canManageGroups={!hasSelectedUsersNotOnCurrentPage}
        selectedGroups={this.state.groupMenuValue}
        onGroupSelectApply={this.groupMenuApplyButtonClick.bind(this)}
        onExportButtonClick={throttle(1000, this.exportToExcelButtonClicked.bind(this))}
        onRefreshButtonClick={throttle(1000, this.refreshButtonClicked.bind(this))}
        onDeleteButtonClick={this.deleteUsersButtonClicked.bind(this)}
        onCreateButtonClick={this.createUserButtonClicked.bind(this)}
        tablePageIndex={this.state.tablePageIndex}
        tablePageSize={this.state.tablePageSize}
        tableTotalSize={this.state.tableTotalSize}
        tableSorting={this.state.tableOrder}
        onTablePageChange={this.onPageChange.bind(this)}
        onTablePageSizeChange={this.pageSizeChanged.bind(this)}
        onTableSortingChange={this.sortingChanged.bind(this)}
        defaultFilterValues={this.state.filters}
        onFilterValueChange={this.onFilterValueChange.bind(this)}
        exportModalShow={this.state.userExportStart}
        exportModalMessage={this.state.userExportProgressMessage}
        onMessageButtonClick={this.onMessageButtonClick.bind(this)}
        canSendMessage={userCanCreateMessages}
        isAdmin={isAdmin}
        hiddenColumns={this.state.hiddenColumns}
        hiddenColumnsChanged={this.hiddenColumnsChanged.bind(this)}
      />
    );
  }

  loadUserTableDebounced = debounce(500, this.loadUserTable.bind(this));
  async loadUserTable(resetSelection = true) {
    try {
      this.setState({ isLoading: true });
      const result = await this.requestUsers(this.getRequestParametersFromFilters());
      const users = result?.page ?? [];
      if (resetSelection) {
        const selectedUsers =
          users?.filter((obj) => this.state?.usersSelected?.includes(obj.id)) ?? [];
        const selectedUserIds = selectedUsers.map((obj) => obj.id);
        this.setState({
          usersSelected: selectedUserIds,
        });
      }
      this.setState({
        tablePageSize: result?.pageSize ?? null,
        tableTotalSize: result?.totalSize ?? null,
        tableTotalPage: result?.totalPage ?? null,
        tablePageIndex: result?.pageIndex ?? 0,
        users: users,
      });
    } catch (e) {
      this.errorHandler(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  // -------------------------------------------------------------------
  // --------------------------------- TABLE ---------------------------
  // -------------------------------------------------------------------
  async onPageChange(pageIndex = 0) {
    this.setState({ tablePageIndex: pageIndex }, () => {
      this.loadUserTableDebounced(false);
    });
  }

  async pageSizeChanged(pageSize = 25) {
    this.setState({ tablePageSize: pageSize }, () => {
      this.loadUserTable(false);
    });
  }

  async sortingChanged(sorting = []) {
    this.setState({ tableOrder: sorting }, () => {
      this.loadUserTable(false);
    });
  }

  async onNewUserChange(data = []) {
    this.setState({ usersNewUncommitted: data });
  }

  async onUsersSelected(userIds = []) {
    try {
      let users = this.state?.users?.filter((obj) => userIds.includes(obj.id));
      let groups = {};
      users.forEach((user) => {
        USERAUTH.getGroupsBelong(user).forEach((group) => {
          if (!groups[group.id]) groups[group.id] = 1;
          else groups[group.id] = groups[group.id] + 1;
        });
      });

      let selected = [];
      let indeterminate = [];
      Object.keys(groups).forEach((groupId) => {
        groupId = Number(groupId);
        if (groups[groupId] === users.length) selected.push(groupId);
        else indeterminate.push(groupId);
      });

      this.setState({
        usersSelected: userIds,
        groupMenuValue: { selected: selected, indeterminate: indeterminate },
        groupMenuUncommittedValue: { selected: selected, indeterminate: indeterminate },
      });
    } catch (e) {
      this.errorHandler(e);
    }
  }

  onSelectedUsersClear() {
    this.setState({ usersSelected: [] });
  }

  async usersCommitDelete(ids = []) {
    try {
      let users = this.state.users?.filter((obj) => ids.includes(obj.id));
      let alert = await this.showDeleteConfirmPopup(users);
      if (alert.value !== true) return;

      this.setState({ isLoading: true });
      await Promise.all(
        users?.map((obj) =>
          this.requestUserDelete(obj.id).catch((e) => {
            this.errorHandler(e);
          })
        ) ?? []
      );
      await this.loadUserTable();
    } catch (e) {
      this.errorHandler(e);
      this.setState({ isLoading: false });
    }
  }

  async usersCommitCreate(data = []) {
    let commitStatus = {};
    try {
      this.setState({ isLoading: true });
      let usersNewUncommitted = Array.from(this.state.usersNewUncommitted);
      let usersNewCommitIds = data.map((obj) => obj.id) ?? [];
      let newUsers = usersNewUncommitted.filter((obj) =>
        usersNewCommitIds?.some((id) => id === obj.id)
      );
      newUsers = await Promise.all(
        newUsers.map((obj) =>
          this.userCommitCreate(obj)
            .then((res) => ({ status: true, result: res, rowId: obj.id }))
            .catch((e) => {
              this.errorHandler(e);
              return { status: false, result: obj, rowId: obj.id };
            })
        )
      );
      // setup commit status
      newUsers.forEach((obj) => {
        commitStatus[obj.rowId] = obj.status;
      });

      let succeed = newUsers.filter((obj) => obj.status === true);
      usersNewUncommitted = usersNewUncommitted.filter((obj) =>
        succeed?.every((o) => o.rowId !== obj.id)
      );

      this.setState({
        isLoading: false,
        users: [...succeed.map((obj) => obj.result), ...this.state.users],
        usersNewUncommitted: usersNewUncommitted,
      });
    } catch (e) {
      this.errorHandler(e);
      this.setState({ isLoading: false });
    }
    return commitStatus;
  }

  async userCommitCreate(data) {
    let user = await this.requestUserCreate(data);
    if (!Utils.isEmpty(data.groupsBelong)) {
      try {
        await Promise.all(data.groupsBelong?.map((obj) => this.addUsersInGroup(obj, user)));
      } catch (e) {
        this.errorHandler(e);
        user = await this.requestUser(user.id);
      }
    }
    return user;
  }

  // -------------------------------------------------------
  // ------------------- FILTERS ---------------------------
  // -------------------------------------------------------
  async onFilterValueChange(filters = {}) {
    Object.keys(filters).forEach((filterName) => {
      if (['username', 'fullName', 'country', 'ungroupedOnly','isReferred','referralCode','referralReferrer'].includes(filterName ?? '')) {
        filters[filterName] = filters[filterName] ?? null;
      } else if (
        [
          'spokenLanguage',
          'spokenLanguageLevel',
          'apiRole',
          'provenance',
          'enabled',
          'groupsBelong',
          'tests',
          'scoreComparator',
          'score',
          'ethnicGroups',
          'gender',
          'dialects',
          'yearOfBirthStart',
          'yearOfBirthEnd',
        ].includes(filterName ?? '')
      ) {
        filters[filterName] = filters[filterName] ?? [];
      }
    });
    this.setState({ filters, tablePageIndex: 0 });
    this.loadUserTableDebounced();
  }

  hiddenColumnsChanged(hiddenColumns) {
    this.setState({ hiddenColumns });
  }

  // ------------------------------------------------------------------------
  // --------------------------------- BUTTON BAR ---------------------------
  // ------------------------------------------------------------------------
  async refreshButtonClicked() {
    return this.loadUserTable();
  }

  async createUserButtonClicked() {
    try {
      let id = -1;
      this.state.usersNewUncommitted.forEach((obj) => {
        if (id >= obj.id) id = obj.id - 1;
      });
      this.setState({
        usersNewUncommitted: [...this.state.usersNewUncommitted, { id: id, username: '' }],
      });
    } catch (e) {
      this.errorHandler(e);
    }
  }

  async deleteUsersButtonClicked() {
    try {
      let alert = await this.showDeleteConfirmPopup(
        this.state.users?.filter((obj) => this.state.usersSelected?.includes(obj.id))
      );
      if (alert.value !== true) return;

      this.setState({ isLoading: true });
      const requests = await Promise.all(
        this.state.usersSelected?.map((obj) =>
          this.requestUserDelete(obj)
            .then(() => ({ id: obj, status: true }))
            .catch((e) => {
              this.errorHandler(e);
              return { id: obj, status: false };
            })
        )
      );

      let succeed = requests.filter((obj) => obj.status === true).map((obj) => obj.id);
      if (succeed.length !== 0) await this.loadUserTable();
    } catch (e) {
      this.errorHandler(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async groupMenuValueChanged(value = {}) {
    this.setState({ groupMenuUncommittedValue: value });
  }

  async groupMenuApplyButtonClick(groupMenuUncommittedValue) {
    try {
      this.setState({ isLoading: true });

      let selected =
        groupMenuUncommittedValue?.selected?.filter(
          (obj) => !this.state.groupMenuValue?.selected?.includes(obj)
        ) ?? [];
      let deSelected = [
        ...this.state.groupMenuValue?.selected,
        ...this.state.groupMenuValue?.indeterminate,
      ];
      deSelected =
        deSelected
          ?.filter((obj) => !(groupMenuUncommittedValue?.selected?.includes(obj) ?? true))
          ?.filter((obj) => !(groupMenuUncommittedValue?.indeterminate?.includes(obj) ?? true)) ??
        [];

      let users = this.state.users?.filter((obj) => this.state?.usersSelected?.includes(obj.id));
      let groupsAddUsers = {};
      selected.forEach((groupId) => {
        groupsAddUsers[groupId] = users.filter((obj) =>
          USERAUTH.getGroupsBelong(obj).every((obj) => obj.id !== groupId)
        );
      });
      let groupsRemoveUsers = {};
      deSelected.forEach((groupId) => {
        groupsRemoveUsers[groupId] = users.filter((obj) =>
          USERAUTH.getGroupsBelong(obj).some((obj) => obj.id === groupId)
        );
      });

      let promises = [];
      let groups = this.state.userGroups?.filter(
        (obj) => selected.includes(obj.id) || deSelected.includes(obj.id)
      );
      groups.forEach((obj) => {
        let add = groupsAddUsers[obj.id];
        if (!Utils.isEmpty(add))
          promises.push(
            this.addUsersInGroup(obj, add).catch((e) => {
              this.errorHandler(e);
              return false;
            })
          );
        let remove = groupsRemoveUsers[obj.id];
        if (!Utils.isEmpty(remove))
          promises.push(
            this.removeUsersFromGroup(obj, remove).catch((e) => {
              this.errorHandler(e);
              return false;
            })
          );
      });

      await Promise.all(promises);
      await this.loadUserTable(false);
      await this.onUsersSelected(this.state.usersSelected);
      // this.setState({
      //     groupMenuValue: this.state.groupMenuUncommittedValue,
      //     groupMenuUncommittedValue: null,
      // },()=>this.loadUserTable());
    } catch (e) {
      this.errorHandler(e);
      this.setState({ isLoading: false });
    }
  }

  onMessageButtonClick() {
    const { openMessageBox } = this.props;
    openMessageBox(this.state.usersSelected);
  }

  // --------------------------------------------------------------
  // ------------------ EXPORT ------------------------------------
  // --------------------------------------------------------------
  async exportToExcelButtonClicked() {
    return this.exportUsersToExcel();
  }

  async exportUsersToExcel() {
    try {
      const exportSize = this.state.usersSelected.length || this.state.tableTotalSize || 'Unknown';
      this.setState({
        userExportStart: true,
        userExportProgressMessage: `Downloading ${exportSize} users...`,
      });
      let parameters = this.getRequestParametersFromFilters();
      parameters.pageSize = this.state.tableTotalSize;
      parameters.page = 1;

      const response = await this.requestUsers(parameters, true);
      this.setState({
        userExportProgressMessage: `Converting ${exportSize} users to excel...`,
      });

      let usersToParse = [];

      if (this.state.usersSelected.length > 0) {
        usersToParse = response?.page.filter((user) => this.state.usersSelected.includes(user.id));
      } else {
        usersToParse = response?.page;
      }

      const xlsData = await this.usersJsonToExcelFormat(usersToParse);

      json2excel({ data: xlsData, name: 'users', formatdate: 'dd/mm/yyyy' });

      this.setState({
        userExportStart: false,
        userExportProgressMessage: 'Export completed!',
      });
    } catch (e) {
      this.setState({
        userExportStart: false,
        userExportProgressMessage: 'Export failed!',
      });
      this.errorHandler(e);
    }
  }

  async usersJsonToExcelFormat(users = []) {
    try {
      const spokenLanguagesFormatter = (languages = []) => {
        return languages
          ?.sort((a, b) => {
            return (
              LANGUAGE.getSpokenLanguageLevelCode(b?.level) -
              LANGUAGE.getSpokenLanguageLevelCode(a?.level)
            );
          })
          ?.map((obj) => `${obj?.name}  (${obj?.level})`.trim())
          ?.join(', ');
      };

      const getScoresForSelectedTests = (user) => {
        const scores = {};
        this.state.filters.tests?.forEach((testId) => {
          const test = this.state.tests.find((test) => test.id === testId);
          let score = 0;
          const task = user.validationTasks.find(
            (validationTask) => validationTask.project.id === testId
          );
          if (task) {
            score = task.evaluationScore ?? 0;
          }
          scores[test.name] = score;
        });
        return scores;
      };

      const formatUserGroups = (groups) => groups?.map((group) => group?.name).join(', ');

      return (
        users?.map((obj) => {
          const testScores = getScoresForSelectedTests(obj);
          return {
            ID: obj.id,
            'Username (email)': obj.username ?? null,
            Name: `${obj?.firstName ?? ''} ${obj?.lastName ?? ''}`.trim(),
            'Ethnic group': obj?.userMetadata?.ethnicGroup ?? '',
            Gender: obj?.userMetadata?.gender ?? '',
            Dialect: obj?.userMetadata?.spokenDialect ?? '',
            'Year of birth': obj?.userMetadata?.yearOfBirth ?? '',
            Country: obj?.country ?? '',
            Groups: formatUserGroups(obj.groupsBelong),
            'Spoken Languages': spokenLanguagesFormatter(obj.spokenLanguages),
            'Registration Date': !obj?.timestampRegistered
              ? ''
              : moment(obj.timestampRegistered).format('YYYY-MM-DD HH:mm'),
            'Last Login': !obj?.timestampActive
              ? ''
              : moment(obj.timestampActive).format('YYYY-MM-DD HH:mm'),
            ...testScores,
          };
        }) ?? []
      );
    } catch (e) {
      this.errorHandler(e);
      return [];
    }
  }

  // --------------------------------------------------------------
  // ------------------ GROUPS ------------------------------------
  // --------------------------------------------------------------
  async showDeleteConfirmPopup(users) {
    return Swal.mixin({
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-outline-danger mx-2 px-5 btn-lg font-weight-bold',
        cancelButton: 'btn btn-outline-secondary mx-2 px-5 btn-lg font-weight-bold',
      },
    }).fire({
      title: 'User delete!',
      text:
        'You are about to delete user ' +
        users.map((obj) => obj.username).join(', ') +
        '. Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });
  }

  // -----------------------------------------------------------------------------------
  // --------------------------------- Requests ----------------------------------------
  // -----------------------------------------------------------------------------------
  getRequestParametersFromFilters() {
    let map = {};
    if (!!this.state?.filters?.username)
      map.sql_username = `username ilike "%${this.state?.filters.username}%"`;
    if (!!this.state?.filters?.fullName)
      map.sql_fullName = `fullName ilike "%${this.state?.filters.fullName}%"`;
    if (!!this.state?.filters?.country)
      map.sql_country = `country ilike "%${this.state?.filters?.country}%"`;

    if (!!this.state?.filters?.spokenLanguage?.length > 0)
      map.spokenLanguageId = this.state?.filters.spokenLanguage;
    if (this.state?.filters?.spokenLanguageLevel?.length > 0)
      map.spokenLanguageLevel = this.state?.filters.spokenLanguageLevel;
    if (this.state?.filters?.groupsBelong?.length > 0)
      map.groupsBelongId = this.state?.filters.groupsBelong;
    if (this.state?.filters?.tests?.length > 0) map.projectWorkId = this.state?.filters.tests;
    if (this.state?.tableOrder?.length > 0) {
      let orderBy =
        this.state?.tableOrder?.map((obj) => `${obj?.columnName} ${obj.direction}`)?.join(', ') ??
        '';
      if (!!orderBy) map.orderBy = orderBy;
    }
    if (this.state?.filters?.score?.length > 0 && this.state?.filters?.scoreComparator?.length) {
      map.sql_taskEvaluationScore = `${this.state.filters.scoreComparator[0]} ${this.state.filters.score[0]}`;
    }
    if (this.state?.filters?.ethnicGroups?.length > 0) {
      map.ethnicGroup = this.state.filters.ethnicGroups;
    }
    if (this.state?.filters?.gender?.length > 0) {
      map.gender = this.state.filters.gender;
    }
    if (this.state?.filters?.dialects?.length > 0) {
      map.spokenDialect = this.state.filters.dialects;
    }
    if (this.state?.filters?.ungroupedOnly) {
      map.unGrouped = this.state.filters.ungroupedOnly;
    }

    if (this.state?.filters?.apiRole?.length > 0) {
      map.apiRole = this.state.filters.apiRole;
    }

    if (this.state?.filters?.provenance?.length > 0) {
      map.provenance = this.state.filters.provenance;
    }

    if (this.state?.filters?.enabled?.length > 0) {
      map.enabled = this.state.filters.enabled;
    }

    if (!!this.state?.filters?.referralCode)
      map.sql_username = `referralCode ilike "%${this.state?.filters.referralCode}%"`;

    if (!!this.state?.filters?.referralReferrer)
      map.sql_username = `referralReferrer ilike "%${this.state?.filters.referralReferrer}%"`;

    if (this.state?.filters?.isReferred) {
      map.isReferred = this.state.filters.isReferred;
    }

    const yearOfBirthStart = this.state?.filters?.yearOfBirthStart;
    const yearOfBirthEnd = this.state?.filters?.yearOfBirthEnd;

    const delimiter = yearOfBirthStart?.length && yearOfBirthEnd?.length ? ' AND ' : '';
    const start = yearOfBirthStart?.length ? `yearOfBirth >= ${yearOfBirthStart[0]}` : '';
    const end = yearOfBirthEnd?.length ? `yearOfBirth <= ${yearOfBirthEnd[0]}` : '';
    const yearOfBirthQuery = `${start}${delimiter}${end}`;
    if (yearOfBirthQuery) {
      map.sql_yearOfBirth = yearOfBirthQuery;
    }

    map.page = (this.state?.tablePageIndex ?? 0) + 1;
    map.pageSize = this.state?.tablePageSize;
    return map;
  }

  async requestUsers(filters = null, excludePermissions = false) {
    return !excludePermissions
      ? UserController.getAll(filters)
      : UserController.getAll(filters, { 'serialize-permission': false });
  }

  async requestUser(userId) {
    return UserController.get(userId);
  }

  async requestUserGroups() {
    let pages = [];
    let pageNum = 0;
    do {
      pageNum = pageNum + 1;
      pages.push(
        await UserGroupController.getAll(
          { permissions: [USERGROUP.PERMISSIONS.MANAGE_MEMBERS], page: pageNum, pageSize: 1000 },
          { 'serialize-permission': false }
        )
      );
    } while (pageNum < (pages?.[pageNum - 1]?.totalPage ?? 0));

    let groups = [];
    pages.forEach((obj) => groups.push(...obj.page));
    return groups;
  }

  async addUsersInGroup(group, users) {
    return UserGroupController.addMembers(
      group.id,
      Utils.defineToArray(users, [])?.map((obj) => obj.id)
    );
  }

  async removeUsersFromGroup(group, users) {
    return UserGroupController.removeMembers(
      group.id,
      Utils.defineToArray(users, [])?.map((obj) => obj.id)
    );
  }

  async addUserToRole(userId, roleId) {
    try {
      this.setState({ isLoading: true });
      await UserController.addToRole(userId, roleId);
      const response = await this.requestUsers(this.getRequestParametersFromFilters());
      this.setState({
        users: response.page ?? [],
      });
    } catch (e) {
      this.errorHandler(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async removeUserFromRole(userId, roleId) {
    try {
      this.setState({ isLoading: true });
      await UserController.removeFromRole(userId, roleId);
      const response = await this.requestUsers(this.getRequestParametersFromFilters());
      this.setState({
        users: response.page ?? [],
      });
    } catch (e) {
      this.errorHandler(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async requestUserDelete(userId) {
    return UserController.delete(userId);
  }

  async requestUserCreate(user) {
    return UserController.create(user);
  }

  async requestLanguages() {
    return MetadataController.getLanguages();
  }

  async requestTests() {
    return ProjectController.getAll({ scope: Project.SCOPE.WORKER_VALIDATION.sid });
  }

  // --------------------------------------------------------------------------
  // ------------------------------ User Preferences --------------------------
  // --------------------------------------------------------------------------
  saveUserPreferences() {
    try {
      let updatedKeys = Object.keys(this.userPreferences).filter(
        (key) => this.userPreferences[key] !== this.state[key]
      );
      if (updatedKeys?.length > 0) {
        updatedKeys.forEach((key) => {
          this.userPreferences[key] = this.state[key];
        });
        API_STORAGE.USER_PREFERENCES.add({ [this.PAGE_SID]: this.userPreferences });
      }
    } catch (e) {
      this.errorHandler(e);
    }
  }

  loadUserPreferences() {
    try {
      let prefs = API_STORAGE.USER_PREFERENCES.read()?.[this.PAGE_SID];
      return Utils.isEmpty(prefs) ? this.state : { ...this.state, ...prefs };
    } catch (e) {
      this.errorHandler(e);
    }
  }

  // --------------------------------------------------------------------------
  // ------------------------------ History -----------------------------------
  // --------------------------------------------------------------------------
  saveHistory() {
    try {
      let updatedKeys = Object.keys(this.history).filter(
        (key) => this.history[key] !== this.state[key]
      );
      if (updatedKeys.length > 0) {
        updatedKeys.forEach((key) => {
          this.history[key] = this.state[key];
        });
        this.props.history.replace({
          pathname: this.props?.location?.pathname,
          state: this.history,
          search: objectToQueryString(this.state?.filters),
        });
      }
    } catch (e) {
      this.errorHandler(e, false);
    }
  }

  loadHistory() {
    try {
      if (Utils.isEmpty(this.props?.location?.state)) return this.state;
      this.history = this.props.location.state;
      return { ...this.state, ...this.history };
    } catch (e) {
      this.errorHandler(e);
    }
  }

  getUrlFilters(location = null) {
    let filters = {};
    try {
      filters = parseQueryString((location ?? this.props?.location)?.search ?? '');
    } catch (e) {
      this.errorHandler(e);
    }
    return {
      ...filters,
      groupsBelong: Utils.defineToArray(filters.groupsBelong),
      tests: Utils.defineToArray(filters.tests),
    };
  }

  // --------------------------------------------------------------------------
  // ------------------------------ Errors ------------------------------------
  // --------------------------------------------------------------------------
  errorHandler(e, message) {
    try {
      e = ApiException.toApiException(e, message);
      Notifier.error(Utils.isNull(message) ? e?.userMessage : message);
      ErrorHandler.errorHandle(e);
    } catch (e) {
      ErrorHandler.errorHandle(e);
    }
  }
}

// ------------------------------------------------------------------------------------
// ------------------------------- Redux ----------------------------------------------
// ------------------------------------------------------------------------------------
const mapStateToProps = (state = {}, ownProps) => ({
  ...ownProps,
  user: Utils.define(state?.session?.user),
});

export default connect(mapStateToProps)(withRouter(withMessageBox(UsersPage)));
