import React from 'react';
import './styles/ProjectSettingsView.scss';
import TextWithIcon from '../components/TextWithIcon';
import ProjectBasicSettingsFormView from '../forms/ProjectBasicSettingsFormView';

export const ProjectCreateView = ({
  onSaveButtonClick = null,
  onCancelButtonClick = null,
  isLoadingLanguages = true,
  languageOptions = [],
  isBusy = false,
}) => {
  return (
    <div className='container mt-2'>
      <div className='jumbotron'>
        <div className='row align-items-start'>
          <div className='col-sm-8 col-xs-12 align-items-start justify-content-start'>
            <h1 className='col-12'>Create Project</h1>
          </div>
          <div className='col-sm-4 col-xs-12 align-items-start d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-secondary btn-lg ml-2'
              onClick={onCancelButtonClick}
            >
              Cancel
            </button>
          </div>
        </div>
        <hr />
        <div className='taus-project-settings-view'>
          <div className='d-flex justify-content-between align-items-start mt-5 mb-3'>
            <TextWithIcon
              text={'Basic Attributes'}
              icon={'fas fa-globe'}
              outerClassName={'d-flex flex-fill justify-content-center'}
              innerClassName={'text-center'}
              size={3}
            />
          </div>
          <div className={isBusy ? ' sphere whirl' : ''}>
            <ProjectBasicSettingsFormView
              isLoadingLanguages={isLoadingLanguages}
              languageOptions={languageOptions}
              showCancelButton={false}
              onSaveButtonClick={onSaveButtonClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCreateView;
