import alasql from 'alasql';
import { Bar } from 'react-chartjs-2';
import { Tooltip } from '@mui/material';
import Utils from '../../../utils/Utils';
import { useEffect, useRef, useState } from 'react';
import TASK_RESULT from '../../../models/TaskResult';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chart as ChartJS, CategoryScale, registerables } from 'chart.js';
ChartJS.register(CategoryScale, ...registerables);

const EvaluationOverviewBarChart = ({ tasks, handleDownload }) => {
  const chartRef = useRef(null);
  const [barStats, setBarStats] = useState([]);
  const [barDatasets, setBarDatasets] = useState([]);

  const barOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      title: {
        display: true,
        text: '2a. Total task result overview of evaluation statuses',
      },
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const status = context.label;
            const stage = String(context.dataset.label).toUpperCase();
            const total = tasks.filter((t) => t.stage === stage).length;
            const count = context.raw;
            const percentageOfTotal = Number((count / total) * 100).toFixed(2);

            let label = [];
            label.push(`Total ${stage} stage tasks: ${total}`);
            label.push(`Total ${stage} stage ${status} tasks: ${count}`);
            label.push(`% of total submissions: ${percentageOfTotal}%`);

            return label;
          },
        },
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
        formatter: (value) => value,
      },
    },
  };

  const barData = {
    labels: ['Failed', 'Risky', 'Inactive', 'Not Risky', 'Passed'],
    datasets: barDatasets,
  };

  const setData = async () => {
    const groupByStatus =
      `select stage, ` +
      `sum(case when taskResult->evaluationStatus='${TASK_RESULT.EVALUATION_STATUS.FAILED}' then 1 else 0 end) as failed, ` +
      `sum(case when taskResult->evaluationStatus='${TASK_RESULT.EVALUATION_STATUS.RISKY}' then 1 else 0 end) as risky, ` +
      `sum(case when taskResult->evaluationStatus='${TASK_RESULT.EVALUATION_STATUS.INACTIVE}' then 1 else 0 end) as inactive, ` +
      `sum(case when taskResult->evaluationStatus='${TASK_RESULT.EVALUATION_STATUS.NOT_RISKY}' then 1 else 0 end) as notRisky, ` +
      `sum(case when taskResult->evaluationStatus='${TASK_RESULT.EVALUATION_STATUS.PASSED}' then 1 else 0 end) as passed ` +
      'from ? ' +
      'group by stage';
    let stats = await alasql.promise(groupByStatus, [tasks]);
    setBarStats(stats);
  };

  const setBarChartData = () => {
    const barChartData = barStats.map((item) => ({
      label: Utils.textFirstOnlyUpper(item.stage),
      data: Object.keys(item)
        .filter((key) => key !== 'stage')
        .map((i) => item[i]),
      backgroundColor: item.stage === 'PRIMARY' ? '#00d41999' : '#5693f599',
      borderColor: item.stage === 'PRIMARY' ? '#00d419' : '#5693f5',
      borderWidth: 1,
      minBarLength: 1,
    }));

    setBarDatasets(barChartData);
  };

  useEffect(() => {
    setData();
  }, []);

  useEffect(() => {
    setBarChartData();
  }, [barStats]);

  return (
    <div className='tw-w-full xl:tw-w-2/3 tw-mx-auto'>
      <div className='tw-relative'>
        <div className='tw-absolute tw-right-0 tw-top-0 tw-rounded tw-bg-blue-400 tw-w-7 tw-h-7 tw-flex tw-items-center tw-justify-center tw-cursor-pointer'>
          <Tooltip title='Download chart as image'>
            <FontAwesomeIcon
              icon={faDownload}
              size='lg'
              onClick={() => handleDownload('EvaluationOverviewBarChart', chartRef)}
              className='tw-text-white'
            />
          </Tooltip>
        </div>
        <Bar data={barData} options={barOptions} ref={chartRef} />
      </div>
    </div>
  );
};

export default EvaluationOverviewBarChart;
