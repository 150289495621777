import React from 'react';
import { Formik } from 'formik';
import { Button, Col, Form, Row } from 'react-bootstrap';

import InputWithValidation from '../../../components/forms/InputWithValidation';
import SelectWithValidation from '../../../components/forms/SelectWithValidation';
import Spinner from '../../../components/Spinner';
import SignatureBox from './SignatureBox';
import ConsentUK from './ConsentUK';
import ConsentUS from './ConsentUS';
import ConsentAU from './ConsentAU';
import ConsentUSUK from './ConsentUSUK';

import { findLabelFromNestedOptions, findOption } from '../../../utils/Select';

// Utilities
import {
  ancestryOptions,
  ancestriesUSUKOptions,
  AUAncestriesWithSub,
  computerOwnOptions,
  contributionOptions,
  deviceSurveyOptions,
  deviceUsageOptions,
  dialectOptions,
  ethnicGroupsWithSub,
  formRelatedLinks,
  frequencyOptions,
  genderOptions,
  highestEducationOptions,
  languageOptions,
  languageUSUKOptions,
  learningMethodOptions,
  USUKresidingCountry,
  getOriginCountriesOptions,
  raceOptions,
  residencyOptions,
  schemas,
  yearOfBirthOptions,
} from './Options';
import { getValidationSchema } from './ValidationSchema';
import { getInitialValues } from './InitialValues';

const DataCollectionForm = ({
  countryCode,
  handleUpdate,
  loading,
  isSubmitted,
  user,
  isUserEditPage = false,
}) => {
  const schema = schemas[countryCode];

  const initialValues = getInitialValues(user, countryCode);
  const validationSchema = getValidationSchema(countryCode);
  const extractLegalValues = (values, countryCode) => {
    const {
      consent_1,
      consent_2,
      consent_3,
      consent_4,
      consent_5,
      consent_6,
      consent_7,
      consent_8,
      consent_9,
      consent_10,
      consent_11,
      sign_name,
      sign_date,
      has_signed,
      ...extra
    } = { ...values };

    let legal = {
      consent_1,
      consent_2,
      consent_3,
      consent_4,
      consent_5,
      sign_name,
      sign_date: sign_date.toISOString(),
      has_signed,
    };

    if (['US', 'AU', 'USUK'].includes(countryCode)) {
      legal.consent_6 = consent_6;
      legal.consent_7 = consent_7;
      legal.consent_8 = consent_8;
      legal.consent_9 = consent_9;
      legal.consent_10 = consent_10;
    }

    if (['AU'].includes(countryCode)) {
      legal.consent_11 = consent_11;
    }

    return { legal, extra };
  };

  const extractAUAncestryValue = (ancestry) => {
    if (ancestry.length > 1) {
      return AUAncestriesWithSub[4].label;
    } else {
      return findLabelFromNestedOptions(AUAncestriesWithSub, ancestry[0]).label;
    }
  };

  const handleSubmit = (values) => {
    const { legal, extra } = extractLegalValues(values, countryCode);

    let userMetadata = {
      gender: values.gender,
      yearOfBirth: values.birth_year,
      extra: { ...extra, legal, questionnaire: countryCode },
    };

    // Country specific attributes
    // Ethnicity for UK
    // Race for US
    // Ancestry for AU
    switch (countryCode) {
      case 'UK':
        userMetadata.spokenDialect = values.spoken_dialect;
        userMetadata.ethnicGroup = findLabelFromNestedOptions(
          ethnicGroupsWithSub,
          values.ethnicity
        ).label;
        userMetadata.extra.ethnicity = [extra.ethnicity];
        break;
      case 'US':
        userMetadata.spokenDialect = values.spoken_dialect;
        userMetadata.ethnicGroup = values.race;
        userMetadata.extra.race = [extra.race];
        break;
      case 'AU':
        userMetadata.spokenDialect = values.dialect_self_au;
        userMetadata.extra.dialect = values.dialect_self_au;
        userMetadata.ethnicGroup = extractAUAncestryValue(values.ancestry);
        break;
      case 'USUK':
        let origin = '';
        if (values.origin_country === 'Other') origin = 'Other';
        else if (values.origin_country === 'China') origin = 'China';
        else if (values.origin_country === 'Japan') origin = 'Japan';
        else if (values.origin_country === 'South Korea') origin = 'Korea';
        else if (values.origin_country === 'Nigeria') origin = 'Nigeria';
        else if (values.origin_country === 'Mexico') origin = 'Mexico';
        else if (values.origin_country === 'Vietnam') origin = 'Vietnam';
        else origin = 'Caribbean';

        userMetadata.spokenDialect = values.native_language;
        userMetadata.ethnicGroup = origin;
        userMetadata.extra.origin = origin;
        break;
      default:
        break;
    }

    handleUpdate(userMetadata);
  };

  const consentForms = {
    UK: ConsentUK,
    US: ConsentUS,
    AU: ConsentAU,
    USUK: ConsentUSUK,
  };

  const ConsentForm = consentForms[countryCode];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(props) => {
        if (loading) {
          return <Spinner />;
        }
        return (
          <Form onSubmit={props.handleSubmit}>
            <div className={`tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-8`}>
              <SelectWithValidation
                id='gender'
                propertyName='gender'
                label={schema?.properties?.gender?.survey_question}
                helpText={schema?.properties?.gender?.survey_question}
                options={genderOptions(countryCode)}
                defaultOption={findOption(genderOptions(countryCode), props.values.gender)}
                touched={props.touched.gender}
                error={props.errors.gender}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                isDisabled={isUserEditPage ? false : isSubmitted}
              />
              <SelectWithValidation
                id='birth_year'
                propertyName='birth_year'
                label={schema?.properties?.birth_year?.survey_question}
                placeholder='YYYY'
                helpText={schema?.properties?.birth_year?.survey_question}
                options={yearOfBirthOptions(countryCode)}
                defaultOption={findOption(yearOfBirthOptions(countryCode), props.values.birth_year)}
                defaultValue={props.values.birth_year}
                touched={props.touched.birth_year}
                error={props.errors.birth_year}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                isDisabled={isUserEditPage ? false : isSubmitted}
              />
            </div>
            <hr className={isUserEditPage ? 'tw-hidden' : ''} />
            <div
              className={`tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-8 ${
                isUserEditPage ? 'tw-hidden' : ''
              }`}
            >
              <SelectWithValidation
                id='survey_device'
                propertyName='survey_device'
                label={schema?.properties?.survey_device?.survey_question}
                helpText={schema?.properties?.survey_device?.survey_question}
                options={deviceSurveyOptions(countryCode)}
                defaultOption={findOption(
                  deviceSurveyOptions(countryCode),
                  props.values.survey_device
                )}
                touched={props.touched.survey_device}
                error={props.errors.survey_device}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                isDisabled={isUserEditPage ? true : isSubmitted}
              />
              <SelectWithValidation
                id='contributed'
                propertyName='contributed'
                label={schema?.properties?.contributed?.survey_question}
                helpText={schema?.properties?.contributed?.survey_question}
                options={contributionOptions(countryCode)}
                defaultOption={findOption(
                  contributionOptions(countryCode),
                  props.values.contributed
                )}
                touched={props.touched.contributed}
                error={props.errors.contributed}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                isDisabled={isUserEditPage ? true : isSubmitted}
              />
              <InputWithValidation
                id='profession'
                propertyName='profession'
                label={schema?.properties?.profession?.survey_question}
                placeholder='Profession'
                helpText={schema?.properties?.profession?.survey_question}
                defaultValue={props.values.profession}
                touched={props.touched.profession}
                error={props.errors.profession}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                disabled={isUserEditPage ? true : isSubmitted}
              />
              <SelectWithValidation
                id='education'
                propertyName='education'
                label={schema?.properties?.education?.survey_question}
                helpText={schema?.properties?.education?.survey_question}
                options={highestEducationOptions(countryCode)}
                defaultOption={findOption(
                  highestEducationOptions(countryCode),
                  props.values.education
                )}
                touched={props.touched.education}
                error={props.errors.education}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                isDisabled={isUserEditPage ? true : isSubmitted}
              />
            </div>
            <hr className={isUserEditPage ? 'tw-hidden' : ''} />
            <div
              className={`tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-8 ${
                isUserEditPage ? 'tw-hidden' : ''
              }`}
            >
              <SelectWithValidation
                id='computer_ownership'
                propertyName='computer_ownership'
                label={schema?.properties?.computer_ownership?.survey_question}
                helpText={schema?.properties?.computer_ownership?.survey_question}
                options={computerOwnOptions}
                defaultOption={findOption(computerOwnOptions, props.values.computer_ownership)}
                touched={props.touched.computer_ownership}
                error={props.errors.computer_ownership}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                isDisabled={isUserEditPage ? true : isSubmitted}
              />
              <SelectWithValidation
                id='frequent_device'
                propertyName='frequent_device'
                label={schema?.properties?.frequent_device?.survey_question}
                helpText={schema?.properties?.frequent_device?.survey_question}
                options={deviceUsageOptions(countryCode)}
                defaultOption={findOption(
                  deviceUsageOptions(countryCode),
                  props.values.frequent_device
                )}
                touched={props.touched.frequent_device}
                error={props.errors.frequent_device}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                isDisabled={isUserEditPage ? true : isSubmitted}
              />
              <SelectWithValidation
                id='computer_frequency'
                propertyName='computer_frequency'
                label={schema?.properties?.computer_frequency?.survey_question}
                helpText={schema?.properties?.computer_frequency?.survey_question}
                options={frequencyOptions}
                defaultOption={findOption(frequencyOptions, props.values.computer_frequency)}
                touched={props.touched.computer_frequency}
                error={props.errors.computer_frequency}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                isDisabled={isUserEditPage ? true : isSubmitted}
              />
              <SelectWithValidation
                id='mobile_frequency'
                propertyName='mobile_frequency'
                label={schema?.properties?.mobile_frequency?.survey_question}
                helpText={schema?.properties?.mobile_frequency?.survey_question}
                options={frequencyOptions}
                defaultOption={findOption(frequencyOptions, props.values.mobile_frequency)}
                touched={props.touched.mobile_frequency}
                error={props.errors.mobile_frequency}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                isDisabled={isUserEditPage ? true : isSubmitted}
              />
              <SelectWithValidation
                id='streaming_frequency'
                propertyName='streaming_frequency'
                label={schema?.properties?.streaming_frequency?.survey_question}
                helpText={schema?.properties?.streaming_frequency?.survey_question}
                options={frequencyOptions}
                defaultOption={findOption(frequencyOptions, props.values.streaming_frequency)}
                touched={props.touched.streaming_frequency}
                error={props.errors.streaming_frequency}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                isDisabled={isUserEditPage ? true : isSubmitted}
              />
            </div>
            <hr className={isUserEditPage ? 'tw-hidden' : ''} />
            <div
              className={`tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-8 ${
                isUserEditPage ? 'tw-hidden' : ''
              }`}
            >
              <SelectWithValidation
                id='native_language'
                propertyName='native_language'
                label={schema?.properties?.native_language?.survey_question}
                placeholder='Native Language'
                helpText={schema?.properties?.native_language?.survey_question}
                options={countryCode === 'USUK' ? languageUSUKOptions : languageOptions}
                defaultOption={findOption(
                  countryCode === 'USUK' ? languageUSUKOptions : languageOptions,
                  props.values.native_language
                )}
                defaultValue={props.values.native_language}
                touched={props.touched.native_language}
                error={props.errors.native_language}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                isDisabled={isUserEditPage ? true : isSubmitted}
              />
              {['US', 'UK'].includes(countryCode) && (
                <SelectWithValidation
                  isMulti
                  id='spoken_languages'
                  propertyName='spoken_languages'
                  label={schema?.properties?.spoken_languages?.survey_question}
                  placeholder='Spoken Languages'
                  helpText={schema?.properties?.spoken_languages?.survey_question}
                  options={languageOptions}
                  defaultOption={findOption(languageOptions, props.values.spoken_languages)}
                  defaultValue={props.values.spoken_languages}
                  touched={!!props.touched.spoken_languages}
                  error={props.errors.spoken_languages}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  isDisabled={isUserEditPage ? true : isSubmitted}
                />
              )}
              {['AU', 'USUK'].includes(countryCode) && (
                <SelectWithValidation
                  isMulti
                  id='languages_spoken'
                  propertyName='languages_spoken'
                  label={schema?.properties?.languages_spoken?.survey_question}
                  placeholder='Spoken Languages'
                  helpText={schema?.properties?.languages_spoken?.survey_question}
                  options={countryCode === 'USUK' ? languageUSUKOptions : languageOptions}
                  defaultOption={findOption(
                    countryCode === 'USUK' ? languageUSUKOptions : languageOptions,
                    props.values.languages_spoken
                  )}
                  defaultValue={props.values.languages_spoken}
                  touched={!!props.touched.languages_spoken}
                  error={props.errors.languages_spoken}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  isDisabled={isUserEditPage ? true : isSubmitted}
                />
              )}
              <InputWithValidation
                type='number'
                min={1}
                id='english_speaking_years'
                propertyName='english_speaking_years'
                label={schema?.properties?.english_speaking_years?.survey_question}
                placeholder='Years of EN proficiency'
                helpText={schema?.properties?.english_speaking_years?.survey_question}
                defaultValue={props.values.english_speaking_years}
                touched={props.touched.english_speaking_years}
                error={props.errors.english_speaking_years}
                onChange={(propertyName, value) =>
                  props.setFieldValue(propertyName, parseInt(value, 10))
                }
                onBlur={props.setFieldTouched}
                disabled={isUserEditPage ? true : isSubmitted}
              />
              <SelectWithValidation
                id='english_learning_method'
                propertyName='english_learning_method'
                label={schema?.properties?.english_learning_method?.survey_question}
                helpText={schema?.properties?.english_learning_method?.survey_question}
                options={learningMethodOptions(countryCode)}
                defaultOption={findOption(
                  learningMethodOptions(countryCode),
                  props.values.english_learning_method
                )}
                touched={props.touched.english_learning_method}
                error={props.errors.english_learning_method}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                isDisabled={isUserEditPage ? true : isSubmitted}
              />
            </div>
            <hr className={isUserEditPage ? 'tw-hidden' : ''} />
            <div className={`tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-8`}>
              {['USUK'].includes(countryCode) && (
                <div className={isUserEditPage ? 'tw-hidden' : ''}>
                  <SelectWithValidation
                    id='residing_country'
                    propertyName='residing_country'
                    label={schema?.properties?.residing_country?.survey_question}
                    helpText={schema?.properties?.residing_country?.survey_question}
                    options={USUKresidingCountry}
                    defaultOption={findOption(USUKresidingCountry, props.values.residing_country)}
                    touched={props.touched.residing_country}
                    error={props.errors.residing_country}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                    isDisabled={isUserEditPage ? true : isSubmitted}
                  />
                </div>
              )}
              {['USUK'].includes(countryCode) && (
                <>
                  <SelectWithValidation
                    id='origin_country'
                    propertyName='origin_country'
                    label={schema?.properties?.origin_country?.survey_question}
                    helpText={schema?.properties?.origin_country?.survey_question}
                    options={getOriginCountriesOptions(false)}
                    defaultOption={findOption(
                      getOriginCountriesOptions(false),
                      props.values.origin_country
                    )}
                    touched={props.touched.origin_country}
                    error={props.errors.origin_country}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                    isDisabled={isUserEditPage ? false : isSubmitted}
                  />
                  <SelectWithValidation
                    id='english_learning_country'
                    propertyName='english_learning_country'
                    label={schema?.properties?.english_learning_country?.survey_question}
                    helpText={schema?.properties?.english_learning_country?.survey_question}
                    options={getOriginCountriesOptions(true)}
                    defaultOption={findOption(
                      getOriginCountriesOptions(true),
                      props.values.english_learning_country
                    )}
                    touched={props.touched.english_learning_country}
                    error={props.errors.english_learning_country}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                    isDisabled={isUserEditPage ? true : isSubmitted}
                    className={isUserEditPage ? 'tw-hidden' : ''}
                  />
                </>
              )}
              <SelectWithValidation
                id='current_residency_duration'
                propertyName='current_residency_duration'
                label={schema?.properties?.current_residency_duration?.survey_question}
                helpText={schema?.properties?.current_residency_duration?.survey_question}
                options={residencyOptions(countryCode)}
                defaultOption={findOption(
                  residencyOptions(countryCode),
                  props.values.current_residency_duration
                )}
                touched={props.touched.current_residency_duration}
                error={props.errors.current_residency_duration}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                isDisabled={isUserEditPage ? true : isSubmitted}
                className={isUserEditPage ? 'tw-hidden' : ''}
              />
              <InputWithValidation
                id='post_code'
                propertyName='post_code'
                label={schema?.properties?.post_code?.survey_question}
                placeholder='Post Code'
                helpText={schema?.properties?.post_code?.survey_question}
                defaultValue={props.values.post_code}
                touched={props.touched.post_code}
                error={props.errors.post_code}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                disabled={isUserEditPage ? true : isSubmitted}
                className={isUserEditPage ? 'tw-hidden' : ''}
              />
              {countryCode === 'UK' && !isUserEditPage && (
                <InputWithValidation
                  type='number'
                  min={1}
                  id='gb_residency_duration'
                  propertyName='gb_residency_duration'
                  label={schema?.properties?.gb_residency_duration?.survey_question}
                  placeholder='Years of residency'
                  helpText={schema?.properties?.gb_residency_duration?.survey_question}
                  defaultValue={props.values.gb_residency_duration}
                  touched={props.touched.gb_residency_duration}
                  error={props.errors.gb_residency_duration}
                  onChange={(propertyName, value) =>
                    props.setFieldValue(propertyName, parseInt(value, 10))
                  }
                  onBlur={props.setFieldTouched}
                  disabled={isUserEditPage ? true : isSubmitted}
                  className={isUserEditPage ? 'tw-hidden' : ''}
                />
              )}
              {countryCode === 'US' && !isUserEditPage && (
                <InputWithValidation
                  type='number'
                  min={1}
                  id='us_residency_duration'
                  propertyName='us_residency_duration'
                  label={schema?.properties?.us_residency_duration?.survey_question}
                  placeholder='Years of residency'
                  helpText={schema?.properties?.us_residency_duration?.survey_question}
                  defaultValue={props.values.us_residency_duration}
                  touched={props.touched.us_residency_duration}
                  error={props.errors.us_residency_duration}
                  onChange={(propertyName, value) =>
                    props.setFieldValue(propertyName, parseInt(value, 10))
                  }
                  onBlur={props.setFieldTouched}
                  disabled={isUserEditPage ? true : isSubmitted}
                  className={isUserEditPage ? 'tw-hidden' : ''}
                />
              )}
              {countryCode === 'AU' && !isUserEditPage && (
                <InputWithValidation
                  type='number'
                  min={1}
                  id='au_residency_duration'
                  propertyName='au_residency_duration'
                  label='How long have you been living in Australia (years)?'
                  placeholder='Years of residency'
                  helpText='How long have you been living in Australia (years)?'
                  defaultValue={props.values.au_residency_duration}
                  touched={props.touched.au_residency_duration}
                  error={props.errors.au_residency_duration}
                  onChange={(propertyName, value) =>
                    props.setFieldValue(propertyName, parseInt(value, 10))
                  }
                  onBlur={props.setFieldTouched}
                  disabled={isUserEditPage ? true : isSubmitted}
                  className={isUserEditPage ? 'tw-hidden' : ''}
                />
              )}
              {countryCode === 'USUK' && !isUserEditPage && (
                <InputWithValidation
                  type='number'
                  min={1}
                  id='residency_duration'
                  propertyName='residency_duration'
                  label={schema?.properties?.residency_duration?.survey_question}
                  placeholder='Years of residency'
                  helpText={schema?.properties?.residency_duration?.survey_question}
                  defaultValue={props.values.residency_duration}
                  touched={props.touched.residency_duration}
                  error={props.errors.residency_duration}
                  onChange={(propertyName, value) =>
                    props.setFieldValue(propertyName, parseInt(value, 10))
                  }
                  onBlur={props.setFieldTouched}
                  disabled={isUserEditPage ? true : isSubmitted}
                  className={isUserEditPage ? 'tw-hidden' : ''}
                />
              )}
            </div>
            <hr className={countryCode === 'USUK' && 'tw-hidden'} />
            {countryCode === 'UK' && (
              <Row>
                <Col sm={12} md={6}>
                  <SelectWithValidation
                    id='ethnicity'
                    propertyName='ethnicity'
                    label={schema?.properties?.ethnicity?.survey_question}
                    helpText={schema?.properties?.ethnicity?.survey_question}
                    options={ethnicGroupsWithSub}
                    defaultOption={findOption(ethnicGroupsWithSub, props.values.ethnicity)}
                    touched={props.touched.ethnicity}
                    error={props.errors.ethnicity}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                    isDisabled={isUserEditPage ? false : isSubmitted}
                  />
                </Col>
                <Col sm={12} md={6} className={isUserEditPage ? 'tw-hidden' : ''}>
                  <InputWithValidation
                    id='ethnicity_free_form'
                    propertyName='ethnicity_free_form'
                    label={schema?.properties?.ethnicity_free_form?.survey_question}
                    placeholder='Ethnicity description'
                    helpText={schema?.properties?.ethnicity_free_form?.survey_question}
                    defaultValue={props.values.ethnicity_free_form}
                    touched={props.touched.ethnicity_free_form}
                    error={props.errors.ethnicity_free_form}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                    disabled={isUserEditPage ? true : isSubmitted}
                  />
                </Col>
              </Row>
            )}
            {['UK', 'US'].includes(countryCode) && (
              <Row>
                <Col sm={12} md={6}>
                  <SelectWithValidation
                    isMulti
                    id='ancestry'
                    propertyName='ancestry'
                    label={schema?.properties?.ancestry?.survey_question}
                    helpText={schema?.properties?.ancestry?.survey_question}
                    options={ancestryOptions}
                    defaultOption={findOption(ancestryOptions, props.values.ancestry)}
                    touched={!!props.touched.ancestry}
                    error={props.errors.ancestry}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                    isDisabled={isUserEditPage ? false : isSubmitted}
                  />
                </Col>
              </Row>
            )}
            {['AU', 'USUK'].includes(countryCode) && (
              <Row>
                <Col
                  sm={12}
                  md={6}
                  className={isUserEditPage && countryCode === 'USUK' ? 'tw-hidden' : ''}
                >
                  <SelectWithValidation
                    isMulti
                    id='ancestry'
                    propertyName='ancestry'
                    label={schema?.properties?.ancestry?.survey_question}
                    helpText={schema?.properties?.ancestry?.survey_question}
                    options={countryCode === 'USUK' ? ancestriesUSUKOptions : AUAncestriesWithSub}
                    defaultOption={findOption(
                      countryCode === 'USUK' ? ancestriesUSUKOptions : AUAncestriesWithSub,
                      props.values.ancestry
                    )}
                    touched={!!props.touched.ancestry}
                    error={props.errors.ancestry}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                    isDisabled={isUserEditPage ? false : isSubmitted}
                  />
                </Col>
                <Col sm={12} md={6} className={isUserEditPage ? 'tw-hidden' : ''}>
                  <InputWithValidation
                    id='ancestry_write_in'
                    propertyName='ancestry_write_in'
                    label={schema?.properties?.ancestry_write_in?.survey_question}
                    placeholder='Ancestry description'
                    helpText={schema?.properties?.ancestry_write_in?.survey_question}
                    defaultValue={props.values.ancestry_write_in}
                    touched={props.touched.ancestry_write_in}
                    error={props.errors.ancestry_write_in}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                    disabled={isUserEditPage ? true : isSubmitted}
                  />
                </Col>
              </Row>
            )}
            {countryCode === 'US' && (
              <Row>
                <Col sm={12} md={6}>
                  <SelectWithValidation
                    id='race'
                    propertyName='race'
                    label={schema?.properties?.race?.survey_question}
                    helpText={schema?.properties?.race?.survey_question}
                    options={raceOptions}
                    defaultOption={findOption(raceOptions, props.values.race)}
                    touched={props.touched.race}
                    error={props.errors.race}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                    isDisabled={isUserEditPage ? false : isSubmitted}
                  />
                </Col>
                <Col sm={12} md={6} className={isUserEditPage ? 'tw-hidden' : ''}>
                  <InputWithValidation
                    id='race_free_form'
                    propertyName='race_free_form'
                    label={schema?.properties?.race_free_form?.survey_question}
                    placeholder='Race description'
                    helpText={schema?.properties?.race_free_form?.survey_question}
                    defaultValue={props.values.race_free_form}
                    touched={props.touched.race_free_form}
                    error={props.errors.race_free_form}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                    disabled={isUserEditPage ? true : isSubmitted}
                  />
                </Col>
              </Row>
            )}
            {['US', 'UK'].includes(countryCode) && (
              <Row>
                <Col sm={12} md={6} className={isUserEditPage ? 'tw-hidden' : ''}>
                  <SelectWithValidation
                    id='encountered_dialect'
                    propertyName='encountered_dialect'
                    label={schema?.properties?.encountered_dialect?.survey_question}
                    helpText={schema?.properties?.encountered_dialect?.survey_question}
                    options={dialectOptions(countryCode)}
                    defaultOption={findOption(
                      dialectOptions(countryCode),
                      props.values.encountered_dialect
                    )}
                    touched={props.touched.encountered_dialect}
                    error={props.errors.encountered_dialect}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                    isDisabled={isUserEditPage ? true : isSubmitted}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <SelectWithValidation
                    id='spoken_dialect'
                    propertyName='spoken_dialect'
                    label={schema?.properties?.spoken_dialect?.survey_question}
                    helpText={schema?.properties?.spoken_dialect?.survey_question}
                    options={dialectOptions(countryCode)}
                    defaultOption={findOption(
                      dialectOptions(countryCode),
                      props.values.spoken_dialect
                    )}
                    touched={props.touched.spoken_dialect}
                    error={props.errors.spoken_dialect}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                    isDisabled={isUserEditPage ? false : isSubmitted}
                  />
                </Col>
              </Row>
            )}
            {countryCode === 'AU' && (
              <Row>
                <Col sm={12} md={6} className={isUserEditPage ? 'tw-hidden' : ''}>
                  <SelectWithValidation
                    id='dialect_environment_au'
                    propertyName='dialect_environment_au'
                    label={schema?.properties?.dialect_environment_au?.survey_question}
                    helpText={schema?.properties?.dialect_environment_au?.survey_question}
                    options={dialectOptions(countryCode)}
                    defaultOption={findOption(
                      dialectOptions(countryCode),
                      props.values.dialect_environment_au
                    )}
                    touched={props.touched.dialect_environment_au}
                    error={props.errors.dialect_environment_au}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                    isDisabled={isUserEditPage ? true : isSubmitted}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <SelectWithValidation
                    id='dialect_self_au'
                    propertyName='dialect_self_au'
                    label={schema?.properties?.dialect_self_au?.survey_question}
                    helpText={schema?.properties?.dialect_self_au?.survey_question}
                    options={dialectOptions(countryCode)}
                    defaultOption={findOption(
                      dialectOptions(countryCode),
                      props.values.dialect_self_au
                    )}
                    touched={props.touched.dialect_self_au}
                    error={props.errors.dialect_self_au}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                    isDisabled={isUserEditPage ? false : isSubmitted}
                  />
                </Col>
              </Row>
            )}
            <Row className={isUserEditPage ? 'tw-hidden' : ''}>
              <Col sm={12}>
                {formRelatedLinks(countryCode).length > 0 && (
                  <>
                    For more info and audio samples on the listed dialects please check out the
                    links below:
                    <ul>
                      {formRelatedLinks(countryCode).map((link) => (
                        <li key={link}>
                          <a target='_blank' rel='noopener noreferrer' href={link}>
                            {link}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
                <hr />
                <p>
                  During your registration, you already agreed to the general{' '}
                  <a
                    href='https://taus-hlp-terms.s3.eu-west-1.amazonaws.com/HLP_Worker_Agreement_NL.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    TAUS Workers Agreement
                  </a>{' '}
                  and{' '}
                  <a
                    href='https://taus-hlp-terms.s3.eu-west-1.amazonaws.com/HLP_Privacy_and_Cookies_Policy.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Privacy and Cookies policy
                  </a>
                  . To perform voice recording work for this specific project, please agree to the
                  following additional terms which form part of the TAUS Terms.
                </p>
                <p>
                  In this consent form, the terms: (i) “process” or “processed” in relation to
                  personal data, includes, but is not limited to, the collection, use and disclosure
                  of such personal data; (ii) “data” includes information in any form; and (iii)
                  “biometric data” includes biometric templates.
                </p>
                <p>
                  {countryCode === 'AU' ? (
                    <b>
                      Australian project additional participation Agreement (please tick each box):
                    </b>
                  ) : (
                    <b>Please tick each box:</b>
                  )}
                </p>
                <ConsentForm
                  values={props.values}
                  touched={props.touched}
                  errors={props.errors}
                  setFieldTouched={props.setFieldTouched}
                  setFieldValue={props.setFieldValue}
                  isSubmitted={isUserEditPage ? true : isSubmitted}
                  isUserEditPage={isUserEditPage}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <SignatureBox
                  touched={props.touched.sign_name}
                  error={props.errors.has_signed}
                  signed={props.values.has_signed}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  signName={props.values.sign_name}
                  signDate={props.values.sign_date}
                  onSign={() => {
                    props.setFieldValue('sign_date', new Date());
                    props.setFieldValue('has_signed', true);
                  }}
                  disabled={isUserEditPage ? true : isSubmitted}
                />
                <Button type='submit' disabled={isUserEditPage ? false : isSubmitted}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DataCollectionForm;
