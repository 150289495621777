import React from 'react';
import Spinner from './Spinner';

const Loader = ({ isLoading = true }) => {
  return (
    isLoading && (
      <div className='tw-absolute tw-w-full tw-h-full tw-bg-gray-100 tw-bg-opacity-40 tw-left-0 tw-top-0 tw-rounded tw-z-10'>
        <Spinner></Spinner>
      </div>
    )
  );
};

export default Loader;
