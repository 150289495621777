import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../../../../components/Tooltip';

const AnnotationDisplay = ({
  children,
  isExpanded = false,
  annotations = [],
  hasMultipleTokens,
  onRemoveValue,
  onSplit,
  onDragOver,
  onDrop,
  fontSize,
}) => {
  const longestAnnotationChain =
    [...annotations].sort((ac1, ac2) => ac2.length - ac1.length)[0]?.length ?? 0;

  return (
    <div
      className={`m-1 py-1 ${hasMultipleTokens ? 'border border-dark' : ''}`}
      style={{
        fontSize,
        position: 'relative',
        borderRadius: 4,
        backgroundColor: hasMultipleTokens ? '#dcdcdc' : 'inherit',
      }}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      {hasMultipleTokens && (
        <button
          className='close px-1'
          onClick={onSplit}
          style={{
            position: 'absolute',
            top: -11,
            right: -13,
            zIndex: 1,
            opacity: 1,
          }}
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            size='sm'
            style={{ borderRadius: '50%', backgroundColor: 'white' }}
          />
        </button>
      )}
      <div className='d-inline-flex flex-wrap w-100' style={{ cursor: 'pointer' }}>
        {children}
      </div>
      {annotations.map((annotationChain) => {
        return (
          <div
            key={annotationChain.map((annotation) => annotation.value).join('-')}
            onDoubleClick={() => onRemoveValue(annotationChain)}
            style={{
              display: 'flex',
              cursor: 'pointer',
              userSelect: 'none',
              color: 'white',
            }}
            className='mt-1 mx-1'
          >
            <div
              className='d-flex flex-grow-1'
              style={{ maxWidth: isExpanded ? 'none' : `${longestAnnotationChain * 5}em` }}
            >
              {annotationChain.map((annotation, i) => {
                const isFirst = i === 0;
                const isLast = i === annotationChain.length - 1;
                const borderRadius = {
                  borderTopLeftRadius: isFirst ? 4 : 0,
                  borderBottomLeftRadius: isFirst ? 4 : 0,
                  borderTopRightRadius: isLast ? 4 : 0,
                  borderBottomRightRadius: isLast ? 4 : 0,
                };

                const expanded = {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  maxWidth: isExpanded ? 'none' : '5em',
                };

                return (
                  <Tooltip key={annotation.value} title={annotation.label}>
                    <span
                      className='d-block px-2'
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        flexGrow: 1,
                        backgroundColor: annotation.color,
                        ...borderRadius,
                        ...expanded,
                      }}
                    >
                      {annotation.label}
                    </span>
                  </Tooltip>
                );
              })}
            </div>
            <button
              className='close pl-1'
              style={{ opacity: 1 }}
              onClick={() => onRemoveValue(annotationChain)}
            >
              <FontAwesomeIcon
                icon={faTimesCircle}
                size='sm'
                style={{ borderRadius: '50%', backgroundColor: 'white' }}
              />
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default AnnotationDisplay;
