import PropTypes from 'prop-types';
import React from 'react';
import MultiSelectWithCheckboxes from '../selects/MultiSelectWithCheckboxes';
import Tooltip from '../Tooltip';

const VisibleColumnsSelect = ({
  tableColumnsOptions,
  visibleColumns,
  tableVisibleColumnsSelectChanged,
}) => {
  let columnOptions = tableColumnsOptions.map((col) => {
    return { value: col.name, label: col.title };
  });
  const value = columnOptions.filter((col) => {
    return visibleColumns.includes(col.value);
  });

  const handleChange = (selectedOptions) => {
    tableVisibleColumnsSelectChanged(selectedOptions.map(({ value }) => value));
  };

  return (
    <MultiSelectWithCheckboxes
      options={columnOptions}
      value={value}
      onChange={handleChange}
      toggleButton={
        <Tooltip title='Select Visible Columns'>
          <button className='btn btn-secondary'>
            <em className='fas fa-columns' style={{ fontSize: '20px' }} />
          </button>
        </Tooltip>
      }
    />
  );
};

export default VisibleColumnsSelect;

VisibleColumnsSelect.propTypes = {
  tableColumnsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  visibleColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableVisibleColumnsSelectChanged: PropTypes.func.isRequired,
};
