import { combineReducers, createStore } from 'redux';

import Utils from '../../utils/Utils';
import session from '../reducers/session';
import pages from '../reducers/pages';
import ApiException from '../../models/ApiException';

// ------------------------------------------------- App Cookies
// ---------------------------------------------------- App Store
const getInitialState = () => {
  try {
    let state = localStorage.getItem('hlp-app-state');
    if (Utils.isNull(state)) return { session: null, pages: {} };
    else return { ...JSON.parse(state), session: null };
  } catch (e) {
    console.error(ApiException.toApiException(e));
    return { session: null, pages: {} };
  }
};

const appReducer = combineReducers({
  session: session,
  pages: pages,
});

export const appStore = createStore(appReducer, getInitialState());

appStore.subscribe(() => {
  try {
    let saveState = { ...appStore.getState() };
    delete saveState.session;
    localStorage.setItem('hlp-app-state', JSON.stringify(saveState));
  } catch (e) {
    console.error(ApiException.toApiException(e));
  }
});
