import { useState } from 'react';

import Notifier from '../../../../components/Notifier';
import TaskResultController from '../../../../controllers/TaskResultController';
import { ROOT } from '../../../project/TagBuilder';
import { prepareDataForSending, useAnnotationTargets } from '../../hooks/useAnnotationTargets';
import TaskToolbar from '../TaskToolbar';
import AnnotationSegment from './AnnotationSegment';

const TaskAnnotationView = ({ task, scope, taskIsUpdated }) => {
  const { targets, changeTargets, onDataChanged } = useAnnotationTargets(task);

  const handleSave =
    (segmentIds = []) =>
    async () => {
      changeTargets(segmentIds, { loadingData: true });
      try {
        const content = segmentIds.map((segmentId) => {
          return {
            id: parseInt(segmentId),
            data: JSON.stringify(prepareDataForSending(targets[segmentId].data)),
          };
        });

        const newTask = await saveTask(task.id, content);
        taskIsUpdated(newTask);
      } catch (error) {
        Notifier.error(error.message);
      } finally {
        changeTargets(segmentIds, { loadingData: false, changed: false });
      }
    };

  const unsavedTargetIds = Object.keys(targets).filter(
    (id) => targets[id].changed && !targets[id].loadingData && targets[id].valid
  );

  const invalidSegments = Object.keys(targets).filter((id) => !targets[id].valid);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const newTask = await submitTask(task.id);
      taskIsUpdated(newTask, true);
    } catch (error) {
      Notifier.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const toolbar = (
    <TaskToolbar
      task={task}
      scope={scope}
      invalidSegments={invalidSegments}
      unsavedTargets={unsavedTargetIds}
      onSaveButtonClicked={handleSave(unsavedTargetIds)}
      onSubmitButtonClicked={handleSubmit}
    />
  );

  const targetLanguage = task?.targets?.[0]?.language;
  const tagDefinitions = task.options?.tagDefinitions ?? {};

  let segmentsCounter = 0;
  let totalSegments = task?.targets?.length;

  return (
    <div className={`${isSubmitting ? 'whirl sphere' : ''}`}>
      {toolbar}
      <div className='card' style={{ boxShadow: 'none' }}>
        <div className='card-body p-0'>
          {task?.targets.map((segment) => {
            segmentsCounter++;
            return (
              <div id={segment.id} className='tw-flex tw-flex-row'>
                <div>
                  <span className='tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-border tw-border-solid tw-border-gray-200 tw-rounded tw-w-8 tw-h-7'>
                    {segmentsCounter}/{totalSegments}
                  </span>
                </div>
                <div className='tw-w-full tw-pt-1'>
                  <AnnotationSegment
                    key={segment.id}
                    segment={segment}
                    targetLanguage={targetLanguage}
                    data={targets[segment.id].data}
                    onDataChanged={onDataChanged}
                    isLoading={targets[segment.id].loadingData}
                    isChanged={!!targets[segment.id].changed}
                    isValid={!!targets[segment.id].valid}
                    errorMessages={targets[segment.id].errorMessages}
                    handleSave={handleSave([segment.id])}
                    tagDefinitions={tagDefinitions}
                    root={ROOT}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {toolbar}
    </div>
  );
};

export default TaskAnnotationView;

const saveTask = (id, data) => {
  return TaskResultController.save(id, data);
};

const submitTask = (id) => {
  return TaskResultController.submit(id);
};
