import { intervalToDuration, isValid, parse } from 'date-fns';

export function formatDate(date) {
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

  return day + '/' + (month + 1) + '/' + year + ' ' + hours + ':' + minutes;
}

export function timestampToDuration(timestamp = null) {
  if (!timestamp) return null;

  const num2string = (num) => {
    return num > 9 ? '' + num : '0' + num;
  };

  let days = Math.floor(timestamp / 86400); // 24*60*60
  let hours = Math.floor((timestamp % 86400) / 3600);
  let minutes = Math.floor((timestamp % 3600) / 60);
  let seconds = timestamp % 60;
  return days === 0
    ? num2string(hours) + ':' + num2string(minutes) + ':' + num2string(seconds)
    : days + ' days ' + num2string(hours) + ':' + num2string(minutes) + ':' + num2string(seconds);
}

export function yearOfBirthToAge(dob) {
  const birthDate = parse(dob, 'yyyy', new Date());
  if (isValid(birthDate)) {
    const { years } = intervalToDuration({
      start: birthDate,
      end: new Date(),
    });
    return years;
  }
}

export const getLastNYears = (numberOfYears, limit = 0) => {
  const yearOptions = [];
  const currentYear = parseInt(new Date().getFullYear() - limit);
  for (let i = 0; i < numberOfYears; i++) {
    yearOptions.push(currentYear - i);
  }
  return yearOptions;
};

export const getTaskRemainingDateForClient = (timestampExpires) => {
  const expires = new Date(timestampExpires).toUTCString(); // Server time
  const clientOffset = new Date().getTimezoneOffset(); // Client offset from server time
  const expiresToClient = new Date(new Date(expires).getTime() + clientOffset); // Calculate expiration date for client's timezone

  return expiresToClient.getTime();
};
