import React from 'react';

const Button = ({
  children,
  classes = '',
  priority = 'primary',
  isOutline = true,
  label,
  disabled = false,
  handleClick,
}) => {
  const setPriority = () => {
    const setOutline = () => {
      return isOutline ? 'outline-' : '';
    };
    switch (priority) {
      case 'primary':
        return `btn-${setOutline()}primary`;
      case 'success':
        return `btn-${setOutline()}success`;
      case 'secondary':
        return `btn-${setOutline()}secondary tw-text-gray-900`;
      case 'danger':
        return `btn-${setOutline()}danger`;
      case 'warning':
        return `btn-${setOutline()}warning`;
      case 'info':
        return `btn-${setOutline()}info`;
      case 'light':
        return `btn-${setOutline()}light`;
      case 'dark':
        return `btn-${setOutline()}dark`;
      default:
        break;
    }
  };

  return (
    <button
      type='button'
      className={`btn ${classes} ${setPriority()}`}
      onClick={handleClick}
      disabled={disabled}
    >
      {label}
      {children}
    </button>
  );
};

export default Button;
