import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import UserController from '../../../controllers/UserController';
import { useDispatch } from 'react-redux';
import '../styles/UserProfile.scss';
import PersonalProfileFormView from '../views/PersonalProfileFormView'
import MetadataController from '../../../controllers/MetadataController'
import SessionController from '../../../controllers/SessionController'
import {
  ACTIONS as SESSION_ACTION
} from '../../../redux/actions/session'
import Notifier from '../../../components/Notifier'
import Spinner from '../../../components/Spinner'
import { PAGES } from '../../index'

const UserOnboardingPageState = (props) =>  {
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = useLocation();

  const [state, setState] = useState({
    personalFormEditOn: true,
    personalInfo: null,
    spokenLanguages: [],
    languages: [],
    countries: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const queryParam = window.location.search;

  useEffect(() => {
    if (queryParam.startsWith("?state=") || queryParam.startsWith("?code=")) {
      const provider = queryParam.startsWith("?state=") ? 'google' : 'facebook';
      loginWithProvider(provider, queryParam).then(s => {
          if(!s?.user?.isValid){
            loadServerData(s?.user);
          }
      })
    } else {
      if(!props?.user?.isValid){
        loadServerData(props?.user)
      }
    }
  }, []);

  async function loadServerData(user){
   await Promise.all([
      getPersonalProfile(user?.id),
      getLanguages(),
      getCountries()
    ]).then(([personalInfo, languages, countries]) => {
      setState({
        ...state,
        personalInfo: personalInfo,
        languages: languages,
        countries: countries,
      });
     setIsLoading(false);
    }).catch(() => {
     setIsLoading(false);
    });
  }

  async function getPersonalProfile(userID) {
    return await UserController.getPersonalProfile(userID);
  }

  async function getLanguages() {
    return MetadataController.getLanguages();
  }

  async function getCountries() {
    return [];
  }

  async function loginWithProvider(provider, params) {
    try{
      const session = await SessionController.loginWith(provider, params).catch(e => errorHandler(e));
      dispatch(SESSION_ACTION.userLogin(session ?? null));
      return session ?? null;
    }
    catch (e) {
      errorHandler(e);
    }
  }

  async function onSaveButtonClick(data = null) {
    try {
      if (!data) return null;
      setIsLoading(true);
      await UserController.updatePersonalProfile(props?.user?.id, data).then(() => {
        SessionController.getSession()
        .then((session) => {
          dispatch(SESSION_ACTION.userLogin(session));
        });
        setIsLoading(false);
        history.push(location?.from ?? PAGES.HOME_PAGE.path());
      })
    } catch (e) {
      setIsLoading(false);
      errorHandler(e);
    }
  }

  function errorHandler(e = null) {
    try {
      Notifier.error(e.message);
    } finally {
      console.error(e);
    }
  }

  return (
    <div className='register-page-wrapper'>
      { ( !isLoading && !!state?.personalInfo) ? (
        <div className='container'>
          <div className='tw-py-4 row'>
            <div className='col-sm-12 tw-pt-6'>
              <span className="tw-text-gray-700 tw-font-bold tw-text-lg">Please fill in the missing information.</span>
            </div>
            <div className='tw-w-full blocks-container tw-columns-1'>
              <div className='tw-w-full box tw-pb-4'>
                <PersonalProfileFormView
                  profile={ state?.personalInfo }
                  languages={ state?.languages }
                  isLoading={ isLoading }
                  isRegistration={ false }
                  isOnboarding={true}
                  onSave={ onSaveButtonClick.bind(this) }
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='col text-center'>
          { props?.user?.id && (<div>
              <span className="tw-text-gray-700 tw-text-center tw-text-sm"> Please wait and do not refresh while we are fetching your information...</span>
              <Spinner/>
            </div>
          )}
        </div>
      ) }
    </div>
  );
}

const mapStateToProps = function (state, props) {
  return {
    ...props,
    user: state?.session?.user ?? null,
  };
};

export default connect(mapStateToProps, null)(withRouter(UserOnboardingPageState));


