import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import InputWithValidation from '../../../components/forms/InputWithValidation';
import CheckboxWithValidation from '../../../components/forms/CheckboxWithValidation';
import Notifier from '../../../components/Notifier';
import Project from '../../../models/Project';
import SelectWithValidation from '../../../components/forms/SelectWithValidation';
import Utils from '../../../utils/Utils';
import PROJECT from '../../../models/Project';

const CROWDSOURCE_SETTINGS = { ...PROJECT.TEMPLATE.CROWDSOURCE.SETTINGS };
const WORKER_VALIDATION_SETTINGS = { ...PROJECT.TEMPLATE.WORKER_VALIDATION.SETTINGS };

const formikOptions = {
  displayName: 'BasicForm',
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Project name is required!')
      .nullable(),
    description: Yup.string().nullable().notRequired(),
    isTest: Yup.boolean(),
    sourceLanguageId: Yup.string().nullable().required('Source language is required'),
    targetLanguageId: Yup.string().nullable().required('Target language is required'),
  }),

  mapPropsToValues: ({ project = null }) => {
    return {
      apiErrors: [],
      id: project?.id ?? null,
      name: project?.name ?? '',
      status: project?.status ?? 'INACTIVE',
      description: project?.description ?? '',
      sourceLanguageId: project?.sourceLanguage?.id ?? null,
      targetLanguageId: project?.targetLanguage?.id ?? null,
      isTest: project?.scope === Project.SCOPE.WORKER_VALIDATION.sid,
    };
  },
};

const FormView = ({
  isLoadingLanguages = false,
  languageOptions = [],
  onSaveButtonClick = null,
  onCancelButtonClick = null,
  showCancelButton = false,
  ...props
}) => {
  const sourceLanguageDefaultOption = Utils.getDefaultOption(
    languageOptions,
    props.values?.sourceLanguageId
  );
  const targetLanguageDefaultOption = Utils.getDefaultOption(
    languageOptions,
    props.values?.targetLanguageId
  );

  const onSubmit = () => {
    try {
      props.setSubmitting(true);
      props.validateForm().then(() => {
        if (props.isValid) {
          if (!props?.dirty) onSaveButtonClick(null);
          else {
            let data = {
              name: props?.values?.name ?? null,
              status: props?.values?.status ?? null,
              description: props?.values?.description ?? null,
              sourceLanguageId: props?.values?.sourceLanguageId ?? null,
              targetLanguageId: props?.values?.targetLanguageId ?? null,
            };
            if (!!props?.values?.isTest) {
              data.settings = !props.project ? WORKER_VALIDATION_SETTINGS : props.project.settings;
              data.scope = PROJECT.SCOPE.WORKER_VALIDATION.sid;
            } else {
              data.settings = !props.project ? CROWDSOURCE_SETTINGS : props.project.settings;
              data.scope = PROJECT.SCOPE.CROWDSOURCING.sid;
            }

            onSaveButtonClick(data);
          }
        } else {
          props.setSubmitting(false);
          Notifier.error(Object.values(props?.errors)?.join('\n'));
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <form onSubmit={props?.handleSubmit}>
      <div className='row'>
        <div className='col'>
          <InputWithValidation
            propertyName={'name'}
            label={'Name'}
            placeholder={'Enter a name...'}
            helpText={"The project's name as it will appear in the project lists (3-50 chars"}
            defaultValue={props?.values?.name}
            touched={props?.touched.name}
            error={props?.errors?.name}
            onChange={props?.setFieldValue}
            onBlur={props?.setFieldTouched}
          />
        </div>
        <div className='col'>
          <InputWithValidation
            type={'textarea'}
            propertyName={'description'}
            label={'Description'}
            placeholder={'Type here...'}
            helpText={"Information about the project and it's configuration. Not required."}
            defaultValue={props?.values?.description}
            touched={props?.touched?.description}
            error={props?.errors?.description}
            onChange={props?.setFieldValue}
            onBlur={props?.setFieldTouched}
            forceDefaultStyle
          />
        </div>
      </div>
      <div className='row justify-content-between'>
        <div className='col-sm-6 col-xs-12'>
          <SelectWithValidation
            propertyName={'sourceLanguageId'}
            label={'Source Language'}
            helpText={"The project's source language"}
            defaultOption={sourceLanguageDefaultOption}
            isLoading={isLoadingLanguages}
            options={languageOptions}
            error={props?.errors?.sourceLanguageId}
            touched={props?.touched?.sourceLanguageId}
            onChange={props?.setFieldValue}
            onBlur={props?.setFieldTouched}
          />
        </div>
        <div className='col-sm-6 col-xs-12'>
          <SelectWithValidation
            propertyName={'targetLanguageId'}
            label={'Target Language'}
            helpText={"The project's target language"}
            defaultOption={targetLanguageDefaultOption}
            isLoading={isLoadingLanguages}
            options={languageOptions}
            error={props?.errors?.targetLanguageId}
            touched={props?.touched?.targetLanguageId}
            onChange={props?.setFieldValue}
            onBlur={props?.setFieldTouched}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <CheckboxWithValidation
            propertyName='isTest'
            label='Test project'
            helpText='Is this project a test project'
            defaultValue={props?.values?.isTest}
            touched={props?.touched?.isTest}
            error={props?.errors?.isTest}
            onChange={props?.setFieldValue}
            onBlur={props?.setFieldTouched}
          />
        </div>
      </div>
      <div className='row justify-content-end mx-0'>
        {showCancelButton ? (
          <button
            key='cancel'
            type='button'
            className='float-right btn btn-outline-secondary mr-2'
            onClick={onCancelButtonClick}
          >
            Cancel
          </button>
        ) : null}
        <button
          key='save'
          type='button'
          className='float-right btn btn-outline-primary'
          onClick={onSubmit}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default withFormik(formikOptions)(FormView);
