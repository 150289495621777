import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import Notifier from '../../../components/Notifier';
import UserController from '../../../controllers/UserController';
import UserMetadataController from '../../../controllers/UserMetadataController';
import DataCollectionFormAmazon from '../../../pages/forms/amazon/DataCollectionForm';
import DataCollectionFormOrange from '../../../pages/forms/orange/DataCollectionForm';
import DataCollectionFormSoapBox from '../../../pages/forms/soapbox/DataCollectionForm';
import User from '../../../models/User';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PAGES } from '../..';

const UserEditPageState = () => {
  let { userId } = useParams();
  const history = useHistory();

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState(null);
  const [isAmbassador, setIsAmbassador] = useState(false);
  const [isEnabled, setEnabled] = useState(true);

  const AMAZON_FORM_CODES = ['UK', 'US', 'AU', 'USUK'];
  const ORANGE_FORM_CODES = ['BAMBARA'];
  const SOAPBOX_FORM_CODES = ['ES-SBL'];

  const getQuestionnaireOrigin = (userData) => {
    const userExtra = userData.userMetadata.extra;
    if (userExtra.hasOwnProperty('questionnaire') && userExtra.questionnaire === 'USUK') {
      setCountryCode('USUK');
    } else if (userExtra.hasOwnProperty('race')) {
      setCountryCode('US');
    } else if (userExtra.hasOwnProperty('ethnicity')) {
      setCountryCode('UK');
    } else if (userExtra.hasOwnProperty('ancestry') && !userData.hasOwnProperty('race')) {
      setCountryCode('AU');
    } else if (userExtra.hasOwnProperty('level_of_reading_bambara')) {
      setCountryCode('BAMBARA');
    } else {
      setCountryCode('ES-SBL');
    }
  };

  const handleUpdate = async (userMetadata) => {
    setLoading(true);
    try {
      if (countryCode === 'US') {
        let newMetadata = {
          ethnicGroup: userMetadata?.ethnicGroup[0],
          gender: userMetadata?.gender,
          spokenDialect: userMetadata?.spokenDialect,
          yearOfBirth: userMetadata?.yearOfBirth,
          extra: {
            ...userMetadata?.extra,
            race: userMetadata?.extra?.race[0],
          },
        };

        userMetadata = newMetadata;
      }
      await UserMetadataController.put(userData?.id, userMetadata);
    } catch (e) {
      Notifier.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    let alert = await showDeleteConfirmPopup();
    if (alert.value !== true) return;

    try {
      await UserController.delete(userData?.id);
      setEnabled(false);
    } catch (e) {
      Notifier.error(e.message);
    }
  };

  const addAmbassador = async () => {
    try {
      await UserController.addToRole(userData?.id, User.API_ROLES_TO_ID.AMBASSADOR);
      setIsAmbassador(true);
    } catch (e) {
      Notifier.error(e.message);
    }
  };

  const removeAmbassador = async () => {
    try {
      await UserController.removeFromRole(userData?.id, User.API_ROLES_TO_ID.AMBASSADOR);
      setIsAmbassador(false);
    } catch (e) {
      Notifier.error(e.message);
    }
  };

  const showDeleteConfirmPopup = async () => {
    return Swal.mixin({
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-outline-danger mx-2 px-5 btn-lg font-weight-bold',
        cancelButton: 'btn btn-outline-secondary mx-2 px-5 btn-lg font-weight-bold',
      },
    }).fire({
      title: 'User delete!',
      text:
        `You are about to delete the user (${userData?.firstName} ${userData?.lastName} ${userData?.username})` +
        '. This action is irreversible (for now). Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });
  };

  let form = null;
  if (!!countryCode) {
    if (AMAZON_FORM_CODES.includes(countryCode.toUpperCase())) {
      form = (
        <DataCollectionFormAmazon
          countryCode={countryCode.toUpperCase()}
          handleUpdate={handleUpdate}
          loading={loading}
          user={userData?.userMetadata}
          isSubmitted={true}
          isUserEditPage={true}
        />
      );
    } else if (ORANGE_FORM_CODES.includes(countryCode.toUpperCase())) {
      form = (
        <DataCollectionFormOrange
          countryCode={countryCode.toUpperCase()}
          handleUpdate={handleUpdate}
          loading={loading}
          user={userData?.userMetadata}
          isUserEditPage={true}
        />
      );
    } else if (SOAPBOX_FORM_CODES.includes(countryCode.toUpperCase())) {
      form = (
        <DataCollectionFormSoapBox
          countryCode={countryCode.toUpperCase()}
          handleUpdate={handleUpdate}
          loading={loading}
          user={userData?.userMetadata}
          isUserEditPage={true}
        />
      );
    }
  }

  useEffect(() => {
    UserController.get(userId).then((r) => {
      setUserData(r);
      getQuestionnaireOrigin(r);
      setIsAmbassador(User.hasRole(r.apiRoles, User.API_ROLES.AMBASSADOR));
      setEnabled(r.isEnabled);
    });
  }, [isAmbassador, isEnabled]);

  const handleBack = () => {
    history.push(PAGES.USERS_PAGE.path());
  };

  return (
    <Container>
      <div className='tw-w-full tw-flex tw-items-center tw-justify-between'>
        <h3 className='tw-inline-block tw-mb-0'>
          Editing user {userData?.firstName} {userData?.lastName} ({userData?.username})
          {!isEnabled && (
            <span>
              {' '}
              - <span className='tw-text-red-700'>DELETED</span>
            </span>
          )}
        </h3>
        <div className='tw-flex tw-gap-6'>
          <Button
            className='tw-flex tw-items-center tw-pl-3'
            type='submit'
            variant={!isAmbassador ? 'primary' : 'secondary'}
            onClick={!isAmbassador ? addAmbassador : removeAmbassador}
            disabled={!isEnabled}
          >
            <em
              className={`fas tw-text-white-100 tw-mr-1 ${!isAmbassador ? 'fa-plus' : 'fa-minus'}`}
              style={{ fontSize: '16px', width: '16px' }}
            />
            {!isAmbassador ? 'Make Ambassador' : 'Remove Ambassador'}
          </Button>

          <Button
            className='tw-bg-red-700 tw-border-red-700 tw-flex tw-items-center tw-pl-4'
            type='submit'
            onClick={handleDelete}
            disabled={!isEnabled}
          >
            <em
              className='far fa-trash-alt tw-text-white-100 tw-mr-1'
              style={{ fontSize: '16px', width: '16px' }}
            />
            {isEnabled ? 'Delete' : 'Deleted'}
          </Button>
        </div>
      </div>
      <hr />
      {countryCode ? (
        <div className='tw-relative'>
          {!isEnabled && (
            <div className='tw-absolute tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full tw-cursor-not-allowed tw-z-10 tw-bg-gray-100 tw-bg-opacity-50'>
              <div className='tw-text-3xl tw-text-red-700 tw-bg-gray-50'>User is deleted</div>
            </div>
          )}
          <div className='tw-relative tw-z-0'>{form}</div>
        </div>
      ) : (
        <div>The user has not submitted any questionnaires yet.</div>
      )}
      <hr />
      <Button className='btn-secondary tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2'>
        <em className='fas fa-hand-point-left tw-text-base'></em>
        <span onClick={handleBack}>Go back to Users</span>
      </Button>
    </Container>
  );
};

export default UserEditPageState;
