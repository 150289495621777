import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import PropTypes from 'prop-types';
import OptionWithCheckbox from './OptionWithCheckbox';

const MultiSelectWithCheckboxes = ({
  options = [],
  onChange,
  value,
  isSearchable,
  toggleButton,
  menuListFooter,
}) => {
  const [open, setOpen] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (inputValue, { action }) => {
    if (action !== 'input-blur' && action !== 'menu-close' && action !== 'set-value') {
      setInputValue(inputValue);
    }
  };

  return (
    <Dropdown isOpen={open} toggle={() => setOpen((open) => !open)}>
      <DropdownToggle tag='span'>{toggleButton}</DropdownToggle>
      <DropdownMenu right className='p-0 mt-3'>
        <Select
          placeholder='Search...'
          classNamePrefix='taus-multi-select-checkbox-parameter'
          autoFocus
          isMulti
          menuIsOpen
          blurInputOnSelect={false}
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          tabSelectsValue={false}
          isSearchable={isSearchable}
          components={{
            DropdownIndicator: null,
            IndicatorSeparator: null,
            Option: OptionWithCheckbox,
            MenuList: MenuListWithFooter,
            Control: isSearchable ? components.Control : () => null,
          }}
          menuListFooter={menuListFooter}
          styles={{
            container: (provided) => ({
              ...provided,
              minWidth: 240,
            }),
          }}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          options={options}
          value={value}
          onChange={onChange}
        />
      </DropdownMenu>
    </Dropdown>
  );
};

const MenuListWithFooter = (props) => {
  return (
    <>
      <components.MenuList {...props} />
      {props.selectProps.menuListFooter}
    </>
  );
};

export default MultiSelectWithCheckboxes;

MultiSelectWithCheckboxes.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      indeterminate: PropTypes.bool,
    })
  ).isRequired,
  isSearchable: PropTypes.bool,
  menuListFooter: PropTypes.object,
  toggleButton: PropTypes.object.isRequired,
};

MultiSelectWithCheckboxes.defaultProps = {
  isSearchable: false,
};
