import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import UserController from '../../../controllers/UserController';
import UserReferralCodeController from '../../../controllers/UserReferralCodeController';
import UserProfileController from '../../../controllers/UserProfileController';
import TransferWiseController from '../../../controllers/TransferWiseController';
import { EXTERNAL_PAGES } from '../../index';
import UserPaymentInfoView from '../components/user-payment-info/UserPaymentInfoView';
import Spinner from '../../../components/Spinner';
import Notifier from '../../../components/Notifier';
import '../styles/UserProfile.scss';
import PersonalProfileState from './PersonalProfileState';
import UserProfileImageNameBio from '../components/user-personal-info/profile-image-name-bio/ProfileImageNameBioView';
import UserReferralCodesView from '../components/user-referral-codes/UserReferralCodesView';
import Utils from '../../../utils/Utils';

class ProfilePageState extends PureComponent {
  state = {
    isLoading: true,
    personalFormEditOn: false,
    paymentInfo: null,
    serverPaymentInfo: null,
    personalInfo: null,
    isStripeOnBoardingComplete: false,
    stripeEmail: null,
    hasReturnedFromStripe: false,
    isDisconnectingFromStripe: false,
    spokenLanguages: [],
    transferWiseBanks: [],
    transferWiseBankBranches: [],
    transferWiseRegions: [],
    transferWiseStates: [],
    transferWiseSupportedCountries: [],
    expertiseFieldTooltipText: `Any subject/field that you have a specific knowledge of because of your studies,
            profession or hobbies. This will help us to better match your skills with our projects’
            needs`,
    paymentProfileFetchError: false,
  };

  componentDidMount() {
    Promise.all([
      this.getPersonalProfile(),
      this.findUserReferralCodesByUserId(),
    ])
      .then(([personalInfo, referralCodes]) => {
        this.setState({
          personalInfo,
          referralCodes,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });

    this.initializeUserPaymentDetails()
    .then(res => {this.setState({ paymentInfo : res})})
    .catch(e => console.log(e))
  }

  async getPersonalProfile() {
    return UserController.getPersonalProfile(this.props?.user?.id);
  }

  async findUserReferralCodesByUserId() {
    return UserReferralCodeController.findByUserId(this.props?.user?.id);
  }

  async updatePersonalInfo(updatedInfo) {
    this.setState({ personalInfo: updatedInfo });
  }

  onEditPersonalForm = () => {
    this.setState({ personalFormEditOn: !this.state.personalFormEditOn });
  };

  /* Fetch payment related user details and the banks and branches from TransferWise
   * if condition is met and on promise resolve set the state and return the server response
   * */
  async initializeUserPaymentDetails() {
    try {
      const userId = this.props.user?.id ?? null;
      const paymentInfo = (await UserController.getPaymentProfile(userId)) ?? null;
      const serverPaymentInfo = paymentInfo;
      if ( !!paymentInfo) {
        const selectedCountryRequiredFields = paymentInfo.transferWiseOptions.supportedCountries.find(
          (country) => country.countryCode === paymentInfo.countryCode
        )?.requiredFields;
        const currencyCode = paymentInfo.transferWiseOptions.supportedCountries.find(
          (country) => country.countryCode === paymentInfo.countryCode
        )?.currencyCode;

        if (selectedCountryRequiredFields) {
          // get banks only if branches is not required
          if (
            selectedCountryRequiredFields.includes('bankCode') &&
            !selectedCountryRequiredFields.includes('branchCode')
          ) {
            await this.getTransferWiseBanks(paymentInfo.countryCode);
          }

          // get banks and branches if both are required
          if (
            selectedCountryRequiredFields.includes('bankCode') &&
            selectedCountryRequiredFields.includes('branchCode')
          ) {
            await this.getTransferWiseBanks(paymentInfo.countryCode);
            await this.getTransferWiseBankBranches(paymentInfo.countryCode, paymentInfo.bankCode);
          }

          if (selectedCountryRequiredFields.includes('region')) {
            await this.getTransferWiseRegions(currencyCode);
          }

          if (selectedCountryRequiredFields.includes('state')) {
            await this.getTransferWiseStates(paymentInfo.countryCode);
          }
      }

      // retrieve stripe account
      const queryParameters = new URLSearchParams(this.props.location.search);
      let hasReturnedFromStripe = queryParameters.has('stripe');

      if (hasReturnedFromStripe) {
        if (queryParameters.get('stripe') === 'active') {
          const account = await UserProfileController.getStripeAccount(queryParameters.get('id'));
          paymentInfo.stripeId = account.id;
          paymentInfo.stripeChargesEnabled = account['charges_enabled'];
          paymentInfo.method = 'Stripe';
          paymentInfo.stripeEmail = account.email;
        }
      }
      else {
        if (paymentInfo.stripeId) {
          const account = await UserProfileController.getStripeAccount(paymentInfo.stripeId);
          paymentInfo.stripeChargesEnabled = account['charges_enabled']
            ? account['charges_enabled']
            : false;
          paymentInfo.stripeEmail = account.email ? account.email : null;
        }
      }

      let isStripeOnBoardingComplete = paymentInfo.stripeId && paymentInfo.stripeChargesEnabled;

      const { supportedCountries } = paymentInfo?.transferWiseOptions;

      this.setState({
        serverPaymentInfo: serverPaymentInfo,
        paymentInfo: paymentInfo,
        hasReturnedFromStripe: hasReturnedFromStripe,
        isStripeOnBoardingComplete: isStripeOnBoardingComplete,
        stripeEmail: paymentInfo.stripeEmail || '',
        transferWiseSupportedCountries: supportedCountries,
      });
    }

      return paymentInfo;
    } catch (e) {
      this.setState({paymentProfileFetchError : "Something went wrong when fetching the payment details. Please try again later!!"})
      this.errorHandler(e, 'Something went wrong! Please try again');
      throw e;
    }
  }

  /* Fetch transferWiseBanks from TransferWise API and on promise resolve set the state */
  async getTransferWiseBanks(countryCode = null) {
    return TransferWiseController.getBanks(countryCode).then((response) => {
      this.setState({ transferWiseBanks: response.values ?? [] });
    });
  }

  /* Fetch transferWiseBankBranches from TransferWise API and on promise resolve set the state */
  async getTransferWiseBankBranches(countryCode, bankCode) {
    return TransferWiseController.getBankBranches(countryCode, bankCode).then((response) => {
      this.setState({ transferWiseBankBranches: response.values });
    });
  }

  async getTransferWiseRegions(currencyCode = null) {
    return TransferWiseController.getRegions(currencyCode).then((response) => {
      let regions = response[0].fields[1].group[0].valuesAllowed;
      this.setState({ transferWiseRegions: regions });
    });
  }

  async getTransferWiseStates(countryCode) {
    return TransferWiseController.getStates(countryCode).then((response) => {
      this.setState({ transferWiseStates: response.values });
    });
  }

  async updatePaymentInfo(paymentInfo) {
    try {
      const newPaymentInfo = { ...this.state.paymentInfo, ...paymentInfo };
      const paymentMethod = paymentInfo?.method?.toLowerCase() || '';
      const paymentDetails = {
        name: paymentInfo.name,
        addressLine: paymentInfo.addressLine,
        city: paymentInfo.city,
        zipCode: paymentInfo.zipCode,
        countryCode: paymentInfo.countryCode,
        vatStatus: paymentInfo.vatStatus,
        vatNumber:
          paymentInfo.vatNumber && paymentInfo.vatNumber !== '' ? paymentInfo.vatNumber : null,
        cocNumber:
          paymentInfo.cocNumber && paymentInfo.cocNumber !== '' ? paymentInfo.cocNumber : null,
        legalType: paymentInfo.legalType,
        email: paymentInfo.email && paymentInfo.email !== '' ? paymentInfo.email : null,
        dateOfBirth:
          paymentInfo.dateOfBirth && paymentInfo.dateOfBirth !== ''
            ? paymentInfo.dateOfBirth
            : null,
        paypalEmail:
          paymentInfo.paypalEmail && paymentInfo.paypalEmail !== ''
            ? paymentInfo.paypalEmail
            : null,
        iban: paymentInfo.iban && paymentInfo.iban !== '' ? paymentInfo.iban : null,
        ifscCode: paymentInfo.ifscCode && paymentInfo.ifscCode !== '' ? paymentInfo.ifscCode : null,
        accountType:
          paymentInfo.accountType && paymentInfo.accountType !== ''
            ? paymentInfo.accountType
            : null,
        abartn: paymentInfo.abartn && paymentInfo.abartn !== '' ? paymentInfo.abartn : null,
        bsbCode: paymentInfo.bsbCode && paymentInfo.bsbCode !== '' ? paymentInfo.bsbCode : null,
        accountNumber:
          paymentInfo.accountNumber && paymentInfo.accountNumber !== ''
            ? paymentInfo.accountNumber
            : null,
        bankCode: paymentInfo.bankCode && paymentInfo.bankCode !== '' ? paymentInfo.bankCode : null,
        branchCode:
          paymentInfo.branchCode && paymentInfo.branchCode !== '' ? paymentInfo.branchCode : null,
        region:
          paymentInfo.regionCode && paymentInfo.regionCode !== '' ? paymentInfo.regionCode : null,
        state: paymentInfo.stateCode && paymentInfo.stateCode !== '' ? paymentInfo.stateCode : null,
        transferwiseType:
          paymentInfo.transferwiseType && paymentInfo.transferwiseType !== ''
            ? paymentInfo.transferwiseType
            : null,
        swift: paymentInfo.swift && paymentInfo.swift !== '' ? paymentInfo.swift : null,
      };

      // If the stripe account is changed
      if (
        (newPaymentInfo?.stripeId || null) !== (this.state.serverPaymentInfo?.stripeId || null) ||
        (newPaymentInfo?.stripeChargesEnabled || null) !==
          (this.state.serverPaymentInfo?.stripeChargesEnabled || null) ||
        !paymentInfo?.stripeId ||
        paymentInfo?.stripeId === null
      ) {
        paymentDetails.stripeId = this.state.paymentInfo?.stripeId || null;
        paymentDetails.stripeEmail = this.state.paymentInfo?.stripeEmail || null;
        paymentDetails.stripeChargesEnabled = this.state.paymentInfo?.stripeChargesEnabled || false;
      }

      await UserProfileController.updateUserDetails(
        paymentMethod,
        this.props?.user?.id,
        paymentDetails
      );
      // reset stripe query params when the payment details have been saved
      this.setState({ paymentInfo: newPaymentInfo });
      this.props.history.push({ search: '' });
    } catch (e) {
      this.errorHandler(e, e?.message ? e.message : 'Something went wrong, please try again');
      throw e;
    }
  }

  updateAdditionalInfo(additionalInfo) {
    const additionalDetails = {
      expertiseFields: additionalInfo.selectedExpertiseFields,
      educationalLevel: additionalInfo.selectedEducationalLevel,
    };

    this.setState({
      additionalInfo: additionalDetails,
    });
  }

  async validateTransferWiseFields(paymentInfo) {
    if (paymentInfo.method !== 'TransferWise') {
      return Promise.resolve();
    }

    if (paymentInfo.transferwiseType === 'iban') {
      return TransferWiseController.validateIban(paymentInfo.iban).catch(e => console.log(e));
    }

    if (paymentInfo.transferwiseType === 'indian') {
      let promises = [
        TransferWiseController.validateIfsc(paymentInfo.ifscCode),
        TransferWiseController.validateIndianAccountNumber(paymentInfo.accountNumber),
      ];
      return Promise.all(promises).catch(e => console.log(e));
    }

    return Promise.resolve();
  }

  async setUpStripeOnboarding() {
    try {
      this.setState({ isLoading: true });
      let resp = await UserProfileController.createStripeOnBoard();
      if (resp) {
        this.props.history.push(EXTERNAL_PAGES.STRIPE_CONNECT.path(resp.accountLink.url));
      }
    } catch (e) {
      console.log(e);
    }
  }

  async disconnectFromStripe() {
    this.setState({
      isDisconnectingFromStripe: true,
      paymentInfo: {
        ...this.state.paymentInfo,
        stripeId: null,
        stripeChargesEnabled: false,
      },
    });

    this.setState({ isDisconnectingFromStripe: false });
  }

  render() {
    if (this.state.isLoading)
      return (
        <div className='col'>
          <Spinner />
        </div>
      );

    return (
      <div className='container'>
        <div className='user-profile-wrapper row'>
          <div className='col-sm-12'>
            <div className='col-sm-12'>
              <div className='col-sm-12 col-md-4 col-lg-3 float-left'></div>
              <div
                className='col-sm-12 col-md-8 col-lg-9 float-left'
                style={{
                  fontSize: '26px',
                  lineHeight: '36px',
                  letterSpacing: '0.48px',
                  fontWeight: '500',
                  color: '#1c1c28',
                  marginBottom: '32px',
                }}
              >
                <span className='user-profile-title'>User profile</span>
              </div>
            </div>
          </div>
          <div className='col-sm-12' style={{ marginBottom: '50px' }}>
            <UserProfileImageNameBio
              prefix={this.state.personalInfo?.prefix ?? ''}
              firstName={this.state.personalInfo?.firstName ?? ''}
              lastName={this.state.personalInfo?.lastName ?? ''}
              onEdit={this.onEditPersonalForm.bind(this)}
            />
          </div>
          <div className='tw-w-full blocks-container tw-columns-2 tw-rounded-lg'>
            <div className='tw-w-full box tw-mb-4'>
              <PersonalProfileState
                user={this.props?.user}
                editingExternaly={this.state.personalFormEditOn}
                onEdit={this.onEditPersonalForm.bind(this)}
                userUpdatedData={this.updatePersonalInfo.bind(this)}
              />
            </div>
            <div className='tw-w-full box'>
              {(!(!!this.state?.paymentProfileFetchError) && !!this.state?.paymentInfo) ? (
              <UserPaymentInfoView
                userEmail={this.state?.personalInfo?.email ?? ''}
                paymentInfo={this.state?.paymentInfo ?? null}
                stripeEmail={this.state?.stripeEmail ?? ''}
                isStripeOnBoardingComplete={this.state?.isStripeOnBoardingComplete ?? false}
                isDisconnectingFromStripe={this.state?.isDisconnectingFromStripe ?? false}
                hasReturnedFromStripe={this.state?.hasReturnedFromStripe ?? false}
                transferWiseBanks={this.state?.transferWiseBanks ?? []}
                transferWiseBankBranches={this.state?.transferWiseBankBranches ?? []}
                transferWiseRegions={this.state?.transferWiseRegions ?? []}
                transferWiseStates={this.state?.transferWiseStates ?? []}
                transferWiseSupportedCountries={this.state?.transferWiseSupportedCountries ?? []}
                onDisconnectFromStripe={this.disconnectFromStripe.bind(this)}
                onSetUpStripeOnboarding={this.setUpStripeOnboarding.bind(this)}
                onInitializeUserPaymentDetails={this.initializeUserPaymentDetails.bind(this)}
                onUpdatePaymentInfo={this.updatePaymentInfo.bind(this)}
                onValidateTransferWiseFields={this.validateTransferWiseFields.bind(this)}
                onGetTransferWiseBanks={this.getTransferWiseBanks.bind(this)}
                onGetTransferWiseBankBranches={this.getTransferWiseBankBranches.bind(this)}
                onGetTransferWiseRegions={this.getTransferWiseRegions.bind(this)}
                onGetTransferWiseStates={this.getTransferWiseStates.bind(this)}
              />
              ) : (<div className='tw-w-full box tw-p-10 text-center'>
               <span className="tw-text-red-400 tw-text-center tw-text-sm">{this.state?.paymentProfileFetchError}</span>
              </div>)}
            </div>
          </div>
          <div className='tw-w-full blocks-container tw-rounded-lg pt-6'>
            {!Utils.isEmpty(this.state?.referralCodes) && (
              <div className='tw-w-full box tw-mb-4'>
                <UserReferralCodesView referralCodes={this.state?.referralCodes ?? null} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  errorHandler(e = null, message = null) {
    try {
      if (message !== null) Notifier.error(message);
    } finally {
      console.error(e);
    }
  }
}

const mapStateToProps = function (state, props) {
  return {
    ...props,
    user: state?.session?.user ?? null,
  };
};

export default connect(mapStateToProps, null)(withRouter(ProfilePageState));
