// External libs
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import Notifier from '../../components/Notifier';
import ApiException from '../../models/ApiException';
import ProjectController from '../../controllers/ProjectController';
import MetadataController from '../../controllers/MetadataController';
import FileController from '../../controllers/FileController';
import ProjectPageView from './views/ProjectPageView';
import { PAGES } from '../index';
import Spinner from '../../components/Spinner';
import PROJECT from '../../models/Project';
import USER from '../../models/User';
import USER_PERMISSION from '../../models/UserPermission';
import Utils from '../../utils/Utils';

class ProjectPageContainer extends PureComponent {
  state = {
    isNew: false,
    isLoadingProject: true,
    isLoadingMetadata: true,
    project: null,
    projectFiles: [],
    files: [],
    roleOptions: [],
    userOptions: [],
    languageOptions: [],
    isEditable: false,
    isDeletable: false,
  };

  // --------------------------------------------------------------------------------------
  // ---------------------------------- ReactJS Methods -----------------------------------
  componentDidMount() {
    this.initProject()
      .then(() => {
        this.setState({ isLoadingProject: false });
      })
      .then(() => this.initMetadata())
      .then(() => {
        this.setState({ isLoadingMetadata: false });
      })
      .catch((e) => this.errorHandler(e))
      .finally(() => this.setState({ isLoadingProject: false, isLoadingMetadata: false }));
  }

  async initProject() {
    try {
      const [project, projectFiles, projectRoles] = await Promise.all([
        this.getProject(),
        this.getProjectFiles(),
        this.getProjectRoles(),
      ]);
      this.setState({
        project: project ?? null,
        projectFiles: projectFiles ?? [],
        roleOptions: projectRoles.map((r) => ({ value: r.id, label: r.name })),
      });
    } catch (e) {
      this.errorHandler(e);
    }
  }

  async initMetadata() {
    try {
      let [languages, files] = await Promise.all([this.getLanguages(), this.getFiles()]);
      this.setState({
        languageOptions: languages ? Utils.mapToOption(languages) : [],
        files: files ?? [],
      });
    } catch (e) {
      this.errorHandler(e);
    }
  }

  async getProject() {
    return ProjectController.adminGetProject(this.props?.match?.params?.id);
  }

  async getLanguages() {
    return MetadataController.getLanguages();
  }

  async getProjectRoles() {
    return ProjectController.getMemberRoles();
  }

  async getProjectFiles() {
    return ProjectController.getFiles(this.props?.match?.params?.id, {
      'serialize-permission': false,
    });
  }

  async getFiles() {
    return FileController.getAll({ pageSize: 10000 }, { 'serialize-permission': false }).then(
      (res) => res?.page ?? []
    );
  }

  async deleteProject() {
    return ProjectController.delete(this.props?.match?.params?.id);
  }

  async onDeleteProjectClick() {
    try {
      // Warn user before deleting
      let alert = Swal.mixin({
        titleText: 'Delete Project?',
        html: 'Are you sure that you want to delete this project? This action cannot be undone. You can only delete inactive projects.',
        showConfirmButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#f05050',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      });
      let alertResponse = await alert.fire('', undefined, undefined);
      if (!alertResponse) return;

      // If user confirmed, send request to the api
      this.setState({ isDeleting: true });
      ProjectController.delete(this.state.project.id)
        .then(() => this.props.history.push(PAGES.PROJECTS_ADMIN_PAGE.path()))
        .catch((error) => {
          this.errorHandler(error);
          this.setState({ isDeleting: false });
        });
    } catch (e) {
      this.errorHandler(e);
    }
  }

  onBack() {
    this.props.history.push(PAGES.PROJECTS_ADMIN_PAGE.path());
  }

  async onProjectFilesUpdated(projectFiles) {
    try {
      if (!projectFiles) projectFiles = await this.getProjectFiles();
      this.setState({ projectFiles: projectFiles });
    } catch (e) {
      this.errorHandler(e);
    }
  }

  render() {
    if (!this.state.project) return <Spinner />;

    const userPermissions = PROJECT.getUserPermission(this.state?.project);
    const isEditable = userPermissions.includes(PROJECT.PERMISSIONS.EDIT);
    const isDeletable = userPermissions.includes(PROJECT.PERMISSIONS.DELETE);
    const canSendMessage = USER.hasAnyPermission(this.props?.user, USER_PERMISSION.TYPES.MESSAGE, [
      'CREATE',
    ]);

    const metadata = {
      languageOptions: this.state.languageOptions,
      projectFiles: this.state.projectFiles,
      files: this.state.files,
    };

    return (
      <ProjectPageView
        user={this.props?.user}
        project={this.state?.project}
        isEditable={isEditable}
        isDeletable={isDeletable}
        onBackButtonClick={this.onBackButtonClick.bind(this)}
        onDeleteButtonClick={this.onDeleteButtonClick.bind(this)}
        onProjectUpdate={this.onProjectUpdate.bind(this)}
        projectIsLoading={this.state.isLoadingProject}
        metadataAreLoading={this.state.isLoadingMetadata}
        metadata={metadata}
        canSendMessage={canSendMessage}
        roleOptions={this.state.roleOptions}
        onProjectFilesUpdated={this.onProjectFilesUpdated.bind(this)}
      />
    );
  }

  async onBackButtonClick() {
    this.props.history.push(PAGES.PROJECTS_ADMIN_PAGE.path());
  }

  async onDeleteButtonClick() {}

  async onProjectUpdate(project = null) {
    try {
      if (!project) project = await this.getProject();
      this.setState({ project: project });
    } catch (e) {
      this.errorHandler(e);
    }
  }

  errorHandler(e) {
    e = ApiException.toApiException(e);
    Notifier.error(e.userMessage);
  }
}

const mapStateToProps = (state = {}, ownProps) => ({ ...ownProps, user: state?.session?.user });
export default connect(mapStateToProps, null)(withRouter(ProjectPageContainer));
