import React from 'react';
import { Modal } from 'react-bootstrap';

function PaymentTerms({ show, handleShow }) {
  return (
    <Modal show={show} onHide={handleShow} size='lg'>
      <Modal.Header closeButton className='m-2'>
        <Modal.Title className='mb-0'>TAUS HLP Payment terms</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='bg-info p-2 mb-4 mx-3'>
          These payment terms need to be agreed to in order to perform HLP work
        </div>
        <ol>
          <li className='pb-2'>
            Payment for HLP work is possible only via PayPal/Stripe/TransferWise.
          </li>
          <li className='pb-2'>
            Payments will be made in Euro only, as per the rate agreed for the specific HLP project
            you are assigned to. Rates can be found in the project instructions.
          </li>
          <li className='pb-2'>
            When starting an HLP project, we will agree on a unit rate and unit type. The unit type
            might vary per project and could be based on words, segments, hours or tasks. Because of
            TAUS internal payment processing, it is not possible to show these different unit rates
            and types on the payment request/invoice we issue on your behalf. Instead, the
            information will be shown as follows:
            <ul>
              <li className='pt-2 pb-1'>
                HLP credit: this is the total amount the beneficiary is entitled to in a specific
                payment period. It is calculated as follows: agreed rate x the total number of units
                eligible for payment in a specific payment period.
              </li>
              <li className='pt-2 pb-1'>
                Unit price (ex VAT): this is not the actual unit price, it is set by default to 1.00
                EUR for administrative purposes only
              </li>
              <li className='pt-2 pb-1'>
                The value of each task can be found in the Credits column on the Available tasks
                page. For example, a task that is worth 2.5 credits equals to 2.5 EUR.
              </li>
              <li className='pt-2 pb-1'>
                The value earned for all submitted tasks is shown in the Credits column on the My
                Tasks page
              </li>
            </ul>
          </li>
          <li className='pb-2'>
            Beneficiary agrees to any eventual payment transfer costs that the selected payment
            provider might apply to HLP payments. Transfer costs are outside of TAUS control and
            depend on many variables (country, bank, currency, etc). For more information on these
            costs, please contact the relevant payment provider.
          </li>
          <li className='pb-2'>
            TAUS will issue an automatic payment request/invoice on the beneficiary’s behalf for the
            amount due for payment. This payment request will be issued with a set frequency,
            depending on the specific HLP project. The raised payment requests/invoices meet EU
            requirements on self-billing.
          </li>
          <li className='pb-2'>
            The beneficiary will not raise any other payment request/invoice for supplies of
            services covered by the HLP Workers Agreement.
          </li>
          <li className='pb-2'>
            This agreement will start at the moment access to tasks is granted.
          </li>
          <li>
            The payment beneficiary will be responsible to update immediately the payment
            information in this user profile page in case of:
            <ul>
              <li className='pt-2 pb-1'>
                changes in the VAT registration number (EU) or local Tax ID number (non-EU) - if
                applicable
              </li>
              <li className='pb-1'>
                termination of the company/business activities (if applicable)
              </li>
              <li className='pb-1'>changes to the preferred payment method</li>
            </ul>
          </li>
        </ol>
      </Modal.Body>
    </Modal>
  );
}
export default PaymentTerms;
