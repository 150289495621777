import Language from '../../../models/Language';

const LanguageDisplay = ({ language }) => {
  const langName = Language.getName(language);
  const langCode = Language.getCode(language);
  return (
    <>
      <span id='language-name'>{langName}</span>
      <span id='language-code'>{langCode}</span>
    </>
  );
};

export default LanguageDisplay;
