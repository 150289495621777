import React, { useEffect, useRef, useState } from 'react'
import wavesurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';
import Tooltip from '../../../components/Tooltip';
import '../styles/waveform.css';
import {
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faPause,
  faPlay,
  faScissors, faVolumeHigh, faVolumeLow,
  faRotateLeft, faFileAudio, faForwardFast,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { encodeWAV } from '../../../utils/Recorder'
import moment from 'moment'

const AudioWaveform = ({audio = null, segmentId=null, onAudioChange, sampleRate}) => {

  const wavesurferRef = useRef(null);
  const timelineRef = useRef(null);

  // create an instance of the wavesurfer
  const [wavesurferObj, setWavesurferObj] = useState();
  const [playing, setPlaying] = useState(false); // to keep track whether audio is currently playing or not
  const [volume, setVolume] = useState(1); // to control volume level of the audio. 0-mute, 1-max
  const [zoom, setZoom] = useState(1); // to control the zoom level of the waveform
  const [originalAudio, setOriginalAudio] = useState([]); // to save the original buffer before trim
  const [downloadAudioURL, setDownloadAudioURL] = useState(null); // to store downloaded audio url
  const playbackSpeeds = [0.25, 0.5, 0.75, 1, 1.5, 2];
  const [playbackRate, setPlaybackRate] = useState(3);
  const [wsRegions, setWsRegions] = useState(); // to store regions plugin data
  const [regions, setRegions] = useState([]); // to store regions created
  const [duration, setDuration] = useState();

  // create the waveform inside the correct component
  useEffect(() => {
    if (wavesurferRef.current && !wavesurferObj) {
      setWavesurferObj(
        wavesurfer.create({
          container: `#waveform-${segmentId}`,
          scrollParent :true,
          height: 80,
          autoCenter: true,
          cursorColor: '#4353FF',
          loopSelection: true,
          waveColor: '#D9DCFF',
          progressColor: '#4353FF',
          responsive: true,
          sampleRate: sampleRate,
          minPxPerSec : 1,
          audioRate: 1,
          plugins: [
            TimelinePlugin.create({
              container: `#wave-timeline-${segmentId}`,
            }),
            RegionsPlugin.create({})
          ]
        })
      );
    }
  }, [wavesurferRef, wavesurferObj, segmentId, sampleRate]);

  useEffect(() => {
    if(wavesurferObj){
      setWsRegions(wavesurferObj?.plugins.filter(plugin => !!plugin?.regions)[0]);
    }
  }, [wavesurferObj])

  useEffect(() => {
    if (!!audio && wavesurferObj) {
      wavesurferObj.load(audio);
      setDownloadAudioURL(audio);
    }
  }, [audio, wavesurferObj]);

  useEffect(() => {
    if (wavesurferObj && wsRegions) {
      wavesurferObj?.plugins.filter(plugin => !!plugin.regions)[0].enableDragSelection({}); // to select the region to be trimmed

      wavesurferObj.on('ready', () => {
        setDuration(wavesurferObj.getDuration());
      });

      // once audio starts playing, set the state variable to true
      wavesurferObj.on('play', () => {
        setPlaying(true);
      });

      // once audio finish playing, set the state variable to false
      wavesurferObj.on('finish', () => {
        setPlaying(false);
      });

      // if multiple regions are created, then remove all the previous regions so that only 1 is present at any given time
      wsRegions.on('region-created', (region) => {
        setRegions((oldRegions) => [region]);
        const regionlist = wsRegions.regions;
        const keys = Object.keys(regionlist);
        if (keys.length > 1) {
          wsRegions.regions[keys[0]].remove();
          setRegions((oldRegions) => [region]);
        }
      });

      wsRegions.on('region-clicked', (region, e) => {
        e.stopPropagation() // prevent triggering a click on the waveform
        region.play();
      })

    }
  }, [wavesurferObj, wsRegions]);

  // set volume of the wavesurfer object, whenever volume variable in state is changed
  useEffect(() => {
    if (wavesurferObj) wavesurferObj.setVolume(volume);
  }, [volume, wavesurferObj]);

  const handlePlayPause = () => {
    wavesurferObj.playPause();
    setPlaying(playing => !playing);
  };

  const handleVolumeSlider = (e) => {
    setVolume(e.target.value);
  };

  const handleZoomSlider = (e) => {
    setZoom(e.target.value);
    wavesurferObj.zoom(e.target.value);
  };

  const handlePlaybackRate = (e) => {
    setPlaybackRate(e.target.value);
    const speed = playbackSpeeds[e.target.value];
    wavesurferObj.setPlaybackRate(speed, true);
  }

  const removeRegion = (region) => {
    wsRegions.regions.filter(r => r.id === region.id)[0].remove();
    let updatedRegions = regions.filter((reg) => {
      return reg.id !== region.id;
    })
    setRegions(updatedRegions);
  }

  const handleTrim = () => {
    if (wavesurferObj) {
      // get start and end points of the selected region
      const region = regions[0];

      if (region) {
        const start = region.start;
        const end = region.end;

        // obtain the original array of the audio
        const originalBuffer = wavesurferObj.getDecodedData();
        setOriginalAudio([...originalAudio , originalBuffer]);

        //duration of new audio buffer
        const newBufferDuration = originalBuffer.duration - (end- start);

        // create a temporary new buffer array with the same length, sample rate and no of channels as the original audio
        const newBuffer = new AudioContext().createBuffer(
          originalBuffer.numberOfChannels,
          (newBufferDuration * originalBuffer.sampleRate),
          originalBuffer.sampleRate
        );

        // create 2 indices:
        // left & right to the part to be trimmed
        const firstListIndex = start * originalBuffer.sampleRate;
        const secondListIndex = end * originalBuffer.sampleRate;
        const secondListMemAlloc =
          originalBuffer.length - end * originalBuffer.sampleRate;

        // create a new array upto the region to be trimmed
        const newList = new Float32Array(parseInt(firstListIndex));

        // create a new array of region after the trimmed region
        const secondList = new Float32Array(
          parseInt(secondListMemAlloc)
        );

        // create an array to combine the 2 parts
        const combined = new Float32Array(newBuffer.length); // changed from originalBuffer.length

        // 2 channels: 1-right, 0-left
        // copy the buffer values for the 2 regions from the original buffer
        for (let i = 0; i < originalBuffer.numberOfChannels; i++) {
          // for the region to the left of the trimmed section
          originalBuffer.copyFromChannel(newList, i);

          // for the region to the right of the trimmed section
          originalBuffer.copyFromChannel(
            secondList,
            i,
            secondListIndex
          );
        }

        // create the combined buffer for the trimmed audio
        combined.set(newList);
        combined.set(secondList, firstListIndex);

        // copy the combined array to the newBuffer
        for (let j = 0; j < newBuffer.numberOfChannels; j++) {
          newBuffer.copyToChannel(combined, j);
        }

        const newAudioBlob = new Blob([encodeWAV(combined,sampleRate)], {type: "audio/wav"});
        const newAudioUrl = URL.createObjectURL(newAudioBlob);

        // load the new url, to restart the wavesurfer's waveform display
        wavesurferObj.load(newAudioUrl);
        removeRegion(region);
        onAudioChange(newAudioBlob);
      }
    }
  };

  const handleUndo = () => {
    if(originalAudio.length > 0) {
      const lastAudioBuffer = originalAudio.slice(-1)[0];
      const channelData = new Float32Array(lastAudioBuffer.length);
      lastAudioBuffer.copyFromChannel(channelData, 0);
      const oldAudioBlob = new Blob([encodeWAV(channelData,sampleRate)], {type: "audio/wav"});
      const oldAudioUrl = URL.createObjectURL(oldAudioBlob);
      wavesurferObj.load(oldAudioUrl);
      onAudioChange(oldAudioBlob);
      setOriginalAudio((prevState) => (prevState.slice(0, -1)));
    }
  }

  return (
    <section className="tw-w-full tw-mt-2 tw-m-auto">
      <div ref={ wavesurferRef } className='tw-my-1' id={`waveform-${segmentId}`}/>
      <div ref={ timelineRef } id={`wave-timeline-${segmentId}`}/>
      <div className="tw-w-full mx-auto">
        <div className="tw-flex tw-items-center tw-justify-evenly tw-mt-2 lg:tw-mb-0 tw-mb-4 tw-gap-1 mb:tw-gap-2">
          <Tooltip title="time in minutes:seconds.milliseconds">
            <div><span> {moment(moment.duration(parseFloat(duration),
          'seconds').asMilliseconds()).subtract({ hours: 1 }).format('mm:ss')}</span></div>
          </Tooltip>
          <Tooltip title="Play/Pause">
            <button
              title="play/pause"
              className="tw-border-none tw-bg-transparent tw-cursor-pointer tw-text-lg"
              onClick={ handlePlayPause }>
              <FontAwesomeIcon className="tw-text-blue-700" icon={ playing ? faPause : faPlay}/>
            </button>
          </Tooltip>
          <Tooltip title="Trim the selected region" >
            <button className="tw-border-none tw-bg-transparent tw-cursor-pointer tw-text-lg" onClick={ handleTrim }>
              <FontAwesomeIcon className="tw-text-blue-700" icon={ faScissors }/>
            </button>
          </Tooltip>
          <Tooltip title="Undo" >
            <button className="tw-border-none tw-bg-transparent tw-cursor-pointer tw-text-lg" onClick={ handleUndo }>
              <FontAwesomeIcon className="tw-text-blue-700" icon={ faRotateLeft }/>
            </button>
          </Tooltip>
          <Tooltip title="Download" >
            <a className="tw-border-none tw-bg-transparent tw-cursor-pointer tw-text-lg" href={ downloadAudioURL } download>
              <FontAwesomeIcon className="tw-text-blue-700" icon={ faFileAudio }/>
            </a>
          </Tooltip>
          <div className="tw-flex tw-items-center gap-2">
            <Tooltip title="playback rate">
              <FontAwesomeIcon className="tw-text-blue-700 mr-1" icon={ faForwardFast }/>
            </Tooltip>
            <input
              type="range"
              min="0"
              max="5"
              step="1"
              value={ playbackRate }
              onChange={ handlePlaybackRate }
              className="slider zoom-slider tw-cursor-pointer"
            />
            <Tooltip title="playback rate">
              <span className='ml-1 tw-w-8'>{ playbackSpeeds[playbackRate] }</span>
            </Tooltip>
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-justify-evenly my-2">
          <div className="tw-flex tw-items-center">
            <Tooltip title="Zoom Out">
              <FontAwesomeIcon className="mr-1 tw-text-blue-700" icon={ faMagnifyingGlassMinus }/>
            </Tooltip>
            <input
              type="range"
              min="1"
              max="500"
              value={ zoom }
              onChange={ handleZoomSlider }
              className="slider zoom-slider tw-cursor-pointer"
            />
            <Tooltip title="Zoom In">
             <FontAwesomeIcon className="ml-1 tw-text-blue-700" icon={ faMagnifyingGlassPlus }/>
            </Tooltip>
          </div>
          <div className="ml-2 tw-flex tw-items-center">
            <FontAwesomeIcon className="mr-1 tw-text-blue-700" icon={ volume > 0.25 ? faVolumeHigh : faVolumeLow }/>
            <input
              type="range"
              min="0"
              max="1"
              step="0.05"
              value={ volume }
              onChange={ handleVolumeSlider }
              className="slider volume-slider"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default AudioWaveform;
