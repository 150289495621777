import React from 'react';

import Task from '../../../models/TaskResult';
import Utils from '../../../utils/Utils';
import Button from '../../../components/Button';

const TaskToolbar = ({
  task,
  scope,
  invalidSegments,
  unsavedTargets,
  onSubmitButtonClicked,
  onSaveButtonClicked,
}) => {
  const permissions = Task.getUserPermission(task);
  const hasInvalidSegments = !Utils.isEmpty(invalidSegments);
  const canSubmit = Task.getUserCanSubmit(task) && !hasInvalidSegments;
  const hasUnsavedSegments = !Utils.isEmpty(unsavedTargets);
  const canSave = Utils.defineBoolean(
    permissions.includes(Task.PERMISSIONS.DATA_EDIT) && hasUnsavedSegments,
    false
  );
  const numEmptyTargets = Utils.define(
    task?.targets?.filter((target) => target?.content?.task?.id !== task?.id)?.length,
    0
  );

  return (
    <div className='card' style={{ boxShadow: 'none' }}>
      <div className='card-body d-flex flex-wrap justify-content-between align-items-center'>
        <div className={numEmptyTargets > 0 ? 'mb-2 text-danger' : 'mb-2'}>
          Pending Target Segments: {numEmptyTargets} of {task?.targets?.length}
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          {task.status === Task.STATUS.PENDING && scope === Task.USER_SCOPE.WORKER && (
            <>
              <Button
                key='submit'
                label='Submit'
                priority='success'
                classes='mr-2 mb-2'
                disabled={!canSubmit}
                handleClick={onSubmitButtonClicked}
              />
              <Button
                key='save'
                label='Save All'
                priority='primary'
                classes='mb-2'
                disabled={!canSave}
                handleClick={onSaveButtonClicked}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskToolbar;
