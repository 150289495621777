import React from 'react'
import PropTypes from "prop-types";


const CardWithIcon = ({ title, description, color, icon }) => {

	return (
		<div className="card b container-fluid">
			<div className={ "card-body text-center " + color }>
				<em className={ "fa-5x fa mb-3 " + icon }></em>
				<br/>
				<span className="h4">{ title }</span>
				<br/>
				<div className="text-sm text-muted">{ description }</div>
			</div>
		</div>
	)
};

CardWithIcon.propTypes = {
	title:PropTypes.string.isRequired,
	description:PropTypes.string,
	color:PropTypes.string.isRequired,
	icon:PropTypes.string.isRequired
};

export default CardWithIcon;