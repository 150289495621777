import React, { useEffect, useState } from 'react';

import Segment from '../../../../models/Segment';
import { calculateAudioLength } from '../../../../utils/Recorder';
import { isUnstructuredSpeechSegment } from '../../hooks/useTTSTargets';

import RecordingDisplay from '../recorder/RecordingDisplay';
import { TargetSegmentReviewFooter } from '../SegmentView';

const reviewToClass = {
  [Segment.REVIEWS.APPROVED]: 'text-success',
  [Segment.REVIEWS.REJECTED]: 'text-danger',
  [Segment.REVIEWS.UNREVIEWED]: '',
};

const TextToSpeechReviewTargetSegment = ({
  segment,
  isLoading,
  isReviewable,
  handleApprove,
  handleReject,
  audio,
  sampleRate,
  transcription,
  validTranscription,
  onTranscriptionChanged,
  isChanged,
  errorMessages,
  segmentMetadata,
  languageQEMetadata,
  isAdmin,
  onAudioChanged,
  validAudio
}) => {
  const review = Segment.isReviewed(segment) ? segment?.status : Segment.getContentReview(segment);

  const showTranscription = isUnstructuredSpeechSegment(segmentMetadata.segmentType);

  const handleTranscriptionChange = (e) => {
    onTranscriptionChanged(segment.id, e.target.value);
  };

  const [audioObject, setAudioObject] = useState(null);
  useEffect(() => {
    let url;
    if (audio) {
      url = window.URL.createObjectURL(audio);
      setAudioObject(url);
    }
    return () => {
      window.URL.revokeObjectURL(url);
    };
  }, [audio]);

  /*
    Reviewer is able to update the transcription, but we can't just
    send the transcription as the data, because it would overwrite the existing audio data.
    That's why we need to send everything: 
     - old Audio, 
     - old wpm, 
     - old length,
     - new transcription
  */
  const dataToSend = {
    transcription,
    audio,
    wpm: languageQEMetadata.wpm,
    score: languageQEMetadata.score,
    length: audio ? calculateAudioLength(audio, sampleRate) : 0,
  };
  const onApprove = () => {
    handleApprove(dataToSend, isChanged);
  };
  const onReject = () => {
    handleReject(dataToSend, isChanged);
  };

  const onAudioChange = (url) => {
    onAudioChanged(
      segment.id,
      url,
      segment?.source?.metadata?.segmentRecDurationMin,
      segment?.source?.metadata?.segmentRecDurationMax,
      segment?.source?.metadata?.segmentType
    );
  }

  return (
    <div className={`pb-3 pr-sm-3 col-12-xs col-sm-6 ${isLoading && 'whirl sphere'}`}>
      <span className={reviewToClass[review]}>Status: {review}</span>
      <RecordingDisplay audio={audioObject} segmentId={segment.id} isAdmin={isAdmin} onAudioChange={onAudioChange} sampleRate={sampleRate}/>
      {showTranscription && (
        <div className={`position-relative`}>
          <label
            className='position-absolute font-weight-bold ml-1 px-3 pt-2 pb-2 bg-white'
            style={{ width: '96%', marginTop: '1px' }}
          >
            Transcription:
          </label>
          <textarea
            rows='3'
            className='form-control pt-5 mb-2'
            onChange={handleTranscriptionChange}
            value={transcription ?? ''}
          />
        </div>
      )}
      {isReviewable && (
        <TargetSegmentReviewFooter
          onApproveButtonClick={onApprove}
          onRejectButtonClick={onReject}
          review={review}
          isChanged={isChanged}
          isValid={validTranscription && validAudio}
          errorMessage={errorMessages.join(' ')}
        />
      )}
    </div>
  );
};

export default TextToSpeechReviewTargetSegment;
