// External libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CardBody, ButtonGroup } from 'reactstrap';
import Swal from 'sweetalert2';
import { throttle, debounce } from 'throttle-debounce';

// Internal libs
import Utils from '../../utils/Utils';
import API_STORAGE from '../../storage/ApiStorage';
import { PAGES } from '../index';
import USER from '../../models/User';
import USER_PERMISSION from '../../models/UserPermission';
import USERGROUP from '../../models/UserGroup';
import { Text } from '../../components/parameters/TextParameter';
import UserGroupController from '../../controllers/UserGroupController';
import ErrorBoundary from '../../components/ErrorBoundary';
import ApiException from '../../models/ApiException';
import Notifier from '../../components/Notifier';
import UserGroupsTable from './components/UserGroupsTable';
import { UserAbortRequestException } from '../../models/ApiException';
import Tooltip from '../../components/Tooltip';
import { objectToQueryString, parseQueryString } from '../../utils/Url';
import { withMessageBox } from '../../contexts/MessageBoxContext';
import ErrorHandler from '../../utils/ErrorHandler';

class UserGroupsPage extends Component {
  constructor(props) {
    super(props);

    this.state = this.loadUserPreferences();
    this.state = this.loadHistory();
    this.state.filters = this.getUrlFilters(this.props.location);
  }

  getDefaultProperties = () => ({ ...UserGroupsPage.defaultProps });
  static defaultProps = {};

  PAGE_SID = Utils.define(PAGES.USERGROUPS_PAGE.sid(), 'USERGROUPS_PAGE');

  state = {
    tableIsLoading: true,

    tablePageIndex: 0,
    tablePageSize: 25, // Saved to user preference
    tableTotalPage: null,
    tableTotalSize: null,
    tableOrder: [{ columnName: 'id', direction: 'desc' }],

    groups: null,
    groupsSelected: [],
    groupsNewUncommitted: [],

    filters: {
      name: null,
      ownerUsername: null,
    },
  };

  userPreferences = {
    tablePageSize: 25,
  };

  history = {
    tablePageIndex: 0,
    tablePageSize: 25,
    tableOrder: [{ columnName: 'name', direction: 'asc' }],
    filters: {},
  };

  // --------------------------------------------------------------------------------------
  // ---------------------------------- ReactJS Methods -----------------------------------
  // --------------------------------------------------------------------------------------
  componentDidMount() {
    try {
      this.updateGroupsTable()
        .then((result) => {
          this.setState({ ...result, tableIsLoading: false });
        })
        .catch((e) => {
          this.errorHandler(e);
          this.setState({ tableIsLoading: false });
        });
    } catch (e) {
      this.errorHandler(e);
    }
  }

  componentDidUpdate() {
    Promise.resolve().then(() => {
      this.saveUserPreferences();
      this.saveHistory();
    });
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    let stateKeys = Object.keys(this.state).filter((key) => this.state[key] !== nextState[key]);
    let propsKeys = Object.keys(this.props).filter((key) => this.props[key] !== nextProps[key]);

    if (propsKeys.includes('location') && nextProps.history?.action === 'REPLACE') {
      propsKeys = propsKeys.filter((obj) => !['location', 'match', 'history'].includes(obj));
    }

    return stateKeys.length > 0 || propsKeys.length > 0;
  }

  render() {
    try {
      this.state.groupsSelected = this.selectedGroupsSync(this.state.groups);

      let canGroupsBeDeleted = false;
      let userCanCreateGroups = USER.hasAnyPermission(
        this.props?.user,
        USER_PERMISSION.TYPES.USERGROUP,
        USERGROUP.PERMISSIONS.CREATE
      );
      let userCanCreateMessages = USER.hasAnyPermission(
        this.props?.user,
        USER_PERMISSION.TYPES.MESSAGE,
        ['CREATE']
      );
      let selectedGroups = this.state.groups?.filter((obj) =>
        this.state?.groupsSelected?.includes(obj.id)
      );

      if (Array.isArray(selectedGroups) === true) {
        canGroupsBeDeleted = selectedGroups.every((obj) =>
          USERGROUP.getUserPermission(obj).includes(USERGROUP.PERMISSIONS.DELETE)
        );
      }

      let isButtonDisabled = selectedGroups?.length === 0 || canGroupsBeDeleted === false;
      const tooltipTitle =
        selectedGroups?.length === 0
          ? 'Select groups to delete'
          : selectedGroups?.length > 0 && canGroupsBeDeleted
          ? 'Delete selected groups'
          : "One or more of selected groups can't be deleted";

      return (
        <div className='col-12 px-0'>
          {/* START FILTER BAR */}
          <CardBody className='row col-12 mx-0'>
            <div className='row col-xs-12 p-0 m-0 w-100'>
              <div className='col-xs-12 col-sm-6 pl-0 pr-2 pb-2'>
                <this.GroupNameFilter />
              </div>
              <div className='col-xs-12 col-sm-6 pl-0 pr-2 pb-2'>
                <this.OwnerUsernameFilter />
              </div>
            </div>
          </CardBody>
          {/* END FILTERS BAR */}
          {/* START TABLE PANEL */}
          <CardBody className='row col-lg-12 mx-0 pt-0'>
            <CardBody className='col-lg-12 b bg-white'>
              {/* START TABLE MENU PANEL */}
              <div className='row m-0 justify-content-between'>
                <ButtonGroup
                  className='col row m-0 justify-content-end px-1'
                  style={{ height: '40px' }}
                >
                  {userCanCreateMessages && selectedGroups?.length > 0 && (
                    <Tooltip key='message_users_tooltip' title='Send a message to selected groups'>
                      <button
                        className='btn btn-secondary border-0 px-2 mb-1'
                        onClick={this.onMessageButtonClick.bind(this)}
                      >
                        <em className='fas fa-envelope' style={{ fontSize: '20px' }} />
                      </button>
                    </Tooltip>
                  )}
                  <this.DeleteGroupsButton disabled={isButtonDisabled} title={tooltipTitle} />
                  {userCanCreateGroups ? <this.CreateGroupButton /> : null}

                  <Tooltip title='Refresh Table' id='refresh-button'>
                    {/* button element is wrapped inside a span as a workaround to enable tooltip when button is disabled*/}
                    <span>
                      <button
                        onClick={throttle(1000, this.refreshButtonClicked.bind(this))}
                        className='btn btn-secondary border-0 px-2'
                      >
                        <em className='fas fa-sync-alt' style={{ fontSize: '20px' }} />
                      </button>
                    </span>
                  </Tooltip>
                </ButtonGroup>
              </div>
              {/* END TABLE MENU PANEL */}
              <div className={this.state.tableIsLoading === true ? 'whirl sphere' : ''}>
                <this.UserGroupsTable />
              </div>
            </CardBody>
          </CardBody>
          {/* END TABLE PANEL */}
        </div>
      );
    } catch (e) {
      this.errorHandler(e);
      return <ErrorBoundary hasError={true} message='Error while rendering groups list.' />;
    }
  }

  GroupNameFilterValue = null;
  GroupNameFilter = () => {
    let onChange = (value) => {
      this.GroupNameFilterValue = value;
      this.groupNameFilterValueChanged(value);
    };

    let updateKey =
      this.GroupNameFilterValue === this.state.filters?.name ? true : this.state.filters?.name;
    this.GroupNameFilterValue = this.state.filters?.name;

    return (
      <ErrorBoundary>
        <Text
          key={updateKey}
          defaultValue={this.state.filters?.name}
          placeholder='Search by group name'
          onChange={debounce(500, onChange)}
        />
      </ErrorBoundary>
    );
  };

  OwnerUsernameFilterValue = null;
  OwnerUsernameFilter = () => {
    let onChange = (value) => {
      this.OwnerUsernameFilterValue = value;
      this.ownerUsernameFilterValueChanged(value);
    };
    let updateKey =
      this.OwnerUsernameFilterValue === this.state.filters?.ownerUsername
        ? true
        : this.state.filters?.ownerUsername;
    this.OwnerUsernameFilterValue = this.state.filters?.ownerUsername;

    return (
      <ErrorBoundary>
        <Text
          defaultValue={this.OwnerUsernameFilterValue}
          placeholder='Search by owner'
          onChange={debounce(500, onChange)}
        />
      </ErrorBoundary>
    );
  };

  CreateGroupButton = () => (
    <Tooltip key='add_tooltip' title='Create New Group'>
      {/* button element is wrapped inside a span as a workaround to enable tooltip when button is disabled*/}
      <span>
        <button
          onClick={throttle(500, true, this.createGroupButtonClicked.bind(this))}
          className='btn btn-secondary border-0 px-2'
        >
          <em className='fas fa-user-plus' style={{ fontSize: '20px' }} />
        </button>
      </span>
    </Tooltip>
  );

  DeleteGroupsButton = (props) => {
    const { title } = props;
    return (
      <Tooltip key='delete_tooltip' title={title}>
        {/* button element is wrapped inside a span as a workaround to enable tooltip when button is disabled*/}
        <span>
          <button
            {...props}
            onClick={throttle(500, true, this.deleteGroupsButtonClicked.bind(this))}
            className='btn btn-secondary border-0 px-2'
          >
            <em className='far fa-trash-alt' style={{ fontSize: '20px' }} />
          </button>
        </span>
      </Tooltip>
    );
  };

  UserGroupsTable = () => {
    let props = {
      data: Utils.defineArray(this.state.groups, []),
      selection: Utils.defineArray(this.state.groupsSelected, []),
      onSelectionChange: this.groupSelected.bind(this),
      onPageChange: this.pageChanged.bind(this),
      onPageSizeChange: this.pageSizeChanged.bind(this),
      onSortingChange: this.sortingChanged.bind(this),
      totalSize: Utils.define(this.state.tableTotalSize, 1),
      page: Utils.define(this.state.tablePageIndex, 0),
      pageSize: this.state.tablePageSize,
      sorting: Utils.defineArray(this.state.tableOrder, []),
      onRowUpdateCommitAsync: this.groupsCommitUpdate.bind(this),
      onRowDeleteCommitAsync: this.groupsCommitDelete.bind(this),
      onRowInsertCommitAsync: this.groupsCommitCreate.bind(this),
      addedRows: Utils.defineArray(this.state.groupsNewUncommitted, []),
      onAddedRowsCancel: this.onAddedRowChange.bind(this, 'cancel'),
      onAddedRowsUpdate: this.onAddedRowChange.bind(this, 'update'),
    };
    return (
      <ErrorBoundary>
        <UserGroupsTable {...props} />
      </ErrorBoundary>
    );
  };

  // -----------------------------------------------------------------------------------
  // --------------------------------- User Events Listeners ---------------------------
  // -----------------------------------------------------------------------------------
  // ------------------------------------------------- Filters
  groupNameFilterValueChanged(value) {
    /* eslint-disable-next-line react/no-direct-mutation-state */
    this.state.filters.name = value;
    this.applyFilters({ ...this.state.filters, tablePageIndex: 0 });
  }

  ownerUsernameFilterValueChanged(value) {
    /* eslint-disable-next-line react/no-direct-mutation-state */
    this.state.filters.ownerUsername = value;
    this.applyFilters({ ...this.state.filters, tablePageIndex: 0 });
  }

  // -------------------------------------------- Table Toolbar
  async refreshButtonClicked() {
    this.applyFilters();
  }

  async createGroupButtonClicked() {
    try {
      let newGroup = {
        name: 'Unnamed Group',
        members: [],
      };
      let id = -1;
      this.state.groupsNewUncommitted.forEach((obj) => {
        if (id >= obj.id) {
          id = obj.id - 1;
        }
      });
      newGroup.id = id;
      this.setState({
        groupsNewUncommitted: Array.from([...this.state.groupsNewUncommitted, ...[newGroup]]),
      });
    } catch (e) {
      this.errorHandler(e);
    }
  }

  async deleteGroupsButtonClicked() {
    try {
      let alert = await this.deleteConfirmAlert(
        this.state.groups?.filter((obj) => this.state.groupsSelected?.includes(obj.id))
      );
      if (alert.value !== true) {
        return;
      }

      this.setState({ tableIsLoading: true });
      let requests = await Promise.all(
        this.state.groupsSelected?.map((obj) =>
          this.requestGroupDelete(obj)
            .then((result) => ({ id: obj, status: result }))
            .catch((e) => {
              this.errorHandler(e);
              return { id: obj, status: false };
            })
        )
      );

      let succeed = requests.filter((obj) => obj.status === true).map((obj) => obj.id);
      if (succeed.length === 0) {
        this.setState({ tableIsLoading: false });
      } else {
        this.setState({ ...(await this.updateGroupsTable()), tableIsLoading: false });
      }
    } catch (e) {
      this.errorHandler(e);
      this.setState({ tableIsLoading: false });
    }
  }

  onMessageButtonClick() {
    const { openMessageBox } = this.props;
    openMessageBox(this.state.groupsSelected);
  }

  // ------------------------------------------- Table
  pageChanged(pageIndex) {
    this.applyTableSettings({ tablePageIndex: pageIndex });
  }

  pageSizeChanged(pageSize) {
    this.applyTableSettings({ tablePageSize: pageSize });
  }

  sortingChanged(sorting) {
    this.applyTableSettings({ tableOrder: sorting });
  }

  onAddedRowChange(type = null, data = []) {
    /* eslint-disable-next-line react/no-direct-mutation-state */
    if (Utils.define(type, '') !== 'cancel') {
      this.state.groupsNewUncommitted = data;
    } /* eslint-disable-next-line react/no-direct-mutation-state */ else {
      this.state.groupsNewUncommitted = this.state.groupsNewUncommitted?.filter((obj) =>
        data.every((o) => o.id !== obj.id)
      );
    }
  }

  groupSelected(groupIds = []) {
    this.setState({ groupsSelected: groupIds });
  }

  // --------------------------------------------------------------------------------
  // ------------------------------------- Table Actions ----------------------------
  // --------------------------------------------------------------------------------
  async groupsCommitUpdate(data = {}) {
    let commitStatus = {};
    try {
      this.setState({ tableIsLoading: true });
      let editGroups = Object.keys(data)
        .map((obj) => parseInt(obj))
        .map((id) => ({ ...data[id], id: id }));

      let results = await Promise.all(
        editGroups.map((obj) =>
          this.groupCommitUpdate(obj)
            .then((res) => ({ id: obj.id, status: true, result: res }))
            .catch((e) => {
              this.errorHandler(e);
              return { id: obj.id, status: false, result: e };
            })
        )
      );
      // fill table commit statuses
      results.forEach((obj) => {
        commitStatus[obj.id] = obj.status;
      });

      // Load failed updated groups from server to get valid data
      let errors = await Promise.all(
        results.filter((obj) => obj.status !== true).map((obj) => this.requestGroup(obj.id))
      );
      // Update results with valid data
      results = [
        ...results.filter((obj) => obj.status === true).map((obj) => obj.result),
        ...errors,
      ];
      // update groups
      let newGroups = this.state.groups?.map((group) => {
        let updatedGroup = results.filter((obj) => obj.id === group.id).pop();
        return Utils.isNull(updatedGroup) ? group : updatedGroup;
      });
      this.setState({ groups: newGroups, tableIsLoading: false });
    } catch (e) {
      this.errorHandler(e);
      this.setState({ tableIsLoading: false, ...(await this.updateGroupsTable()) });
    }
    return commitStatus;
  }

  async groupCommitUpdate(data = {}) {
    let group = this.state.groups?.filter((obj) => obj.id === data.id).pop();

    if (data?.name !== group?.name) {
      group = await this.requestUpdateGroup({ ...data, id: group.id });
    }

    return group;
  }

  async groupsCommitDelete(ids = []) {
    try {
      let groups = this.state.groups?.filter((obj) => ids.includes(obj.id));

      let alert = await this.deleteConfirmAlert(groups);
      if (alert.value !== true) {
        return;
      }

      this.setState({ tableIsLoading: true });
      await Promise.all(
        this.state.groups
          ?.filter((obj) => ids.includes(obj.id))
          .map((obj) =>
            this.requestGroupDelete(obj.id)
              .then((status) => ({ status: status, id: obj.id }))
              .catch((e) => {
                this.errorHandler(e);
                return { status: e, id: obj.id };
              })
          )
      );
      this.setState({
        ...(await this.updateGroupsTable()),
        tableIsLoading: false,
      });
    } catch (e) {
      this.errorHandler(e);
      this.setState({ tableIsLoading: false });
    }
  }

  async groupsCommitCreate(data = []) {
    let commitStatus = {};
    try {
      this.setState({ tableIsLoading: true });
      let groupsNewUncommitted = Array.from(this.state.groupsNewUncommitted);
      let groupsNewCommitIds = data.map((obj) => obj.id);
      let newGroups = groupsNewUncommitted.filter((obj) =>
        groupsNewCommitIds?.some((id) => id === obj.id)
      );
      newGroups = await Promise.all(
        newGroups.map((obj) =>
          this.groupCommitCreate(obj)
            .then((res) => ({ status: true, result: res, rowId: obj.id }))
            .catch((e) => {
              this.errorHandler(e);
              return { status: false, result: obj, rowId: obj.id };
            })
        )
      );
      // setup commit status
      newGroups.forEach((obj) => {
        commitStatus[obj.rowId] = obj.status;
      });

      let succeed = newGroups.filter((obj) => obj.status === true);
      groupsNewUncommitted = groupsNewUncommitted.filter((obj) =>
        succeed?.every((o) => o.rowId !== obj.id)
      );

      this.setState({
        tableIsLoading: false,
        groups: [...succeed.map((obj) => obj.result), ...this.state.groups],
        groupsNewUncommitted: groupsNewUncommitted,
      });
    } catch (e) {
      this.errorHandler(e);
      this.setState({ tableIsLoading: false });
    }
    return commitStatus;
  }

  async groupCommitCreate(data) {
    return await this.requestGroupCreate(data);
  }

  // ------------------------------------------------------------------------------
  // ------------------------------- Private Utils --------------------------------
  // ------------------------------------------------------------------------------
  async applyFilters(filters = null) {
    try {
      filters = Utils.define(filters, this.state.filters);
      this.setState({ tableIsLoading: true });
      let newState = await this.updateGroupsTable(filters);
      this.setState({ ...newState, tableIsLoading: false, filters: filters });
    } catch (e) {
      if (UserAbortRequestException()?.code !== e?.code) {
        this.setState({ tableIsLoading: false, groups: [] });
      }
      this.errorHandler(e);
    }
  }

  async applyTableSettings(settings = null) {
    try {
      this.setState({ tableIsLoading: true });
      let newState = await this.updateGroupsTable(settings);
      this.setState({ ...newState, tableIsLoading: false });
    } catch (e) {
      if (UserAbortRequestException()?.code !== e?.code) {
        this.setState({ tableIsLoading: false });
      }
      this.errorHandler(e);
    }
  }

  requestParametersMapping(params = {}) {
    let map = {};
    if (!Utils.isEmpty(params?.name)) {
      map.sql_name = 'name ilike "%' + Utils.define(params?.name, '') + '%"';
    }
    if (!Utils.isEmpty(params?.ownerUsername)) {
      map.sql_ownerUsername =
        'ownerUsername ilike "%' + Utils.define(params?.ownerUsername, '') + '%"';
    }
    if (!Utils.isEmpty(params.tableOrder)) {
      let orderBy = this.sortingToOrderBy(params.tableOrder);
      if (!Utils.isEmpty(orderBy)) {
        map.orderBy = orderBy;
      }
    }
    map.page = params.tablePageIndex;
    map.pageSize = params.tablePageSize;
    return map;
  }

  async updateGroupsTable(overrideParams) {
    let parameters = {
      tablePageIndex: this.state.tablePageIndex,
      tablePageSize: this.state.tablePageSize,
      tableOrder: this.state.tableOrder,
      name: this.state.filters?.name,
      ownerUsername: this.state.filters?.ownerUsername,
      ...overrideParams,
    };
    parameters.tablePageIndex++;
    return this.requestGroups(this.requestParametersMapping(parameters)).then((results) => {
      return {
        tablePageSize: parameters?.tablePageSize,
        tableTotalSize: Utils.define(results?.totalSize),
        tableTotalPage: Utils.define(results?.totalPage),
        tablePageIndex: Utils.define(results?.pageIndex, 0),
        tableOrder: parameters?.tableOrder,
        groups: Utils.defineArray(results?.page, []),
      };
    });
  }

  sortingToOrderBy(sorting) {
    let orderBy = '';
    Utils.defineArray(sorting, []).forEach((obj) => {
      if (!Utils.isEmpty(orderBy)) {
        orderBy = orderBy + ', ';
      }
      orderBy = orderBy + obj.columnName + ' ' + obj.direction;
    });
    return orderBy;
  }

  selectedGroupsSync(groups) {
    return groups
      ?.filter((obj) => this.state?.groupsSelected?.includes(obj.id))
      .map((obj) => obj.id);
  }

  async deleteConfirmAlert(groups) {
    return Swal.mixin({
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-outline-danger mx-2 px-5 btn-lg font-weight-bold',
        cancelButton: 'btn btn-outline-secondary mx-2 px-5 btn-lg font-weight-bold',
      },
    }).fire({
      title: 'User delete!',
      text:
        'You are about to delete user group ' +
        groups.map((obj) => obj.name).join(',') +
        '. Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });
  }

  // -----------------------------------------------------------------------------------
  // --------------------------------- Requests ----------------------------------------
  // -----------------------------------------------------------------------------------
  async requestGroups(filters) {
    return UserGroupController.getAll(filters);
  }

  async requestGroup(groupId) {
    return UserGroupController.get(groupId);
  }

  async requestUpdateGroup(group) {
    return UserGroupController.update(group);
  }

  async requestGroupDelete(groupId) {
    return UserGroupController.delete(groupId);
  }

  async requestGroupCreate(group) {
    return UserGroupController.create(group);
  }

  // --------------------------------------------------------------------------
  // ------------------------------ User Preferences --------------------------
  // --------------------------------------------------------------------------
  saveUserPreferences() {
    try {
      let updatedKeys = Object.keys(this.userPreferences).filter(
        (key) => this.userPreferences[key] !== this.state[key]
      );
      if (updatedKeys?.length > 0) {
        updatedKeys.forEach((key) => {
          this.userPreferences[key] = this.state[key];
        });
        API_STORAGE.USER_PREFERENCES.add({ [this.PAGE_SID]: this.userPreferences });
      }
    } catch (e) {
      this.errorHandler(e);
    }
  }

  loadUserPreferences() {
    try {
      let prefs = API_STORAGE.USER_PREFERENCES.read()?.[this.PAGE_SID];
      return Utils.isEmpty(prefs) ? this.state : { ...this.state, ...prefs };
    } catch (e) {
      this.errorHandler(e);
    }
  }

  // --------------------------------------------------------------------------
  // ------------------------------ History -----------------------------------
  // --------------------------------------------------------------------------
  saveHistory() {
    try {
      let updatedKeys = Object.keys(this.history).filter(
        (key) => this.history[key] !== this.state[key]
      );
      if (updatedKeys.length > 0) {
        updatedKeys.forEach((key) => {
          this.history[key] = this.state[key];
        });
        this.props.history.replace({
          pathname: this.props?.location?.pathname,
          state: this.history,
          search: objectToQueryString(this.state?.filters),
        });
      }
    } catch (e) {
      this.errorHandler(e, false);
    }
  }

  loadHistory() {
    try {
      if (Utils.isEmpty(this.props?.location?.state)) {
        return this.state;
      }
      this.history = this.props.location.state;
      return { ...this.state, ...this.history };
    } catch (e) {
      this.errorHandler(e);
    }
  }

  getUrlFilters(location = null) {
    let filters = {};
    try {
      filters = parseQueryString(Utils.define(location, this.props?.location)?.search);
    } catch (e) {
      this.errorHandler(e);
    }
    return filters;
  }

  // --------------------------------------------------------------------------
  // ------------------------------ Errors ------------------------------------
  // --------------------------------------------------------------------------
  errorHandler(e, message) {
    try {
      e = ApiException.toApiException(e, message);
      if (UserAbortRequestException()?.code === e?.code) {
        return;
      }
      Notifier.error(Utils.isNull(message) ? e?.userMessage : message);
      ErrorHandler.errorHandle(e);
    } catch (e) {
      ErrorHandler.errorHandle(e);
    }
  }
}

// ------------------------------------------------------------------------------------
// ------------------------------- Redux ----------------------------------------------
// ------------------------------------------------------------------------------------
const mapStateToProps = (state = {}, ownProps) => ({
  ...ownProps,
  user: Utils.define(state?.session?.user),
});

export default connect(mapStateToProps)(withRouter(withMessageBox(UserGroupsPage)));
