import { PAGES } from '..';
import moment from 'moment';
import User from '../../models/User';
import Utils from '../../utils/Utils';
import Project from '../../models/Project';
import './views/styles/ProjectListing.scss';
import Loader from '../../components/Loader';
import Notifier from '../../components/Notifier';
import React, { useEffect, useState } from 'react';
import UserPermission from '../../models/UserPermission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectController from '../../controllers/ProjectController';
import ProjectListingFilters from './components/ProjectListingFilters';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { faPlus, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { PagingState, CustomPaging, SortingState } from '@devexpress/dx-react-grid';
import { Grid, Table, PagingPanel, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';

const ProjectList = ({ user }) => {
  const history = useHistory();
  const [filters, setFilters] = useState({});
  const [projects, setProjects] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const hasCreatePermission = User.hasAnyPermission(user, UserPermission.TYPES.PROJECT, ['CREATE']);
  const [sorting, setSorting] = useState([
    {
      columnName: 'id',
      direction: 'desc',
    },
  ]);
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });

  const columns = [
    {
      name: 'id',
      title: 'ID',
      orderable: true,
      getCellValue: (data) => data.id,
    },
    {
      name: 'name',
      title: 'Name',
      orderable: true,
      getCellValue: (data) => data.name,
    },
    {
      orderable: true,
      name: 'description',
      title: 'Description',
      getCellValue: (data) => data.description,
    },
    {
      name: 'scope',
      title: 'Type',
      orderable: true,
      getCellValue: (data) => (data.scope === Project.SCOPE.CROWDSOURCING.sid ? 'Regular' : 'Test'),
    },
    {
      orderable: true,
      name: 'sourceLanguage',
      title: 'Source Language',
      getCellValue: (data) => data.sourceLanguage ?? 'N/A',
    },
    {
      orderable: true,
      name: 'targetLanguage',
      title: 'Target Language',
      getCellValue: (data) => data.targetLanguage ?? 'N/A',
    },
    {
      name: 'status',
      title: 'Status',
      orderable: true,
      getCellValue: (data) => data.status,
    },
    {
      orderable: true,
      name: 'visibility',
      title: 'Visibility',
      getCellValue: (data) => data.visibility,
    },
    {
      name: 'owner',
      title: 'Owner',
      orderable: true,
      getCellValue: (data) => data.owner.sid,
    },
    {
      orderable: true,
      title: 'Created At',
      name: 'timestampCreated',
      getCellValue: (data) => moment(data.timestampCreated).format('YYYY-MM-DD HH:mm'),
    },
  ];

  const sortableColumns = columns.filter((col) => col.orderable).map((col) => col.name);

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      const direction = props.direction;
      return (
        <TableHeaderRow.SortLabel {...props} className='sortable-column-label'>
          <div className='tw-flex tw-items-center'>
            {props.children}
            <div className='ml-2'>
              {!Utils.isNull(direction) ? (
                <FontAwesomeIcon icon={direction === 'asc' ? faArrowUp : faArrowDown} />
              ) : (
                <FontAwesomeIcon icon={faArrowUp} className='tw-opacity-40' />
              )}
            </div>
          </div>
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const getProjects = async (params) => {
    setLoading(true);
    try {
      ProjectController.adminGetAll(params).then((r) => {
        setProjects(r.content);
        setTotalElements(r.totalElements);
        setLoading(false);
      });
    } catch (e) {
      Notifier.error(e.toString());
    }
  };

  const handleCreateProject = () => {
    history.push(PAGES.PROJECT_CREATE_PAGE.path());
  };

  const handleFilterChange = (field, value) => {
    setLoading(true);
    setFilters((prev) => ({ ...prev, [field]: String(value).trim() }));
    (Utils.isNull(value) || value === '') &&
      setFilters((prev) => {
        const obj = { ...prev };
        delete obj[field];
        return obj;
      });
    setPagination({ ...pagination, page: 0 });
  };

  const handleClearFilters = () => {
    setFilters({});
  };

  const handleSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPagination({ ...pagination, page: page });
  };

  const handlePageSizeChange = (size) => {
    setLoading(true);
    setPagination({ ...pagination, size: size });
  };

  const rowComponent = ({ row, ...restProps }) => {
    return (
      <Table.Row
        {...restProps}
        className='tw-cursor-pointer hover:tw-bg-gray-100 tw-transition-all tw-ease-in-out tw-duration-200'
        onClick={() => history.push(PAGES.PROJECT_PAGE.path(row.id))}
      />
    );
  };

  useEffect(() => {
    let params = {};
    if (!Utils.isNull(pagination)) {
      params['page'] = pagination.page;
      params['size'] = pagination.size;
    }
    if (!Utils.isEmpty(sorting)) {
      params['sort'] = sorting[0].columnName + ',' + sorting[0].direction;
    }
    if (!Utils.isEmpty(filters)) {
      params = { ...params, ...filters };
    }

    if (
      filters.id?.length > 0 ||
      filters.name?.taskId > 0 ||
      filters['sourceLanguage.name']?.length > 0 ||
      filters['targetLanguage.name']?.length > 0
    ) {
      const timer = setTimeout(() => {
        getProjects(params);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      getProjects(params);
    }
  }, [filters, sorting, pagination]);

  return (
    <div>
      <div className='tw-relative tw-text-center tw-mb-6'>
        <span className='tw-text-2xl tw-font-semibold'>Projects List</span>
        {hasCreatePermission && (
          <div className='create-button' onClick={handleCreateProject}>
            <FontAwesomeIcon icon={faPlus} className='tw-text-white' size='xl' />
          </div>
        )}
      </div>
      <div className='tw-border tw-border-solid tw-border-gray-200 tw-rounded-tl tw-rounded-tr'>
        <div className='tw-bg-gray-100 tw-bg-opacity-50'>
          <ProjectListingFilters
            filters={filters}
            handleFilterChange={handleFilterChange}
            handleClearFilters={handleClearFilters}
          />
        </div>
        <div className='tw-relative'>
          {isLoading && <Loader isLoading={isLoading} />}
          <Grid rows={projects} columns={columns}>
            <SortingState sorting={sorting} onSortingChange={handleSorting} />
            <PagingState
              currentPage={pagination.page}
              pageSize={pagination.size}
              onCurrentPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
            <CustomPaging totalCount={totalElements} />
            <Table
              columnExtensions={[
                {
                  width: '50',
                  align: 'center',
                  columnName: 'id',
                },
                {
                  width: 'auto',
                  columnName: 'name',
                  wordWrapEnabled: true,
                },
                {
                  width: '90',
                  columnName: 'scope',
                  wordWrapEnabled: true,
                },
                {
                  width: 'auto',
                  columnName: 'sourceLanguage',
                  wordWrapEnabled: true,
                },
                {
                  width: 'auto',
                  columnName: 'targetLanguage',
                  wordWrapEnabled: true,
                },
                {
                  width: '90',
                  columnName: 'status',
                  wordWrapEnabled: true,
                },
                {
                  width: '90',
                  columnName: 'visibility',
                  wordWrapEnabled: true,
                },
                {
                  width: 'auto',
                  columnName: 'owner',
                  wordWrapEnabled: true,
                },
                {
                  width: '145',
                  columnName: 'timestampCreated',
                  wordWrapEnabled: true,
                },
                {
                  width: '50',
                  align: 'center',
                  wordWrapEnabled: true,
                  columnName: 'controls',
                },
              ]}
              rowComponent={rowComponent}
            />
            <TableHeaderRow
              showSortingControls={true}
              sortLabelComponent={TableHeaderRowSortLabelComponent}
            />
            <PagingPanel pageSizes={[10, 25, 50, 100]} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default ProjectList;
