import React, { useState } from 'react';

import Segment from '../../../../models/Segment';
import Task from '../../../../models/TaskResult';

import ReviewerToolbar from '../ReviewerToolbar';
import Notifier from '../../../../components/Notifier';
import SegmentController from '../../../../controllers/SegmentController';
import TaskResultController from '../../../../controllers/TaskResultController';
import { prepareDataForSending, useAnnotationTargets } from '../../hooks/useAnnotationTargets';
import AnnotationReviewSegment from './AnnotationReviewSegment';
import { ROOT } from '../../../project/TagBuilder';

const TaskAnnotationReviewView = ({ task, scope, taskIsUpdated }) => {
  const { targets, changeTargets, onDataChanged } = useAnnotationTargets(task);

  const handleSave =
    (segmentIds = [], review) =>
    async (data = null, hasNewData = false) => {
      changeTargets(segmentIds, { loadingData: true, changed: false });
      try {
        let save;
        let reviewAttribute;
        if (scope === Task.USER_SCOPE.WORKER) {
          save = saveAsWorker;
          reviewAttribute = 'review';
        } else {
          save = saveAsLeadReviewer;
          reviewAttribute = 'status';
        }

        const segments = segmentIds.map((segmentId) => {
          let segment = {
            id: parseInt(segmentId),
            [reviewAttribute]: review,
          };
          if (hasNewData && data && review !== Segment.REVIEWS.REJECTED) {
            segment.data = JSON.stringify(prepareDataForSending(data));
          }
          return segment;
        });

        const newTask = await save(task, segments);
        taskIsUpdated(newTask);
      } catch (error) {
        Notifier.error(error.message);
      } finally {
        changeTargets(segmentIds, { loadingData: false });
      }
    };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const newTask = await submitTask(task.id);
      taskIsUpdated(newTask, true);
    } catch (error) {
      Notifier.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleComplete = async () => {
    try {
      setIsSubmitting(true);
      const newTask = await completeCheck(task.id);
      taskIsUpdated(newTask, true);
    } catch (error) {
      Notifier.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const segmentIds = task.targets.map((target) => target.id.toString());
  const toolbar = (
    <ReviewerToolbar
      task={task}
      scope={scope}
      handleSubmit={handleSubmit}
      handleComplete={handleComplete}
      handleApproveAll={handleSave(segmentIds, Segment.REVIEWS.APPROVED)}
      handleRejectAll={handleSave(segmentIds, Segment.REVIEWS.REJECTED)}
    />
  );

  const isWorkerReviewer = Task.getUserPermission(task).includes(Task.PERMISSIONS.DATA_EDIT);
  const targetLanguage = task?.targets?.[0]?.language;
  const tagDefinitions = task.options?.tagDefinitions ?? {};

  let segmentsCounter = 0;
  let totalSegments = task?.targets?.length;

  return (
    <div className={`${isSubmitting ? 'whirl sphere' : ''}`}>
      {toolbar}
      <div className='card' style={{ boxShadow: 'none' }}>
        <div className='card-body p-0'>
          {task?.targets.map((segment) => {
            segmentsCounter++;
            return (
              <div id={segment.id} className='tw-flex tw-flex-row'>
                <div>
                  <span className='tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-border tw-border-solid tw-border-gray-200 tw-rounded tw-w-8 tw-h-7'>
                    {segmentsCounter}/{totalSegments}
                  </span>
                </div>
                <div className='tw-w-full tw-pt-1'>
                  <AnnotationReviewSegment
                    key={segment.id}
                    segment={segment}
                    isReviewable={
                      isWorkerReviewer ||
                      Segment.getUserPermission(segment).includes(Segment.PERMISSIONS.EDIT)
                    }
                    handleApprove={handleSave([segment.id.toString()], Segment.REVIEWS.APPROVED)}
                    handleReject={handleSave([segment.id.toString()], Segment.REVIEWS.REJECTED)}
                    targetLanguage={targetLanguage}
                    data={targets[segment.id].data}
                    onDataChanged={onDataChanged}
                    isLoading={targets[segment.id].loadingData}
                    isChanged={!!targets[segment.id].changed}
                    isValid={!!targets[segment.id].valid}
                    errorMessages={targets[segment.id].errorMessages}
                    tagDefinitions={tagDefinitions}
                    root={ROOT}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {toolbar}
    </div>
  );
};

export default TaskAnnotationReviewView;

const saveAsWorker = async (task, segments) => {
  return await saveTask(task.id, segments);
};

const saveAsLeadReviewer = async (task, segments) => {
  const newSegments = await saveSegments(segments);
  const newTask = { ...task };
  newSegments.forEach((newSegment) => {
    for (let i = 0; i < task.targets.length; i++) {
      if (task.targets[i].id === newSegment.id) {
        newTask.targets[i] = newSegment;
        break;
      }
    }
  });
  return newTask;
};

const saveSegments = async (segments) => SegmentController.updateAll(segments);

const saveTask = (id, data) => {
  return TaskResultController.save(id, data);
};

const submitTask = (id) => {
  return TaskResultController.submit(id);
};

const completeCheck = async (id) => {
  return TaskResultController.update(id, {
    evaluationStatus: Task.EVALUATION_STATUS.NOT_RISKY,
  });
};
