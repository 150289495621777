import React from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';

export const TableHeaderRowSortLabelComponent = (props) => {
  if (props?.direction === 'asc')
    return (
      <TableHeaderRow.SortLabel {...props}>
        {props?.children}
        <span className='pl-2'>
          <em className='fas fa-long-arrow-alt-up' />
        </span>
      </TableHeaderRow.SortLabel>
    );
  if (props?.direction === 'desc')
    return (
      <TableHeaderRow.SortLabel {...props}>
        {props?.children}
        <span className='pl-2'>
          <em className='fas fa-long-arrow-alt-down' />
        </span>
      </TableHeaderRow.SortLabel>
    );
  return <TableHeaderRow.SortLabel {...props}>{props.children}</TableHeaderRow.SortLabel>;
};
