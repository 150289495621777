import React, { useState } from 'react'
import Notifier from '../../../../components/Notifier';

import TaskToolbar from '../TaskToolbar';
import TextToSpeechSegment from './TextToSpeechSegment';
import TaskResultController from '../../../../controllers/TaskResultController';
import { blobToBase64, calculateAudioLength } from '../../../../utils/Recorder'
import { useTTSTargets } from '../../hooks/useTTSTargets';
import TaskResult from '../../../../models/TaskResult'

const TaskTextToSpeechView = ({ task, scope, taskIsUpdated }) => {
  const sampleRate = task.options?.sampleRate;

  const { targets, changeTargets, onAudioChanged, onTranscriptionChanged } = useTTSTargets(
    task,
    sampleRate ?? 16000
  );

  const isRolledback = task?.version > 0;

  const handleSave =
    (segmentIds = []) =>
    async () => {
      changeTargets(segmentIds, { loadingData: true });
      try {
        const content = await Promise.all(
          segmentIds.map((segmentId) => {
            return prepareSegmentData(
              segmentId,
              targets[segmentId].audio,
              sampleRate,
              targets[segmentId]?.transcription ?? null,
              targets[segmentId]?.languageQEMetadata?.wpm ?? null,
              targets[segmentId]?.languageQEMetadata?.score ?? null
            );
          })
        );
        const newTask = await saveTask(task.id, content);
        await taskIsUpdated(newTask);
        changeTargets(segmentIds, { loadingData: false, changed: false });
      } catch (error) {
        Notifier.error(error.message);
        changeTargets(segmentIds, { loadingData: false });
      }
    };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async () => {
    const metadata =  { totalRecordingDuration : TaskResult.getTotalRecordedTime(task.targets) };
    try {
      setIsSubmitting(true);
      const newTask = await submitTask(task.id, isRolledback, metadata);
      await taskIsUpdated(newTask, true);
    } catch (error) {
      Notifier.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const unsavedTargetIds = Object.keys(targets).filter(
    (id) =>
      targets[id].changed &&
      !targets[id].loadingData &&
      !targets[id].loadingLanguageQEMetadata &&
      targets[id].validAudio &&
      targets[id].validTranscription
  );

  const invalidSegments = Object.keys(targets).filter(
    (id) => !targets[id].validAudio || !targets[id].validTranscription
  );

  const toolbar = (
    <TaskToolbar
      task={task}
      scope={scope}
      invalidSegments={invalidSegments}
      unsavedTargets={unsavedTargetIds}
      onSaveButtonClicked={handleSave(unsavedTargetIds)}
      onSubmitButtonClicked={handleSubmit}
    />
  );

  const retryLoadingLanguageQEMetadata = (segmentId) => () => {
    changeTargets([segmentId], {
      shouldStartLanguageQE: true,
      loadingLanguageQEMetadataFailed: false,
      validAudio: true,
      errorMessages: [],
    });
  };

  let segmentsCounter = 0;
  let totalSegments = task?.targets?.length;

  return (
    <div className={`${isSubmitting ? 'whirl sphere' : ''}`}>
      {toolbar}
      <div className='card' style={{ boxShadow: 'none' }}>
        <div className='card-body p-0'>
          {task?.targets.map((segment) => {
            segmentsCounter++;
            return (
              <div id={segment.id} key={segment.id} className='tw-flex tw-flex-row'>
                <div>
                  <span className='tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-border tw-border-solid tw-border-gray-200 tw-rounded tw-w-8 tw-h-7'>
                    {segmentsCounter}/{totalSegments}
                  </span>
                </div>
                <div className='tw-w-full tw-pt-1'>
                  <TextToSpeechSegment
                    key={segment.id}
                    segment={segment}
                    audio={targets[segment.id].audio}
                    sampleRate={sampleRate}
                    validAudio={targets[segment.id].validAudio}
                    onAudioChanged={onAudioChanged}
                    transcription={targets[segment.id].transcription}
                    validTranscription={targets[segment.id].validTranscription}
                    onTranscriptionChanged={onTranscriptionChanged}
                    segmentMetadata={targets[segment.id].metadata}
                    task={task}
                    taskIsUpdated={taskIsUpdated}
                    errorMessages={targets[segment.id].errorMessages}
                    isLoading={targets[segment.id].loadingData}
                    isLoadingLanguageQEMetadata={targets[segment.id].loadingLanguageQEMetadata}
                    loadingLanguageQEMetadataFailed={
                      targets[segment.id].loadingLanguageQEMetadataFailed
                    }
                    onRetryLanguageQE={retryLoadingLanguageQEMetadata(segment.id)}
                    isChanged={targets[segment.id].changed}
                    shouldUpdateOriginalTranscription={targets[segment.id].shouldUpdateOriginalTranscription}
                    handleSave={handleSave([segment.id])}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {toolbar}
    </div>
  );
};

export default TaskTextToSpeechView;

const saveTask = async (id, data) => {
  return await TaskResultController.save(id, data);
};

const submitTask = async (id, isRolledback, metadata) => {
  await updateMetadata(id, metadata);
  if (isRolledback) {
    return TaskResultController.resubmit(id);
  } else {
    return TaskResultController.submit(id);
  }
};

const prepareSegmentData = async (segmentId, audio, sampleRate, transcription, wpm, score) => {
  const length = calculateAudioLength(audio, sampleRate);
  const base64EncodedAudio = await blobToBase64(audio);

  return {
    id: parseInt(segmentId),
    data: JSON.stringify({
      audio: base64EncodedAudio,
      transcription,
      wpm,
      length,
      score,
    })
  };
};

const updateMetadata = (id, metadata) => {
  return TaskResultController.updateMetadata(id, metadata);
};
