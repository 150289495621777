import React, { useEffect, useState } from 'react';
import Notifier from '../../../components/Notifier';
import ProjectController from '../../../controllers/ProjectController';
import ProjectApplicantsTabView from '../views/ProjectApplicantsTabView';
import ScreeningController from '../../../controllers/ScreeningController';

const ProjectApplicantsTabState = ({ project, canSendMessage }) => {
  const [loading, setLoading] = useState(true);
  const [applicants, setApplicants] = useState([]);
  const [pagination, setPagination] = useState({});
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [sorting, setSorting] = useState([{ columnName: 'email', direction: 'asc' }]);

  const getApplicants = () => {
    setLoading(true);
    try {
      let sortingParams = '';
      if (sorting.length > 0) {
        sorting.map((item) => {
          if (item.columnName === 'email') {
            return (sortingParams += 'u.sid,' + item.direction);
          } else {
            return (sortingParams += 'isMember' + ',' + item.direction);
          }
        });
      }

      ProjectController.getApplicants(project.id, {
        page: page,
        size: pageSize,
        sort: sortingParams,
      })
        .then((r) => {
          setApplicants(r.content);
          setPagination({
            page: r.pageable.pageNumber,
            size: r.pageable.pageSize,
          });
          setTotalElements(r.totalElements);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      Notifier.error(e.message);
    }
  };

  const handleApproveAll = (userId = null) => {
    setLoading(true);
    try {
      ProjectController.completeAllScreenerSteps(project.id, userId).finally(() => {
        getApplicants();
      });
    } catch (e) {
      Notifier.error(e.message);
    }
  };

  const completeStep = (stepId, userId) => {
    setLoading(true);
    try {
      ScreeningController.completeStep(stepId, userId).finally(() => {
        getApplicants();
      });
    } catch (e) {
      Notifier.error(e.message);
    }
  };

  const unCompleteStep = (stepId, userId) => {
    setLoading(true);
    try {
      ScreeningController.unCompleteStep(stepId, userId).finally(() => {
        getApplicants();
      });
    } catch (e) {
      Notifier.error(e.message);
    }
  };

  const handleStepToggle = (step, userId) => {
    if (step.isCompleted) {
      unCompleteStep(step.id, userId);
    } else {
      completeStep(step.id, userId);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  useEffect(() => {
    getApplicants();
  }, [sorting, page, pageSize]);

  return (
    <ProjectApplicantsTabView
      isLoading={loading}
      canSendMessage={canSendMessage}
      applicants={applicants}
      sorting={sorting}
      handleSorting={setSorting}
      selectedUsers={selectedUsers}
      setSelectedUsers={setSelectedUsers}
      pagination={pagination}
      totalElements={totalElements}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      handleApproveAll={handleApproveAll}
      handleStepToggle={handleStepToggle}
    />
  );
};

export default ProjectApplicantsTabState;
