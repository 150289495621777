import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Tooltip from '../../../components/Tooltip';
import SelectWithValidation from '../../../components/forms/SelectWithValidation';
import Loader from '../../../components/Loader';
import { findOption } from '../../../utils/Select';

const ProjectScreeningView = ({
  isLoading,
  screeningStepSettings,
  screenerStepOptions,
  roleOptions,
  createScreeningStepSetting,
  invalidateScreeningStepSetting,
  removeScreeningStepSetting,
}) => {
  const initialValues = {
    step: null,
    role: null,
  };

  const handleReset = (resetForm) => {
    resetForm();
  };

  const isStepProject = (stepId) => {
    return screenerStepOptions.some(
      (o) => o.value === parseInt(stepId) && o.type === 'ScreenerStepProject'
    );
  };

  const handleSubmit = (values, formik) => {
    let data = {
      roleId: values.role,
    };
    createScreeningStepSetting(values.step, data);
    handleReset(formik.resetForm);
  };

  const handleInvalidate = (id) => {
    invalidateScreeningStepSetting(id);
  };

  const handleDelete = (e, step) => {
    removeScreeningStepSetting(step.screenerStep.id);
  };

  const validationSchema = Yup.object({
    step: Yup.string().required('Screener Step cannot be empty!').nullable()
  });

  return (
    <div className='jumbotron tw-relative'>
      {isLoading && <Loader></Loader>}
      <h1 className='tw-border-solid tw-border-b tw-border-t-0 tw-border-x-0 tw-border-b-gray-200 tw-pb-2 tw-mb-8'>
        Add Screener Step Setting
      </h1>
      <Formik
        className='tw-relative tw-z-0'
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, { resetForm });
        }}
      >
        {(props) => {
          return (
            <div>
              <Form onSubmit={props.handleSubmit}>
                <Row>
                  <Col sm={12} md={6}>
                    <SelectWithValidation
                      id='step'
                      propertyName='step'
                      label='Screener Step'
                      helpText='Select a screening step.'
                      options={screenerStepOptions}
                      defaultOption={findOption(screenerStepOptions, props.values.step)}
                      defaultValue={props.values.step}
                      touched={props.touched.step}
                      error={props.errors.step}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <SelectWithValidation
                      id='role'
                      propertyName='role'
                      label='Role'
                      helpText='Assign the role for this screening step'
                      options={roleOptions}
                      defaultOption={findOption(roleOptions, props.values.role)}
                      defaultValue={props.values.role}
                      touched={props.touched.role}
                      error={props.errors.role}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className='tw-flex tw-justify-end'>
                    <Button
                      className={props.isValid ? 'btn-success' : 'btn-secondary'}
                      type='submit'
                      disabled={!props.isValid}
                    >
                      Add
                    </Button>
                    <Button
                      className='btn-secondary tw-ml-4'
                      type='reset'
                      onClick={() => {
                        handleReset(props.resetForm);
                      }}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
              <div className='tw-mt-20'>
                <h1 className='tw-border-solid tw-border-b tw-border-t-0 tw-border-x-0 tw-border-b-gray-200 tw-pb-2 tw-mb-8'>
                  Step settings
                </h1>
                <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4 tw-mb-10'>
                  {screeningStepSettings.length > 0 ? (
                    screeningStepSettings.map((step) => {
                      return (
                        <div
                          key={step.screenerStep.id}
                          className='tw-grid tw-grid-cols-1 tw-border-solid tw-border tw-border-gray-200 tw-p-6 tw-rounded-lg'
                        >
                          <div className='mb-4'>
                            <span className='tw-text-3xl tw-text-gray-900'>
                              {step.screenerStep.name}
                            </span>
                          </div>
                          <div className='mb-4'>
                            <span className='tw-text-lg tw-text-gray-900'>Type - </span>
                            <span className='tw-text-lg'>{step.screenerStep.type}</span>
                          </div>
                          <div className='mb-4'>
                            <span className='tw-text-lg tw-text-gray-900'>
                              {step.role?.name ? 'Role - ' : ''}
                            </span>
                            <span className='tw-text-lg'>
                              {step.role?.name ? step.role.name : ''}
                            </span>
                          </div>
                          <div className='tw-flex tw-justify-around'>
                            <Tooltip
                              key='outdated_tooltip'
                              title='Marks the step as outdated for this project only: all HLP applicants/members of this project will have to complete this step again'
                            >
                              <Button
                                className='btn-secondary tw-flex tw-items-center'
                                onClick={() => handleInvalidate(step.screenerStep.id)}
                              >
                                <em
                                  className='far fa-clock'
                                  style={{ fontSize: '18px', width: '18px' }}
                                />
                              </Button>
                            </Tooltip>
                            <Tooltip key='delete_tooltip' title='Delete'>
                              <Button
                                className='btn-secondary tw-flex tw-items-center'
                                onClick={(e) => handleDelete(e, step)}
                              >
                                <em
                                  className='far fa-trash-alt'
                                  style={{ fontSize: '18px', width: '18px' }}
                                ></em>
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className='tw-col-span-3 tw-text-center tw-px-2 tw-py-4'>
                      No screening steps have been added yet. <br />
                      Start by setting a screening step above.
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default ProjectScreeningView;
