import dayjs from 'dayjs';
import moment from 'moment';
import { PAGES } from '../..';
import { json2excel } from 'js2excel';
import { Tooltip } from '@mui/material';
import Utils from '../../../utils/Utils';
import './styles/ProjectTrackingView.scss';
import { useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import TaskResult from '../../../models/TaskResult';
import API_STORAGE from '../../../storage/ApiStorage';
import { timestampToDuration } from '../../../utils/DateHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import ProjectTrackingFilters from '../components/ProjectTrackingFilters';
import TaskResultController from '../../../controllers/TaskResultController';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import CheckboxWithValidation from '../../../components/forms/CheckboxWithValidation';
import {
  faArrowDown,
  faArrowUp,
  faChevronDown,
  faChevronUp,
  faCircleCheck,
  faCircleExclamation,
  faClockRotateLeft,
  faDownload,
  faFileExport,
  faRightToBracket,
  faTableColumns,
  faTimes,
  faTruckRampBox,
} from '@fortawesome/free-solid-svg-icons';
import {
  PagingState,
  CustomPaging,
  SortingState,
  RowDetailState,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  PagingPanel,
  ColumnChooser,
  TableHeaderRow,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';

const ProjectTrackingView = ({
  projectId,
  tasks,
  sorting,
  filters,
  isLoading,
  pagination,
  handleSorting,
  totalElements,
  handlePageChange,
  handlePageSizeChange,
  handleFilterChange,
  handleClearFilters,
  handleDeliverTask,
}) => {
  const history = useHistory();
  const [expandedRows, setExpandedRows] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [isColumnsHelperOpen, setColumnsHelperOpen] = useState(false);
  const [disableColumnVisiblityHandling, setColumnVisibilityHandling] = useState(false);

  const resolveUserGroups = (row) => {
    return (
      <div className='tw-flex tw-items-center tw-gap-1 tw-flex-wrap tw-text-center'>
        {row.userGroups.map((group) => {
          return (
            <span
              key={group.name}
              className='tw-px-1 tw-text-xs tw-border tw-border-solid tw-border-gray-200 tw-bg-gray-50 tw-rounded'
            >
              {group.name}
            </span>
          );
        })}
      </div>
    );
  };

  const resolveStatus = (type, status, version = null, timestampExpires = null) => {
    const color = TaskResult.getStatusColor(type, status);
    return (
        <span
          className={`[overflow-wrap:anywhere] tw-relative tw-flex tw-items-center tw-justify-center tw-text-center tw-p-0.5 tw-text-xs ${
            color.bg && `tw-${color.bg}`
          } tw-border tw-border-solid ${color.border && `tw-${color.border}`} tw-rounded ${
            color.text && `tw-${color.text}`
          }`}
        >
          {Utils.enumToCamelcase(status === TaskResult.STATUS.PENDING ? 'IN PROGRESS' : status)}
          {version > 0 && ' - ' + version}
          {status === TaskResult.STATUS.PENDING && '(expires on ' +
            moment(timestampExpires).format('YYYY-MM-DD HH:mm') +
            ')'}
        </span>
    );
  };

  const resolveControls = (row) => {
    const taskResultID = row.id;
    const isDeliverable =
      row.taskResult.status === TaskResult.STATUS.REVIEWED ||
      row.taskResult.status === TaskResult.STATUS.DELIVERED;
    const deliveryStatus = row.taskResult.deliveryStatus;
    const permissions = Array.isArray(row.permissions) ? row.permissions : [];

    let action = null;
    if (permissions.includes(TaskResult.PERMISSIONS.READ)) action = 'Open';
    else if (permissions.includes(TaskResult.PERMISSIONS.DATA_EDIT)) action = 'Resume';
    else if (permissions.includes(TaskResult.PERMISSIONS.EDIT)) action = 'Review';
    else if (Utils.isEmpty(permissions)) action = 'Pending';

    const handleRedirectToTaskResult = (action) => {
      const scope =
        action === 'RESUME' ? TaskResult.USER_SCOPE.WORKER : TaskResult.USER_SCOPE.REVIEWER;
      history.push(PAGES.TASK_RESULT_PAGE.path(row.id), {
        back: history.location.pathname,
        scope: scope,
      });
    };

    return (
      <div className='tw-flex tw-items-center tw-justify-end tw-gap-1'>
        {isDeliverable && (
          <div
            className={`tw-flex tw-items-center tw-justify-center tw-border tw-border-solid tw-border-gray-200 tw-rounded tw-w-8 tw-h-8 tw-min-w-8 tw-min-h-8 ${
              deliveryStatus === TaskResult.DELIVERY_STATUS.DELIVERED ||
              deliveryStatus === TaskResult.DELIVERY_STATUS.PENDING
                ? 'tw-cursor-default'
                : 'tw-cursor-pointer'
            }`}
          >
            <Tooltip
              title={`${
                deliveryStatus === TaskResult.DELIVERY_STATUS.FAILED
                  ? 'Task delivery failed. Try again.'
                  : deliveryStatus === TaskResult.DELIVERY_STATUS.DELIVERED
                  ? 'Task delivered successfully.'
                  : deliveryStatus === TaskResult.DELIVERY_STATUS.PENDING
                  ? 'Task is pending delivery.'
                  : deliveryStatus === null && 'Task is Reviewed and eligible for Delivery.'
              }`}
              placement='top'
            >
              <div className='tw-relative'>
                {deliveryStatus === TaskResult.DELIVERY_STATUS.FAILED && (
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    size='sm'
                    className='tw-absolute -tw-right-2 -tw-top-2 tw-text-orange-700'
                  />
                )}
                {deliveryStatus === TaskResult.DELIVERY_STATUS.DELIVERED && (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    size='sm'
                    className='tw-absolute -tw-right-2 -tw-top-2 tw-text-green-600'
                  />
                )}
                {deliveryStatus === TaskResult.DELIVERY_STATUS.PENDING && (
                  <FontAwesomeIcon
                    icon={faClockRotateLeft}
                    size='sm'
                    className='tw-absolute -tw-right-2 -tw-top-2 tw-text-gray-600 tw-animate-reverse-spin'
                  />
                )}
                <FontAwesomeIcon
                  icon={faTruckRampBox}
                  size='lg'
                  className={`${
                    deliveryStatus === TaskResult.DELIVERY_STATUS.DELIVERED ||
                    deliveryStatus === TaskResult.DELIVERY_STATUS.PENDING
                      ? 'tw-opacity-50'
                      : null
                  }`}
                  onClick={
                    deliveryStatus === TaskResult.DELIVERY_STATUS.FAILED || deliveryStatus === null
                      ? () => handleDeliverTask(taskResultID)
                      : null
                  }
                />
              </div>
            </Tooltip>
          </div>
        )}
        <div
          className='tw-flex tw-items-center tw-justify-center tw-border tw-border-solid tw-border-gray-200 tw-rounded tw-w-8 tw-h-8 tw-cursor-pointer'
          onClick={() => handleRedirectToTaskResult(action)}
        >
          <Tooltip title='Open' placement='top'>
            <FontAwesomeIcon icon={faRightToBracket} size='lg' />
          </Tooltip>
        </div>
      </div>
    );
  };

  const columns = [
    {
      name: 'id',
      title: 'TR ID',
      orderable: true,
      hideable: true,
      getCellValue: (row) => row.id,
    },
    {
      name: 'taskId',
      title: 'T ID',
      orderable: true,
      hideable: true,
      getCellValue: (row) => row.taskId,
    },
    {
      name: 'task.type.id',
      title: 'Type',
      orderable: true,
      hideable: true,
      getCellValue: (row) => row.type.name,
    },
    {
      name: 'worker.sid',
      title: 'Worker',
      orderable: true,
      hideable: true,
      getCellValue: (row) => {
        return <span className='[overflow-wrap:anywhere]'>{row.worker.sid}</span>;
      },
    },
    {
      name: 'referrer',
      title: 'Ref',
      orderable: false,
      hideable: true,
      getCellValue: (row) => row.referrer,
    },
    {
      name: 'userGroups',
      title: 'Groups',
      orderable: false,
      hideable: true,
      getCellValue: resolveUserGroups,
    },
    {
      name: 'worker.metadata.ethnicGroup',
      title: 'Origin',
      orderable: true,
      hideable: true,
      getCellValue: (row) => row.worker?.metadata?.ethnicGroup,
    },
    {
      name: 'worker.metadata.gender',
      title: 'Gndr',
      orderable: true,
      hideable: true,
      getCellValue: (row) => Utils.textFirstOnlyUpper(row.worker?.metadata?.gender),
    },
    {
      name: 'worker.metadata.yearOfBirth',
      title: 'YOB',
      orderable: true,
      hideable: true,
      getCellValue: (row) => row.worker?.metadata?.yearOfBirth,
    },
    {
      name: 'status',
      title: 'Status',
      orderable: true,
      hideable: true,
      getCellValue: (row) => resolveStatus('STATUS', row.taskResult.status, row.version, row.taskResult.timestampExpires),
    },
    {
      name: 'evaluationStatus',
      title: 'Eval Status',
      orderable: true,
      hideable: true,
      getCellValue: (row) => resolveStatus('EVALUATION_STATUS', row.taskResult.evaluationStatus),
    },
    {
      name: 'paymentStatus',
      title: 'Pay Status',
      orderable: true,
      hideable: true,
      getCellValue: (row) => {
        return (
          <span className='[overflow-wrap:anywhere]'>
            {Utils.enumToCamelcase(row.taskResult.paymentStatus)}
          </span>
        );
      },
    },
    {
      name: 'timestampSubmitted',
      title: 'Timestamp Submitted',
      orderable: true,
      hideable: true,
      getCellValue: (row) => {
        return Utils.isNull(row.taskResult.timestampSubmitted) ? '' :
        moment(row.taskResult.timestampSubmitted).format('YYYY-MM-DD HH:mm')},
    },
    {
      name: 'speakerId',
      title: 'Spkr ID',
      orderable: true,
      hideable: true,
      getCellValue: (row) => {
        return <span className='[overflow-wrap:anywhere]'>{row.worker?.metadata?.speakerId}</span>;
      },
    },
    {
      name: 'segmentsApproved',
      title: 'Segments Approved',
      orderable: true,
      hideable: true,
      getCellValue: (row) => row.segmentsApproved,
    },
    {
      name: 'segmentsRejected',
      title: 'Segments Rejected',
      orderable: true,
      hideable: true,
      getCellValue: (row) => row.segmentsRejected,
    },
    {
      name: 'credits',
      title: 'Credits',
      orderable: true,
      hideable: true,
      getCellValue: (row) => row.taskResult.credits,
    },
    {
      name: 'evaluationScore',
      title: 'Evaluation Score',
      orderable: true,
      hideable: true,
      getCellValue: (row) => row.taskResult.evaluationScore,
    },
    {
      name: 'metadata',
      title: 'Total Recorded Time',
      orderable: true,
      hideable: true,
      getCellValue: (row) => {
        const totalRecordingDuration = Utils.isNull(row.taskResult.stringMetadata) ? '' :
          moment(moment.duration(parseFloat(row?.taskResult?.stringMetadata.totalRecordingDuration),
            'seconds').asMilliseconds()).subtract({ hours: 1 }).format('HH:mm:ss');
        return totalRecordingDuration;
      }
    },
    {
      name: 'submitTime',
      title: 'Submit Time',
      orderable: true,
      hideable: true,
      getCellValue: (row) => timestampToDuration(row.taskResult.submitTime),
    },
    { name: 'controls', getCellValue: resolveControls },
  ];

  const sortableColumns = columns.filter((col) => col.orderable).map((col) => col.name);

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      const direction = props.direction;

      return (
        <TableHeaderRow.SortLabel {...props} className='sortable-column-label'>
          <div className='tw-flex tw-items-center tw-text-xs md:tw-text-sm'>
            {props.children}
            <div className='ml-1'>
              {!Utils.isNull(direction) ? (
                <FontAwesomeIcon icon={direction === 'asc' ? faArrowUp : faArrowDown} />
              ) : (
                <FontAwesomeIcon icon={faArrowUp} className='tw-opacity-40' />
              )}
            </div>
          </div>
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold tw-text-xs md:tw-text-sm'>{props.column.title}</span>;
    }
  };

  const handleColumnVisibilityChange = (field, checked) => {
    if (checked) {
      setVisibleColumns((prev) => {
        const newState = [...prev, field];
        saveUserPreferences('visibleColumns', newState);
        return newState;
      });
    } else {
      setVisibleColumns((prev) => {
        let newState = [...prev];
        newState.splice(newState.indexOf(field), 1);
        saveUserPreferences('visibleColumns', newState);
        return newState;
      });
    }
  };

  const getHiddenColumns = () => {
    const hidden = columns
      .filter((col) => col.hideable)
      .filter((col) => !visibleColumns.includes(col.name))
      .flatMap((col) => [col.name]);
    return hidden;
  };

  const toolbarRoot = () => {
    return (
      <div className='table-toolbar'>
        <div>
          <span className='tw-font-medium tw-text-base'>Total task results:</span>
          <span className='tw-ml-2 tw-text-base'>{totalElements}</span>
        </div>
        <div className='tw-flex tw-items-center tw-gap-2'>
          <span className='toolbar-button' onClick={() => downloadTasks('table')}>
            <Tooltip title="Export to CSV the tasks that are currently on the table's page.">
              <FontAwesomeIcon icon={faFileExport} className='icon' />
            </Tooltip>
          </span>
          <span className='toolbar-button' onClick={() => downloadTasks('all')}>
            <Tooltip title="Export to CSV all of the project's tasks.">
              <FontAwesomeIcon icon={faDownload} className='icon' />
            </Tooltip>
          </span>
          <span
            className='toolbar-button'
            onClick={() => setColumnsHelperOpen(!isColumnsHelperOpen)}
          >
            <FontAwesomeIcon icon={faTableColumns} className='icon' />
          </span>

          {isColumnsHelperOpen && (
            <div className='column-visibility-box'>
              <div className='tw-absolute tw-right-3 tw-top-2'>
                <FontAwesomeIcon
                  icon={faTimes}
                  className='tw-text-lg tw-cursor-pointer'
                  onClick={() => setColumnsHelperOpen(false)}
                />
              </div>
              <div className='tw-px-2'>
                <div className='tw-text-center tw-mb-4'>
                  <span className='tw-text-[15px] tw-font-medium'>Visible Columns</span>
                </div>
                {columns
                  .filter((col) => col.hideable)
                  .map((col) => {
                    return (
                      <div key={col.name} className='tw-mb-2'>
                        <CheckboxWithValidation
                          label={col.title}
                          isFormGroup={false}
                          propertyName={col.name}
                          onChange={handleColumnVisibilityChange}
                          defaultValue={visibleColumns.includes(col.name)}
                          disabled={
                            visibleColumns.includes(col.name) && disableColumnVisiblityHandling
                          }
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const toggleExpandRow = (props) => {
    return (
      <td
        className='text-center align-middles relative tw-cursor-pointer tw-p-2'
        onClick={props?.onToggle}
      >
        <FontAwesomeIcon icon={props?.expanded ? faChevronUp : faChevronDown} />
      </td>
    );
  };

  const rowDetail = ({ row }) => {
    const hiddenCols = getHiddenColumns().map((col) => col);
    return (
      <div className='tw-w-full tw-flex tw-justify-around tw-gap-2 tw-p-2'>
        {columns
          .filter((col) => hiddenCols.includes(col.name))
          .map((col) => {
            return (
              <div key={col.name} className='tw-rounded tw-bg-[#fff] tw-p-2 tw-text-center'>
                <div className='tw-mb-1'>
                  <span className='tw-font-bold tw-text-gray-500'>{col.title}</span>
                </div>
                <div>
                  <span>{col.getCellValue(row)}</span>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const cellComponent = (props) => {
    return <Table.Cell {...props} className='tw-p-[6px]' />;
  };

  const saveUserPreferences = (pref, value) => {
    const visibleColumns = { [pref]: value };
    API_STORAGE.USER_PREFERENCES.add({ ['PROJECT_PAGE_' + projectId]: visibleColumns });
  };

  const loadUserPreferences = () => {
    const visibleColumnsFromPrefs =
      API_STORAGE.USER_PREFERENCES.read()?.['PROJECT_PAGE_' + projectId]?.visibleColumns;

    if (!!visibleColumnsFromPrefs) {
      setVisibleColumns(visibleColumnsFromPrefs);
    } else {
      setVisibleColumns([
        'id',
        'taskId',
        'task.type.id',
        'worker.sid',
        'referrer',
        'userGroups',
        'worker.metadata.ethnicGroup',
        'worker.metadata.gender',
        'worker.metadata.yearOfBirth',
        'status',
        'evaluationStatus',
        'paymentStatus',
        'timestampSubmitted',
      ]);
    }
  };

  useEffect(() => {
    loadUserPreferences();
  }, []);

  useEffect(() => {
    setColumnVisibilityHandling(visibleColumns.length <= 1);
  }, [visibleColumns]);

  const downloadTasks = async (source) => {
    let excelTasks = [];
    let tasksToDownload;

    const convertAndExport = async (tasks) => {
      for (let i = 0; i < tasks.length; i++) {
        excelTasks.push(await tasksJsonToExcelFormat(tasks[i]));
      }
      json2excel({ data: excelTasks, name: projectId + ' tasks', formatdate: 'dd/mm/yyyy' });
    };

    if (source === 'table') {
      tasksToDownload = tasks;
      await convertAndExport(tasksToDownload);
    } else if (source === 'all') {
      tasksToDownload = (
        await TaskResultController.getProjectTaskResults(projectId, {
          page: 0,
          size: 100000,
        })
      ).content;
      await convertAndExport(tasksToDownload);
    }
  };

  const tasksJsonToExcelFormat = async (task) => {
    return {
      'TaskResult ID': task.id,
      'Task ID': task.taskId,
      Type: task.type?.name ?? '',
      'User SID': task.worker?.sid ?? null,
      Credits: task.taskResult.credits,
      Status: task.taskResult.status,
      'Evaluation Status': task.taskResult.evaluationStatus ?? '',
      'Payment Status': task.taskResult.paymentStatus,
      Segments: task.segmentsNum ?? '',
      'Approved Segments': task.segmentsApproved ?? '',
      'Rejected Segments': task.segmentsRejected ?? '',
      'Rollback Version': task.version,
      'Submit Time': timestampToDuration(task.taskResult.submitTime) ?? '',
      'Date Claimed': task.taskResult.timestampCreated
        ? dayjs(task.taskResult.timestampCreated).format('YYYY-MM-DD HH:mm')
        : null,
      'Date Submitted': task.taskResult.timestampSubmitted
        ? dayjs(task.taskResult.timestampSubmitted).format('YYYY-MM-DD HH:mm')
        : null,
      'Date Reviewed': task.taskResult.timestampReviewed
        ? dayjs(task.taskResult.timestampReviewed).format('YYYY-MM-DD HH:mm')
        : null,
    };
  };

  return (
    <div>
      <ProjectTrackingFilters
        filters={filters}
        handleFilterChange={handleFilterChange}
        handleClearFilters={handleClearFilters}
      />
      <div className='tw-relative'>
        {isLoading && <Loader isLoading={isLoading} />}
        <Grid rows={tasks} columns={columns}>
          <RowDetailState expandedRowIds={expandedRows} onExpandedRowIdsChange={setExpandedRows} />
          <SortingState sorting={sorting} onSortingChange={handleSorting} />
          <PagingState
            currentPage={pagination.page}
            pageSize={pagination.size}
            onCurrentPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
          <CustomPaging totalCount={totalElements} />
          <Table
            columnExtensions={[
              {
                width: '72',
                columnName: 'id',
              },
              {
                width: '72',
                columnName: 'taskId',
              },
              {
                width: '100',
                columnName: 'task.type.id',
                wordWrapEnabled: true,
              },
              {
                width: '80',
                columnName: 'worker.sid',
                wordWrapEnabled: true,
              },
              {
                width: 'auto',
                columnName: 'referrer',
                wordWrapEnabled: true,
              },
              {
                width: 'auto',
                columnName: 'userGroups',
                wordWrapEnabled: true,
              },
              {
                width: 'auto',
                columnName: 'worker.metadata.ethnicGroup',
                wordWrapEnabled: true,
              },
              {
                width: '80',
                columnName: 'worker.metadata.gender',
                wordWrapEnabled: true,
              },
              {
                width: '70',
                columnName: 'worker.metadata.yearOfBirth',
                wordWrapEnabled: true,
              },
              {
                width: 'auto',
                columnName: 'status',
                wordWrapEnabled: true,
              },
              {
                width: 'auto',
                columnName: 'evaluationStatus',
                wordWrapEnabled: true,
              },
              {
                width: 'auto',
                columnName: 'paymentStatus',
                wordWrapEnabled: true,
              },
              {
                width: 'auto',
                columnName: 'timestampSubmitted',
                wordWrapEnabled: true,
              },
              {
                width: '200',
                columnName: 'speakerId',
                wordWrapEnabled: true,
              },
              {
                width: 'auto',
                columnName: 'segmentsApproved',
                wordWrapEnabled: true,
              },
              {
                width: 'auto',
                columnName: 'segmentsRejected',
                wordWrapEnabled: true,
              },
              {
                width: 'auto',
                columnName: 'credits',
                wordWrapEnabled: true,
              },
              {
                width: 'auto',
                columnName: 'evaluationScore',
                wordWrapEnabled: true,
              },
              {
                width: 'auto',
                columnName: 'submitTime',
                wordWrapEnabled: true,
              },
              {
                width: '80',
                align: 'center',
                wordWrapEnabled: true,
                columnName: 'controls',
              },
            ]}
            cellComponent={cellComponent}
          />
          <TableHeaderRow
            showSortingControls={true}
            sortLabelComponent={TableHeaderRowSortLabelComponent}
          />
          <TableColumnVisibility hiddenColumnNames={getHiddenColumns()} />
          <Toolbar rootComponent={toolbarRoot} />
          <ColumnChooser />
          <TableRowDetail toggleCellComponent={toggleExpandRow} contentComponent={rowDetail} />
          <PagingPanel pageSizes={[10, 25, 50, 100, 500, 1000]} />
        </Grid>
      </div>
    </div>
  );
};

export default ProjectTrackingView;
