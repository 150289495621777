import {
  ConflictException,
  NotFoundException,
  UnauthorizedException,
  UnknownServerException,
  UnprocessableEntityException,
  UserAbortRequestException,
  BadRequestException,
  MethodNotAllowedException,
} from './models/ApiException';

export const get = (url, configOverrides = {}) => {
  const requestOptions = {
    method: 'GET',
    credentials: 'same-origin',
    mode: 'cors',
    headers: { Accept: 'application/json' },
    ...configOverrides,
  };
  return _call(url, requestOptions);
};

export const post = (url, data, configOverrides = {}) => {
  const requestOptions = {
    method: 'POST',
    credentials: 'same-origin',
    mode: 'cors',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
    ...configOverrides,
  };
  return _call(url, requestOptions);
};

export const put = (url, data, configOverrides = {}) => {
  const requestOptions = {
    method: 'PUT',
    credentials: 'same-origin',
    mode: 'cors',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
    ...configOverrides,
  };
  return _call(url, requestOptions);
};

export const remove = (url, data, configOverrides = {}) => {
  const requestOptions = {
    method: 'DELETE',
    credentials: 'same-origin',
    mode: 'cors',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
    ...configOverrides,
  };
  return _call(url, requestOptions);
};

const _call = async (url, requestOptions) => {
  try {
    const response = await fetch(url, requestOptions);
    const data = await parse(response);

    if (!response.ok) {
      switch (response.status) {
        case 400:
          throw new BadRequestException(data);
        case 401:
          throw new UnauthorizedException(data);
        case 404:
          throw new NotFoundException(data);
        case 405:
          throw new MethodNotAllowedException(data);
        case 409:
          throw new ConflictException(data);
        case 422:
          throw new UnprocessableEntityException(data);
        case 500:
          throw new UnknownServerException(data);
        default:
          throw new Error(data);
      }
    }

    return data;
  } catch (error) {
    if (error.code === 20) {
      throw new UserAbortRequestException();
    }
    throw error;
  }
};

const parse = async (response) => {
  const responseText = await response.text();
  try {
    return JSON.parse(responseText);
  } catch (error) {
    return responseText;
  }
};
