import React from 'react';
import PropTypes from 'prop-types';
import { FormFieldHelpText } from './FormFieldHelpText';
import { FormFieldError } from './FormFieldError';

export const FormFieldFooter = (props) => {
  if (props.error && props.touched) {
    return <FormFieldError error={props.error} touched={props.touched} />;
  } else if (props.helpText) {
    return <FormFieldHelpText value={props.helpText} />;
  } else {
    return null;
  }
};

FormFieldFooter.propTypes = {
  helpText: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};
