class ApiStorageItem {
  constructor(props) {
    this.props = { ...ApiStorageItem.defaultProps, ...props };
  }

  static defaultProps = {
    name: 'hlp-storage-item',
  };

  read() {
    let data = sessionStorage.getItem(this.props?.name ?? ApiStorageItem.defaultProps.name);
    return !data || data.length === 0 ? {} : JSON.parse(data);
  }

  save(data) {
    sessionStorage.setItem(
      this.props?.name ?? ApiStorageItem.defaultProps.name,
      JSON.stringify(data ?? null)
    );
  }

  add(data) {
    let newData = { ...this.read(), ...(data ?? {}) };
    this.save(newData);
  }
}

const ApiStorage = {
  USER_PREFERENCES: new ApiStorageItem({ name: 'hlp-user-preferences' }),
};

export default ApiStorage;
