import dayjs from 'dayjs';
import '../styles/datetimepicker.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

const DateTimePickerInput = ({
  label,
  value,
  format,
  sx = null,
  isDateOnly = false,
  isClearable = false,
  handleChange = null,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        {label && (
          <div>
            <span className='tw-mb-0'>{label}</span>
          </div>
        )}
        {isDateOnly ? (
          <DatePicker
            sx={sx}
            format={format}
            value={value ? dayjs(value) : null}
            slotProps={{
              field: { clearable: isClearable },
            }}
            onChange={(value) => handleChange(value ? value.toDate() : null)}
          />
        ) : (
          <DateTimePicker
            sx={sx}
            format={format}
            value={value ? dayjs(value) : null}
            slotProps={{
              field: { clearable: isClearable },
            }}
            onChange={(value) => handleChange(value ? value.toDate() : null)}
          />
        )}
      </div>
    </LocalizationProvider>
  );
};

export default DateTimePickerInput;
