import Utils from '../utils/Utils';

export const CHARACTER_RECOGNITION_REGEX = {
  // Hindi
  'in-Hi': /[\u0900-ॾ]/g,
  // Tamil
  'ta-In': /[\u0B80-௺]/g,
  // Nepali
  'ne-NP': /[\u0900-\u097F]/g,
  // Assamese(Unicode block Bengali)
  'as-IN': /[\u0980-\u09FF]/g,
  // Bengali(India)
  'bn-IN': /[\u0980-\u09FF]/g,
  // Telugu(India)
  'te-IN': /(([\u0c00-౾])|(\d))/g,
  // Pashto (Pakistan) - Arabic range 0600–06FF
  'ps-PK': /(([\u0600-\u06FF])|(\d))/g,
  // Punjabi (Pakistan) - subset of the extended Arabic ranges. see unicode range in:https://en.wikipedia.org/wiki/Arabic_script
  'pa-PK':
    /(([\u{0600}-\u{06FF}\u{0750}-\u{077F}\u{08A0}-\u{08FF}\u{FB50}-\u{FDFF}\u{FE70}-\u{FEFF}\u{1EE00}-\u{1EEFF}\u{1EC70}-\u{1ECBF}\u{1ED00}-\u{1ED4F}\u{10E60}-\u{10E7F}])|(\d))/gu,
  // Lao(Lao P.D.R.)
  'lo-LA': /(([\u0E80-\u0EFF])|(\d))/g,
  // Sinhala(Sri Lanka)
  'si-LK': /(([\u0D80-\u0DFF])|(\d))/g,
  // Sorani - subset of the extended Arabic ranges. see unicode range in:https://en.wikipedia.org/wiki/Arabic_script
  'ku-Arab-IQ':
    /(([\u{0600}-\u{06FF}\u{0750}-\u{077F}\u{08A0}-\u{08FF}\u{FB50}-\u{FDFF}\u{FE70}-\u{FEFF}\u{1EE00}-\u{1EEFF}\u{1EC70}-\u{1ECBF}\u{1ED00}-\u{1ED4F}\u{10E60}-\u{10E7F}])|(\d))/gu,
  // Dari - subset of the extended Arabic ranges. see unicode range in:https://en.wikipedia.org/wiki/Arabic_script
  'prs-AF':
    /(([\u{0600}-\u{06FF}\u{0750}-\u{077F}\u{08A0}-\u{08FF}\u{FB50}-\u{FDFF}\u{FE70}-\u{FEFF}\u{1EE00}-\u{1EEFF}\u{1EC70}-\u{1ECBF}\u{1ED00}-\u{1ED4F}\u{10E60}-\u{10E7F}])|(\d))/gu,
  // Turkish(Turkey)
  'tr-TR': /[a-zA-Z0-9ığüşöçİĞÜŞÖÇ]/g,
  // Burmese(Myanmar)
  'my-MM': /[\u1000-\u109F]/g,
  // Gurmukhi - Punjabi(India)
  'pa-IN': /[\u0A00-\u0A7F]/g,
  // Kurmanji(Northern Kurdish)
  kmr: /[a-zA-Z0-9çêîşûÇÊÎŞÛ]/g,
  // Kurmanji(Arabic) - subset of the extended Arabic ranges. see unicode range in:https://en.wikipedia.org/wiki/Arabic_script
  'kmr-ar':
    /(([\u{0600}-\u{06FF}\u{0750}-\u{077F}\u{08A0}-\u{08FF}\u{FB50}-\u{FDFF}\u{FE70}-\u{FEFF}\u{1EE00}-\u{1EEFF}\u{1EC70}-\u{1ECBF}\u{1ED00}-\u{1ED4F}\u{10E60}-\u{10E7F}])|(\d))/gu,
  // Urdu - subset of the extended Arabic ranges. see unicode range in:https://en.wikipedia.org/wiki/Arabic_script
  'ur-PK':
    /(([\u{0600}-\u{06FF}\u{0750}-\u{077F}\u{08A0}-\u{08FF}\u{FB50}-\u{FDFF}\u{FE70}-\u{FEFF}\u{1EE00}-\u{1EEFF}\u{1EC70}-\u{1ECBF}\u{1ED00}-\u{1ED4F}\u{10E60}-\u{10E7F}])|(\d))/gu,
  // Spanish - Basic Latin, Latin-1 Supplement, Latin Extended-A, Latin Extended-B
  'es-ES': /(([\u{0020}-\u{007F}\u{00A0}-\u{00FF}\u{0100}-\u{017F}\u{0180}-\u{024F}])|(\d))/gu,
  // Zulu(South Africa) - Basic Latin, Latin-1 Supplement, Latin Extended-A, Latin Extended-B
  'zu-ZA': /(([\u{0020}-\u{007F}\u{00A0}-\u{00FF}\u{0100}-\u{017F}\u{0180}-\u{024F}])|(\d))/gu,
  // Xhosa(South Africa) - Basic Latin, Latin-1 Supplement, Latin Extended-A, Latin Extended-B
  'xh-ZA': /(([\u{0020}-\u{007F}\u{00A0}-\u{00FF}\u{0100}-\u{017F}\u{0180}-\u{024F}])|(\d))/gu,
};

export const RTL_LANGUAGE_CODES = ['ps-PK', 'pa-PK', 'ku-Arab-IQ', 'prs-AF', 'ur-PK', 'kmr-ar'];

export const NEW_LINE_REGEX = /\n/g; // /(\n)$/s;

export const SPOKEN_LANGUAGE_LEVEL = {
  NATIVE: 'NATIVE',
  ADVANCED: 'ADVANCED',
  INTERMEDIATE: 'INTERMEDIATE',
  ELEMENTARY: 'ELEMENTARY',
};

export const SPOKEN_LANGUAGE_LEVEL_ARRAY = [
  { name: 'Native', value: 'NATIVE', level: 4 },
  { name: 'Advanced', value: 'ADVANCED', level: 3 },
  { name: 'Intermediate', value: 'INTERMEDIATE', level: 2 },
  { name: 'Elementary', value: 'ELEMENTARY', level: 1 },
];

export default class Language {
  /**
   * Returns the name of the language object
   * @param language
   * @returns {string|null}
   */
  static getName(language = null) {
    return Utils.define(language?.name);
  }

  /**
   * Returns the code of the language object in upper case.
   * @param {*} language
   * @returns {string|null}
   */
  static getCode(language = null) {
    return Utils.define(language?.code?.toUpperCase());
  }

  static getRtlCode(language = null) {
    const langCode = this.getCode(language);
    const upperCaseRtlCodes = RTL_LANGUAGE_CODES.map((code) => code.toUpperCase());
    return upperCaseRtlCodes.includes(langCode);
  }

  static getSpokenLanguageLevelCode(level = null) {
    switch (level) {
      case SPOKEN_LANGUAGE_LEVEL.NATIVE:
        return 4;
      case SPOKEN_LANGUAGE_LEVEL.ADVANCED:
        return 3;
      case SPOKEN_LANGUAGE_LEVEL.INTERMEDIATE:
        return 2;
      case SPOKEN_LANGUAGE_LEVEL.ELEMENTARY:
        return 1;
      default:
        return 0;
    }
  }

  /**
   * Returns the name and code of the language object in format "{name} ({code})"
   * @param {*} language
   * @returns {null|string}
   */
  static getNameAndCode(language = null) {
    let str = Language.getName(language) + ' (' + Language.getCode(language) + ')';
    return str === 'null (null)' ? null : str;
  }
}
