import React from 'react';
import { SPOKEN_LANGUAGE_LEVEL } from '../../../models/Language';
import Utils from '../../../utils/Utils';

export const PersonalProfileView = ({
  isBusy = false,
  profile = null,
  isEditable = false,
  onEditButtonClick = null,
}) => {
  const spokenLanguagesToText = (languages) =>
    languages
      ?.map((obj) => obj?.name ?? null)
      ?.filter((obj) => !!obj)
      ?.join(', ') ?? '';
  const nativeLanguages = spokenLanguagesToText(
    profile?.spokenLanguages?.filter((obj) => obj?.level === SPOKEN_LANGUAGE_LEVEL.NATIVE)
  );
  const advancedLanguages = spokenLanguagesToText(
    profile?.spokenLanguages?.filter((obj) => obj?.level === SPOKEN_LANGUAGE_LEVEL.ADVANCED)
  );
  const intermediateLanguages = spokenLanguagesToText(
    profile?.spokenLanguages?.filter((obj) => obj?.level === SPOKEN_LANGUAGE_LEVEL.INTERMEDIATE)
  );
  const elementaryLanguages = spokenLanguagesToText(
    profile?.spokenLanguages?.filter((obj) => obj?.level === SPOKEN_LANGUAGE_LEVEL.ELEMENTARY)
  );
  const provenanceFormatter = () => {
    let prov = '';
    
    if (!profile?.provenance) {
      prov = 'N/A';
    } else if (profile?.provenance === 'UNKNOWN') {
      prov = 'Unknown';
    } else {
      prov = profile?.provenance;
    }

    return prov;
  };

  return (
    <div className={`personal-profile-view' ${isBusy && 'whirl sphere'}`}>
      {!isEditable ? null : (
        <div className='box-action box-icon'>
          <span className='edit-button' onClick={onEditButtonClick}></span>
        </div>
      )}
      <div style={{ marginBottom: '12px' }}>
        <span
          style={{
            display: 'block',
            fontSize: '18px',
            lineHeight: '28px',
            letterSpacing: '0.3px',
            color: '#77777e',
            marginBottom: '4px',
          }}
        >
          Email
        </span>
        <span
          style={{
            display: 'block',
            fontSize: '18px',
            lineHeight: '28px',
            letterSpacing: '0.3px',
            color: '#1c1c28',
          }}
        >
          {profile?.email ?? 'N/A'}
        </span>
      </div>
      <div style={{ marginBottom: '12px' }}>
        <span
          style={{
            display: 'block',
            fontSize: '18px',
            lineHeight: '28px',
            letterSpacing: '0.3px',
            color: '#77777e',
            marginBottom: '4px',
          }}
        >
          Country of residence
        </span>
        <span
          style={{
            display: 'block',
            fontSize: '18px',
            lineHeight: '28px',
            letterSpacing: '0.3px',
            color: '#1c1c28',
          }}
        >
          {profile?.country ?? 'N/A'}
        </span>
      </div>
      <div style={{ marginBottom: '24px' }}>
        <span
          style={{
            display: 'block',
            fontSize: '18px',
            lineHeight: '28px',
            letterSpacing: '0.3px',
            color: '#77777e',
            marginBottom: '4px',
          }}
        >
          Provenance
        </span>
        <span
          style={{
            display: 'block',
            fontSize: '18px',
            lineHeight: '28px',
            letterSpacing: '0.3px',
            color: '#1c1c28',
          }}
        >
          {provenanceFormatter()}
        </span>
      </div>
      <div style={{ overflow: 'hidden' }}>
        <span
          style={{
            display: 'block',
            fontSize: '18px',
            lineHeight: '28px',
            letterSpacing: '0.3px',
            fontWeight: '500',
            color: '#1c1c28',
            marginBottom: '12px',
          }}
        >
          Languages
        </span>

        <div className='row col-xs-12' style={{ overflow: 'hidden', marginBottom: '10px' }}>
          <div className='col-xs-12 col-md-5 float-left'>
            <span
              style={{
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#77777e',
              }}
            >
              Native or bilingual:
            </span>
          </div>
          <div className='col-xs-12 col-md-7 float-left'>
            <span
              style={{
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#1c1c28',
              }}
            >
              {nativeLanguages ?? 'N/A'}
            </span>
          </div>
        </div>
        {advancedLanguages ? (
          <div className='row col-xs-12' style={{ overflow: 'hidden', marginBottom: '10px' }}>
            <div className='col-xs-12 col-md-5 float-left'>
              <span
                style={{
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#77777e',
                }}
              >
                Advanced:
              </span>
            </div>
            <div className='col-xs-12 col-md-7 float-left'>
              <span
                style={{
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#1c1c28',
                }}
              >
                {advancedLanguages}
              </span>
            </div>
          </div>
        ) : null}
        {intermediateLanguages ? (
          <div className='row col-xs-12' style={{ overflow: 'hidden', marginBottom: '10px' }}>
            <div className='col-xs-12 col-md-5 float-left'>
              <span
                style={{
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#77777e',
                }}
              >
                Intermediate:
              </span>
            </div>
            <div className='col-xs-12 col-md-7 float-left'>
              <span
                style={{
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#1c1c28',
                }}
              >
                {intermediateLanguages}
              </span>
            </div>
          </div>
        ) : null}
        {elementaryLanguages ? (
          <div className='row col-xs-12' style={{ overflow: 'hidden', marginBottom: '10px' }}>
            <div className='col-xs-12 col-md-5 float-left'>
              <span
                style={{
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#77777e',
                }}
              >
                Elementary:
              </span>
            </div>
            <div className='col-xs-12 col-md-7 float-left'>
              <span
                style={{
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#1c1c28',
                }}
              >
                {elementaryLanguages}
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PersonalProfileView;
