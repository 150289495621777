import React from 'react';
import PropTypes from 'prop-types';

import Utils from '../../utils/Utils';
import DOMObject from '../DOMObject';

export default class Parameter extends DOMObject {
  static defaultProps = {
    onChange: null,
  };

  render() {
    return <div hidden></div>;
  }

  clear() {
    return false;
  }

  getValue() {
    return null;
  }

  setValue(value) {
    return false;
  }

  getStringValue() {
    return '';
  }

  setStringValue(value) {
    return false;
  }

  /**
   * Calls all the 'onChange' listeners for the parameter object. It passes itself as the first parameter.
   */
  valueChanged() {
    try {
      if (Utils.isNull(this.props.onChange)) return;
      if (!Array.isArray(this.props.onChange))
        Promise.resolve(this).then((obj) => {
          obj.props.onChange(this, this?.getValue());
        });
      else
        this.props.onChange.forEach((func) => {
          Promise.resolve(this)
            .then((obj) => {
              func(obj, this?.getValue());
            })
            .catch((e) => {});
        });
    } catch (e) {
      this.errorHandler(e);
    }
  }
}

Parameter.propTypes = {
  onChange: PropTypes.func,
};
