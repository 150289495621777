import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { MultiSelect } from '../../../components/parameters/MultiSelectParameter';
import { Select } from '../../../components/parameters/SelectParameter';
import { Text } from '../../../components/parameters/TextParameter';
import CheckboxWithValidation from '../../../components/forms/CheckboxWithValidation';

const UserFilters = ({
  filters,
  resetFilters,
  handleChange,
  basicDropdownOpen,
  onBasicDropdownToggle,
  testsDropdownOpen,
  onTestDropdownOpen,
  demographicsDropdownOpen,
  onDemographicsDropdownToggle,
  hasBasicFilters,
  hasTestFilters,
  hasDemographicsFilters,
  isLoadingOptions,
  languageOptions,
  languageLevelOptions,
  userEthnicGroupOptions,
  userGenderOptions,
  userDialectOptions,
  geolocationOptions,
  yearOfBirthOptions,
  testOptions,
  evaluationScoreOptions,
}) => {
  return (
    <div className='tw-flex tw-mb-4'>
      <Dropdown isOpen={basicDropdownOpen} toggle={onBasicDropdownToggle} className='tw-mr-2'>
        <DropdownToggle caret color={hasBasicFilters ? 'primary' : 'secondary'}>
          Basic
        </DropdownToggle>
        <DropdownMenu style={{ minWidth: 360 }}>
          <DropdownItem text>
            <div className='row px-0'>
              <Text
                defaultValue={filters?.username ?? null}
                placeholder='Username'
                onChange={(value) => handleChange('username', value)}
              />
            </div>
          </DropdownItem>
          <DropdownItem text>
            <div className='row px-0'>
              <Text
                defaultValue={filters?.fullName ?? null}
                placeholder='Full name'
                onChange={(value) => handleChange('fullName', value)}
              />
            </div>
          </DropdownItem>
          <DropdownItem text>
            <div className='row px-0'>
              <Text
                defaultValue={filters?.country ?? null}
                placeholder='Country'
                onChange={(value) => handleChange('country', value)}
              />
            </div>
          </DropdownItem>
          <DropdownItem text>
            <div className='row px-0'>
              <MultiSelect
                options={languageOptions}
                defaultValue={filters?.languageIds ?? []}
                onChange={(value) => handleChange('languageIds', value)}
                typeOptions={{ placeholder: 'Spoken languages', isLoading: isLoadingOptions }}
                style={{ width: '100%' }}
              />
            </div>
          </DropdownItem>
          <DropdownItem text>
            <div className='row px-0'>
              <MultiSelect
                options={languageLevelOptions}
                defaultValue={filters?.languageLevelIds ?? []}
                onChange={(value) => handleChange('languageLevelIds', value)}
                typeOptions={{ placeholder: 'Spoken language level', isLoading: isLoadingOptions }}
                style={{ width: '100%' }}
              />
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Dropdown isOpen={testsDropdownOpen} toggle={onTestDropdownOpen} className='tw-mr-2'>
        <DropdownToggle caret color={hasTestFilters ? 'primary' : 'secondary'}>
          Tests
        </DropdownToggle>
        <DropdownMenu style={{ minWidth: 360 }}>
          <DropdownItem text>
            <div className='row px-0'>
              <MultiSelect
                options={testOptions}
                defaultValue={filters?.projectIds ?? []}
                onChange={(value) => handleChange('projectIds', value)}
                typeOptions={{ placeholder: 'Tests' }}
                style={{ width: '100%' }}
              />
            </div>
          </DropdownItem>
          <DropdownItem text>
            <div className='row px-0'>
              <Select
                options={evaluationScoreOptions}
                defaultValue={filters?.evaluationScore ?? ''}
                onChange={(value) => handleChange('evaluationScore', value)}
                typeOptions={{ placeholder: 'Evaluation score' }}
                style={{ width: '100%' }}
              />
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Dropdown isOpen={demographicsDropdownOpen} toggle={onDemographicsDropdownToggle}>
        <DropdownToggle caret color={hasDemographicsFilters ? 'primary' : 'secondary'}>
          Demographics
        </DropdownToggle>
        <DropdownMenu style={{ minWidth: 360 }}>
          <DropdownItem text>
            <div className='row px-0'>
              <MultiSelect
                options={userEthnicGroupOptions}
                defaultValue={filters?.ethnicGroupIds ?? []}
                onChange={(value) => handleChange('ethnicGroupIds', value)}
                typeOptions={{ placeholder: 'Origins / Ethnic group / Race / Ancestry' }}
                style={{ width: '100%' }}
              />
            </div>
          </DropdownItem>
          <DropdownItem text>
            <div className='row px-0'>
              <MultiSelect
                options={userGenderOptions}
                defaultValue={filters?.genderIds ?? []}
                onChange={(value) => handleChange('genderIds', value)}
                typeOptions={{ placeholder: 'Gender' }}
                style={{ width: '100%' }}
              />
            </div>
          </DropdownItem>
          <DropdownItem text>
            <div className='row px-0'>
              <MultiSelect
                options={userDialectOptions}
                defaultValue={filters?.dialectIds ?? []}
                onChange={(value) => handleChange('dialectIds', value)}
                typeOptions={{ placeholder: 'Dialect' }}
                style={{ width: '100%' }}
              />
            </div>
          </DropdownItem>
          <DropdownItem text>
            <div className='row px-0'>
              <MultiSelect
                options={geolocationOptions}
                defaultValue={filters?.geolocations ?? []}
                onChange={(value) => handleChange('geolocations', value)}
                typeOptions={{ placeholder: 'Geolocation' }}
                style={{ width: '100%' }}
              />
            </div>
          </DropdownItem>
          <DropdownItem text>
            <div className='row'>
              <div className='col-6 px-0'>
                Year of birth (from):
                <Select
                  options={yearOfBirthOptions}
                  defaultValue={filters?.yearOfBirthFrom ?? ''}
                  onChange={(value) => handleChange('yearOfBirthFrom', value[0])}
                  typeOptions={{ placeholder: 'YYYY' }}
                  style={{ width: '100%' }}
                />
              </div>
              <div className='col-6 px-0'>
                Year of birth (to):
                <Select
                  options={yearOfBirthOptions}
                  defaultValue={filters?.yearOfBirthTo ?? ''}
                  onChange={(value) => handleChange('yearOfBirthTo', value[0])}
                  typeOptions={{ placeholder: 'YYYY' }}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <div className='ml-2 mt-2'>
        <CheckboxWithValidation
          propertyName='ungroupedOnly'
          defaultValue={!!filters.ungroupedOnly}
          onChange={(event, value) => {
            handleChange('ungroupedOnly', value);
          }}
          onBlur={() => {}}
          label='Ungrouped Only'
        />
      </div>
      {Object.keys(filters).length > 0 && (
        <button
          id='clearFilters'
          className='btn btn-secondary border-0 p-0 ml-3'
          onClick={resetFilters}
        >
          <em className='far fa-trash-alt' style={{ fontSize: '17px' }} />
        </button>
      )}
    </div>
  );
};

export default UserFilters;
