import React, { useState } from 'react';
import {
  Button,
  ButtonDropdown,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { ProgressBar } from 'react-bootstrap';
import TextWithIcon from '../components/TextWithIcon';
import Tooltip from '../../../components/Tooltip';
import { Bar } from 'react-chartjs-2';
import Utils from '../../../utils/Utils';
import TASK from '../../../models/Task';

export const ProjectActions = {
  GENERATE_TASKS: { id: 1, name: 'Generate Tasks' },
  RECALCULATE_CREDITS: { id: 2, name: 'Recalculate Credits' },
};

const TaskStatusBarData = (project) => {
  return {
    labels: [
      'Inactive',
      'Active',
      'Pending',
      'Pending Check',
      'Pending Review',
      'Submitted',
      'Reviewed',
      'Completed',
    ],
    datasets: [
      {
        data: [
          project?.tasksInactiveNum ?? 0,
          project?.tasksActiveNum ?? 0,
          project?.tasksPendingNum ?? 0,
          project?.tasksPendingCheckNum ?? 0,
          project?.tasksPendingReviewNum ?? 0,
          project?.tasksSubmittedNum ?? 0,
          project?.tasksReviewedNum ?? 0,
          project?.tasksCompletedNum ?? 0,
        ],
        backgroundColor: Utils.statusColors,
        minBarLength: 1,
        minValue: 0,
      },
    ],
  };
};

const TaskStatusBarOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export const ProjectActionsView = ({
  project = null,
  projectFiles = null,
  onActionClick = null,
  isBusy = false,
  onDownload = null,
  progressState = 0,
  progressMessage = '',
}) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [dropDownSelection, setDropDownSelection] = useState(ProjectActions.GENERATE_TASKS.name);
  const [statusFilter, setStatusFilter] = useState('-100,0,100');

  const taskTypeTemplate =
    Object.values(TASK.TYPE)
      ?.filter((type) => type.sid === project.settings?.primaryStageTaskTypeSid)
      ?.pop()?.template ?? null;
  const hasNoFiles = !projectFiles || projectFiles.length === 0;
  const hasInvalidFiles =
    !taskTypeTemplate ||
    (taskTypeTemplate.requiresTokenizedFiles &&
      projectFiles.some((f) => f.tokenizationStatus !== 'COMPLETED'));

  const toggleDropdown = () => {
    setDropDownOpen(!dropDownOpen);
  };
  const onDropDownMenuSelectionChange = (value) => {
    setDropDownSelection(value);
  };
  const onButtonClick = () => {
    if (onActionClick) {
      const selectionId =
        Object.values(ProjectActions).find((obj) => obj.name === dropDownSelection)?.id ?? null;
      onActionClick(selectionId);
    }
  };

  const onDownloadClick = () => {
    if (onDownload) {
      onDownload(statusFilter);
    }
  };

  const onStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const chartRef = React.createRef();

  return (
    <>
      <Tooltip title='When generating tasks, credits will also get calculated. The project needs a valid setting for translation credits in order to initialize. The second option just re-calculates the credits for all tasks (translation and review).'>
        <TextWithIcon
          text={'Initialize'}
          icon={'fas fa-play'}
          outerClassName={'mb-3'}
          innerClassName={'text-center'}
          size={3}
          cursor='help'
        />
      </Tooltip>
      <div className='row'>
        <div className='col-md-3'>
          <ButtonDropdown className={'btn-block'} isOpen={dropDownOpen} toggle={toggleDropdown}>
            <Button
              className={'btn-block'}
              size='lg'
              color='primary'
              onClick={onButtonClick}
              disabled={isBusy || hasNoFiles || hasInvalidFiles}
            >
              {dropDownSelection}
            </Button>
            <DropdownToggle caret color='primary' disabled={isBusy} />
            <DropdownMenu>
              <DropdownItem header>Actions</DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => onDropDownMenuSelectionChange(ProjectActions.GENERATE_TASKS.name)}
              >
                {ProjectActions.GENERATE_TASKS.name}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() =>
                  onDropDownMenuSelectionChange(ProjectActions.RECALCULATE_CREDITS.name)
                }
              >
                {ProjectActions.RECALCULATE_CREDITS.name}
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>
        <div className='col-md-9'>
          <ProgressBar
            animated={true}
            striped={true}
            now={progressState ?? 0}
            label={`${progressState}%`}
            style={{ height: '42px' }}
          />
          <div className='text-center pt-2'>{progressMessage}</div>
        </div>
      </div>
      {hasNoFiles && (
        <div className='alert alert-warning mt-2' role='alert'>
          You have not associated any files
        </div>
      )}
      {hasInvalidFiles && (
        <div className='alert alert-warning mt-2' role='alert'>
          Could not find applicable files for {taskTypeTemplate.name} (maybe tokenization is not
          ready yet)
        </div>
      )}
      <Tooltip title='Task statuses regardless of type (translation/review). This is just for informative reasons when generating tasks.'>
        <TextWithIcon
          text={'Tasks'}
          icon={'fas fa-tasks'}
          outerClassName={'mt-5'}
          innerClassName={'text-center'}
          size={3}
          cursor='help'
        />
      </Tooltip>
      <p className='text-center text-muted  mb-3'>{'Total #' + (project?.tasksNum ?? 'N/A')}</p>
      <div style={{ height: '250px' }}>
        <Bar ref={chartRef} data={TaskStatusBarData(project)} options={TaskStatusBarOptions} />
      </div>
      <Tooltip title="Download the project's segments">
        <TextWithIcon
          text={'Data Export'}
          icon={'fas fa-download'}
          outerClassName={'mt-5'}
          innerClassName={'text-center'}
          size={3}
          cursor='help'
        />
      </Tooltip>
      <div className='row'>
        <div className='col-md-3'>
          <Button block color='success' onClick={onDownloadClick} disabled={isBusy}>
            Download
          </Button>
        </div>
        <div className='col-md-9'>
          <div onChange={onStatusFilterChange}>
            <CustomInput
              type='radio'
              id='rbAll'
              name='statusFilter'
              label='All'
              value='-100,0,100'
              defaultChecked
              inline
            />
            <CustomInput
              type='radio'
              id='rbRejected'
              name='statusFilter'
              label='Rejected'
              value='-100'
              inline
            />
            <CustomInput
              type='radio'
              id='rbPendingReview'
              name='statusFilter'
              label='Pending Review'
              value='0'
              inline
            />
            <CustomInput
              type='radio'
              id='rbApproved'
              name='statusFilter'
              label='Approved'
              value='100'
              inline
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectActionsView;
