import React from 'react';
import { number, object, string, boolean } from 'yup';
import { Formik } from 'formik';
import { Button, Col, Form, Row } from 'react-bootstrap';

import SelectWithValidation from '../../../components/forms/SelectWithValidation';
import CheckboxWithValidation from '../../../components/forms/CheckboxWithValidation';
import Spinner from '../../../components/Spinner';

import { getLastNYears } from '../../../utils/DateHelper';
import { findOption } from '../../../utils/Select';

const DataCollectionFormOrange = ({ handleUpdate, loading, isSubmitted, user, isUserEditPage = false }) => {
  const initialValues = {
    consent_1: isUserEditPage ? true : !!user?.extra?.legal?.consent_1,
    birth_year: user?.extra?.birth_year ?? '',
    gender: user?.extra?.gender ?? '',
  };

  const validationRules = {
    consent_1: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
    gender: string().nullable().required('Gender is required'),
    birth_year: number()
      .nullable()
      .required('Year of birth is required')
      .min(1900, 'Please enter a valid year of birth')
      .max(2050, 'Please enter a valid year of birth'),
  };

  const validationSchema = object().shape(validationRules);

  const handleSubmit = (values) => {
    let userMetadata = {
      gender: values.gender,
      ethnicGroup: 'none',
      yearOfBirth: values.birth_year,
      spokenDialect: 'none',
      extra: { ...values },
    };

    handleUpdate(userMetadata);
  };

  const genderOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ];

  const yearOfBirthOptions = getLastNYears(100, 4).map((year) => ({
    value: year,
    label: year.toString(),
  }));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(props) => {
        if (loading) {
          return <Spinner />;
        }
        return (
          <Form onSubmit={props.handleSubmit}>
            <Row className={isUserEditPage ? 'tw-hidden' : ''}>
              <Col sm={12} md={12}>
                <CheckboxWithValidation
                  id='consent_1'
                  propertyName='consent_1'
                  label={
                    <>
                      Please confirm that your child is 4 to 8 years old and you are the holder of
                      parental responsibility over the child that would perform this task subject to
                      Article 8 of the GDPR{' '}
                      <a
                        href='https://gdpr-text.com/en/read/article-8/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        https://gdpr-text.com/en/read/article-8/
                      </a>
                      .
                    </>
                  }
                  defaultValue={props.values.consent_1}
                  touched={props.touched.consent_1}
                  error={props.errors.consent_1}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  disabled={isUserEditPage ? true : isSubmitted}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <SelectWithValidation
                  id='birth_year'
                  propertyName='birth_year'
                  label='Enter the year of birth of the child.'
                  placeholder='YYYY'
                  helpText='Enter the year of birth of the child.'
                  options={yearOfBirthOptions}
                  defaultOption={findOption(yearOfBirthOptions, props.values.birth_year)}
                  defaultValue={props.values.birth_year}
                  touched={props.touched.birth_year}
                  error={props.errors.birth_year}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  isDisabled={isUserEditPage ? false : isSubmitted}
                />
              </Col>
              <Col sm={12} md={6}>
                <SelectWithValidation
                  id='gender'
                  propertyName='gender'
                  label={`Which of these best describes the child's gender?`}
                  helpText={`Which of these best describes the child's gender?`}
                  options={genderOptions}
                  defaultOption={findOption(genderOptions, props.values.gender)}
                  touched={props.touched.gender}
                  error={props.errors.gender}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  isDisabled={isUserEditPage ? false : isSubmitted}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12}>
                <div>
                  <Button className='floatRight' type='submit' disabled={isUserEditPage ? false : isSubmitted}>
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DataCollectionFormOrange;
