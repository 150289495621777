// External dependencies
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Internal Dependencies
import Utils from '../../../utils/Utils';
import DOMObject from '../../../components/DOMObject';
import ErrorBoundary from '../../../components/ErrorBoundary';
import ApiException from '../../../models/ApiException';
import Notifier from '../../../components/Notifier';
import Spinner from '../../../components/Spinner';
import TASK from '../../../models/Task';
import TASK_RESULT from '../../../models/TaskResult';
import SEGMENT from '../../../models/Segment';
import Segment from '../../../models/Segment';
import { TargetSegment, TargetSegmentTranslationFooter } from './SegmentView';
import TaskResultController from '../../../controllers/TaskResultController';
import TaskToolbar from './TaskToolbar';
import SourceSegment from './SourceSegment';

class TaskTranslationView extends DOMObject {
  constructor(props) {
    super(props);
    try {
      const { task, scope } = props;

      if (
        TASK_RESULT.USER_SCOPE.WORKER === scope &&
        task?.type?.policy === TASK.POLICY.EDIT &&
        task?.status === TASK.STATUS.PENDING
      ) {
        task.targets.forEach((target) => {
          if (!!target?.content?.data && (target?.content?.task?.id ?? -1) !== (task?.id ?? 0)) {
            this.state.targetUncommitted[target.id] = target.content?.data;
          }
        });
      }
    } catch (e) {
      this.errorHandler(e);
    }
  }

  static propTypes = {
    task: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    scope: PropTypes.string.isRequired,
    taskIsUpdated: PropTypes.func,
  };

  static defaultProps = {
    task: null,
    user: null,
    scope: TASK_RESULT.USER_SCOPE.WORKER,
    taskIsUpdated: null,
  };

  state = {
    inValidSegments: {},
    targetUncommitted: {},
    targetIsBusy: [],
  };

  // --------------------------------------------------------------------------------------
  // ---------------------------------- ReactJS Methods -----------------------------------
  // --------------------------------------------------------------------------------------

  render() {
    try {
      let thisObject = this;
      let segmentsCounter = 0;
      let totalSegments = this.props?.task?.targets?.length;
      if (Utils.isNull(this.props?.task)) return <Spinner />;

      let targets = this.props?.task?.targets?.map((obj) => {
        segmentsCounter++;
        return (
          <div id={obj.id} key={obj.id} className='tw-flex tw-flex-row'>
            <div>
              <span className='tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-border tw-border-solid tw-border-gray-200 tw-rounded tw-w-8 tw-h-7'>
                {segmentsCounter}/{totalSegments}
              </span>
            </div>
            <div className='tw-w-full tw-pt-1'>
              <thisObject.Segment key={obj?.id} segment={obj} />
            </div>
          </div>
        );
      });

      return (
        <>
          <TaskToolbar
            task={this.props?.task}
            scope={this.props?.scope}
            invalidSegments={this.state.inValidSegments}
            unsavedTargets={this.state.targetUncommitted}
            onSubmitButtonClicked={this.submitButtonClicked.bind(this)}
            onSaveButtonClicked={this.saveButtonClicked.bind(this)}
          />
          <div className='card' style={{ boxShadow: 'none' }}>
            <div className='card-body p-0'>
              <ErrorBoundary errorMessage='Segments render failed'>{targets}</ErrorBoundary>
            </div>
          </div>
          <TaskToolbar
            task={this.props?.task}
            scope={this.props?.scope}
            invalidSegments={this.state.inValidSegments}
            unsavedTargets={this.state.targetUncommitted}
            onSubmitButtonClicked={this.submitButtonClicked.bind(this)}
            onSaveButtonClicked={this.saveButtonClicked.bind(this)}
          />
        </>
      );
    } catch (e) {
      this.errorHandler(e, false);
      return <ErrorBoundary hasError={true} errorMessage={'Internal application error'} />;
    }
  }

  // --------------------------------------------------------------------------------------
  // ---------------------------------- Components ----------------------------------------
  // --------------------------------------------------------------------------------------
  /** Segment translation component */
  Segment = ({ segment }) => {
    try {
      // Checks if the target segment content is updated
      let key = segment?.id + '_' + segment?.content?.timestampUpdated;
      const isValid = Utils.defineBoolean(
        this.state?.inValidSegments?.[segment?.id]?.targetIsValid,
        true
      );
      const errorMessage = Utils.define(
        this.state?.inValidSegments?.[segment?.id]?.targetErrorMessage
      );
      const isChanged = !Utils.isNull(this.state.targetUncommitted?.[segment?.id]);
      const isEditable = TASK_RESULT.getUserPermission(this.props?.task).includes(
        TASK_RESULT.PERMISSIONS.DATA_EDIT
      );
      const isBusy = Utils.defineBoolean(this.state?.targetIsBusy?.includes(segment?.id), false);
      const isUpdated =
        (segment?.content?.owner?.id ?? -1) === (this.props?.user?.id ?? -2) ?? false;

      return (
        <ErrorBoundary errorMessage='Segment failed to render'>
          <div
            key={key}
            className='d-flex flex-wrap justify-content-between align-items-start mb-5 pb-5 border-bottom'
          >
            <SourceSegment
              taks={this.props?.task}
              segment={segment}
              className='taus-source-segment-single pb-3 pr-sm-3 col-12-xs col-sm-6'
            />
            <div className='col-12-xs col-sm-6'>
              <TargetSegment
                className='taus-target-segment'
                segment={segment}
                isBusy={isBusy ?? false}
                readOnly={!(isEditable ?? false)}
                isUpdated={isUpdated}
                onChange={this.onTargetSegmentChange.bind(this, segment?.id)}
              />
              <TargetSegmentTranslationFooter
                isValid={isValid}
                errorMessage={errorMessage}
                isChanged={isChanged}
                onSaveButtonClick={this.targetSegmentSaveClicked.bind(this, segment?.id)}
              />
            </div>
          </div>
        </ErrorBoundary>
      );
    } catch (e) {
      this.errorHandler(e, false);
      return <ErrorBoundary hasError={true} errorMessage='Segment failed to render' />;
    }
  };

  // -------------------------------------------------------------------
  // ------------------------------ Listeners --------------------------
  // -------------------------------------------------------------------
  async onTargetSegmentChange(id, event) {
    let data = event?.target?.value ?? null;
    let target = this?.props?.task?.targets?.filter((obj) => obj.id === id)?.pop() ?? null;
    if (
      data === (SEGMENT.getContentData(target) ?? null) &&
      (this.props?.task?.id ?? 0) === (SEGMENT.getContentTaskId(target) ?? -1)
    ) {
      delete this.state.targetUncommitted[id];
      delete this.state.inValidSegments[id];
    } else {
      this.state.targetUncommitted[id] = data;
      this.validateData(data, id);
    }
    this.forceUpdate();
  }

  async targetSegmentSaveClicked(id) {
    try {
      if (!id) return;

      let target = this?.props?.task?.targets?.filter((obj) => obj.id === id)?.pop() ?? null;
      let data = this.state?.targetUncommitted?.[id] ?? '';
      if (
        data === (SEGMENT.getContentData(target) ?? null) &&
        (this.props?.task?.id ?? 0) === (SEGMENT.getContentTaskId(target) ?? -1)
      ) {
        // If segments has invalid registration, remove it
        if (!!this.state.inValidSegments[id]) {
          delete this.state.inValidSegments[id];
          this.forceUpdate();
        }
        return;
      }
      data = this.validateData(data, id);
      if (Utils.isNull(data)) return;

      this.setTargetBusy(id, true);
      let segment = { id: id, data: data };
      let task = await this.saveTask([segment]);
      delete this.state.targetUncommitted[id];
      this.triggerTaskSaved(task);
      this.setTargetBusy(id, false);
    } catch (e) {
      this.setTargetBusy(id, false);
      this.errorHandler(e, true);
    }
  }

  async saveButtonClicked() {
    try {
      this.setBusy(true);
      let commitSegments = [];
      let uncommittedTargets = Object.keys(this.state.targetUncommitted).map((obj) =>
        parseInt(obj)
      );

      // Get uncommitted changes
      uncommittedTargets.forEach((id) => {
        let data = this.state.targetUncommitted?.[id] ?? null;
        let target = this?.props?.task?.targets?.filter((obj) => obj.id === id)?.pop() ?? null;
        // If uncommitted data are the same with target content, skip
        if (
          data === (SEGMENT.getContentData(target) ?? null) &&
          (this.props?.task?.id ?? 0) === (SEGMENT.getContentTaskId(target) ?? -1)
        ) {
          delete this.state.targetUncommitted[id];
          return;
        }
        data = this.validateData(data, id);
        if (!data) return;

        commitSegments.push({ id: target.id, data: data });
      });

      // Commit changes
      if (commitSegments.length > 0) {
        let task = await this.saveTask(commitSegments);
        commitSegments.forEach((obj) => {
          delete this.state.targetUncommitted[obj.id];
        });
        this.triggerTaskSaved(task);
      }

      this.setBusy(false);
    } catch (e) {
      this.setBusy(false);
      this.errorHandler(e, true);
    }
  }

  async submitButtonClicked() {
    const isRolledback = this.props?.task?.version > 0;
    try {
      this.setBusy(true);
      let task = await this.submitTask(this.props?.task?.id, isRolledback);
      this.triggerTaskSubmitted(task);
      this.setBusy(false);
    } catch (e) {
      this.errorHandler(e, true);
      this.setBusy(false);
    }
  }

  // --------------------------------------------------------------------------------------
  // ---------------------------------- Methods -------------------------------------------
  // --------------------------------------------------------------------------------------
  /** Makes a segment component view loading */
  setTargetBusy(id, state) {
    if (Utils.isNull(id)) return;
    if (state === true) this.state.targetIsBusy.push(id);
    else this.state.targetIsBusy = this.state.targetIsBusy.filter((obj) => obj !== id);
    this.forceUpdate();
  }

  /** Validates segment content and presents validation errors */
  validateData(data, id) {
    try {
      let inValid = this.state.inValidSegments;
      data = Utils.define(data, '');
      if (!Segment.validateIsEmpty(data)) {
        inValid[id] = {
          targetIsValid: false,
          targetErrorMessage: 'Translation text cannot be empty',
        };
        this.forceUpdate();
        return null;
      }
      if (!Segment.validateNoNewLines(data)) {
        inValid[id] = {
          targetIsValid: false,
          targetErrorMessage: 'Translation text cannot contain new lines',
        };
        this.forceUpdate();
        return null;
      }
      if (
        !Segment.validateEncoded(
          data,
          this.props?.task?.targetLanguage?.code,
          this.props?.task?.options?.targetLanguageRegexCheck?.matchRate
        )
      ) {
        inValid[id] = {
          targetIsValid: false,
          targetErrorMessage:
            'Translation text characters encoding does not match the target language one',
        };
        this.forceUpdate();
        return null;
      }
      // Check the percentage of Zawgyi encoding of the segment. Only for (my-MM) Burmese(Myanmar)
      // if( this.props?.task?.project?.targetLanguage?.code === "my-MM" && Segment.validateBurmeseZawgyiUnicode(data,
      //     this.props?.task?.options?.translation?.targetLanguageZawgyiCheck?.zawgyiMatchRate) ){
      //     inValid[id] = { 'targetIsValid':false, 'targetErrorMessage':"Zawgyi typing has been detected. Please use the International unicode to proceed further" };
      //     this.forceUpdate();
      //     return null;
      // }
      if (!Utils.isNull(inValid[id])) {
        delete inValid[id];
        this.forceUpdate();
      }
      return data;
    } catch (e) {
      return null;
    }
  }

  // --------------------------------------------------- Task status changes
  async triggerTaskSaved(task = null) {
    if (!!task && !!this.props?.taskIsUpdated) this.props.taskIsUpdated(task);
  }

  async triggerTaskSubmitted(task = null) {
    if (!!task && !!this.props?.taskIsUpdated) this.props.taskIsUpdated(task, true);
  }

  // -------------------------------------------------- Http controllers
  async saveTask(data) {
    return TaskResultController.save(this.props?.task?.id, data);
  }

  async submitTask(id, isRolledback) {
    if (isRolledback) {
      return TaskResultController.resubmit(id);
    } else {
      return TaskResultController.submit(id);
    }
  }

  // --------------------------------------------------------------------------
  // ------------------------------ Errors ------------------------------------
  // --------------------------------------------------------------------------
  errorHandler(e, show = true) {
    try {
      if (show) {
        if (e instanceof ApiException) Notifier.error(e?.userMessage ?? e?.message);
        else if (e instanceof Error) Notifier.error(e?.message);
        else Notifier.error(e);
      }
    } finally {
      console.error(e);
    }
  }
}

export default withRouter(TaskTranslationView);
