// External libs
import PropTypes from 'prop-types';
import React from 'react';

// App libs
import Utils from '../../../utils/Utils';
import DOMObject from '../../../components/DOMObject';
import ApiException from '../../../models/ApiException';
import Notifier from '../../../components/Notifier';
import Spinner from '../../../components/Spinner';
import SegmentController from '../../../controllers/SegmentController';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { Select } from '../../../components/parameters/SelectParameter';
import { Label } from '../../../components/parameters/ParameterLabel';
import TASK_RESULT from '../../../models/TaskResult';
import Segment from '../../../models/Segment';
import {
  SourceSegmentSingle,
  TargetSegment as TargetSegmentComponent,
  TargetSegmentReviewFooter,
  TargetSegmentGoogleQEInfo,
} from './SegmentView.js';
import SEGMENT from '../../../models/Segment';
import TaskResultController from '../../../controllers/TaskResultController';
import ReviewerToolbar from './ReviewerToolbar';
import Task from '../../../models/TaskResult';

export default class TaskReviewView extends DOMObject {
  constructor(props) {
    super(props);
    this.state.sourceLanguageId = TASK_RESULT.getSourceLanguage(this.props?.task);
  }

  static propTypes = {
    task: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    scope: PropTypes.string.isRequired,
    sort: PropTypes.string,
    reviewFilter: PropTypes.array,
    taskIsUpdated: PropTypes.func,
  };
  static defaultProps = {
    task: null,
    isAdmin: false,
    scope: TASK_RESULT.USER_SCOPE.WORKER,
    sort: null,
    reviewFilter: null,
    taskIsUpdated: null,
  };

  state = {
    isBusy: false,
    reviewFilter: this.props?.reviewFilter ?? [],
    // The default value for sort is asc when the scope is not worker
    sort: !!this.props?.sort
      ? this.props?.sort
      : this.props.scope === TASK_RESULT.USER_SCOPE.WORKER
      ? null
      : 'asc',
    inValidSegments: {},
    withInfo: false,
    sourceLanguageId: null,
    targetIsBusy: [],
    targetUncommitted: {},
  };

  // --------------------------------------------------------------------------------------
  // ---------------------------------- ReactJS Methods -----------------------------------
  // --------------------------------------------------------------------------------------
  render() {
    try {
      let thisObject = this;
      const task = this.props?.task;
      if (!task) return <Spinner />;

      let targets = Array.from(task.targets);
      targets = this.sortTargetsByGoogleQE(targets, this.state.sort);

      if (this.state.reviewFilter?.length > 0) {
        targets = targets?.filter((target) => {
          let status =
            this.props?.scope === TASK_RESULT.USER_SCOPE.WORKER
              ? Segment.getContentReview(target)
              : target.status;
          return this.state.reviewFilter?.includes(status);
        });
      }

      let segmentsCounter = 0;
      let totalSegments = targets?.length;

      // Make targets UI objects
      targets = targets?.map((obj) => {
        segmentsCounter++;
        return (
          <div id={obj.id} key={obj.id} className='tw-flex tw-flex-row'>
            <div>
              <span className='tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-border tw-border-solid tw-border-gray-200 tw-rounded tw-w-8 tw-h-7'>
                {segmentsCounter}/{totalSegments}
              </span>
            </div>
            <div className='tw-w-full tw-pt-1'>
              <this.Segment key={obj?.id} segment={obj} />
            </div>
          </div>
        );
      });

      return (
        <div>
          <div className='card' style={{ boxShadow: 'none' }}>
            <div className='card-body d-flex flex-wrap justify-content-between align-items-center pb-0'>
              <thisObject.FiltersToolbar />
              <ReviewerToolbar
                task={this.props?.task}
                isAdmin={this.props?.isAdmin}
                scope={this.props?.scope}
                handleSubmit={this.submitTaskClicked.bind(this)}
                handleRollback={this.handleRollback.bind(this)}
                handleComplete={this.checkCompletedClicked.bind(this)}
                handleApproveAll={this.reviewAllTargetsClicked.bind(this, SEGMENT.REVIEWS.APPROVED)}
                handleRejectAll={this.reviewAllTargetsClicked.bind(this, SEGMENT.REVIEWS.REJECTED)}
                enabledHandleAllForWorkers
              />
            </div>
          </div>
          <div
            className={this.state.isBusy === true ? 'card whirl sphere' : 'card'}
            style={{ boxShadow: 'none' }}
          >
            <div className='card-body p-0'>
              <ErrorBoundary errorMessage='Error occur while rendering the task segments'>
                {targets.length !== 0
                  ? targets
                  : 'There is none segment with this status on the Task!'}
              </ErrorBoundary>
            </div>
          </div>
          <ReviewerToolbar
            task={task}
            isAdmin={this.props?.isAdmin}
            scope={this.props?.scope}
            handleSubmit={this.submitTaskClicked.bind(this)}
            handleRollback={this.handleRollback.bind(this)}
            handleComplete={this.checkCompletedClicked.bind(this)}
            handleApproveAll={this.reviewAllTargetsClicked.bind(this, SEGMENT.REVIEWS.APPROVED)}
            handleRejectAll={this.reviewAllTargetsClicked.bind(this, SEGMENT.REVIEWS.REJECTED)}
            enabledHandleAllForWorkers
          />
        </div>
      );
    } catch (e) {
      this.errorHandler(e);
      return <ErrorBoundary hasError={true} errorMessage={'Internal application error'} />;
    }
  }

  // --------------------------------------------------------------------------------------
  // ---------------------------------- Components ----------------------------------------
  // --------------------------------------------------------------------------------------
  // ----------------------------------------- Filters Toolbar
  /** segment review status filter */
  ReviewSortSelect = ({ onChange }) => (
    <ErrorBoundary>
      <Label className='mb-2' label='Filter:'>
        <Select
          className='pl-0'
          style={{ minWidth: '250px' }}
          options={[
            { name: 'Approved', value: SEGMENT.REVIEWS.APPROVED },
            { name: 'Rejected', value: SEGMENT.REVIEWS.REJECTED },
            { name: 'Not Reviewed', value: SEGMENT.REVIEWS.UNREVIEWED },
          ]}
          typeOptions={{ isSearchable: false }}
          onChange={onChange ?? null}
        />
      </Label>
    </ErrorBoundary>
  );

  /** MT QE sort type select */
  MTQESortSelect = ({ onChange }) => (
    <ErrorBoundary>
      <Label className='pr-3 mb-2' label='Sort:'>
        <Select
          className='pl-0'
          style={{ minWidth: '250px' }}
          options={[
            { name: 'MT (Low to High)', value: 'asc' },
            { name: 'MT (High to Low)', value: 'desc' },
            { name: 'No sort', value: null },
          ]}
          defaultValue={[this.state.sort]}
          typeOptions={{
            isSearchable: false,
            isMulti: false,
            isClearable: false,
            styles: { dropdownIndicator: (provided, state) => ({ ...provided }) },
          }}
          onChange={onChange ?? null}
        />
      </Label>
    </ErrorBoundary>
  );

  /** Filters toolbar holding GoogleQE sorting and review status filtering */
  FiltersToolbar = () => {
    try {
      if ((this.props?.scope ?? TASK_RESULT.USER_SCOPE.WORKER) === TASK_RESULT.USER_SCOPE.WORKER)
        return null;

      const thisObject = this;
      const taskHasMachineTranslationQE =
        this.props?.task?.targets?.some(
          (target) => target?.content?.machineTranslationQEResult?.score !== undefined
        ) ?? false;

      return (
        <div className='d-flex flex-wrap justify-content-between align-items-center mb-4'>
          {taskHasMachineTranslationQE ? (
            <thisObject.MTQESortSelect
              onChange={thisObject.sortParameterValueChanged.bind(thisObject)}
            />
          ) : null}
          <thisObject.ReviewSortSelect
            onChange={thisObject.reviewFilterValueChanged.bind(thisObject)}
          />
        </div>
      );
    } catch (e) {
      this.errorHandler(e);
      return null;
    }
  };

  // -------------------------------------------------------- Segments
  /** Source Segment */
  SourceSegment = ({ segment }) => {
    let source = { ...segment.source };
    if (!!this.props?.task?.glossary) source.glossary = this.props.task.glossary;

    return (
      <SourceSegmentSingle
        className='taus-source-segment-single pb-3 pr-sm-3 col-sm-12 col-md-5'
        source={source}
      />
    );
  };

  /** Target Segment */
  TargetSegment = ({
    segment,
    isEditable,
    isBusy,
    isValid,
    errorMessage,
    isChanged,
    review,
    onChange,
    onApprove,
    onReject,
  }) => {
    let thisObject = this;
    let footer =
      isEditable !== true ? null : (
        <thisObject.TargetSegmentFooter
          id={segment?.id}
          isValid={isValid}
          errorMessage={errorMessage}
          isChanged={isChanged}
          review={review}
          onApproveClick={onApprove}
          onRejectClick={onReject}
        />
      );
    let info =
      this.props?.scope === TASK_RESULT.USER_SCOPE.WORKER ? null : (
        <thisObject.TargetSegmentInfo segment={segment} />
      );

    return (
      <TargetSegmentComponent
        className='taus-target-segment col-xm-12 col-sm-8 col-md-5'
        segment={segment}
        isBusy={isBusy ?? false}
        readOnly={!(isEditable ?? false)}
        review={review}
        onChange={onChange ?? null}
        footer={footer}
        info={info}
      />
    );
  };

  /** Target segment footer with approve and reject buttons */
  TargetSegmentFooter = ({
    id = null,
    isValid = true,
    errorMessage = null,
    isChanged = false,
    review = null,
    onApproveClick = null,
    onRejectClick = null,
    className = null,
  }) => (
    <TargetSegmentReviewFooter
      id={id}
      isValid={isValid}
      errorMessage={errorMessage}
      isChanged={isChanged}
      review={review}
      onRejectButtonClick={onRejectClick}
      onApproveButtonClick={onApproveClick}
    />
  );

  /** Target segment info component, shows the GoogleQE */
  TargetSegmentInfo = ({ segment = null }) => (
    <TargetSegmentGoogleQEInfo
      className='col-xs-12 col-sm-4 col-md-2 pl-3'
      editDistance={segment?.content?.machineTranslationQEResult?.score}
    />
  );

  /** Segment translation component */
  Segment = ({ segment }) => {
    try {
      // Checks if the target segment content is updated
      let thisObject = this;
      let key =
        segment?.id +
        '_' +
        segment?.content?.timestampUpdated +
        '_' +
        segment?.content?.review?.timestampUpdated;
      let isBusy = this.state?.targetIsBusy?.includes(segment?.id) ?? false;
      let isValid = Utils.defineBoolean(
        this.state?.inValidSegments?.[segment?.id]?.targetIsValid,
        true
      );
      let errorMessage = this.state?.inValidSegments?.[segment?.id]?.targetErrorMessage ?? null;
      let isChanged = !!this.state?.targetUncommitted?.[segment?.id];
      let isEditable =
        (this.props?.scope === TASK_RESULT.USER_SCOPE.WORKER &&
          TASK_RESULT.getUserPermission(this.props?.task)?.includes(
            TASK_RESULT.PERMISSIONS.DATA_EDIT
          )) ||
        (this.props?.scope !== TASK_RESULT.USER_SCOPE.WORKER &&
          SEGMENT.getUserPermission(segment)?.includes(SEGMENT.PERMISSIONS.EDIT));

      let review = SEGMENT.isReviewed(segment)
        ? segment?.status
        : SEGMENT.getContentReview(segment);

      return (
        <ErrorBoundary errorMessage='Segment failed to render'>
          <div key={key} className='d-flex flex-wrap align-items-start mb-5 pb-5 border-bottom'>
            <thisObject.SourceSegment segment={segment} />
            <thisObject.TargetSegment
              segment={segment}
              isEditable={isEditable}
              isBusy={isBusy}
              isValid={isValid}
              errorMessage={errorMessage}
              isChanged={isChanged}
              review={review}
              onChange={thisObject.onTargetSegmentChange.bind(thisObject, segment?.id)}
              onReject={thisObject.rejectButtonClicked.bind(thisObject, segment?.id)}
              onApprove={thisObject.approveButtonClicked.bind(thisObject, segment?.id)}
            />
          </div>
        </ErrorBoundary>
      );
    } catch (e) {
      this.errorHandler(e);
      return <ErrorBoundary hasError={true} errorMessage='Segment failed to render' />;
    }
  };

  // -------------------------------------------------------------------
  // ------------------------------ Listeners --------------------------
  // -------------------------------------------------------------------
  /** Segments review status filter select listener */
  async reviewFilterValueChanged(value) {
    this.setState({ reviewFilter: value?.[0] ?? null });
  }

  /** GoogleQE sort select listener */
  async sortParameterValueChanged(value) {
    this.setState({ sort: value?.[0] ?? null });
  }

  /** Segment content changes listener */
  async onTargetSegmentChange(id, event) {
    let data = event?.target?.value ?? null;
    let target = this?.props?.task?.targets?.filter((obj) => obj.id === id).pop();
    if (data === (SEGMENT.getContentData(target) ?? '')) {
      delete this.state.targetUncommitted[id];
      delete this.state.inValidSegments[id];
    } else {
      this.state.targetUncommitted[id] = data;
      this.validateData(data, id);
    }
    this.forceUpdate();
  }

  /** Segments Apporve button listener, all scopes */
  async approveButtonClicked(id) {
    return this.props?.scope === TASK_RESULT.USER_SCOPE.WORKER
      ? this.taskDataUpdateClicked(id, SEGMENT.REVIEWS.APPROVED)
      : this.targetSegmentUpdateClicked(id, SEGMENT.REVIEWS.APPROVED);
  }

  /** Segments Reject button listener, all scopes */
  async rejectButtonClicked(id) {
    return this.props?.scope === TASK_RESULT.USER_SCOPE.WORKER
      ? this.taskDataUpdateClicked(id, SEGMENT.REVIEWS.REJECTED)
      : this.targetSegmentUpdateClicked(id, SEGMENT.REVIEWS.REJECTED);
  }

  // --------------------------------------------------------------------------------------
  // ---------------------------------- WORKER Scope --------------------------------------
  // --------------------------------------------------------------------------------------
  /** Approve/Reject button listener on WORKER scope */
  async taskDataUpdateClicked(id, review) {
    try {
      if (!id || !Object.values(Segment.REVIEWS).includes(review)) return;

      let target = this?.props?.task?.targets?.filter((obj) => obj.id === id)?.pop() ?? null;
      let data = this.state?.targetUncommitted?.[id] ?? null;
      if (review === Segment.getContentReview(target) && data === Segment.getContentData(target))
        return;

      this.setTargetBusy(id, true);

      // If review is rejected ignore data.
      if (review !== Segment.getContentReview(target) && review === Segment.REVIEWS.REJECTED) {
        let task = await this.saveTask([{ id: target.id, review: review }]);
        delete this.state?.targetUncommitted?.[id];
        this.triggerTaskDataUpdated(task);
        this.setTargetBusy(id, false);
        return;
      }

      // if review is approved
      let segment = { id: target.id, review: review };
      // If segment content is changed
      if (!!data && data !== SEGMENT.getContentData(target)) {
        data = this.validateData(data, id);
        if (!!data) segment.data = data;
        else {
          this.setTargetBusy(id, false);
          return;
        }
      }
      let task = await this.saveTask([segment]);
      delete this.state?.targetUncommitted?.[id];
      this.triggerTaskDataUpdated(task);
      this.setTargetBusy(id, false);
    } catch (e) {
      this.setTargetBusy(id, false);
      this.errorHandler(e, true);
    }
  }

  /** Submit task button listener */
  async submitTaskClicked() {
    try {
      this.setState({ isBusy: true });
      let task = await this.submitTask();
      this.triggerTaskUpdated(task, true);
    } catch (e) {
      this.errorHandler(e, true);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  /** Rollback task button listener */
  async handleRollback() {
    try {
      this.setState({ isBusy: true });
      let task = await this.rollbackTask();
      this.triggerTaskUpdated(task, true, true);
    } catch (e) {
      this.errorHandler(e, true);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  // --------------------------------------------------------------------------------------
  // ---------------------------------- NON WORKER Scope ----------------------------------
  // --------------------------------------------------------------------------------------
  /** Create Review Task for the task button listener */
  async checkCompletedClicked() {
    try {
      this.setState({ isBusy: true });
      let task = await this.completeCheck();
      this.triggerTaskUpdated(task, true);
    } catch (e) {
      this.errorHandler(e, true);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  /** Update target segment listener on REVIEW scope */
  async targetSegmentUpdateClicked(id = null, review = null) {
    try {
      if (!id || ![SEGMENT.REVIEWS.APPROVED, SEGMENT.REVIEWS.REJECTED].includes(review)) return;

      this.setTargetBusy(id, true);
      let target = this?.props?.task?.targets?.filter((obj) => obj.id === id).pop() ?? null;
      let text = Utils.define(this.state?.targetUncommitted?.[id], SEGMENT.getContentData(target));

      // If the review and the content have not changed, return
      if (review === target?.status && text === SEGMENT.getContentData(target)) {
        delete this.state?.targetUncommitted?.[id];
        this.setTargetBusy(id, false);
        return;
      }
      // If the review or the content has changed, update on server
      let segment = { id: target.id, status: review };
      if (review === SEGMENT.REVIEWS.APPROVED && text !== SEGMENT.getContentData(target))
        segment.data = text;

      segment = await this.saveSegment(segment);
      delete this.state?.targetUncommitted?.[id];
      let newTask = { ...this.props?.task };
      for (let i = 0; i < this.props?.task?.targets?.length; i++) {
        if (this.props?.task?.targets?.[i]?.id === segment.id) newTask.targets[i] = segment;
      }
      this.triggerTaskDataUpdated(newTask);
    } catch (e) {
      this.errorHandler(e, true);
    } finally {
      this.setTargetBusy(id, false);
    }
  }

  /** Approve/Reject All buttons listener */
  async reviewAllTargetsClicked(status = null) {
    try {
      if (status !== SEGMENT.REVIEWS.APPROVED && status !== SEGMENT.REVIEWS.REJECTED) return;

      let save;
      let reviewAttribute;
      if (this.props.scope === Task.USER_SCOPE.WORKER) {
        save = this.saveAsWorker;
        reviewAttribute = 'review';
      } else {
        save = this.saveAsLeadReviewer;
        reviewAttribute = 'status';
      }

      let targets = this.props?.task?.targets
        ?.filter((obj) => obj?.status !== status)
        .map((obj) => ({ id: obj?.id, [reviewAttribute]: status }));
      if (targets?.length === 0) return;

      this.setState({ isBusy: true });
      let segments = await save(this, targets);
      let newTask = { ...this.props?.task };

      if (save === this.saveAsWorker) {
        segments = segments.targets;
      }

      segments.forEach((segment) => {
        for (let i = 0; i < this?.props?.task?.targets?.length; i++) {
          if (this?.props?.task?.targets?.[i]?.id === segment.id) newTask.targets[i] = segment;
        }
      });

      return this.triggerTaskDataUpdated(newTask);
    } catch (e) {
      this.errorHandler(e, true);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  // --------------------------------------------------------------------------
  // ----------------------------- Methods ------------------------------------
  // --------------------------------------------------------------------------
  /** Makes a segment component view loading */
  setTargetBusy(id, state) {
    if (!id) return;
    if (state === true) this.state.targetIsBusy.push(id);
    else this.state.targetIsBusy = this.state.targetIsBusy.filter((obj) => obj !== id);
    this.forceUpdate();
  }

  /** Sort target segments by their GoogleQE edit distance */
  sortTargetsByGoogleQE(targets = null, sort = null) {
    try {
      if (!Array.isArray(targets) || !sort || !['asc', 'desc'].includes(sort.toLowerCase()))
        return targets;
      return targets.sort((a, b) => {
        let aGoogle = a.content?.machineTranslationQEResult?.score ?? 0;
        let bGoogle = b.content?.machineTranslationQEResult?.score ?? 0;
        return sort === 'desc' ? bGoogle - aGoogle : aGoogle - bGoogle;
      });
    } catch (e) {
      this.errorHandler(e);
    }
    return targets;
  }

  /** Apply segment content validation checks and updates UI */
  validateData(data, id) {
    try {
      let inValid = { ...this.state.inValidSegments };
      if (!Segment.validateIsEmpty(data)) {
        inValid[id] = {
          targetIsValid: false,
          targetErrorMessage: 'Translation text cannot be empty',
        };
        this.setState({ inValidSegments: inValid });
        return null;
      }
      if (!Segment.validateNoNewLines(data)) {
        inValid[id] = {
          targetIsValid: false,
          targetErrorMessage: 'Translation text cannot contain new lines',
        };
        this.setState({ inValidSegments: inValid });
        return null;
      }
      if (
        !Segment.validateEncoded(
          data,
          this.props?.task?.project?.targetLanguage?.code,
          this.props?.task?.options?.translation?.targetLanguageRegexCheck?.matchRate
        )
      ) {
        inValid[id] = {
          targetIsValid: false,
          targetErrorMessage:
            'Translation text characters encoding does not match the target language one',
        };
        this.setState({ inValidSegments: inValid });
        return null;
      }
      // Check the percentage of Zawgyi encoding of the segment. Only for (my-MM) Burmese(Myanmar)
      // if( this.props?.task?.project?.targetLanguage?.code === "my-MM" && Segment.validateBurmeseZawgyiUnicode(data,
      //     this.props?.task?.options?.translation?.targetLanguageZawgyiCheck?.zawgyiMatchRate) ){
      //     inValid[id] = { 'targetIsValid':false, 'targetErrorMessage':"Zawgyi typing has been detected. Please use the International unicode to proceed further" };
      //     this.setState({ 'inValidSegments':inValid });
      //     return null;
      // }
      if (!!inValid[id]) {
        delete inValid[id];
        this.setState({ inValidSegments: inValid });
      }
      return data;
    } catch (e) {
      return null;
    }
  }

  // ------------------------------------------- Redux Task Store Triggers
  async triggerTaskUpdated(task = null, finalized = false, isRollback = false) {
    if (!!task && !!this.props?.taskIsUpdated)
      this.props.taskIsUpdated(task, finalized, isRollback);
  }

  async triggerTaskDataUpdated(task = null) {
    if (!!task && !!this.props?.taskIsUpdated) this.props.taskIsUpdated(task);
  }

  // -------------------------------------------- Http Controllers
  async saveSegment(segment) {
    return SegmentController.update(segment);
  }

  async saveAsLeadReviewer(_, segments) {
    return SegmentController.updateAll(segments);
  }

  async saveAsWorker(t, segments) {
    return TaskResultController.save(t.props?.task?.id, segments);
  }

  async completeCheck() {
    return TaskResultController.update(this.props?.task?.id, {
      evaluationStatus: TASK_RESULT.EVALUATION_STATUS.NOT_RISKY,
    });
  }

  async saveTask(data) {
    return TaskResultController.save(this.props?.task?.id, data);
  }

  async submitTask() {
    return TaskResultController.submit(this.props?.task?.id);
  }

  async rollbackTask() {
    return TaskResultController.rollback(this.props?.task?.id);
  }

  // --------------------------------------------------------------------------
  // ------------------------------ Errors ------------------------------------
  // --------------------------------------------------------------------------
  errorHandler(e = null, message = null) {
    try {
      e = ApiException.toApiException(e);
      if (typeof message === 'string') Notifier.error(message);
      else if (message === true) Notifier.error(e.userMessage);
    } finally {
      super.errorHandler(e);
    }
  }
}
