import React, { useEffect, useState } from 'react';
import Notifier from '../../../components/Notifier';
import ProjectRoleNotificationsController from '../../../controllers/ProjectRoleNotificationsController';
import UserController from '../../../controllers/UserController';
import { getUserOptions } from '../../../utils/Select';
import RoleNotificationTestEmail from '../components/RoleNotificationTestEmail';
import ProjectRoleNotificationsFormView from '../forms/ProjectRoleNotificationsFormView';

export const ProjectRoleNotificationsState = ({ project, onProjectUpdate, roleOptions = [] }) => {
  const [selectedRole, setSelectedRole] = useState(null);
  const selectedRoleNotification = project.roleNotifications.find(
    (x) => x.role.id === selectedRole?.value
  );

  const handleSelectedRoleChange = (newValue) => {
    setSelectedRole(roleOptions.find((option) => option.value === newValue));
  };

  const [loading, setLoading] = useState(false);
  const handleSendTestEmail = async (userId) => {
    setLoading(true);
    try {
      await sendTestEmail(project.id, selectedRoleNotification.id, userId);
      Notifier.success('Email sent!');
    } catch (error) {
      Notifier.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      if (selectedRoleNotification) {
        await updateRoleNotification(project.id, selectedRoleNotification.id, data);
        Notifier.success('Role notification updated!');
      } else {
        await createRoleNotification(project.id, data);
        Notifier.success('Role notification created!');
      }
      onProjectUpdate();
    } catch (error) {
      Notifier.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteRoleNotification(project.id, selectedRoleNotification.id);
      Notifier.success('Role notification deleted!');
      setSelectedRole(null);
      onProjectUpdate();
    } catch (error) {
      Notifier.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const [loadingOptions, setLoadingOptions] = useState(true);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    getUsers()
      .then((users) => setUsers(users))
      .finally(() => setLoadingOptions(false));
  }, []);

  return (
    <div className={loading ? 'sphere whirl' : ''}>
      <ProjectRoleNotificationsFormView
        key={selectedRoleNotification?.id ?? 0}
        roleOptions={roleOptions}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        selectedRole={selectedRole}
        onSelectedRoleChange={handleSelectedRoleChange}
        roleNotification={selectedRoleNotification}
      />
      {!!selectedRoleNotification && (
        <RoleNotificationTestEmail
          userOptions={getUserOptions(users, [], 'Users')}
          loadingOptions={loadingOptions}
          sendTestEmail={handleSendTestEmail}
        />
      )}
    </div>
  );
};

export default ProjectRoleNotificationsState;

const getUsers = async () => {
  return UserController.getAll(
    { pageSize: 50000 },
    { 'serialize-permission': false, 'serialize-minimum': true }
  ).then((res) => res?.page ?? []);
};

const sendTestEmail = async (projectId, roleNotificationId, userId) => {
  return await ProjectRoleNotificationsController.test(projectId, roleNotificationId, userId);
};

const createRoleNotification = async (projectId, body) => {
  return await ProjectRoleNotificationsController.create(projectId, body);
};

const updateRoleNotification = async (projectId, roleNotificationId, body) => {
  return await ProjectRoleNotificationsController.update(projectId, roleNotificationId, body);
};

const deleteRoleNotification = async (projectId, roleNotificationId) => {
  return await ProjectRoleNotificationsController.delete(projectId, roleNotificationId);
};
