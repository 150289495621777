import { useEffect, useState } from 'react';
import MetadataController from '../../../controllers/MetadataController';
import ProjectController from '../../../controllers/ProjectController';
import Project from '../../../models/Project';

const useFilterOptions = () => {
  const [loading, setLoading] = useState(true);

  const [options, setOptions] = useState({});

  useEffect(() => {
    const getFilterOptions = async () => {
      const [
        languages,
        languageLevels,
        userEthnicGroups,
        userGenders,
        userDialects,
        geolocations,
        tests,
      ] = await Promise.all([
        MetadataController.getLanguages(),
        MetadataController.getLanguageLevels(),
        MetadataController.getUserEthnicGroups(),
        MetadataController.getUserGenders(),
        MetadataController.getUserDialects(),
        MetadataController.getGeolocations(),
        ProjectController.adminGetAll({
          scope: Project.SCOPE.WORKER_VALIDATION.sid,
          size: 500,
        }),
      ]);

      const combinedEthnicGroupsAndRaces = [
        {
          name: 'USUK Origins',
          options: [],
        },
        {
          name: 'AU Ancestries',
          options: [],
        },
        {
          name: 'UK Ethnic Groups',
          options: [],
        },
        {
          name: 'US Races',
          options: [],
        },
      ];

      setOptions({
        languages: languages.map((item) => ({ name: item.name, value: item.id })),
        languageLevels: languageLevels.map((item) => ({ name: item.name, value: item.id })),
        userEthnicGroups: userEthnicGroups.reduce((group, item) => {
          switch (item.country) {
            case 'USUK':
              group[0].options = [...group[0].options, { label: item.name, value: item.id }];
              return group;
            case 'AU':
              group[1].options = [...group[1].options, { label: item.name, value: item.id }];
              return group;
            case 'UK':
              group[2].options = [...group[2].options, { label: item.name, value: item.id }];
              return group;
            case 'US':
              group[3].options = [...group[3].options, { label: item.name, value: item.id }];
              return group;
            default:
              return group;
          }
        }, combinedEthnicGroupsAndRaces),
        userGenders: userGenders.map((item) => ({ name: item.name, value: item.id })),
        userDialects: userDialects.map((item) => ({ name: item.name, value: item.id })),
        geolocation: geolocations.map((item) => ({
          value: item.code,
          name: item.name,
        })),
        tests: tests.content.map((item) => ({ name: item.name, value: item.id })),
        evaluationScores: [
          { value: '90', name: '>= 90' },
          { value: '80', name: '>= 80' },
          { value: '70', name: '>= 70' },
          { value: '60', name: '>= 60' },
          { value: '50', name: '>= 50' },
          { value: '40', name: '>= 40' },
          { value: '30', name: '>= 30' },
          { value: '20', name: '>= 20' },
          { value: '10', name: '>= 10' },
          { value: '0', name: '>= 0' },
        ],
      });
      setLoading(false);
    };
    getFilterOptions();
  }, []);

  return { loadingOptions: loading, ...options };
};

export default useFilterOptions;
