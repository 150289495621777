import { get, post, put, remove } from '../http';
import { objectToQueryString } from '../utils/Url';

const BASE_URL = '/api/task/result';

const getAll = async (parameters = {}, headers = {}, httpConfProps = null) => {
  return get(`${BASE_URL}?${objectToQueryString(parameters)}`, {
    headers: { ...headers },
    ...httpConfProps,
  });
};

const getTaskResult = async (id) => {
  if (!id) {
    throw new Error('Invalid task id (missing)');
  }
  return get(`${BASE_URL}/${id}`);
};

const getProjectTaskResults = async (id, parameters = {}) => {
  if (!id) {
    throw new Error('Invalid task id (missing)');
  }
  return get(`${BASE_URL}/project/${id}?${objectToQueryString(parameters)}`);
};

const update = async (id, data) => {
  if (!data) {
    throw new Error('Invalid task (missing)');
  }
  if (!id) {
    throw new Error('Invalid task id (missing)');
  }
  return put(`${BASE_URL}/${id}`, data);
};

const release = async (id) => {
  if (!id) {
    throw new Error('Invalid task id (missing)');
  }
  return remove(`${BASE_URL}/${id}/release`);
};

const save = async (id, data) => {
  if (!id) {
    throw new Error('Invalid task id (missing)');
  }
  return put(`${BASE_URL}/${id}/save`, data);
};

const submit = async (id, data) => {
  if (!id) {
    throw new Error('Invalid task id (missing)');
  }
  return put(`${BASE_URL}/${id}/submit`, data);
};

const rollback = async (id, data) => {
  if (!id) {
    throw new Error('Invalid task id (missing)');
  }
  return put(`${BASE_URL}/${id}/rollback`, data);
};

const resubmit = async (id, data) => {
  if (!id) {
    throw new Error('Invalid task id (missing)');
  }
  return put(`${BASE_URL}/${id}/resubmit`, data);
};

const reviewWorker = async (id, data) => {
  if (!id) {
    throw new Error('Invalid task id (missing)');
  }
  return put(`${BASE_URL}/${id}/review/worker`, data);
};

const validateMSI = async (taskId, segmentId) => {
  if (!taskId) {
    throw new Error('Invalid task taskId (missing)');
  }
  if (!segmentId) {
    throw new Error('Invalid segment taskId (missing)');
  }
  return get(`/api/msi/task/result/${taskId}/validate/${segmentId}`);
};

const deliver = async (id) => {
  if (!id) {
    throw new Error('Invalid task id (missing)');
  }
  return put(`${BASE_URL}/${id}/deliver`);
};

const getTargetQE = async (
  taskResultId,
  segmentId,
  data,
  type,
  metadata = {
    length: 0,
    premium: false,
  }
) => {
  return post(`${BASE_URL}/${taskResultId}/target/${segmentId}/qe`, {
    type,
    data,
    ...metadata,
  });
};

const updateMetadata = async (id, data) => {
  if (!id) {
    throw new Error('Invalid task id (missing)');
  }
  return put(`${BASE_URL}/${id}/metadata`, data);
};

const TaskResultController = {
  getAll,
  get: getTaskResult,
  getProjectTaskResults,
  update,
  release,
  save,
  submit,
  rollback,
  resubmit,
  reviewWorker,
  validateMSI,
  getTargetQE,
  deliver,
  updateMetadata
};

export default TaskResultController;
