import React, { Component } from 'react';
import { Getter, Plugin, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { RowDetailState } from '@devexpress/dx-react-grid';
import Utils from '../../utils/Utils';
import { TableRowDetail } from '@devexpress/dx-react-grid-bootstrap4';
import { throttle } from 'throttle-debounce';

// -------------------------------------------------- State Component
class TableResponsiveState extends Component {
  constructor(props) {
    super(props);
    this.state.tableDOMSize = Utils.define(props?.tableDOM?.offsetWidth);
  }

  static defaultProps = {
    tableDom: null,
    tableColumns: null,
    defaultMinWidth: 150,
    onHiddenColumnsChange: null,
  };

  state = {
    tableDOMSize: null,
    tableHiddenColumns: [],
  };

  componentDidMount() {
    window.addEventListener('resize', throttle(500, this.onWindowSizeChange.bind(this)));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', throttle(500, this.onWindowSizeChange.bind(this)));
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    let stateKeys = Object.keys(this.state)
      .filter((key) => this.state[key] !== nextState[key])
      .filter((key) => key !== 'tableHiddenColumns');
    if (stateKeys.length > 0) return true;

    let propsKeys = Object.keys(this.props).filter((key) => this.props[key] !== nextProps[key]);
    if (propsKeys.includes('tableColumns')) {
      // Compare columns by keys, not by objects
      let tableColumns = Utils.defineArray(
        this.props?.tableColumns?.map((obj) => obj.key),
        []
      );
      let nextTableColumns = Utils.defineArray(
        nextProps?.tableColumns?.map((obj) => obj.key),
        []
      );
      if (tableColumns?.length !== nextTableColumns?.length) return true;
      if (!tableColumns?.every((obj) => nextTableColumns?.includes(obj))) return true;
      propsKeys = propsKeys.filter((key) => key !== 'tableColumns');
    }
    return propsKeys.length > 0;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (Utils.isNull(this.props?.onHiddenColumnsChange)) return;
    let hiddenColumnKeys = this.state.tableHiddenColumns.map((obj) => obj.key);
    let prevHiddenColumnKeys = prevState.tableHiddenColumns.map((obj) => obj.key);
    if (
      hiddenColumnKeys.length !== prevHiddenColumnKeys.length ||
      !hiddenColumnKeys.every((obj) => prevHiddenColumnKeys.includes(obj.key))
    ) {
      Promise.resolve(this).then((obj) => this.onHiddenColumnsChange(obj.state.tableHiddenColumns));
    }
  }

  render() {
    this.state.tableHiddenColumns =
      this.calculateHiddenColumns(); /* eslint-disable-line react/no-direct-mutation-state */
    return (
      <Plugin>
        <Getter name='responsiveHiddenColumns' value={this.state.tableHiddenColumns} />
        <RowDetailState />
      </Plugin>
    );
  }

  calculateHiddenColumns() {
    if (Utils.isNull(this.props?.tableDOM))
      return Utils.defineArray(this.state?.tableHiddenColumns, []);
    let tableSize = this.props?.tableDOM.offsetWidth;
    let HtmlColumns = this.props?.tableDOM?.querySelector('thead')?.querySelectorAll('th');
    let columns = Array.from(this.props?.tableColumns);
    let hidden = Array.from(this.state?.tableHiddenColumns);
    let hiddenKeys = hidden.map((obj) => obj?.key);
    let width = 0;

    columns.forEach((obj, i) => {
      if (!Utils.isNull(obj?.column?.minWidth)) obj.minWidth = obj.column.minWidth;
      else obj.minWidth = Utils.define(HtmlColumns?.[i]?.offsetWidth, this.props?.defaultMinWidth);
      if (Utils.isNull(obj?.column)) obj.priority = 'always';
      else obj.priority = this.getColumnPriority(obj?.column?.name);
      return obj;
    });
    columns = columns.filter((obj) => !hiddenKeys?.includes(obj?.key));
    columns = columns.filter((obj) => {
      if (obj.priority !== 'always') return true;
      width = width + obj.minWidth;
      return false;
    });
    columns = columns.sort((a, b) => a.priority - b.priority);
    columns = columns.filter((obj) => {
      width = width + obj.minWidth;
      return width > tableSize;
    });

    if (columns.length === 0) {
      hidden = hidden.sort((a, b) => a.priority - b.priority);
      hidden = hidden.filter((obj) => {
        width = width + obj.minWidth;
        return width > tableSize;
      });
    }

    columns.push(...hidden);
    return columns;
  }

  onWindowSizeChange() {
    this.setState({ tableDOMSize: Utils.define(this.props?.tableDOM?.offsetWidth) });
  }

  getColumnPriority(name) {
    return Utils.define(
      this.props?.columnsPriority?.filter((obj) => obj?.columnName === name)?.pop()?.priority,
      Number.MAX_VALUE
    );
  }
}

export const TableColumnResponsiveState = (props) => {
  return (
    <Plugin>
      <TemplateConnector>
        {({ tableColumns }) => <TableResponsiveState {...props} tableColumns={tableColumns} />}
      </TemplateConnector>
    </Plugin>
  );
};

// ----------------------------------------------- Remarkers

export const TableColumnResponsive = () => {
  let filterTableColumns = (tableColumns, responsiveHiddenColumns) => {
    let hiddenColumnKeys = responsiveHiddenColumns.map((obj) => obj.key);
    return tableColumns.filter((obj) => !hiddenColumnKeys.includes(obj.key));
  };

  return (
    <Plugin>
      <TemplateConnector>
        {({ responsiveHiddenColumns }) => (
          <Getter
            name='tableColumns'
            computed={({ tableColumns }) =>
              filterTableColumns(tableColumns, responsiveHiddenColumns)
            }
          />
        )}
      </TemplateConnector>
      <TemplateConnector>
        {({ responsiveHiddenColumns }) =>
          responsiveHiddenColumns.length === 0 ? null : (
            <TableRowDetail
              toggleColumnWidth='30px'
              rowComponent={TableColumnResponsiveHiddenColumnsRow}
              toggleCellComponent={TableColumnResponsiveHiddenToggleCellComponent}
            />
          )
        }
      </TemplateConnector>
    </Plugin>
  );
};

const TableColumnResponsiveHiddenToggleCellComponent = (props) => (
  <td className='text-center align-middles'>
    <span onClick={props?.onToggle}>
      <em className={props?.expanded === true ? 'icon-arrow-down' : 'icon-arrow-right'} />
    </span>
  </td>
);

const TableColumnResponsiveHiddenColumnsRow = (props) => (
  <Plugin>
    <TemplateConnector>
      {({ editingRowIds, tableColumns, responsiveHiddenColumns }, { changeRow }) => {
        let rows = responsiveHiddenColumns?.map((col) => {
          let onValueChange = (value) => {
            let change = {};
            if (Utils.isNull(col?.column?.createRowChange)) change[col?.column?.name] = value;
            else change = col?.column?.createRowChange(props.row, value);
            changeRow({ rowId: props.row.id, change: change });
          };

          let cellValue = Utils.isNull(col?.column?.getCellValue)
            ? props?.row?.[col.column.name]
            : col?.column?.getCellValue(props?.row, col?.column?.name);

          return editingRowIds.includes(props.row.id) ? (
            <tr>
              <td className='border-0 px-0 pt-1 pb-0 align-middle d-flex justify-content-between font-weight-bold pr-2'>
                <div className='text-nowrap pr-2'>{col?.column?.title}</div>
                <div className='pr-2'>:</div>
              </td>
              <td className='border-0 px-0 pt-1 pb-0 align-middle'>
                <div>
                  <TemplatePlaceholder
                    name='valueEditor'
                    params={{
                      onValueChange: onValueChange,
                      row: props.row,
                      value: cellValue,
                      column: col?.column,
                    }}
                  />
                </div>
              </td>
            </tr>
          ) : (
            <tr>
              <td className='border-0 px-0 pt-1 pb-0 align-middle d-flex justify-content-between font-weight-bold pr-2'>
                <div className='text-nowrap pr-2'>{col?.column?.title}</div>
                <div className='pr-2'>:</div>
              </td>
              <td className='border-0 px-0 pt-1 pb-0 align-middle'>
                <div>
                  <TemplatePlaceholder
                    name='valueFormatter'
                    params={{ row: props.row, value: cellValue, column: col?.column }}
                  />
                </div>
              </td>
            </tr>
          );
        });

        return (
          <tr>
            <td></td>
            <td colSpan={tableColumns.length - 1}>{rows}</td>
          </tr>
        );
      }}
    </TemplateConnector>
  </Plugin>
);
