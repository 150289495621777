import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Notifier from '../../../components/Notifier';
import ProjectActionsView, { ProjectActions as PROJECT_ACTIONS } from '../views/ProjectActionsView';

import ProjectController from '../../../controllers/ProjectController';
import RequestProgressController from '../../../controllers/RequestProgressController';
import Utils from '../../../utils/Utils';

export default class ProjectActionsState extends PureComponent {
  static propTypes = {
    project: PropTypes.object.isRequired,
    projectFiles: PropTypes.array.isRequired,
    onProjectUpdate: PropTypes.func,
  };

  state = {
    project: null,
    activeAction: null,
    activeActionName: null,
  };

  actionProgressLastUpdateTimestamp = null;
  actionProgressUpdateInterval = 1000;

  // -------------------------------------------------------------------------
  // ------------------------ Data -------------------------------------------
  // -------------------------------------------------------------------------
  async onActionClick(actionId = null) {
    try {
      if (actionId === PROJECT_ACTIONS.GENERATE_TASKS.id) return this.generateTasks();
      else if (actionId === PROJECT_ACTIONS.RECALCULATE_CREDITS.id)
        return this.reCalculateCredits();
    } catch (e) {
      this.errorHandler(e);
    }
  }

  async generateTasks() {
    try {
      this.setState({ isBusy: true });
      const action = await ProjectController.initializeSegments(this.props?.project?.id);
      this.setState(
        {
          activeAction: action,
          activeActionName: PROJECT_ACTIONS.GENERATE_TASKS.name,
        },
        () => {
          setTimeout(this.getActionProgress.bind(this));
        }
      );
    } catch (e) {
      this.errorHandler(e);
      this.setState({ isBusy: false });
    }
  }

  async reCalculateCredits() {
    try {
      this.setState({ isBusy: true });
      const action = await ProjectController.initializeCredits(this.props?.project?.id);
      this.setState(
        {
          activeAction: action,
          activeActionName: PROJECT_ACTIONS.RECALCULATE_CREDITS.name,
        },
        () => {
          setTimeout(this.getActionProgress.bind(this));
        }
      );
    } catch (e) {
      this.errorHandler(e);
      this.setState({ isBusy: false });
    }
  }

  async getActionProgress() {
    try {
      if (this.state?.activeAction?.completed) return;
      const action = await RequestProgressController.getStatus(this.state?.activeAction?.uuid);
      if (action?.completed) {
        this.setState({ activeAction: action }, () => {
          this.getActionResult();
        });
      } else {
        if (!!this.actionProgressLastUpdateTimestamp) {
          const progressChange =
            (action?.progress ?? 0) - (this.state?.activeAction?.progress ?? 0);
          if (!progressChange)
            this.actionProgressUpdateInterval = 2 * this.actionProgressUpdateInterval;
          else {
            const requestInterval = Date.now() - this.actionProgressLastUpdateTimestamp;
            this.actionProgressUpdateInterval = Math.max(1000, requestInterval / progressChange);
          }
          this.actionProgressUpdateInterval = Math.min(this.actionProgressUpdateInterval, 10000);
        }
        this.actionProgressLastUpdateTimestamp = Date.now();
        this.setState({ activeAction: action }, () => {
          setTimeout(this.getActionProgress.bind(this), this.actionProgressUpdateInterval);
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  async getActionResult() {
    try {
      if (!this.state.activeAction) return;
      await RequestProgressController.getResponse(this.state?.activeAction?.uuid);
      Notifier.success(`Project action ${this.state.activeActionName ?? ''} completed!`);
      this.props.onProjectUpdate(null);
    } catch (e) {
      this.errorHandler(e);
    } finally {
      this.setState({
        isBusy: false,
        activeAction: null,
        activeActionName: null,
      });
    }
  }

  async onDownload(statuses) {
    try {
      this.setState({ isBusy: true });
      let segments = await ProjectController.getSegments(this.props?.project?.id, statuses);
      Utils.downloadFile(segments, this.props?.project?.name, 'application/json');
    } catch (e) {
      this.errorHandler(e);
      this.setState({ isBusy: false });
    } finally {
      this.setState({
        isBusy: false,
      });
    }
  }

  render() {
    return (
      <ProjectActionsView
        project={this.props.project}
        projectFiles={this.props.projectFiles}
        isBusy={this.state.isBusy}
        onActionClick={this.onActionClick.bind(this)}
        onDownload={this.onDownload.bind(this)}
        progressState={this.state?.activeAction?.progress ?? 0}
        progressMessage={this.state?.activeAction?.message ?? ''}
      />
    );
  }

  errorHandler(e) {
    console.log(e);
    Notifier.error(e['userMessage']);
  }
}
