import CheckboxWithValidation from '../../../components/forms/CheckboxWithValidation';

const ConsentUS = ({ values, touched, errors, setFieldTouched, setFieldValue, isSubmitted, isUserEditPage = false }) => {
  return (
    <>
      <CheckboxWithValidation
        id='consent_1'
        propertyName='consent_1'
        label={
          <>
            I acknowledge that my personal data (including, but not limited to, voice recordings,
            any biometric data generated therefrom, demographic characteristics (e.g., age, gender),
            and any other special categories of personal data or otherwise sensitive personal data
            (e.g., race, ethnic origin)) may be processed by by Amazon Web Services, Inc. and/or its
            affiliates (“Amazon”) and may be used for the development, improvement, and maintenance
            of Amazon's commercial artificial intelligence and machine learning products and
            services, and may be further published and shared in the manner described below.
          </>
        }
        defaultValue={values.consent_1}
        touched={touched.consent_1}
        error={errors.consent_1}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        disabled={isUserEditPage ? true : isSubmitted}
      />
      <CheckboxWithValidation
        id='consent_2'
        propertyName='consent_2'
        label={
          <>
            I acknowledge that I have notice of and, to the extent my personal data is provided by
            TAUS B.V. (“TAUS”) to Amazon, my personal data is subject to, the Amazon privacy notice
            available at{' '}
            <a href='https://aws.amazon.com/privacy/' target='_blank' rel='noopener noreferrer'>
              https://aws.amazon.com/privacy/
            </a>
            , including as indicated therein my rights under applicable laws for the jurisdiction
            where I reside.
          </>
        }
        defaultValue={values.consent_2}
        touched={touched.consent_2}
        error={errors.consent_2}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        disabled={isUserEditPage ? true : isSubmitted}
      />
      <p>
        If your personal data is provided by TAUS to Amazon and Amazon's processing of your personal
        data is subject to data protection laws which require a lawful basis for processing, unless
        your explicit consent is required, then Amazon's processing of your personal data is
        necessary for the performance of the TAUS Terms between you and TAUS.
      </p>
      <CheckboxWithValidation
        id='consent_3'
        propertyName='consent_3'
        label="I agree that Amazon may process biometric data from my voice recordings to develop Amazon's commercial artificial intelligence and machine learning products and services."
        defaultValue={values.consent_3}
        touched={touched.consent_3}
        error={errors.consent_3}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        disabled={isUserEditPage ? true : isSubmitted}
      />
      <CheckboxWithValidation
        id='consent_4'
        propertyName='consent_4'
        label="I agree that Amazon may process biometric data from my voice recordings to improve Amazon's commercial artificial intelligence and machine learning products and services."
        defaultValue={values.consent_4}
        touched={touched.consent_4}
        error={errors.consent_4}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        disabled={isUserEditPage ? true : isSubmitted}
      />
      <CheckboxWithValidation
        id='consent_5'
        propertyName='consent_5'
        label="I agree that Amazon may process biometric data from my voice recordings to maintain Amazon's commercial artificial intelligence and machine learning products and services."
        defaultValue={values.consent_5}
        touched={touched.consent_5}
        error={errors.consent_5}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        disabled={isUserEditPage ? true : isSubmitted}
      />
      <CheckboxWithValidation
        id='consent_6'
        propertyName='consent_6'
        label="I agree that Amazon may process any other special categories of personal data or sensitive data it collects about me (e.g., race, ethnic origin) to develop Amazon's commercial artificial intelligence and machine learning products and services."
        defaultValue={values.consent_6}
        touched={touched.consent_6}
        error={errors.consent_6}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        disabled={isUserEditPage ? true : isSubmitted}
      />
      <CheckboxWithValidation
        id='consent_7'
        propertyName='consent_7'
        label="I agree that Amazon may process any other special categories of personal data or sensitive data it collects about me (e.g., race, ethnic origin) to improve Amazon's commercial artificial intelligence and machine learning products and services."
        defaultValue={values.consent_7}
        touched={touched.consent_7}
        error={errors.consent_7}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        disabled={isUserEditPage ? true : isSubmitted}
      />
      <CheckboxWithValidation
        id='consent_8'
        propertyName='consent_8'
        label="I agree that Amazon may process any other special categories of personal data or sensitive data it collects about me (e.g., race, ethnic origin) to maintain Amazon's commercial artificial intelligence and machine learning products and services."
        defaultValue={values.consent_8}
        touched={touched.consent_8}
        error={errors.consent_8}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        disabled={isUserEditPage ? true : isSubmitted}
      />
      <p>
        Where required by applicable data protection laws, you may withdraw your consent for
        Amazon's processing of your personal data that is based on the lawful basis of consent at
        any time by contacting{' '}
        <a href='mailto:awsaimlresearch@amazon.com' target='_blank' rel='noopener noreferrer'>
          awsaimlresearch@amazon.com
        </a>
        . Any withdrawal of consent will only take effect for future processing after Amazon has
        received actual notice of your withdrawn consent and will not affect the lawfulness of
        processing before Amazon received such notice.
      </p>
      <CheckboxWithValidation
        id='consent_9'
        propertyName='consent_9'
        label='I acknowledge that Amazon may publish online my voice recordings, transcripts, demographic characteristics, and related information which may be viewed, downloaded, and/or processed by researchers and/or the general public and that this publication by Amazon is necessary for the performance of the TAUS Terms between you and TAUS. Amazon will not publish online any written identifiers which can be used to directly identify you (e.g., your name, email address, or address).'
        defaultValue={values.consent_9}
        touched={touched.consent_9}
        error={errors.consent_9}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        disabled={isUserEditPage ? true : isSubmitted}
      />
      <p>
        Other researchers and/or members of the general public may be separate controllers of your
        personal data. Amazon is not responsible for the processing of your personal data by
        researchers and/or the general public.
      </p>
      <CheckboxWithValidation
        id='consent_10'
        propertyName='consent_10'
        label="I agree that my participation in this project is completely voluntary. I agree that my participation in this project does not grant me any rights in or to the data collected or processed, except as expressly set forth in the Amazon privacy notice, and does not grant me any rights in or to Amazon's products and services."
        defaultValue={values.consent_10}
        touched={touched.consent_10}
        error={errors.consent_10}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        disabled={isUserEditPage ? true : isSubmitted}
      />
    </>
  );
};

export default ConsentUS;
