import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DataCollectionFormAmazon from './amazon/DataCollectionForm';
import DataCollectionFormOrange from './orange/DataCollectionForm';
import DataCollectionFormSoapBox from './soapbox/DataCollectionForm';

import Utils from '../../utils/Utils';
import { Container } from 'react-bootstrap';
import Notifier from '../../components/Notifier';
import { Link, useParams } from 'react-router-dom';
import { PAGES } from '..';
import MetadataController from '../../controllers/MetadataController';
import UserController from '../../controllers/UserController';
import ProjectController from '../../controllers/ProjectController';

const DataCollectionFormContainer = () => {
  const { projectId, screenerStepId } = useParams();
  const user = useSelector((state) => Utils.define(state?.session?.user));
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [screenerStep, setScreenerStep] = useState();
  const [code, setCode] = useState();
  const [form, setForm] = useState();

  useEffect(() => {
    const loadData = async () => {
      const [questionnaires, step] = await Promise.all([
        MetadataController.getQuestionnaires(),
        ProjectController.getScreenerStep(projectId, screenerStepId),
      ]);
      setScreenerStep(step.projectScreenerStep.screenerStep);
      setCode(
        questionnaires.find((q) => q.id === step.projectScreenerStep.screenerStep.requirementTypeId)
          .code
      );

      const AMAZON_FORM_CODES = ['UK', 'US', 'AU', 'USUK'];
      const ORANGE_FORM_CODES = ['BAMBARA'];
      const SOAPBOX_FORM_CODES = ['ES-SBL'];

      if (AMAZON_FORM_CODES.includes(code?.toUpperCase())) {
        setForm(
          <DataCollectionFormAmazon
            countryCode={code?.toUpperCase()}
            handleUpdate={handleUpdate}
            loading={loading}
            user={user?.userMetadata}
            isUserEditPage={false}
          />
        );
      } else if (ORANGE_FORM_CODES.includes(code?.toUpperCase())) {
        setForm(
          <DataCollectionFormOrange
            handleUpdate={handleUpdate}
            loading={loading}
            user={user?.userMetadata}
            isUserEditPage={false}
          />
        );
      } else if (SOAPBOX_FORM_CODES.includes(code?.toUpperCase())) {
        setForm(
          <DataCollectionFormSoapBox
            handleUpdate={handleUpdate}
            loading={loading}
            user={user?.userMetadata}
            isUserEditPage={false}
          />
        );
      }
    };
    loadData();
  }, [code]);

  const handleUpdate = async (userMetadata) => {
    setLoading(true);
    try {
      await UserController.submitQuestionnaire(screenerStep.requirementTypeId, userMetadata);
      setCompleted(true);
    } catch (e) {
      Notifier.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <h2>{code === 'USUK' ? 'Speech data collection' : code + ' - Speech data collection'}</h2>
      <hr />

      {completed && (
        <p>
          Thank you for providing your information.{' '}
          <Link to={PAGES.PUBLIC_PROJECT_LOBBY_PAGE.path(projectId)}>Click here to go back.</Link>
        </p>
      )}

      {user && !completed && form}
    </Container>
  );
};

export default DataCollectionFormContainer;
