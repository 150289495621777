import React from 'react'

export const createDescription = (description) => {
  const descriptionList = !!description ? (description.toString()).split('~') : [];
  return (
    descriptionList.length > 0 && <ul>
      {
        descriptionList.map((description,index) => {
          return <li key={index}>{ description }</li>
        })
      }
    </ul>
  )
}
