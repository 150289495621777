import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './styles/ProjectSettingsView.scss';

import ProjectBasicSettingsView from './ProjectBasicSettingsView';
import ProjectWorkflowSettingsView from './ProjectWorkflowSettingsView';
import ProjectWorkflowSettingsFormView from '../forms/ProjectWorkflowSettingsFormView';
import ProjectPaymentSettingsView from './ProjectPaymentSettingsView';
import ProjectPaymentSettingsFormView from '../forms/ProjectPaymentSettingsFormView';
import TextWithIcon from '../components/TextWithIcon';
import ProjectBasicSettingsFormView from '../forms/ProjectBasicSettingsFormView';

export const SETTINGS_ITEMS = {
  BASIC: 'basic',
  WORKFLOW: 'workflow',
  PAYMENTS: 'payments',
};

export const EditButton = ({ onClick = null }) => (
  <button
    type='button'
    className='btn bg-white d-flex px-0 py-0'
    style={{ fontSize: 'large' }}
    onClick={onClick}
  >
    <em className='far fa-edit' />
  </button>
);

export class ProjectSettingsView extends PureComponent {
  static defaultProps = {
    project: null,
    itemsAreEditable: [],
    itemsAreEditing: [],
    itemsAreBusy: [],
    onSaveButtonClick: null,
    onEditButtonClick: null,
    onCancelButtonClick: null,
    metadataLoading: false,
    languageOptions: [],
  };

  static propTypes = {
    project: PropTypes.object.isRequired,
    itemsAreEditable: PropTypes.array,
    itemsAreEditing: PropTypes.array,
    itemsAreBusy: PropTypes.array,
    onSaveButtonClick: PropTypes.func.isRequired,
    onEditButtonClick: PropTypes.func.isRequired,
    onCancelButtonClick: PropTypes.func.isRequired,
    languageOptions: PropTypes.array.isRequired,
    metadataLoading: PropTypes.bool,
  };

  isItemBusy(id = null) {
    return this.props?.itemsAreBusy?.includes(id) ?? false;
  }

  isItemEditing(id = null) {
    return this.props?.itemsAreEditing?.includes(id) ?? false;
  }

  isItemEditable(id = null) {
    return this.props?.itemsAreEditable?.includes(id) ?? false;
  }

  render() {
    return (
      <div className='taus-project-settings-view'>
        {/* Basic Attributes */}
        <div className='d-flex justify-content-between align-items-start mt-3 mb-3'>
          <TextWithIcon
            text={'Basic Attributes'}
            icon={'fas fa-globe'}
            outerClassName={'d-flex flex-fill justify-content-center'}
            innerClassName={'text-center'}
            size={3}
          />
          {this.isItemEditing(SETTINGS_ITEMS.BASIC) ? null : (
            <EditButton onClick={() => this.props.onEditButtonClick(SETTINGS_ITEMS.BASIC)} />
          )}
        </div>
        <div className={this.isItemBusy(SETTINGS_ITEMS.BASIC) ? ' sphere whirl' : ''}>
          {this.isItemEditing(SETTINGS_ITEMS.BASIC) ? (
            <ProjectBasicSettingsFormView
              project={this.props?.project}
              languageOptions={this.props?.languageOptions}
              isLoadingLanguages={this.props?.metadataLoading}
              showCancelButton={true}
              onCancelButtonClick={() => {
                this.props.onCancelButtonClick(SETTINGS_ITEMS.BASIC);
              }}
              onSaveButtonClick={(data) => {
                this.props.onSaveButtonClick(SETTINGS_ITEMS.BASIC, data);
              }}
            />
          ) : (
            <ProjectBasicSettingsView project={this.props?.project} />
          )}
        </div>
        {/* Workflow */}
        <div className='d-flex justify-content-between align-items-start mt-3 mb-3'>
          <TextWithIcon
            text={'Workflow'}
            icon={'fa fa-cogs'}
            outerClassName={'d-flex flex-fill justify-content-center'}
            innerClassName={'text-center'}
            size={3}
          />
          {this.isItemEditing(SETTINGS_ITEMS.WORKFLOW) ? null : (
            <EditButton onClick={() => this.props.onEditButtonClick(SETTINGS_ITEMS.WORKFLOW)} />
          )}
        </div>
        <div
          className={
            'taus-project-settings-view-stage-settings' +
            (this.isItemBusy(SETTINGS_ITEMS.WORKFLOW) ? ' sphere whirl' : '')
          }
        >
          {this.isItemEditing(SETTINGS_ITEMS.WORKFLOW) ? (
            <ProjectWorkflowSettingsFormView
              {...this.props?.project?.settings}
              scope={this.props?.project?.scope}
              sourceLanguage={this.props?.project.sourceLanguage}
              targetLanguage={this.props?.project.targetLanguage}
              languageOptions={this.props?.languageOptions}
              showCancelButton={true}
              onCancelButtonClick={() => this.props.onCancelButtonClick(SETTINGS_ITEMS.WORKFLOW)}
              onSaveButtonClick={(data, keepFormOpen) =>
                this.props.onSaveButtonClick(SETTINGS_ITEMS.WORKFLOW, data, keepFormOpen)
              }
              hasTasks={this.props?.project?.tasksNum > 0}
            />
          ) : (
            <ProjectWorkflowSettingsView
              {...this.props?.project?.settings}
              scope={this.props?.project?.scope}
            />
          )}
        </div>
        {/* Payments */}
        <div className=' d-flex justify-content-between align-items-start mt-3 mb-3'>
          <TextWithIcon
            text={'Payments'}
            icon={'fa fa-money-bill'}
            outerClassName={'d-flex flex-fill justify-content-center'}
            innerClassName={'text-center'}
            size={3}
          />
          {this.isItemEditing(SETTINGS_ITEMS.PAYMENTS) ? null : (
            <EditButton onClick={() => this.props.onEditButtonClick(SETTINGS_ITEMS.PAYMENTS)} />
          )}
        </div>
        <div className={this.isItemBusy(SETTINGS_ITEMS.PAYMENTS) ? 'sphere whirl' : ''}>
          {this.isItemEditing(SETTINGS_ITEMS.PAYMENTS) ? (
            <ProjectPaymentSettingsFormView
              {...this.props?.project?.settings}
              onCancelButtonClick={() => this.props.onCancelButtonClick(SETTINGS_ITEMS.PAYMENTS)}
              onSaveButtonClick={(data) =>
                this.props.onSaveButtonClick(SETTINGS_ITEMS.PAYMENTS, data)
              }
            />
          ) : (
            <ProjectPaymentSettingsView {...this.props?.project?.settings} />
          )}
        </div>
      </div>
    );
  }
}

export default ProjectSettingsView;
