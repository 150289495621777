// External libs
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

// Libs
import Utils from '../../utils/Utils';
import Parameter from './Parameter';

// -------------------------------------------------------------------------
// ---------------------------- Functional Component -----------------------
// -------------------------------------------------------------------------
export const Text = (props) => {
  let onChange = (event) => {
    if (!Utils.isNull(props?.onChange))
      Promise.resolve(event?.target?.value).then((value) => props.onChange(value));
  };

  let textProps = {
    placeholder: Utils.define(props?.placeholder, ''),
    onChange: onChange,
    className:
      Utils.define(props?.className, '') +
      (props?.isValid === false ? ' text-danger' : '') +
      ' TextParameter',
    style: { height: '38px', ...props?.style },
  };

  if (Utils.isDefined(props?.value)) textProps.value = Utils.define(props.value, '');
  else textProps.defaultValue = Utils.define(props?.defaultValue, '');

  return <Input {...textProps} />;
};

Text.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  isValid: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};

// -------------------------------------------------------------------------
// ---------------------------- Parameter ----------------------------------
// -------------------------------------------------------------------------
const textParameterDefaultProps = () => ({
  // Properties
  defaultValue: '',
  isValid: true,
  placeholder: '',
  className: null,
  style: null,
  // Listeners
  onChange: null,
});

export default class TextParameter extends Parameter {
  constructor(props = {}) {
    super(props);
    this.state.value = Utils.define(props?.defaultValue, '');
  }

  getDefaultProperties = () => ({ ...TextParameter.defaultProps });

  static defaultProps = textParameterDefaultProps();

  state = {
    value: '',
  };

  // --------------------------------------------------------------------------
  //-------------------------- ReactJS Methods --------------------------------
  // --------------------------------------------------------------------------
  render() {
    let textProps = {
      className: Utils.define(this.props?.className),
      isValid: Utils.defineBoolean(this.props?.isValid, true),
      value: Utils.define(this.state?.value),
      placeholder: Utils.define(this.props?.placeholder),
      onChange: this.valueChanged.bind(this),
      style: Utils.define(this.props?.style),
    };

    return <Text {...textProps} />;
  }

  // ---------------------------------------------------------------
  // -------------------------- Listeners --------------------------
  // ---------------------------------------------------------------
  valueChanged(value) {
    try {
      this.state.value = Utils.define(value, '');
      this.forceUpdate();
      super.valueChanged();
    } catch (e) {
      this.errorHandler(e);
    }
  }

  // ---------------------------------------------------------------
  // -------------------------- Methods ----------------------------
  // ---------------------------------------------------------------
  clear() {
    this.setValue('');
  }

  reset() {
    this.setValue(this.props?.defaultValue);
  }

  getValue() {
    return this.state.value;
  }

  setValue(value, arrayDelimiter = ',') {
    try {
      if (!Utils.isDefined(value)) return false;
      value = Utils.define(value, '');
      value = Array.isArray(value) ? value.join(Utils.define(arrayDelimiter, '')) : value;
      this.state.value = value;
      this.forceUpdate();
      return true;
    } catch (e) {
      this.errorHandler(e);
      return false;
    }
  }

  getStringValue() {
    return this.getValue();
  }
}

TextParameter.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};
