import Task from '../../../models/Task';
import { findOption } from '../../../utils/Select';
import TaskResult from '../../../models/TaskResult';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { yearOfBirthOptions } from '../../forms/amazon/Options';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputWithValidation from '../../../components/forms/InputWithValidation';
import SelectWithValidation from '../../../components/forms/SelectWithValidation';

const ProjectTrackingFilters = ({ filters, handleFilterChange, handleClearFilters }) => {
  const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'non-binary', label: 'Non Binary' },
  ];
  const genderDefaultOption = findOption(genderOptions, filters['worker.metadata.gender']);
  const yobOptions = yearOfBirthOptions(null, 0);
  const yobFromDefaultOption = findOption(yobOptions, filters['yearOfBirthFrom'], true);
  const yobToDefaultOption = findOption(yobOptions, filters['yearOfBirthTo'], true);
  const typeOptions = Task.getTaskTypeOptions();
  const typeDefaultOption = findOption(typeOptions, filters['task.type.name'], true);
  const statusOptions = TaskResult.getStatusOptions();
  const statusDefaultOption = findOption(statusOptions, filters['status']);
  const evaluationStatusOptions = TaskResult.getEvaluationStatusOptions();
  const evaluationStatusDefaultOption = findOption(
    evaluationStatusOptions,
    filters['evaluationStatus']
  );
  const paymentStatusOptions = TaskResult.getPaymentStatusOptions();
  const paymentStatusDefaultOption = findOption(paymentStatusOptions, filters['paymentStatus']);

  return (
    <>
      <div className='tw-relative tw-mb-4'>
        <span className='tw-font-medium tw-text-lg'>Filters</span>
        <div
          className='tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-right-0 tw-w-8 tw-h-8 tw-rounded tw-bg-[#fff] tw-border tw-border-solid tw-border-gray-300 tw-cursor-pointer hover:tw-bg-gray-100 tw-transition-all tw-ease-in-out tw-duration-200'
          onClick={handleClearFilters}
        >
          <FontAwesomeIcon icon={faTrashCan} className='tw-text-[20px]' />
        </div>
      </div>
      <div className='tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 xl:tw-grid-cols-5 tw-gap-x-2'>
        <InputWithValidation
          propertyName='id'
          label='Task Result ID'
          placeholder='Task Result ID'
          defaultValue={filters.id ?? ''}
          onChange={handleFilterChange}
        />
        <InputWithValidation
          label='Task ID'
          propertyName='taskId'
          placeholder='Task ID'
          defaultValue={filters?.taskId ?? ''}
          onChange={handleFilterChange}
        />
        <InputWithValidation
          label='Worker Username'
          propertyName='worker.sid'
          placeholder='Worker Username'
          defaultValue={filters['worker.sid'] ?? ''}
          onChange={handleFilterChange}
        />
        <InputWithValidation
          label='Speaker Id'
          propertyName='worker.metadata.speakerId'
          placeholder='Speaker Id'
          defaultValue={filters['worker.metadata.speakerId'] ?? ''}
          onChange={handleFilterChange}
        />
        <SelectWithValidation
          label='Gender'
          placeholder='Gender'
          propertyName='worker.metadata.gender'
          options={genderOptions}
          defaultOption={genderDefaultOption}
          onChange={handleFilterChange}
        />
        <SelectWithValidation
          label='Year of Birth From'
          placeholder='Year of Birth From'
          propertyName='yearOfBirthFrom'
          options={yobOptions}
          defaultOption={yobFromDefaultOption}
          onChange={handleFilterChange}
        />
        <SelectWithValidation
          label='Year of Birth To'
          placeholder='Year of Birth To'
          propertyName='yearOfBirthTo'
          options={yobOptions}
          defaultOption={yobToDefaultOption}
          onChange={handleFilterChange}
        />
        <SelectWithValidation
          label='Type'
          placeholder='Type'
          propertyName='task.type.name'
          isMulti={false}
          byLabel={true}
          isSearchable={true}
          options={typeOptions}
          defaultOption={typeDefaultOption}
          onChange={handleFilterChange}
        />
        <SelectWithValidation
          label='Status'
          placeholder='Status'
          propertyName='status'
          isMulti={true}
          isSearchable={true}
          options={statusOptions}
          defaultOption={statusDefaultOption}
          onChange={handleFilterChange}
        />
        <SelectWithValidation
          label='Evaluation Status'
          placeholder='Evaluation Status'
          propertyName='evaluationStatus'
          isMulti={true}
          isSearchable={true}
          options={evaluationStatusOptions}
          defaultOption={evaluationStatusDefaultOption}
          onChange={handleFilterChange}
        />
        <SelectWithValidation
          label='Payment Status'
          placeholder='Payment Status'
          propertyName='paymentStatus'
          isMulti={true}
          isSearchable={true}
          options={paymentStatusOptions}
          defaultOption={paymentStatusDefaultOption}
          onChange={handleFilterChange}
        />
      </div>
    </>
  );
};

export default ProjectTrackingFilters;
