import React  from 'react';

export default function PaypalComponent(){
  const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
  const PAYPAL_REDIRECT_URI = process.env.REACT_APP_PAYPAL_REDIRECT_URI;
  const PAYPAL_API_URL = process.env.REACT_APP_PAYPAL_API_URL;
  const url =`${PAYPAL_API_URL}/?flowEntry=static&client_id=${CLIENT_ID}&response_type=code&scope=openid profile email address&redirect_uri=${PAYPAL_REDIRECT_URI}&state=123456`;
  return (
    <div>
        <a className={`tw-cursor-pointer`} href={url}>
          <img src='https://www.paypalobjects.com/devdoc/log-in-with-paypal-button.png' alt="login_with_paypal" />
        </a>
        <div>
          <span>We'll never transact anything without your permission.</span>
        </div>
    </div>
);
}