import { get } from '../http';

const getLanguages = async () => {
  return get('/api/metadata/language');
};

const getLanguageLevels = async () => {
  return get('/api/metadata/language-level');
};

const getUserEthnicGroups = async () => {
  return get('/api/metadata/user-ethnic-group');
};

const getUserGenders = async () => {
  return get('/api/metadata/user-gender');
};

const getUserDialects = async () => {
  return get('/api/metadata/user-dialect');
};

const getGeolocations = async () => {
  return get('/api/metadata/geolocation');
};

const getQuestionnaires = async () => {
  return get('/api/metadata/questionnaire');
};

const MetadataController = {
  getLanguages,
  getLanguageLevels,
  getUserEthnicGroups,
  getUserGenders,
  getUserDialects,
  getGeolocations,
  getQuestionnaires,
};

export default MetadataController;
