/**
 * This file contains utility methods and classes for the Quality Dashboard.
 * @author Panos Kalodimas
 * @since 01/07/2018
 *
 * Completed
 * Tested
 */

export default class Utils {
  /**
   * Checks if a variable is undefined or Null. If it is not then it returns it as
   * is. Else it returns the 'defaultValue' parameter.
   *
   * @param variable -
   *            A javascript object variable
   * @param defaultValue -
   *            The value to be returned if the 'variable' parameter is either
   *            undefined or null. If it is not defined then the 'defaultValue' is
   *            set as Null.
   * @returns 'variable' parameter if it is defined and not Null.
   * @returns 'defaultValue' parameter if the 'variable' parameter is undefined or
   *          Null.
   * @returns Null if both the 'variable' and 'defaultValue' parameters are
   *          undefined.
   */
  static define(value, defaultValue = null) {
    if (defaultValue === undefined) defaultValue = null;
    return value === undefined || value === null ? defaultValue : value;
  }

  static defineNumber(value, defaultValue = null) {
    value = Utils.define(value, defaultValue);
    return typeof value === 'number' ? value : Utils.define(defaultValue);
  }

  static defineString(value, defaultValue = null) {
    value = Utils.define(value, defaultValue);
    return typeof value === 'string' ? value : Utils.define(defaultValue);
  }

  static defineToString(value, defaultValue = null) {
    value = Utils.define(value, defaultValue);
    if (value === null) return '';
    if (typeof value === 'string') return value;
    if (typeof value === 'number') return '' + value;
    return defaultValue;
  }

  static defineBoolean(value, defaultValue) {
    value = Utils.define(value);
    if (value === true || value === false) return value;
    return defaultValue;
  }

  /**
   * Checks if the 'value' parameter is an Array Object.
   * If it is not it returns the 'defaultValue' parameter object if it is an Array object else null.
   *
   * @param value
   *            Variable to check
   * @param defaultValue
   *            The value to return if 'value' parameter fails the check
   *            procedure. Default value is null.
   * @returns 'value' parameter - If the passes the checks
   * @returns 'defaultValue' parameter - If the 'value' parameter fails the
   *          checks.
   * @returns Null - If 'value' parameter fails the checks and 'defaultValue'
   *          is undefined.
   * @see defineToArray
   */
  static defineArray(value, defaultValue) {
    if (Array.isArray(value)) return value;
    if (Array.isArray(defaultValue)) return defaultValue;
    return null;
  }

  /**
   * Checks if the 'value' parameter is an Array object.
   * If the 'value' parameter value is null then it returns the 'defaultValue' parameter object, if it is an Array one or null, as in defineArray method.
   * If the 'value' parameter value is not null but also not an Array object it returns an Array object with the 'value' parameter object as its first element.
   * If the 'delimiter' parameter is defined then the 'value' object will be splited as a string object using this delimiter and the splited parts Array will be returned.
   * If the 'value' object is not a String one then null will be returned.
   * @param {*} value The object to be defined.
   * @param {Array} defaultValue The object to be returned if the 'value' parameter is null or undefined.
   * @param {String} delimiter The delimiter to use for spliting the 'value' parameter object in case of String object.
   * @returns {Array} Array or null
   */
  static defineToArray(value = null, defaultValue = null, delimiter = null) {
    try {
      if (Array.isArray(value)) return value;
      if (Utils.isNull(value)) return Utils.defineArray(defaultValue);
      if (Utils.isNull(delimiter)) return [value];
      return value.split(delimiter);
    } catch (e) {
      return null;
    }
  }

  /**
   * Checks if the 'value' parameter is defined
   *
   * @param value -
   *            Variable to check
   * @returns True - If the 'value' parameter is defined
   * @returns False - If the 'value' parameter is undefined
   */
  static isDefined(value) {
    if (value === undefined) return false;
    return true;
  }

  /**
   * Checks if the 'value' parameter is Null or Undefined.
   *
   * @param value -
   *            Variable to check
   * @returns True - If 'value' parameter is Null or Undefined
   * @returns False - If 'value' parameter is not Null
   */
  static isNull(value) {
    if (value === undefined || value === null) return true;
    return false;
  }

  /**
   * Checks if the 'value' parameter is Null, Undefined or Emtpy.
   * - For strings empty means zero length, ex ""
   * - For Arrays empty means zero elements, ex []
   * - For Objects empty means zero properties, ex {}
   *
   * @param value -
   *            Variable to check
   * @returns True - If 'value' parameter is Null, Undefined or Empty string
   * @returns False - If 'value' parameter is not Null
   */
  static isEmpty(value) {
    if (value === undefined || value === null || value === '') return true;
    if (Array.isArray(value) && value.length === 0) return true;
    if (typeof value === 'object' && Object.keys(value).length === 0) return true;
    if (typeof value === 'string' && value.trim() === '') return true;
    return false;
  }

  /**
   * Returns the string passed as input with the first letter Upper and the rest lower.
   * @param text
   * @returns {string|*}
   */
  static textFirstOnlyUpper(text = null) {
    if (Utils.isEmpty(text)) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  static enumToCamelcase = (text) => {
    const textWithBreaks = text.replace(/_/g, ' ');
    const words = textWithBreaks.split(' ');

    return words
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
      })
      .join(' ');
  };

  static textFormalize(text) {
    if (Utils.isEmpty(text)) return text;
    let formal = '';
    let parts = text.split(' ');
    parts.forEach((obj) => {
      if (formal !== '') formal = formal + ' ';
      formal = formal + Utils.textFirstOnlyUpper(obj);
    });
    return formal;
  }

  static num2String(num, digits) {
    if (Utils.isNull(num)) return '';
    let string = '' + num;
    if (typeof digits !== 'number') return string;
    while (string.length < digits) string = '0' + string;
    return string;
  }

  static trimStringShort(text, numberOfWords = 10) {
    if (Utils.isNull(text)) return '';
    let dots = '';
    text.length > 100 ? (dots = '...') : (dots = '');
    return text && text.replace(/^(.{100}[^\s]*).*/, '$1') + dots;
  }

  // TODO This should be moved to models.Task file
  /**
   * rgb color array (size 6) for the respective statuses: 'Inactive', 'Active', 'Pending', 'Submitted', 'Reviewed', 'Completed'
   * @type {string[]}
   */
  static statusColors = [
    'rgba(201,203,207,1)',
    'rgba(255,61,103,1)',
    'rgba(255,205,86,1)',
    'rgba(54,162,235,1)',
    'rgba(32,184,55,1)',
    'rgba(46,46,46,1)',
  ];

  /**
   * In this solution, we use the .reduce() method to continuously execute a function that checks if the current item is already in the accumulator array (newArray). Take note that the accumulator is initialized to an empty array at the beginning of the iteration.
   * <p>If the element is within the array already, we return the array as it is, to be used in the next iteration. If it isn’t, we return a new array which we create from the elements in the accumulator(newArray) and the current item. This new array is used as the accumulator in the next iteration.
   * <p>	The result array contains no duplicates
   */
  static mergeArrays(...arrays) {
    let jointArray = [];

    arrays.forEach((array) => {
      jointArray = [...jointArray, ...array];
    });
    const uniqueArray = jointArray.reduce((newArray, item) => {
      if (newArray.includes(item)) {
        return newArray;
      } else {
        return [...newArray, item];
      }
    }, []);
    return uniqueArray;
  }

  // Helper method to map a static model fetched from the api to an option for react-select
  static mapToOption = (list, propertyName) => {
    if (!propertyName) propertyName = 'name';
    return list.map((item) => {
      return {
        value: item.id,
        label: item[propertyName],
      };
    });
  };

  // Helper method to get the default option with the specified id as it's value
  static getDefaultOption = (list, id) => {
    let item = list.find((o) => o.value === id);
    return item ? item : null;
  };

  // Helper method to get the default option list that have the specified ids
  static getDefaultOptions = (list, ids) => {
    if (!ids) return null;
    let item = list.filter((o) => ids.includes(o.value));
    return item ? item : null;
  };

  static downloadFile(fileData, fileName, contentType) {
    let extension;
    if (contentType === 'application/json') {
      fileData = JSON.stringify(fileData, null, 2);
      extension = '.json';
    } else if (contentType === 'text/plain') extension = '.txt';
    else if (contentType === 'text/csv') extension = '.csv';
    else {
      console.log('Utils.downloadFile does not support content type: ' + contentType);
      return;
    }

    let data = new Blob([fileData], { type: contentType });
    let csvURL = window.URL.createObjectURL(data);
    let tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', fileName + extension);
    tempLink.click();
    window.URL.revokeObjectURL(csvURL); // revoke created URL to avoid memory leaks
  }

  // Short-circuiting, and saving a parse operation
  static isInt(value) {
    let x;
    if (isNaN(value)) {
      return false;
    }
    x = parseFloat(value);
    return (x | 0) === x;
  }
}
