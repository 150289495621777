// External libs
import React from 'react';
import PropTypes from 'prop-types';

// QD libs
import Utils from '../../utils/Utils';

export const Label = (props) => {
  let attrs = {
    className: Utils.define(props?.className, '') + ' d-flex align-items-center ParameterLabel',
    style: Utils.define(props?.style),
  };

  return (
    <div {...attrs}>
      {props?.position !== 'right' ? (
        <div
          className={Utils.isNull(props?.labelClass) ? 'pr-2 font-weight-bold' : props?.labelClass}
        >
          {props?.label}
        </div>
      ) : null}
      {props.children}
      {props?.position === 'right' ? (
        <div
          className={Utils.isNull(props?.labelClass) ? 'pl-2 font-weight-bold' : props?.labelClass}
        >
          {props?.label}
        </div>
      ) : null}
    </div>
  );
};

export default Label;

Label.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  position: PropTypes.oneOf(['right', 'left']),
  labelClass: PropTypes.string,
  children: PropTypes.node.isRequired,
};
