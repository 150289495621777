import React from 'react';
import '../css/Toggler.scss';

const Toggler = ({ toggle, handleToggle }) => {
  const handleClick = () => {
    handleToggle(!toggle);
  };

  return (
    <div
      className={`toggler tw-w-[3rem] tw-h-[1.625rem] tw-relative tw-rounded-full tw-border tw-border-solid tw-pl-1 tw-pr-1 tw-pt-1 tw-pb-1 tw-cursor-pointer ${
        toggle ? 'tw-border-green-500 tw-bg-green-500' : 'tw-border-gray-300 tw-bg-gray-300'
      }`}
      onClick={handleClick}
    >
      <div
        className={`tw-block tw-w-[1.2rem] tw-h-[1.2rem] tw-mt-[-1.6px] tw-bg-white tw-absolute tw-rounded-full ${
          toggle ? 'thumb-enabled' : 'thumb-disabled'
        }`}
      ></div>
    </div>
  );
};

export default Toggler;
