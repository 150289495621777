import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import toHtml from 'draftjs-to-html';

import Utils from './Utils';

export const htmlToEditorState = (htmlString) => {
  if (!Utils.isEmpty(htmlString)) {
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(htmlString), null)
    );
  } else {
    return EditorState.createEmpty();
  }
};

export const editorStateToHtml = (editorState) =>
  toHtml(convertToRaw(editorState.getCurrentContent()));
