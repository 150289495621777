import { useState } from 'react';
import WindowedSelect from 'react-windowed-select';

const RoleNotificationTestEmail = ({ userOptions, loadingOptions, sendTestEmail }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  return (
    <div className='row justify-content-center mt-2'>
      <div className='col'>
        <WindowedSelect
          id='selectUsers'
          placeholder='Select a user to test the template'
          onChange={setSelectedUser}
          isClearable
          isSearchable
          options={userOptions}
          isMulti={false}
          noResultsText='No users found'
          menuPlacement='auto'
          isLoading={loadingOptions}
        />
      </div>
      <div className='col'>
        <button
          key='test'
          type='button'
          color='primary'
          onClick={() => sendTestEmail(selectedUser.value)}
          className={'btn btn-outline-primary btn-block'}
          disabled={!selectedUser}
        >
          Send test email
        </button>
      </div>
    </div>
  );
};

export default RoleNotificationTestEmail;
