import { Redirect, useLocation } from 'react-router-dom';
import { PAGES } from '.';

const PrivateRoute = ({ children, userHasAccess = false, redirectTo = PAGES.SIGN_IN.path() }) => {
  const location = useLocation();

  if (!userHasAccess) {
    return <Redirect to={{ pathname: redirectTo, state: { from: location } }} />;
  }

  return children;
};

export default PrivateRoute;
