import { Input } from 'reactstrap';
import { FormFieldFooter } from './FormFieldFooter';

const CheckboxWithValidation = ({
  label,
  error,
  onBlur,
  touched,
  helpText,
  disabled,
  onChange,
  propertyName,
  defaultValue,
  onValueChange,
  forceDefaultStyle,
  isFormGroup = true,
}) => {
  const handleChange = (e) => {
    onChange(propertyName, e.target.checked);

    // If and extra callback is defined, call that as well
    if (onValueChange) onValueChange(e.target.checked);
  };

  const handleBlur = () => {
    onBlur(propertyName, true);
  };

  return (
    <div className={isFormGroup ? 'form-group' : null}>
      <div className='checkbox c-checkbox d-flex align-items-center tw-w-full'>
        <label className='mb-0'>
          <Input
            className='form-check-input'
            type='checkbox'
            checked={!!defaultValue}
            onChange={handleChange}
            onBlur={handleBlur}
            invalid={!forceDefaultStyle && !!(error && touched)}
            valid={!forceDefaultStyle && !error && touched}
            disabled={disabled}
          />
          <span className='fa fa-check mr-2 mb-0 mt-0 ml-0 pr-2' />
          {label}
        </label>
      </div>
      <FormFieldFooter helpText={helpText} error={error} touched={touched} />
    </div>
  );
};

export default CheckboxWithValidation;
