import { useCallback, useState } from 'react';
import Segment from '../../../models/Segment';

export const useAnnotationTargets = (task) => {
  const [targets, setTargets] = useState(() =>
    initializeTargets(task.targets, task.options?.tagDefinitions)
  );

  const changeTargets = (segmentIds, newValues) => {
    setTargets((prevState) => {
      let newTargets = {};
      segmentIds.forEach((segmentId) => {
        newTargets[segmentId] = { ...prevState[segmentId], ...newValues };
      });
      return {
        ...prevState,
        ...newTargets,
      };
    });
  };

  const onDataChanged = useCallback((segmentId, data) => {
    let errorMessages = [];
    let valid = true;

    if (data.some((bucket) => bucket.tokens.length > 1 && bucket.tags.length === 0)) {
      valid = false;
      errorMessages.push('All groups must have at least one tag');
    } else if (data.every((bucket) => bucket.tags.length === 0)) {
      valid = false;
      errorMessages.push('At least one token must be tagged');
    }

    changeTargets([segmentId], { changed: true, data, valid, errorMessages });
  }, []);

  return { targets, changeTargets, onDataChanged };
};

const initializeTargets = (targets, tagDefinitions) => {
  const newTargets = {};
  targets.forEach((target) => {
    let data = [];
    try {
      data = prepareDataForDisplay(JSON.parse(Segment.getContentData(target)), tagDefinitions);
    } catch (error) {
      data = initializeBucketsFromTokens(Segment.getContentTokens(target));
    }

    newTargets[target.id] = {
      id: target.id,
      changed: false,
      valid: true,
      errorMessages: [],
      loadingData: false,
      data,
    };
  });
  return newTargets;
};

const initializeBucketsFromTokens = (tokens) =>
  tokens.map((token, i) => ({ id: i, tags: [], tokens: [{ id: i, value: token }] }));

const prepareDataForDisplay = (buckets, tagDefinitions) =>
  buckets.map((bucket) => ({
    ...bucket,
    tags: extractTagDefinitions(bucket.tags, tagDefinitions),
  }));

const extractTagDefinitions = (tags, tagDefinitions) =>
  tags.map((chain) => chain.map((value) => tagDefinitions[value]));

export const prepareDataForSending = (buckets) =>
  buckets.map((bucket) => ({ ...bucket, tags: extractTagValues(bucket.tags) }));

const extractTagValues = (tags) => tags.map((chain) => chain.map(({ value }) => value));
