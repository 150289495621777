import { useCallback, useEffect, useState } from 'react';
import Notifier from '../../../components/Notifier';
import RequestProgressController from '../../../controllers/RequestProgressController';
import TaskResultController from '../../../controllers/TaskResultController';
import TelemetryController from '../../../controllers/TelemetryController';
import Project from '../../../models/Project';
import Segment from '../../../models/Segment';
import { base64toBlob, blobToBase64, calculateAudioLength } from '../../../utils/Recorder';
import Utils from '../../../utils/Utils';
import { distance } from 'damerau-levenshtein-js';

const initializeTargets = (targets) => {
  const newTargets = {};
  targets.forEach((target) => {
    const data = JSON.parse(Segment.getContentData(target));
    const base64EncodedAudio = data?.audio;

    newTargets[target.id] = {
      id: target.id,
      changed: false,
      loadingData: false,
      audio: base64EncodedAudio ? base64toBlob(base64EncodedAudio) : null,
      transcription: data?.transcription,
      validAudio: true,
      validTranscription: true,
      errorMessages: [],
      metadata: {
        ...target.source.metadata,
        segmentRecDurationMin: target?.source?.metadata?.segmentRecDurationMin ?? 0,
        segmentRecDurationMax: target?.source?.metadata?.segmentRecDurationMax ?? 500,
        segmentType: target?.source?.metadata?.segmentType ?? 'read_speech',
        minNumberOfEdits: target?.source?.metadata?.minNumberOfEdits ?? 0,
        maxSilencePercentage: target?.source?.metadata?.maxSilencePercentage ?? 1,
      },
      shouldStartLanguageQE: false,
      loadingLanguageQEMetadata: false,
      loadingLanguageQEMetadataFailed: false,
      languageQEMetadata: {
        wpm: data?.wpm ?? 0,
        score: data?.score ?? 0,
      },
      shouldUpdateOriginalTranscription: true,
    };
  });
  return newTargets;
};

export const useTTSTargets = (task, sampleRate = 16000) => {
  const [targets, setTargets] = useState(() => initializeTargets(task.targets));

  const changeTargets = (segmentIds, newValues) => {
    setTargets((prevState) => {
      let newTargets = {};
      segmentIds.forEach((segmentId) => {
        newTargets[segmentId] = { ...prevState[segmentId], ...newValues };
      });
      return {
        ...prevState,
        ...newTargets,
      };
    });
  };

  const languageQEminPassScore =
    task.options?.preSubmitEvaluation?.languageQE?.minPassScore ?? null;
  const scope = task.task.scope;

  const onAudioChanged = useCallback(
    (segmentId, audio, minRecDuration, maxRecDuration, segmentType) => {
      const { validAudio, errorMessages } = validateAudioPreLanguageQE(
        audio,
        sampleRate,
        minRecDuration,
        maxRecDuration
      );

      const shouldStartLanguageQE =
        validAudio &&
        scope !== Project.SCOPE.WORKER_VALIDATION.sid &&
        !Utils.isEmpty(languageQEminPassScore);

      // We need this to prevent the saving of empty transcriptions
      // BUT just for the transcription segments
      const validTranscription =  isReadSpeechSegment ? true : !isUnstructuredSpeechSegment(segmentType);

      changeTargets([segmentId], {
        changed: true,
        audio,
        validAudio,
        errorMessages,
        validTranscription,
        shouldStartLanguageQE,
        shouldUpdateOriginalTranscription: false,
      });
    },
    [languageQEminPassScore, scope, sampleRate]
  );

  const onTranscriptionChanged = (segmentId, transcription, validationMetadata) => {
    const { validTranscription, errorMessages } = validateTranscription(
      transcription,
      validationMetadata
    );
    changeTargets([segmentId], {
      changed: true,
      transcription,
      validTranscription,
      errorMessages,
      shouldUpdateOriginalTranscription: false,
    });
  };

  // The polling mechanism used here can easily be changed to websockets
  useEffect(() => {
    Object.keys(targets)
      .filter((key) => targets[key].shouldStartLanguageQE)
      .forEach((key) => {
        startLanguageQE(
          task.id,
          targets[key].id,
          targets[key].metadata.segmentType,
          targets[key].audio,
          changeTargets,
          languageQEminPassScore,
          targets[key].metadata.minNumberOfEdits,
          targets[key].metadata.maxSilencePercentage,
          sampleRate
        );
      });
  }, [targets, task.id, languageQEminPassScore, sampleRate]);

  return { targets, changeTargets, onAudioChanged, onTranscriptionChanged };
};

export const isUnstructuredSpeechSegment = (segmentType) =>
  segmentType.toUpperCase() === 'UNSTRUCTURED_SPEECH';

export const isReadSpeechSegment = (segmentType) => segmentType.toUpperCase() === 'READ_SPEECH';

const validateAudioPreLanguageQE = (audio, sampleRate, minRecDuration, maxRecDuration) => {
  let errorMessages = [];
  const validAudio = audio
    ? calculateAudioLength(audio, sampleRate) >= minRecDuration &&
      calculateAudioLength(audio, sampleRate) <= maxRecDuration
    : true;
  if (!validAudio) {
    errorMessages.push(
      `Your recording must be between ${minRecDuration} and ${maxRecDuration} seconds long.`
    );
  }
  return { errorMessages, validAudio };
};

const validateTranscription = (transcription, validationMetadata = {}) => {
  let validTranscription = true;
  let errorMessages = [];

  if (Utils.isEmpty(transcription)) {
    validTranscription = false;
    errorMessages.push('Transcription cannot be empty!');
  }

  const { oldTranscription, minNumberOfEdits } = validationMetadata;
  if (
    !!validationMetadata.oldTranscription &&
    distance(transcription, oldTranscription) < minNumberOfEdits * oldTranscription.length
  ) {
    validTranscription = false;
    errorMessages.push(
      "Transcription wasn't edited enough. Please check punctuation/filler words/capital letters. If no other change is needed and you still can't save, please add spaces at the end of the transcription."
    );
  }

  return { validTranscription, errorMessages };
};

const QE_ERROR_TEXT =
  'An error occurred while processing your recording. Please try again.' +
  ' If this persists, please contact the admins.';

const segmentTypeMappings = {
  unstructured_speech: 'SPEECH_TRANSCRIPTION',
  read_speech: 'SPEECH_QA',
  silence_check: 'SILENCE_CHECK',
};

const startLanguageQE = async (
  taskId,
  segmentId,
  segmentType,
  audio,
  changeTargets,
  minLanguageQEScore,
  minNumberOfEdits,
  maxSilencePercentage,
  sampleRate
) => {
  try {
    const audioLength = calculateAudioLength(audio, sampleRate);
    const base64EncodedAudio = await blobToBase64(audio);
    const type = segmentTypeMappings[segmentType];

    changeTargets([segmentId], {
      shouldStartLanguageQE: false,
      loadingLanguageQEMetadata: true,
      shouldUpdateOriginalTranscription: false,
    });
    const response = await TaskResultController.getTargetQE(
      taskId,
      segmentId,
      JSON.stringify({ audio: base64EncodedAudio }),
      type,
      { length: audioLength, premium: isUnstructuredSpeechSegment(segmentType) }
    );
    pollForLiveQEResults({
      timeout: audioLength * 700, // 1000 * 70%
      pollCount: 1,
      segmentId,
      requestUUID: response.uuid,
      changeTargets,
      minLanguageQEScore,
      segmentType,
      minNumberOfEdits,
      maxSilencePercentage,
    });
  } catch (e) {
    Notifier.error(QE_ERROR_TEXT);
    await TelemetryController.sendTelemetry({
      message: e.message,
      stack: e.stack,
      pollCount: 0,
      userAgent: navigator.userAgent,
    });
    changeTargets([segmentId], {
      loadingLanguageQEMetadata: false,
      loadingLanguageQEMetadataFailed: true,
      validAudio: false,
      errorMessages: ['There was an error with processing this recording'],
      shouldUpdateOriginalTranscription: false,
    });
  }
};

const pollForLiveQEResults = async ({
  timeout,
  pollCount,
  segmentId,
  requestUUID,
  changeTargets,
  minLanguageQEScore,
  segmentType,
  minNumberOfEdits,
  maxSilencePercentage,
}) => {
  const MAX_TIMEOUT = 5000;
  const ONE_SECOND = 1000;
  const newTimeout = pollCount >= 1 ? Math.min(timeout + ONE_SECOND, MAX_TIMEOUT) : timeout;

  const MAX_POLL_COUNT = 50;
  if (pollCount > MAX_POLL_COUNT) {
    changeTargets([segmentId], {
      loadingLanguageQEMetadata: false,
      loadingLanguageQEMetadataFailed: true,
      validAudio: false,
      errorMessages: ['There was an error with processing this recording'],
      shouldUpdateOriginalTranscription: false,
    });
    await TelemetryController.sendTelemetry({
      message: 'Language QE polling timed out.',
      stack: '',
      pollCount,
      userAgent: navigator.userAgent,
    });
    return;
  }

  setTimeout(async () => {
    try {
      const responseStatus = await RequestProgressController.getStatus(requestUUID);
      if (responseStatus.completed) {
        const response = await RequestProgressController.getResponse(requestUUID);
        const score = calculateScore(response?.result.score);
        const transcription = response?.result.transcription;
        const duration = response?.result.duration;
        const totalSilence = response?.result.total_silence;

        const { validAudio, errorMessages: errorMessagesAudio } = validateAudioPostLanguageQE(
          score,
          duration,
          totalSilence,
          minLanguageQEScore,
          maxSilencePercentage
        );

        let validTranscription = true;
        let errorMessagesTranscription = [];
        if (validAudio && isUnstructuredSpeechSegment(segmentType)) {
          const validationResult = validateTranscription(transcription, {
            oldTranscription: transcription,
            minNumberOfEdits,
          });
          validTranscription = validationResult.validTranscription;
          errorMessagesTranscription = validationResult.errorMessages;
        }

        changeTargets([segmentId], {
          languageQEMetadata: { ...response.result, score },
          loadingLanguageQEMetadata: false,
          validAudio,
          errorMessages: [...errorMessagesAudio, ...errorMessagesTranscription],
          transcription,
          validTranscription,
          shouldUpdateOriginalTranscription: true,
        });
      } else {
        pollForLiveQEResults({
          timeout: newTimeout,
          pollCount: pollCount + 1,
          segmentId,
          requestUUID,
          changeTargets,
          minLanguageQEScore,
          segmentType,
          minNumberOfEdits,
          maxSilencePercentage,
        });
      }
    } catch (e) {
      if (pollCount > 10) {
        changeTargets([segmentId], {
          loadingLanguageQEMetadata: false,
          loadingLanguageQEMetadataFailed: true,
          validAudio: false,
          errorMessages: ['There was an error with processing this recording'],
          shouldUpdateOriginalTranscription: false,
        });
      } else {
        await TelemetryController.sendTelemetry({
          message: e.message,
          stack: e.stack,
          pollCount,
          userAgent: navigator.userAgent,
        });
        pollForLiveQEResults({
          timeout: newTimeout,
          pollCount: pollCount + 1,
          segmentId,
          requestUUID,
          changeTargets,
          minLanguageQEScore,
          segmentType,
          minNumberOfEdits,
          maxSilencePercentage,
        });
      }
    }
  }, timeout);
};

const calculateScore = (wordErrorRate) => {
  let score = Infinity;

  if (!Utils.isEmpty(wordErrorRate)) {
    score = Math.round(100 - wordErrorRate * 100);
  }

  return score;
};

const validateAudioPostLanguageQE = (
  score,
  duration,
  totalSilence,
  minLanguageQEScore,
  maxSilencePercentage
) => {
  let errorMessages = [];
  let validAudio = true;
  if (score < minLanguageQEScore) {
    errorMessages.push('Your recording does not match the script.');
    validAudio = false;
  }
  const silencePercentage = (totalSilence * 1.0) / duration;
  if (isNaN(silencePercentage) || silencePercentage > maxSilencePercentage) {
    errorMessages.push(`Your recording contains too much silence.`);
    validAudio = false;
  }
  if (!validAudio) {
    errorMessages.push('Please try again.');
  }
  return { validAudio, errorMessages };
};
