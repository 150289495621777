import { array, boolean, date, number, object, string } from 'yup';
import CountryHelper from '../../../utils/CountryHelper';
import { yearOfBirthToAge } from '../../../utils/DateHelper';

export const getValidationSchema = (countryCode) =>
  object().shape({
    ...baseValidationRules,
    ...countrySpecificValidationRules[countryCode],
  });

const baseValidationRules = {
  gender: string().nullable().required('Gender is required'),
  birth_year: number()
    .nullable()
    .required('Year of birth is required')
    .min(1900, 'Please enter a valid year of birth')
    .max(2050, 'Please enter a valid year of birth')
    .test(
      'year',
      'You must be 20 y.o or older',
      (yearOfBirth) => yearOfBirthToAge(yearOfBirth) >= 20
    ),

  survey_device: string().nullable().required('Device is required'),
  contributed: string().nullable().required('Contribution frequency is required'),
  profession: string().required('Profession is required'),
  education: string().nullable().required('Highest education level is required'),

  computer_ownership: string().nullable().required('This field is required'),
  frequent_device: string().nullable().required('This field is required'),
  streaming_frequency: string().nullable().required('This field is required'),
  computer_frequency: string().nullable().required('This field is required'),
  mobile_frequency: string().nullable().required('This field is required'),

  native_language: string().nullable().required('Native language is required'),
  english_speaking_years: number()
    .nullable()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .min(1, 'Years of speaking English must be greather than 0')
    .max(199, 'Years of speaking English cannot be greather than 199')
    .required('Years of speaking English are required'),
  english_learning_method: string().nullable().required('Learning method is required'),

  current_residency_duration: string().nullable().required('Residency is required'),

  ancestry: array().nullable().required('Ancestry is required'),

  consent_1: boolean()
    .required('You must accept in order to continue')
    .oneOf([true], 'You must accept in order to continue'),
  consent_2: boolean()
    .required('You must accept in order to continue')
    .oneOf([true], 'You must accept in order to continue'),
  consent_3: boolean()
    .required('You must accept in order to continue')
    .oneOf([true], 'You must accept in order to continue'),
  consent_4: boolean()
    .required('You must accept in order to continue')
    .oneOf([true], 'You must accept in order to continue'),
  consent_5: boolean()
    .required('You must accept in order to continue')
    .oneOf([true], 'You must accept in order to continue'),
  sign_name: string().required('Your signature is required'),
  sign_date: date(),
  has_signed: boolean()
    .required('You must place your signature in order to continue')
    .oneOf([true], 'You must place your signature in order to continue'),
};

const countrySpecificValidationRules = {
  UK: {
    spoken_languages: array()
      .nullable()
      .required('Spoken languages are required')
      .test('hasEnglish', 'Spoken languages must contain English', (spokenLanguages) =>
        spokenLanguages?.includes('English')
      ),
    spoken_dialect: string().nullable().required('Spoken dialect is required'),
    encountered_dialect: string().nullable().required('Encountered dialect is required'),
    gb_residency_duration: number()
      .nullable()
      .min(1, 'Years of residency must be greater than 0')
      .max(199, 'Years of residency cannot be greather than 199')
      .typeError('Years of residency are required')
      .required('Years of residency are required'),
    ethnicity: string().nullable().required('Ethnicity is required'),
    ethnicity_free_form: string().nullable().default(''),
    post_code: string().required('Post code is required'),
  },
  US: {
    spoken_languages: array()
      .nullable()
      .required('Spoken languages are required')
      .test('hasEnglish', 'Spoken languages must contain English', (spokenLanguages) =>
        spokenLanguages?.includes('English')
      ),
    spoken_dialect: string().nullable().required('Spoken dialect is required'),
    encountered_dialect: string().nullable().required('Encountered dialect is required'),
    us_residency_duration: number()
      .nullable()
      .min(1, 'Years of residency must be greater than 0')
      .max(199, 'Years of residency cannot be greather than 199')
      .typeError('Years of residency are required')
      .required('Years of residency are required'),
    race: string().nullable().required('Race is required'),
    race_free_form: string().nullable().default(''),
    post_code: string()
      .required('Post code is required')
      .test(
        'is5digits',
        'The post code must be exactly 5 digits',
        (post_code) => post_code?.length === 5
      )
      .test(
        'isIllinois',
        "Sorry, but due to legal restrictions, we can't enroll speakers from Illinois for this project",
        (post_code) => {
          if (post_code?.length === 5) {
            return !(CountryHelper.findStateByZipCode(post_code).code === 'IL');
          } else {
            return true;
          }
        }
      )
      .test(
        'isTexas',
        "Sorry, but due to legal restrictions, we can't enroll speakers from Texas for this project",
        (post_code) => {
          if (post_code?.length === 5) {
            return !(CountryHelper.findStateByZipCode(post_code).code === 'TX');
          } else {
            return true;
          }
        }
      ),
    consent_6: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
    consent_7: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
    consent_8: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
    consent_9: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
    consent_10: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
  },
  AU: {
    languages_spoken: array()
      .nullable()
      .required('Spoken languages are required')
      .test('hasEnglish', 'Spoken languages must contain English', (spokenLanguages) =>
        spokenLanguages?.includes('English')
      ),
    dialect_environment_au: string().nullable().required('Spoken dialect is required'),
    dialect_self_au: string().nullable().required('Spoken dialect is required'),
    au_residency_duration: number()
      .nullable()
      .min(1, 'Years of residency must be greater than 0')
      .max(199, 'Years of residency cannot be greather than 199')
      .typeError('Years of residency are required')
      .required('Years of residency are required'),
    ancestry_write_in: string().nullable().default(''),
    post_code: string()
      .required('Post code is required')
      .trim()
      .matches(/^(0[289][0-9]{2})$|^([1-9][0-9]{3})$/, 'Post code must be valid'),
    consent_6: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
    consent_7: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
    consent_8: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
    consent_9: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
    consent_10: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
    consent_11: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
  },
  USUK: {
    residing_country: string().nullable().required('Residing country is required'),
    origin_country: string().nullable().required('Origin country is required'),
    english_learning_country: string().nullable().required('English learning country is required'),
    languages_spoken: array()
      .nullable()
      .required('Spoken languages are required')
      .test('hasEnglish', 'Spoken languages must contain English', (spokenLanguages) =>
        spokenLanguages?.includes('English')
      ),
    residency_duration: number()
      .nullable()
      .min(1, 'Years of residency must be greater than 0')
      .max(199, 'Years of residency cannot be greather than 199')
      .typeError('Years of residency are required')
      .required('Years of residency are required'),
    ancestry_write_in: string().nullable().default(''),
    post_code: string()
      .required('Post code is required')
      .trim()
      .test(
        'isIllinois',
        "Sorry, but due to legal restrictions, we can't enroll speakers from Illinois for this project",
        (post_code) => {
          if (post_code?.length === 5) {
            return !(CountryHelper.findStateByZipCode(post_code).code === 'IL');
          } else {
            return true;
          }
        }
      )
      .test(
        'isTexas',
        "Sorry, but due to legal restrictions, we can't enroll speakers from Texas for this project",
        (post_code) => {
          if (post_code?.length === 5) {
            return !(CountryHelper.findStateByZipCode(post_code).code === 'TX');
          } else {
            return true;
          }
        }
      )
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'The post code is invalid',
        test: function (post_code) {
          const residing_country = this.parent.residing_country;
          return CountryHelper.validatePostCode(post_code, residing_country);
        },
      }),
    consent_6: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
    consent_7: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
    consent_8: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
    consent_9: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
    consent_10: boolean()
      .required('You must accept in order to continue')
      .oneOf([true], 'You must accept in order to continue'),
  },
};
