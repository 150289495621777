import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PAGES } from '../..';

const TestLandingPage = ({ test, onStart, isSignedIn }) => {
  const location = useLocation();

  return (
    <div className='container mt-2'>
      <div className='row align-items-start'>
        <div className='col align-items-start justify-content-start'>
          <h1>{isSignedIn ? test?.name : 'Please sign in'}</h1>
          <p className='text-muted'>
            {isSignedIn ? (
              "Thanks for participating in this test! If you pass it, you'll get access to our HLP project starting soon. We'll notify the workers who passed the test in a few days after its submission"
            ) : (
              <>
                You have been invited by the admin to complete a test. Please{' '}
                <Link to={{ pathname: PAGES.SIGN_IN.path(), state: { from: location.pathname } }}>
                  sign in
                </Link>{' '}
                to proceed.
              </>
            )}
          </p>
          {isSignedIn && (
            <button type='button' className='btn btn-primary btn-lg' onClick={onStart}>
              Start the test
            </button>
          )}
        </div>
        <hr />
      </div>
    </div>
  );
};

export default TestLandingPage;
