import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';
import '../css/ScrollUpArrow.scss';

const VERTICAL_THRESHOLD = 400;

const ScrollUpArrow = () => {
  const [visible, setVisible] = useState(false);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const checkScrollTop = () => {
      if (window.scrollY > VERTICAL_THRESHOLD) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };
    window.addEventListener('scroll', checkScrollTop);

    return () => window.removeEventListener('scroll', checkScrollTop);
  }, []);

  return (
    <FontAwesomeIcon
      icon={faArrowCircleUp}
      onClick={scrollTop}
      className='scrollUpArrow'
      style={{ display: visible ? 'flex' : 'none' }}
    />
  );
};

export default ScrollUpArrow;
