import { TYPES } from '../actions/session';

export const session = (state = null, action = {}) => {
  switch (action.type) {
    case TYPES.USER_LOGIN:
      return action.session;
    case TYPES.USER_LOGOUT:
      return null;
    case TYPES.USER_SWITCH:
      return action.session;
    default:
      return state;
  }
};

export default session;
