import { get } from '../http';


const getUserDetails = async(code) =>{
  return get(`/api/payment/paypal/user/${code}`);
}

const PaypalController = {
  getUserDetails
};

export default PaypalController;
