import React, { useRef, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import WindowedSelect from 'react-windowed-select';

import RichTextEditor from '../RichTextEditor';
import { FormFieldFooter } from '../forms/FormFieldFooter';

const MessageBox = ({
  isOpen = false,
  onCancel,
  onSend,
  subject,
  content,
  onSubjectChange,
  onContentChange,
  loading,
  loadingOptions,
  headerText,
  minimized,
  setMinimized,
  recipients,
  onRecipientsChange,
  recipientOptions,
  errors,
}) => {
  // Focus the first input element each time the modal is opened
  const selectRef = useRef(null);
  const handleShow = () => {
    if (selectRef.current) {
      selectRef.current.focus();
    }
  };

  // Each time a bootstrap dialog opens, an overflow: hidden; style is added to the <body> tag
  // This makes the <body> tag unscrollable, so we need to remove the overflow attribute each time a dialog is opened
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = null;
    }
  }, [isOpen]);

  const handleMinimize = () => setMinimized(true);

  // Escape key handler for the select and the editor
  // Close the MessageBox then the escape is pressed
  const handleEscape = (e) => {
    const key = e.key || e.keyCode;
    if (key === 'Escape' || key === 'Esc' || key === 27) {
      onCancel();
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={onCancel}
      size='lg'
      backdrop={false}
      keyboard={!loading}
      onShow={handleShow}
      bsPrefix='modeless'
      enforceFocus={false}
    >
      {minimized ? (
        <Modal.Header closeButton>
          <Modal.Title className='w-100 my-0 minimized' onClick={() => setMinimized(false)}>
            {headerText}
          </Modal.Title>
        </Modal.Header>
      ) : (
        <div>
          <Modal.Header closeButton>
            <Modal.Title className='w-100 my-0'>{headerText}</Modal.Title>
            <div className='minimize-btn' onClick={handleMinimize}>
              <i className='fas fa-window-minimize'></i>
            </div>
          </Modal.Header>
          <div className={loading ? 'sphere whirl' : ''}>
            <Modal.Body>
              <div className='d-flex flex-column'>
                <div className='form-group'>
                  <WindowedSelect
                    ref={selectRef}
                    id='selectUsers'
                    placeholder='Recipients'
                    onChange={onRecipientsChange}
                    value={recipients}
                    isClearable
                    isSearchable
                    options={recipientOptions}
                    isMulti
                    isLoading={loadingOptions}
                    noResultsText='No users found'
                    menuPlacement='auto'
                    styles={{
                      valueContainer: (base) => ({
                        ...base,
                        maxHeight: '100px',
                        overflowY: 'auto',
                      }),
                      editorContainer: (base) => ({ ...base, zIndex: 0 }),
                      menu: (base) => ({ ...base, zIndex: 2 }),
                    }}
                    onKeyDown={handleEscape}
                  />
                  <FormFieldFooter error={errors.recipients} touched={!!errors.recipients} />
                </div>
                <div className='form-group'>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Subject'
                    value={subject}
                    onChange={onSubjectChange}
                  />
                  <FormFieldFooter error={errors.subject} touched={!!errors.subject} />
                </div>
                <div className='form-group' onKeyDown={handleEscape}>
                  <RichTextEditor
                    placeholder='Content'
                    value={content}
                    onChange={onContentChange}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className='d-flex flex-row'>
              <Button variant='danger' onClick={onCancel}>
                Close
              </Button>
              <Button variant='primary' onClick={onSend}>
                Send
              </Button>
            </Modal.Footer>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default MessageBox;
