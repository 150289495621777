import { get, post, put, remove } from '../http';

const BASE_URL = '/api/project';

const list = async (projectId) => {
  return get(`${BASE_URL}/${projectId}/role-notification`);
};

const create = async (projectId, body) => {
  return post(`${BASE_URL}/${projectId}/role-notification`, body);
};

const update = async (projectId, roleNotificationId, body) => {
  return put(`${BASE_URL}/${projectId}/role-notification/${roleNotificationId}`, body);
};

const deleteRoleNotification = async (projectId, roleNotificationId) => {
  return remove(`${BASE_URL}/${projectId}/role-notification/${roleNotificationId}`);
};

const test = async (projectId, roleNotificationId, userId) => {
  return get(`${BASE_URL}/${projectId}/role-notification/${roleNotificationId}/test/${userId}`);
};

const ProjectRoleNotificationsController = {
  list,
  create,
  update,
  delete: deleteRoleNotification,
  test,
};

export default ProjectRoleNotificationsController;
