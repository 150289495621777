import * as countries from 'countries-list';
import { postcodeValidator } from 'postcode-validator';

export default class CountryHelper {
  /* Returns an object of country properties e.g. name, capital etc */
  static getCountry(countryCode) {
    return countries.countries[countryCode];
  }

  /* Returns an array of objects. Each object contains a `value` key whose value is
   * the country code and a `label` key whose value is the country name.
   * */
  static getCountryList() {
    const countryCodeKeys = Object.keys(countries.countries);

    return countryCodeKeys.map((code) => ({
      value: code,
      label: countries.countries[code].name,
    }));
  }

  /* Returns an array of objects. Each object contains a `value` key whose value is
   * the language code and a `label` key whose value is the language name.
   * */
  static getLanguageList() {
    const languageCodeKeys = Object.keys(countries.languages);

    return languageCodeKeys.map((code) => ({
      value: code,
      label: countries.languages[code].name,
    }));
  }

  /* Return the vatStatus based on the country code */
  static setVatStatus(countryCode) {
    return countryCode === 'NL' ? 'NL' : this.isEuropean(countryCode) ? 'EU' : 'NON_EU';
  }

  /* Checks whether a country belongs to EU based on its code
   * and returns true otherwise it returns false
   */
  static isEuropean(countryCode) {
    // TODO retrieve european countries from Treasurer API
    let europeanCountries = [
      'AT',
      'BE',
      'BG',
      'CY',
      'CZ',
      'DE',
      'DK',
      'EE',
      'ES',
      'FI',
      'FR',
      'GR',
      'HR',
      'HU',
      'IE',
      'IT',
      'LT',
      'LU',
      'LV',
      'MT',
      'NL',
      'PL',
      'PT',
      'RO',
      'SE',
      'SI',
      'SK',
    ];
    return europeanCountries.includes(countryCode);
  }

  /* Return the transferWise transaction type as a string (e.g. iban) based on the selected country code */
  static setTransferWiseTransactionType(supportedCountries, countryCode) {
    const supportedTypes = supportedCountries
      ?.filter((country) => country?.countryCode === countryCode)
      ?.map((type) => {
        return type.type;
      });
    return supportedTypes;
  }

  static setTransferWiseTransactionTypeOptions(options) {
    const formattedOptions = options.map((option) => {
      let nameParts = '';
      option.split('_').forEach((part) => {
        nameParts += part.charAt(0).toUpperCase() + part.slice(1) + ' ';
      });
      return { key: option, name: nameParts };
    });
    return formattedOptions;
  }

  static setIbanSupportedLength(countryCode, ibanCountries) {
    return ibanCountries?.find((country) => country?.countryCode === countryCode)?.length || null;
  }

  static findStateByZipCode = (zipString) => {
    /* Ensure we don't parse strings starting with 0 as octal values */
    const zipcode = parseInt(zipString, 10);

    if (zipcode >= 35000 && zipcode <= 36999) {
      return {
        code: 'AL',
        name: 'Alabama',
      };
    } else if (zipcode >= 99500 && zipcode <= 99999) {
      return {
        code: 'AK',
        name: 'Alaska',
      };
    } else if (zipcode >= 85000 && zipcode <= 86999) {
      return {
        code: 'AZ',
        name: 'Arizona',
      };
    } else if (zipcode >= 71600 && zipcode <= 72999) {
      return {
        code: 'AR',
        name: 'Arkansas',
      };
    } else if (zipcode >= 90000 && zipcode <= 96699) {
      return {
        code: 'CA',
        name: 'California',
      };
    } else if (zipcode >= 80000 && zipcode <= 81999) {
      return {
        code: 'CO',
        name: 'Colorado',
      };
    } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
      return {
        code: 'CT',
        name: 'Connecticut',
      };
    } else if (zipcode >= 19700 && zipcode <= 19999) {
      return {
        code: 'DE',
        name: 'Delaware',
      };
    } else if (zipcode >= 32000 && zipcode <= 34999) {
      return {
        code: 'FL',
        name: 'Florida',
      };
    } else if ((zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999)) {
      return {
        code: 'GA',
        name: 'Georgia',
      };
    } else if (zipcode >= 96700 && zipcode <= 96999) {
      return {
        code: 'HI',
        name: 'Hawaii',
      };
    } else if (zipcode >= 83200 && zipcode <= 83999) {
      return {
        code: 'ID',
        name: 'Idaho',
      };
    } else if (zipcode >= 60000 && zipcode <= 62999) {
      return {
        code: 'IL',
        name: 'Illinois',
      };
    } else if (zipcode >= 46000 && zipcode <= 47999) {
      return {
        code: 'IN',
        name: 'Indiana',
      };
    } else if (zipcode >= 50000 && zipcode <= 52999) {
      return {
        code: 'IA',
        name: 'Iowa',
      };
    } else if (zipcode >= 66000 && zipcode <= 67999) {
      return {
        code: 'KS',
        name: 'Kansas',
      };
    } else if (zipcode >= 40000 && zipcode <= 42999) {
      return {
        code: 'KY',
        name: 'Kentucky',
      };
    } else if (zipcode >= 70000 && zipcode <= 71599) {
      return {
        code: 'LA',
        name: 'Louisiana',
      };
    } else if (zipcode >= 3900 && zipcode <= 4999) {
      return {
        code: 'ME',
        name: 'Maine',
      };
    } else if (zipcode >= 20600 && zipcode <= 21999) {
      return {
        code: 'MD',
        name: 'Maryland',
      };
    } else if ((zipcode >= 1000 && zipcode <= 2799) || zipcode === 5501 || zipcode === 5544) {
      return {
        code: 'MA',
        name: 'Massachusetts',
      };
    } else if (zipcode >= 48000 && zipcode <= 49999) {
      return {
        code: 'MI',
        name: 'Michigan',
      };
    } else if (zipcode >= 55000 && zipcode <= 56899) {
      return {
        code: 'MN',
        name: 'Minnesota',
      };
    } else if (zipcode >= 38600 && zipcode <= 39999) {
      return {
        code: 'MS',
        name: 'Mississippi',
      };
    } else if (zipcode >= 63000 && zipcode <= 65999) {
      return {
        code: 'MO',
        name: 'Missouri',
      };
    } else if (zipcode >= 59000 && zipcode <= 59999) {
      return {
        code: 'MT',
        name: 'Montana',
      };
    } else if (zipcode >= 27000 && zipcode <= 28999) {
      return {
        code: 'NC',
        name: 'North Carolina',
      };
    } else if (zipcode >= 58000 && zipcode <= 58999) {
      return {
        code: 'ND',
        name: 'North Dakota',
      };
    } else if (zipcode >= 68000 && zipcode <= 69999) {
      return {
        code: 'NE',
        name: 'Nebraska',
      };
    } else if (zipcode >= 88900 && zipcode <= 89999) {
      return {
        code: 'NV',
        name: 'Nevada',
      };
    } else if (zipcode >= 3000 && zipcode <= 3899) {
      return {
        code: 'NH',
        name: 'New Hampshire',
      };
    } else if (zipcode >= 7000 && zipcode <= 8999) {
      return {
        code: 'NJ',
        name: 'New Jersey',
      };
    } else if (zipcode >= 87000 && zipcode <= 88499) {
      return {
        code: 'NM',
        name: 'New Mexico',
      };
    } else if (
      (zipcode >= 10000 && zipcode <= 14999) ||
      zipcode === 6390 ||
      zipcode === 501 ||
      zipcode === 544
    ) {
      return {
        code: 'NY',
        name: 'New York',
      };
    } else if (zipcode >= 43000 && zipcode <= 45999) {
      return {
        code: 'OH',
        name: 'Ohio',
      };
    } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999)) {
      return {
        code: 'OK',
        name: 'Oklahoma',
      };
    } else if (zipcode >= 97000 && zipcode <= 97999) {
      return {
        code: 'OR',
        name: 'Oregon',
      };
    } else if (zipcode >= 15000 && zipcode <= 19699) {
      return {
        code: 'PA',
        name: 'Pennsylvania',
      };
    } else if (zipcode >= 300 && zipcode <= 999) {
      return {
        code: 'PR',
        name: 'Puerto Rico',
      };
    } else if (zipcode >= 2800 && zipcode <= 2999) {
      return {
        code: 'RI',
        name: 'Rhode Island',
      };
    } else if (zipcode >= 29000 && zipcode <= 29999) {
      return {
        code: 'SC',
        name: 'South Carolina',
      };
    } else if (zipcode >= 57000 && zipcode <= 57999) {
      return {
        code: 'SD',
        name: 'South Dakota',
      };
    } else if (zipcode >= 37000 && zipcode <= 38599) {
      return {
        code: 'TN',
        name: 'Tennessee',
      };
    } else if (
      (zipcode >= 75000 && zipcode <= 79999) ||
      (zipcode >= 73301 && zipcode <= 73399) ||
      (zipcode >= 88500 && zipcode <= 88599)
    ) {
      return {
        code: 'TX',
        name: 'Texas',
      };
    } else if (zipcode >= 84000 && zipcode <= 84999) {
      return {
        code: 'UT',
        name: 'Utah',
      };
    } else if (zipcode >= 5000 && zipcode <= 5999) {
      return {
        code: 'VT',
        name: 'Vermont',
      };
    } else if (
      (zipcode >= 20100 && zipcode <= 20199) ||
      (zipcode >= 22000 && zipcode <= 24699) ||
      zipcode === 20598
    ) {
      return {
        code: 'VA',
        name: 'Virginia',
      };
    } else if (
      (zipcode >= 20000 && zipcode <= 20099) ||
      (zipcode >= 20200 && zipcode <= 20599) ||
      (zipcode >= 56900 && zipcode <= 56999)
    ) {
      return {
        code: 'DC',
        name: 'Washington DC',
      };
    } else if (zipcode >= 98000 && zipcode <= 99499) {
      return {
        code: 'WA',
        name: 'Washington',
      };
    } else if (zipcode >= 24700 && zipcode <= 26999) {
      return {
        code: 'WV',
        name: 'West Virginia',
      };
    } else if (zipcode >= 53000 && zipcode <= 54999) {
      return {
        code: 'WI',
        name: 'Wisconsin',
      };
    } else if (zipcode >= 82000 && zipcode <= 83199) {
      return {
        code: 'WY',
        name: 'Wyoming',
      };
    } else {
      return {
        code: 'none',
        name: 'none',
      };
    }
  };

  static validatePostCode = async (code, country) => {
    return postcodeValidator(code, country);
  };
}
