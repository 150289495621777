import React, { useState } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import InputWithValidation from '../../../components/forms/InputWithValidation';
import SelectWithValidation from '../../../components/forms/SelectWithValidation';
import CheckboxWithValidation from '../../../components/forms/CheckboxWithValidation';
import UserProfile from '../../../models/UserProfile';
import { SPOKEN_LANGUAGE_LEVEL } from '../../../models/Language';
import { Col, Row } from 'reactstrap';
import '../../../css/elements/button.scss';

const formikOptions = {
  validationSchema: Yup.object().shape({
    isRegistration: Yup.boolean(),
    isOnboarding: Yup.boolean(),
    firstName: Yup.string()
      .min(2, 'Minimum 2 characters')
      .max(50, 'Maximum 50 characters')
      .required('First name is required')
      .nullable(),
    lastName: Yup.string()
      .min(2, 'Minimum 2 characters')
      .max(50, 'Maximum 50 characters')
      .required('Last name is required')
      .nullable(),
    email: Yup.string().email('The email is invalid').required('Email is required').nullable(),
    country: Yup.string().required('Country is required').nullable(),
    password: Yup.string()
      .min(8, 'Password must be a minimum of 8 characters')
      .max(30, 'Password must be a maximum of 30 characters')
      .matches(
        '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\\W)|(?=.*_))^[^ ]+$',
        'Password must contain at least one capital letter, a number, and a special character'
      )
      .when('isRegistration', {
        is: true,
        then: Yup.string().required('Password is required'),
        otherwise: Yup.string(),
      })
      .nullable(),
    matchingPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords do not match')
      .when('isRegistration', {
        is: true,
        then: Yup.string().required('You must confirm your password'),
        otherwise: Yup.string(),
      })
      .nullable(),
    nativeLanguages: Yup.string().required('Native language is required'),
    termsAndConditions: Yup.boolean().when('isRegistration', {
      is: true,
      then: Yup.boolean().oneOf([true], 'You must accept the terms and conditions.'),
    }),
    privacyPolicy: Yup.boolean().when('isRegistration', {
      is: true,
      then: Yup.boolean().oneOf([true], 'You must accept the privacy policy.'),
    }),
    provenance: Yup.string()
      .trim()
      .min(4, 'Please enter valid origin.')
      .required('Provenance is required.'),
  }),

  mapPropsToValues: (props) => {
    const nativeLanguages = props?.profile?.spokenLanguages
      ?.filter((obj) => obj?.level === SPOKEN_LANGUAGE_LEVEL.NATIVE)
      .map((obj) => obj?.code);
    const advancedLanguages = props?.profile?.spokenLanguages
      ?.filter((obj) => obj?.level === SPOKEN_LANGUAGE_LEVEL.ADVANCED)
      .map((obj) => obj?.code);
    const intermediateLanguages = props?.profile?.spokenLanguages
      ?.filter((obj) => obj?.level === SPOKEN_LANGUAGE_LEVEL.INTERMEDIATE)
      .map((obj) => obj?.code);
    const elementaryLanguages = props?.profile?.spokenLanguages
      ?.filter((obj) => obj?.level === SPOKEN_LANGUAGE_LEVEL.ELEMENTARY)
      .map((obj) => obj?.code);

    return {
      firstName: props?.profile?.firstName ?? null,
      lastName: props?.profile?.lastName ?? null,
      password: null,
      matchingPassword: null,
      email: props?.profile?.email ?? null,
      country: props?.profile?.country ?? null,
      nativeLanguages: nativeLanguages ?? [],
      advancedLanguages: advancedLanguages ?? [],
      intermediateLanguages: intermediateLanguages ?? [],
      elementaryLanguages: elementaryLanguages ?? [],
      isRegistration: props.isRegistration ?? false,
      isOnboarding: props?.isOnboarding ?? false,
      termsAndConditions: false,
      privacyPolicy: false,
      provenance: props.referrer ? 'Referral' : props?.profile?.provenance,
    };
  },
};

function getSpokenLanguageAvailableOptions(allOptions = [], otherLevelSelectedCodes = []) {
  return allOptions?.filter((obj) => !otherLevelSelectedCodes.includes(obj.value)) ?? [];
}

const mapValuesToSpokenLanguages = (values = {}) => {
  const nativeLanguages =
    values?.nativeLanguages?.map((obj) => ({ code: obj, level: SPOKEN_LANGUAGE_LEVEL.NATIVE })) ??
    [];
  const advancedLanguages =
    values?.advancedLanguages?.map((obj) => ({
      code: obj,
      level: SPOKEN_LANGUAGE_LEVEL.ADVANCED,
    })) ?? [];
  const intermediateLanguages =
    values?.intermediateLanguages?.map((obj) => ({
      code: obj,
      level: SPOKEN_LANGUAGE_LEVEL.INTERMEDIATE,
    })) ?? [];
  const elementaryLanguages =
    values?.elementaryLanguages?.map((obj) => ({
      code: obj,
      level: SPOKEN_LANGUAGE_LEVEL.ELEMENTARY,
    })) ?? [];

  return [
    ...nativeLanguages,
    ...advancedLanguages,
    ...intermediateLanguages,
    ...elementaryLanguages,
  ];
};

const mapValuesToRegistrationData = (values = {}) => {
  const spokenLanguages = mapValuesToSpokenLanguages(values) ?? [];
  return {
    email: values?.email ?? null,
    firstName: values?.firstName ?? null,
    lastName: values?.lastName ?? null,
    country: values?.country ?? null,
    password: values?.password ?? null,
    matchingPassword: values?.matchingPassword ?? null,
    spokenLanguages: spokenLanguages ?? [],
    gtcConsent: values?.termsAndConditions ?? false,
    pcpConsent: values?.privacyPolicy ?? false,
    provenance: values?.provenance === 'Referral' ? '' : values?.provenance,
  };
};

const mapValuesToUpdateData = (values = {}) => {
  const spokenLanguages = mapValuesToSpokenLanguages(values) ?? [];
  return {
    firstName: values?.firstName ?? null,
    lastName: values?.lastName ?? null,
    country: values?.country ?? null,
    spokenLanguages: spokenLanguages ?? [],
    provenance: values?.provenance ?? null,
  };
};

const PersonalProfileFormView = ({
  profile = null,
  languages = [],
  isLoading = false,
  referrer = null,
  isRegistration = true,
  isOnboarding = false,
  onSave = null,
  onCancel = null,
  ...props
}) => {
  const provenanceOptions = [
    { label: 'Indeed', value: 'Indeed' },
    { label: 'LinkedIn', value: 'LinkedIn' },
    { label: 'Social Media', value: 'Social Media' },
    { label: 'Join', value: 'Join' },
    { label: 'University job board', value: 'University job board' },
    { label: 'HLP website', value: 'HLP website' },
    { label: 'Others', value: 'Others' },
  ];

  const [showAllErrors, setShowAllErrors] = useState(false);
  const [isOtherProvenance, setIsOtherProvenance] = useState(() => {
    if (referrer) return false;
    if (!props?.values?.provenance || props?.values?.provenance === "UNKNOWN") return false;
    if (provenanceOptions.find((option) => option.value === props?.values?.provenance))
      return false;
    else return true;
  });

  const onSubmit = () => {
    props.setSubmitting(true);
    props.validateForm().then(() => {
      setShowAllErrors(true);
      props.setSubmitting(false);
      if (props.dirty && props.isValid && onSave) {
        const data = props.values?.isRegistration
          ? mapValuesToRegistrationData(props.values)
          : mapValuesToUpdateData(props.values);
        onSave(data);
      }
    });
  };

  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const showTermsBox = (e) => {
    e.preventDefault();
    setShowTerms(!showTerms);
  };

  const showPrivacyBox = (e) => {
    e.preventDefault();
    setShowPrivacy(!showPrivacy);
  };

  const countryOptions = Object.values(UserProfile.COUNTRIES).map((obj) => ({
    value: obj.name,
    label: obj.name,
  }));
  const countrySelected = countryOptions.find((obj) => obj.value === props?.values?.country);

  const provenanceSelected = referrer ? { label: 'Referral', value: 'Referral' } : props?.values?.provenance
    ? provenanceOptions.find((obj) => obj.value === props?.values?.provenance)
    : null;

  const provenanceChange = (_, value) => {
    if (value === 'Others') {
      setIsOtherProvenance(true);
      props.setFieldValue('provenance', 'Others' ?? '');
    } else {
      setIsOtherProvenance(false);
      props.setFieldValue('provenance', value ?? '');
    }
  };

  const languagesOptions = languages
    .map((element) => ({ value: element.code, label: element.name }))
    .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

  const nativeOptions = getSpokenLanguageAvailableOptions(languagesOptions, [
    ...props?.values?.advancedLanguages,
    ...props?.values?.intermediateLanguages,
    ...props?.values?.elementaryLanguages,
  ]);
  const advancedOptions = getSpokenLanguageAvailableOptions(languagesOptions, [
    ...props?.values?.nativeLanguages,
    ...props?.values?.intermediateLanguages,
    ...props?.values?.elementaryLanguages,
  ]);
  const intermediateOptions = getSpokenLanguageAvailableOptions(languagesOptions, [
    ...props?.values?.nativeLanguages,
    ...props?.values?.advancedLanguages,
    ...props?.values?.elementaryLanguages,
  ]);
  const elementaryOptions = getSpokenLanguageAvailableOptions(languagesOptions, [
    ...props?.values?.nativeLanguages,
    ...props?.values?.advancedLanguages,
    ...props?.values?.intermediateLanguages,
  ]);

  const nativeSelected = nativeOptions.filter(
    (obj) => props?.values?.nativeLanguages?.includes(obj?.value) ?? false
  );
  if (nativeSelected.length > 0) {
    nativeSelected[0].label = 'Native/Bilingual: ' + nativeSelected[0].label;
  }

  const advancedSelected = advancedOptions.filter(
    (obj) => props?.values?.advancedLanguages?.includes(obj?.value) ?? false
  );
  if (advancedSelected.length > 0) {
    advancedSelected[0].label = 'Advanced: ' + advancedSelected[0].label;
  }

  const intermediateSelected = intermediateOptions.filter(
    (obj) => props?.values?.intermediateLanguages?.includes(obj?.value) ?? false
  );
  if (intermediateSelected.length > 0) {
    intermediateSelected[0].label = 'Intermediate: ' + intermediateSelected[0].label;
  }

  const elementarySelected = elementaryOptions.filter(
    (obj) => props?.values?.elementaryLanguages?.includes(obj?.value) ?? false
  );
  if (elementarySelected.length > 0) {
    elementarySelected[0].label = 'Elementary: ' + elementarySelected[0].label;
  }

  const onLanguageChange = (field, value) => props.setFieldValue(field, value ?? []);

  const getApiErrors = (fieldName) => {
    return props?.apiErrors?.[fieldName]?.join(' ');
  };

  return (
    <div className={isLoading ? ' whirl sphere' : ''}>
      {isRegistration ? null : (
        <div className='box-action'>
          <span className='close-button' onClick={onCancel}></span>
        </div>
      )}
      <div>
        <Col className='mx-auto box'>
          <Row className='mb-3 mt-2'>
            <Col>
              <span
                style={{
                  fontSize: '18px',
                  lineHeight: '1.56',
                  letterSpacing: '0.3px',
                  color: '#1c1c28',
                  fontWeight: '500',
                }}
              >
                Personal details
              </span>
            </Col>
          </Row>
          <Row>
            <Col className='mb-3'>
              <InputWithValidation
                id={'firstName'}
                label={''}
                propertyName={'firstName'}
                placeholder={'First Name'}
                className={'taus-input'}
                defaultValue={props.values.firstName ? props.values.firstName : ''}
                touched={props.touched.firstName || showAllErrors}
                error={props.errors.firstName}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
              />
            </Col>
          </Row>
          <Row>
            <Col className='mb-3'>
              <InputWithValidation
                id='lastName'
                label={''}
                propertyName={'lastName'}
                placeholder={'Last Name'}
                className={'taus-input'}
                defaultValue={props.values.lastName ? props.values.lastName : ''}
                touched={props.touched.lastName || showAllErrors}
                error={props.errors.lastName}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
              />
            </Col>
          </Row>
          <Row>
            <Col className='mb-3'>
              <InputWithValidation
                id='email'
                disabled={!props.values.isRegistration}
                label={''}
                propertyName={'email'}
                placeholder={'Email'}
                className={'taus-input'}
                defaultValue={props.values.email ? props.values.email : ''}
                touched={props.touched.email || showAllErrors}
                error={props.errors.email}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                autoComplete='email'
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectWithValidation
                label={''}
                propertyName={'country'}
                options={countryOptions}
                placeholder={'Country of residence'}
                className={'taus-select'}
                defaultOption={countrySelected}
                error={props.errors.country}
                touched={props.touched.country || showAllErrors}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
              />
            </Col>
          </Row>
          {isRegistration && (
            <div>
              <Row>
                <Col className='mb-3'>
                  <InputWithValidation
                    id='password'
                    label=''
                    type='password'
                    propertyName={'password'}
                    placeholder='Password'
                    className='taus-input'
                    defaultValue={props.values.password ?? ''}
                    touched={props.touched.password || showAllErrors}
                    error={props.errors.password || getApiErrors('password')}
                    onChange={(...args) => {
                      props.setFieldValue(...args);
                      props.clearApiErrors('password');
                    }}
                    onBlur={props.setFieldTouched}
                    autoComplete='new-password'
                  />
                </Col>
              </Row>
              <Row>
                <Col className='mb-3'>
                  <InputWithValidation
                    id='matchingPassword'
                    label=''
                    type='password'
                    propertyName={'matchingPassword'}
                    placeholder='Confirm Password'
                    className='taus-input'
                    defaultValue={
                      props.values.matchingPassword ? props.values.matchingPassword : ''
                    }
                    touched={props.touched.matchingPassword || showAllErrors}
                    error={props.errors.matchingPassword}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                  />
                </Col>
              </Row>
            </div>
          )}
          <Row>
            <Col>
              <SelectWithValidation
                label={''}
                propertyName={'provenance'}
                options={provenanceOptions}
                placeholder={'How did you hear about us?'}
                className={'taus-select'}
                defaultOption={provenanceSelected}
                error={props.errors.provenance}
                touched={props.touched.provenance || showAllErrors}
                onChange={provenanceChange}
                onBlur={props.setFieldTouched}
                isDisabled={!!referrer}
              />
            </Col>
          </Row>
          {isOtherProvenance && (
            <Row className='tw-mb-4'>
              <Col>
                <InputWithValidation
                  id={'OtherProvenance'}
                  label={''}
                  propertyName={'provenance'}
                  placeholder={'If "others" please specify'}
                  className={'taus-input'}
                  defaultValue={props.values.provenance === 'Others' ? '' : props.values.provenance}
                  touched={props.touched.provenance || showAllErrors}
                  error={props.errors.provenance}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                />
              </Col>
            </Row>
          )}
          <Row className='mb-3 mt-3'>
            <Col md='12'>
              <span
                style={{
                  fontSize: '18px',
                  lineHeight: '1.56',
                  letterSpacing: '0.3px',
                  color: '#1c1c28',
                  fontWeight: '500',
                }}
              >
                Languages
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectWithValidation
                label={''}
                propertyName={'nativeLanguages'}
                options={nativeOptions}
                defaultOption={nativeSelected}
                placeholder={'Native or Bilingual Proficiency'}
                className={'taus-select'}
                error={props.errors.nativeLanguages}
                touched={props.touched.nativeLanguages || showAllErrors}
                onChange={onLanguageChange}
                onBlur={props.setFieldTouched}
                isMulti={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectWithValidation
                label={''}
                propertyName={'advancedLanguages'}
                options={advancedOptions}
                defaultOption={advancedSelected}
                placeholder={'Advanced Proficiency'}
                className={'taus-select'}
                error={props.errors.advancedLanguages}
                touched={props.touched.advancedLanguages || showAllErrors}
                onChange={onLanguageChange}
                onBlur={props.setFieldTouched}
                isMulti={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectWithValidation
                label={''}
                propertyName={'intermediateLanguages'}
                options={intermediateOptions}
                defaultOption={intermediateSelected}
                placeholder={'Intermediate Proficiency'}
                className={'taus-select'}
                error={props.errors.intermediateLanguages}
                touched={props.touched.intermediateLanguages || showAllErrors}
                onChange={onLanguageChange}
                onBlur={props.setFieldTouched}
                isMulti={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectWithValidation
                label={''}
                propertyName={'elementaryLanguages'}
                options={elementaryOptions}
                defaultOption={elementarySelected}
                placeholder={'Elementary Proficiency'}
                className={'taus-select'}
                error={props.errors.elementaryLanguages}
                touched={props.touched.elementaryLanguages || showAllErrors}
                onChange={onLanguageChange}
                onBlur={props.setFieldTouched}
                isMulti={true}
                menuIsOpen={true}
              />
            </Col>
          </Row>
          {isRegistration ? (
            <Row>
              <Col md='12'>
                <CheckboxWithValidation
                  label={
                    <span style={{ display: 'contents' }}>
                      I agree to the{' '}
                      <a href='#' onClick={showTermsBox}>
                        TAUS Terms of Use
                      </a>
                      .
                    </span>
                  }
                  propertyName={'termsAndConditions'}
                  defaultValue={
                    props.values.termsAndConditions ? props.values.termsAndConditions : false
                  }
                  error={props.errors.termsAndConditions}
                  touched={props.touched.termsAndConditions || showAllErrors}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                />
              </Col>
              <Col md='12'>
                <CheckboxWithValidation
                  label={
                    <span style={{ display: 'contents' }}>
                      I agree to the use of my personal data as per{' '}
                      <a href='#' onClick={showPrivacyBox}>
                        TAUS Privacy & Cookies Policy
                      </a>
                      .
                    </span>
                  }
                  propertyName={'privacyPolicy'}
                  defaultValue={props.values.privacyPolicy ? props.values.privacyPolicy : false}
                  error={props.errors.privacyPolicy}
                  touched={props.touched.privacyPolicy || showAllErrors}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                />
              </Col>
            </Row>
          ) : null}
          {showTerms && isRegistration ? (
            <Row>
              <Col md='12'>
                <iframe
                  title={'TAUS HLP Worker Agreement'}
                  width={'100%'}
                  height={'400'}
                  src={
                    'https://taus-hlp-terms.s3.eu-west-1.amazonaws.com/HLP_Worker_Agreement_NL.pdf'
                  }
                  style={{ border: '1px solid #eee', borderRadius: '4px' }}
                />
              </Col>
            </Row>
          ) : null}
          {showPrivacy && isRegistration ? (
            <Row>
              <Col md='12'>
                <iframe
                  title={'TAUS HLP Worker Agreement'}
                  width={'100%'}
                  height={'400'}
                  src={
                    'https://taus-hlp-terms.s3.eu-west-1.amazonaws.com/HLP_Privacy_and_Cookies_Policy.pdf'
                  }
                  style={{ border: '1px solid #eee', borderRadius: '4px' }}
                />
              </Col>
            </Row>
          ) : null}
        </Col>
      </div>
      <Row style={{ marginTop: '30px' }}>
        <Col md='12' className='row justify-content-end mx-auto'>
            <>
              {(!isOnboarding && !isRegistration) &&
              (<button
                key='cancel'
                type='button'
                className='float-right taus-btn-secondary mr-4'
                style={{ width: '120px' }}
                onClick={onCancel}
              >
                Cancel
              </button>
              )}
              <button
                key='save'
                type='button'
                className={`float-right taus-btn-primary ${!props.dirty ? 'disabled' : ''}`}
                style={{ width: '120px' }}
                onClick={onSubmit}
              >
                {isRegistration ? 'Register' : 'Save'}
              </button>
            </>
        </Col>
      </Row>
    </div>
  );
};

export default withFormik(formikOptions)(PersonalProfileFormView);
