import { put } from '../http';

const BASE_URL = '/api/admin/task/result';

const resubmit = async (id, data) => {
  if (!id) {
    throw new Error('Invalid task id (missing)');
  }
  return put(`${BASE_URL}/${id}/resubmit`, data);
};

const TaskResultController = {
  resubmit,
};

export default TaskResultController;
