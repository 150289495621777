import React, { useState } from 'react';
import { Button, Collapse, Label } from 'reactstrap';
import { FormFieldError } from '../../../components/forms/FormFieldError';
import TagTable from './TagTable';

export const ROOT = 0;

const getFirstLevelNodes = (tags) => {
  // Filter out the dummy ROOT node
  const nodes = Object.keys(tags).filter((key) => key !== ROOT.toString());
  const nonFirstLevelNodes = new Set(nodes.map((node) => tags[node].children).flat(1));
  return nodes.filter((node) => !nonFirstLevelNodes.has(node));
};

const TagBuilder = ({ tagDefinitions = {}, error, onChange, onSave }) => {
  const setTags = (tags) => {
    // Connect the first level nodes to the dummy ROOT node
    tags[ROOT] = { children: getFirstLevelNodes(tags), value: ROOT, label: 'Dummy root node' };
    onChange('tagDefinitions', tags);
  };

  const handleDeleteTag = (value) => {
    const newTags = {};
    Object.keys(tagDefinitions)
      .filter((key) => key !== value)
      .forEach((key) => {
        newTags[key] = {
          ...tagDefinitions[key],
          children: tagDefinitions[key].children.filter((id) => id !== value),
        };
      });
    setTags(newTags);
  };

  const handleUpdateTag = (changedKey, changedValues) => {
    const newTags = {};
    Object.keys(tagDefinitions).forEach((key) => {
      if (key === changedKey) {
        newTags[key] = {
          ...tagDefinitions[key],
          ...changedValues,
        };
      } else {
        newTags[key] = {
          ...tagDefinitions[key],
        };
      }
    });
    setTags(newTags);
  };

  const handleAddTag = (tag) => {
    if (!!tagDefinitions[tag.value]) {
      return;
    }
    setTags({
      ...tagDefinitions,
      [tag.value]: tag,
    });
  };

  const [open, setOpen] = useState(false);
  return (
    <div className='mb-3'>
      <Label>Tag builder</Label>
      <Button className='ml-2' color='primary' onClick={() => setOpen((prevOpen) => !prevOpen)}>
        Show Tags
      </Button>
      <Collapse isOpen={open}>
        <TagTable
          tagDefinitions={tagDefinitions}
          rootKey={ROOT}
          tags={Object.keys(tagDefinitions)
            .filter((id) => id !== ROOT.toString())
            .map((id) => tagDefinitions[id])}
          onDeleteTag={handleDeleteTag}
          onUpdateTag={handleUpdateTag}
          onAddTag={handleAddTag}
        />
        <button className='float-right btn btn-outline-primary' type='button' onClick={onSave}>
          Save
        </button>
      </Collapse>
      {error && <FormFieldError error={error} touched />}
    </div>
  );
};

export default TagBuilder;
