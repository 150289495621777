import React from 'react';
import moment from 'moment';

import Utils from '../../utils/Utils';
import { Text } from '../parameters/TextParameter';

try {
  moment.locale(window.navigator.language);
} catch (e) {}

// --------------------------------------------- Text Formatters
export const TextFormatter = (data) => {
  return Utils.define(data?.value, '');
};

export const TextEditorFormatter = (data) => {
  return (
    <Text
      key={data?.row?.id}
      defaultValue={Utils.define(data?.value, '')}
      onChange={(value) => {
        data.onValueChange(value);
      }}
    />
  );
};

export const DateTimeFormatter = (data) => {
  return moment(data?.value).format('YYYY-MM-DD HH:mm');
};
