import { get, post, put, remove } from '../http';
import { objectToQueryString } from '../utils/Url';

const BASE_URL = '/api/project';

const list = async (projectId, parameters) => {
  return get(
    `${BASE_URL}/${projectId}/campaign` + (parameters ? `?${objectToQueryString(parameters)}` : '')
  );
};

const create = async (projectId, body) => {
  return post(`${BASE_URL}/${projectId}/campaign`, body);
};

const update = async (projectId, campaignId, body) => {
  return put(`${BASE_URL}/${projectId}/campaign/${campaignId}`, body);
};

const deleteCampaign = async (projectId, campaignId) => {
  return remove(`${BASE_URL}/${projectId}/campaign/${campaignId}`);
};

const ProjectCampaignController = {
  list,
  create,
  update,
  delete: deleteCampaign,
};

export default ProjectCampaignController;
