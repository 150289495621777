import React from 'react';
import { createDescription } from '../../../../utils/SpeechRecording';

import SourceSegment from '../SourceSegment';
import TextToSpeechReviewTargetSegment from './TextToSpeechReviewTargetSegment';

const TextToSpeechReviewSegment = ({
  segment,
  task,
  isLoading,
  isReviewable,
  handleApprove,
  handleReject,
  audio,
  sampleRate,
  transcription,
  validTranscription,
  onTranscriptionChanged,
  isChanged,
  errorMessages,
  segmentMetadata,
  languageQEMetadata,
  isAdmin,
  onAudioChanged,
  validAudio
}) => {
  const description = createDescription(segmentMetadata?.description);

  return (
    <div className='d-flex flex-wrap justify-content-between align-items-start mb-5 pb-5 border-bottom'>
      <SourceSegment
        className='taus-source-segment-single pb-3 pr-sm-3 col-12-xs col-sm-6'
        segment={segment}
        task={task}
        description={description}
      />
      <TextToSpeechReviewTargetSegment
        segment={segment}
        isLoading={isLoading}
        isReviewable={isReviewable}
        handleApprove={handleApprove}
        handleReject={handleReject}
        audio={audio}
        sampleRate={sampleRate}
        transcription={transcription}
        validTranscription={validTranscription}
        onTranscriptionChanged={onTranscriptionChanged}
        isChanged={isChanged}
        errorMessages={errorMessages}
        segmentMetadata={segmentMetadata}
        languageQEMetadata={languageQEMetadata}
        isAdmin={isAdmin}
        onAudioChanged={onAudioChanged}
        validAudio={validAudio}
      />
    </div>
  );
};

export default TextToSpeechReviewSegment;
