import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormFieldFooter } from '../../../components/forms/FormFieldFooter';
// noinspection ES6CheckImport
import SelectWithValidation from '../../../components/forms/SelectWithValidation';
import InputWithValidation from '../../../components/forms/InputWithValidation';
import TextWithIcon from '../components/TextWithIcon';
import Tooltip from '../../../components/Tooltip';
import { Form } from 'react-bootstrap';
import RichTextEditor from '../../../components/RichTextEditor';
import { editorStateToHtml, htmlToEditorState } from '../../../utils/RichTextEditor';

const validationSchema = Yup.object().shape({
  permissionRoleId: Yup.number().required('You must select a role!').nullable(),
  subject: Yup.string().required("Please type the email's subject!").nullable(),
  body: Yup.object()
    .test(
      'has-body',
      "Please type the email's body!",
      (val) => !!val && val.getCurrentContent().getPlainText().length > 0
    )
    .required("Please type the email's body!")
    .nullable(),
});

const ProjectRoleNotificationsFormView = ({
  selectedRole,
  onSelectedRoleChange,
  roleNotification,
  roleOptions,
  onSubmit,
  onDelete,
}) => {
  const initialValues = {
    permissionRoleId: selectedRole?.value,
    subject: roleNotification?.subject ?? '',
    body: htmlToEditorState(roleNotification?.body),
  };

  const INSTRUCTIONS =
    'Select a role and enter an email subject and body to automate notifications for that role.' +
    'If the role you selected is already automated, the email fields will get prefilled and you will be able to either update them' +
    ' or delete them, thus disabling the automation.';

  const handleSubmit = ({ permissionRoleId, subject, body }) => {
    onSubmit({
      permissionRoleId,
      subject,
      body: editorStateToHtml(body),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        return (
          <Form onSubmit={props.handleSubmit}>
            <Tooltip title={INSTRUCTIONS}>
              <TextWithIcon
                size={3}
                text='Notifications'
                icon='fas fa-envelope'
                outerClassName='mt-5 mb-3'
                innerClassName='text-center'
                cursor='help'
              />
            </Tooltip>
            <SelectWithValidation
              propertyName='permissionRoleId'
              label='Role'
              defaultOption={selectedRole}
              options={roleOptions}
              error={props?.errors?.permissionRoleId}
              touched={props?.touched?.permissionRoleId}
              placeholder='Select a role...'
              helpText='The role we want to assign a notification for'
              onChange={(name, value) => {
                props.setFieldValue(name, value);
                onSelectedRoleChange(value);
              }}
              onBlur={props?.setFieldTouched}
            />
            <InputWithValidation
              type='text'
              propertyName='subject'
              label='Subject'
              defaultValue={props?.values.subject}
              placeholder='Type email subject'
              helpText='The subject as it will appear in the sent email'
              touched={props?.touched?.subject}
              error={props?.errors?.subject}
              onChange={props?.setFieldValue}
              onBlur={props?.setFieldTouched}
            />
            <RichTextEditor
              placeholder="Type the email's body. This will be sent with appended TAUS header and footer."
              value={props?.values.body}
              onChange={(editorState) => props.setFieldValue('body', editorState)}
              onBlur={() => props.setFieldTouched('body')}
            />
            <FormFieldFooter error={props.errors.body} touched={!!props.touched.body} />
            <div className='row justify-content-center  mt-2'>
              <div className='col'>
                <button
                  key='delete'
                  type='button'
                  color='danger'
                  onClick={onDelete}
                  className={'btn btn-outline-danger btn-block'}
                  disabled={!roleNotification}
                >
                  Delete
                </button>
              </div>
              <div className='col'>
                <button
                  key='save'
                  type='submit'
                  color='success'
                  className={'btn btn-outline-success btn-block'}
                >
                  {!!roleNotification ? 'Update' : 'Add'}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProjectRoleNotificationsFormView;
