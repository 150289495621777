import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Container } from 'react-bootstrap';
import * as Yup from 'yup';
import InputWithValidation from '../../../components/forms/InputWithValidation';
import ButtonSpinner from '../../../components/ButtonSpinner';

const UserAccountRecoverPageView = ({
  loading = false,
  errors = [],
  onSubmitButtonClick = null,
}) => {
  const initialValues = {
    password: '',
    matchingPassword: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .nullable()
      .min(8, 'Password must have at least 8 characters')
      .test(
        'regex',
        'Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase',
        (val) => {
          let regExp = new RegExp('^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$');
          return regExp.test(val);
        }
      )
      .required('Password is required'),
    matchingPassword: Yup.string()
      .nullable()
      .required('You must confirm your password')
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], 'Your passwords are not matching'),
      }),
  });

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmitButtonClick(values)}
      >
        {(props) => {
          return (
            <div className='tw-max-w-lg tw-p-6 tw-mx-auto tw-border tw-border-solid tw-border-gray-300 tw-rounded'>
              <h2 className='tw-text-black'>Reset Password</h2>
              <Form className='tw-grid tw-gap-4 tw-mt-10' onSubmit={props.handleSubmit}>
                <InputWithValidation
                  className='taus-input'
                  id='password'
                  propertyName='password'
                  label='Password'
                  type='password'
                  defaultValue={props.values.password}
                  touched={props.touched.password}
                  error={props.errors.password}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                />
                <InputWithValidation
                  className='taus-input'
                  id='matchingPassword'
                  propertyName='matchingPassword'
                  label='Confirm Password'
                  type='password'
                  defaultValue={props.values.matchingPassword}
                  touched={props.touched.matchingPassword}
                  error={props.errors.matchingPassword}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                />
                <Button
                  type='submit'
                  className='taus-btn-primary tw-flex tw-items-center'
                  disabled={loading}
                >
                  {loading ? (
                    <span>
                      Saving... <ButtonSpinner />
                    </span>
                  ) : (
                    <span>Save</span>
                  )}
                </Button>
                <span className='tw-text-red-700'>{errors}</span>
              </Form>
            </div>
          );
        }}
      </Formik>
    </Container>
  );
};
export default UserAccountRecoverPageView;
