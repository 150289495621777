import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import '../../css/forms/select.scss';
import { FormFieldFooter } from './FormFieldFooter';
import Utils from '../../utils/Utils';

const SelectWithValidation = ({
  id,
  label,
  error,
  onBlur,
  options,
  isMulti,
  touched,
  byLabel,
  helpText,
  onChange,
  className,
  isLoading,
  isDisabled,
  placeholder,
  isClearable,
  isSearchable,
  propertyName,
  onValueChange,
  defaultOption,
  noOptionsMessage,
}) => {
  const handleChange = (option) => {
    let value;

    if (!option) {
      value = null;
    } else if (byLabel) {
      if (option.length > 0) value = option.map((o) => o.label);
      else value = option.label;
    } else {
      if (option.length > 0) value = option.map((o) => o.value);
      else value = option.value;
    }

    onChange(propertyName, value);

    // If and extra callback is defined, call that as well
    if (onValueChange) onValueChange(value);
  };

  const handleBlur = () => {
    if (!Utils.isDefined(onBlur)) return;
    onBlur(propertyName, true);
  };

  const isInvalidClass = !!(error && touched) ? 'is-invalid' : '';
  let isValidClass = '';

  if (!!(!error && touched)) {
    // If touched field but returned no error then
    // Check if field is multiselect (therefore values will be array) and has at least one selected item
    if (Array.isArray(defaultOption) && defaultOption?.length > 0) {
      isValidClass = 'is-valid'; // If yes then mark field as valid
      // If not make sure field is not multiselect (therefore value will be string) and also check that the value is not undefined
    } else if (!Array.isArray(defaultOption) && defaultOption !== undefined) {
      isValidClass = 'is-valid'; // If above condition is true then mark field as valid
    }
  }

  return (
    <div className={`${className ? className : 'form-group'} mb-3`}>
      {label ? (
        <Label htmlFor={id ? id : 'frmSelect'} className='tw-mb-0'>
          {label}
        </Label>
      ) : null}
      <Select
        id={id ? id : 'frmSelect'}
        // If touched field and returned error then mark field as invalid
        className={`${isInvalidClass} ${isValidClass}`}
        classNamePrefix='taus-react-select'
        placeholder={placeholder}
        value={defaultOption}
        onChange={handleChange}
        onBlur={handleBlur}
        isLoading={isLoading}
        isClearable={isClearable ?? true}
        isSearchable={isSearchable ?? true}
        options={options}
        isDisabled={isDisabled}
        isOptionDisabled={(option) => !!option.disabled}
        isMulti={isMulti}
        noOptionsMessage={noOptionsMessage ? noOptionsMessage : () => 'No options'}
        styles={{
          menu: (base) => ({ ...base, zIndex: 110 }),
          menuList: (base) => ({
            ...base,
            zIndex: 130,
            maxHeight: '150px',
          }),
        }}
      />
      <FormFieldFooter helpText={helpText} error={error} touched={touched} />
    </div>
  );
};

export default SelectWithValidation;
