export const getInitialValues = (user, countryCode) => ({
  ...baseInitialValues(user),
  ...countrySpecificInitialValues(user, countryCode),
});

const baseInitialValues = (user) => ({
  gender: user?.extra?.gender ?? '',
  birth_year: user?.extra?.birth_year ?? '',
  survey_device: user?.extra?.survey_device ?? '',
  contributed: user?.extra?.contributed ?? '',
  profession: user?.extra?.profession ?? '',
  education: user?.extra?.education ?? '',

  computer_ownership: user?.extra?.computer_ownership ?? '',
  frequent_device: user?.extra?.frequent_device ?? '',
  streaming_frequency: user?.extra?.streaming_frequency ?? '',
  computer_frequency: user?.extra?.computer_frequency ?? '',
  mobile_frequency: user?.extra?.mobile_frequency ?? '',

  native_language: user?.extra?.native_language ?? '',
  english_speaking_years: parseInt(user?.extra?.english_speaking_years) ?? '',
  english_learning_method: user?.extra?.english_learning_method ?? '',

  current_residency_duration: user?.extra?.current_residency_duration ?? '',
  post_code: user?.extra?.post_code ?? '',

  ancestry: user?.extra?.ancestry ?? [],

  consent_1: !!user?.extra?.legal?.consent_1,
  consent_2: !!user?.extra?.legal?.consent_2,
  consent_3: !!user?.extra?.legal?.consent_3,
  consent_4: !!user?.extra?.legal?.consent_4,
  consent_5: !!user?.extra?.legal?.consent_5,
  sign_name: user?.extra?.legal?.sign_name ?? '',
  sign_date: user?.extra?.legal?.sign_date ? new Date(user.extra.legal.sign_date) : new Date(),
  has_signed: !!user?.extra?.legal?.has_signed,
});

const countrySpecificInitialValues = (user, countryCode) => {
  switch (countryCode) {
    case 'UK':
      return {
        spoken_languages: user?.extra?.spoken_languages ?? '',
        spoken_dialect: user?.extra?.spoken_dialect ?? '',
        encountered_dialect: user?.extra?.encountered_dialect ?? '',
        ethnicity: user?.extra?.ethnicity?.[0] ?? '',
        ethnicity_free_form: user?.extra?.ethnicity_free_form ?? '',
        gb_residency_duration: user?.extra?.gb_residency_duration ?? '',
      };
    case 'US':
      return {
        spoken_languages: user?.extra?.spoken_languages ?? '',
        spoken_dialect: user?.extra?.spoken_dialect ?? '',
        encountered_dialect: user?.extra?.encountered_dialect ?? '',
        race: user?.extra?.race ?? '',
        race_free_form: user?.extra?.race_free_form ?? '',
        us_residency_duration: user?.extra?.us_residency_duration ?? '',
        consent_6: !!user?.extra?.legal?.consent_6,
        consent_7: !!user?.extra?.legal?.consent_7,
        consent_8: !!user?.extra?.legal?.consent_8,
        consent_9: !!user?.extra?.legal?.consent_9,
        consent_10: !!user?.extra?.legal?.consent_10,
      };
    case 'AU':
      return {
        languages_spoken: user?.extra?.languages_spoken ?? '',
        dialect: user?.extra?.dialect_self_au ?? '',
        dialect_environment_au: user?.extra?.dialect_environment_au ?? '',
        dialect_self_au: user?.extra?.dialect_self_au ?? '',
        ancestry_write_in: user?.extra?.ancestry_write_in ?? '',
        au_residency_duration: user?.extra?.au_residency_duration ?? '',
        consent_6: !!user?.extra?.legal?.consent_6,
        consent_7: !!user?.extra?.legal?.consent_7,
        consent_8: !!user?.extra?.legal?.consent_8,
        consent_9: !!user?.extra?.legal?.consent_9,
        consent_10: !!user?.extra?.legal?.consent_10,
        consent_11: !!user?.extra?.legal?.consent_11,
      };
    case 'USUK':
      return {
        spoken_dialect: user?.spokenDialect ?? null,
        origin: user?.extra?.origin ?? '',
        origin_country: user?.extra?.origin_country ?? '',
        residing_country: user?.extra?.residing_country ?? '',
        languages_spoken: user?.extra?.languages_spoken ?? '',
        english_learning_country: user?.extra?.english_learning_country ?? '',
        ancestry_write_in: user?.extra?.ancestry_write_in ?? '',
        residency_duration: user?.extra?.residency_duration ?? '',
        consent_6: !!user?.extra?.legal?.consent_6,
        consent_7: !!user?.extra?.legal?.consent_7,
        consent_8: !!user?.extra?.legal?.consent_8,
        consent_9: !!user?.extra?.legal?.consent_9,
        consent_10: !!user?.extra?.legal?.consent_10,
      };
    default: {
    }
  }
};
