import Utils from '../utils/Utils';
import PropTypes from 'prop-types';
import USER_PERMISSIONS from './UserPermission';

export default class User {
  static API_ROLES = {
    USER: 'USER',
    ADMIN: 'ADMIN',
    AMBASSADOR: 'AMBASSADOR',
  };

  static API_ROLES_TO_ID = {
    ADMIN: 1,
    USER: 2,
    AMBASSADOR: 10,
  };

  static PROJECT_ROLES = {
    MANAGER: 'MANAGER',
    AMBASSADOR: 'AMBASSADOR',
    LEAD_REVIEWER: 'LEAD_REVIEWER',
    WORKER_TRANSLATOR: 'WORKER_TRANSLATOR',
    WORKER_REVIEWER: 'WORKER_REVIEWER',
  };

  static isAdmin(user = null) {
    return (
      user?.apiRoles?.find((role) => role.sid === User.API_ROLES.ADMIN)?.sid ===
      User.API_ROLES.ADMIN
    );
  }

  static hasAnyPermission(user = null, type = null, sids = null) {
    sids = Utils.defineToArray(sids);
    if (Utils.isNull(user) || Utils.isEmpty(type) || Utils.isEmpty(sids)) return false;
    let permissions = User.getPermissions(user);
    return sids?.some((sid) => permissions.some((per) => per?.type === type && per?.sid === sid));
  }

  static hasAnyProjectPermission(user = null, projectId = null, type = null, sids = null) {
    sids = Utils.defineToArray(sids);
    if (Utils.isNull(user) || Utils.isNull(projectId) || Utils.isEmpty(type) || Utils.isEmpty(sids))
      return false;
    let permissions = User.getPermissions(user);
    return sids?.some((sid) =>
      permissions.some(
        (per) => per?.type === type && per?.projectId === projectId && per?.sid === sid
      )
    );
  }

  static hasAnyScopePermission(user = null, scope = null, type = null, sids = null) {
    sids = Utils.defineToArray(sids);
    if (Utils.isNull(user) || Utils.isEmpty(scope) || Utils.isEmpty(type) || Utils.isEmpty(sids))
      return false;
    let permissions = User.getPermissions(user);
    return sids?.some((sid) =>
      permissions.some((per) => per?.type === type && per?.scope === scope && per?.sid === sid)
    );
  }

  static hasAnyPermissionOfType(user = null, types = null) {
    types = Utils.defineToArray(types);
    if (Utils.isNull(user) || Utils.isEmpty(types)) return false;
    let permissions = User.getPermissions(user);
    return types?.some((type) => permissions.some((per) => per?.type === type));
  }

  static hasAnyProjectPermissionOfType(user = null, projectId = null, types = null) {
    types = Utils.defineToArray(types);
    if (Utils.isNull(user) || Utils.isNull(projectId) || Utils.isEmpty(types)) return false;
    let permissions = User.getPermissions(user);
    return types?.some((type) =>
      permissions.some((per) => per?.projectId === projectId && per?.type === type)
    );
  }

  static getPermissionOfType(user = null, types = null) {
    types = Utils.defineToArray(types);
    if (Utils.isNull(user) || Utils.isEmpty(types)) return [];
    let permissions = User.getPermissions(user);
    return permissions.filter((per) => types?.includes(per?.type));
  }

  static hasAnyScopePermissionOfType(user = null, scope = null, types = null) {
    types = Utils.defineToArray(types);
    if (Utils.isNull(user) || Utils.isEmpty(scope) || Utils.isEmpty(types)) return false;
    let permissions = User.getPermissions(user);
    return types?.some((type) =>
      permissions.some((per) => per?.scope === scope && per?.type === type)
    );
  }

  static hasRole(roles = null, role = null) {
    if (Utils.isNull(roles) || Utils.isEmpty(roles) || Utils.isNull(role)) {
      return false;
    }

    return roles.some((r) => r.sid === role);
  }

  static getPermissions(user = {}) {
    return Utils.defineArray(user?.apiPermissions, []);
  }

  static propTypes = {
    username: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    apiPermissions: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(Object.values(USER_PERMISSIONS.TYPES)).isRequired,
        sid: PropTypes.string.isRequired,
      })
    ).isRequired,
  };

  static isValid(user) {
    return !Utils.isNull(user) && user?.isValid;
  }
}
