// External libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter, useNavigate } from 'react-router-dom';
import { CardBody, ButtonGroup } from 'reactstrap';
import { throttle, debounce } from 'throttle-debounce';

// Internal libs
import Utils from '../../utils/Utils';
import API_STORAGE from '../../storage/ApiStorage';
import { PAGES } from '../index';
import { Text } from '../../components/parameters/TextParameter';
import UserReferralCodeController from '../../controllers/UserReferralCodeController';
import ErrorBoundary from '../../components/ErrorBoundary';
import ApiException from '../../models/ApiException';
import Notifier from '../../components/Notifier';
import UserRefereesRefCampaignTable from './components/UserRefereesRefCampaignTable';
import UserRefereesAmbassadorTable from './components/UserRefereesAmbassadorTable';
import { UserAbortRequestException } from '../../models/ApiException';
import Tooltip from '../../components/Tooltip';
import { objectToQueryString, parseQueryString } from '../../utils/Url';
import ErrorHandler from '../../utils/ErrorHandler';

class UserRefereesPage extends Component {
  constructor(props) {
    super(props);

    this.state = this.loadUserPreferences();
    this.state = this.loadHistory();
    this.state.filters = this.getUrlFilters(this.props.location);
  }

  getDefaultProperties = () => ({ ...UserRefereesPage.defaultProps });
  static defaultProps = {};

  PAGE_SID = Utils.define(PAGES.USER_REFEREES_PAGE.sid(), 'USER_REFEREES_PAGE');

  state = {
    tableIsLoading: true,

    tablePageIndex: 0,
    tablePageSize: 25, // Saved to user preference
    tableTotalPage: null,
    tableTotalSize: null,
    tableOrder: [{ columnName: 'email', direction: 'asc' }],

    referees: null,

    filters: {
      refereeSid: null,
    },
  };

  userPreferences = {
    tablePageSize: 25,
  };

  history = {
    tablePageIndex: 0,
    tablePageSize: 25,
    tableOrder: [{ columnName: 'email', direction: 'asc' }],
    filters: {},
  };

  // --------------------------------------------------------------------------------------
  // ---------------------------------- ReactJS Methods -----------------------------------
  // --------------------------------------------------------------------------------------
  componentDidMount() {
    try {
      this.updateRefereesTable()
      .then((result) => {
        this.setState({ ...result, tableIsLoading: false });
      })
      .catch((e) => {
        this.errorHandler(e);
        this.setState({ tableIsLoading: false });
      });
    } catch (e) {
      this.errorHandler(e);
    }
  }

  componentDidUpdate() {
    Promise.resolve().then(() => {
      this.saveUserPreferences();
      this.saveHistory();
    });
  }

  render() {
    try {
      if (Object.keys(this.state.referees).length !== 0) {
        return (
          <div className='col-12 px-0'>
            {/* START FILTER BAR */ }
            <CardBody className='row col-12 mx-0'>
              <div className='row col-xs-12 p-0 m-0 w-100'>
                <div className='col-xs-12 col-sm-6 pl-0 pr-2 pb-2'>
                  {/*<this.RefereeSidFilter />*/ }
                </div>
              </div>
            </CardBody>
            {/* END FILTERS BAR */ }
            {/* START TABLE PANEL */ }
            <CardBody className='row col-lg-12 mx-0 pt-0'>
              <CardBody className='col-lg-12 b bg-white'>
                {/* START TABLE MENU PANEL */ }
                <div className='row m-0 justify-content-between'>
                  { this.state.referees[0].referrerEmail ?
                    <h4>Users referred by&nbsp;{ this.state.referees[0].referrerEmail }</h4>
                     : null
                  }
                  <ButtonGroup className='col row m-0 justify-content-end px-1'
                               style={ { height: '40px' } }>
                    <Tooltip title='Refresh Table' id='refresh-button'>
                      {/* button element is wrapped inside a span as a workaround to enable tooltip when button is disabled*/ }
                      <span>
                      <button
                        onClick={ throttle(1000, this.refreshButtonClicked.bind(this)) }
                        className='btn btn-secondary border-0 px-2'
                      >
                        <em className='fas fa-sync-alt' style={ { fontSize: '20px' } }/>
                      </button>
                    </span>
                    </Tooltip>
                  </ButtonGroup>
                </div>
                {/* END TABLE MENU PANEL */ }
                <div className={ this.state.tableIsLoading === true ? 'whirl sphere' : '' }>
                  { this.state.referees[0].referralType === "AMBASSADOR" ?
                    <this.UserRefereesAmbassadorTable/> :
                    <this.UserRefereesRefCampaignTable/>
                  }
                </div>
              </CardBody>
            </CardBody>
            {/* END TABLE PANEL */ }
          </div>
        );
      }
      else {
        return (
          <div className='register-page-wrapper'>
            <div className='container mt-2 form-wrapper'>
              <div>
                <h3>There are no referees available at the moment!</h3>
              </div>
            </div>
          </div>
        )
      }
    } catch (e) {
      this.errorHandler(e);
      return <ErrorBoundary hasError={true} message='Error while rendering list.' />;
    }
  }

  RefereeSidFilterValue = null;
  RefereeSidFilter = () => {
    let onChange = (value) => {
      this.RefereeSidFilterValue = value;
      this.refereeSidFilterValueChanged(value);
    };

    let updateKey =
      this.RefereeSidFilterValue === this.state.filters?.refereeSid ? true : this.state.filters?.refereeSid;
    this.RefereeSidFilterValue = this.state.filters?.refereeSid;

    return (
      <ErrorBoundary>
        <Text
          key={updateKey}
          defaultValue={this.state.filters?.refereeSid}
          placeholder='Search by referee'
          onChange={debounce(500, onChange)}
        />
      </ErrorBoundary>
    );
  };


  UserRefereesRefCampaignTable = () => {
    let props = {
      data: Utils.defineArray(this.state.referees, []),
      onPageChange: this.pageChanged.bind(this),
      onPageSizeChange: this.pageSizeChanged.bind(this),
      onSortingChange: this.sortingChanged.bind(this),
      totalSize: Utils.define(this.state.tableTotalSize, 1),
      page: Utils.define(this.state.tablePageIndex, 0),
      size: this.state.tablePageSize,
      sorting: Utils.defineArray(this.state.tableOrder, []),
    };
    return (
      <ErrorBoundary>
        <UserRefereesRefCampaignTable {...props} />
      </ErrorBoundary>
    );
  };

  UserRefereesAmbassadorTable = () => {
    let props = {
      data: Utils.defineArray(this.state.referees, []),
      onPageChange: this.pageChanged.bind(this),
      onPageSizeChange: this.pageSizeChanged.bind(this),
      onSortingChange: this.sortingChanged.bind(this),
      totalSize: Utils.define(this.state.tableTotalSize, 1),
      page: Utils.define(this.state.tablePageIndex, 0),
      size: this.state.tablePageSize,
      sorting: Utils.defineArray(this.state.tableOrder, []),
    };
    return (
      <ErrorBoundary>
        <UserRefereesAmbassadorTable {...props} />
      </ErrorBoundary>
    );
  };

  // -----------------------------------------------------------------------------------
  // --------------------------------- User Events Listeners ---------------------------
  // -----------------------------------------------------------------------------------
  // ------------------------------------------------- Filters
  refereeSidFilterValueChanged(value) {
    /* eslint-disable-next-line react/no-direct-mutation-state */
    this.state.filters.refereeSid = value;
    this.applyFilters({ ...this.state.filters, tablePageIndex: 0 });
  }

  // -------------------------------------------- Table Toolbar
  async refreshButtonClicked() {
    this.applyFilters();
  }

  // async onBackButtonClick() {
  //   this.props.history.push(PAGES.PROJECTS_ADMIN_PAGE.path());
  // }

  // ------------------------------------------- Table
  pageChanged(pageIndex) {
    this.applyTableSettings({ tablePageIndex: pageIndex });
  }

  pageSizeChanged(pageSize) {
    this.applyTableSettings({ tablePageSize: pageSize });
  }

  sortingChanged(sorting) {
    this.applyTableSettings({ tableOrder: sorting });
  }

  // ------------------------------------------------------------------------------
  // ------------------------------- Private Utils --------------------------------
  // ------------------------------------------------------------------------------
  async applyFilters(filters = null) {
    try {
      filters = Utils.define(filters, this.state.filters);
      this.setState({ tableIsLoading: true });
      let newState = await this.updateRefereesTable(filters);
      this.setState({ ...newState, tableIsLoading: false, filters: filters });
    } catch (e) {
      if (UserAbortRequestException()?.code !== e?.code) {
        this.setState({ tableIsLoading: false, referees: [] });
      }
      this.errorHandler(e);
    }
  }

  async applyTableSettings(settings = null) {
    try {
      this.setState({ tableIsLoading: true });
      let newState = await this.updateRefereesTable(settings);
      this.setState({ ...newState, tableIsLoading: false });
    } catch (e) {
      if (UserAbortRequestException()?.code !== e?.code) {
        this.setState({ tableIsLoading: false });
      }
      this.errorHandler(e);
    }
  }

  requestParametersMapping(params = {}) {
    let map = {};
    if (!Utils.isEmpty(params?.refereeSid)) {
      map.refereeSid = Utils.define(params?.refereeSid, '');
    }
    if (!Utils.isEmpty(params.tableOrder)) {
      let sort = this.sortingToOrderBy(params.tableOrder);
      if (!Utils.isEmpty(sort)) {
        map.sort = sort;
      }
    }
    map.page = params.tablePageIndex;
    map.size = params.tablePageSize;
    return map;
  }

  async updateRefereesTable(overrideParams) {
    let parameters = {
      tablePageIndex: this.state.tablePageIndex,
      tablePageSize: this.state.tablePageSize,
      tableOrder: this.state.tableOrder,
      refereeSid: this.state.filters?.refereeSid,
      ...overrideParams,
    };
    //parameters.tablePageIndex++;
    return this.requestUserReferees(this.requestParametersMapping(parameters)).then((results) => {
      return {
        tablePageSize: parameters?.tablePageSize,
        tableTotalSize: Utils.define(results.totalElements),
        tableTotalPage: Utils.define(results?.totalPages),
        tablePageIndex: Utils.define(results.pageable?.pageNumber, 0),
        tableOrder: parameters?.tableOrder,
        referees: Utils.defineArray(results?.content, []),
      };
    });
  }

  sortingToOrderBy(sorting) {
    let sort = '';
    Utils.defineArray(sorting, []).forEach((obj) => {
      if (!Utils.isEmpty(sort)) {
        sort = sort + ', ';
      }
      sort = sort + obj.columnName + ',' + obj.direction;
    });
    return sort;
  }

  // -----------------------------------------------------------------------------------
  // --------------------------------- Requests ----------------------------------------
  // -----------------------------------------------------------------------------------
  async requestUserReferees(filters) {
    return UserReferralCodeController.findAllCodeRefereesWithParams(
      this.props.match.params.codeId, filters
    );
  }

  // --------------------------------------------------------------------------
  // ------------------------------ User Preferences --------------------------
  // --------------------------------------------------------------------------
  saveUserPreferences() {
    try {
      let updatedKeys = Object.keys(this.userPreferences).filter(
        (key) => this.userPreferences[key] !== this.state[key]
      );
      if (updatedKeys?.length > 0) {
        updatedKeys.forEach((key) => {
          this.userPreferences[key] = this.state[key];
        });
        API_STORAGE.USER_PREFERENCES.add({ [this.PAGE_SID]: this.userPreferences });
      }
    } catch (e) {
      this.errorHandler(e);
    }
  }

  loadUserPreferences() {
    try {
      let prefs = API_STORAGE.USER_PREFERENCES.read()?.[this.PAGE_SID];
      return Utils.isEmpty(prefs) ? this.state : { ...this.state, ...prefs };
    } catch (e) {
      this.errorHandler(e);
    }
  }

  // --------------------------------------------------------------------------
  // ------------------------------ History -----------------------------------
  // --------------------------------------------------------------------------
  saveHistory() {
    try {
      let updatedKeys = Object.keys(this.history).filter(
        (key) => this.history[key] !== this.state[key]
      );
      if (updatedKeys.length > 0) {
        updatedKeys.forEach((key) => {
          this.history[key] = this.state[key];
        });
        this.props.history.replace({
          pathname: this.props?.location?.pathname,
          state: this.history,
          search: objectToQueryString(this.state?.filters),
        });
      }
    } catch (e) {
      this.errorHandler(e, false);
    }
  }

  loadHistory() {
    try {
      if (Utils.isEmpty(this.props?.location?.state)) {
        return this.state;
      }
      this.history = this.props.location.state;
      return { ...this.state, ...this.history };
    } catch (e) {
      this.errorHandler(e);
    }
  }

  getUrlFilters(location = null) {
    let filters = {};
    try {
      filters = parseQueryString(Utils.define(location, this.props?.location)?.search);
    } catch (e) {
      this.errorHandler(e);
    }
    return filters;
  }

  // --------------------------------------------------------------------------
  // ------------------------------ Errors ------------------------------------
  // --------------------------------------------------------------------------
  errorHandler(e, message) {
    try {
      e = ApiException.toApiException(e, message);
      if (UserAbortRequestException()?.code === e?.code) {
        return;
      }
      Notifier.error(Utils.isNull(message) ? e?.userMessage : message);
      ErrorHandler.errorHandle(e);
    } catch (e) {
      ErrorHandler.errorHandle(e);
    }
  }
}

// const navigate = useNavigate();
// ------------------------------------------------------------------------------------
// ------------------------------- Redux ----------------------------------------------
// ------------------------------------------------------------------------------------
const mapStateToProps = (state = {}, ownProps) => ({
  ...ownProps,
  user: Utils.define(state?.session?.user),
});

export default connect(mapStateToProps)(withRouter(UserRefereesPage));
