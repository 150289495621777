import React from 'react';
import CardWithIcon from '../components/CardWithIcon';

export const ProjectBasicSettingsView = ({ project = null }) => {
  return (
    <div>
      <div className='row justify-content-center'>
        <div className='col-xs-12 col-sm-6 col-lg-3 d-flex'>
          <CardWithIcon
            title={project?.sourceLanguage?.name ?? 'Not set'}
            color={'text-purple'}
            icon={'fas fa-language'}
            description={'Source Language'}
          />
        </div>
        <div className='col-xs-12 col-sm-6 col-lg-3 d-flex'>
          <CardWithIcon
            title={project?.targetLanguage?.name ?? 'Not set'}
            color={'text-warning'}
            icon={'fas fa-language'}
            description={'Target Language'}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectBasicSettingsView;
