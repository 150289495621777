// CSS and IMAGES
import LogoImageUrl from '../../media/hlp-logo-black-background.png';
import LogoImageUrlSmall from '../../media/favicon.png';
import '../../css/Taus.scss';

// Dependencies
import React, { useState } from 'react';
import { Button, Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// Sources
import Utils from '../../utils/Utils';
import { PAGES } from '../index';
import LogoutPlugin from './LogoutPlugin';
import { UserMenu } from './Menu';
import USER from '../../models/User';
import USER_PERMISSION from '../../models/UserPermission';
import { useMessageBox } from '../../contexts/MessageBoxContext';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => Utils.define(state?.session?.user));

  const openMessageBox = useMessageBox();

  const navBarToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const navBarOptionSelected = () => {
    setIsOpen(false);
  };

  const canCreateMessages = USER.hasAnyPermission(user, USER_PERMISSION.TYPES.MESSAGE, ['CREATE']);

  return (
    <div className='row topnavbar-wrapper taus-topnavbar-wrapper bg-taus'>
      <div className='col-12'>
        <Navbar className='topnavbar top-nav taus-top-nav bg-taus' color='dark' dark expand='md'>
          <NavLink to={PAGES.HOME_PAGE.path()}>
            <img className='img-fluid default-logo' src={LogoImageUrl} alt='Taus Logo' />
            <img className='img-fluid small-logo' src={LogoImageUrlSmall} alt='Taus Logo' />
          </NavLink>
          <div className='d-flex d-md-none h-100' style={{ position: 'absolute', right: '80px' }}>
            {!Utils.isNull(user) && canCreateMessages && (
              <div className='h-100 mr-2' style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  onClick={() => openMessageBox()}
                  className='border-0 text-white bg-transparent taus-login-btn'
                  to='#'
                >
                  <em className='fas fa-envelope'></em>
                </Button>
              </div>
            )}
            {!Utils.isNull(user) && <LogoutPlugin user={user} />}
          </div>
          <NavbarToggler onClick={navBarToggle} />
          <Collapse
            className='login-dropdown taus-login-dropdown'
            navbar
            isOpen={isOpen}
            onClick={navBarOptionSelected}
          >
            <Nav navbar className='ml-auto'>
              {UserMenu(user)}
              {!Utils.isNull(user) && canCreateMessages && (
                <NavItem className='d-none d-md-block'>
                  <div className='h-100 mr-2' style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      onClick={() => openMessageBox()}
                      className='border-0 text-white bg-transparent taus-login-btn'
                      to='#'
                    >
                      <em data-testid='messageBoxButton' className='fas fa-envelope'></em>
                    </Button>
                  </div>
                </NavItem>
              )}
              <NavItem className='d-none d-md-block'>
                {!Utils.isNull(user) && <LogoutPlugin user={user} />}
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

// --------------------------------------------------------
// ----------------------- PropTypes ----------------------
// --------------------------------------------------------

Header.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};
