import React from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { MENUS } from '../index';
import Utils from '../../utils/Utils';
import { PAGE_TYPE } from '../index';

// ----------------------------------------------------------------------------------------
// --------------------------------------------- Users Menu -------------------------------
// ----------------------------------------------------------------------------------------
/**
 * Create a menu view. If its only one items its a navigation link. If its more a dropdown
 * @param menu
 * @returns {null|*}
 */
const menuSection = (menu) => {
  let pages = Utils.defineArray(menu.pages, []);
  if (pages?.length === 0) return null;
  if (pages?.length === 1 && (Utils.isNull(menu?.grouped) || menu.grouped() !== true)) {
    return (
      <NavItem key={menu?.sid()} className='d-flex align-items-center'>
        {Utils.isNull(menu?.render) ? (
          pages?.[0]?.type() === PAGE_TYPE.EXTERNAL ? (
            <NavLink
              className='nav-link pointer text-uppercase'
              to='#'
              href='#'
              onClick={() => {
                window.open(pages?.[0]?.path());
              }}
            >
              {pages?.[0]?.name()}
            </NavLink>
          ) : (
            <NavLink className='nav-link pointer text-uppercase' to={pages?.[0].path()}>
              {pages?.[0]?.name()}
            </NavLink>
          )
        ) : (
          menu.render(pages?.[0])
        )}
      </NavItem>
    );
  }

  return (
    <UncontrolledDropdown nav inNavbar className='d-flex align-items-center' key={menu?.sid()}>
      <DropdownToggle
        nav
        caret
        className='w-100 text-uppercase'
        onClick={(e) => e.stopPropagation()}
      >
        <span>{menu?.name()}</span>
      </DropdownToggle>
      <DropdownMenu right>
        {pages?.map((obj) => (
          <DropdownItem className='pointer' key={obj?.name()}>
            <NavLink
              className='nav-link'
              to={obj?.path()}
              style={{ color: '#5d9cec', textDecoration: 'none' }}
              activeStyle={{ color: '#5d9cec' }}
            >
              {obj?.name()}
            </NavLink>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

/**
 * Returns the navigation bar foul menu filtered with the items the user has access.
 * @param user
 * @returns {*[]}
 * @constructor
 */
export const UserMenu = (user) => {
  return Object.values(MENUS)
    .map((menu) => ({ ...menu, pages: menu?.pages()?.filter((page) => page.userHasAccess(user)) }))
    .map((menu) => menuSection(menu));
};
