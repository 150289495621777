import { memo } from 'react';
import deepEqual from 'fast-deep-equal';

import AnnotationTargetSegment from './AnnotationTargetSegment';
import { TargetSegmentTranslationFooter as TargetSegmentFooter } from '../SegmentView';
import AnnotationSourceSegment from './AnnotationSourceSegment';

const AnnotationSegment = memo(
  ({
    segment,
    targetLanguage,
    data,
    onDataChanged,
    isLoading,
    isChanged,
    isValid,
    errorMessages,
    handleSave,
    tagDefinitions,
    root,
  }) => {
    return (
      <div className='d-flex flex-wrap justify-content-between align-items-start mb-5 pb-5'>
        <AnnotationSourceSegment source={segment?.source} />
        <AnnotationTargetSegment
          segmentId={segment.id}
          targetLanguage={targetLanguage}
          isLoading={isLoading}
          isChanged={isChanged}
          isValid={isValid}
          errorMessages={errorMessages}
          data={data}
          onDataChanged={onDataChanged}
          tagDefinitions={tagDefinitions}
          root={root}
          footer={
            <TargetSegmentFooter
              isValid={isValid}
              errorMessage={errorMessages.join(' ')}
              isChanged={isChanged}
              onSaveButtonClick={handleSave}
            />
          }
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      deepEqual(prevProps.isLoading, nextProps.isLoading) &&
      deepEqual(prevProps.isChanged, nextProps.isChanged) &&
      deepEqual(prevProps.isValid, nextProps.isValid) &&
      deepEqual(prevProps.errorMessages, nextProps.errorMessages) &&
      deepEqual(prevProps.data, nextProps.data)
    );
  }
);

export default AnnotationSegment;
