import { get, post, put, remove } from '../http';
import { objectToQueryString } from '../utils/Url';

const BASE_URL = '/api/file';

const getFile = async (id) => {
  return get(`${BASE_URL}/${id}`);
};

const getAll = async (parameters = {}, headers = {}) => {
  return get(`${BASE_URL}?${objectToQueryString(parameters)}`, {
    headers: { Accept: 'application/json', ...headers },
  });
};

const create = async (file) => {
  if (!file) {
    throw new Error('Invalid file (Null)');
  }
  return post('/api/file', file);
};

const update = async (id, file = {}) => {
  if (!id) {
    throw new Error('Invalid id (Null)');
  }
  return put(`${BASE_URL}/${id}`, file);
};

const deleteFile = async (id) => {
  if (!id) {
    throw new Error('Invalid id (Null)');
  }
  return remove(`${BASE_URL}/${id}`);
};

const getSegments = async (id) => {
  if (!id) {
    throw new Error('Invalid file id (Null)');
  }
  return get(`${BASE_URL}/${id}/segments`);
};

const createSegments = async (id = null, segments = []) => {
  if (!id) {
    throw new Error('Invalid file id (Null)');
  }
  return post(`${BASE_URL}/${id}/segments`, segments);
};

const FileController = {
  get: getFile,
  getAll,
  create,
  update,
  delete: deleteFile,
  getSegments,
  createSegments,
};

export default FileController;
