import { useEffect, useState } from 'react';
import { LinearProgress, Tooltip } from '@mui/material';
import TaskResult from '../../../models/TaskResult';

const RolledBackTasksProgressBar = ({ tasks }) => {
  const [totalRB, setTotalRB] = useState();
  const [totalRBR, setTotalRBR] = useState();
  const [totalRBRPassed, setTotalRBRPassed] = useState();
  const [percentageOfPassedRBtasks, setPercentageOfPassedRBtasks] = useState(50);
  const [percentageOfResubmittedRBtasks, setPercentageOfResubmittedRBtasks] = useState(50);

  useEffect(() => {
    const totalRolledBack = tasks.filter((t) => t.version > 0).length;
    setTotalRB(totalRolledBack);

    const totalRolledBackResubmitted = tasks
      .filter((t) => t.version > 0)
      .filter((t) => t.taskResult.status !== TaskResult.STATUS.ROLLED_BACK).length;
    setTotalRBR(totalRolledBackResubmitted);

    setPercentageOfResubmittedRBtasks(
      parseFloat((totalRolledBackResubmitted / totalRolledBack) * 100).toFixed(2)
    );

    const totalRolledBackPassed = tasks
      .filter((t) => t.version > 0)
      .filter((t) => t.taskResult.evaluationStatus !== TaskResult.EVALUATION_STATUS.PASSED).length;
    setTotalRBRPassed(totalRolledBackPassed);

    setPercentageOfPassedRBtasks(
      parseFloat((totalRolledBackPassed / totalRolledBackResubmitted) * 100).toFixed(2)
    );
  }, [tasks]);

  return (
    <div className='tw-flex tw-flex-col tw-gap-8'>
      <div>
        <div>
          <span className='tw-text-sm tw-font-medium'>2b.</span>
        </div>
        <span>Percentage of resubmitted Rolled Back tasks</span>
        <div className='tw-relative'>
          <div className='tw-z-20 tw-absolute tw-top-0 tw-left-1/2 -tw-translate-x-1/2'>
            <span className='tw-text-white'>{percentageOfResubmittedRBtasks}%</span>
          </div>
          <div className='tw-z-10 tw-absolute tw-left-0 tw-top-0 tw-w-full tw-cursor-pointer'>
            <Tooltip
              title={
                <>
                  <div>Total number of Rolled Back tasks = {totalRB}</div>
                  <div>Total number of Resubmitted Rolled Back tasks = {totalRBR}</div>
                  <div>
                    Total number of not Resubmitted Rolled Back tasks = {totalRB - totalRBR}
                  </div>
                </>
              }
              placement='top'
            >
              <LinearProgress
                color='warning'
                variant='determinate'
                value={Number(percentageOfResubmittedRBtasks)}
                sx={{
                  width: '100%',
                  height: '20px',
                  borderRadius: '4px',
                }}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <div>
        <span>Percentage of resubmitted Rolled Back tasks with Eval status PASSED</span>
        <div className='tw-relative'>
          <div className='tw-z-20 tw-absolute tw-top-0 tw-left-1/2 -tw-translate-x-1/2'>
            <span className='tw-text-white'>{percentageOfPassedRBtasks}%</span>
          </div>
          <div className='tw-z-10 tw-absolute tw-left-0 tw-top-0 tw-w-full'>
            <Tooltip
              title={
                <>
                  <div>Total number of Resubmitted Rolled Back tasks = {totalRBR}</div>
                  <div>
                    Total number of Resubmitted Rolled Back tasks with EVAL PASSED ={' '}
                    {totalRBRPassed}
                  </div>
                  <div>
                    Total number of Resubmitted Rolled Back tasks with no EVAL PASSED ={' '}
                    {totalRB - totalRBRPassed}
                  </div>
                </>
              }
              placement='top'
            >
              <LinearProgress
                color='warning'
                variant='determinate'
                value={Number(percentageOfPassedRBtasks)}
                sx={{
                  width: '100%',
                  height: '20px',
                  borderRadius: '4px',
                }}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolledBackTasksProgressBar;
