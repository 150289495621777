import React, { useEffect, useState } from 'react';
import ProjectController from '../../../controllers/ProjectController';
import ProjectMembersView from '../views/ProjectMembersView';


const ProjectMembersState = ({project, isEditable, canSendMessage}) => {

  const [projectMembers, setProjectMembers] = useState([]);

  const getProjectMembers = async() => {
    await ProjectController.getMembers(project?.id,
      {'serialize-permission': false}).then((r) => {
      setProjectMembers(r);
    });
  };

  useEffect(() => {
    getProjectMembers();
  }, []);

  return (
    <>
      <ProjectMembersView
        project={project}
        projectMembers={projectMembers}
        isEditable={isEditable}
        canSendMessage={canSendMessage}
      />
    </>
  );

};

export default ProjectMembersState;
