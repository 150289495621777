// External dependencies
import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

// Internal dependencies
import Utils from '../utils/Utils';
import ErrorHandler from '../utils/ErrorHandler';

export default class Tooltip extends ReactTooltip {
  constructor(props) {
    super(props);
    this.DOMObject = null;
    this.tooltip = null;
    this.id = Utils.isEmpty(this.props.id) ? Tooltip.generateId() : this.props.id;
  }

  static defaultProps = {
    title: null,
    place: 'top',
    typeOptions: {
      delayShow: 500,
      effect: 'solid',
      type: 'dark',
      html: false,
    },
    selector: null,
    singleLine: false,
  };

  static generateId() {
    return 'tooltip-' + Math.random().toString(36).substr(2);
  }

  errorHandler(e) {
    ErrorHandler.errorHandle(e);
  }

  // ------------------------------------------------------------------------------------
  // -------------------------------- ReactJS -------------------------------------------
  // ------------------------------------------------------------------------------------
  componentDidMount() {
    try {
      this.DOMObject = ReactDOM.findDOMNode(this);

      if (!Utils.isNull(this.tooltip)) {
        if (this.props.singleLine === true) {
          try {
            ReactDOM.findDOMNode(this.tooltip).style.width = 'fit-content';
          } catch (e) {
            this.errorHandler(e);
          }
        }

        if (!Utils.isNull(this.props.children)) {
          let selector = Utils.isNull(this.props.selector)
            ? this.DOMObject
            : this.DOMObject.querySelector(this.props.selector);
          if (!Utils.isNull(selector)) {
            selector.setAttribute('data-tip', true);
            selector.setAttribute('data-for', this.id);
          }
        }
        this.tooltip.globalRebuild();
      }
    } catch (e) {
      this.errorHandler(e);
    }
  }

  render() {
    try {
      if (Utils.isEmpty(this.props.title)) return Utils.define(this.props.children);

      let options = {
        place: this.props.place,
        ...Tooltip.defaultProps.typeOptions,
        ...this.props.typeOptions,
        id: this.id,
        ref: (ref) => {
          this.tooltip = ref;
        },
      };

      return (
        <>
          {this.props.children}
          <ReactTooltip {...options}>{this.props.title}</ReactTooltip>
        </>
      );
    } catch (e) {
      this.errorHandler(e);
      return Utils.define(this.props.children);
    }
  }
}

Tooltip.propTypes = {
  title: PropTypes.node.isRequired,
  place: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  selector: PropTypes.string,
  id: PropTypes.string,
  singleLine: PropTypes.bool,
  typeOptions: PropTypes.shape({
    place: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
    delayShow: PropTypes.number,
    effect: PropTypes.oneOf(['solid', 'float']),
    type: PropTypes.oneOf(['dark', 'success', 'warning', 'error', 'info', 'light']),
    html: PropTypes.bool,
  }),
};
