import React from 'react';
import { Provider } from 'react-redux';
import { appStore } from './redux/stores/App';
import ReactGA from 'react-ga';

import Main from './pages/Main';
import ErrorHandler from './utils/ErrorHandler';
import { MessageBoxProvider } from './contexts/MessageBoxContext';
import ScrollUpArrow from './components/ScrollUpArrow';

import './css/index.css';

// Initialize Google Analytics
try {
  ReactGA.initialize('UA-57303170-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
} catch (e) {
  ErrorHandler.errorHandle(e);
}

const App = () => (
  <Provider store={appStore}>
    <MessageBoxProvider>
      <Main />
      <ScrollUpArrow />
    </MessageBoxProvider>
  </Provider>
);

export default App;
