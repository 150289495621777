import React, {Fragment, useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem} from 'reactstrap';
import PropTypes from "prop-types";
import {v4} from 'uuid';

const ModalErrors = (props) => {
    const {
        buttonLabel,
        className,
        errors,
        size,
        color,
        header,
        hideOnNoErrors
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    if (hideOnNoErrors && errors && errors.length === 0)
        return null;
    else
        return (
            <Fragment>
                <Button outline
                        className={className}
                        size={size ? size : "lg"}
                        color={color ? color : "danger"}
                        onClick={toggle}
                        disabled={errors ? errors.length === 0 : true}>
                    {buttonLabel}
                </Button>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>{header}</ModalHeader>
                    <ModalBody>
                        <ListGroup>
                            {errors.map(e => {
                                return <ListGroupItem key={v4()}>{e}</ListGroupItem>
                            })}
                        </ListGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Close</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
};

ModalErrors.propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    className: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
    size: PropTypes.string,
    color: PropTypes.string,
    header: PropTypes.string.isRequired,
    hideOnNoErrors: PropTypes.bool
};

export default ModalErrors;