import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Loader from '../../../components/Loader';
import InputWithValidation from '../../../components/forms/InputWithValidation';
import SelectWithValidation from '../../../components/forms/SelectWithValidation';
import { findOption } from '../../../utils/Select';
import ScreeningStepsTable from './ScreeningStepsTable';

const ScreeningPageView = ({
  isLoading,
  screeningSteps,
  questionnaires,
  sorting,
  handleSorting,
  pagination,
  totalElements,
  handlePageChange,
  handlePageSizeChange,
  adminProjects,
  createScreeningStep,
  handleInvalidate,
  handleDelete,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [stepToEdit, setStepToEdit] = useState(null);

  const initialValues = {
    name: stepToEdit?.name ?? '',
    description: stepToEdit?.description ?? '',
    type: stepToEdit?.type ?? null,
    requirements: null,
  };

  const screeningTypes = [
    { value: 'PROJECT', label: 'Project' },
    { value: 'QUESTIONNAIRE', label: 'Questionnaire' },
  ];

  const renderRequirements = (type) => {
    if (!type) return [];

    if (type === 'PROJECT') {
      return adminProjects;
    } else {
      return questionnaires;
    }
  };

  const resetFormAndData = (resetForm) => {
    setStepToEdit(null);
    setIsEditing(false);
    resetForm();
  };

  const handleSubmit = (values, formik) => {
    let data = {
      name: values.name,
      description: values.description,
      type: values.type,
      requirementTypeId: values.requirements,
    };

    if (stepToEdit?.id) {
      data = {
        ...data,
        id: stepToEdit.id,
      };
    }

    createScreeningStep(data, isEditing);
    resetFormAndData(formik.resetForm);
  };

  const handleEdit = (step, setFieldValue) => {
    setIsEditing(true);
    setStepToEdit({
      id: step.id,
      name: step.name,
      description: step.description,
      type: step.type,
      requirements: null,
      status: step.status,
    });
    setFieldValue('name', step.name);
    setFieldValue('description', step.description);
    setFieldValue('type', step.type);
    setFieldValue('requirements', '_');
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Screener step name cannot be empty!').nullable(),
    description: Yup.string()
      .required('Please type a description for the screener step!')
      .nullable(),
    type: Yup.string().nullable().required('Please select a screener step type!'),
    requirements: Yup.string().required('Please select a screener step requirement!').nullable(),
  });

  return (
    <div className='jumbotron tw-relative'>
      {isLoading && <Loader />}
      <h1 className='tw-border-solid tw-border-b tw-border-t-0 tw-border-x-0 tw-border-b-gray-200 tw-pb-2 tw-mb-8'>
        Create Screening Step
      </h1>
      <Formik
        className='tw-relative tw-z-0'
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, { resetForm });
        }}
      >
        {(props) => {
          return (
            <div>
              <Form onSubmit={props.handleSubmit}>
                <Row>
                  <Col sm={12} md={6}>
                    <InputWithValidation
                      id='name'
                      propertyName='name'
                      label='Name'
                      placeholder='Name'
                      helpText='Enter a name for this screening step'
                      defaultValue={props.values.name}
                      touched={props.touched.name}
                      error={props.errors.name}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <InputWithValidation
                      id='description'
                      propertyName='description'
                      label='Description'
                      placeholder='Description'
                      helpText='Enter a description for this screening step'
                      defaultValue={props.values.description}
                      touched={props.touched.description}
                      error={props.errors.description}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <SelectWithValidation
                      id='type'
                      propertyName='type'
                      label='Type'
                      helpText='Set a type for this screening step'
                      options={screeningTypes}
                      defaultOption={findOption(
                        screeningTypes,
                        stepToEdit?.type ?? props.values.type
                      )}
                      defaultValue={props.values.type}
                      touched={props.touched.type}
                      error={props.errors.type}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      isDisabled={isEditing}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <SelectWithValidation
                      id='requirements'
                      propertyName='requirements'
                      label='Requirements'
                      helpText='Set the requirements for this screening step'
                      options={renderRequirements(props.values.type)}
                      defaultOption={findOption(
                        renderRequirements(props.values.type),
                        stepToEdit?.requirements ?? props.values.requirements
                      )}
                      defaultValue={props.values.requirements}
                      touched={props.touched.requirements}
                      error={props.errors.requirements}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      isDisabled={isEditing}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className='tw-flex tw-justify-end'>
                    <Button
                      className={props.isValid ? 'btn-success' : 'btn-secondary'}
                      type='submit'
                      disabled={!props.isValid}
                    >
                      {isEditing ? 'Edit' : 'Add'}
                    </Button>
                    <Button
                      className='btn-secondary tw-ml-4'
                      type='reset'
                      onClick={() => resetFormAndData(props.resetForm)}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
              <h1 className='tw-border-solid tw-border-b tw-border-t-0 tw-border-x-0 tw-border-b-gray-200 tw-pb-2 tw-mb-8 tw-mt-20'>
                Screening Steps
              </h1>
              {screeningSteps.length > 0 ? (
                <div className={`${isLoading ? 'sphere whirl' : ''}`}>
                  <ScreeningStepsTable
                    steps={screeningSteps}
                    sorting={sorting}
                    handleSorting={handleSorting}
                    pagination={pagination}
                    totalElements={totalElements}
                    handlePageChange={handlePageChange}
                    handlePageSizeChange={handlePageSizeChange}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    handleInvalidate={handleInvalidate}
                    setFieldValue={props.setFieldValue}
                  />
                </div>
              ) : (
                <div className='tw-text-center tw-px-2 tw-py-4'>
                  No screening steps have been added yet. <br />
                  Start by adding a screening step above.
                </div>
              )}
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default ScreeningPageView;
