import React from 'react';
import { components } from 'react-select';

import IndeterminateCheckbox from '../IndeterminateCheckbox';

const OptionWithCheckbox = (props) => {
  const indeterminate = !!props.getValue().find((option) => option.value === props.value)
    ?.indeterminate;

  let value = -1;
  if (props.isSelected) {
    if (indeterminate) {
      value = 0;
    } else {
      value = 1;
    }
  }

  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };

  return (
    <components.Option {...newProps}>
      <IndeterminateCheckbox
        checked={value > 0}
        indeterminate={value === 0}
        label={props.label}
        onChange={() => {}}
      />
    </components.Option>
  );
};

export default OptionWithCheckbox;
