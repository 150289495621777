import React, { useState } from 'react';
import Button from '../../../components/Button';
import Tooltip from '../../../components/Tooltip';
import CheckboxWithValidation from '../../../components/forms/CheckboxWithValidation';
import { useMessageBox } from '../../../contexts/MessageBoxContext';

import {
  PagingState,
  CustomPaging,
  SortingState,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import { SelectHeaderCheckbox, SelectRowCheckbox } from '../../../components/tables/Select';

export const ProjectApplicantsTabView = ({
  isLoading,
  canSendMessage,
  applicants,
  sorting,
  handleSorting,
  selectedUsers,
  setSelectedUsers,
  pagination,
  totalElements,
  handlePageChange,
  handlePageSizeChange,
  handleApproveAll,
  handleStepToggle,
}) => {
  const openMessageBox = useMessageBox();
  const messageButtonClicked = () => openMessageBox(selectedUsers);

  const TableHeaderRowSortLabelComponent = (props) => {
    const sortableColumns = ['email', 'member'];
    const centerAlignedColumns = ['member', 'controls'];

    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props}>
          {props.column.title}
          <span className='pl-2'>
            <em className={`fas fa-long-arrow-alt-${props?.direction === 'asc' ? 'up' : 'down'}`} />
          </span>
        </TableHeaderRow.SortLabel>
      );
    } else {
      if (centerAlignedColumns.includes(props.column.name)) {
        return (
          <TableHeaderRow.Content {...props}>
            <span className='tw-w-full tw-block tw-text-gray-500 tw-font-bold tw-text-center'>
              {props.column.title}
            </span>
          </TableHeaderRow.Content>
        );
      } else {
        return <span className='tw-text-gray-500 tw-font-bold'>{props.column.title}</span>;
      }
    }
  };

  const resolveMember = (row) => {
    const isMember = row.member;

    return <span className='tw-block tw-text-center'>{isMember ? 'Yes' : 'No'}</span>;
  };

  const resolveSteps = (row) => {
    const userId = row.id;
    const userScreenerSteps = row.screenerSteps;

    if (userScreenerSteps?.length > 0) {
      return (
        <div className='tw-flex tw-flex-col tw-items-start'>
          {userScreenerSteps.map((s) => {
            const isLastItem =
              userScreenerSteps[userScreenerSteps.length - 1].projectScreenerStep.screenerStep
                .id === s.projectScreenerStep.screenerStep.id;
            let step = s.projectScreenerStep.screenerStep;
            step = { ...step, isCompleted: s.isCompleted };

            return (
              <div
                key={step.id}
                className={`tw-flex tw-items-center tw-justify-around ${
                  !isLastItem ? 'tw-mb-2' : ''
                }`}
              >
                <CheckboxWithValidation
                  id={userId}
                  label={step.name}
                  isFormGroup={false}
                  propertyName='completeStep'
                  defaultValue={step.isCompleted}
                  onChange={() => handleStepToggle(step, userId)}
                  onBlur={() => {}}
                />
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div>No screener steps defined yet for this user role</div>;
    }
  };

  const resolveControls = (row) => {
    const userId = row.id;
    const hasScreenerSteps = row.screenerSteps.length > 0;

    return (
      <div className='tw-flex tw-justify-center tw-items-center'>
        <Tooltip key='approve_tooltip' title='Approve'>
          <Button
            label='Approve'
            priority='primary'
            isOutline={false}
            handleClick={() => handleApproveAll(userId)}
            disabled={!hasScreenerSteps}
          />
        </Tooltip>
      </div>
    );
  };

  const tableColumns = [
    {
      name: 'email',
      title: 'Email',
      getCellValue: (data) => data.sid,
    },
    {
      name: 'member',
      title: 'Member',
      getCellValue: resolveMember,
    },
    {
      name: 'steps',
      title: 'Steps',
      getCellValue: resolveSteps,
    },
    {
      name: 'controls',
      title: 'Controls',
      getCellValue: resolveControls,
    },
  ];

  return (
    <div className='tw-relative tw-mt-4'>
      {canSendMessage && (
        <div className='text-right'>
          <Tooltip key='message_users_tooltip' title='Send a message to the project members'>
            <button className='btn btn-secondary border-0 px-2' onClick={messageButtonClicked}>
              <em className='fas fa-envelope' style={{ fontSize: '24px' }} />
            </button>
          </Tooltip>
        </div>
      )}

      {applicants ? (
        <div className={`${isLoading ? 'sphere whirl' : ''}`}>
          <Grid rows={applicants} columns={tableColumns} getRowId={(row) => row.id}>
            <SortingState
              columnExtensions={[
                { columnName: 'email', sortingEnabled: true },
                { columnName: 'member', sortingEnabled: true },
                { columnName: 'steps', sortingEnabled: false },
                { columnName: 'controls', sortingEnabled: false },
              ]}
              sorting={sorting}
              onSortingChange={handleSorting}
            />
            <PagingState
              currentPage={pagination.page}
              pageSize={pagination.size}
              onCurrentPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
            <CustomPaging totalCount={totalElements} />
            <SelectionState selection={selectedUsers} onSelectionChange={setSelectedUsers} />
            <IntegratedSelection />
            <Table
              columnExtensions={[
                { columnName: 'email', wordWrapEnabled: true, width: 'auto' },
                { columnName: 'member', wordWrapEnabled: true, width: '120' },
                { columnName: 'steps', wordWrapEnabled: true, width: 'auto' },
                { columnName: 'controls', wordWrapEnabled: true, width: '150' },
              ]}
            />
            <TableHeaderRow
              sortLabelComponent={TableHeaderRowSortLabelComponent}
              showSortingControls={true}
            />
            <TableSelection
              showSelectAll={true}
              cellComponent={SelectRowCheckbox}
              headerCellComponent={SelectHeaderCheckbox}
            />
            <PagingPanel pageSizes={[10, 20, 50, 100]} />
          </Grid>
        </div>
      ) : (
        <div className='tw-text-center tw-px-2 tw-py-4'>
          There are no applicants for this project.
        </div>
      )}
    </div>
  );
};

export default ProjectApplicantsTabView;
