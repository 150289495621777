import { get, put } from '../http';

const BASE_URL = '/api/user';

const getMetadata = async (userId) => {
  return get(`${BASE_URL}/${userId}/meta-data`);
};

const updateMetadata = async (userId, userMetadata) => {
  return put(`${BASE_URL}/${userId}/meta-data`, userMetadata);
};

const UserMetadataController = {
  get: getMetadata,
  put: updateMetadata,
};

export default UserMetadataController;
