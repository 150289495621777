import React from 'react';
import DataTable from '../project/components/DataTable';
import { Button, Col, Row } from 'reactstrap';
import { PAGES } from '../index';
import $ from 'jquery';
import Notifier from '../../components/Notifier';
import FileController from '../../controllers/FileController';
import Swal from 'sweetalert2';
import { withRouter } from 'react-router-dom';
import USER from '../../models/User';
import USER_PERMISSION from '../../models/UserPermission';
import { connect } from 'react-redux';

class FileList extends React.Component {
  constructor(props) {
    super(props);

    // Permissions
    let user = this.props?.user;
    this.hasCreatePermission = USER.hasAnyPermission(user, USER_PERMISSION.TYPES.FILE, ['CREATE']);
    this.hasEditPermission = USER.hasAnyPermission(user, USER_PERMISSION.TYPES.FILE, ['EDIT']);
    this.hasDeletePermission = USER.hasAnyPermission(user, USER_PERMISSION.TYPES.FILE, ['DELETE']);

    this.state = {
      files: [],
      isFetching: false,
      isDeleting: false,
    };

    this.columns = [
      {
        title: 'Id',
        data: (row) => row?.id ?? '',
      },
      {
        title: 'Name',
        data: (row) => row?.name ?? '',
      },
      {
        title: 'Source Language',
        data: (row) => row?.sourceLanguage?.name ?? '',
      },
      {
        title: 'Target Language',
        data: (row) => row?.targetLanguage?.name ?? '',
      },
      {
        title: 'Segments',
        data: (row) => row?.segmentsNum ?? '',
      },
      {
        title: 'Tokenization',
        data: (row) => {
          if (row.tokenizationStatus === 'ACKNOWLEDGED') {
            return '<span class = "badge bg-primary">Acknowledged</span>';
          } else if (row.tokenizationStatus === 'IN_PROGRESS') {
            return '<span class = "badge bg-primary">In progress</span>';
          } else if (row.tokenizationStatus === 'FAILED') {
            return '<span class = "badge bg-danger">Failed</span>';
          } else if (row.tokenizationStatus === 'COMPLETED') {
            return '<span class = "badge bg-success">Completed</span>';
          }
          return '<span class = "badge bg-gray">Not started</span>';
        },
      },
      {
        title: 'Created',
        data: (row) =>
          row?.timestampCreated ? new Date(row?.timestampCreated).toUTCString() : null,
      },
      {
        title: '',
        orderable: false,
        visible: this.hasEditPermission,
        data: () => {
          return '<button class="btnEdit btn btn-primary btn-block">Edit</button>';
        },
      },
      {
        title: '',
        orderable: false,
        visible: this.hasDeletePermission,
        width: 1,
        data: () => {
          return '<button class="btnDelete btn btn-danger btn-block">Delete</button>';
        },
      },
    ];

    this.options = {
      rowId: 'id',
      bInfo: true,
      bFilter: true,
      paging: false,
      ordering: true,
      autoWidth: false,
      bLengthChange: false,
      order: [[0, 'desc']],
    };

    this.language = {
      emptyTable: 'No files found...',
      zeroRecords: 'Ooops! Nothing found...',
    };

    this.rowCallback = (row) => {
      $(row)
        .find('.btnEdit')
        .on('click', this.onEditClicked.bind(this, parseInt(row.id)));
      $(row)
        .find('.btnDelete')
        .on('click', this.onDeleteClicked.bind(this, parseInt(row.id)));
    };

    this.dataTableRef = React.createRef();
    this.onNew = this.onNew.bind(this);
  }

  componentDidMount() {
    this.getSourceFiles();
  }

  getSourceFiles() {
    // Get files
    this.setState({ isFetching: true });
    FileController.getAll({ pageSize: 1000 })
      .then((result) => {
        this.setState({
          files: result?.page ?? [],
        });
      })
      .catch((error) => {
        this.errorHandler(error);
      })
      .finally(() => this.setState({ isFetching: false }));
  }

  onNew() {
    this.props.history.push(PAGES.FILE_CREATE_PAGE.path());
  }

  onEditClicked(id) {
    let file = this.state.files.find((p) => p.id === parseInt(id));
    this.props.history.push(PAGES.FILE_EDIT_PAGE.path(id), { file: file });
  }

  async onDeleteClicked(id = null) {
    try {
      let file = this.state.files.find((p) => p.id === parseInt(id));
      let extraMessage =
        ' You will not be able to delete the file if it is associated with any projects though...';

      // Warn user before deleting
      let alert = Swal.mixin({
        titleText: 'Delete ' + file.name + '?',
        html: 'This action cannot be undone!' + extraMessage,
        showConfirmButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#f05050',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      });
      const result = await alert.fire('', undefined, undefined);
      if (result.value) {
        // If user confirmed, send request to the api
        this.setState({ isDeleting: true });

        FileController.delete(id)
          .then(() => this.getSourceFiles())
          .catch((error) => {
            this.errorHandler(error);
            this.setState({ isDeleting: false });
          });
      }
    } catch (e) {
      this.errorHandler(e, true);
    }
  }

  render() {
    let tableClassName = 'table-bordered' + (this.state.isFetching ? ' whirl standard' : '');

    // New button visibility
    let ButtonNewView;
    if (this.hasCreatePermission)
      ButtonNewView = (
        <Button outline color='success' size='lg' onClick={this.onNew}>
          {' '}
          New{' '}
        </Button>
      );

    return (
      <div>
        <div className='container mt-2'>
          <div className='jumbotron'>
            <Row>
              <Col md='11'>
                <h1>{'File List'}</h1>
              </Col>
              <Col md='1'>{ButtonNewView}</Col>
            </Row>
            <p className='text-muted'>
              This list contains all of the files on the platform. Depending on your permissions you
              will be able to edit, delete* or create new files
            </p>
            <p className='text-muted'>
              <small>
                *Only file/segment associations will be deleted and not the actual content
              </small>
            </p>

            <DataTable
              className={tableClassName}
              ref={this.dataTableRef}
              data={this.state.files}
              columns={this.columns}
              options={this.options}
              language={this.language}
              rowCallback={this.rowCallback}
            />
          </div>
        </div>
      </div>
    );
  }

  errorHandler(e) {
    console.log(e);
    Notifier.error(e['message']);
  }
}

const mapStateToProps = (state = {}, ownProps) => ({ ...ownProps, user: state?.session?.user });
export default connect(mapStateToProps, null)(withRouter(FileList));
