import React, {PureComponent} from 'react';
import $ from 'jquery';
import '../../../css/taus.datatables.scss';

require('datatables.net');

export default class DataTable extends PureComponent {

    constructor(props) {
        super(props);
        this.datatable = null;
    }

    componentDidMount() {
        this.$el = $(this.el);
        this.dataTable = this.$el.DataTable({
            data: this.props.data,
            columns: this.props.columns,
            language: this.props.language,
            ...this.props.options,
            rowCallback: this.props.rowCallback
        });
    }

    componentDidUpdate(){
        this.dataTable.clear();
        this.dataTable.rows.add(this.props.data);
        this.dataTable.draw();
    }

    componentWillUnmount() {
        this.dataTable.destroy(true);
    }

    render() {
        const styles = { cursor:'pointer', ...this.props?.styles };
        return <table style={styles} className={"col-12 px-0 taus-datatable pt-2 table table-hover " + this.props.className} ref={(el) => (this.el = el)} />;
    }
}