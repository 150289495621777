import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const editorToolbarOptions = {
  inline: { inDropdown: true },
  textAlign: { inDropdown: true },
  link: { inDropdown: true },
  options: [
    'colorPicker',
    'link',
    'image',
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'remove',
    'history',
  ],
};

const RichTextEditor = ({ placeholder, value, onChange, onBlur }) => {
  // A mechanism for the editor to mimic the bootstrap input field
  // i.e. change the border color to light blue on focus
  const [hasFocus, setHasFocus] = useState(false);
  const handleFocus = () => setHasFocus(true);
  const handleBlur = () => {
    setHasFocus(false);
    onBlur?.();
  };
  const editorStyle = {
    minHeight: 250,
    maxHeight: 250,
  };

  if (hasFocus) {
    editorStyle.borderColor = '#66afe9';
  }

  return (
    <Editor
      placeholder={placeholder}
      editorState={value}
      onEditorStateChange={onChange}
      editorClassName='form-control'
      editorStyle={editorStyle}
      onFocus={handleFocus}
      onBlur={handleBlur}
      toolbar={editorToolbarOptions}
    />
  );
};

export default RichTextEditor;
