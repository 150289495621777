// Css
import 'loaders.css/loaders.css';
import 'spinkit/css/spinkit.css';

// External libs
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CardBody, ButtonGroup } from 'reactstrap';
import $ from 'jquery';
import moment from 'moment';
import { json2excel } from 'js2excel';
import debounce from 'awesome-debounce-promise';
import { throttle } from 'throttle-debounce';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

// Internal libs
import Utils from '../../utils/Utils';
import { objectToQueryString, parseQueryString } from '../../utils/Url';
import DOMObject from '../../components/DOMObject';
import TaskController from '../../controllers/TaskController';
import ApiException from '../../models/ApiException';
import Notifier from '../../components/Notifier';
import { PAGES } from '../index';
import { Select } from '../../components/parameters/SelectParameter';
import { MultiSelect } from '../../components/parameters/MultiSelectParameter';
import DateParameter from '../../components/parameters/DateParameter';
import { Label } from '../../components/parameters/ParameterLabel';
import { Text } from '../../components/parameters/TextParameter';
import Tooltip from '../../components/Tooltip';
import { ACTIONS } from '../../redux/actions/pages';
import DataTable from '../../components/datatable/DatatableAjax';
import { ModalTitle, ProgressBar } from 'react-bootstrap';
import TASK from '../../models/Task';
import TASK_RESULT from '../../models/TaskResult';
import API_STORAGE from '../../storage/ApiStorage';
import { getTaskRemainingDateForClient, timestampToDuration } from '../../utils/DateHelper';
import TaskResultController from '../../controllers/TaskResultController';
import VisibleColumnsSelect from '../../components/tables/VisibleColumnsSelect';
import CheckboxWithValidation from '../../components/forms/CheckboxWithValidation';

// Setup moment
try {
  moment.locale(window.navigator.language);
} catch (e) {}

class WorkerTasks extends DOMObject {
  constructor(props) {
    super(props);

    this.datatable = null;
    this.state = this.loadUserPreferences();
    this.exportAbortController = new AbortController();

    try {
      this.state.filters = this.getUrlFilters();
      if (!Utils.isEmpty(this.state.filters)) this.savePageState();
      else this.setProps({ filters: this.getPropsFilters() });
    } catch (e) {
      this.errorHandler(e);
    }
  }

  static defaultProps = {
    tableColumnsVisible: [
      'action',
      'id',
      'taskId',
      'projectName',
      'typeName',
      'username',
      'reviewTaskId',
      'sourceLanguageCode',
      'targetLanguageCode',
      'segmentsApproved',
      'segmentsRejected',
      'status',
      'evaluationStatus',
      'paymentStatus',
      'timestampSubmitted',
      'submitTime',
      'machineTranslationQEResult',
    ],
    tableColumnsVisibilityPriority: [
      'action',
      'typeName',
      'status',
      'evaluationStatus',
      'paymentStatus',
      'username',
      'machineTranslationQEResult',
      'timestampSubmitted',
      'submitTime',
      'projectName',
      'id',
      'taskId',
      'reviewTaskId',
      'segmentsApproved',
      'segmentsRejected',
    ],
    tableColumnsFixed: ['action'],
    tableSort: [['id', 'desc']],
    emptyTableMessage: 'No tasks to show',
    saveUserState: null,
    filters: {},
  };

  PAGE_SID = Utils.define(PAGES.WORKER_TASKS_PAGE.sid(), 'TASK_MANAGER_PAGE');

  state = {
    filters: {},
    tableColumnsVisible: WorkerTasks.defaultProps.tableColumnsVisible,
    tasks: null,
    tasksIsLoading: true,

    table: {
      order: [{ columnName: 'id', direction: 'desc' }],
      page: 1,
      pageSize: 10,
      totalPages: 1,
      totalSize: 1,
      columnOrder: [],
    },

    taskExportDownloadedTasks: null,
    taskExportTotalTasks: null,
    taskExportStart: false,
  };

  filters = {
    id: {
      validate: (value = null) => {
        if (!this.filters.id.isValid(value)) return null;
        if (typeof value === 'number') return value >= 0 ? value : null;
        if (typeof value === 'string') return Utils.isEmpty(value) ? null : parseInt(value);
        return null;
      },
      isValid: (value = null) => {
        if (Utils.isNull(value)) return true;
        if (typeof value === 'number') return value >= 0;
        if (typeof value === 'string')
          return Utils.isEmpty(value) ? true : Utils.isNull(value.trim().match(/[\D]/g));
        return false;
      },
    },
    project: {
      validate: (value = null) => {
        return Utils.isEmpty(value) ? null : value;
      },
      isValid: (value = null) => true,
    },
    user: {
      validate: (value = null) => {
        return Utils.isEmpty(value) ? null : value;
      },
      isValid: (value = null) => true,
    },
    hasRollbackHistory: {
      validate: (value = null) => {
        return value;
      },
    },
    from: {
      validate: (value = null) => {
        return !this.filters.from.isValid(value) ? null : value;
      },
      isValid: (value = null) => {
        return Utils.isEmpty(value)
          ? true
          : moment(value, ['DD/MM/YYYY', 'D/MM/YYYY', 'DD/M/YYYY', 'D/M/YYYY'], true).isValid();
      },
    },
    to: {
      validate: (value = null) => {
        return !this.filters.to.isValid(value) ? null : value;
      },
      isValid: (value = null) => {
        return Utils.isEmpty(value)
          ? true
          : moment(value, ['DD/MM/YYYY', 'D/MM/YYYY', 'DD/M/YYYY', 'D/M/YYYY'], true).isValid();
      },
    },
    type: {
      validate: (value = null) => {
        if (!this.filters.type.isValid(value)) return null;
        if (Array.isArray(value))
          return Utils.isEmpty(value) ? null : value.map((obj) => obj.trim().toUpperCase());
        else return Utils.isNull(value) ? null : value.trim().toUpperCase();
      },
      isValid: (value = null) => {
        try {
          if (Array.isArray(value))
            return value.every((obj) =>
              Object.values(TASK.TYPE)
                .map((obj) => obj.sid)
                .includes(obj.trim().toUpperCase())
            );
          else
            return Utils.isNull(value)
              ? true
              : Object.values(TASK.TYPE)
                  .map((obj) => obj.sid)
                  .includes(value.trim().toUpperCase());
        } catch (e) {
          return false;
        }
      },
    },
    status: {
      validate: (value = null) => {
        if (Utils.isEmpty(value)) return null;
        if (Array.isArray(value)) {
          value = value
            .filter((obj) => this.filters.status.validate(obj))
            .map((obj) => obj.toUpperCase().trim())
            .map((obj) => (obj === 'IN PROGRESS' ? 'PENDING' : obj))
            .map((obj) => (obj === 'PENDING QE' ? 'PENDING_SUBMISSION_QE' : obj))
            .map((obj) => (obj === 'PENDING REVIEW' ? 'PENDING_REVIEW' : obj))
            .map((obj) => (obj === 'PENDING CHECK' ? 'PENDING_CHECK' : obj))
            .map((obj) => (obj === 'ROLLED BACK' ? 'ROLLED_BACK' : obj));
          if (value.length === 0) value = null;
        } else {
          if (!this.filters.status.isValid(value)) return null;
          value = value.toUpperCase();
          if (value === 'IN PROGRESS') value = 'PENDING';
          if (value === 'PENDING REVIEW') value = 'PENDING_REVIEW';
          if (value === 'PENDING CHECK') value = 'PENDING_CHECK';
          if (value === 'PENDING QE') value = 'PENDING_SUBMISSION_QE';
        }
        return value;
      },
      isValid: (value = null) => {
        try {
          if (Array.isArray(value))
            return value.every((obj) =>
              [
                'SUBMITTED',
                'PENDING',
                'IN PROGRESS',
                'ACTIVE',
                'REVIEWED',
                'PENDING_CHECK',
                'PENDING QE',
                'PENDING_SUBMISSION_QE',
                'PENDING CHECK',
                'PENDING REVIEW',
                'PENDING_REVIEW',
                'COMPLETED',
                'ROLLED BACK',
                'ROLLED_BACK',
              ].includes(obj.toUpperCase())
            );
          else
            return Utils.isNull(value)
              ? true
              : [
                  'SUBMITTED',
                  'PENDING',
                  'IN PROGRESS',
                  'ACTIVE',
                  'REVIEWED',
                  'PENDING REVIEW',
                  'PENDING_REVIEW',
                  'PENDING CHECK',
                  'PENDING_CHECK',
                  'PENDING QE',
                  'PENDING_SUBMISSION_QE',
                  'COMPLETED',
                  'ROLLED BACK',
                  'ROLLED_BACK',
                ].includes(value.toUpperCase());
        } catch (e) {
          return false;
        }
      },
    },
    evaluationStatus: {
      validate: (value = null) => {
        //if( !this.filters.status.isValid(value) ) return null;
        if (Utils.isEmpty(value)) return null;
        if (Array.isArray(value)) {
          value = value
            .filter((obj) => this.filters.evaluationStatus.validate(obj))
            .map((obj) => obj.toUpperCase().trim())
            .map((obj) => (obj === 'ΝΟΤ RISKY' ? 'ΝΟΤ_RISKY' : obj));
          if (value.length === 0) value = null;
        } else {
          if (!this.filters.evaluationStatus.isValid(value)) return null;
          value = value.toUpperCase();
          if (value === 'ΝΟΤ RISKY') value = 'ΝΟΤ_RISKY';
        }
        return value;
      },
      isValid: (value = null) => {
        try {
          if (Array.isArray(value))
            return value.every((obj) =>
              ['PASSED', 'FAILED', 'ΝΟΤ RISKY', 'ΝΟΤ_RISKY', 'RISKY', 'INACTIVE'].includes(
                obj.toUpperCase()
              )
            );
          else
            return Utils.isNull(value)
              ? true
              : ['PASSED', 'FAILED', 'ΝΟΤ RISKY', 'NOT_RISKY', 'RISKY', 'INACTIVE'].includes(
                  value.toUpperCase()
                );
        } catch (e) {
          return false;
        }
      },
    },
    paymentStatus: {
      validate: (value = null) => {
        if (Utils.isEmpty(value)) return null;
        if (Array.isArray(value)) {
          value = value
            .filter((obj) => this.filters.paymentStatus.validate(obj))
            .map((obj) => obj.toUpperCase().trim());
          if (value.length === 0) value = null;
        } else {
          if (!this.filters.paymentStatus.isValid(value)) return null;
          value = value.toUpperCase();
        }
        return value;
      },
      isValid: (value = null) => {
        try {
          if (Array.isArray(value))
            return value.every((obj) =>
              [
                'INACTIVE',
                'PENDING',
                'SUBMITTED',
                'NO_PAYMENT',
                'COMPLETED',
                'FAILED',
                'REJECTED',
                'UNKNOWN',
              ].includes(obj.toUpperCase())
            );
          else
            return Utils.isNull(value)
              ? true
              : [
                  'INACTIVE',
                  'PENDING',
                  'SUBMITTED',
                  'NO_PAYMENT',
                  'COMPLETED',
                  'FAILED',
                  'REJECTED',
                  'UNKNOWN',
                ].includes(value.toUpperCase());
        } catch (e) {
          return false;
        }
      },
    },
  };

  userPreferences = {
    tableColumnsVisible: [],
  };

  // --------------------------------------------------------------------------------------
  // ---------------------------------- Component Methods ---------------------------------
  // --------------------------------------------------------------------------------------
  componentDidUpdate(prevProps, prevState, snapshot) {
    try {
      let newFilters = {};
      let oldFilters = {};
      Object.keys(this?.filters).forEach((key) => {
        newFilters[key] = this.filters?.[key]?.validate(this.state?.filters?.[key]);
        oldFilters[key] = this.filters?.[key]?.validate(prevState?.filters?.[key]);
      });
      let dataTableReload = Object.keys(this?.filters).some(
        (key) =>
          JSON.stringify(Utils.define(oldFilters[key])) !==
          JSON.stringify(Utils.define(newFilters[key]))
      );
      if (dataTableReload === true && !Utils.isNull(this.datatable)) this.datatable.table.draw();

      if (this.props?.location?.search !== prevProps?.location?.search) {
        this.setState({ filters: this.getUrlFilters() });
      }

      if (this.state.tableColumnsVisible !== prevState.tableColumnsVisible) {
        this.saveUserPreferences();
      }

      this.savePageState();
    } catch (e) {
      this.errorHandler(e);
    }
  }

  render() {
    let tableProps = {
      ref: (ref) => {
        this.datatable = ref;
      },
      tableProps: this.dataTableProps(),
    };

    let filterIsEmpty = Object.values(Utils.define(this.state?.filters, {})).every((obj) =>
      Utils.isEmpty(obj)
    );

    return (
      <CardBody className='col-12 p-0 mt-2 bg-white'>
        <CardBody className='col-12 b'>
          <CardBody className='col-12 row p-0 mx-0 justify-content-between'>
            <div className='col-xs-12 col-sm-9 col-md-10 px-0 pt-2'>
              <h4 className='col-12'>
                Filters
                {filterIsEmpty === true ? null : (
                  <Tooltip title='Clear Filters'>
                    <button
                      id='clearFilters'
                      className='btn btn-secondary border-0 p-0 ml-3'
                      onClick={this.clearFiltersButtonClicked.bind(this)}
                    >
                      <em className='far fa-trash-alt' style={{ fontSize: '17px' }} />
                    </button>
                  </Tooltip>
                )}
              </h4>
              {this.renderFilterPanel()}
            </div>
            <ButtonGroup className='col-xs-12 col-sm-3 col-md-2 d-flex flex-row justify-content-end pt-2 m-0'>
              <div>
                <this.TaskExportButton />
              </div>
              <VisibleColumnsSelect
                tableColumnsOptions={this.dataTableProps().columns.filter(
                  (column) => column.name !== 'action'
                )}
                visibleColumns={this.state.tableColumnsVisible}
                tableVisibleColumnsSelectChanged={this.tableVisibleColumnsSelectChanged.bind(this)}
              />
            </ButtonGroup>
          </CardBody>
          <DataTable {...tableProps} className='col-12 taus-datatable px-0 pt-2 sphere whirl' />
        </CardBody>
        <this.TaskExportModal />
      </CardBody>
    );
  }

  TaskExportModal = () => {
    let progress = Utils.isNull(this.state.taskExportTotalTasks)
      ? 50
      : Math.floor(
          (100 * Utils.define(this.state.taskExportDownloadedTasks, 0)) /
            this.state.taskExportTotalTasks
        );
    progress = Math.max(progress, 5);
    return (
      <Modal isOpen={this.state.taskExportStart} size='md'>
        <ModalHeader className='bg-primary text-center'>
          <ModalTitle className='w-100 my-0'>Exporting Tasks to Excel</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {progress === 100 || this.state.taskExportStart !== true ? (
            <div className='d-flex flex-row'>
              <div className='ball-pulse pr-3'>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className='pr-3'>
                {'Converting ' + this.state.taskExportDownloadedTasks + ' tasks to excel...'}
              </div>
            </div>
          ) : (
            <div>
              <div className='pb-2'>
                {'Downloading tasks... (' +
                  Utils.define(this.state.taskExportDownloadedTasks, 0) +
                  ' of ' +
                  Utils.define(this.state.taskExportTotalTasks, 'Unknown') +
                  ')'}
              </div>
              <ProgressBar
                className='w-100'
                animated
                variant='success'
                now={Utils.define(progress, 50)}
                style={{ height: '15px' }}
              />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <button
            className='btn btn-outline-danger'
            onClick={this.exportToExcelButtonCancelClicked.bind(this)}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    );
  };

  TaskExportButton = () => {
    return (
      <Tooltip title='Export to Excel Sheet'>
        <button
          id='exportToExcelButton'
          className='btn btn-secondary'
          onClick={throttle(1000, this.exportToExcelButtonClicked.bind(this))}
        >
          <em className='fas fa-download' style={{ fontSize: '20px' }} />
        </button>
      </Tooltip>
    );
  };

  renderFilterPanel() {
    return (
      <>
        <div className='col-12 row mx-0 px-0'>
          {Utils.isNull(this.filters.project) ? null : (
            <Label
              label='Project:'
              className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'
            >
              <Text
                className='col-9'
                onChange={this.filterChanged.bind(this, 'project')}
                placeholder='Project Name'
                value={Utils.define(this.state?.filters?.project, '')}
              />
            </Label>
          )}
          {Utils.isNull(this.filters.user) ? null : (
            <Label
              label='User:'
              className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'
            >
              <Text
                className='col-9'
                onChange={this.filterChanged.bind(this, 'user')}
                placeholder='Username'
                value={Utils.define(this.state?.filters?.user, '')}
              />
            </Label>
          )}
          {Utils.isNull(this.filters.id) ? null : (
            <Label
              label='TaskRes ID:'
              className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'
            >
              <Text
                className='col-9'
                onChange={this.filterChanged.bind(this, 'id')}
                placeholder='#TaskRes ID'
                value={Utils.define(this.state?.filters?.id, '')}
                isValid={this.filters?.id?.isValid(this.state?.filters?.id)}
              />
            </Label>
          )}
          {Utils.isNull(this.filters.type) ? null : (
            <Label
              label='Type:'
              className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'
            >
              <Select className='col-8 px-0' {...this.filterTypeParameterProps()} />
            </Label>
          )}
        </div>
        <div className='col-12 row mx-0 px-0'>
          {Utils.isNull(this.filters.from) ? null : (
            <Label
              label='From:'
              className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'
            >
              <DateParameter
                className='col-9 px-0'
                onChange={(param) => {
                  this.filterChanged('from', param.getValue());
                }}
                updateValueFromProps={true}
                value={this.state?.filters?.from}
              />
            </Label>
          )}
          {Utils.isNull(this.filters.to) ? null : (
            <Label label='To:' className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'>
              <DateParameter
                className='col-9 px-0'
                onChange={(param) => {
                  this.filterChanged('to', param.getValue());
                }}
                updateValueFromProps={true}
                value={this.state?.filters?.to}
              />
            </Label>
          )}
          {Utils.isNull(this.filters.status) ? null : (
            <Label
              label='Status:'
              className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'
            >
              <MultiSelect className='col-9 px-0' {...this.filterStatusParameterProps()} />
            </Label>
          )}
          {Utils.isNull(this.filters.evaluationStatus) ? null : (
            <Label
              label='Evaluation:'
              className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'
            >
              <MultiSelect
                className='col-8 px-0'
                {...this.filterEvaluationStatusParameterProps()}
              />
            </Label>
          )}
        </div>
        <div className='col-12 row mx-0 px-0'>
          {Utils.isNull(this.filters.paymentStatus) ? null : (
            <Label
              label='Payment:'
              className='col-xs-12 col-md-6 col-xl-3 justify-content-between pt-1'
            >
              <MultiSelect className='col-9 px-0' {...this.filterPaymentStatusParameterProps()} />
            </Label>
          )}
          {Utils.isNull(this.filters.hasRollbackHistory) ? null : (
            <Label label='Has Rollback History:' className='col-xs-12 col-md-6 col-xl-3 pt-1'>
              <CheckboxWithValidation
                propertyName='hasRollbackHistory'
                className='col-1'
                isFormGroup={false}
                onChange={this.filterChanged.bind(this)}
                defaultValue={Boolean(this.state?.filters?.hasRollbackHistory)}
                onBlur={() => {}}
              />
            </Label>
          )}
        </div>
      </>
    );
  }

  // --------------------------------------------------------------------------------------
  // ---------------------------------- Component Configuration ---------------------------
  // --------------------------------------------------------------------------------------
  tableProps() {
    return {
      ...this.state.table,
      data: this.state.tasks,
      onTablePageChanged: this.tablePageUpdated.bind(this),
      onTablePageSizeChanged: this.tablePageSizeUpdated.bind(this),
      onTableSortingChanged: this.tableSortUpdated.bind(this),
    };
  }

  filterTypeParameterProps() {
    return {
      options: [
        { value: TASK.TYPE.REVIEW.sid, name: TASK.TYPE.REVIEW.name },
        { value: TASK.TYPE.TRANSLATION.sid, name: TASK.TYPE.TRANSLATION.name },
        { value: TASK.TYPE.MSI.sid, name: TASK.TYPE.MSI.name },
        { value: TASK.TYPE.POST_EDITING.sid, name: TASK.TYPE.POST_EDITING.name },
        { value: TASK.TYPE.SPEECH_RECORDING.sid, name: TASK.TYPE.SPEECH_RECORDING.name },
        {
          value: TASK.TYPE.SPEECH_RECORDING_REVIEW.sid,
          name: TASK.TYPE.SPEECH_RECORDING_REVIEW.name,
        },
        { value: TASK.TYPE.TEXT_ANNOTATION.sid, name: TASK.TYPE.TEXT_ANNOTATION.name },
        {
          value: TASK.TYPE.TEXT_ANNOTATION_REVIEW.sid,
          name: TASK.TYPE.TEXT_ANNOTATION_REVIEW.name,
        },
      ],
      value: Utils.defineToArray(this.filters?.type?.validate(this.state?.filters?.type), []),
      updateValueFromProps: true,
      onChange: this.filterChanged.bind(this, 'type'),
      typeOptions: { placeholder: 'Task Type', isMulti: false },
    };
  }

  filterStatusParameterProps() {
    return {
      options: [
        { value: TASK.STATUS.ACTIVE, name: 'Active' },
        { value: TASK.STATUS.PENDING, name: 'In Progress' },
        { value: TASK.STATUS.SUBMITTED, name: 'Submitted' },
        { value: TASK.STATUS.PENDING_SUBMISSION_QE, name: 'Pending QE' },
        { value: TASK.STATUS.PENDING_CHECK, name: 'Pending check' },
        { value: TASK.STATUS.PENDING_REVIEW, name: 'Pending review' },
        { value: TASK.STATUS.REVIEWED, name: 'Reviewed' },
        { value: TASK.STATUS.ROLLED_BACK, name: 'Rolled Back' },
      ],
      value: Utils.defineToArray(this.filters?.status?.validate(this.state?.filters?.status), []),
      onChange: this.filterChanged.bind(this, 'status'),
      typeOptions: { placeholder: 'Task Status' },
    };
  }

  filterEvaluationStatusParameterProps() {
    return {
      options: [
        { value: TASK_RESULT.EVALUATION_STATUS.PASSED, name: 'Passed' },
        { value: TASK_RESULT.EVALUATION_STATUS.FAILED, name: 'Failed' },
        { value: TASK_RESULT.EVALUATION_STATUS.RISKY, name: 'Risky' },
        { value: TASK_RESULT.EVALUATION_STATUS.NOT_RISKY, name: 'Not Risky' },
      ],
      value: Utils.defineToArray(
        this.filters?.evaluationStatus?.validate(this.state?.filters?.evaluationStatus),
        []
      ),
      onChange: this.filterChanged.bind(this, 'evaluationStatus'),
      typeOptions: { placeholder: 'Evaluation Status' },
    };
  }

  filterPaymentStatusParameterProps() {
    return {
      options: [
        { value: TASK_RESULT.PAYMENT_STATUS.INACTIVE, name: 'Inactive' },
        { value: TASK_RESULT.PAYMENT_STATUS.PENDING, name: 'Pending' },
        { value: TASK_RESULT.PAYMENT_STATUS.SUBMITTED, name: 'Submitted' },
        { value: TASK_RESULT.PAYMENT_STATUS.NO_PAYMENT, name: 'No Payment' },
        { value: TASK_RESULT.PAYMENT_STATUS.COMPLETED, name: 'Completed' },
        { value: TASK_RESULT.PAYMENT_STATUS.FAILED, name: 'Failed' },
        { value: TASK_RESULT.PAYMENT_STATUS.REJECTED, name: 'Rejected' },
        { value: TASK_RESULT.PAYMENT_STATUS.UNKNOWN, name: 'Unknown' },
      ],
      value: Utils.defineToArray(
        this.filters?.paymentStatus?.validate(this.state?.filters?.paymentStatus),
        []
      ),
      onChange: this.filterChanged.bind(this, 'paymentStatus'),
      typeOptions: { placeholder: 'Payment Status' },
    };
  }

  dataTableProps() {
    let getVisiblePriority = (name) => {
      let index = this.props?.tableColumnsVisibilityPriority?.indexOf(name);
      return index < 0 ? 1000 : index + 1;
    };
    let getVisible = (name) => this.state.tableColumnsVisible.includes(name);

    let thisObject = this;
    return {
      //data: thisObject.state.tasks,
      responsive: { details: { type: 'none', target: 'tr' } },
      colReorder: { enable: true, fixedColumnsRight: 1, realtime: false },
      paging: true,
      pageLength: 25,
      ordering: true,
      autoWidth: true,
      //scrollX: true,
      //scrollCollapse: false,
      rowId: (row) => {
        return row.id;
      },
      order: [[0, 'desc']],
      columns: [
        {
          // 1
          name: 'id',
          title: 'TaskRes ID',
          className: 'taus-datatable-col-reorder text-nowrap text-center',
          responsivePriority: getVisiblePriority('id'),
          visible: getVisible('id'),
          data: (row, type, set, meta) => {
            return Utils.define(row.id, 0);
          },
        },
        {
          // 1
          name: 'taskId',
          title: 'Task ID',
          className: 'taus-datatable-col-reorder text-nowrap text-center',
          responsivePriority: getVisiblePriority('taskId'),
          visible: getVisible('taskId'),
          data: (row, type, set, meta) => {
            return row?.task?.id ?? null;
          },
        },
        {
          // 2
          name: 'projectName',
          title: 'Project',
          orderable: true,
          className: 'taus-datatable-col-reorder',
          responsivePriority: getVisiblePriority('projectName'),
          visible: getVisible('projectName'),
          data: (row, type, set, meta) => {
            try {
              return Utils.define(row.project.name, '');
            } catch (e) {
              this.errorHandler(e);
              return '';
            }
          },
        },
        {
          // 3
          name: 'typeName',
          title: 'Type',
          orderable: true,
          className: 'taus-datatable-col-reorder',
          responsivePriority: getVisiblePriority('typeName'),
          visible: getVisible('typeName'),
          data: (row, type, set, meta) => row?.type?.name ?? '',
        },
        {
          // 4
          name: 'reviewTaskId',
          title: 'RW/TR task ID',
          orderable: false,
          className: 'text-center taus-datatable-col-reorder',
          responsivePriority: getVisiblePriority('reviewTaskId'),
          visible: getVisible('reviewTaskId'),
          data: (row, type, set, meta) => {
            return Utils.define(row?.reviewTask?.id, '');
          },
        },
        {
          // 5
          name: 'username',
          title: 'User',
          orderable: true,
          className: 'taus-datatable-col-reorder',
          responsivePriority: getVisiblePriority('username'),
          visible: getVisible('username'),
          data: (row, type, set, meta) => {
            try {
              return Utils.define(row.worker.username, '');
            } catch (e) {
              return '';
            }
          },
        },
        {
          // 6
          name: 'sourceLanguageCode',
          title: 'Source Language',
          orderable: true,
          className: 'taus-datatable-col-reorder text-center',
          responsivePriority: getVisiblePriority('sourceLanguageCode'),
          visible: getVisible('sourceLanguageCode'),
          data: (row, type, set, meta) => row?.sourceLanguage?.code ?? '',
        },
        {
          // 7
          name: 'targetLanguageCode',
          title: 'Target Language',
          orderable: true,
          className: 'taus-datatable-col-reorder text-center',
          responsivePriority: getVisiblePriority('targetLanguageCode'),
          visible: getVisible('targetLanguageCode'),
          data: (row, type, set, meta) => {
            try {
              return Utils.define(row?.targetLanguage?.code, '');
            } catch (e) {
              this.errorHandler(e);
              return '';
            }
          },
        },
        {
          // 8
          name: 'segments',
          title: 'Segments',
          orderable: false,
          className: 'taus-datatable-col-reorder text-center',
          responsivePriority: getVisiblePriority('segments'),
          visible: getVisible('segments'),
          data: (row, type, set, meta) => {
            return Utils.define(row.segmentsNum, '');
          },
        },
        {
          // 9
          name: 'segmentsApproved',
          title: 'Segments Approved',
          orderable: true,
          className: 'taus-datatable-col-reorder text-center',
          responsivePriority: getVisiblePriority('segmentsApproved'),
          visible: getVisible('segmentsApproved'),
          data: (row, type, set, meta) => {
            try {
              if (type !== 'display') return Utils.define(row.segmentsApprovedNum, '');
              else
                return (
                  '<span class="text-success">' +
                  Utils.define(row.segmentsApprovedNum, '') +
                  '</span>'
                );
            } catch (e) {
              this.errorHandler(e);
              return '';
            }
          },
        },
        {
          // 10
          name: 'segmentsRejected',
          title: 'Segments Rejected',
          orderable: true,
          className: 'taus-datatable-col-reorder text-center',
          responsivePriority: getVisiblePriority('segmentsRejected'),
          visible: getVisible('segmentsRejected'),
          data: (row, type, set, meta) => {
            try {
              if (type !== 'display') return Utils.define(row.segmentsRejectedNum, '');
              else
                return (
                  '<span class="text-danger">' +
                  Utils.define(row.segmentsRejectedNum, '') +
                  '</span>'
                );
            } catch (e) {
              this.errorHandler(e);
              return '';
            }
          },
        },
        {
          // 11
          name: 'words',
          title: 'Words',
          orderable: false,
          className: 'taus-datatable-col-reorder text-center',
          rrsponsivePriority: getVisiblePriority('words'),
          visible: getVisible('words'),
          data: (row, type, set, meta) => {
            return row?.sourceWordsNum ?? '';
          },
        },
        {
          // 12
          name: 'credits',
          title: 'Credits',
          orderable: true,
          className: 'taus-datatable-col-reorder text-center',
          responsivePriority: getVisiblePriority('credits'),
          visible: getVisible('credits'),
          data: (row, type, set, meta) => {
            return Utils.define(row.credits, '');
          },
        },
        {
          // 13
          name: 'machineTranslationQEResult',
          title: 'MT QE',
          orderable: true,
          className: 'taus-datatable-col-reorder text-center',
          responsivePriority: getVisiblePriority('machineTranslationQEResult'),
          visible: getVisible('machineTranslationQEResult'),
          data: (row, type, set, meta) => {
            try {
              if (row?.machineTranslationQEResult?.segmentRatio === undefined) return '';
              let segmentRatio = row.machineTranslationQEResult.segmentRatio;
              segmentRatio = Math.round(segmentRatio * 100);
              if (type !== 'display') return segmentRatio;
              else
                return `<span class="${
                  row?.machineTranslationQEResult?.isPassed ?? true ? '' : 'text-danger'
                }">${segmentRatio ?? ''}%</span>`;
            } catch (e) {
              this.errorHandler(e);
              return '';
            }
          },
        },
        {
          // 21
          name: 'totalRecordedTime',
          title: 'Total Recorded Time',
          orderable: false,
          className: 'taus-datatable-col-reorder',
          responsivePriority: getVisiblePriority('totalRecordedTime'),
          visible: getVisible('totalRecordedTime'),
          data: (row) => {
            const totalRecordingDuration = Utils.isNull(row.metadata) ? '' :
                        moment(moment.duration(parseFloat(row.metadata?.totalRecordingDuration),
                  'seconds').asMilliseconds()).subtract({ hours: 1 }).format('HH:mm:ss');
            try {
              if(!!row.metadata?.totalRecordingDuration){
                return (
                  `<span class="">
                     ${totalRecordingDuration}
                    </span>`
                );
              } else return '';
            } catch (e) {
              this.errorHandler(e);
              return '';
            }
          },
        },
        {
          // 18
          name: 'submitTime',
          title: 'Submit Time',
          orderable: true,
          className: 'taus-datatable-col-reorder text-center',
          responsivePriority: getVisiblePriority('submitTime'),
          visible: getVisible('submitTime'),
          data: (row, type, set, meta) => {
            return row.submitTime ?? null;
          },
          render: (data, type, row, meta) => {
            return type !== 'display' ? data : timestampToDuration(data) ?? '';
          },
        },
        {
          // 15
          name: 'status',
          title: 'Status',
          width: '150px',
          className: 'taus-datatable-col-reorder',
          orderable: true,
          responsivePriority: getVisiblePriority('status'),
          visible: getVisible('status'),
          data: (row, type, set, meta) => {
            const version = row?.version;
            try {
              let status = TASK_RESULT.getStatusForWorker(row);
              if (
                status.toLocaleUpperCase() === 'IN PROGRESS' &&
                moment(row?.timestampExpires).isValid()
              ) {
                const timestampExpires = getTaskRemainingDateForClient(row?.timestampExpires);

                status =
                  status +
                  '<br/> (expires on ' +
                  moment(timestampExpires).format('YYYY-MM-DD HH:mm') +
                  ')';
              } else if (status.toLocaleUpperCase() === 'ROLLED BACK') {
                status =
                  `<Tooltip title='This task has been requested to be worked on again.'><span class='tw-underline tw-cursor-help tw-text-red-400'>` +
                  status +
                  '</span></Tooltip>';
              }

              return status + (version > 0 ? ' - ' + version : '');
            } catch (e) {
              this.errorHandler(e);
              return '';
            }
          },
        },
        {
          // 16
          name: 'evaluationStatus',
          title: 'Evaluation',
          width: '150px',
          className: 'taus-datatable-col-reorder',
          orderable: true,
          responsivePriority: getVisiblePriority('evaluationStatus'),
          visible: getVisible('evaluationStatus'),
          data: (row, type, set, meta) => TASK_RESULT.getEvaluationStatusForAdmin(row),
        },
        {
          // 17
          name: 'paymentStatus',
          title: 'Payment Status',
          width: '150px',
          className: 'taus-datatable-col-reorder',
          orderable: true,
          responsivePriority: getVisiblePriority('paymentStatus'),
          visible: getVisible('paymentStatus'),
          data: (row, type, set, meta) => TASK_RESULT.getPaymentStatusForAdmin(row),
        },
        {
          // 19
          name: 'timestampSubmitted',
          title: 'Date Submitted',
          orderable: true,
          className: 'taus-datatable-col-reorder',
          responsivePriority: getVisiblePriority('timestampSubmitted'),
          visible: getVisible('timestampSubmitted'),
          data: (row, type, set, meta) => {
            return row.timestampSubmitted;
          },
          render: (data, type, row, meta) => {
            if (type !== 'display') return Utils.define(data, 0);
            return Utils.isNull(data) ? '' : moment(data).format('YYYY-MM-DD HH:mm');
          },
        },
        {
          // 20
          name: 'action',
          title: '',
          width: '80px',
          orderable: false,
          className: 'text-right',
          responsivePriority: getVisiblePriority('action'),
          visible: true, // set to 'true' directly since we always need this column to get displayed
          data: (row, type, set, meta) => {
            try {
              const permissions = Array.isArray(row.permissions) ? row.permissions : [];
              let action = null;
              if (permissions.includes(TASK_RESULT.PERMISSIONS.READ)) action = 'Open';
              else if (permissions.includes(TASK_RESULT.PERMISSIONS.DATA_EDIT)) action = 'Resume';
              else if (permissions.includes(TASK_RESULT.PERMISSIONS.EDIT)) action = 'Review';
              else if (Utils.isEmpty(permissions)) action = 'Pending';
              if (type !== 'display') return action;
              return action
                ? `<button style="width:80px" class="task-open-button btn ${
                    action === 'Pending' ? 'btn-secondary disabled' : 'btn-primary'
                  } py-2">${action}</button>`
                : action;
            } catch (e) {
              this.errorHandler(e);
              return '';
            }
          },
        },
      ],
      language: {
        emptyTable: this.props.emptyTableMessage,
        zeroRecords: this.props.emptyTableMessage,
        lengthMenu: 'Tasks per pages :&nbsp;&nbsp; _MENU_',
        info: '&nbsp;&nbsp;_START_-_END_ of _TOTAL_',
        infoEmpty: '&nbsp;&nbsp;_START_-_END_ of _TOTAL_',
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        select: { rows: { _: '' } },
      },
      rowCallback: (row, data, displayNum, displayIndex, dataIndex) => {
        try {
          // Init the task claim buttons
          $(row).find('.task-open-button').off('click');
          $(row)
            .find('.task-open-button')
            .on(
              'click',
              thisObject.openButtonClicked.bind(
                thisObject,
                row.id,
                row.querySelector('.task-open-button').innerText
              )
            );
          // Init row color when task has error notes
          // let alerts = data.notes.filter(obj=>{ return (obj.type === "ALERT" || obj.type === "ERROR") });
          // if( alerts.length > 0 ) row.classList.add("text-danger");
          if (data.status === TASK.STATUS.PENDING_CHECK) row.classList.add('text-danger');
          // Set classes for hidden columns
          if (!Utils.isNull(thisObject.table)) {
            if (thisObject.table.getScreenHiddenColumns().length > 0) {
              row.classList.add('taus-text-underline');
              row.classList.add('taus-pointer');
            } else {
              row.classList.remove('taus-text-underline');
              row.classList.remove('taus-pointer');
            }
          }
        } catch (e) {
          this.errorHandler(e);
        }
      },
      serverSide: true,
      ajax: (data, callback, settings) => {
        let filters = DataTable.getAjaxParameters(data);
        filters = { ...filters, ...thisObject.filtersMapping() };
        //filters['sql_status'] = "status >= PENDING";
        thisObject.setBusy(true, '.taus-datatable');
        thisObject
          .getTasks(filters)
          .then((tasks) => {
            thisObject.state.tasksTotalSize = tasks.totalSize;
            callback({
              draw: data.draw,
              recordsFiltered: tasks.totalSize,
              recordsTotal: tasks.totalSize,
              data: tasks.page,
            });
            thisObject.setBusy(false, '.taus-datatable');
          })
          .catch((e) => {
            thisObject.errorHandler(e);
          });
      },
    };
  }

  // --------------------------------------------------------------------------
  // ------------------------------ User Preferences --------------------------
  // --------------------------------------------------------------------------
  saveUserPreferences() {
    try {
      let updatedKeys = Object.keys(this.userPreferences).filter(
        (key) => this.userPreferences[key] !== this.state[key]
      );
      if (updatedKeys?.length > 0) {
        updatedKeys.forEach((key) => {
          this.userPreferences[key] = this.state[key];
        });
        API_STORAGE.USER_PREFERENCES.add({ [this.PAGE_SID]: this.userPreferences });
      }
    } catch (e) {
      this.errorHandler(e);
    }
  }

  loadUserPreferences() {
    try {
      let prefs = API_STORAGE.USER_PREFERENCES.read()?.[this.PAGE_SID];
      return Utils.isEmpty(prefs) ? this.state : { ...this.state, ...prefs };
    } catch (e) {
      this.errorHandler(e);
    }
  }

  // ------------------------------------------------------------------------------
  // -------------------------------- Listeners -----------------------------------
  // ------------------------------------------------------------------------------
  tableSortUpdated(sort) {
    this.state.table.order = sort;
    this.datatable.draw();
    this.updateTasks();
  }
  tablePageUpdated(page) {
    this.state.table.page = page + 1;
    this.updateTasks();
  }
  tablePageSizeUpdated(size) {
    this.state.table.pageSize = size;
    this.updateTasks();
  }

  updateTasks() {
    let filters = this.filtersMapping();
    filters = { ...filters, ...this.getTableFilters() };
    this.getTasks(filters)
      .then((data) => {
        this.setState({
          tasks: Utils.defineArray(data?.page, []),
          table: {
            ...this.state.table,
            page: data.pageIndex,
            pageSize: data.pageSize,
            totalPages: data.totalPage,
            totalSize: data.totalSize,
          },
        });
      })
      .catch((e) => {
        this.errorHandler(e);
      });
  }

  getTableFilters() {
    let order = '';
    if (Array.isArray(this.state?.table?.order)) {
      this.state.table.order.forEach((obj) => {
        if (order !== '') order = order + ', ';
        order = obj.columnName + ' ' + obj.direction;
      });
    }

    let filters = {};
    filters.page = Utils.define(this.state?.table?.page, 1);
    filters.pageSize = Utils.define(this.state?.table?.pageSize, 1);
    if (order !== '') filters.orderBy = order;

    return filters;
  }

  tableVisibleColumnsSelectChanged(value) {
    try {
      this.setState({ tableColumnsVisible: value });
      this.datatable.setUserVisibleColumns(this.props.tableColumnsFixed.concat(value));
    } catch (e) {
      this.errorHandler(e, 'Table columns update failed.');
    }
  }

  filterChanged(name, value) {
    if (name === 'hasRollbackHistory') {
      this.setState({ hasRollbackHistory: !this.state?.filters?.hasRollbackHistory });
      value = !this.state?.filters?.hasRollbackHistory ? true : null;
    }

    let filter = this?.filters?.[name];
    if (Utils.isNull(filter)) return;

    let filters = { ...this.state.filters };
    if (Utils.isEmpty(value)) delete filters?.[name];
    else filters[name] = value;

    this.setProps({ filters: filters });
  }

  clearFiltersButtonClicked() {
    this.setProps({ filters: {} });
  }

  async exportToExcelButtonClicked() {
    try {
      let filters = this.filtersMapping();
      this.downloadTasks(filters);
    } catch (e) {
      this.errorHandler(e);
    }
  }

  async exportToExcelButtonCancelClicked() {
    try {
      this.exportAbortController.abort();
    } catch (e) {
      this.errorHandler(e);
    }
  }

  // Continue task button listener
  openButtonClicked(rowId = null, action = '') {
    try {
      if (['Open', 'Review', 'Resume'].includes(action)) {
        this.setBusy(true);
        let scope =
          action === 'RESUME' ? TASK_RESULT.USER_SCOPE.WORKER : TASK_RESULT.USER_SCOPE.REVIEWER;
        this.props.history.push(PAGES.TASK_RESULT_PAGE.path(rowId), {
          back: this.props?.location,
          scope: scope,
        });
        this.setBusy(false);
      }
      if (action === 'Claim') {
        this.setBusy(true);
        this.userClaimTask(rowId)
          .then((task) => {
            this.props.history.push(PAGES.TASK_RESULT_PAGE.path(task.id), {
              back: this.props?.location,
              scope: TASK_RESULT.USER_SCOPE.WORKER,
            });
          })
          .catch((e) => {
            this.errorHandler(e, true);
          })
          .finally(() => {
            this.setBusy(false);
          });
      }
    } catch (e) {
      this.errorHandler(e, true);
    }
  }

  // ------------------------------------------------------------------------------
  // -------------------------------- Methods -------------------------------------
  // ------------------------------------------------------------------------------
  getPropsFilters(props = null) {
    let filters = {};
    try {
      props = Utils.isNull(props) ? this.props : props;
      Object.keys(Utils.define(props?.filters, {})).forEach((key) => {
        if (!Utils.isEmpty(this.filters[key])) filters[key] = props.filters[key];
      });
    } catch (e) {
      this.errorHandler(e);
    }
    return filters;
  }

  getUrlFilters(location = null) {
    try {
      location = Utils.isNull(location) ? this.props?.location : location;
      return parseQueryString(location?.search);
    } catch (e) {
      this.errorHandler(e);
      return {};
    }
  }

  savePageState() {
    if (!Utils.isNull(this.props.saveUserState)) this.props.saveUserState(this.state);
  }

  setProps(props = {}) {
    if (Utils.isDefined(props.filters)) {
      this.props.history.replace({
        pathname: this.props?.location?.pathname,
        search: objectToQueryString(props?.filters),
      });
    }
  }

  filtersMapping() {
    let filters = {};
    Object.keys(Utils.define(this.state?.filters, {})).forEach((key) => {
      let filter = Utils.define(this.filters[key]);
      if (Utils.isNull(filter)) return;
      let value = filter.validate(this.state?.filters[key]);
      if (Utils.isNull(value)) return;

      if (key === 'user') filters['sql_username'] = 'username ilike "%' + value + '%"';
      else if (key === 'project') filters['sql_project'] = 'projectName ilike "%' + value + '%"';
      else if (key === 'from')
        filters['sql_from'] =
          'timestampSubmitted >= ' + moment(value, 'DD/MM/YYYY').utc().valueOf();
      else if (key === 'to')
        filters['sql_to'] = 'timestampSubmitted < ' + moment(value, 'DD/MM/YYYY').utc().valueOf();
      else if (key === 'type') filters['typeSid'] = this.state.filters?.[key];
      else filters[key] = value;
    });
    return filters;
  }

  // User continue task method
  userOpenTask(id) {
    return TaskResultController.get(id);
  }

  userClaimTask(id) {
    return TaskController.request(id);
  }

  // User get task results method
  getTasks = debounce(this.getTasksNotDebounced, 1000);
  async getTasksNotDebounced(filters = {}) {
    return TaskResultController.getAll(filters);
  }

  async downloadTasks(filters = {}) {
    try {
      this.setState({ taskExportStart: true });

      filters.pageSize = 5000;
      filters.page = 1;
      let totalTasks = this.state.tasksTotalSize;
      let pages = [];
      let response = null;
      do {
        totalTasks = response?.totalSize ?? totalTasks;
        this.setState({
          taskExportDownloadedTasks: (filters.page - 1) * filters.pageSize,
          taskExportTotalTasks: totalTasks,
        });

        response = await TaskResultController.getAll(
          filters,
          { 'serialize-permission': false },
          { signal: this.exportAbortController.signal }
        );
        pages.push(response.page);
        filters.page++;
      } while (filters.page <= response.totalPage);

      this.setState({
        taskExportDownloadedTasks: totalTasks,
        taskExportTotalTasks: totalTasks,
      });

      let tasks = [];
      for (let i = 0; i < pages.length; i++) {
        tasks.push(...(await this.tasksJsonToExcelFormat(pages[i])));
      }
      this.exportToExcel(tasks, 'tasks');
    } catch (e) {
      this.setState({
        taskExportDownloadedTasks: null,
        taskExportTotalTasks: null,
        taskExportStart: false,
      });
      this.errorHandler(e);
    }
  }

  async exportToExcel(data, filename) {
    setTimeout(() => {
      json2excel({ data: data, name: filename, formatdate: 'dd/mm/yyyy' });
      this.setState({
        taskExportDownloadedTasks: null,
        taskExportTotalTasks: null,
        taskExportStart: false,
      });
    }, 200);
  }

  async tasksJsonToExcelFormat(tasks = []) {
    try {
      tasks = Utils.defineArray(tasks, []);
      let excel = [];
      tasks.forEach((obj) => {
        excel.push({
          'TaskResult ID': obj.id,
          'Task ID': obj.task.id,
          'Project ID': obj.project?.id ?? null,
          'Project Name': obj.project?.name ?? '',
          Type: obj.type?.name ?? '',
          'RW/TR Task ID': obj.reviewTask?.id ?? null,
          'User ID': obj.worker?.id ?? null,
          Username: obj.worker?.username ?? null,
          Credits: obj.credits,
          Status: obj.status,
          'Evaluation Status': obj.evaluationStatus ?? '',
          'Payment Status': obj.paymentStatus,
          Segments: obj.segmentsNum ?? '',
          'Source Words': obj.sourceWordsNum ?? '',
          'Source Language': obj.sourceLanguage?.code,
          'Target Language': obj.targetLanguage?.code,
          'Approved Segments': obj.segmentsApprovedNum ?? '',
          'Rejected Segments': obj.segmentsRejectedNum ?? '',
          'MT QE': obj.machineTranslationQEResult?.segmentRatio ?? '',
          'Rollback Version': obj.version,
          'Submit Time': timestampToDuration(obj.submitTime) ?? '',
          'Date Claimed': Utils.isNull(obj.timestampCreated)
            ? ''
            : moment(obj.timestampCreated).format('YYYY-MM-DD HH:mm'),
          'Date Submitted': Utils.isNull(obj.timestampSubmitted)
            ? ''
            : moment(obj.timestampSubmitted).format('YYYY-MM-DD HH:mm'),
          'Date Reviewed': Utils.isNull(obj.timestampReviewed)
            ? ''
            : moment(obj.timestampReviewed).format('YYYY-MM-DD HH:mm'),
        });
      });
      return excel;
    } catch (e) {
      this.errorHandler(e);
      return [];
    }
  }

  // --------------------------------------------------------------------------
  // ------------------------------ Errors ------------------------------------
  // --------------------------------------------------------------------------
  errorHandler(e = null, message = null) {
    try {
      e = ApiException.toApiException(e, message);
      Notifier.error(Utils.isNull(message) ? e.userMessage : message);
      super.errorHandler(e);
    } catch {
      super.errorHandler(e);
    }
  }
}

// ---------------------------------------------------------------------------
// ------------------------------------ PropTypes ----------------------------
// ---------------------------------------------------------------------------
WorkerTasks.propTypes = {
  emptyTableMessage: PropTypes.string.isRequired,
  filters: PropTypes.object,
  history: PropTypes.any.isRequired,
  saveUserState: PropTypes.func.isRequired,
  tableColumnsFixed: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableColumnsVisibilityPriority: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableColumnsVisible: PropTypes.arrayOf(PropTypes.string),
  tableSort: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

// ---------------------------------------------------------------------------
// ------------------------------------ Redux --------------------------------
// ---------------------------------------------------------------------------
const mapStateToProps = (state = {}, ownProps = {}) => {
  return { ...ownProps, ...Utils.define(state?.pages?.[PAGES.WORKER_TASKS_PAGE.sid()], {}) };
};
const mapDispatchToProps = (dispatch = null, ownProps = {}) => {
  return {
    saveUserState: (state) =>
      dispatch(ACTIONS.saveUserState(PAGES.WORKER_TASKS_PAGE.sid(), { filters: state.filters })),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkerTasks));
