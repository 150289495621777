import React, { useEffect, useState } from 'react';
import PublicProjectsListingPageView from '../views/PublicProjectsListingPageView';
import ProjectController from '../../../controllers/ProjectController';
import Notifier from '../../../components/Notifier';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { PAGES } from '../../index';
import Utils from '../../../utils/Utils';
import { useSelector } from 'react-redux';

const PublicProjectsListingPageState = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [privateProjects, setPrivateProjects] = useState([]);
  const user = useSelector((state) => Utils.define(state?.session?.user));

  const getProjects = () => {
    setLoading(true);
    try {
      ProjectController.getAll({ size: 500 })
        .then((r) => {
          const projects = r.content.sort((a, b) => b.project.id - a.project.id);

          if (user) {
            setAvailableProjects(
              projects.filter((project) => !project.isMember && !project.isApplicant)
            );
            setPrivateProjects(
              projects.filter((project) => project.isMember || project.isApplicant)
            );
          } else {
            setAvailableProjects(
              projects.filter((project) => project.project.visibility === 'PUBLIC')
            );
            setPrivateProjects(
              projects.filter((project) => project.project.visibility === 'PRIVATE')
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      Notifier.error(e.message);
    }
  };

  const handleApply = (projectId) => {
    setLoading(true);

    if (!user) {
      history.push(PAGES.SIGN_IN.path(), {
        from: {
          pathname: PAGES.PUBLIC_PROJECTS_LISTING_PAGE.path(),
        },
      });
    }

    ProjectController.addApplicant(projectId).then(
      () => {
        setLoading(false);
        history.push(PAGES.PUBLIC_PROJECT_LOBBY_PAGE.path(projectId), {
          from: {
            pathname: PAGES.PUBLIC_PROJECTS_LISTING_PAGE.path(),
          },
        });
      },
      () => setLoading(false)
    );
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <PublicProjectsListingPageView
      loading={loading}
      availableProjects={availableProjects}
      userProjects={privateProjects}
      handleApply={handleApply}
    />
  );
};

export default PublicProjectsListingPageState;
