// import CSS
import '../template/src/styles/whirl/dist/whirl.css';
//import 'whirl/dist/whirl.css';
// Font Awesome
import '@fortawesome/fontawesome-free-webfonts/css/fa-brands.css';
import '@fortawesome/fontawesome-free-webfonts/css/fa-regular.css';
import '@fortawesome/fontawesome-free-webfonts/css/fa-solid.css';
import '@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';
// Animate.CSS
import 'animate.css/animate.min.css';
// Simple line icons
import 'simple-line-icons/dist/styles/simple-line-icons.css';

// Roboto Font
import '../assets/fonts/Roboto/index.css';

// DQF Theme from Themicon
import '../theme-dqf.scss';

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ACTIONS as SESSION_ACTION } from '../redux/actions/session';
import SessionController from '../controllers/SessionController';

import Utils from '../utils/Utils';
import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';
import Spinner from '../components/Spinner';

import { PAGES } from '.';
import Login from './Login';
import RegisterPage from './profile/RegisterPage';
import Home from './Home';
import TaskPage from './task/TaskPage';
import UserTasksAvailable from './task/UserTasksAvailable';
import UserTasks from './task/UserTasks';
import WorkerTasks from './task/WorkerTasks';
import UserPage from './user/UsersPage';
import UserEditPage from './user/UserEditPage';
import UsersReferralPage from './user/UserReferralsPage';
import UsersRefereesPage from './user/UserRefereesPage';
import UserGroupsPage from './user/UserGroupsPage';
import ProfilePage from './profile/ProfilePage';
import ProjectCreatePageContainer from './project/ProjectCreatePageContainer';
import ProjectCopyPageContainer from './project/ProjectCopyPageContainer';
import ProjectPageContainer from './project/ProjectPageContainer';
import ProjectList from './project/ProjectList';
import PublicProjectsListingPage from './public-projects/PublicProjectsListingPage';
import PublicProjectPage from './public-projects/PublicProjectPage';
import TestLandingPageContainer from './tests/TestLandingPageContainer';
import DataCollectionFormContainer from './forms/DataCollectionFormContainer';
import CreateFilePage from './file/CreateFilePage';
import FileList from './file/FileList';
import PrivateRoute from './PrivateRoute';
import UserGroupPage from './user/UserGroupPage';
import UserAccountRecoverPage from './user/UserAccountRecoverPage';
import ScreeningPage from './screening/ScreeningPage';
import PublicProjectLobbyPage from './public-projects/PublicProjectLobbyPage';
import UserOnboardingPage from './profile/UserOnboardingPage';

const Main = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => Utils.define(state?.session?.user));

  useEffect(() => {
    SessionController.getSession()
      .then((session) => {
        dispatch(SESSION_ACTION.userLogin(session));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  if (loading) {
    return (
      <Body>
        <Spinner />
      </Body>
    );
  }

  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path={PAGES.HOME_PAGE.path()}>
          <PrivateRoute userHasAccess={PAGES.HOME_PAGE.userHasAccess(user)}>
            <Home />
          </PrivateRoute>
        </Route>
        <Route path={PAGES.USER_REGISTER.path()}>
          <Body wrapperClassName='taus-register-page'>
            <RegisterPage />
          </Body>
        </Route>
        <Route path='/'>
          <Body>
            <Switch>
              <Route path={PAGES.SIGN_IN.path()}>
                <PrivateRoute
                  userHasAccess={PAGES.SIGN_IN.userHasAccess(user)}
                  redirectTo={PAGES.USER_ONBOARDING.path()}
                >
                  <Login />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.USER_ACCOUNT_RECOVER_PAGE.path()}>
                <PrivateRoute
                  userHasAccess={PAGES.USER_ACCOUNT_RECOVER_PAGE.userHasAccess(user)}
                  redirectTo={PAGES.HOME_PAGE.path()}
                >
                  <UserAccountRecoverPage />
                </PrivateRoute>
              </Route>
              <Route exact path={PAGES.USER_ONBOARDING.path()}>
                <PrivateRoute
                  userHasAccess={PAGES.USER_ONBOARDING.userHasAccess(user)}
                  redirectTo={PAGES.HOME_PAGE.path()}
                >
                  <UserOnboardingPage />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.TEST_LANDING_PAGE.path()}>
                <TestLandingPageContainer />
              </Route>
              <Route path={PAGES.SCREENER_STEP_QUESTIONNAIRE.path()}>
                <PrivateRoute userHasAccess={PAGES.SCREENER_STEP_QUESTIONNAIRE.userHasAccess(user)}>
                  <DataCollectionFormContainer />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.SCREENER_STEP_PROJECT.path()}>
                <PrivateRoute userHasAccess={PAGES.SCREENER_STEP_PROJECT.userHasAccess(user)}>
                  <UserTasksAvailable />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.TASK_RESULT_PAGE.path()}>
                <PrivateRoute userHasAccess={PAGES.TASK_RESULT_PAGE.userHasAccess(user)}>
                  <TaskPage />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.PUBLIC_PROJECT_LOBBY_PAGE.path()}>
                <PrivateRoute userHasAccess={PAGES.PUBLIC_PROJECT_LOBBY_PAGE.userHasAccess(user)}>
                  <PublicProjectLobbyPage />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.PUBLIC_PROJECT_PAGE.path()}>
                <PrivateRoute userHasAccess={PAGES.PUBLIC_PROJECT_PAGE.userHasAccess(user)}>
                  <PublicProjectPage />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.PUBLIC_PROJECTS_LISTING_PAGE.path()}>
                <PrivateRoute
                  userHasAccess={PAGES.PUBLIC_PROJECTS_LISTING_PAGE.userHasAccess(user)}
                >
                  <PublicProjectsListingPage />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.AVAILABLE_TASKS_PAGE.path()}>
                <PrivateRoute userHasAccess={PAGES.AVAILABLE_TASKS_PAGE.userHasAccess(user)}>
                  <UserTasksAvailable />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.USER_TASKS_PAGE.path()}>
                <PrivateRoute userHasAccess={PAGES.USER_TASKS_PAGE.userHasAccess(user)}>
                  <UserTasks />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.WORKER_TASKS_PAGE.path()}>
                <PrivateRoute
                  userHasAccess={PAGES.WORKER_TASKS_PAGE.userHasAccess(user)}
                  redirectTo={PAGES.HOME_PAGE.path()}
                >
                  <WorkerTasks />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.USER_EDIT_PAGE.path()}>
                <PrivateRoute
                  userHasAccess={PAGES.USER_EDIT_PAGE.userHasAccess(user)}
                  redirectTo={PAGES.HOME_PAGE.path()}
                >
                  <UserEditPage />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.USER_REFEREES_PAGE.path()}>
                <PrivateRoute
                  userHasAccess={PAGES.USER_REFEREES_PAGE.userHasAccess(user)}
                  redirectTo={PAGES.HOME_PAGE.path()}
                >
                  <UsersRefereesPage />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.USER_REFERRALS_PAGE.path()}>
                <PrivateRoute
                  userHasAccess={PAGES.USER_REFERRALS_PAGE.userHasAccess(user)}
                  redirectTo={PAGES.HOME_PAGE.path()}
                >
                  <UsersReferralPage />
                </PrivateRoute>
              </Route>
              <Route exact path={PAGES.USERS_PAGE.path()}>
                <PrivateRoute
                  userHasAccess={PAGES.USERS_PAGE.userHasAccess(user)}
                  redirectTo={PAGES.HOME_PAGE.path()}
                >
                  <UserPage />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.USER_GROUP_PAGE.path()}>
                <PrivateRoute
                  userHasAccess={PAGES.USER_GROUP_PAGE.userHasAccess(user)}
                  redirectTo={PAGES.HOME_PAGE.path()}
                >
                  <UserGroupPage />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.USERGROUPS_PAGE.path()}>
                <PrivateRoute
                  userHasAccess={PAGES.USERGROUPS_PAGE.userHasAccess(user)}
                  redirectTo={PAGES.HOME_PAGE.path()}
                >
                  <UserGroupsPage />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.PROFILE_PAGE.path()}>
                <PrivateRoute userHasAccess={PAGES.PROFILE_PAGE.userHasAccess(user)}>
                  <ProfilePage />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.PROJECT_CREATE_PAGE.path()}>
                <PrivateRoute
                  userHasAccess={PAGES.PROJECT_CREATE_PAGE.userHasAccess(user)}
                  redirectTo={PAGES.HOME_PAGE.path()}
                >
                  <ProjectCreatePageContainer />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.PROJECT_COPY_PAGE.path()}>
                <PrivateRoute
                  userHasAccess={PAGES.PROJECT_COPY_PAGE.userHasAccess(user)}
                  redirectTo={PAGES.HOME_PAGE.path()}
                >
                  <ProjectCopyPageContainer />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.PROJECT_PAGE.path()}>
                <PrivateRoute
                  userHasAccess={PAGES.PROJECT_PAGE.userHasAccess(user)}
                  redirectTo={PAGES.HOME_PAGE.path()}
                >
                  <ProjectPageContainer />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.PROJECTS_ADMIN_PAGE.path()} redirectTo={PAGES.HOME_PAGE.path()}>
                <PrivateRoute userHasAccess={PAGES.PROJECTS_ADMIN_PAGE.userHasAccess(user)}>
                  <ProjectList user={user} />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.FILE_CREATE_PAGE.path()} redirectTo={PAGES.HOME_PAGE.path()}>
                <PrivateRoute userHasAccess={PAGES.FILE_CREATE_PAGE.userHasAccess(user)}>
                  <CreateFilePage />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.FILE_EDIT_PAGE.path()} redirectTo={PAGES.HOME_PAGE.path()}>
                <PrivateRoute userHasAccess={PAGES.FILE_EDIT_PAGE.userHasAccess(user)}>
                  <CreateFilePage />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.FILES_PAGE.path()} redirectTo={PAGES.HOME_PAGE.path()}>
                <PrivateRoute userHasAccess={PAGES.FILES_PAGE.userHasAccess(user)}>
                  <FileList />
                </PrivateRoute>
              </Route>
              <Route path={PAGES.SCREENING_PAGE.path()} redirectTo={PAGES.HOME_PAGE.path()}>
                <PrivateRoute userHasAccess={PAGES.SCREENING_PAGE.userHasAccess(user)}>
                  <ScreeningPage />
                </PrivateRoute>
              </Route>
            </Switch>
          </Body>
        </Route>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Main;
