import React from "react";
import {FormText} from "reactstrap"
import PropTypes from "prop-types";

export const FormFieldError = (props) => {
    return(<FormText color="danger">{props.error}</FormText>)
};

FormFieldError.propTypes = {
    error: PropTypes.string.isRequired,
    touched: PropTypes.bool.isRequired
};