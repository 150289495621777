
import React from 'react'
import PropTypes from "prop-types";

const ListGroupItemWithBadge = ({message="", value=null, value2=null, color=null, className=""}) => {

    return (
        <li className={"list-group-item " + className} color={color}>
            {message}
            { !!value2 ? <span className="float-right badge badge-success badge-pill">{value2}</span> : null }
            { !!value ? <span className="float-right badge badge-primary badge-pill">{value}</span> : null }
        </li>
    )
};

ListGroupItemWithBadge.propTypes={
    message: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    value2: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    color: PropTypes.string,
    className: PropTypes.string
};

export default  ListGroupItemWithBadge;