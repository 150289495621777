import CheckboxWithValidation from '../../../components/forms/CheckboxWithValidation';

const ConsentUK = ({ values, touched, errors, setFieldTouched, setFieldValue, isSubmitted, isUserEditPage = false }) => {
  return (
    <>
      <CheckboxWithValidation
        id='consent_1'
        propertyName='consent_1'
        label={
          <>
            I acknowledge that my personal data (including, but not limited to, voice recordings and
            any biometric data generated therefrom) may be processed by Amazon Web Services, Inc,
            410 Terry Avenue North, Seattle, WA, 98109, United States (“Amazon”) and may be used for
            the development, improvement, and maintenance of Amazon's commercial language products
            and services and is subject to the Amazon privacy notice available at{' '}
            <a href='https://aws.amazon.com/privacy/' target='_blank' rel='noopener noreferrer'>
              https://aws.amazon.com/privacy/
            </a>
            .
          </>
        }
        defaultValue={values.consent_1}
        touched={touched.consent_1}
        error={errors.consent_1}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        disabled={isUserEditPage ? true : isSubmitted}
      />
      <p>
        Unless your explicit consent is required, Amazon's processing of your personal data is
        necessary for the performance of this Agreement between you and TAUS, B.V.
      </p>
      <CheckboxWithValidation
        id='consent_2'
        propertyName='consent_2'
        label="I agree that Amazon may process biometric data from my voice recordings to develop Amazon's commercial language products and services."
        defaultValue={values.consent_2}
        touched={touched.consent_2}
        error={errors.consent_2}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        disabled={isUserEditPage ? true : isSubmitted}
      />
      <CheckboxWithValidation
        id='consent_3'
        propertyName='consent_3'
        label="I agree that Amazon may process biometric data from my voice recordings to improve Amazon's commercial language products and services."
        defaultValue={values.consent_3}
        touched={touched.consent_3}
        error={errors.consent_3}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        disabled={isUserEditPage ? true : isSubmitted}
      />
      <CheckboxWithValidation
        id='consent_4'
        propertyName='consent_4'
        label="I agree that Amazon may process biometric data from my voice recordings to maintain Amazon's commercial language products and services."
        defaultValue={values.consent_4}
        touched={touched.consent_4}
        error={errors.consent_4}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        disabled={isUserEditPage ? true : isSubmitted}
      />
      <p>
        You may withdraw your consent for Amazon's processing of your biometric data at any time by
        contacting{' '}
        <a href='mailto:awsaimlresearch@amazon.com' target='_blank' rel='noopener noreferrer'>
          awsaimlresearch@amazon.com
        </a>
        . Any withdrawal of consent will only take effect for future processing and will not affect
        the lawfulness of processing before the withdrawal.
      </p>
      <CheckboxWithValidation
        id='consent_5'
        propertyName='consent_5'
        label={
          <>
            I acknowledge that Amazon may publish online my voice recordings, transcripts,
            demographic characteristics, and related information which may be viewed, downloaded,
            and/or processed by other researchers and/or the general public and that this
            publication by Amazon is necessary for the performance of this Agreement between you and
            TAUS, B.V. Amazon will not publish online any written identifiers which can be used to
            directly identify you (e.g., your name, email address, or address).
          </>
        }
        defaultValue={values.consent_5}
        touched={touched.consent_5}
        error={errors.consent_5}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        disabled={isUserEditPage ? true : isSubmitted}
      />
      <p>
        Other researchers and/or members of the general public may be separate controllers of your
        personal data. Amazon is not responsible for the processing of your personal data by other
        researchers and/or the general public.
      </p>
    </>
  );
};

export default ConsentUK;
