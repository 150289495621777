
import React, {Fragment} from 'react'
import PropTypes from "prop-types";


const TextWithIcon = ({outerClassName, innerClassName, text, icon, size, cursor}) => {

    let inner =
            <Fragment>
                <i className={icon}></i>
                <span style={{cursor: cursor?cursor:'auto'}}> {text}</span>
            </Fragment>

    let headerWithIcon;
    switch (size) {
        case 1: headerWithIcon=<h1 className={innerClassName}>{inner}</h1>; break;
        case 2: headerWithIcon=<h2 className={innerClassName}>{inner}</h2>; break;
        case 3: headerWithIcon=<h3 className={innerClassName}>{inner}</h3>; break;
        case 4: headerWithIcon=<h4 className={innerClassName}>{inner}</h4>; break;
        case 5: headerWithIcon=<h5 className={innerClassName}>{inner}</h5>; break;
        case 6: headerWithIcon=<h6 className={innerClassName}>{inner}</h6>; break;
        default: headerWithIcon=<div className={innerClassName}>{inner}</div>;
    }

    return (
        <div className={outerClassName}>
            {headerWithIcon}
        </div>
        )
};

TextWithIcon.propTypes={
    text: PropTypes.string.isRequired,
    outerClassName: PropTypes.string,
    innerClassName: PropTypes.string,
    icon: PropTypes.string.isRequired,
    size: PropTypes.number,
    cursor: PropTypes.string
};

export default  TextWithIcon;