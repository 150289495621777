import Utils from '../utils/Utils';
import { get, post, put, remove } from '../http';
import { objectToQueryString } from '../utils/Url';

const BASE_URL = '/api/user/group';

const getAll = async (parameters, headers) => {
  return get(`${BASE_URL}?${objectToQueryString(parameters)}`, { headers: { ...headers } });
};

const getUserGroup = async (id) => {
  if (Utils.isNull(id)) {
    throw new Error('Invalid user group id (missing)');
  }
  return get(`${BASE_URL}/${id}`);
};

const create = async (group) => {
  if (Utils.isEmpty(group)) {
    throw new Error('Invalid user group (missing)');
  }
  return post(`${BASE_URL}`, group);
};

const update = async (id, data) => {
  if (Utils.isEmpty(data)) {
    throw new Error('Invalid user group (missing)');
  }

  return put(`${BASE_URL}/${id}`, data);
};

const deleteUserGroup = async (id) => {
  if (Utils.isNull(id)) {
    throw new Error('Invalid user group id (missing)');
  }
  return remove(`${BASE_URL}/${id}`);
};

const listMembers = async (id, parameters) => {
  if (Utils.isNull(id)) {
    throw new Error('Invalid user group id (missing)');
  }
  return get(`${BASE_URL}/${id}/member?${objectToQueryString(parameters)}`);
};

const addMembers = async (groupId, memberIds) => {
  if (Utils.isNull(groupId)) {
    throw new Error('Invalid user group id (missing)');
  }
  if (Utils.isEmpty(memberIds)) {
    throw new Error('Invalid user group member ids (missing)');
  }
  return post(`${BASE_URL}/${groupId}/members`, memberIds);
};

const removeMembers = async (groupId, memberIds) => {
  if (Utils.isNull(groupId)) {
    throw new Error('Invalid user group id (missing)');
  }
  if (Utils.isEmpty(memberIds)) {
    throw new Error('Invalid user group member ids (missing)');
  }
  return remove(`${BASE_URL}/${groupId}/members`, memberIds);
};

const approveMember = async (groupId, memberId) => {
  if (Utils.isNull(groupId)) {
    throw new Error('Invalid user group id (missing)');
  }
  if (Utils.isNull(memberId)) {
    throw new Error('Invalid member id (missing)');
  }
  return put(`${BASE_URL}/${groupId}/member/${memberId}/approve`);
};

const createCriteria = async (groupId, filters) => {
  if (Utils.isNull(groupId)) {
    throw new Error('Invalid user group id (missing)');
  }
  if (Utils.isEmpty(filters)) {
    throw new Error('Invalid filters (empty)');
  }
  return post(`${BASE_URL}/${groupId}/criteria`, filters);
};

const updateCriteria = async (groupId, criteriaId, filters) => {
  if (Utils.isNull(groupId)) {
    throw new Error('Invalid user group id (missing)');
  }
  if (Utils.isNull(criteriaId)) {
    throw new Error('Invalid criteria id (missing)');
  }
  if (Utils.isEmpty(filters)) {
    throw new Error('Invalid filters (empty)');
  }
  return put(`${BASE_URL}/${groupId}/criteria/${criteriaId}`, filters);
};

const deleteCriteria = async (groupId, criteriaId) => {
  if (Utils.isNull(groupId)) {
    throw new Error('Invalid user group id (missing)');
  }
  if (Utils.isNull(criteriaId)) {
    throw new Error('Invalid criteria id (missing)');
  }
  return remove(`${BASE_URL}/${groupId}/criteria/${criteriaId}`);
};

const invalidateGroup = async (groupId) => {
  if (Utils.isNull(groupId)) {
    throw new Error('Invalid user group id (missing)');
  }
  return put(`${BASE_URL}/${groupId}/invalidate`);
};

const UserGroupController = {
  getAll,
  get: getUserGroup,
  create,
  update,
  delete: deleteUserGroup,
  addMembers,
  removeMembers,
  listMembers: listMembers,
  approveMember,
  createCriteria,
  updateCriteria,
  deleteCriteria,
  invalidateGroup,
};

export default UserGroupController;
