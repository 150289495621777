import React, { useState } from 'react';
import ButtonSpinner from '../components/ButtonSpinner';
import sessionController from '../controllers/SessionController'
import Tooltip from '../../src/components/Tooltip.js';

const FacebookLogin = ({ title }) => {
  const [isLoading, setLoading] = useState(false);
  const handleClick = async () => {
    const provider = 'hlp-facebook';
    if (!isLoading) {
      setLoading(true);
      await sessionController.authorize(provider)
      .then((resp) => {
        window.location.replace(resp);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  };

  return (
    <Tooltip key='facebook_tooltip' title='To be able to use this option, Use a verified facebook email address'>
      <div
        className={`taus-btn-primary tw-relative tw-inline-flex tw-flex-row tw-items-center tw-px-5 tw-py-4 tw-transition tw-duration-100 tw-ease-in-out tw-border tw-rounded tw-cursor-pointer ${
          isLoading ? 'tw-bg-[#3b5998] tw-bg-opacity-80 tw-cursor-default' : 'hover:tw-bg-opacity-100'
        }`}
        onClick={handleClick}
      >
        <img
          alt='facebook-icon'
          className='tw-inline tw-w-6 tw-h-6 tw-mr-4'
          src='https://a.storyblok.com/f/139507/x/1c88592eee/icons-social-media-facebook.svg'
        />
        <span>{title} with Facebook</span>
        {isLoading && <ButtonSpinner />}
      </div>
    </Tooltip>
  );
};

export default FacebookLogin;