// External libs
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Notifier from '../../components/Notifier';
import ApiException from '../../models/ApiException';
import ProjectController from '../../controllers/ProjectController';
import ProjectCopyView from './views/ProjectCopyView';
import { PAGES } from '../index';
import MetadataController from '../../controllers/MetadataController';
import Utils from '../../utils/Utils';

class ProjectCopyPageContainer extends PureComponent {
  state = {
    isLoadingLanguages: true,
    languageOptions: [],
  };

  render() {
    return (
      <ProjectCopyView
        onSaveButtonClick={this.onSaveButtonClick.bind(this)}
        onCancelButtonClick={this.onBackButtonClick.bind(this)}
        languageOptions={this.state.languageOptions}
        isLoadingLanguages={this.state.isLoadingLanguages}
      />
    );
  }

  componentDidMount() {
    this.setState({ isLoadingLanguages: false });
    this.getLanguages()
      .then((response) => {
        const languageOptions = Utils.mapToOption(response);
        this.setState({ languageOptions: languageOptions });
      })
      .catch((e) => this.errorHandler(e))
      .finally(() => this.setState({ isLoadingLanguages: false }));
  }

  async getLanguages() {
    return MetadataController.getLanguages();
  }

  async onBackButtonClick() {
    const id = this.props.match.params.id;
    this.props.history.push(PAGES.PROJECT_PAGE.path(id));
  }

  async onSaveButtonClick(data = null) {
    try {
      this.setState({ isBusy: true });
      const id = this.props.match.params.id;
      const project = await ProjectController.copy(id, data);
      this.props.history.push(PAGES.PROJECT_PAGE.path(project?.id));
    } catch (e) {
      this.errorHandler(e);
    }
  }

  errorHandler(e) {
    e = ApiException.toApiException(e);
    Notifier.error(e.userMessage);
  }
}

const mapStateToProps = (state = {}, ownProps) => ({ ...ownProps, user: state?.session?.user });
export default connect(mapStateToProps, null)(withRouter(ProjectCopyPageContainer));
