import { getLastNYears } from '../../../utils/DateHelper';
import {
  languages,
  languagesUSUK,
  ancestriesAU,
  ancestriesUSUK,
  ancestries,
  origins,
  originCountries,
  frequencies,
  dialectsAU,
  dialectsUK,
  dialectsUS,
  ethnicities,
  speakerUK,
  speakerUS,
  speakerAU,
  speakerUSUK,
  races,
} from './schema';

export const schemas = {
  UK: speakerUK,
  US: speakerUS,
  AU: speakerAU,
  USUK: speakerUSUK,
};

export const computerOwnOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

const mapToOptions = (array) => array.map((item) => ({ value: item, label: item }));

export const dialectOptions = (countryCode) => {
  switch (countryCode) {
    case 'UK':
      return mapToOptions(dialectsUK.enum);
    case 'US':
      return mapToOptions(dialectsUS.enum);
    case 'AU':
      return mapToOptions(dialectsAU.enum);
    case 'USUK':
      return mapToOptions(languagesUSUK.enum);
    default:
      throw new Error(`No dialect options exist for country code: ${countryCode}`);
  }
};
export const frequencyOptions = mapToOptions(frequencies.enum);
export const ancestryOptions = mapToOptions(ancestries.enum);
export const languageOptions = mapToOptions(languages.enum);
export const languageUSUKOptions = mapToOptions(languagesUSUK.enum);

export const allDialectOptions = [
  {
    name: 'Questionnaire Native Language US UK',
    options: dialectOptions('USUK'),
  },
  {
    name: 'AU Dialects',
    options: dialectOptions('AU'),
  },
  {
    name: 'US Dialects',
    options: dialectOptions('US'),
  },
  {
    name: 'UK Dialects',
    options: dialectOptions('UK'),
  },
];

export const deviceSurveyOptions = (countryCode) =>
  countryCode === 'UK'
    ? mapToOptions(speakerUK.properties.survey_device.enum)
    : mapToOptions(speakerUS.properties.survey_device.enum);
export const contributionOptions = (countryCode) =>
  countryCode === 'UK'
    ? mapToOptions(speakerUK.properties.contributed.enum)
    : mapToOptions(speakerUS.properties.contributed.enum);
export const highestEducationOptions = (countryCode) =>
  countryCode === 'UK'
    ? mapToOptions(speakerUK.properties.education.enum)
    : mapToOptions(speakerUS.properties.education.enum);
export const deviceUsageOptions = (countryCode) =>
  countryCode === 'UK'
    ? mapToOptions(speakerUK.properties.frequent_device.enum)
    : mapToOptions(speakerUS.properties.frequent_device.enum);
export const residencyOptions = (countryCode) =>
  countryCode === 'UK'
    ? mapToOptions(speakerUK.properties.current_residency_duration.enum)
    : mapToOptions(speakerUS.properties.current_residency_duration.enum);
export const genderOptions = (countryCode) =>
  countryCode === 'UK'
    ? mapToOptions(speakerUK.properties.gender.enum)
    : mapToOptions(speakerUS.properties.gender.enum);
export const learningMethodOptions = (countryCode) =>
  countryCode === 'UK'
    ? mapToOptions(speakerUK.properties.english_learning_method.enum)
    : mapToOptions(speakerUS.properties.english_learning_method.enum);

export const ethnicGroups = [
  { value: 'Asian or Asian British', label: 'Asian or Asian British' },
  {
    value: 'Black, Black British, Caribbean or African',
    label: 'Black, Black British, Caribbean or African',
  },
  { value: 'White', label: 'White' },
  { value: 'Other ethnic group', label: 'Other ethnic group' },
];

export const ethnicGroupsWithSub = [
  {
    label: ethnicGroups[0].label,
    options: mapToOptions(ethnicities.enum.slice(0, 5)),
  },
  {
    label: ethnicGroups[1].label,
    options: mapToOptions(ethnicities.enum.slice(5, 8)),
  },
  {
    label: ethnicGroups[2].label,
    options: mapToOptions(ethnicities.enum.slice(8, 13)),
  },
  {
    label: ethnicGroups[3].label,
    options: mapToOptions(ethnicities.enum.slice(13)),
  },
];

export const ancestryAUOptions = mapToOptions(ancestriesAU.enum);
const ancestryAUTopLevel = [
  { value: 'African', label: 'African' },
  { value: 'Asian', label: 'Asian' },
  { value: 'European', label: 'European' },
  { value: 'Oceanian', label: 'Oceanian' },
  { value: 'Some Other Ancestry', label: 'Some Other Ancestry' },
];

export const AUAncestriesWithSub = [
  {
    label: ancestryAUTopLevel[0].label,
    options: ancestryAUOptions.slice(0, 2),
  },
  {
    label: ancestryAUTopLevel[1].label,
    options: ancestryAUOptions.slice(2, 5),
  },
  {
    label: ancestryAUTopLevel[2].label,
    options: ancestryAUOptions.slice(5, 7),
  },
  {
    label: ancestryAUTopLevel[3].label,
    options: ancestryAUOptions.slice(7, 8),
  },
  {
    label: ancestryAUTopLevel[4].label,
    options: ancestryAUOptions.slice(8),
  },
];

export const ancestriesUSUKOptions = mapToOptions(ancestriesUSUK.enum);
export const USUKresidingCountry = [
  { value: 'UK', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
];
export const originsOptions = mapToOptions(origins.enum);
export const getOriginCountriesOptions = (extra) => {
  if (extra) return mapToOptions(['United Kingdom', 'United States', ...originCountries.enum]);
  else return mapToOptions(originCountries.enum);
};

export const raceOptions = mapToOptions(races.enum);

export const combinedEthnicGroupsAndRaces = [
  {
    name: 'USUK Origin Countries',
    options: originsOptions,
  },
  {
    name: 'AU Ancestries',
    options: ancestryAUTopLevel,
  },
  {
    name: 'US Races',
    options: raceOptions,
  },
  {
    name: 'UK Ethnic Groups',
    options: ethnicGroups,
  },
];

export const yearOfBirthOptions = (countryCode, ageLimit = 20) => {
  let limit = ageLimit;
  if (countryCode === 'AU' || countryCode === 'USUK') limit = 21;
  return getLastNYears(100, limit).map((year) => ({
    value: year,
    label: year.toString(),
  }));
};

export const formRelatedLinks = (countryCode) => {
  switch (countryCode) {
    case 'UK':
      return [
        'https://tinyurl.com/TAUSdialectsInfo',
        'https://oxfordhousebcn.com/en/a-brief-guide-to-different-british-accents-and-dialects/',
      ];
    case 'US':
      return ['https://drive.google.com/file/d/1pfPtkbe75DO6vDpTplbG6ITtC9FCDK1x/view'];

    default:
      return [];
  }
};
