import Segment from '../../../../models/Segment';
import LanguageDisplay from '../LanguageDisplay';

const AnnotationSourceSegment = ({ source }) => {
  const content = Segment.getContentData(source);
  return (
    <div className='taus-source-segment-single pb-3 pr-sm-3 col-12'>
      <label className='font-weight-bold'>
        <LanguageDisplay language={source?.language} />
      </label>
      <div className='border-bottom border-top py-1' style={{ fontSize: '16px' }}>
        {content}
      </div>
    </div>
  );
};

export default AnnotationSourceSegment;
