import React from 'react';
import Loader from '../../../components/Loader';
import PublicProjectCard from '../components/PublicProjectCard';
import { Link } from 'react-router-dom'
import { PAGES } from '../../index'

const PublicProjectPageView = ({ isLoading, project, handleApply }) => {
  return (
    <div className='tw-relative'>
      {isLoading && <Loader />}
      <div className='tw-flex tw-justify-center'>
        { project?.project.visibility ? (
          <div className='tw-w-1/2'>
            <PublicProjectCard
              key={project?.id}
              isBig={true}
              project={project}
              handleApply={handleApply}
            />
          </div>
          ) : (
          <div className='tw-my-10 tw-border-2 tw-border-solid tw-border-gray-200 tw-rounded tw-p-6 tw-text-center'>
            <span className='tw-text-lg'>This project is not active at the moment. <br/>Go to the {' '}
               <Link
                 to={PAGES.PUBLIC_PROJECTS_LISTING_PAGE.path()}
               >
                projects
              </Link> page to see those available to you.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicProjectPageView;
