import { Formik } from 'formik';
import { object, string } from 'yup';
import ButtonSpinner from '../../../components/ButtonSpinner';
import CheckboxWithValidation from '../../../components/forms/CheckboxWithValidation';
import InputWithValidation from '../../../components/forms/InputWithValidation';

const GroupDetailsForm = ({ group, onSaveGroupDetails, onInvalidateGroup }) => {
  const handleSubmit = async (values) => {
    await onSaveGroupDetails(values);
  };

  return (
    <Formik
      initialValues={{
        name: group?.name ?? '',
        autoApprove: !!group?.autoApprove,
        autoRemove: !!group?.autoRemove,
      }}
      validationSchema={object().shape({
        name: string().required('Name is required'),
      })}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(props) => (
        <form className='tw-pb-2' onSubmit={props.handleSubmit}>
          <InputWithValidation
            id='user_group_name'
            propertyName='name'
            defaultValue={props.values.name ?? ''}
            touched={props.touched.name}
            error={props.errors.name}
            onChange={props.setFieldValue}
            onBlur={props.setFieldTouched}
          />
          <div className='tw-columns-2'>
            <CheckboxWithValidation
              id='autoApprove'
              propertyName='autoApprove'
              label='Auto approve'
              defaultValue={props.values.autoApprove}
              touched={props.touched.autoApprove}
              error={props.errors.autoApprove}
              onChange={props.setFieldValue}
              onBlur={props.setFieldTouched}
              helpText='Automatically approve members that fit the criteria'
            />
            <CheckboxWithValidation
              id='autoRemove'
              propertyName='autoRemove'
              label='Auto remove'
              defaultValue={props.values.autoRemove}
              touched={props.touched.autoRemove}
              error={props.errors.autoRemove}
              onChange={props.setFieldValue}
              onBlur={props.setFieldTouched}
              helpText='Automatically remove members that no longer fit the criteria'
            />
          </div>

          <div className='tw-flex tw-justify-end'>
            <button
              className='btn btn-danger tw-flex tw-items-center mr-2'
              type='button'
              onClick={onInvalidateGroup}
            >
              Invalidate
            </button>
            <button
              disabled={!props.dirty || !props.isValid}
              type='submit'
              className='btn btn-primary tw-flex tw-items-center'
            >
              Save
              {props.isSubmitting && <ButtonSpinner />}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default GroupDetailsForm;
