import React, { useEffect, useState } from 'react';
import PublicProjectPageView from '../views/PublicProjectPageView';
import { useParams } from 'react-router-dom';
import ProjectController from '../../../controllers/ProjectController';
import Notifier from '../../../components/Notifier';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { PAGES } from '../../index';
import Utils from '../../../utils/Utils';
import { useSelector } from 'react-redux';

const PublicProjectPageState = () => {
  const history = useHistory();
  const { id: projectId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [project, setProject] = useState();
  const user = useSelector((state) => Utils.define(state?.session?.user));

  const getProject = () => {
    setLoading(true);
    try {
      ProjectController.getProject(projectId).then((r) => {
        setProject(r);
      });
    } catch (e) {
      Notifier.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleApply = (projectId) => {
    setLoading(true);

    if (!user) {
      history.push(PAGES.SIGN_IN.path(), {
        from: {
          pathname: PAGES.PUBLIC_PROJECT_PAGE.path(projectId),
        },
      });
      return;
    }

    ProjectController.addApplicant(projectId).then(
      () => {
        setLoading(false);
        history.push(PAGES.PUBLIC_PROJECT_LOBBY_PAGE.path(projectId), {
          from: {
            pathname: PAGES.PUBLIC_PROJECTS_LISTING_PAGE.path(),
          },
        });
      },
      () => setLoading(false)
    );
  };

  useEffect(() => {
    getProject();
  }, []);

  return (
    <PublicProjectPageView isLoading={isLoading} project={project} handleApply={handleApply} />
  );
};

export default PublicProjectPageState;
