import React, { PureComponent } from 'react';
import {
  Getter,
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import { EditingState } from '@devexpress/dx-react-grid';
import ToolTip from '../Tooltip';
import Utils from '../../utils/Utils';

export const TableEditColumnOrderLast = () => {
  const tableMoveEditColumnAtTheEnd = (columns) => {
    const editCommandKey = 'Symbol(editCommand)';
    const editColumn = columns.filter((column) => column.key === editCommandKey).pop();
    return [...columns.filter((column) => column.key !== editCommandKey), editColumn];
  };

  return (
    <Plugin>
      <Getter
        name='tableColumns'
        computed={({ tableColumns }) => tableMoveEditColumnAtTheEnd(tableColumns)}
      />
    </Plugin>
  );
};

export const TableEditAsyncColumnCommandComponent = (props) => {
  let isValid = Utils.isEmpty(props?.errors);
  switch (props.id) {
    // case "edit": return (<ToolTip key="edit_tooltip" title="Edit" ><button hidden={props?.disabled} disabled={props?.disabled} onClick={props.onExecute} className="float-left btn btn-secondary border-0 px-2"><em className="far fa-edit" style={{fontSize:"20px", width:"20px"}} /></button></ToolTip>);
    case 'delete':
      return (
        <ToolTip key='delete_tooltip' title='Delete'>
          <button
            hidden={props?.disabled}
            disabled={props?.disabled}
            onClick={props.onExecute}
            className='float-right btn btn-secondary border-0 px-2 text-danger'
          >
            <em className='far fa-trash-alt' style={{ fontSize: '20px', width: '20px' }} />
          </button>
        </ToolTip>
      );
    case 'cancel':
      return (
        <ToolTip key='cancel_tooltip' title='Cancel'>
          <button
            onClick={props.onExecute}
            className='float-right btn btn-secondary border-0 px-2 text-danger'
          >
            <em className='fas fa-times' style={{ fontSize: '20px', width: '20px' }} />
          </button>
        </ToolTip>
      );
    case 'add':
      return (
        <ToolTip key='add_tooltip' title='Create'>
          <button className='btn btn-secondary border-0 text-success' onClick={props.onExecute}>
            {Utils.isNull(props?.addTitle) ? 'New' : props?.addTitle}
          </button>
        </ToolTip>
      );
    case 'commit':
      return (
        <ToolTip key='commit_tooltip' title={isValid ? 'Save' : props?.errors?.join(',')}>
          <button
            onClick={isValid ? props.onExecute : null}
            className={'float-left btn btn-secondary border-0 px-2' + (isValid ? '' : ' disabled')}
          >
            <em className='far fa-save' style={{ fontSize: '20px', width: '20px' }} />
          </button>
        </ToolTip>
      );
    default:
      return null;
  }
};

export class EditingAsyncState extends PureComponent {
  constructor(props) {
    super(props);
    this.state.addedRows = Array.from(Utils.defineArray(props?.addedRows, []));
  }

  static defaultProps = {
    addedRows: [],
    onAddedRowsInsert: null,
    onAddedRowsCancel: null,
    onAddedRowsUpdate: null,

    onRowUpdateCommitAsync: null,
    onRowDeleteCommitAsync: null,
    onRowInsertCommitAsync: null,

    validationSchema: null,
  };

  state = {
    editingRowIds: [],
    editingRowUncommittedChanges: {},
    addedRows: [],
    inValidRows: {},
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props?.addedRows !== prevProps?.addedRows) {
      this.setState({ addedRows: Utils.defineArray(this.props?.addedRows, []) });
    }
  }

  render() {
    this.state.inValidRows =
      this.validateEditingRows(); /* eslint-disable-line react/no-direct-mutation-state */

    let props = {
      columnExtensions: this.props?.columnExtensions,
      editingRowIds: Utils.defineArray(this.state.editingRowIds),
      rowChanges: Utils.define(this.state.editingRowUncommittedChanges, {}),
      addedRows: Utils.defineArray(this.state?.addedRows, []),
      onAddedRowsChange: this.onAddedRowsChange.bind(this),
      onCommitChanges: this.onCommitChanges.bind(this),
      onEditingRowIdsChange: this.onEditingRowIdsChange.bind(this),
      onRowChangesChange: this.onRowChangesChange.bind(this),
      columnEditingEnabled: false,
    };

    return (
      <Plugin>
        <EditingState {...props} />
        <Getter name='InValidEditingRows' value={this.state.inValidRows} />
      </Plugin>
    );
  }

  // Added rows
  onAddedRowsChange(rows) {
    let added = rows.filter((obj) => this.state?.addedRows.every((r) => r.id !== obj.id));
    let deleted = this.state?.addedRows.filter((obj) => rows.every((r) => r.id !== obj.id));
    // Listeners
    if (added?.length > 0 && !Utils.isNull(this.props.onAddedRowsInsert))
      Promise.resolve().then(() => this.props.onAddedRowsInsert(added));
    else if (deleted?.length > 0 && !Utils.isNull(this.props.onAddedRowsCancel))
      Promise.resolve().then(() => this.props.onAddedRowsCancel(deleted));
    else if (!Utils.isNull(this.props.onAddedRowsUpdate))
      Promise.resolve().then(() => this.props.onAddedRowsUpdate(rows));
    this.setState({ addedRows: rows });
  }

  onEditingRowIdsChange(rowIds) {
    this.setState({ editingRowIds: rowIds });
  }

  onCommitChanges(data) {
    if (!Utils.isNull(data?.changed)) this.commitEditedRows(data.changed);
    if (!Utils.isNull(data?.deleted)) this.commitDeletedRows(data.deleted);
    if (!Utils.isNull(data?.added)) this.commitAddedRows(data.added);
  }

  async commitEditedRows(rows) {
    if (Utils.isEmpty(rows)) return;

    if (!Utils.isNull(this.props?.onRowUpdateCommitAsync)) {
      let results = {};
      let failedIds = [];
      let failedChanges = { ...this.state.editingRowUncommittedChanges };
      try {
        results = await this.props.onRowUpdateCommitAsync(rows);
      } catch (e) {
        console.error(e);
      }
      Object.keys(rows).forEach((id) => {
        if (Utils.defineBoolean(results[id], true) === false) {
          failedIds.push(parseInt(id));
          failedChanges[id] = rows[id];
        } else delete failedChanges[id];
      });
      this.setState({ editingRowIds: failedIds, editingRowUncommittedChanges: failedChanges });
    }
  }

  async commitDeletedRows(rows) {
    if (Utils.isEmpty(rows)) return;
    if (!Utils.isNull(this.props.onRowDeleteCommitAsync)) this.props.onRowDeleteCommitAsync(rows);
  }

  async commitAddedRows(rows) {
    if (Utils.isEmpty(rows)) return;
    if (!Utils.isNull(this.props.onRowInsertCommitAsync)) this.props.onRowInsertCommitAsync(rows);
  }

  onRowChangesChange(rows) {
    this.setState({ editingRowUncommittedChanges: rows });
  }

  validateEditingRows() {
    let inValidRows = {};
    if (Utils.isNull(this.props?.validationSchema)) return inValidRows;
    // Check editing rows
    Object.keys(this.state.editingRowUncommittedChanges).forEach((rowId) => {
      let data = this.state.editingRowUncommittedChanges[rowId];
      Object.keys(data).forEach((columnName) => {
        try {
          this.props.validationSchema.validateSyncAt(columnName, data);
        } catch (e) {
          inValidRows[rowId] = {
            ...inValidRows[rowId],
            [columnName]: Utils.defineToArray(e.errors, []),
          };
        }
      });
    });
    // check added rows
    Utils.defineArray(this.state?.addedRows, []).forEach((row) => {
      Object.keys(row).forEach((columnName) => {
        try {
          this.props.validationSchema.validateSyncAt(columnName, row);
        } catch (e) {
          inValidRows[row?.id] = {
            ...inValidRows[row?.id],
            [columnName]: Utils.defineToArray(e.errors, []),
          };
        }
      });
    });

    return inValidRows;
  }
}

export const PopupEditing = React.memo(({ EditorComponent, ...rest }) => (
  <Plugin>
    <Template name='popupEditing'>
      <TemplateConnector>
        {(
          { rows, getRowId, addedRows, editingRowIds, createRowChange, rowChanges },
          {
            changeRow,
            changeAddedRow,
            commitChangedRows,
            commitAddedRows,
            stopEditRows,
            cancelAddedRows,
            cancelChangedRows,
          }
        ) => {
          const isNew = addedRows.length > 0;
          let editedRow;
          let rowId;
          if (isNew) {
            rowId = 0;
            editedRow = addedRows[rowId];
          } else {
            [rowId] = editingRowIds;
            const targetRow = rows.filter((row) => getRowId(row) === rowId)[0];
            editedRow = { ...targetRow, ...rowChanges[rowId] };
          }

          const processValueChange = ({ target: { name, value } }) => {
            const changeArgs = {
              rowId,
              change: createRowChange(editedRow, value, name),
            };
            if (isNew) {
              changeAddedRow(changeArgs);
            } else {
              changeRow(changeArgs);
            }
          };
          const rowIds = isNew ? [0] : editingRowIds;
          const applyChanges = () => {
            if (isNew) {
              commitAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              commitChangedRows({ rowIds });
            }
          };
          const cancelChanges = () => {
            if (isNew) {
              cancelAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              cancelChangedRows({ rowIds });
            }
          };

          const open = editingRowIds.length > 0 || isNew;
          return (
            <EditorComponent
              open={open}
              toggle={() => cancelChanges()}
              row={editedRow}
              rows={rows}
              isNew={isNew}
              onChange={processValueChange}
              onApplyChanges={applyChanges}
              onCancelChanges={cancelChanges}
              {...rest}
            />
          );
        }}
      </TemplateConnector>
    </Template>
    <Template name='root'>
      <TemplatePlaceholder />
      <TemplatePlaceholder name='popupEditing' />
    </Template>
  </Plugin>
));
