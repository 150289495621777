import React from 'react';
import AnnotationTargetSegment from '../Annotation/AnnotationTargetSegment';

import { TargetSegmentReviewFooter } from '../SegmentView';
import Segment from '../../../../models/Segment';
import AnnotationSourceSegment from '../Annotation/AnnotationSourceSegment';

const reviewToClass = {
  [Segment.REVIEWS.APPROVED]: 'text-success',
  [Segment.REVIEWS.REJECTED]: 'text-danger',
  [Segment.REVIEWS.UNREVIEWED]: '',
};

const AnnotationReviewSegment = ({
  segment,
  targetLanguage,
  isLoading,
  isReviewable,
  isValid,
  handleApprove,
  handleReject,
  data,
  onDataChanged,
  isChanged,
  errorMessages,
  tagDefinitions,
  root,
}) => {
  const review = Segment.isReviewed(segment) ? segment?.status : Segment.getContentReview(segment);

  const reviewStatus = <span className={`mr-2 ${reviewToClass[review]}`}>[Status: {review}]</span>;

  const onApprove = () => {
    handleApprove(data, isChanged);
  };
  const onReject = () => {
    handleReject(data, isChanged);
  };

  return (
    <div className='d-flex flex-wrap justify-content-between align-items-start mb-5 pb-5 border-bottom'>
      <AnnotationSourceSegment source={segment?.source} />
      <AnnotationTargetSegment
        segmentId={segment.id}
        targetLanguage={targetLanguage}
        reviewStatus={reviewStatus}
        isLoading={isLoading}
        isChanged={isChanged}
        isValid={isValid}
        errorMessages={errorMessages}
        data={data}
        onDataChanged={onDataChanged}
        tagDefinitions={tagDefinitions}
        root={root}
        footer={
          isReviewable && (
            <TargetSegmentReviewFooter
              onApproveButtonClick={onApprove}
              onRejectButtonClick={onReject}
              review={review}
              isChanged={isChanged}
              isValid={isValid}
              errorMessage={errorMessages.join(' ')}
            />
          )
        }
      />
    </div>
  );
};

export default AnnotationReviewSegment;
