import Utils from '../utils/Utils';

export default class UserAuth {
  static PERMISSIONS = {
    READ: 'READ',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
    CREATE: 'CREATE',
    MANAGE_MEMBERS: 'MANAGE_MEMBERS',
  };

  static getUserPermission(object = null) {
    return Utils.defineArray(object?.permissions, []);
  }
}
