// External libs
import React from 'react';
import { withRouter } from 'react-router-dom';
import Countdown from 'react-countdown-now';
import { connect } from 'react-redux';

// Libs
import TASK from '../../models/Task';
import TASK_RESULT from '../../models/TaskResult';
import PROJECT from '../../models/Project';
import Utils from '../../utils/Utils';
import DOMObject from '../../components/DOMObject';
import ApiException from '../../models/ApiException';
import Notifier from '../../components/Notifier';
import Spinner from '../../components/Spinner';
import TaskTranslationView from './components/TaskTranslationView';
import TaskReviewView from './components/TaskReviewView';
import ErrorBoundary from '../../components/ErrorBoundary';
import Swal from 'sweetalert2';
import TaskMSIView from './components/TaskMSIView';
import TaskTextToSpeechView from './components/TextToSpeech/TaskTextToSpeechView';
import TaskTextToSpeechReviewView from './components/TextToSpeechReview/TaskTextToSpeechReviewView';
import TaskResultController from '../../controllers/TaskResultController';
import TaskAnnotationView from './components/Annotation/TaskAnnotationView';
import TaskAnnotationReviewView from './components/AnnotationReview/TaskAnnotationReviewView';
import TaskSubmitSuccessModal from './components/TaskSubmitSuccessModal';
import User from '../../models/User';
import Button from '../../components/Button';
import DateTimePickerInput from '../../components/forms/DateTimePickerInput';
import './styles/styles.css';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import Segment from '../../models/Segment';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

// Class
class TaskPage extends DOMObject {
  constructor(props) {
    super(props);
    // Read result from history state if exist and matches the url
    this.state.scope = this.props?.location?.state?.scope;
    this.state.projectId = this.props?.location?.state?.projectId;
    this.getTask(Utils.define(this.props?.match?.params?.id))
      .then((task) => {
        const taskExpirationDate = new Date(task.timestampExpires);

        if (!Utils.isNull(this.state.scope))
          this.setState({
            task: task,
            isAdmin: User.isAdmin(this.props?.user),
            taskExpirationDate: taskExpirationDate,
            taskExpirationTimestamp: task.timestampExpires,
            isEditingExpirationTimestamp: false,
          });
        else {
          this.setState({
            task: task,
            scope: TASK_RESULT.getUserScope(task, this.props?.user),
            isAdmin: User.isAdmin(this.props?.user),
            taskExpirationDate: taskExpirationDate,
            taskExpirationTimestamp: task.timestampExpires,
            isEditingExpirationTimestamp: false,
          });
        }
      })
      .catch((e) => {
        this.errorHandler(e, true);
      });
  }

  static defaultProps = {};

  state = {
    projectId: null,
    task: null,
    scope: null,
    isAdmin: null,
    showModal: false,
    isRollback: false,
    taskExpirationDate: null,
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
    this.backButtonClicked();
  };

  // --------------------------------------------------------------------------------------
  // ---------------------------------- ReactJS Methods -----------------------------------
  // --------------------------------------------------------------------------------------
  render() {
    try {
      let task = this.state.task;
      if (!task) return <Spinner />;

      // Check the user membership in the project.
      task.name = `${task?.id ?? 'N/A'} (${task?.type?.name ?? 'N/A'})`;
      if (task.name === ' ()') task.name = '';
      task.workerInstructions = task?.options?.workerInstructions ?? null;
      task.workerInstructionsUrl = task?.options?.workerInstructionsUrl ?? null;

      const backButton = this.props.history?.location?.state?.back ?? null;

      const isRolledback = task.version > 0;

      const emptyTargets = Utils.define(
        task?.stage === PROJECT.STAGES.PRIMARY
          ? task?.targets?.filter((target) => target?.content?.task?.id !== task?.id)
          : task?.stage === PROJECT.STAGES.REVIEW
          ? task?.targets?.filter(
              (target) =>
                Utils.define(target?.content?.review?.status, target?.status) ===
                Segment.REVIEWS.UNREVIEWED
            )
          : [],
        []
      );

      const handleOverlayClick = () => {
        const pendingTargetId = Utils.define(emptyTargets[0].id);
        document
          .getElementById(pendingTargetId)
          .scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
      };

      const totalRecordingDuration = (this.state.task.type.sid === TASK.TYPE.SPEECH_RECORDING.sid || this.state.task.type.sid === TASK.TYPE.SPEECH_RECORDING_REVIEW.sid)
        ? moment(moment.duration(parseFloat(TASK_RESULT.getTotalRecordedTime(task?.targets)),
          'seconds').asMilliseconds()).subtract({ hours: 1 }).format('HH:mm:ss') : '';

      const overlayButton = emptyTargets.length > 0 &&
        emptyTargets.length < task?.targets.length && (
          <Tooltip key='pending_segment_tooltip' title='Jump to next pending segment'>
            <div
              onClick={handleOverlayClick}
              className='tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-2 tw-w-20 tw-rounded-b-none tw-cursor-pointer tw-fixed tw-z-10 tw-bottom-0 tw-left-1/2 tw-right-1/2 btn btn-danger'
            >
              <FontAwesomeIcon icon={faArrowDown} className='' />
              <span>{emptyTargets.length}</span>
            </div>
          </Tooltip>
        );

      return (
        <div className='tw-relative'>
          {overlayButton}
          <div className='card mb-0' style={{ boxShadow: 'none' }}>
            <div className='card-header d-flex justify-content-between align-items-center'>
              <h3>Project: {Utils.define(task?.project?.name, '')}</h3>
              {TASK_RESULT.getUserPermission(task).includes(TASK_RESULT.PERMISSIONS.RELEASE) ? (
                !isRolledback && (
                  <Button
                    label='Unclaim Task'
                    priority='danger'
                    classes='btn-oval'
                    handleClick={this.unclaimButtonClicked.bind(this)}
                  />
                )
              ) : backButton !== null ? (
                <Button
                  priority='dark'
                  classes='tw-rounded tw-px-2 tw-py-1'
                  handleClick={this.backButtonClicked.bind(this)}
                >
                  <div className='flex items-center'>
                    <FontAwesomeIcon icon={faRightFromBracket} className='tw-rotate-180' />
                    <span className='ml-1'>Go Back</span>
                  </div>
                </Button>
              ) : null}
            </div>
            <div className='card-body d-flex justify-content-between flex-wrap'>
              <div>
                <h4>
                  Task:{' '}
                  <span style={{ fontWeight: 'normal', fontSize: 'smaller' }}>{task.name}</span>
                </h4>
                {isRolledback && (
                  <div className='tw-mb-4'>
                    <span className='tw-inline-block tw-mt-2 tw-mb-1 tw-bg-orange-400 tw-text-orange-900 tw-rounded-full tw-px-2 tw-py-1'>
                      Changes Required
                    </span>
                    <span className='tw-block tw-pl-2'>
                      You can now edit the task. <br /> Please make the necessary changes and make
                      sure to save and submit them.
                    </span>
                  </div>
                )}
                {this.state.scope !== TASK_RESULT.USER_SCOPE.WORKER && (
                  <h4 className='col-12 px-0'>
                    Worker:{' '}
                    <span style={{ fontWeight: 'normal', fontSize: 'smaller' }}>
                      {task?.worker?.username ?? ''}
                    </span>
                  </h4>
                )}
                {!!task.workerInstructions && (
                  <div className='pt-3 mb-3'>
                    <h4>Instructions</h4>
                    <p
                      className='col-12 px-0'
                      dangerouslySetInnerHTML={{ __html: task.workerInstructions }}
                    />
                  </div>
                )}
              </div>
              <div>
                <h4>
                  Status:{' '}
                  <span style={{ fontWeight: 'normal', fontSize: 'smaller' }}>
                    {this.state.scope === TASK_RESULT.USER_SCOPE.WORKER
                      ? TASK_RESULT.getStatusForWorker(task)
                      : TASK_RESULT.getStatusForAdmin(task)}
                  </span>
                </h4>
                {task?.status === TASK.STATUS.PENDING && task?.timestampExpires ? (
                  <div className='tw-flex tw-items-center tw-pb-2 tw-font-semibold tw-text-lg'>
                    Expires:
                    <div className='tw-inline-flex tw-items-center'>
                      {this.state.isEditingExpirationTimestamp ? (
                        <div className='tw-ml-2'>
                          <DateTimePickerInput
                            value={this.state.taskExpirationDate}
                            format='MMM DD/YYYY HH:mm a'
                            handleChange={this.onExpirationUpdated.bind(this)}
                          />
                        </div>
                      ) : (
                        <span
                          className='tw-ml-2'
                          style={{ fontWeight: 'normal', fontSize: 'smaller' }}
                        >
                          <Countdown
                            date={this.state?.taskExpirationTimestamp}
                            daysInHours={true}
                            onComplete={this.taskExpired.bind(this)}
                          />
                        </span>
                      )}
                      {this.state.isAdmin && (
                        <em
                          className={`${
                            this.state.isEditingExpirationTimestamp
                              ? 'fas fa-times'
                              : 'fas fa-pencil-alt'
                          } edit-timestamp-expires-btn`}
                          onClick={() => {
                            this.setState({
                              isEditingExpirationTimestamp:
                                !this.state.isEditingExpirationTimestamp,
                            });
                          }}
                        ></em>
                      )}
                    </div>
                  </div>
                ) : null}
                {this.state.scope !== TASK_RESULT.USER_SCOPE.WORKER &&
                task?.evaluationStatus !== TASK_RESULT.EVALUATION_STATUS.INACTIVE ? (
                  <h4>
                    Evaluation:{' '}
                    <span style={{ fontWeight: 'normal', fontSize: 'smaller' }}>
                      {TASK_RESULT.getEvaluationStatusForAdmin(task)}
                    </span>
                  </h4>
                ) : null}
                {this.state.scope !== TASK_RESULT.USER_SCOPE.WORKER &&
                !Utils.isNull(task?.reviewTask?.id) ? (
                  <h4>
                    {task?.stage === PROJECT.STAGES.PRIMARY ? 'Review' : 'Primary'} Task ID:
                    <span style={{ fontWeight: 'normal', fontSize: 'smaller' }}>
                      {task?.reviewTask?.id}
                    </span>
                  </h4>
                ) : null}
                {this.state.task.status !== TASK_RESULT.STATUS.PENDING ?
                  ( <div>
                    <h4>Total Recording time:
                      <span style={{ fontWeight: 'normal', fontSize: 'smaller' }}>
                        {totalRecordingDuration}
                      </span>
                    </h4>
                  </div>) : null}
              </div>
            </div>
          </div>
          {this.renderTaskPage()}
          <TaskSubmitSuccessModal
            projectId={this.state.projectId}
            scope={this.state.task.task.scope}
            isOpen={this.state.showModal}
            isRollback={this.state.isRollback}
            onClose={this.handleCloseModal}
          />
        </div>
      );
    } catch (e) {
      this.errorHandler(e);
      return <ErrorBoundary hasError={true} errorMessage='Api error! failed render task page!' />;
    }
  }

  renderTaskPage() {
    try {
      if (
        [TASK.TYPE.TRANSLATION.sid, TASK.TYPE.POST_EDITING.sid].includes(this.state.task?.type?.sid)
      ) {
        return this.state.scope === TASK_RESULT.USER_SCOPE.WORKER ? (
          <TaskTranslationView
            task={this.state.task}
            user={this.props.user}
            scope={this.state.scope}
            taskIsUpdated={this.onTaskUpdated.bind(this)}
          />
        ) : (
          <TaskReviewView
            task={this.state.task}
            scope={this.state.scope}
            isAdmin={this.state.isAdmin}
            taskIsUpdated={this.onTaskUpdated.bind(this)}
          />
        );
      }
      if (TASK.TYPE.SPEECH_RECORDING.sid === this.state.task?.type?.sid) {
        if (this.state.scope === TASK_RESULT.USER_SCOPE.WORKER) {
          return (
            <TaskTextToSpeechView
              task={this.state.task}
              scope={this.state.scope}
              taskIsUpdated={this.onTaskUpdated.bind(this)}
            />
          );
        } else {
          return (
            <TaskTextToSpeechReviewView
              task={this.state.task}
              scope={this.state.scope}
              isAdmin={this.state.isAdmin}
              taskIsUpdated={this.onTaskUpdated.bind(this)}
            />
          );
        }
      }
      if (TASK.TYPE.SPEECH_RECORDING_REVIEW.sid === this.state.task?.type?.sid) {
        return (
          <TaskTextToSpeechReviewView
            task={this.state.task}
            scope={this.state.scope}
            isAdmin={this.state.isAdmin}
            taskIsUpdated={this.onTaskUpdated.bind(this)}
          />
        );
      }
      if (TASK.TYPE.TEXT_ANNOTATION.sid === this.state.task?.type?.sid) {
        if (this.state.scope === TASK_RESULT.USER_SCOPE.WORKER) {
          return (
            <TaskAnnotationView
              task={this.state.task}
              scope={this.state.scope}
              taskIsUpdated={this.onTaskUpdated.bind(this)}
            />
          );
        } else {
          return (
            <TaskAnnotationReviewView
              task={this.state.task}
              scope={this.state.scope}
              taskIsUpdated={this.onTaskUpdated.bind(this)}
            />
          );
        }
      }
      if (TASK.TYPE.TEXT_ANNOTATION_REVIEW.sid === this.state.task?.type?.sid) {
        return (
          <TaskAnnotationReviewView
            task={this.state.task}
            scope={this.state.scope}
            taskIsUpdated={this.onTaskUpdated.bind(this)}
          />
        );
      }
      if (TASK.TYPE.REVIEW.sid === this.state.task?.type?.sid)
        return (
          <TaskReviewView
            task={this.state.task}
            scope={this.state.scope}
            taskIsUpdated={this.onTaskUpdated.bind(this)}
          />
        );
      if (TASK.TYPE.MSI.sid === this.state.task?.type?.sid)
        return (
          <TaskMSIView
            task={this.state.task}
            scope={this.state.scope}
            taskIsUpdated={this.onTaskUpdated.bind(this)}
          />
        );

      throw new ApiException('Unknown task type error', 500, 500);
    } catch (e) {
      this.errorHandler(e, true);
      return <ErrorBoundary hasError={true} errorMessage={'Platform failed present the task'} />;
    }
  }

  // -------------------------------------------------------------------
  // ------------------------------ Listeners --------------------------
  // -------------------------------------------------------------------
  backButtonClicked() {
    try {
      this.props.history.goBack();
    } catch (e) {
      this.errorHandler(e, true);
    }
  }

  async unclaimButtonClicked() {
    try {
      // Warn user before deleting
      const alert = Swal.mixin({
        titleText: 'Unclaim Task?',
        html: 'Are you sure that you want to unclaim the task?',
        showConfirmButton: true,
        confirmButtonText: 'Unclaim',
        confirmButtonColor: '#f05050',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      });
      const response = await alert.fire();
      if (response?.value !== true) return;
      // If user confirmed
      this.setBusy(true);
      await this.releaseTask(this.state?.task?.id);
      this.backButtonClicked();
    } catch (e) {
      this.errorHandler(e, true);
    } finally {
      this.setBusy(false);
    }
  }

  async taskExpired() {
    try {
      const alert = Swal.mixin({
        titleText: 'Task submit deadline expired',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#f05050',
      });
      await alert.fire();
      this.setBusy(true);
      await this.releaseTask(this.state?.task?.id);
    } catch (e) {
      this.errorHandler(e, true);
    } finally {
      this.setBusy(false);
      await this.backButtonClicked();
    }
  }

  async onTaskUpdated(task = null, finalized = false, isRollback = false) {
    if (finalized === true) {
      this.setState({ task: task });
      this.setState({ showModal: true });
      if (isRollback) this.setState({ isRollback: true });
    } else {
      if (!task) return;
      this.setState({ task: task });
      if (this.state.task?.id !== task?.id) {
        this.setState({
          scope: TASK_RESULT.getUserScope(task, this.props?.user),
        });
      }
    }
  }

  async onExpirationUpdated(value) {
    try {
      this.setBusy(true);

      await this.updateTask(this.state.task.id, {
        timestampExpires: value.getTime(),
      }).then((r) => {
        this.setState({
          taskExpirationDate: value,
          taskExpirationTimestamp: r.timestampExpires,
        });
      });
    } catch (e) {
      this.errorHandler(e, true);
    } finally {
      this.setState({
        isEditingExpirationTimestamp: !this.state.isEditingExpirationTimestamp,
      });
      this.setBusy(false);
    }
  }

  // --------------------------------------------------------------------------------------
  // ---------------------------------- Ajax Calls ----------------------------------------
  // --------------------------------------------------------------------------------------
  async getTask(id) {
    return TaskResultController.get(id);
  }

  async releaseTask(id) {
    return TaskResultController.release(id);
  }

  async updateTask(id, data) {
    return TaskResultController.update(id, data);
  }

  // --------------------------------------------------------------------------
  // ------------------------------ Errors ------------------------------------
  // --------------------------------------------------------------------------
  errorHandler(e = null, message = null) {
    try {
      e = ApiException.toApiException(e);
      if (typeof message === 'string') Notifier.error(message);
      else if (message === true) Notifier.error(e.userMessage);
    } finally {
      super.errorHandler(e);
    }
  }
}

// ------------------------------------------------------------------------------------
// ------------------------------- Redux ----------------------------------------------
// ------------------------------------------------------------------------------------
// ------------------------------------------ Redux Mappers
const mapStateToProps = (state = {}, ownProps) => ({ ...ownProps, user: state?.session?.user });

export default connect(mapStateToProps, null)(withRouter(TaskPage));
