import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MetadataController from '../../../controllers/MetadataController';
import UserController from '../../../controllers/UserController';
import Notifier from '../../../components/Notifier';
import PersonalProfileView from '../views/PersonalProfileView';
import PersonalProfileFormView from '../views/PersonalProfileFormView';

export default class PersonalProfileState extends PureComponent {
  constructor(props) {
    super(props);
  }

  static defaultProps = {
    user: null,
  };

  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  };

  state = {
    isLoadingProfile: true,
    isLoadingMetadata: true,
    languages: [],
    countries: [],
    personalProfile: null,
    isOnEditMode: false,
  };

  componentDidMount() {
    this.loadPersonalProfile();
    this.loadMetadata();
  }

  componentDidUpdate() {
    this.props.editingExternaly
      ? this.setState({ isOnEditMode: true })
      : this.setState({ isOnEditMode: false });
  }

  async loadPersonalProfile() {
    try {
      const profile = await UserController.getPersonalProfile(this.props?.user?.id);
      this.setState({ personalProfile: profile });
    } catch (e) {
      this.errorHandler(e);
    } finally {
      this.setState({ isLoadingProfile: false });
    }
  }

  async loadMetadata() {
    try {
      const [languages, countries] = await Promise.all([this.getLanguages(), this.getCountries()]);
      this.setState({
        languages: languages,
        countries: countries,
      });
    } catch (e) {
      this.errorHandler(e);
    } finally {
      this.setState({ isLoadingMetadata: false });
    }
  }

  async getLanguages() {
    return MetadataController.getLanguages();
  }

  async getCountries() {
    return [];
  }

  async onEditButtonClick() {
    this.props.onEdit(this.state.isOnEditMode);
    this.setState({ isOnEditMode: !this.state.isOnEditMode });
  }

  async onSaveButtonClick(data = null) {
    try {
      if (!data) return null;
      this.setState({ isLoadingProfile: true });
      const profile = await UserController.updatePersonalProfile(this.props?.user?.id, data);
      this.setState({
        personalProfile: profile,
        isOnEditMode: false,
      });
      this.props.onEdit(true);
      this.props.userUpdatedData(this.state.personalProfile);
    } catch (e) {
      this.errorHandler(e);
    } finally {
      this.setState({ isLoadingProfile: false });
    }
  }

  render() {
    return (
      <div className='tw-p-5'>
        {this.state.isOnEditMode ? (
          <PersonalProfileFormView
            profile={this.state?.personalProfile}
            languages={this.state.languages}
            isLoading={this.state?.isLoadingProfile || this.state?.isLoadingMetadata}
            isRegistration={false}
            onSave={this.onSaveButtonClick.bind(this)}
            onCancel={this.onEditButtonClick.bind(this)}
          />
        ) : (
          <div>
            <span
              style={{
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                fontWeight: '500',
                color: '#1c1c28',
                marginBottom: '12px',
              }}
            >
              Personal information
            </span>
            <PersonalProfileView
              isBusy={this.state?.isLoadingProfile}
              profile={this.state?.personalProfile}
              isEditable={true}
              onEditButtonClick={this.onEditButtonClick.bind(this)}
            />
          </div>
        )}
      </div>
    );
  }

  errorHandler(e = null, showMessage = true) {
    if (showMessage) {
      if (e instanceof String) Notifier.error(e);
      else if (!!e?.message) Notifier.error(e.message);
      else if (!!e?.userMessage) Notifier.error(e.userMessage);
    }
    console.error(e);
  }
}
