export const randomColorGenerator = (index, opacity = 1) => {
  let colors = [
    'rgba(' + 245 + ', ' + 0 + ', ' + 0 + ', ' + opacity + ')', // red
    'rgba(' + 0 + ', ' + 220 + ', ' + 0 + ', ' + opacity + ')', // green
    'rgba(' + 0 + ', ' + 0 + ', ' + 245 + ', ' + opacity + ')', // blue
    'rgba(' + 240 + ', ' + 240 + ', ' + 0 + ', ' + opacity + ')', // yellow
    'rgba(' + 0 + ', ' + 255 + ', ' + 255 + ', ' + opacity + ')', // cyan
    'rgba(' + 255 + ', ' + 0 + ', ' + 255 + ', ' + opacity + ')', // magenta
    'rgba(' + 128 + ', ' + 0 + ', ' + 0 + ', ' + opacity + ')', // brown
    'rgba(' + 128 + ', ' + 128 + ', ' + 0 + ', ' + opacity + ')', // olive
    'rgba(' + 128 + ', ' + 0 + ', ' + 128 + ', ' + opacity + ')', // purple
    'rgba(' + 0 + ', ' + 0 + ', ' + 128 + ', ' + opacity + ')', // navy
    'rgba(' + 255 + ', ' + 127 + ', ' + 80 + ', ' + opacity + ')', // coral
  ];

  const getRandomColor = () => {
    var num = Math.round(0xffffff * Math.random());
    var r = num >> 16;
    var g = (num >> 8) & 255;
    var b = num & 255;
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
  };

  return !!colors[index] ? colors[index] : getRandomColor();
};
