import React from 'react';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { Grid, Table, TableEditColumn, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';
import { PopupEditing, TableEditColumnOrderLast } from '../../../components/tables/Editor';
import TagEditModal from './TagEditModal';
import { TableHeaderRowSortLabelComponent } from '../../../components/tables/Sort';
import { editorStateToHtml } from '../../../utils/RichTextEditor';

const tableColumns = [
  {
    name: 'value',
    title: 'Value',
  },
  {
    name: 'label',
    title: 'Label',
  },
  {
    name: 'order',
    title: 'Order',
  },
  {
    name: 'color',
    title: 'Color',
  },
  {
    name: 'children',
    title: 'Subtags',
  },
];

const TagTable = ({ tagDefinitions, rootKey, tags, onDeleteTag, onUpdateTag, onAddTag }) => {
  const commitChanges = ({ added, changed, deleted }) => {
    if (added) {
      for (const tag of added) {
        if (!tag.children) {
          tag.children = [];
        }
        if (!tag.description) {
          tag.description = '';
        } else {
          tag.description = editorStateToHtml(tag.description);
        }
        onAddTag(tag);
      }
    }
    if (changed) {
      for (const [key, value] of Object.entries(changed)) {
        if (!value) {
          continue;
        }
        if (value.description) {
          value.description = editorStateToHtml(value.description);
        }
        onUpdateTag(key, value);
      }
    }
    if (deleted) {
      deleted.forEach((id) => {
        onDeleteTag(id);
      });
    }
  };

  return (
    <Grid rows={tags} columns={tableColumns} getRowId={(row) => row.value}>
      <DataTypeProvider
        for={['children']}
        formatterComponent={(props) => (
          <SubtagsFormatter {...props} tagDefinitions={tagDefinitions} />
        )}
      />
      <DataTypeProvider for={['color']} formatterComponent={ColorFormatter} />
      <SortingState defaultSorting={[{ columnName: 'order', direction: 'asc' }]} />
      <IntegratedSorting />
      <Table
        columnExtensions={[
          { columnName: 'color', width: 110 },
          { columnName: 'children', width: 256 },
        ]}
      />
      <TableHeaderRow showSortingControls sortLabelComponent={TableHeaderRowSortLabelComponent} />
      <EditingState onCommitChanges={commitChanges} />
      <TableEditColumn showAddCommand showEditCommand showDeleteCommand />
      <TableEditColumnOrderLast />

      <PopupEditing
        EditorComponent={(props) => (
          <TagEditModal {...props} tagDefinitions={tagDefinitions} rootKey={rootKey} />
        )}
      />
      <RowDetailState />
    </Grid>
  );
};

const SubtagsFormatter = ({ value, tagDefinitions }) => {
  return (
    <div style={{ whiteSpace: 'normal' }}>
      {Object.keys(tagDefinitions)
        .filter((key) => value.includes(key))
        .map((key) => tagDefinitions[key].label)
        .join(', ')}
    </div>
  );
};

const ColorFormatter = ({ value }) => {
  return (
    <div
      style={{
        backgroundColor: value,
        width: 28,
        height: 28,
        borderRadius: 8,
        border: '3px solid #fff',
        boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)',
      }}
    ></div>
  );
};

export default TagTable;
