import React, { useEffect, useState } from 'react';
import ProjectScreeningView from '../views/ProjectScreeningView';
import ProjectController from '../../../controllers/ProjectController';
import Notifier from '../../../components/Notifier';
import ScreeningController from '../../../controllers/ScreeningController';

const ProjectScreeningState = ({ project, roleOptions }) => {
  const [loading, setLoading] = useState(true);
  const [screeningStepSettings, setScreeningStepSettings] = useState([]);
  const [screenerStepOptions, setScreenerStepOptions] = useState([]);

  const getScreenerStepsOptions = () => {
    setLoading(true);
    try {
      ScreeningController.getAll()
        .then((r) => {
          setScreenerStepOptions(
            r.content.map((r) => ({ value: r.id, label: r.name, type: r.type }))
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      Notifier.error(e.message);
    }
  };

  const getScreeningStepSettings = () => {
    setLoading(true);
    try {
      ProjectController.adminGetScreenerSteps(project.id)
        .then((r) => {
          setScreeningStepSettings(r);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      Notifier.error(e.message);
    }
  };

  const createScreeningStepSetting = async (stepId, data) => {
    setLoading(true);
    try {
      await ProjectController.adminAddScreenerStep(project.id, stepId, data).then(() => {
        getScreeningStepSettings();
      });
    } catch (e) {
      Notifier.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const invalidateScreeningStepSetting = (id) => {
    setLoading(true);
    try {
      ProjectController.adminInvalidateScreenerStep(project.id, id).then(() => {
        getScreeningStepSettings();
      });
    } catch (e) {
      Notifier.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const removeScreeningStepSetting = (id) => {
    setLoading(true);
    try {
      ProjectController.adminDeleteScreeningStep(project.id, id).then(() => {
        getScreeningStepSettings();
      });
    } catch (e) {
      Notifier.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getScreenerStepsOptions();
    getScreeningStepSettings();
  }, []);

  return (
    <ProjectScreeningView
      isLoading={loading}
      screeningStepSettings={screeningStepSettings}
      screenerStepOptions={screenerStepOptions}
      roleOptions={roleOptions}
      createScreeningStepSetting={createScreeningStepSetting}
      invalidateScreeningStepSetting={invalidateScreeningStepSetting}
      removeScreeningStepSetting={removeScreeningStepSetting}
    />
  );
};

export default ProjectScreeningState;
