// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// External Modules
import Select from 'react-select';
// Utils
import CountryHelper from '../../../../../utils/CountryHelper';
// Styles
import './PaymentDetailsForm.scss';
// Components
import PaymentTerms from '../../../../PaymentTerms';
import Tooltip from '../../../../../components/Tooltip';
import TextWithIcon from '../../../../project/components/TextWithIcon';
// Options
import { accountTypes, paymentMethods } from './Options';
import PaypalComponent from '../Paypal';


// TODO: Break form fields into separate components

function PaymentDetailsFormView(props) {
  const {
    values,
    getFieldProps,
    setFieldValue,
    isSubmitting,
    onHandleSubmit,
    onHandleReset,
    onHandleChange,
    touched,
    errors,
    onSetUpStripeOnboarding,
    onDisconnectFromStripe,
    onHandleCountryChange,
    onHandleBankChange,
    transferWiseSupportedCountries,
    transferWiseTransactionTypeOptions,
    ibanCountries,
    transferWiseBanks,
    transferWiseBranches,
    transferWiseRegions,
    transferWiseStates,
    stripeEmail,
    isDisconnecting,
    selectedCountryRequiredFields,
    bankRoutingNumbersUS,
  } = props || {};

  const [showPaymentTerms, setPaymentTermsShow] = useState(false);

  const countries = CountryHelper.getCountryList();

  const transferWiseCountriesNoUniqueFilter = transferWiseSupportedCountries?.map(
    (transferWiseCountry) => {
      return countries.find((country) => country.value === transferWiseCountry.countryCode);
    }
  ); // This returns duplicate countries for different types of payment

  const transferWiseCountriesUnique = transferWiseCountriesNoUniqueFilter.filter((elem, pos) => {
    return transferWiseCountriesNoUniqueFilter.indexOf(elem) === pos;
  }); // This filters out the duplicate countries to prevent showing multiple times same country in Countries select field

  const transferWiseCountries = transferWiseCountriesUnique.map(({ label, value }) =>
    (['HK', 'CN'].includes(value)) ? { label: label, value: value, disabled: true } : { label: label, value: value }
  ); // This disables the countries that are not ready yet

  const countryList = (values.method === 'TransferWise' ? transferWiseCountries : countries).sort(
    (a, b) => a.label.localeCompare(b.label)
  );

  // Map transferWise Banks and Branches arrays of objects to a format compatible with react-select input
  const bankList = transferWiseBanks?.map(({ code, title }) => ({ label: title, value: code }));
  const branchList = transferWiseBranches?.map(({ code, title }) => ({
    label: title,
    value: code,
  }));
  const regionList = transferWiseRegions?.map(({ key, name }) => ({ label: name, value: key }));
  const stateList = transferWiseStates?.map(({ code, name }) => ({ label: name, value: code }));
  const USbanks = bankRoutingNumbersUS?.map(({ state, bankRoutingNumber }) => ({
    label: state,
    value: bankRoutingNumber,
  }));

  const methodOptionsList = transferWiseTransactionTypeOptions?.map(
    ({ key, name }) =>
      // {label: name, value: key} Use this if don't want to disable any options manually
      key === 'chinese_card'
        ? { label: name, value: key, disabled: true }
        : { label: name, value: key } // Disable chinese_card because Wise doesn't support it yet
  );

  const onPaymentMethodChange = (payment) => {
    handleReset()
    setFieldValue('method', payment.value, true)
  }
  const onCountryChange = (country) => {
    const vatStatus = CountryHelper.setVatStatus(country.value);
    const transactionType = CountryHelper.setTransferWiseTransactionType(
      transferWiseSupportedCountries,
      country.value
    );
    setFieldValue('transferWiseTypeOptions', transactionType);
    const ibanLength = CountryHelper.setIbanSupportedLength(country.value, ibanCountries);

    setFieldValue('vatStatus', vatStatus);
    setFieldValue('country', country.label, true);
    setFieldValue('countryCode', country.value);

    // If the selected country has only 1 transaction type set it right away
    if (transactionType.length === 1) {
      setFieldValue('transferwiseType', transactionType[0]);
    } else {
      // Otherwise if selected country has more than 1 types then the user will set it from the Transaction Options dropdown
      setFieldValue('transferwiseType', '', true);
    }

    setFieldValue('ibanLength', ibanLength);

    // reset fields in order to re-validate
    setFieldValue('transactionMethod', '');
    setFieldValue('vatNumber', '', true);
    setFieldValue('cocNumber', '', true);
    setFieldValue('bank', '', true);
    setFieldValue('branch', '', true);
    setFieldValue('bankCode', '', true);
    setFieldValue('branchCode', '', true);
    setFieldValue('region', '', true);
    setFieldValue('regionCode', '', true);
    setFieldValue('state', '', true);
    setFieldValue('stateCode', '', true);
    setFieldValue('accountType', '', true);
    setFieldValue('abartn', '', true);
    setFieldValue('bsbCode', '', true);
    setFieldValue('accountNumber', '', true);
    setFieldValue('ifscCode', '', true);
    setFieldValue('iban', '', true);
    setFieldValue('swift', '', true);
    setFieldValue('dateOfBirth', '', true);

    if(values.method !== 'PayPal'){
      setFieldValue('email', '', true);
    }

    if (country.value === 'KR' && values.legalType === 'Private') {
      setFieldValue('transactionMethod', 'south_korean_paygate');
      setFieldValue('transferwiseType', 'south_korean_paygate');
    } else if (country.value === 'KR' && values.legalType === 'Business') {
      setFieldValue('transactionMethod', 'south_korean_paygate_business');
      setFieldValue('transferwiseType', 'south_korean_paygate_business');
    }

    onHandleCountryChange(country, transactionType, ibanLength, values.method);
  };
  const onLegalTypeChange = (event) => {
    const { value } = event.target;
    setFieldValue('legalType', value, true);
    // reset fields in order to re-validate
    setFieldValue('vatNumber', '', true);
    setFieldValue('cocNumber', '', true);

    if (values.countryCode === 'KR' && value === 'Private') {
      setFieldValue('transactionMethod', 'south_korean_paygate');
      setFieldValue('transferwiseType', 'south_korean_paygate');
    } else if (values.countryCode === 'KR' && value === 'Business') {
      setFieldValue('transactionMethod', 'south_korean_paygate_business');
      setFieldValue('transferwiseType', 'south_korean_paygate_business');
    }
  };
  const onTransactionMethodChange = (method) => {
    setFieldValue('transactionMethod', method.value);
    setFieldValue('transferwiseType', method.value);
  };
  const onIfscCodeChange = (event) => {
    const { value } = event.target;
    const bankCode =
      transferWiseBanks.find((bank) => bank.code === value.substring(0, 4))?.code || null;
    // According to TransferWise the last six digits of the IFSC are a code of numbers
    // representing the individual branch of the bank.
    const branchCode = value.substring(6);

    setFieldValue('ifscCode', value);
    setFieldValue('bankCode', bankCode);
    setFieldValue('branchCode', branchCode);
  };
  const onAccountTypeChange = (accountType) => {
    setFieldValue('accountType', accountType.value);
  };
  const onBankChange = (bank) => {
    setFieldValue('bank', bank.label, true);
    setFieldValue('bankCode', bank.value);
    // reset fields in order to re-validate
    setFieldValue('branchCode', '', true);
    setFieldValue('accountNumber', '', true);
    setFieldValue('swift', '', true);

    onHandleBankChange(values.countryCode, bank.value);
  };
  const onBranchChange = (branch) => {
    setFieldValue('branch', branch.label, true);
    setFieldValue('branchCode', branch.value);

    if (values.method === 'TransferWise' && values.countryCode === 'HK') {
      setFieldValue('accountNumber', branch.value);
    }
  };
  const onRegionChange = (region) => {
    setFieldValue('region', region.label, true);
    setFieldValue('regionCode', region.value);
  };
  const onStateChange = (state) => {
    setFieldValue('state', state.label, true);
    setFieldValue('stateCode', state.value);
  };
  const onUSbankChange = (bank) => {
    setFieldValue('abartn', bank.value);
  };
  const setSelectedByLabel = (options, fieldValue) => {
    return options?.filter((option) => option.label === fieldValue);
  };
  const setSelectedByValue = (options, fieldValue) => {
    return options?.filter((option) => option.value === fieldValue);
  };

  const handleShowPaymentTerms = () => setPaymentTermsShow(!showPaymentTerms);
  const handleStripeDisconnect = () => {
    setFieldValue('isConnected', '');
    onDisconnectFromStripe();
  };
  const handleReset = () => {
    onHandleReset()
    setFieldValue('method', values.method, true)
    setFieldValue('email', '', true)
    setFieldValue('name', '', true)
    setFieldValue('addressLine', '', true)
    setFieldValue('city', '', true)
    setFieldValue('zipCode', '', true)
    setFieldValue('countryCode', '', true)
    setFieldValue('country', '', true)
  }

  return (
    <div className='taus-form-group'>
      <form onSubmit={onHandleSubmit} noValidate>
        <div style={{ marginBottom: '16px' }}>
          <span
            style={{
              display: 'block',
              fontSize: '18px',
              lineHeight: '28px',
              letterSpacing: '0.3px',
              color: '#1c1c28',
              marginBottom: '4px',
            }}
          >
            Preferred Payment Method
          </span>

          <Select
            className={ `form-select ${ touched.method && errors.method ? 'is-invalid' : 'mb-3' }` }
            placeholder='Select your preferred payment method'
            name='method'
            options={ paymentMethods }
            value={ setSelectedByValue(paymentMethods, values.method) }
            onChange={ onPaymentMethodChange }
            isOptionDisabled={ (option) => option.disabled }
          />
          { touched['method'] && errors['method'] && (
            <span className='text-danger mb-2'>{ errors['method'] }</span>
          ) }
        </div>

        {/* {values.method === 'PayPal' && (
            <div style={{marginBottom: "16px"}}>
                <span style={{display: "block", fontSize: "18px", lineHeight: "28px", letterSpacing: "0.3px", color: "#1c1c28", marginBottom: "4px"}}>PayPal Email</span>

                <input
                    className={`form-control ${touched.paypalEmail && errors.paypalEmail ? 'is-invalid' : 'mb-3'}`}
                    type='text'
                    id='paypalEmail'
                    placeholder='Enter your PayPal email'
                    {...getFieldProps('paypalEmail')}
                />
                {touched['paypalEmail'] && errors['paypalEmail'] && (
                    <span className='text-danger mb-2'>{errors['paypalEmail']}</span>
                )}
            </div>
        )} */ }

        { values.method === 'Stripe' && (
          <div style={ { marginBottom: '16px' } }>
            <span
              style={ {
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#1c1c28',
                marginBottom: '4px',
              } }
            >
              Stripe Account
            </span>
            { values.isConnected ? (
              <div className='d-flex flex-wrap justify-content-between align-items-center'>
                <button className='btn btn-stripe' onClick={ handleStripeDisconnect }>
                  { isDisconnecting ? 'Disconnecting...' : 'Disconnect from Stripe' }
                </button>
                <span className='text-muted'>Connected as { stripeEmail }</span>
              </div>
            ) : (
              <>
                <input
                  type='button'
                  className={ `taus-btn-stripe ${
                    touched.isConnected && errors.isConnected ? 'is-invalid' : ''
                  }` }
                  value={ 'Connect to stripe' }
                  id='isConnected'
                  name='isConnected'
                  onClick={ onSetUpStripeOnboarding }
                />
                { touched['isConnected'] && errors['isConnected'] && (
                  <span className='text-danger d-block mb-2 mt-1'>{ errors['isConnected'] }</span>
                ) }
              </>
            ) }
          </div>
        ) }

        { values.method === 'PayPal' && values.email === '' && (
          <div style={ { marginTop: '32px', marginBottom: '16px' } } className={ `text-center` }>
            <PaypalComponent />
          </div>
        ) }

        { (values.method === 'TransferWise' ||
          (values.method === 'PayPal' && values.email !== '')) && (
          <div style={ { marginBottom: '16px' } }>
            <span
              style={ {
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#1c1c28',
                marginBottom: '4px',
              } }
            >
              Beneficiary Name
            </span>
            <input
              className={ `form-control ${ touched.name && errors.name ? 'is-invalid' : 'mb-3' }` }
              type='text'
              id='name'
              placeholder='Enter the full name of the legal entity as it should appear in the invoice'
              readOnly={ !!getFieldProps('email') && values.method === 'PayPal' }
              { ...getFieldProps('name') }
            />
            { touched['name'] && errors['name'] && (
              <span className='text-danger mb-2'>{ errors['name'] }</span>
            ) }
          </div>
        ) }


        { (values.method === 'TransferWise'
          ? values.countryCode === 'KR'
            ? values.transferwiseType === 'south_korean_paygate'
            : selectedCountryRequiredFields?.includes('email')
          : (values.method === 'PayPal' && values.email !== '')) && (
          <div style={ { marginBottom: '16px' } }>
            <span
              style={ {
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#1c1c28',
                marginBottom: '4px',
              } }
            >
              Email
            </span>
            <input
              className={ `form-control ${ touched.email && errors.email
                ? 'is-invalid'
                : 'mb-3' }` }
              type='text'
              id='email'
              placeholder='Enter your email here'
              readOnly={ !!getFieldProps('email') && values.method === 'PayPal' }
              { ...getFieldProps('email') }
            />
            { touched['email'] && errors['email'] && (
              <span className='text-danger mb-2'>{ errors['email'] }</span>
            ) }
          </div>
        ) }

        { (values.method === 'TransferWise' ||
          (values.method === 'PayPal' && values.email !== '')) && (
          <div style={ { marginBottom: '16px' } }>
            <span
              style={ {
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#1c1c28',
                marginBottom: '4px',
              } }
            >
              Legal Type
            </span>
            <div
              className={ `form-group mb-0 ${
                touched.legalType && errors.legalType ? 'is-invalid' : ''
              }` }
            >
              <div className='form-check form-check-inline'>
                <div style={ { marginRight: '14px' } }>
                  <Tooltip title='Fill in your info here if you do NOT have a VAT/Tax number'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='privateType'
                      value='Private'
                      name='legalType'
                      checked={
                        (values.country === 'Russia' || values.country === 'China') &&
                        values.method === 'TransferWise'
                          ? true
                          : values.legalType === 'Private'
                      }
                      onChange={ onLegalTypeChange }
                    />
                    <label
                      style={ {
                        fontSize: '18px',
                        lineHeight: '28px',
                        letterSpacing: '0.3px',
                        color: '#1c1c28',
                        marginBottom: '0px',
                      } }
                      htmlFor='privateType'
                    >
                      Private
                    </label>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title='Fill in your info here if you have a VAT/Tax number'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='businessType'
                      value='Business'
                      name='legalType'
                      disabled={
                        (values.country === 'Russia' || values.country === 'China') &&
                        values.method === 'TransferWise'
                          ? true
                          : false
                      }
                      checked={
                        values.method === 'TransferWise'
                          ? values.country !== 'Russia' &&
                          values.country !== 'China' &&
                          values.legalType === 'Business'
                          : values.legalType === 'Business'
                      }
                      onChange={ onLegalTypeChange }
                    />
                    <label
                      style={ {
                        fontSize: '18px',
                        lineHeight: '28px',
                        letterSpacing: '0.3px',
                        color: '#1c1c28',
                        marginBottom: '0px',
                      } }
                      htmlFor='businessType'
                    >
                      Business
                    </label>
                  </Tooltip>
                </div>
              </div>
            </div>
            { touched['legalType'] && errors['legalType'] && (
              <span className='text-danger'>{ errors['legalType'] }</span>
            ) }
          </div>
        ) }

        { (values.method === 'TransferWise' ||
          (values.method === 'PayPal' && values.email !== '')) && (
          <div style={ { marginBottom: '16px' } }>
            <span
              style={ {
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#1c1c28',
                marginBottom: '4px',
              } }
            >
              Full beneficiary's address
            </span>
            <input
              className={ `form-control ${
                touched.addressLine && errors.addressLine ? 'is-invalid' : 'mb-3'
              }` }
              type='text'
              id='addressLine'
              placeholder='Add your address'
              { ...getFieldProps('addressLine') }
            />
            { touched['addressLine'] && errors['addressLine'] && (
              <span className='text-danger mb-2'>{ errors['addressLine'] }</span>
            ) }

            <input
              className={ `form-control ${ touched.city && errors.city ? 'is-invalid' : 'mb-3' }` }
              type='text'
              id='city'
              placeholder='Enter your city'
              { ...getFieldProps('city') }
            />
            { touched['city'] && errors['city'] && (
              <span className='text-danger mb-2'>{ errors['city'] }</span>
            ) }

            <input
              className={ `form-control ${ touched.zipCode && errors.zipCode
                ? 'is-invalid'
                : 'mb-3' }` }
              type='text'
              id='zipCode'
              placeholder='Enter your zip code'
              { ...getFieldProps('zipCode') }
            />
            { touched['zipCode'] && errors['zipCode'] && (
              <span className='text-danger mb-2'>{ errors['zipCode'] }</span>
            ) }

            <Select
              className={ `form-select ${ touched.country && errors.country
                ? 'is-invalid'
                : 'mb-3' }` }
              placeholder='Select your country'
              name='country'
              options={ countryList }
              value={ setSelectedByLabel(countryList, values.country) }
              onChange={ onCountryChange }
              isOptionDisabled={ (option) => option.disabled }
            />
            { touched['country'] && errors['country'] && (
              <span className='text-danger mb-2'>{ errors['country'] }</span>
            ) }

            { values.method === 'TransferWise' &&
              selectedCountryRequiredFields?.includes('state') &&
              (
                <div style={ { marginBottom: '16px' } }>
                  <Select
                    className={ `form-select ${ touched.state && errors.state
                      ? 'is-invalid'
                      : 'mb-3' }` }
                    placeholder='Select your state'
                    name='state'
                    options={ stateList }
                    value={ setSelectedByLabel(stateList, values.state) }
                    onChange={ onStateChange }
                  />
                  { touched['state'] && errors['state'] && (
                    <span className='text-danger mb-2'>{ errors['state'] }</span>
                  ) }
                </div>
              ) }
          </div>
        ) }


        { values.method === 'TransferWise' &&
          values.transferWiseTypeOptions.length > 1 &&
          values.countryCode !== 'KR' && (
            <div style={ { marginBottom: '16px' } }>
              <span
                style={ {
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#1c1c28',
                  marginBottom: '4px',
                } }
              >
                Transaction Method
              </span>
              <Select
                className={ `form-select ${
                  touched.transactionMethod && errors.transactionMethod ? 'is-invalid' : 'mb-3'
                }` }
                placeholder='Select transaction method'
                name='transactionMethod'
                options={ methodOptionsList }
                value={ setSelectedByValue(methodOptionsList, values.transferwiseType) }
                onChange={ onTransactionMethodChange }
                isOptionDisabled={ (option) => option.disabled }
              />
              { touched['transactionMethod'] && errors['transactionMethod'] && (
                <span className='text-danger mb-2'>{ errors['transactionMethod'] }</span>
              ) }
            </div>
          ) }

        { values.legalType === 'Business' &&
          (values.vatStatus === 'EU' || values.vatStatus === 'NL') && (
            <div style={ { marginBottom: '16px' } }>
              <span
                style={ {
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#1c1c28',
                  marginBottom: '4px',
                } }
              >
                VAT Number
              </span>
              <input
                className={ `form-control ${
                  touched.vatNumber && errors.vatNumber ? 'is-invalid' : 'mb-3'
                }` }
                type='text'
                id='vatNumber'
                placeholder='Enter your VAT number as it should appear in the invoice'
                { ...getFieldProps('vatNumber') }
              />
              { touched['vatNumber'] && errors['vatNumber'] && (
                <span className='text-danger mb-2'>{ errors['vatNumber'] }</span>
              ) }
            </div>
          ) }

        { values.legalType === 'Business' && values.vatStatus === 'NL' && (
          <div style={ { marginBottom: '16px' } }>
            <span
              style={ {
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#1c1c28',
                marginBottom: '4px',
              } }
            >
              Chamber of Commerce number
            </span>
            <input
              className={ `form-control ${
                touched.cocNumber && errors.cocNumber ? 'is-invalid' : 'mb-3'
              }` }
              type='text'
              id='cocNumber'
              placeholder='Enter your Chamber of Commerce Number'
              { ...getFieldProps('cocNumber') }
            />
            { touched['cocNumber'] && errors['cocNumber'] && (
              <span className='text-danger mb-2'>{ errors['cocNumber'] }</span>
            ) }
          </div>
        ) }

        { values.method === 'TransferWise' && selectedCountryRequiredFields?.includes('IBAN') && (
          <div style={ { marginBottom: '16px' } }>
            <span
              style={ {
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#1c1c28',
                marginBottom: '4px',
              } }
            >
              IBAN
            </span>
            <input
              className={ `form-control ${ touched.iban && errors.iban ? 'is-invalid' : 'mb-3' }` }
              type='text'
              id='iban'
              placeholder='Enter your IBAN (e.g. NL91 ABNA 0417 1643 00)'
              { ...getFieldProps('iban') }
            />
            { touched['iban'] && errors['iban'] && (
              <span className='text-danger mb-2'>{ errors['iban'] }</span>
            ) }
          </div>
        ) }

        { values.method === 'TransferWise' && selectedCountryRequiredFields?.includes('region') && (
          <div style={ { marginBottom: '16px' } }>
            <span
              style={ {
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#1c1c28',
                marginBottom: '4px',
              } }
            >
              Region
            </span>
            <Select
              className={ `form-select ${ touched.region && errors.region
                ? 'is-invalid'
                : 'mb-3' }` }
              placeholder='Select your region'
              name='region'
              options={ regionList }
              value={ setSelectedByLabel(regionList, values.region) }
              onChange={ onRegionChange }
            />
            { touched['region'] && errors['region'] && (
              <span className='text-danger mb-2'>{ errors['region'] }</span>
            ) }
          </div>
        ) }

        { values.method === 'TransferWise' &&
          selectedCountryRequiredFields?.includes('ifscCode') && (
            <div style={ { marginBottom: '16px' } }>
              <span
                style={ {
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#1c1c28',
                  marginBottom: '4px',
                } }
              >
                IFSC Code
              </span>
              <input
                className={ `form-control ${
                  touched.ifscCode && errors.ifscCode ? 'is-invalid' : 'mb-3'
                }` }
                type='text'
                id='ifscCode'
                placeholder='Enter your IFSC Code (e.g. ICIC0001359)'
                onChange={ onIfscCodeChange }
                value={ values.ifscCode }
              />
              { touched['ifscCode'] && errors['ifscCode'] && (
                <span className='text-danger mb-2'>{ errors['ifscCode'] }</span>
              ) }
            </div>
          ) }

        { values.method === 'TransferWise' &&
          selectedCountryRequiredFields?.includes('bankCode') && (
            <div style={ { marginBottom: '16px' } }>
              <span
                style={ {
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#1c1c28',
                  marginBottom: '4px',
                } }
              >
                Bank
              </span>
              <Select
                className={ `form-select ${ touched.bank && errors.bank ? 'is-invalid' : 'mb-3' }` }
                placeholder='Select your bank'
                name='bank'
                options={ bankList }
                value={ setSelectedByLabel(bankList, values.bank) }
                onChange={ onBankChange }
              />
              { touched['bank'] && errors['bank'] && (
                <span className='text-danger mb-2'>{ errors['bank'] }</span>
              ) }
            </div>
          ) }
        { values.method === 'TransferWise' &&
          selectedCountryRequiredFields?.includes('branchCode') && (
            <div style={ { marginBottom: '16px' } }>
              <span
                style={ {
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#1c1c28',
                  marginBottom: '4px',
                } }
              >
                Branch
              </span>
              <Select
                className={ `form-select ${ touched.branch && errors.branch
                  ? 'is-invalid'
                  : 'mb-3' }` }
                placeholder='Select your bank branch'
                name='branch'
                options={ branchList }
                value={ setSelectedByLabel(branchList, values.branch) }
                onChange={ onBranchChange }
              />
              { touched['branch'] && errors['branch'] && (
                <span className='text-danger mb-2'>{ errors['branch'] }</span>
              ) }
            </div>
          ) }

        {/* {values.method === 'TransferWise' && 
            (values.countryCode === 'CN' ? values.transferwiseType === 'chinese_card' : selectedCountryRequiredFields?.includes('cardNumber')) && (
            <div style={{marginBottom: "16px"}}>
                <span style={{display: "block", fontSize: "18px", lineHeight: "28px", letterSpacing: "0.3px", color: "#1c1c28", marginBottom: "4px"}}>Card Number</span>
                <input
                    className={`form-control ${touched.cardNumber && errors.cardNumber ? 'is-invalid' : 'mb-3'}`}
                    type='text'
                    id='cardNumber'
                    placeholder='Enter your card number'
                />
                {touched['cardNumber'] && errors['cardNumber'] && (
                    <span className='text-danger mb-2'>{errors['cardNumber']}</span>
                )}
            </div>
        )} */ }

        { values.method === 'TransferWise' &&
          selectedCountryRequiredFields?.includes('accountType') && (
            <div style={ { marginBottom: '16px' } }>
              <span
                style={ {
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#1c1c28',
                  marginBottom: '4px',
                } }
              >
                Account Type
              </span>
              <Select
                className={ `form-select ${
                  touched.accountType && errors.accountType ? 'is-invalid' : 'mb-3'
                }` }
                placeholder='Select your account type'
                name='accountType'
                options={ accountTypes(values.countryCode) }
                value={ setSelectedByValue(accountTypes(values.countryCode), values.accountType) }
                onChange={ onAccountTypeChange }
              />
              { touched['accountType'] && errors['accountType'] && (
                <span className='text-danger mb-2'>{ errors['accountType'] }</span>
              ) }
            </div>
          ) }

        { values.method === 'TransferWise' && selectedCountryRequiredFields?.includes('abartn') && (
          <div style={ { marginBottom: '16px' } }>
            <span
              style={ {
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#1c1c28',
                marginBottom: '4px',
              } }
            >
              ACH Routing number
              <Tooltip title='Also known as ABA routing number (abartn) or routing transit number.'>
                <TextWithIcon
                  text={ '' }
                  icon={ 'fas fa-info-circle' }
                  outerClassName={ 'inline ml-2' }
                  innerClassName={ 'text-primary' }
                  cursor={ 'help' }
                  size={ 4 }
                />
              </Tooltip>
            </span>
            <Select
              className={ `form-select mb-3` }
              placeholder='Prefill your routing number by selecting here'
              options={ USbanks }
              onChange={ onUSbankChange }
            />
            <input
              className={ `form-control ${ touched.abartn && errors.abartn
                ? 'is-invalid'
                : 'mb-3' }` }
              type='text'
              id='abartn'
              placeholder='Enter your ACH routing number'
              { ...getFieldProps('abartn') }
            />
            { touched['abartn'] && errors['abartn'] && (
              <span className='text-danger mb-2'>{ errors['abartn'] }</span>
            ) }
          </div>
        ) }

        { values.method === 'TransferWise' && selectedCountryRequiredFields?.includes('bsbCode') &&
          (
            <div style={ { marginBottom: '16px' } }>
            <span
              style={ {
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#1c1c28',
                marginBottom: '4px',
              } }
            >
              Bank State Branch code (BSB Code)
            </span>
              <input
                className={ `form-control ${
                  touched.bsbCode && errors.bsbCode ? 'is-invalid' : 'mb-3'
                }` }
                type='text'
                id='bsbCode'
                placeholder='Enter your ACH routing number'
                { ...getFieldProps('bsbCode') }
              />
              { touched['bsbCode'] && errors['bsbCode'] && (
                <span className='text-danger mb-2'>{ errors['bsbCode'] }</span>
              ) }
            </div>
          ) }

        { values.method === 'TransferWise' &&
          (values.countryCode === 'CN'
            ? values.transferwiseType === 'chinese_alipay'
            : selectedCountryRequiredFields?.includes('accountNumber')) && (
            <div style={ { marginBottom: '16px' } }>
              <span
                style={ {
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#1c1c28',
                  marginBottom: '4px',
                } }
              >
                Account Number
              </span>
              <input
                className={ `form-control ${
                  touched.accountNumber && errors.accountNumber ? 'is-invalid' : 'mb-3'
                }` }
                type='text'
                id='accountNumber'
                placeholder='Enter your account number'
                { ...getFieldProps('accountNumber') }
              />
              { touched['accountNumber'] && errors['accountNumber'] && (
                <span className='text-danger mb-2'>{ errors['accountNumber'] }</span>
              ) }
            </div>
          ) }

        { values.method === 'TransferWise' &&
          selectedCountryRequiredFields?.includes('swiftCode') && (
            <div style={ { marginBottom: '16px' } }>
              <span
                style={ {
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#1c1c28',
                  marginBottom: '4px',
                } }
              >
                SWIFT/BIC Code
              </span>
              <input
                className={ `form-control ${ touched.swift && errors.swift
                  ? 'is-invalid'
                  : 'mb-3' }` }
                type='text'
                id='swift'
                placeholder='Enter your SWIFT/BIC code (e.g. AAAABBCC123)'
                { ...getFieldProps('swift') }
              />
              { touched['swift'] && errors['swift'] && (
                <span className='text-danger mb-2'>{ errors['swift'] }</span>
              ) }
            </div>
          ) }


        { values.method === 'TransferWise' &&
          (values.countryCode === 'KR'
            ? values.transferwiseType === 'south_korean_paygate'
            : selectedCountryRequiredFields?.includes('dateOfBirth')) && (
            <div style={ { marginBottom: '16px' } }>
              <span
                style={ {
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#1c1c28',
                  marginBottom: '4px',
                } }
              >
                Date of birth
              </span>
              <input
                className={ `form-control ${
                  touched.dateOfBirth && errors.dateOfBirth ? 'is-invalid' : 'mb-3'
                }` }
                type='text'
                id='dateOfBirth'
                placeholder='Enter your date of birth'
                { ...getFieldProps('dateOfBirth') }
              />
              { touched['dateOfBirth'] && errors['dateOfBirth'] && (
                <span className='text-danger mb-2'>{ errors['dateOfBirth'] }</span>
              ) }
            </div>
          ) }

        { (values.method === 'TransferWise' ||
          (values.method === 'PayPal' && values.email !== '')) && (
          <div style={ { marginBottom: '16px', marginTop: '34px' } }>
            <div
              className={ `form-group mb-0 ${
                touched.agreedToPaymentTerms && errors.agreedToPaymentTerms ? 'is-invalid' : ''
              }` }
            >
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value={ values.agreedToPaymentTerms }
                  id='paymentTerms'
                  name='agreedToPaymentTerms'
                  onChange={ onHandleChange }
                />
                <label
                  style={ {
                    fontSize: '14px',
                    lineHeight: '22px',
                    letterSpacing: '0.23px',
                    color: '#1c1c28',
                  } }
                  htmlFor='paymentTerms'
                >
                  I agree to{ ' ' }
                  <span
                    style={ { color: '#2da4df', cursor: 'pointer' } }
                    onClick={ handleShowPaymentTerms }
                  >
                  Payment terms and conditions
                </span>
                </label>
              </div>
            </div>
            { touched['agreedToPaymentTerms'] && errors['agreedToPaymentTerms'] && (
              <span className='text-danger'>{ errors['agreedToPaymentTerms'] }</span>
            ) }
          </div>
        ) }
        { (values.method === 'TransferWise' ||
          (values.method === 'PayPal' && values.email !== '')) && (
          <div style={ { display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' } }>
            <Tooltip
              title='If PayPal is selected, if you reset you have to login with PayPal again.'>
              <button type='reset' className='taus-btn-secondary mr-2' onClick={ handleReset }>
                Reset
              </button>
            </Tooltip>
            <button type='submit' className='taus-btn-primary' disabled={ isSubmitting }>
              { isSubmitting ? 'Please wait...' : 'Submit' }
            </button>
          </div>
        ) }
      </form>
      <PaymentTerms show={ showPaymentTerms } handleShow={ setPaymentTermsShow } />
    </div>
  );
}

PaymentDetailsFormView.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getFieldProps: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleSubmit: PropTypes.func.isRequired,
  onHandleReset: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  onSetUpStripeOnboarding: PropTypes.func.isRequired,
  onDisconnectFromStripe: PropTypes.func.isRequired,
  onHandleCountryChange: PropTypes.func.isRequired,
  onHandleBankChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  transferWiseTransactionType: PropTypes.array.isRequired,
  transferWiseTransactionTypeOptions: PropTypes.array,
  ibanCountries: PropTypes.array.isRequired,
  transferWiseBanks: PropTypes.array.isRequired,
  transferWiseBranches: PropTypes.array.isRequired,
  transferWiseRegions: PropTypes.array.isRequired,
  transferWiseStates: PropTypes.array.isRequired,
  transferWiseSupportedCountries: PropTypes.array.isRequired,
  stripeEmail: PropTypes.string.isRequired,
  isDisconnecting: PropTypes.bool.isRequired,
};
export default PaymentDetailsFormView;
