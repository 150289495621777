import React from 'react';
import '../styles/styles.scss';
import Loader from '../../../components/Loader';
import PublicProjectCard from '../components/PublicProjectCard';

const PublicProjectsListingPageView = ({
  isLoading,
  availableProjects,
  userProjects,
  handleApply,
}) => {
  return (
    <div className='tw-relative tw-py-6'>
      {isLoading && <Loader />}
      <div>
        {userProjects?.length === 0 && availableProjects?.length === 0 && (
          <div className='tw-mt-8 tw-text-center'>
            <span>
              There are currently no active projects in HLP, check again regularly to see if there
              is one for you.
            </span>
          </div>
        )}
        {userProjects?.length > 0 && (
          <div className='tw-mb-20'>
            <h3 className='section-heading'>My Projects</h3>
            <div className='projects-grid'>
              {userProjects?.map((item) => {
                return (
                  <PublicProjectCard
                    key={item.project.id}
                    isBig={false}
                    project={item}
                    handleApply={handleApply}
                  />
                );
              })}
            </div>
          </div>
        )}
        {availableProjects?.length > 0 && (
          <div>
            <h3 className='section-heading'>Available Projects</h3>
            <div className='projects-grid'>
              {availableProjects?.map((item) => {
                return (
                  <PublicProjectCard
                    key={item.project.id}
                    isBig={false}
                    project={item}
                    handleApply={handleApply}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicProjectsListingPageView;
