import React from 'react';
import { number, object, string } from 'yup';
import { Formik } from 'formik';
import { Button, Col, Form, Row } from 'react-bootstrap';

import InputWithValidation from '../../../components/forms/InputWithValidation';
import SelectWithValidation from '../../../components/forms/SelectWithValidation';
import Spinner from '../../../components/Spinner';

import { getLastNYears } from '../../../utils/DateHelper';
import { findOption } from '../../../utils/Select';

const DataCollectionFormOrange = ({
  handleUpdate,
  loading,
  isSubmitted,
  user,
  isUserEditPage = false,
}) => {
  const initialValues = {
    gender: user?.extra?.gender ?? '',
    birth_year: user?.extra?.birth_year ?? '',
    level_of_studies: user?.extra?.level_of_studies ?? '',
    profession: user?.extra?.profession ?? '',
    level_of_reading_french: user?.extra?.level_of_reading_french ?? '',
    level_of_reading_bambara: user?.extra?.level_of_reading_bambara ?? '',
    language_father: user?.extra?.language_father ?? '',
    language_mother: user?.extra?.language_mother ?? '',
    languages_inside_home: user?.extra?.languages_inside_home ?? '',
    languages_outside_home: user?.extra?.languages_outside_home ?? '',
    languages_mainly_daily: user?.extra?.languages_mainly_daily ?? '',
  };

  const validationRules = {
    gender: string().nullable().required('Ce champ est obligatoire'),
    birth_year: number()
      .nullable()
      .required('Ce champ est obligatoire')
      .min(1900, 'Veuillez entrer une année de naissance valide')
      .max(2050, 'Veuillez entrer une année de naissance valide'),
    level_of_studies: string().nullable().required('Ce champ est obligatoire'),
    profession: string().required('Ce champ est obligatoire'),
    level_of_reading_french: string().nullable().required('Ce champ est obligatoire'),
    level_of_reading_bambara: string().nullable().required('Ce champ est obligatoire'),
    language_father: string().nullable().required('Ce champ est obligatoire'),
    language_mother: string().nullable().required('Ce champ est obligatoire'),
    languages_inside_home: string().nullable().required('Ce champ est obligatoire'),
    languages_outside_home: string().nullable().required('Ce champ est obligatoire'),
    languages_mainly_daily: string().nullable().required('Ce champ est obligatoire'),
  };

  const validationSchema = object().shape(validationRules);

  const handleSubmit = (values) => {
    const genderMapping = {
      homme: 'male',
      femme: 'female',
    };

    let userMetadata = {
      gender: genderMapping[values.gender],
      ethnicGroup: 'none',
      yearOfBirth: values.birth_year,
      spokenDialect: 'none',
      extra: { ...values },
    };

    handleUpdate(userMetadata);
  };

  const genderOptions = [
    { label: 'Homme', value: 'homme' },
    { label: 'Femme', value: 'femme' },
  ];

  const levelOfStudiesOptions = [
    { label: 'Fondamental', value: 'fondamental' },
    { label: 'Collège', value: 'collège' },
    { label: 'Lycée', value: 'lycée' },
    { label: 'Université', value: 'université' },
  ];

  const levelOfReadingOptions = [
    { label: 'Faible', value: 'faible' },
    { label: 'Moyen', value: 'moyen' },
    { label: 'Avancé', value: 'avancé' },
  ];

  const languageOptions = [
    { label: 'bambara', value: 'bambara' },
    { label: 'peul / foulfouldé', value: 'peul / foulfouldé' },
    { label: 'dogon', value: 'dogon' },
    { label: 'maraka / soninké', value: 'maraka / soninké' },
    { label: 'malinké', value: 'malinké' },
    { label: 'sonraï / djerma', value: 'sonraï / djerma' },
    { label: 'minianka', value: 'minianka' },
    { label: 'tamacheq', value: 'tamacheq' },
    { label: 'sénoufo', value: 'sénoufo' },
    { label: 'bobo', value: 'bobo' },
    { label: 'bozo', value: 'bozo' },
    { label: 'kassonké', value: 'kassonké' },
    { label: 'maure', value: 'maure' },
    { label: 'samogo', value: 'samogo' },
    { label: 'dafing', value: 'dafing' },
    { label: 'arabe', value: 'arabe' },
    { label: 'haoussa', value: 'haoussa' },
  ];

  const yearOfBirthOptions = getLastNYears(100, 20).map((year) => ({
    value: year,
    label: year.toString(),
  }));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(props) => {
        if (loading) {
          return <Spinner />;
        }
        return (
          <Form onSubmit={props.handleSubmit}>
            <Row>
              <Col sm={12} md={6}>
                <SelectWithValidation
                  id='gender'
                  propertyName='gender'
                  label='Quelle option parmi celles dans la liste vous correspond ? '
                  helpText='Quelle option parmi celles dans la liste vous correspond ?  '
                  options={genderOptions}
                  defaultOption={findOption(genderOptions, props.values.gender)}
                  touched={props.touched.gender}
                  error={props.errors.gender}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  isDisabled={isUserEditPage ? false : isSubmitted}
                />
              </Col>
              <Col sm={12} md={6}>
                <SelectWithValidation
                  id='birth_year'
                  propertyName='birth_year'
                  label='Année de naissance'
                  placeholder='YYYY'
                  helpText='Année de naissance'
                  options={yearOfBirthOptions}
                  defaultOption={findOption(yearOfBirthOptions, props.values.birth_year)}
                  defaultValue={props.values.birth_year}
                  touched={props.touched.birth_year}
                  error={props.errors.birth_year}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  isDisabled={isUserEditPage ? false : isSubmitted}
                />
              </Col>
            </Row>
            <hr />
            <Row className={isUserEditPage ? 'tw-hidden' : ''}>
              <Col sm={12} md={6}>
                <SelectWithValidation
                  id='level_of_studies'
                  propertyName='level_of_studies'
                  label="Niveau d'études"
                  placeholder="Niveau d'études"
                  helpText="Niveau d'études"
                  options={levelOfStudiesOptions}
                  defaultOption={findOption(levelOfStudiesOptions, props.values.level_of_studies)}
                  defaultValue={props.values.level_of_studies}
                  touched={props.touched.level_of_studies}
                  error={props.errors.level_of_studies}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  isDisabled={isUserEditPage ? true : isSubmitted}
                />
              </Col>
              <Col sm={12} md={6}>
                <InputWithValidation
                  id='profession'
                  propertyName='profession'
                  label='Métier'
                  placeholder='Métier'
                  helpText='Métier'
                  defaultValue={props.values.profession}
                  touched={props.touched.profession}
                  error={props.errors.profession}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  disabled={isUserEditPage ? true : isSubmitted}
                />
              </Col>
            </Row>
            <Row className={isUserEditPage ? 'tw-hidden' : ''}>
              <Col sm={12} md={6}>
                <SelectWithValidation
                  id='level_of_reading_french'
                  propertyName='level_of_reading_french'
                  label='Niveau de lecture en français'
                  placeholder='Niveau de lecture en français'
                  helpText='Niveau de lecture en français'
                  options={levelOfReadingOptions}
                  defaultOption={findOption(
                    levelOfReadingOptions,
                    props.values.level_of_reading_french
                  )}
                  defaultValue={props.values.level_of_reading_french}
                  touched={props.touched.level_of_reading_french}
                  error={props.errors.level_of_reading_french}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  isDisabled={isUserEditPage ? true : isSubmitted}
                />
              </Col>
              <Col sm={12} md={6}>
                <SelectWithValidation
                  id='level_of_reading_bambara'
                  propertyName='level_of_reading_bambara'
                  label='Niveau de lecture en bambara'
                  placeholder='Niveau de lecture en bambara'
                  helpText='Niveau de lecture en bambara'
                  options={levelOfReadingOptions}
                  defaultOption={findOption(
                    levelOfReadingOptions,
                    props.values.level_of_reading_bambara
                  )}
                  defaultValue={props.values.level_of_reading_bambara}
                  touched={props.touched.level_of_reading_bambara}
                  error={props.errors.level_of_reading_bambara}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  isDisabled={isUserEditPage ? true : isSubmitted}
                />
              </Col>
            </Row>
            <hr className={isUserEditPage ? 'tw-hidden' : ''} />
            <Row className={isUserEditPage ? 'tw-hidden' : ''}>
              <Col sm={12} md={6}>
                <SelectWithValidation
                  id='language_father'
                  propertyName='language_father'
                  label='Langue maternelle du père'
                  placeholder='Langue maternelle du père'
                  helpText='Langue maternelle du père'
                  options={languageOptions}
                  defaultOption={findOption(languageOptions, props.values.language_father)}
                  defaultValue={props.values.language_father}
                  touched={props.touched.language_father}
                  error={props.errors.language_father}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  isDisabled={isUserEditPage ? true : isSubmitted}
                />
              </Col>
              <Col sm={12} md={6}>
                <SelectWithValidation
                  id='language_mother'
                  propertyName='language_mother'
                  label='Langue maternelle de la mère'
                  placeholder='Langue maternelle de la mère'
                  helpText='Langue maternelle de la mère'
                  options={languageOptions}
                  defaultOption={findOption(languageOptions, props.values.language_mother)}
                  defaultValue={props.values.language_mother}
                  touched={props.touched.language_mother}
                  error={props.errors.language_mother}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  isDisabled={isUserEditPage ? true : isSubmitted}
                />
              </Col>
            </Row>
            <Row className={isUserEditPage ? 'tw-hidden' : ''}>
              <Col sm={12} md={6}>
                <SelectWithValidation
                  isMulti
                  id='languages_inside_home'
                  propertyName='languages_inside_home'
                  label='Langue(s) parlée(s) à la maison'
                  placeholder='Langue(s) parlée(s) à la maison'
                  helpText='Langue(s) parlée(s) à la maison'
                  options={languageOptions}
                  defaultOption={findOption(languageOptions, props.values.languages_inside_home)}
                  defaultValue={props.values.languages_inside_home}
                  touched={props.touched.languages_inside_home}
                  error={props.errors.languages_inside_home}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  isDisabled={isUserEditPage ? true : isSubmitted}
                />
              </Col>
              <Col sm={12} md={6}>
                <SelectWithValidation
                  id='languages_outside_home'
                  propertyName='languages_outside_home'
                  label='Langue(s) parlée(s) en dehors de la maison'
                  placeholder='Langue(s) parlée(s) en dehors de la maison'
                  helpText='Langue(s) parlée(s) en dehors de la maison'
                  options={languageOptions}
                  defaultOption={findOption(languageOptions, props.values.languages_outside_home)}
                  defaultValue={props.values.languages_outside_home}
                  touched={props.touched.languages_outside_home}
                  error={props.errors.languages_outside_home}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  isDisabled={isUserEditPage ? true : isSubmitted}
                />
              </Col>
            </Row>
            <Row className={isUserEditPage ? 'tw-hidden' : ''}>
              <Col sm={12} md={6}>
                <SelectWithValidation
                  id='languages_mainly_daily'
                  propertyName='languages_mainly_daily'
                  label='Langue parlée majoritairement au quotidien'
                  placeholder='Langue parlée majoritairement au quotidien'
                  helpText='Langue parlée majoritairement au quotidien'
                  options={languageOptions}
                  defaultOption={findOption(languageOptions, props.values.languages_mainly_daily)}
                  defaultValue={props.values.languages_mainly_daily}
                  touched={props.touched.languages_mainly_daily}
                  error={props.errors.languages_mainly_daily}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  isDisabled={isUserEditPage ? true : isSubmitted}
                />
              </Col>
            </Row>
            <hr className={isUserEditPage ? 'tw-hidden' : ''} />
            <p className={isUserEditPage ? 'tw-hidden' : ''}>
              TAUS collecte ces données au nom de notre client, pour s'assurer que les voix que nous
              recueillons sont une représentation égale des différents groupes d'âge/sexe. C'est le
              seul but pour lequel ces données seront utilisées.
            </p>
            <Row>
              <Col sm={12} md={6}>
                <Button type='submit' disabled={isUserEditPage ? false : isSubmitted}>
                  {isUserEditPage ? 'Submit' : 'Envoyer'}
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DataCollectionFormOrange;
