// External libs
import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { format } from 'date-fns';

// Internal libs
import { SPOKEN_LANGUAGE_LEVEL } from '../../../models/Language';
import { Text } from '../../../components/parameters/TextParameter';
import Tooltip from '../../../components/Tooltip';
import UsersTable from '../components/UsersTable';
import { MultiSelect } from '../../../components/parameters/MultiSelectParameter';
import MultiSelectWithCheckboxes from '../../../components/selects/MultiSelectWithCheckboxes';
import { Select } from '../../../components/parameters/SelectParameter';
import CheckboxWithValidation from '../../../components/forms/CheckboxWithValidation';
import {
  combinedEthnicGroupsAndRaces,
  genderOptions,
  allDialectOptions,
} from '../../forms/amazon/Options';
import VisibleColumnsSelect from '../../../components/tables/VisibleColumnsSelect';
import { getLastNYears } from '../../../utils/DateHelper';
import User from '../../../models/User';
import Utils from '../../../utils/Utils';

const UsersPageView = ({
  isLoading = false,
  canDelete = false,
  canCreate = false,
  spokenLanguages = [],
  groups = [],
  selectedGroups = null,
  canManageGroups,
  users = [],
  selectedUsers = [],
  newUsers = [],
  tests = [],
  onSelectedUserChange = null,
  onSelectedUsersClear,
  onAddUserToRole = null,
  onRemoveUserFromRole = null,
  onNewUserSubmit = null,
  onUserDelete = null,
  onNewUserUpdate = null,
  onRefreshButtonClick = null,
  onCreateButtonClick = null,
  onDeleteButtonClick = null,
  onExportButtonClick = null,
  onGroupSelectApply = null,
  onFilterValueChange = null,
  defaultFilterValues = null,
  tablePageIndex = 0,
  tablePageSize = 0,
  tableTotalSize = 0,
  tableSorting = [],
  onTablePageChange = null,
  onTablePageSizeChange = null,
  onTableSortingChange = null,
  exportModalShow = false,
  exportModalMessage = null,
  onMessageButtonClick = null,
  canSendMessage = false,
  isAdmin = false,
  hiddenColumns = [],
  hiddenColumnsChanged = null,
}) => {
  const spokenLanguageOptions =
    spokenLanguages
      ?.map((lang) => ({ name: lang?.name, value: lang?.id }))
      ?.sort((a, b) => a?.name?.localeCompare(b?.name)) ?? [];

  const groupOptions =
    groups
      ?.map((obj) => ({ value: obj?.id, label: obj?.name }))
      ?.sort((a, b) => a?.label?.localeCompare(b?.label))
      ?.sort((a, b) => {
        let aSort = selectedGroups?.selected?.includes(a?.value)
          ? 0
          : selectedGroups?.indeterminate?.includes(a?.value)
          ? 1
          : 2;
        let bSort = selectedGroups?.selected?.includes(b?.value)
          ? 0
          : selectedGroups?.indeterminate?.includes(b?.value)
          ? 1
          : 2;
        return aSort - bSort;
      }) ?? [];

  const testOptions = tests?.map((obj) => ({ value: obj?.id, name: obj?.name })) ?? [];
  const scoreComparatorOptions = [
    { value: 'taskEvaluationScore >=', name: 'Greater than or equal to' },
    { value: 'taskEvaluationScore <', name: 'Less than' },
  ];
  const scoreOptions = [
    { value: '100', name: '100' },
    { value: '90', name: '90' },
    { value: '80', name: '80' },
    { value: '70', name: '70' },
    { value: '60', name: '60' },
    { value: '50', name: '50' },
    { value: '40', name: '40' },
    { value: '30', name: '30' },
    { value: '20', name: '20' },
    { value: '10', name: '10' },
  ];

  const yearOfBirthOptions = getLastNYears(100).map((year) => ({
    value: year,
    label: year.toString(),
  }));

  const tableColumns = [
    {
      name: 'username',
      title: 'Username (email)',
      sort: true,
      editable: true,
      minWidth: 200,
      responsivePriority: 'always',
      visible: false,
    },
    {
      name: 'fullName',
      title: 'Name',
      sort: true,
      minWidth: 200,
      responsivePriority: 2,
      getCellValue: (row) => `${row.firstName ?? ''} ${row?.lastName ?? ''}`.trim(),
    },
    {
      name: 'country',
      title: 'Country',
      sort: true,
      minWidth: 150,
      responsivePriority: 3,
      getCellValue: (row) => row?.country ?? '',
    },
    {
      name: 'spokenLanguages',
      title: 'Spoken Languages',
      sort: false,
      minWidth: 200,
      responsivePriority: 4,
      getCellValue: (row) => row.spokenLanguages ?? [],
    },
    {
      name: 'groups',
      title: 'Groups',
      sort: false,
      minWidth: 200,
      responsivePriority: 5,
      getCellValue: (row) => row?.groupsBelong ?? [],
    },
    {
      name: 'tests',
      title: 'Tests',
      sort: false,
      minWidth: 200,
      responsivePriority: 6,
      getCellValue: (row) => row?.validationTasks ?? [],
    },
    {
      name: 'ethnicGroup',
      title: 'Ethnic Group',
      sort: true,
      minWidth: 200,
      responsivePriority: 7,
      getCellValue: (row) => row?.userMetadata?.ethnicGroup,
    },
    {
      name: 'gender',
      title: 'Gender',
      sort: true,
      minWidth: 150,
      responsivePriority: 8,
      getCellValue: (row) => row?.userMetadata?.gender,
    },
    {
      name: 'spokenDialect',
      title: 'Dialect',
      sort: true,
      minWidth: 200,
      responsivePriority: 9,
      getCellValue: (row) => row?.userMetadata?.spokenDialect,
    },
    {
      name: 'yearOfBirth',
      title: 'Year of Birth',
      sort: true,
      minWidth: 100,
      responsivePriority: 10,
      getCellValue: (row) => row?.userMetadata?.yearOfBirth,
    },
    {
      name: 'provenance ',
      title: 'Provenance',
      sort: false,
      minWidth: 100,
      responsivePriority: 'always',
      getCellValue: (row) => row?.provenance ?? '',
    },
    {
      name: 'referrer ',
      title: 'Referrer',
      sort: false,
      minWidth: 200,
      responsivePriority: 'always',
      getCellValue: (row) => row?.referral?.referralReferrer ?? '',
    },
    {
      name: 'referralCode ',
      title: 'Referral Code',
      sort: false,
      minWidth: 100,
      responsivePriority: 'always',
      getCellValue: (row) => row?.referral?.referralCode ?? '',
    },
    {
      name: 'timestampRegistered',
      title: 'Registered On',
      sort: true,
      minWidth: 200,
      responsivePriority: 100,
      getCellValue: (row) =>
        !row?.timestampRegistered ? '' : format(row.timestampRegistered, 'yyyy-MM-dd HH:mm'),
    },
    {
      name: 'lastActive',
      title: 'Latest Activity',
      sort: true,
      minWidth: 200,
      responsivePriority: 101,
      getCellValue: (row) => row?.timestampActive,
    },
  ];

  const visibleColumnNames = tableColumns
    .filter((col) => !hiddenColumns.includes(col.name))
    .map((col) => col.name);

  const visibleColumnsSelectChanged = (values) => {
    const hiddenColumnNames = tableColumns
      .filter((col) => !values.includes(col.name))
      .map((col) => col.name);
    hiddenColumnsChanged(hiddenColumnNames);
  };

  const genderOptionsWithNames = genderOptions('UK').map((gender) => ({
    value: gender.value,
    name: gender.label,
  }));

  const yearOfBirthOptionsWithNames = yearOfBirthOptions.map((year) => ({
    value: year.value,
    name: year.label,
  }));

  return (
    <div className='col-12 px-0'>
      <div className={`card-body row col-lg-12 mx-0 pt-0 ${isLoading ? 'whirl sphere' : ''}`}>
        <div className='card-body col-lg-12 b bg-white'>
          <FiltersBar
            defaultFilterValues={defaultFilterValues}
            spokenLanguageOptions={spokenLanguageOptions}
            groupOptions={groupOptions}
            testOptions={testOptions}
            scoreOptions={scoreOptions}
            scoreComparatorOptions={scoreComparatorOptions}
            onFilterValueChange={onFilterValueChange}
            tableColumnsOptions={tableColumns}
            tableVisibleColumnsSelectChanged={visibleColumnsSelectChanged}
            visibleColumns={visibleColumnNames}
            dialectOptions={allDialectOptions}
            ethnicGroupOptions={combinedEthnicGroupsAndRaces}
            genderOptions={genderOptionsWithNames}
            yearOfBirthOptions={yearOfBirthOptionsWithNames}
          />
          <div className='d-flex align-items-center'>
            <div className='flex-grow-1'>
              Selected {selectedUsers.length} of {tableTotalSize} rows
              {!!selectedUsers.length && (
                <Tooltip title='Clear Selection'>
                  <button
                    className='btn btn-secondary border-0 ml-1 px-2'
                    onClick={onSelectedUsersClear}
                  >
                    <em className='far fa-trash-alt' style={{ fontSize: '20px' }} />
                  </button>
                </Tooltip>
              )}
            </div>
            <ButtonBar
              canManageGroups={canManageGroups}
              groupOptions={groupOptions}
              selectedGroups={selectedGroups}
              hasSelectedUsers={selectedUsers?.length > 0}
              allowCreate={canCreate}
              allowDelete={canDelete}
              onRefreshButtonClick={onRefreshButtonClick}
              onCreateButtonClick={onCreateButtonClick}
              onDeleteButtonClick={onDeleteButtonClick}
              onGroupSelectApply={onGroupSelectApply}
              onExportButtonClick={onExportButtonClick}
              onMessageButtonClick={onMessageButtonClick}
              canSendMessage={canSendMessage}
            />
          </div>
          <Table
            tableColumns={tableColumns}
            hiddenColumns={hiddenColumns}
            users={users}
            onAddUserToRole={onAddUserToRole}
            onRemoveUserFromRole={onRemoveUserFromRole}
            isAdmin={isAdmin}
            pageIndex={tablePageIndex}
            pageSize={tablePageSize}
            totalSize={tableTotalSize}
            sorting={tableSorting}
            onSortingChange={onTableSortingChange}
            selectedUsers={selectedUsers}
            onSelectionChange={onSelectedUserChange}
            addedRows={newUsers}
            onAddedRowsUpdate={onNewUserUpdate}
            onRowInsertCommit={onNewUserSubmit}
            onRowDeleteCommit={onUserDelete}
            onPageChange={onTablePageChange}
            onPageSizeChange={onTablePageSizeChange}
            onUserDeleted={onRefreshButtonClick}
          />
        </div>
      </div>
      <UserExportModal isOpen={exportModalShow} message={exportModalMessage} />
    </div>
  );
};

// -----------------------------------------------------------------------------
// ----------------------------- FILTERS BAR -----------------------------------
// -----------------------------------------------------------------------------
const FiltersBar = ({
  defaultFilterValues = null,
  onFilterValueChange = null,
  spokenLanguageOptions = [],
  groupOptions = [],
  testOptions = [],
  scoreOptions = [],
  scoreComparatorOptions = [],
  dialectOptions = [],
  ethnicGroupOptions = [],
  genderOptions = [],
  yearOfBirthOptions = [],
  tableColumnsOptions,
  visibleColumns,
  tableVisibleColumnsSelectChanged,
}) => {
  const areFiltersEmpty = (filters) => Object.values(filters)?.filter((obj) => obj)?.length === 0;
  let [filters] = useState(defaultFilterValues ?? {});
  let [filtersAreEmpty, setFiltersAreEmpty] = useState(areFiltersEmpty(defaultFilterValues));
  let [reset, setReset] = useState(0);

  const onChange = (filtersToChange) => {
    filtersToChange.forEach(({ name, value }) => {
      filters[name] = value;
    });
    if (!!onFilterValueChange) {
      onFilterValueChange(filters);
    }
    if (areFiltersEmpty(filters)) {
      setFiltersAreEmpty(true);
    } else {
      setFiltersAreEmpty(false);
    }
  };

  const onFiltersClear = () => {
    Object.keys(filters).forEach((key) => {
      filters[key] = null;
    });
    if (!!onFilterValueChange) {
      onFilterValueChange(filters);
    }
    setReset(reset + 1);
  };

  const spokenLanguageLevelOptions = [
    { name: 'Native', value: SPOKEN_LANGUAGE_LEVEL.NATIVE },
    { name: 'Advanced', value: SPOKEN_LANGUAGE_LEVEL.ADVANCED },
    { name: 'Intermediate', value: SPOKEN_LANGUAGE_LEVEL.INTERMEDIATE },
    { name: 'Elementary', value: SPOKEN_LANGUAGE_LEVEL.ELEMENTARY },
  ];

  const apiRolesFilters = Object.values(User.API_ROLES)
    .filter((role) => !(role === 'USER'))
    .map((role) => ({ name: Utils.textFirstOnlyUpper(role), value: User.API_ROLES_TO_ID[role] }));

  const provenanceFilters = [
    { name: 'Indeed', value: 'Indeed' },
    { name: 'LinkedIn', value: 'LinkedIn' },
    { name: 'Social Media', value: 'Social Media' },
    { name: 'Join', value: 'Join' },
    { name: 'University job board', value: 'University job board' },
    { name: 'HLP website', value: 'HLP website' },
    { name: 'Others', value: 'Others' },
  ];

  const isEnabledFilters = [
    { name: 'Deleted Only', value: false },
    { name: 'Exclude', value: true },
  ];

  const [basicDropdownOpen, setBasicDropdownOpen] = useState(false);
  const [testsDropdownOpen, setTestsDropdownOpen] = useState(false);
  const [demographicsDropdownOpen, setDemographicsDropdownOpen] = useState(false);
  const [referralDropdownOpen, setReferralDropdownOpen] = useState(false);
  const toggle = (setDropdownOpen) => () => setDropdownOpen((prevState) => !prevState);

  const hasBasicFilters =
    (!!filters.username && filters.username.length > 0) ||
    (!!filters.fullName && filters.fullName.length > 0) ||
    (!!filters.country && filters.country.length > 0) ||
    (!!filters.spokenLanguage && filters.spokenLanguage.length > 0) ||
    (!!filters.spokenLanguageLevel && filters.spokenLanguageLevel.length > 0) ||
    (!!filters.provenance && filters.provenance.length > 0) ||
    (!!filters.apiRole && filters.apiRole.length > 0) ||
    (!!filters.enabled && filters.enabled.length > 0) ||
    (!!filters.hasRole && filters.hasRole.length > 0) ||
    !!filters.ungroupedOnly;

  const hasTestFilters =
    (!!filters.tests && filters.tests.length > 0) ||
    (!!filters.scoreComparator && filters.scoreComparator.length > 0) ||
    (!!filters.score && filters.score.length > 0);

  const hasDemographicsFilters =
    (!!filters.ethnicGroups && filters.ethnicGroups.length > 0) ||
    (!!filters.gender && filters.gender.length > 0) ||
    (!!filters.dialects && filters.dialects.length > 0) ||
    (!!filters.yearOfBirthStart && filters.yearOfBirthStart.length > 0) ||
    (!!filters.yearOfBirthEnd && filters.yearOfBirthEnd.length > 0);

  const hasReferralFilters =
    (!!filters.referralCode && filters.referralCode.length > 0) ||
    (!!filters.referralReferrer && filters.referralReferrer.length > 0) ||
    !!filters.isReferred;

  return (
    <div className='row col-12 mx-0 px-0' key={reset}>
      <div className='col-12 d-flex px-1 mb-1 align-items-end'>
        <h4 className='flex-grow-1'>
          Filters
          {filtersAreEmpty ? null : (
            <Tooltip title='Clear Filters'>
              <button
                id='clearFilters'
                className='btn btn-secondary border-0 p-0 ml-3'
                onClick={onFiltersClear}
              >
                <em className='far fa-trash-alt' style={{ fontSize: '17px' }} />
              </button>
            </Tooltip>
          )}
        </h4>
        <VisibleColumnsSelect
          tableColumnsOptions={tableColumnsOptions}
          tableVisibleColumnsSelectChanged={tableVisibleColumnsSelectChanged}
          visibleColumns={visibleColumns}
        />
      </div>
      <div className='pl-0 pr-2 pb-2'>
        <Dropdown isOpen={basicDropdownOpen} toggle={toggle(setBasicDropdownOpen)} inNavbar={true}>
          <DropdownToggle caret color={hasBasicFilters ? 'primary' : 'secondary'}>
            Basic
          </DropdownToggle>
          <DropdownMenu style={{ minWidth: 360 }}>
            <DropdownItem text>
              <div className='row px-0'>
                <Text
                  key={reset}
                  defaultValue={filters?.username ?? null}
                  placeholder='Username'
                  onChange={(value) => onChange([{ name: 'username', value }])}
                />
              </div>
            </DropdownItem>
            <DropdownItem text>
              <div className='row px-0'>
                <Text
                  key={reset}
                  defaultValue={filters?.fullName ?? null}
                  placeholder='Full name'
                  onChange={(value) => onChange([{ name: 'fullName', value }])}
                />
              </div>
            </DropdownItem>
            <DropdownItem text>
              <div className='row px-0'>
                <Text
                  key={reset}
                  defaultValue={filters?.country ?? null}
                  placeholder='Country'
                  onChange={(value) => onChange([{ name: 'country', value }])}
                />
              </div>
            </DropdownItem>
            <DropdownItem text>
              <div className='row px-0'>
                <MultiSelect
                  key={reset}
                  options={spokenLanguageOptions}
                  defaultValue={filters?.spokenLanguage ?? []}
                  onChange={(value) => onChange([{ name: 'spokenLanguage', value }])}
                  typeOptions={{ placeholder: 'Spoken languages' }}
                  style={{ width: '100%' }}
                />
              </div>
            </DropdownItem>
            <DropdownItem text>
              <div className='row px-0'>
                <MultiSelect
                  key={reset}
                  options={spokenLanguageLevelOptions}
                  defaultValue={filters?.spokenLanguageLevel ?? []}
                  onChange={(value) => onChange([{ name: 'spokenLanguageLevel', value }])}
                  typeOptions={{ placeholder: 'Spoken language level' }}
                  style={{ width: '100%' }}
                />
              </div>
            </DropdownItem>
            <DropdownItem text>
              <div className='row px-0'>
                <MultiSelect
                  key={reset}
                  options={apiRolesFilters}
                  defaultValue={filters?.apiRole ?? []}
                  onChange={(value) => onChange([{ name: 'apiRole', value }])}
                  typeOptions={{ placeholder: 'Api Role' }}
                  style={{ width: '100%' }}
                />
              </div>
            </DropdownItem>
            <DropdownItem text>
              <div className='row px-0'>
                <MultiSelect
                  key={reset}
                  options={provenanceFilters}
                  defaultValue={filters?.provenance ?? []}
                  onChange={(value) => onChange([{ name: 'provenance', value }])}
                  typeOptions={{ placeholder: 'Provenance' }}
                  style={{ width: '100%' }}
                />
              </div>
            </DropdownItem>
            <DropdownItem text>
              <div className='row px-0'>
                <MultiSelect
                  key={reset}
                  options={isEnabledFilters}
                  defaultValue={filters?.enabled ?? []}
                  onChange={(value) => onChange([{ name: 'enabled', value }])}
                  typeOptions={{ placeholder: 'Deleted Users' }}
                  style={{ width: '100%' }}
                />
              </div>
            </DropdownItem>
            <div className='dropdown-item-text'>
              <CheckboxWithValidation
                key={reset}
                propertyName='ungroupedOnly'
                defaultValue={!!filters?.ungroupedOnly}
                onChange={(_, value) => {
                  let filtersToChange = [{ name: 'ungroupedOnly', value }];
                  if (value === true) {
                    filtersToChange.push({
                      name: 'groupsBelong',
                      value: [],
                    });
                  }
                  onChange(filtersToChange);
                }}
                onBlur={() => {}}
                label='Ungrouped only'
              />
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className='pl-0 pr-2 pb-2'>
        <Dropdown isOpen={testsDropdownOpen} toggle={toggle(setTestsDropdownOpen)}>
          <DropdownToggle className='w-100' caret color={hasTestFilters ? 'primary' : 'secondary'}>
            Tests
          </DropdownToggle>
          <DropdownMenu style={{ minWidth: 360 }}>
            <DropdownItem text>
              <div className='row px-0'>
                <MultiSelect
                  key={reset}
                  options={testOptions}
                  defaultValue={filters?.tests ?? []}
                  onChange={(value) => onChange([{ name: 'tests', value }])}
                  typeOptions={{ placeholder: 'Tests' }}
                  style={{ width: '100%' }}
                />
              </div>
            </DropdownItem>
            <DropdownItem text>
              <div className='row'>
                <div className='col-6 px-0'>
                  <Select
                    key={reset}
                    options={scoreComparatorOptions}
                    defaultValue={filters?.scoreComparator ?? ''}
                    onChange={(value) => onChange([{ name: 'scoreComparator', value }])}
                    typeOptions={{ placeholder: 'Score comparator' }}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className='col-6 px-0'>
                  <Select
                    key={reset}
                    options={scoreOptions}
                    defaultValue={filters?.score ?? ''}
                    onChange={(value) => onChange([{ name: 'score', value }])}
                    typeOptions={{ placeholder: 'Score' }}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className='pl-0 pr-2 pb-2'>
        <Dropdown isOpen={demographicsDropdownOpen} toggle={toggle(setDemographicsDropdownOpen)}>
          <DropdownToggle
            className='w-100'
            caret
            color={hasDemographicsFilters ? 'primary' : 'secondary'}
          >
            Demographics
          </DropdownToggle>
          <DropdownMenu style={{ minWidth: 360 }}>
            <DropdownItem text>
              <div className='row px-0'>
                <MultiSelect
                  key={reset}
                  options={ethnicGroupOptions}
                  defaultValue={filters?.ethnicGroups ?? []}
                  onChange={(value) => onChange([{ name: 'ethnicGroups', value }])}
                  typeOptions={{ placeholder: 'Origins / Ethnic group / Race / Ancestry' }}
                  style={{ width: '100%' }}
                />
              </div>
            </DropdownItem>
            <DropdownItem text>
              <div className='row px-0'>
                <MultiSelect
                  key={reset}
                  options={genderOptions}
                  defaultValue={filters?.gender ?? []}
                  onChange={(value) => onChange([{ name: 'gender', value }])}
                  typeOptions={{ placeholder: 'Gender' }}
                  style={{ width: '100%' }}
                />
              </div>
            </DropdownItem>
            <DropdownItem text>
              <div className='row px-0'>
                <MultiSelect
                  key={reset}
                  options={dialectOptions}
                  defaultValue={filters?.dialects ?? []}
                  onChange={(value) => onChange([{ name: 'dialects', value }])}
                  typeOptions={{ placeholder: 'Dialect/Quest Native Language' }}
                  style={{ width: '100%' }}
                />
              </div>
            </DropdownItem>
            <DropdownItem text className='tw-px-2'>
              <div className='tw-grid tw-grid-cols-2 tw-gap-2'>
                <div>
                  Year of birth (from):
                  <Select
                    key={reset}
                    options={yearOfBirthOptions}
                    defaultValue={filters?.yearOfBirthStart ?? ''}
                    onChange={(value) => onChange([{ name: 'yearOfBirthStart', value }])}
                    typeOptions={{ placeholder: 'YYYY' }}
                    style={{ width: '100%' }}
                  />
                </div>
                <div>
                  Year of birth (to):
                  <Select
                    key={reset}
                    options={yearOfBirthOptions}
                    defaultValue={filters?.yearOfBirthEnd ?? ''}
                    onChange={(value) => onChange([{ name: 'yearOfBirthEnd', value }])}
                    typeOptions={{ placeholder: 'YYYY' }}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className='pl-0 pr-2 pb-2'>
        <Dropdown isOpen={referralDropdownOpen} toggle={toggle(setReferralDropdownOpen)}>
          <DropdownToggle caret color={hasReferralFilters ? 'primary' : 'secondary'}>
            Referral
          </DropdownToggle>
          <DropdownMenu style={{ minWidth: 360 }}>
            <DropdownItem text>
              <div className='row px-0'>
                <Text
                  key={reset}
                  defaultValue={filters?.referralCode ?? null}
                  placeholder='Referral Code'
                  onChange={(value) => onChange([{ name: 'referralCode', value }])}
                />
              </div>
            </DropdownItem>
            <DropdownItem text>
              <div className='row px-0'>
                <Text
                  key={reset}
                  defaultValue={filters?.referralReferrer ?? null}
                  placeholder='Referrer email'
                  onChange={(value) => onChange([{ name: 'referralReferrer', value }])}
                />
              </div>
            </DropdownItem>
            <div className='dropdown-item-text'>
              <CheckboxWithValidation
                key={reset}
                propertyName='isReferred'
                defaultValue={!!filters?.isReferred}
                onChange={(_, value) => {
                  let filtersToChange = [{ name: 'isReferred', value }];
                  onChange(filtersToChange);
                }}
                onBlur={() => {}}
                label='Referred Only'
              />
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
      <MultiSelect
        key={`${reset}-${!!filters.ungroupedOnly}`}
        disabled={!!filters?.ungroupedOnly}
        options={groupOptions.map((obj) => ({ value: obj.value, name: obj.label }))}
        defaultValue={filters?.groupsBelong ?? []}
        onChange={(value) => onChange([{ name: 'groupsBelong', value }])}
        typeOptions={{ placeholder: 'Groups' }}
        style={{ minWidth: 320, maxWidth: 320 }}
      />
    </div>
  );
};

// ---------------------------------------------------------------------------
// ----------------------- BUTTONS BAR ---------------------------------------
// ---------------------------------------------------------------------------
const ButtonBar = ({
  hasSelectedUsers = false,
  allowDelete = false,
  allowCreate = false,
  groupOptions = [],
  selectedGroups = [],
  canManageGroups,
  onDeleteButtonClick = null,
  onCreateButtonClick = null,
  onRefreshButtonClick = null,
  onExportButtonClick = null,
  onGroupSelectApply = null,
  onMessageButtonClick = null,
  canSendMessage = false,
}) => {
  return (
    <div className='row m-0 justify-content-between'>
      <div
        role='group'
        className='col row m-0 pb-2 justify-content-end px-1 btn-group'
        style={{ height: '40px' }}
      >
        {canSendMessage && hasSelectedUsers && (
          <Tooltip key='message_users_tooltip' title='Send a message to selected users'>
            <button className='btn btn-secondary border-0 px-2' onClick={onMessageButtonClick}>
              <em className='fas fa-envelope' style={{ fontSize: '20px' }} />
            </button>
          </Tooltip>
        )}
        {hasSelectedUsers && (
          <GroupsMenu
            canManageGroups={canManageGroups}
            options={groupOptions}
            originalValue={selectedGroups}
            key={selectedGroups?.selected?.length + selectedGroups?.indeterminate?.length}
            onApply={onGroupSelectApply}
          />
        )}
        {allowCreate && (
          <Tooltip key='add_tooltip' title='Register User'>
            <button className='btn btn-secondary border-0 px-2' onClick={onCreateButtonClick}>
              <em className='fas fa-user-plus' style={{ fontSize: '20px' }} />
            </button>
          </Tooltip>
        )}
        <Tooltip key='export_tooltip' title='Export to Excel Sheet'>
          <button className='btn btn-secondary border-0 px-2' onClick={onExportButtonClick}>
            <em className='fas fa-download' style={{ fontSize: '20px' }} />
          </button>
        </Tooltip>
        <Tooltip title='Refresh Table' id='refresh-button'>
          <button className='btn btn-secondary border-0 px-2' onClick={onRefreshButtonClick}>
            <em className='fas fa-sync-alt' style={{ fontSize: '20px' }} />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

const GroupsMenu = ({
  options,
  originalValue = { selected: [], indeterminate: [] },
  canManageGroups,
  onApply,
}) => {
  const [value, setValue] = useState(originalValue);
  const resetValue = useRef(originalValue);

  const handleChange = (_, { action, option }) => {
    setValue((oldValue) => {
      const { selected, indeterminate } = oldValue;

      let newSelected = [...selected];
      let newIndeterminate = [...indeterminate];

      if (action === 'select-option') {
        if (resetValue.current.indeterminate.includes(option.value)) {
          newIndeterminate.push(option.value);
        } else {
          newSelected.push(option.value);
        }
      }

      if (action === 'deselect-option') {
        if (indeterminate.includes(option.value)) {
          newIndeterminate = newIndeterminate.filter((value) => value !== option.value);
          newSelected.push(option.value);
        } else {
          newSelected = newSelected.filter((value) => value !== option.value);
        }
      }

      return { selected: newSelected, indeterminate: newIndeterminate };
    });
  };

  const handleReset = () => {
    setValue(resetValue.current);
  };

  const handleApply = () => {
    onApply(value);
  };

  const { selected, indeterminate } = value;

  return (
    <MultiSelectWithCheckboxes
      options={options}
      value={options
        .filter(({ value }) => selected.includes(value) || indeterminate.includes(value))
        .map(({ value, label }) => ({
          value,
          label,
          indeterminate: !!indeterminate.includes(value),
        }))}
      onChange={handleChange}
      isSearchable
      menuListFooter={
        <FooterActions
          disabled={value === resetValue.current}
          onApply={handleApply}
          onReset={handleReset}
        />
      }
      toggleButton={
        <Tooltip title='Selected Users Edit Groups'>
          <button disabled={!canManageGroups} className='btn btn-secondary border-0 px-2'>
            <em className='fas fa-users' style={{ fontSize: '20px' }} />
          </button>
        </Tooltip>
      }
    />
  );
};

const FooterActions = ({ disabled, onApply, onReset }) => {
  return (
    <div className='my-1'>
      <button onClick={onApply} disabled={disabled} className='btn btn-secondary border-0 w-100'>
        Apply
      </button>
      <button onClick={onReset} disabled={disabled} className='btn btn-secondary border-0 w-100'>
        Reset
      </button>
    </div>
  );
};

// ------------------------------------------------------------------------
// --------------------------- EXPORT MODAL -------------------------------
// ------------------------------------------------------------------------
const UserExportModal = ({ isOpen = false, message = null }) => {
  return (
    <Modal show={isOpen} size='md'>
      <Modal.Header className='bg-primary text-center'>
        <Modal.Title className='w-100 my-0'>Exporting Users to Excel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-row'>
          <div className='ball-pulse pr-3'>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className='pr-3'>{message}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

// ------------------------------------------------------------------------
// --------------------------- TABLE --------------------------------------
// ------------------------------------------------------------------------

const Table = ({
  users = [],
  isAdmin = false,
  onAddUserToRole = null,
  onRemoveUserFromRole = null,
  selectedUsers = [],
  pageIndex = 0,
  pageSize = 10,
  totalSize = 0,
  sorting = [],
  onSelectionChange = null,
  onPageChange = null,
  onPageSizeChange = null,
  onSortingChange = null,
  onRowDeleteCommit = null,
  onRowInsertCommit = null,
  addedRows = [],
  onAddedRowsUpdate = null,
  tableColumns,
  hiddenColumns = [],
  onUserDeleted = null,
}) => {
  const onAddedRowsCancel = (data = []) => {
    const canceledIds = data?.map((obj) => obj?.id) ?? [];
    const updated = addedRows?.filter((obj) => !canceledIds?.includes(obj?.id)) ?? [];
    if (!!onAddedRowsUpdate) onAddedRowsUpdate(updated);
  };

  let props = {
    data: users ?? [],
    isAdmin,
    onAddUserToRole: onAddUserToRole,
    onRemoveUserFromRole: onRemoveUserFromRole,
    selection: selectedUsers ?? [],
    onSelectionChange: onSelectionChange,
    onPageChange: onPageChange,
    onPageSizeChange: onPageSizeChange,
    onSortingChange: onSortingChange,
    totalSize: totalSize ?? 0,
    page: pageIndex ?? 0,
    pageSize: pageSize ?? 10,
    sorting: sorting ?? [],
    tableColumns,
    hiddenColumns,
    onRowDeleteCommitAsync: onRowDeleteCommit,
    onRowInsertCommitAsync: onRowInsertCommit,
    addedRows: addedRows ?? [],
    onAddedRowsCancel: onAddedRowsCancel,
    onAddedRowsUpdate: onAddedRowsUpdate,
    onUserDeleted: onUserDeleted,
  };

  return <UsersTable {...props} />;
};

export default UsersPageView;
