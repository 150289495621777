import React, { useState } from 'react';
import UserAccountRecoverPageView from '../views/UserAccountRecoverPageView';
import { useHistory, useRouteMatch } from 'react-router-dom';
import UserController from '../../../controllers/UserController';
import Swal from 'sweetalert2';
import { PAGES } from '../..';

const UserAccountRecoverPageState = () => {
  const {
    params: { token },
  } = useRouteMatch('/user/account/recover/:token');
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const onSubmitButtonClick = async (formData) => {
    setLoading(true);
    try {
      await UserController.updatePassword(token, formData)
        .then(() => {
          showPasswordChangeSuccess();
          history.replace(PAGES.SIGN_IN.path());
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      if (e.message.indexOf(',') > 0) {
        const errors = e.message.slice(1, -1).split(',');
        setErrors(errors);
      } else {
        setErrors(e.message);
      }
    }
  };

  const showPasswordChangeSuccess = async (email) => {
    return Swal.mixin({
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-outline-secondary mx-2 px-5 btn-lg font-weight-bold',
      },
    }).fire({
      title: 'Password Updated',
      html: `Your password has been updated! You can now sign in using your new password.`,
      confirmButtonText: 'Close',
      reverseButtons: true,
    });
  };

  return (
    <UserAccountRecoverPageView
      errors={errors}
      loading={loading}
      onSubmitButtonClick={onSubmitButtonClick.bind(this)}
    />
  );
};

export default UserAccountRecoverPageState;
