import { TYPES } from '../actions/pages';

export const pages = (state = null, action = {}) => {
  switch (action.type) {
    case TYPES.SAVE_USER_STATE: {
      let newState = { ...state };
      newState[action.page] = action.state;
      return newState;
    }
    default:
      return state;
  }
};

export default pages;
