import Utils from './Utils';

export const parseQueryString = (queryString) => {
  const searchParams = new URLSearchParams(queryString);
  let parsedObject = {};
  for (const key of searchParams.keys()) {
    let parsedValue;
    const value = searchParams.getAll(key);
    if (key === 'sort') {
      let sort = value[0].split(',');
      parsedValue = [{ columnName: sort[0], direction: sort[1] }];
    } else if (value.length > 1) {
      parsedValue = value;
    } else {
      const commaSeparated = value[0].split(',');
      parsedValue = commaSeparated.length > 1 ? commaSeparated : commaSeparated[0];
    }
    parsedObject[key] = parsedValue;
  }
  return parsedObject;
};

export const objectToQueryString = (object = {}, options = { stringifyArrays: false }) => {
  let validValues = {};
  Object.keys(object)
    .filter((key) => !Utils.isEmpty(object[key]))
    .forEach((key) => {
      if (Array.isArray(object[key]) && !!options?.stringifyArrays) {
        validValues[key] = JSON.stringify(object[key]);
      } else {
        validValues[key] = object[key];
      }
    });
  return new URLSearchParams(validValues).toString();
};
