import React from 'react';
import { PAGES } from '../../index';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Utils from '../../../utils/Utils';
import './styles/styles.scss';

const PublicProjectCard = ({ isBig, project, handleApply }) => {
  return (
    <div className='project-card'>
      {isBig ? (
        <>
          <div className='tw-text-center'>
            <h1 className='tw-border-b tw-border-t-0 tw-border-r-0 tw-border-l-0 tw-border-solid tw-border-gray-200 tw-mb-6 tw-pb-2'>
              {project?.project?.name}
            </h1>
          </div>
          <div className='tw-flex tw-justify-center'>
            <div className='tw-inline-flex tw-flex-row tw-items-center tw-gap-2 tw-bg-blue-50 tw-rounded tw-px-4 tw-py-2 tw-mb-4'>
              <span className='tw-text-xl'>{project?.project?.sourceLanguage}</span>
              <em className='fas fa-minus' style={{ fontSize: '20px' }} />
              <span className='tw-text-xl'>{project?.project?.targetLanguage}</span>
            </div>
          </div>
          <div className='tw-mt-10'>
            <span className='tw-text-xl'>{project?.project?.description}</span>
          </div>
          <div className='tw-flex tw-justify-center tw-mt-10'>
            {project?.isMember || project?.isApplicant ? (
              <Link
                className='btn-primary tw-w-1/3 tw-text-lg tw-px-4 tw-py-2 tw-rounded-[3px] tw-text-center tw-no-underline tw-transition tw-duration-20'
                to={PAGES.PUBLIC_PROJECT_LOBBY_PAGE.path(project?.project?.id)}
              >
                Open
              </Link>
            ) : (
              <Button className='tw-w-1/3 tw-py-2 tw-text-lg' onClick={() => handleApply(project?.project?.id)}>Apply</Button>
            )}
          </div>
        </>
      ) : (
        <>
        <Link
          to={PAGES.PUBLIC_PROJECT_PAGE.path(project?.project?.id)}
          className='tw-text-gray-600 tw-no-underline tw-cursor-pointer'
        >
          <div className='card-heading'>
              {project?.project?.name}
          </div>
          <div className='card-languages'>
            <span>{project?.project?.sourceLanguage}</span>
            <em className='fas fa-minus' style={{ fontSize: '12px' }} />
            <span>{project?.project?.targetLanguage}</span>
          </div>
          <div className='card-description'>
            {Utils.trimStringShort(project?.project?.description)}
          </div>
          <div className='card-button'>
            {project?.isMember || project?.isApplicant ? (
              <Link
                className='btn-primary'
                to={PAGES.PUBLIC_PROJECT_LOBBY_PAGE.path(project?.project?.id)}
              >
                Open
              </Link>
            ) : (
              <Button onClick={() => handleApply(project?.project?.id)}>
                Apply
              </Button>
            )}
          </div>
        </Link>
        </>
      )}
    </div>
  );
};

export default PublicProjectCard;
