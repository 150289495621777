import React from 'react';
import ListGroupItemWithBadge from '../components/ListGroupItemWithBadge';
import { ListGroup } from 'react-bootstrap';

import TASK from '../../../models/Task';
import PROJECT from '../../../models/Project';

const ProjectWorkflowSettingsView = ({
  scope,
  reviewMethod = null,
  segmentChunkPolicy = null,
  segmentChunkSource = null,
  segmentChunkMin = null,
  segmentChunkMax = null,
  segmentChunkAutoReChunk = false,
  primaryStageTaskTypeSid = null,
  primaryStageTaskResultsPolicy = null,
  primaryStageTaskTimeout = null,
  primaryStageTaskWorkerInstructions = null,
  primaryStageTaskCreditsPolicy = null,
  primaryStageTaskCredits = null,
  primaryStageTaskPreSubmitEvaluation = null,
  primaryStageTaskSubmitEvaluation = null,
  primaryStageTaskEvaluationPassThreshold = null,
  sampleRate = null,
  reviewStageTaskTypeSid = null,
  reviewStageTaskTimeout = null,
  reviewStageTaskWorkerInstructions = null,
  reviewStageTaskCreditsPolicy = null,
  reviewStageTaskCredits = null,
  reviewStageTaskSubmitEvaluation = null,
}) => {
  const chunkPolicyName =
    PROJECT.SETTINGS.SEGMENT_CHUNK.POLICY?.[segmentChunkPolicy]?.name ?? 'N/A';
  const chunkSourceName =
    PROJECT.SETTINGS.SEGMENT_CHUNK.SOURCE?.[segmentChunkSource]?.name ?? 'N/A';
  const reviewMethodName = PROJECT.SETTINGS.REVIEW_METHOD?.[reviewMethod]?.name ?? 'N/A';

  const primaryStageTaskTypeName = TASK.TYPE?.[primaryStageTaskTypeSid]?.name ?? 'N/A';
  const primaryStageTaskTimeOutMinutes =
    primaryStageTaskTimeout >= 0 ? primaryStageTaskTimeout / 60 : 'N/A';
  const primaryStageTaskCreditsPolicyName =
    PROJECT.SETTINGS.TASKS.CREDITS.POLICY?.[primaryStageTaskCreditsPolicy]?.name ?? 'N/A';
  const primaryStageTaskResultsPolicyName =
    PROJECT.SETTINGS.TASKS.RESULTS.POLICY?.[primaryStageTaskResultsPolicy]?.name ?? 'N/A';

  const reviewStageTaskTypeName = TASK.TYPE?.[reviewStageTaskTypeSid]?.name ?? 'N/A';
  const reviewStageTaskTimeOutMinutes =
    reviewStageTaskTimeout >= 0 ? reviewStageTaskTimeout / 60 : 'N/A';
  const reviewStageTaskCreditsPolicyName =
    PROJECT.SETTINGS.TASKS.CREDITS.POLICY?.[reviewStageTaskCreditsPolicy]?.name ?? 'N/A';

  const reviewStageEnabled = PROJECT.SETTINGS.REVIEW_METHOD.REVIEW_TASK.sid === reviewMethod;
  const showSegmentChunk = scope === PROJECT.SCOPE.CROWDSOURCING.sid;

  return (
    <>
      {/* Segment Chunk */}
      <div className='row pb-4'>
        <div className='col-12 pt-3'>
          {showSegmentChunk && (
            <>
              <h4 className='text-center text-muted'>Segment Chunk</h4>
              <ul className='list-group'>
                <ListGroupItemWithBadge
                  key='ChunkPolicy'
                  message={'Chunk Policy'}
                  value={chunkPolicyName}
                />
                <ListGroupItemWithBadge
                  key='ChunkSource'
                  message={'Chunk Source'}
                  value={chunkSourceName}
                />
                <ListGroupItemWithBadge
                  key='chunkMin'
                  message={'Chunk Down Limit'}
                  value={'' + (segmentChunkMin ?? 'N/A')}
                />
                <ListGroupItemWithBadge
                  key='ChunkMax'
                  message={'Chunk Up Limit'}
                  value={'' + (segmentChunkMax ?? 'N/A')}
                />
                <ListGroupItemWithBadge
                  key='AutoReChunk'
                  message={'Auto Re-Chunking'}
                  value={'' + (!!segmentChunkAutoReChunk ? 'Enabled' : 'Disabled')}
                />
              </ul>
            </>
          )}
        </div>
      </div>
      {/* Primary Task */}
      <h4 className='text-center text-muted'>Primary Stage</h4>
      <div className='row pb-4'>
        <div className='col-sm-12 col-lg-4 pt-3'>
          <h4 className='text-center text-muted'>Task</h4>
          <ul className='list-group'>
            <ListGroupItemWithBadge
              key='type'
              message={'Task Type'}
              value={primaryStageTaskTypeName}
            />
            {sampleRate && (
              <ListGroupItemWithBadge
                key='sampleRate'
                message='Frequency'
                value={`${sampleRate / 1000}kHz`}
              />
            )}
            <ListGroupItemWithBadge
              key='primaryStageTaskTimeout'
              message={'Timeout (minutes)'}
              value={'' + `${primaryStageTaskTimeOutMinutes}`}
            />
            <ListGroupItemWithBadge
              key='primaryStageTaskCreditsPolicy'
              message={'Pricing Policy'}
              value={primaryStageTaskCreditsPolicyName}
            />
            <ListGroupItemWithBadge
              key='primaryStageTaskCredits'
              message={'Credits'}
              value={'' + (primaryStageTaskCredits ?? 'N/A')}
            />
            <ListGroupItemWithBadge
              key='resultsPolicy'
              message={'Results Policy'}
              value={primaryStageTaskResultsPolicyName}
            />
            <ListGroupItemWithBadge
              key='reviewMethod'
              message={'Review Method'}
              value={reviewMethodName}
            />
          </ul>
        </div>
        <div className='col-sm-12 col-lg-4 pt-3'>
          <h4 className='text-center text-muted'>Worker Instructions</h4>
          <div className='border'>
            <p
              className='p-3 my-0'
              id='worker-instructions'
              dangerouslySetInnerHTML={{ __html: primaryStageTaskWorkerInstructions ?? 'N/A' }}
            />
          </div>
        </div>
        <div className='col-sm-12 col-lg-4 pt-3'>
          <h4 className='text-center text-muted'>Quality Evaluation</h4>
          <ListGroup className='list-group'>
            <ListGroupItemWithBadge
              key='liveLanguageQE'
              message={'Live Language QE (Pre Submit)'}
              className='list-group-item-danger text-bold'
              value={!!primaryStageTaskPreSubmitEvaluation?.languageQE ? 'Enabled' : 'Disabled'}
            />
            {!!primaryStageTaskPreSubmitEvaluation?.languageQE && (
              <ListGroupItemWithBadge
                key='liveLanguageQEMinPassScore'
                message={'Minimum Pass Score'}
                value={
                  '' +
                  (primaryStageTaskPreSubmitEvaluation?.languageQE?.minPassScore >= 0
                    ? primaryStageTaskPreSubmitEvaluation.languageQE.minPassScore
                    : 'N/A')
                }
              />
            )}
            <ListGroupItemWithBadge
              key='timeQE'
              message={'Submission Time (Post Submit)'}
              className='list-group-item-warning text-bold'
              value={!!primaryStageTaskSubmitEvaluation?.timeQE ? 'Enabled' : 'Disabled'}
            />
            {!!primaryStageTaskSubmitEvaluation?.timeQE && (
              <ListGroupItemWithBadge
                key='timeQEDownLimit'
                message={'Down Limit (minutes)'}
                value={
                  '' + ((primaryStageTaskSubmitEvaluation?.timeQE?.downLimit ?? null) / 60 ?? 'N/A')
                }
              />
            )}
            <ListGroupItemWithBadge
              key='googleQE'
              message={'Machine Translation (Post Submit)'}
              className={'list-group-item-warning text-bold'}
              value={!!primaryStageTaskSubmitEvaluation?.googleQE ? 'Enabled' : 'Disabled'}
            />
            {!!primaryStageTaskSubmitEvaluation?.googleQE && (
              <>
                <ListGroupItemWithBadge
                  key='googleQEDownLimit'
                  message={'Score Down Limit'}
                  value={'' + (primaryStageTaskSubmitEvaluation?.googleQE?.downLimit ?? 'N/A')}
                />
                <ListGroupItemWithBadge
                  key='googleQEUpLimit'
                  message={'Score Up Limit'}
                  value={'' + (primaryStageTaskSubmitEvaluation?.googleQE?.upLimit ?? 'N/A')}
                />
                <ListGroupItemWithBadge
                  key='googleQESegRation'
                  message={'Minimum Pass Segment Ratio'}
                  value={'' + (primaryStageTaskSubmitEvaluation?.googleQE?.segmentRatio ?? 'N/A')}
                />
              </>
            )}
            <ListGroupItemWithBadge
              key='languageQE'
              message={'Language QE (Post Submit)'}
              className={'list-group-item-warning text-bold'}
              value={!!primaryStageTaskSubmitEvaluation?.languageQE ? 'Enabled' : 'Disabled'}
            />
            {!!primaryStageTaskSubmitEvaluation?.languageQE && (
              <>
                <ListGroupItemWithBadge
                  key='minOverallScore'
                  message={'Minimum Pass Score'}
                  value={primaryStageTaskSubmitEvaluation?.languageQE?.minOverallScore ?? 'N/A'}
                />
                <ListGroupItemWithBadge
                  key='segmentRatio'
                  message={'Minimum Pass Segment Ratio'}
                  value={primaryStageTaskSubmitEvaluation?.languageQE?.segmentRatio ?? 'N/A'}
                />
              </>
            )}
            <ListGroupItemWithBadge
              key='FinalQE'
              message={'Final Evaluation (Post Review)'}
              className={'list-group-item-success text-bold'}
            />
            <ListGroupItemWithBadge
              key='primaryStageTaskEvaluationPassThreshold'
              message={'Minimum Pass Score (%)'}
              value={'' + (primaryStageTaskEvaluationPassThreshold ?? 'N/A')}
            />
          </ListGroup>
        </div>
      </div>
      {/* Review Task */}
      {!reviewStageEnabled ? null : (
        <>
          <h4 className='text-center text-muted'>Review Stage</h4>
          <div className='row pb-4'>
            <div className='col-sm-12 col-lg-4 pt-3'>
              <h4 className='text-center text-muted'>Task</h4>
              <ul className='list-group'>
                <ListGroupItemWithBadge
                  key='type'
                  message={'Task Type'}
                  value={reviewStageTaskTypeName}
                />
                <ListGroupItemWithBadge
                  key='reviewStageTaskTimeout'
                  message={'Timeout (minutes)'}
                  value={'' + `${reviewStageTaskTimeOutMinutes}`}
                />
                <ListGroupItemWithBadge
                  key='reviewStageTaskCreditsPolicy'
                  message={'Pricing Policy'}
                  value={reviewStageTaskCreditsPolicyName}
                />
                <ListGroupItemWithBadge
                  key='reviewStageTaskCredits'
                  message={'Credits'}
                  value={'' + (reviewStageTaskCredits ?? 'N/A')}
                />
              </ul>
            </div>
            <div className='col-sm-12 col-lg-4 pt-3'>
              <h4 className='text-center text-muted'>Worker Instructions</h4>
              <div className='border'>
                <p
                  className='p-3 my-0'
                  id='worker-instructions'
                  dangerouslySetInnerHTML={{ __html: reviewStageTaskWorkerInstructions ?? 'N/A' }}
                />
              </div>
            </div>
            <div className='col-sm-12 col-lg-4 pt-3'>
              <h4 className='text-center text-muted'>Quality Evaluation</h4>
              <ListGroup className='list-group'>
                <ListGroupItemWithBadge
                  key='timeQE'
                  message={'Submission Time (Post Submit)'}
                  className='list-group-item-warning text-bold'
                  value={!!reviewStageTaskSubmitEvaluation?.timeQE ? 'Enabled' : 'Disabled'}
                />
                {!!reviewStageTaskSubmitEvaluation?.timeQE && (
                  <ListGroupItemWithBadge
                    key='timeQEDownLimit'
                    message={'Down Limit (minutes)'}
                    value={
                      '' +
                      ((reviewStageTaskSubmitEvaluation?.timeQE?.downLimit ?? null) / 60 ?? 'N/A')
                    }
                  />
                )}
              </ListGroup>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProjectWorkflowSettingsView;
