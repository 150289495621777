// External libs
import React from 'react';

// QD libs
import Utils from '../../utils/Utils';
import SelectParameter, { reactSelectDefaultProps, Select } from './SelectParameter';

export const reactMultiSelectDefaultProps = () => ({
  ...reactSelectDefaultProps(),
  isMulti: true,
  closeMenuOnSelect: false,
  isClearable: true,
  hideSelectedOptions: true,
});

// -----------------------------------------------------------------------
// ----------------------------- Functional Component --------------------
// -----------------------------------------------------------------------
export const MultiSelect = (props) => {
  // default props
  let selectProps = {
    ...props,
    typeOptions: { ...reactMultiSelectDefaultProps(), ...props?.typeOptions },
  };

  return <Select {...selectProps} />;
};

MultiSelect.propTypes = { ...Select.propTypes };

// -----------------------------------------------------------------------
// ----------------------------- PureComponent ---------------------------
// -----------------------------------------------------------------------
export default class MultiSelectParameter extends SelectParameter {
  getDefaultProperties = () => ({ ...MultiSelectParameter.defaultProps });

  static defaultProps = {
    ...SelectParameter.defaultProps,
    // properties
    typeOptions: reactMultiSelectDefaultProps(),
  };

  validateValues(values = null) {
    values = Utils.defineToArray(values, []);
    return this.props?.options?.map((obj) => obj.value).filter((obj) => values.includes(obj));
  }
}

MultiSelectParameter.propTypes = { ...SelectParameter.propTypes };
