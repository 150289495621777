import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '../../../../components/Tooltip'
import { PAGES } from '../../../index'


export const UserReferralCodesView = ({ referralCodes = null }) => {
  const [tooltipText, setTooltipText] = useState('Copy to clipboard')
  const resetTooltipText = () => setTooltipText('Copy to clipboard')

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(`https://app-hlp.taus.net/register?ref=${ code }`)
    setTooltipText('Copied!')
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric", hour: 'numeric', minute: 'numeric'}
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  return (
    <div className="tw-p-5">
      <span
        style={ {
          display: 'block',
          fontSize: '18px',
          lineHeight: '28px',
          letterSpacing: '0.3px',
          fontWeight: '500',
          color: '#1c1c28',
          marginBottom: '12px',
        } }
      >
        Campaigns
      </span>
      <table className="table">
        <thead>
        <tr>
          <th className="px-2 py-1">Referral Codes</th>
          <th className="px-2 py-1">Type</th>
          <th className="px-2 py-1">Project name</th>
          <th className="px-2 py-1">Expiring on</th>
          <th className="px-2 py-1">View referees</th>
        </tr>
        </thead>
        <tbody>
        { referralCodes.map((code, i) => {
          return (
            <tr>
              <td class="px-2 py-1">
                { code.enabled ?
                  <Tooltip key={ i } title={ tooltipText }>
                    <span
                      className="tw-px-1 tw-py-1 tw-bg-blue-400 tw-text-white tw-rounded-full tw-text-base tw-cursor-pointer"
                      onClick={ (i) => copyToClipboard(code.code) }
                      onMouseOut={ resetTooltipText }
                    >
                      { code.code }
                      <FontAwesomeIcon className="ml-2" icon={ faCopy }/>
                    </span>
                  </Tooltip>
                  :
                  <span style={{textDecoration: 'line-through'}}>
                    { code.code }
                  </span>
                }
              </td>
              <td class="px-2 py-1">
                { code.type }
              </td>
              <td class="px-2 py-1">
                { code.projectName  ? code.projectName : '-'}
              </td>
              <td className="px-2 py-1">
                { code.projectCampaignEndDate ? formatDate(code.projectCampaignEndDate) : '-'}
              </td>
              <td class="px-2 py-1">
                <a href={ PAGES.USER_REFEREES_PAGE.path(code.id) }>
                  View
                </a>
              </td>
            </tr>
          )
        }) }
        </tbody>
      </table>
    </div>
  )
}
UserReferralCodesView.propTypes = {
  referralCodes: PropTypes.array,
}
export default UserReferralCodesView
