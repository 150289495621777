// import { ZawgyiDetector } from 'myanmar-tools';

import Utils from '../utils/Utils';
import ErrorHandler from '../utils/ErrorHandler';
import { CHARACTER_RECOGNITION_REGEX } from './Language';

export default class Segment {
  static REVIEWS = {
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    UNREVIEWED: 'UNREVIEWED',
  };

  static PERMISSIONS = {
    READ: 'READ',
    EDIT: 'EDIT',
  };

  static getUserPermission(segment = null) {
    return Utils.defineArray(segment?.permissions, []);
  }

  static statusAsBoolean(status = null) {
    if (Utils.isEmpty(status)) return null;
    else status = status.toUpperCase();
    return status === Segment.REVIEWS.APPROVED
      ? true
      : status === Segment.REVIEWS.REJECTED
      ? false
      : null;
  }

  static statusFromBoolean(status = null) {
    return status === true
      ? Segment.REVIEWS.APPROVED
      : status === false
      ? Segment.REVIEWS.REJECTED
      : null;
  }

  /**
   * Returns the Segment status as number given its status as string
   * @param {String} name
   */
  static statusNameToCode(name) {
    try {
      name = Utils.define(name, Segment.REVIEWS.UNREVIEWED).toUpperCase();
      if (name === Segment.REVIEWS.APPROVED) return 100;
      if (name === Segment.REVIEWS.REJECTED) return -100;
      if (name === Segment.REVIEWS.UNREVIEWED) return 0;
      return 0;
    } catch (e) {
      ErrorHandler.errorHandle(e);
      return 0;
    }
  }

  /** Returns the Segment status as string, given its status as number */
  static statusCodeToName(code) {
    try {
      if (code === 0) return Segment.REVIEWS.UNREVIEWED;
      if (code > 0) return Segment.REVIEWS.APPROVED;
      if (code < 0) return Segment.REVIEWS.REJECTED;
      return 'INVALID';
    } catch (e) {
      ErrorHandler.errorHandle(e);
      return 'INVALID';
    }
  }

  /**
   * Coverts the segment's review status from String to Number
   * @param {String} name
   */
  static contentReviewNameToCode(name) {
    try {
      name = Utils.define(name, Segment.REVIEWS.UNREVIEWED).toUpperCase();
      if (name === Segment.REVIEWS.APPROVED) return 100;
      if (name === Segment.REVIEWS.REJECTED) return -100;
      if (name === Segment.REVIEWS.UNREVIEWED) return 0;
      return 0;
    } catch (e) {
      ErrorHandler.errorHandle(e);
      return 0;
    }
  }

  /**
   * Converts the Segment review status from Number to String
   */
  static contentReviewCodeToName(code) {
    try {
      if (code === 0) return Segment.REVIEWS.UNREVIEWED;
      if (code > 0) return Segment.REVIEWS.APPROVED;
      if (code < 0) return Segment.REVIEWS.REJECTED;
      return 'INVALID';
    } catch (e) {
      ErrorHandler.errorHandle(e);
      return 'INVALID';
    }
  }

  /**
   * Returns the segment's content review status as String.
   * @param {Segment} segment
   * @returns String
   */
  static getContentReview(segment) {
    return Utils.define(segment?.content?.review?.status, Segment.REVIEWS.UNREVIEWED);
  }

  // ---------------------------------------------------------------------------------
  // ---------------------------------- GETTERS --------------------------------------
  // ---------------------------------------------------------------------------------
  /** Returns content data */
  static getContentData(segment) {
    return segment?.content?.data ?? null;
  }

  static getContentTokens(segment) {
    return segment?.content?.tokens ?? [];
  }

  static getContentTaskId(segment) {
    return segment?.content?.task?.id ?? null;
  }

  /** Returns true if the segment is reviewed */
  static isReviewed(segment) {
    return [Segment.REVIEWS.APPROVED, Segment.REVIEWS.REJECTED].includes(segment?.status);
  }

  static getLanguage(segment) {
    return Utils.define(segment?.language);
  }

  // -------------------------------------------------------------------------------------
  // ---------------------------------------- Data Validation ----------------------------
  // -------------------------------------------------------------------------------------
  /**
   * Returns true if the data string is not empty string
   * @param data
   * @returns {boolean}
   */
  static validateIsEmpty(data = '') {
    return !Utils.isEmpty(data);
  }
  /**
   * Returns true if the data string does not contain new line characters
   * @param data
   * @returns {boolean}
   */
  static validateNoNewLines(data = '') {
    if (Utils.isEmpty(data)) return true;
    let check = data.match(/\n/g);
    return Utils.isNull(check);
  }

  /**
   * Returns true if the ratio of the characters encoded according to the language CHARACTER_RECOGNITION_REGEX
   * is larger than the matchRate value passed.
   * @param data String to validate
   * @param languageCode language code to parse encoding regex from CHARACTER_RECOGNITION_REGEX
   * @param matchRate the ratio of encoded_chars / total_chars
   * @returns {boolean}
   */
  static validateEncoded(data = '', languageCode = '', matchRate = null) {
    if (Utils.isEmpty(data) || Utils.isEmpty(languageCode) || Utils.isNull(matchRate)) return true;
    // Get regex for specific language code.
    let regExp = CHARACTER_RECOGNITION_REGEX[languageCode];
    if (Utils.isNull(regExp)) return true;

    let validChars = data.match(regExp);
    if (Utils.isNull(validChars)) return false;

    let allChars = data.replace(/ /g, '');
    let ratio = validChars.length / allChars.length;

    return ratio > matchRate;
  }

  /**
   * Returns true if the ratio of the characters encoded according to the Zawgyi Probability is larger than 0.3.
   * @param data String to validate
   * @returns {boolean}
   */
  // static validateBurmeseZawgyiUnicode(data="", zawgyiMatchRate=null){
  //
  //     if(Utils.isNull(zawgyiMatchRate)) return false;
  //
  //     //const google_myanmar_tools = require("myanmar-tools");
  //     //const detector = new google_myanmar_tools.ZawgyiDetector();
  //     //const score = detector.getZawgyiProbability(data);
  //
  //     let score = ZawgyiDetector.getZawgyiProbability(data);
  //
  //     if(!isFinite(score) || score > zawgyiMatchRate){
  //         return true;
  //     }
  //
  //     return false;
  // }
}
