// Core
import React from 'react';
import UserProfile from '../../../../../models/UserProfile';
// Dependencies
import PropTypes from 'prop-types';
import './PaymentDetailsDisplayView.scss';

function PaymentDetailsDisplayView(props) {
  const {
    isPaymentDetailsEditable,
    paymentInfo,
    selectedCountryRequiredFields,
    country,
    onToggleVisibility,
    bank,
    branch,
    stripeEmail,
    errorDisplay
  } = props || {};

  return (
    <div className='payment-details-view'>
      {paymentInfo?.method ? (
        <>
          <div style={{ marginBottom: '16px' }}>
            <span
              style={{
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#77777e',
                marginBottom: '4px',
              }}
            >
              Preferred Payment Method
            </span>

            <div>
              {paymentInfo?.method === 'PayPal' ? (
                <span className='paypal-icon'></span>
              ) : paymentInfo?.method === 'Stripe' ? (
                <span className='stripe-icon'></span>
              ) : (
                <span className='wise-icon'></span>
              )}
            </div>

            {/* <span
                            className={`ml-2 ${
                                paymentInfo?.method === 'Stripe' && !stripeEmail
                                    ? 'badge badge-pill badge-danger'
                                    : null
                            }`}>
                            {paymentInfo?.method === 'Stripe' && stripeEmail ? 
                                    stripeEmail : paymentInfo?.method === 'Stripe' && !stripeEmail ? 
                                        'Not connected' : null
                            }
                        </span> */}
          </div>
          {/* {paymentInfo?.method === 'PayPal' ? (
                        <div style={{marginBottom: "16px"}}>
                            <span style={{display: "block", fontSize: "18px", lineHeight: "28px", letterSpacing: "0.3px", color: "#77777e", marginBottom: "4px"}}>Paypal email</span>
                            <span style={{display: "block", fontSize: "18px", lineHeight: "28px", letterSpacing: "0.3px", color: "#1c1c28"}}>{paymentInfo?.paypalEmail}</span>
                        </div>
                    ) : null } */}
          <div style={{ marginBottom: '16px' }}>
            <span
              style={{
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#77777e',
                marginBottom: '4px',
              }}
            >
              Legal Entity
            </span>
            <span
              style={{
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#1c1c28',
              }}
            >
              {paymentInfo?.legalType}
            </span>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <span
              style={{
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#77777e',
                marginBottom: '4px',
              }}
            >
              Full beneficiary's address
            </span>
            <span
              style={{
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#1c1c28',
              }}
            >
              {paymentInfo?.addressLine}, {paymentInfo?.city}, {paymentInfo?.zipCode}, {country}
            </span>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <span
              style={{
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#77777e',
                marginBottom: '4px',
              }}
            >
              Beneficiary Name
            </span>
            <span
              style={{
                display: 'block',
                fontSize: '18px',
                lineHeight: '28px',
                letterSpacing: '0.3px',
                color: '#1c1c28',
              }}
            >
              {paymentInfo?.name}
            </span>
          </div>

          {paymentInfo?.vatNumber && paymentInfo?.legalType === 'Business' && (
            <div className='box-list'>
              <div>
                <h5 className='box-section-title'>VAT Number</h5>
                <span className='box-section-subtitle d-block'>{paymentInfo?.vatNumber}</span>
              </div>
            </div>
          )}

          {paymentInfo?.cocNumber &&
            paymentInfo?.legalType === 'Business' &&
            paymentInfo?.vatStatus === 'NL' && (
              <div style={{ marginBottom: '16px' }}>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#77777e',
                    marginBottom: '4px',
                  }}
                >
                  Chamber of Commerce number
                </span>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#1c1c28',
                  }}
                >
                  {paymentInfo?.cocNumber}
                </span>
              </div>
            )}

          {paymentInfo?.method === 'TransferWise' &&
            selectedCountryRequiredFields.includes('region') && (
              <div style={{ marginBottom: '16px' }}>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#77777e',
                    marginBottom: '4px',
                  }}
                >
                  IBAN
                </span>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#1c1c28',
                  }}
                >
                  {paymentInfo?.iban}
                </span>
              </div>
            )}
          {paymentInfo?.method === 'TransferWise' &&
            selectedCountryRequiredFields.includes('region') && (
              <div style={{ marginBottom: '16px' }}>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#77777e',
                    marginBottom: '4px',
                  }}
                >
                  Region
                </span>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#1c1c28',
                  }}
                >
                  {paymentInfo?.iban}
                </span>
              </div>
            )}
          {paymentInfo?.method === 'TransferWise' &&
            selectedCountryRequiredFields.includes('ifscCode') && (
              <div style={{ marginBottom: '16px' }}>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#77777e',
                    marginBottom: '4px',
                  }}
                >
                  IFSC Code
                </span>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#1c1c28',
                  }}
                >
                  {paymentInfo?.ifscCode}
                </span>
              </div>
            )}

          {paymentInfo?.method === 'TransferWise' &&
            bank &&
            selectedCountryRequiredFields.includes('bankCode') && (
              <div style={{ marginBottom: '16px' }}>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#77777e',
                    marginBottom: '4px',
                  }}
                >
                  Bank
                </span>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#1c1c28',
                  }}
                >
                  {bank}
                </span>
              </div>
            )}

          {paymentInfo?.method === 'TransferWise' &&
            (selectedCountryRequiredFields.includes('branchCode') ||
              paymentInfo?.countryCode === 'HK') && (
              <div style={{ marginBottom: '16px' }}>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#77777e',
                    marginBottom: '4px',
                  }}
                >
                  Branch
                </span>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#1c1c28',
                  }}
                >
                  {branch}
                </span>
              </div>
            )}

          {/* {paymentInfo?.method === 'TransferWise' && (paymentInfo?.countryCode === 'CN' ? paymentInfo?.transferwiseType === 'chinese_card' : selectedCountryRequiredFields.includes('cardNumber')) && (
                            <div style={{marginBottom: "16px"}}>
                                <span style={{display: "block", fontSize: "18px", lineHeight: "28px", letterSpacing: "0.3px", color: "#77777e", marginBottom: "4px"}}>Card Number</span>
                                <span style={{display: "block", fontSize: "18px", lineHeight: "28px", letterSpacing: "0.3px", color: "#1c1c28"}}>
                                    {paymentInfo?.cardNumber}
                                </span>
                            </div>
                        )} */}

          {paymentInfo?.method === 'TransferWise' &&
            paymentInfo?.transferwiseType === 'japanese' &&
            selectedCountryRequiredFields.includes('accountType') && (
              <div style={{ marginBottom: '16px' }}>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#77777e',
                    marginBottom: '4px',
                  }}
                >
                  Account Type
                </span>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#1c1c28',
                  }}
                >
                  {paymentInfo?.accountType}
                </span>
              </div>
            )}

          {paymentInfo?.method === 'TransferWise' &&
            (paymentInfo?.countryCode === 'CN'
              ? paymentInfo?.transferwiseType === 'chinese_alipay'
              : selectedCountryRequiredFields.includes('accountNumber')) && (
              <div style={{ marginBottom: '16px' }}>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#77777e',
                    marginBottom: '4px',
                  }}
                >
                  Account Number
                </span>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#1c1c28',
                  }}
                >
                  {paymentInfo?.accountNumber}
                </span>
              </div>
            )}

          {paymentInfo?.method === 'TransferWise' &&
            selectedCountryRequiredFields.includes('swiftCode') && (
              <div style={{ marginBottom: '16px' }}>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#77777e',
                    marginBottom: '4px',
                  }}
                >
                  SWIFT/BIC Code
                </span>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#1c1c28',
                  }}
                >
                  {paymentInfo?.swift}
                </span>
              </div>
            )}

          {(paymentInfo?.method === 'TransferWise'
            ? paymentInfo?.countryCode === 'KR'
              ? paymentInfo?.transferwiseType === 'south_korean_paygate'
              : selectedCountryRequiredFields.includes('email')
            : paymentInfo?.method === 'PayPal') && (
            <div style={{ marginBottom: '16px' }}>
              <span
                style={{
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#77777e',
                  marginBottom: '4px',
                }}
              >
                Email
              </span>
              <span
                style={{
                  display: 'block',
                  fontSize: '18px',
                  lineHeight: '28px',
                  letterSpacing: '0.3px',
                  color: '#1c1c28',
                }}
              >
                {paymentInfo?.email}
              </span>
            </div>
          )}

          {paymentInfo?.method === 'TransferWise' &&
            (paymentInfo?.countryCode === 'KR'
              ? paymentInfo?.transferwiseType === 'south_korean_paygate'
              : selectedCountryRequiredFields.includes('dateOfBirth')) && (
              <div style={{ marginBottom: '16px' }}>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#77777e',
                    marginBottom: '4px',
                  }}
                >
                  Date of birth
                </span>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    lineHeight: '28px',
                    letterSpacing: '0.3px',
                    color: '#1c1c28',
                  }}
                >
                  {UserProfile.getFormattedDate(paymentInfo?.dateOfBirth)}
                </span>
              </div>
            )}
        </>
      ) : (
        <div className='d-flex flex-column justify-content-center align-items-center pt-4 px-4 mt-4'>
          {errorDisplay && (
            <strong className='text-center tw-pb-2' style={{color : 'red'}}>
              { errorDisplay }
            </strong>
          )}
          <strong className='text-center tw-pb-2'>
            You must update your payment details in order to get paid
          </strong>
          <button
            className='btn btn-primary mt-4 mb-3 shadow'
            onClick={onToggleVisibility}
            disabled={!isPaymentDetailsEditable}
          >
            Update Payment Details
          </button>
        </div>
      )}
    </div>
  );
}

PaymentDetailsDisplayView.propTypes = {
  isPaymentDetailsEditable: PropTypes.bool.isRequired,
  paymentInfo: PropTypes.object.isRequired,
  selectedCountryRequiredFields: PropTypes.array,
  country: PropTypes.string.isRequired,
  bank: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  onToggleVisibility: PropTypes.func.isRequired,
};
export default PaymentDetailsDisplayView;
