import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import SelectWithValidation from '../../../components/forms/SelectWithValidation';
import InputWithValidation from '../../../components/forms/InputWithValidation';
import Utils from '../../../utils/Utils';
import Notifier from '../../../components/Notifier';
import TASK from '../../../models/Task';
import PROJECT from '../../../models/Project';
import CheckboxWithValidation from '../../../components/forms/CheckboxWithValidation';
import TagBuilder from '../TagBuilder';
import Tooltip from '../../../components/Tooltip';

const OPTIONS = {
  primaryStageTaskType: Object.values(TASK.TYPE)
    .filter((obj) => !obj.sid.includes('REVIEW'))
    .map((obj) => ({ value: obj.sid, label: obj.name })),
  reviewStageTaskType: (template) => {
    return Object.values(TASK.TYPE)
      .filter(
        (type) => template === getTaskTypeTemplate(type.sid) && type.policy.includes('REVIEW')
      )
      .map((obj) => ({ value: obj.sid, label: obj.name }));
  },
  taskResultsPolicy: Object.values(PROJECT.SETTINGS.TASKS.RESULTS.POLICY).map((obj) => ({
    value: obj.sid,
    label: obj.name,
  })),
  creditsPolicy: Object.values(PROJECT.SETTINGS.TASKS.CREDITS.POLICY).map((obj) => ({
    value: obj.sid,
    label: obj.name,
  })),
  reviewMethod: Object.values(PROJECT.SETTINGS.REVIEW_METHOD).map((obj) => ({
    value: obj.sid,
    label: obj.name,
  })),
  chunkPolicy: Object.values(PROJECT.SETTINGS.SEGMENT_CHUNK.POLICY).map((obj) => ({
    value: obj.sid,
    label: obj.name,
  })),
  chunkSource: Object.values(PROJECT.SETTINGS.SEGMENT_CHUNK.SOURCE).map((obj) => ({
    value: obj.sid,
    label: obj.name,
  })),
  sampleRate: [
    { value: 16000, label: '16kHz' },
    { value: 44100, label: '44.1kHz' },
  ],
};

const taskTemplateSupportingCreateReview = [
  TASK.TEMPLATE.LINGUISTIC.name,
  TASK.TEMPLATE.SPEECH_RECORDING.name,
  TASK.TEMPLATE.TEXT_ANNOTATION.name,
];

const getTaskTypeTemplate = (typeSid = null) =>
  Object.values(TASK.TYPE)
    ?.filter((type) => type.sid === typeSid)
    ?.pop()?.template.name ?? null;
const formikOptions = {
  validationSchema: Yup.object().shape({
    reviewMethod: Yup.string()
      .ensure()
      .required('Project review method is required!')
      .when(['primaryStageTaskType'], {
        is: (obj) => taskTemplateSupportingCreateReview.includes(getTaskTypeTemplate(obj)),
        otherwise: Yup.string().matches(
          '(APPROVE_ALL)',
          'This option is not supported for ' +
            Object.keys(TASK.TEMPLATE)
              .filter((value) => !taskTemplateSupportingCreateReview.includes(value))
              .map((v) => ' ' + TASK.TYPE[v].name) +
            ' task types.'
        ),
      }),
    // ------- Chunking Settings
    chunkMin: Yup.number()
      .typeError('Segment chunk min must be a number')
      .required('Segment chunk min is required! Please enter a positive integer!')
      .positive('Only positive values')
      .integer('Only integer values')
      .max(Yup.ref('chunkMax'), 'Value must be be less or equal to segment chunk max'),
    chunkMax: Yup.number()
      .typeError('Segment chunk max must be a number')
      .required('Segment chunk max is required! Please enter a positive integer!')
      .positive('Only positive values')
      .integer('Only integer values')
      .min(Yup.ref('chunkMin'), 'Value must be more or equal to segment chunk min'),
    chunkPolicy: Yup.string()
      .ensure()
      .required('Segment chunk policy is required!')
      .when(['primaryStageTaskType'], {
        is: (obj) => getTaskTypeTemplate(obj) === TASK.TEMPLATE.LINGUISTIC.name,
        otherwise: Yup.string().matches('PER_SEGMENT', 'Only linguistic tasks support this option'),
      }),
    chunkSource: Yup.string()
      .required('Segment chunk source is required')
      .when(['primaryStageTaskType'], {
        is: (obj) => getTaskTypeTemplate(obj) === TASK.TEMPLATE.LINGUISTIC.name,
        otherwise: Yup.string().matches(
          '(UNASSIGNED|OFF)',
          'Only Translation tasks support this option'
        ),
      }),
    chunkAutoReChunk: Yup.bool()
      .required('Segment chunk auto rechunk is required')
      .when(['primaryStageTaskResultsPolicy'], {
        is: (obj) => obj === PROJECT.SETTINGS.TASKS.RESULTS.POLICY.ONE_TO_ONE.sid,
        otherwise: Yup.bool().oneOf(
          [false],
          'Only "One to One" task results policy allows re-chunking'
        ),
      }),
    // ------------ Primary Stage
    primaryStageTaskType: Yup.string().nullable().required('Task type is required'),
    primaryStageTaskResultsPolicy: Yup.string()
      .oneOf(Object.values(PROJECT.SETTINGS.TASKS.RESULTS.POLICY).map((p) => p.sid))
      .required('Task results policy is required'),
    primaryStageTaskInstructions: Yup.string().ensure().nullable().default(null),
    primaryStageTaskTimeout: Yup.number()
      .required('Task timeout is required! Please enter a positive integer!')
      .integer('Only integer values')
      .min(0, 'Only positive or 0 values')
      .default(0),
    primaryStageTaskCreditsPolicy: Yup.string()
      .ensure()
      .required('Credits policy is required!')
      .when(['primaryStageTaskType'], {
        is: (obj) => getTaskTypeTemplate(obj) === TASK.TEMPLATE.LINGUISTIC.name,
        otherwise: Yup.string().matches(
          '(PER_SEGMENT|PER_TASK)',
          'Only Translations can be Per Words'
        ),
      }),
    primaryStageTaskCredits: Yup.number()
      .required('Credits are required! Please enter a positive integer!')
      .positive('Only positive values')
      .default(0)
      .min(0, 'Please specify a number greater or equal to 0')
      .test('is-decimal', 'Five decimal places allowed', (value) =>
        (value + '').match(/^(\d+)?(\.\d{1,5})?$/)
      ),
    primaryStageTaskEvaluationPassThreshold: Yup.number()
      .required(
        'Review Evaluation Pass Score (%) is required! Please enter a number between 0 and 100'
      )
      .integer('Only integer values')
      .default(0)
      .min(0, 'Only between 0 and 100')
      .max(100, 'Only between 0 and 100'),
    primaryStageTaskLiveLanguageQEChecked: Yup.bool(),
    primaryStageTaskLiveLanguageQEMinPassScore: Yup.number().when(
      ['primaryStageTaskLiveLanguageQEChecked'],
      {
        is: true,
        then: Yup.number()
          .required('Minimum pass score is required! Please enter a number between 0 and 100')
          .integer('Only integer values')
          .min(0, 'Please specify a decimal number between 0 and 100')
          .max(100, 'Please specify a decimal number between 0 and 100'),
      }
    ),
    primaryStageTaskTimeQEChecked: Yup.bool(),
    primaryStageTaskTimeQEDownLimit: Yup.number().when(['primaryStageTaskTimeQEChecked'], {
      is: true,
      then: Yup.number()
        .required('Submission Time down limit is required! Please enter a positive integer!')
        .positive('Only positive values')
        .integer('Only integer values'),
    }),
    primaryStageTaskGoogleQEChecked: Yup.bool(),
    primaryStageTaskGoogleQEDownLimit: Yup.number().when(['primaryStageTaskGoogleQEChecked'], {
      is: true,
      then: Yup.number()
        .required('Machine Translation down limit is required! Please enter a positive integer!')
        .positive('Only positive values')
        .integer('Only integer values'),
    }),
    primaryStageTaskGoogleQEUpLimit: Yup.number().when(['primaryStageTaskGoogleQEChecked'], {
      is: true,
      then: Yup.number()
        .required('Machine Translate up limit is required! Please enter a positive integer!')
        .positive('Only positive values')
        .integer('Only integer values'),
    }),
    primaryStageTaskGoogleQESegmentRatio: Yup.number().when(['primaryStageTaskGoogleQEChecked'], {
      is: true,
      then: Yup.number()
        .required(
          'Machine Translate segment ratio is required! Please enter a floating point ratio!'
        )
        .min(0, 'Please specify a decimal number between 0 and 1')
        .max(1, 'Please specify a decimal number between 0 and 1')
        .positive('Only positive values'),
    }),
    primaryStageTaskLanguageQEChecked: Yup.bool().when(['reviewMethod'], {
      is: PROJECT.SETTINGS.REVIEW_METHOD.TAUS_TES.sid,
      then: Yup.bool()
        .required()
        .oneOf([true], 'You need to enable Language QE with Test Evaluation Service review'),
    }),
    primaryStageTaskLanguageQEMinOverallScore: Yup.number().when(
      ['primaryStageTaskLanguageQEChecked'],
      {
        is: true,
        then: Yup.number()
          .required('Minimum score is required! Please enter a number between 0 and 100')
          .integer('Only integer values')
          .min(0, 'Please specify a decimal number between 0 and 100')
          .max(100, 'Please specify a decimal number between 0 and 100'),
      }
    ),
    primaryStageTaskLanguageQESegmentRatio: Yup.number().when(
      ['primaryStageTaskLanguageQEChecked'],
      {
        is: true,
        then: Yup.number()
          .required('Language QE segment ratio is required! Please enter a floating point ratio!')
          .min(0, 'Please specify a decimal number between 0 and 1')
          .max(1, 'Please specify a decimal number between 0 and 1'),
      }
    ),

    tagDefinitions: Yup.object()
      .nullable()
      .when(['primaryStageTaskType'], {
        is: (type) => getTaskTypeTemplate(type) === TASK.TEMPLATE.TEXT_ANNOTATION.name,
        then: Yup.object()
          .nullable()
          .required('Tag definitions are required for Text Annotation type tasks'),
      }),

    sampleRate: Yup.number()
      .nullable()
      .when(['primaryStageTaskType'], {
        is: (type) => getTaskTypeTemplate(type) === TASK.TEMPLATE.SPEECH_RECORDING.name,
        then: Yup.number()
          .nullable()
          .required('Sample rate is required for Speech Recording type tasks'),
      }),

    // ------------ Review Stage
    reviewStageEnabled: Yup.bool(),
    reviewStageTaskType: Yup.string()
      .nullable()
      .when(['reviewMethod'], {
        is: PROJECT.SETTINGS.REVIEW_METHOD.REVIEW_TASK.sid,
        then: Yup.string()
          .required('Task type is required')
          .test(
            'primary-review-mismatch',
            'Primary and review stage task template mismatch!',
            function (value) {
              return (
                getTaskTypeTemplate(value) === getTaskTypeTemplate(this.parent.primaryStageTaskType)
              );
            }
          ),
      }),
    reviewStageTaskInstructions: Yup.string().ensure().nullable().default(null),
    reviewStageTaskTimeout: Yup.number()
      .default(0)
      .when(['reviewMethod'], {
        is: PROJECT.SETTINGS.REVIEW_METHOD.REVIEW_TASK.sid,
        then: Yup.number()
          .required('Task timeout is required! Please enter a positive integer!')
          .integer('Only integer values')
          .min(0, 'Only positive or 0 values'),
      }),
    reviewStageTaskCreditsPolicy: Yup.string()
      .when(['reviewMethod'], {
        is: PROJECT.SETTINGS.REVIEW_METHOD.REVIEW_TASK.sid,
        then: Yup.string().ensure().required('Credits policy is required!'),
      })
      .when(['reviewStageTaskType'], {
        is: (obj) => getTaskTypeTemplate(obj) === TASK.TEMPLATE.LINGUISTIC.name,
        otherwise: Yup.string().matches(
          '(PER_SEGMENT|PER_TASK)',
          'Only Translations can be Per Words'
        ),
      }),
    reviewStageTaskCredits: Yup.number()
      .default(0)
      .when(['reviewMethod'], {
        is: PROJECT.SETTINGS.REVIEW_METHOD.REVIEW_TASK.sid,
        then: Yup.number()
          .required('Credits are required! Please enter a positive integer!')
          .positive('Only positive values')
          .min(0, 'Please specify a decimal number greater or equal to 0')
          .test('is-decimal', 'Five decimal places allowed', (value) =>
            (value + '').match(/^(\d+)?(\.\d{1,5})?$/)
          ),
      }),
    reviewStageTaskTimeQEChecked: Yup.bool(),
    reviewStageTaskTimeQEDownLimit: Yup.number().when(
      ['reviewStageTaskTimeQEChecked', 'reviewMethod'],
      {
        is: [true, PROJECT.SETTINGS.REVIEW_METHOD.REVIEW_TASK.sid],
        then: Yup.number()
          .required('Submission Time down limit is required! Please enter a positive integer!')
          .positive('Only positive values')
          .integer('Only integer values'),
      }
    ),
  }),

  mapPropsToValues: (props) => {
    return {
      apiErrors: [],
      reviewMethod: props?.reviewMethod ?? PROJECT.SETTINGS.REVIEW_METHOD.APPROVE_ALL.sid,
      chunkMin: props?.segmentChunkMin ?? 10,
      chunkMax: props?.segmentChunkMax ?? 10,
      chunkPolicy: props?.segmentChunkPolicy ?? PROJECT.SETTINGS.SEGMENT_CHUNK.POLICY.PER_SEGMENT,
      chunkSource: props?.segmentChunkSource ?? PROJECT.SETTINGS.SEGMENT_CHUNK.SOURCE.UNASSIGNED,
      chunkAutoReChunk: !!props?.segmentChunkAutoReChunk,
      primaryStageTaskType: props?.primaryStageTaskTypeSid ?? TASK.TYPE.TRANSLATION.sid,
      primaryStageTaskResultsPolicy:
        props?.primaryStageTaskResultsPolicy ?? PROJECT.SETTINGS.TASKS.RESULTS.POLICY.ONE_TO_ONE,
      primaryStageTaskCredits: props?.primaryStageTaskCredits ?? 0,
      primaryStageTaskCreditsPolicy:
        props?.primaryStageTaskCreditsPolicy ?? PROJECT.SETTINGS.TASKS.CREDITS.POLICY.PER_TASK.sid,
      primaryStageTaskInstructions: props?.primaryStageTaskWorkerInstructions ?? '',
      primaryStageTaskTimeout: (props?.primaryStageTaskTimeout ?? 0) / 60,
      primaryStageTaskEvaluationPassThreshold: props?.primaryStageTaskEvaluationPassThreshold ?? 0,
      primaryStageTaskLiveLanguageQEChecked:
        !!props?.primaryStageTaskPreSubmitEvaluation?.languageQE,
      primaryStageTaskLiveLanguageQEMinPassScore:
        props?.primaryStageTaskPreSubmitEvaluation?.languageQE?.minPassScore ?? 0,
      primaryStageTaskTimeQEChecked: !!props?.primaryStageTaskSubmitEvaluation?.timeQE,
      primaryStageTaskTimeQEDownLimit:
        (props?.primaryStageTaskSubmitEvaluation?.timeQE?.downLimit ?? 0) / 60,
      primaryStageTaskGoogleQEChecked: !!props?.primaryStageTaskSubmitEvaluation?.googleQE,
      primaryStageTaskGoogleQEDownLimit:
        props?.primaryStageTaskSubmitEvaluation?.googleQE?.downLimit ?? 4,
      primaryStageTaskGoogleQEUpLimit:
        props?.primaryStageTaskSubmitEvaluation?.googleQE?.upLimit ?? 65,
      primaryStageTaskGoogleQESegmentRatio:
        props?.primaryStageTaskSubmitEvaluation?.googleQE?.segmentRatio ?? 0.5,
      primaryStageTaskLanguageQEChecked: !!props?.primaryStageTaskSubmitEvaluation?.languageQE,
      primaryStageTaskLanguageQEMinOverallScore:
        props?.primaryStageTaskSubmitEvaluation?.languageQE?.minOverallScore ?? 0,
      primaryStageTaskLanguageQESegmentRatio:
        props?.primaryStageTaskSubmitEvaluation?.languageQE?.segmentRatio ?? 0,

      tagDefinitions: props?.tagDefinitions ?? {},
      sampleRate: props?.sampleRate,

      reviewStageEnabled: props?.reviewMethod === PROJECT.SETTINGS.REVIEW_METHOD.REVIEW_TASK.sid,
      reviewStageTaskType: props?.reviewStageTaskTypeSid ?? TASK.TYPE.REVIEW.sid,
      reviewStageTaskCredits: props?.reviewStageTaskCredits ?? 0,
      reviewStageTaskCreditsPolicy:
        props?.reviewStageTaskCreditsPolicy ?? PROJECT.SETTINGS.TASKS.CREDITS.POLICY.PER_TASK.sid,
      reviewStageTaskInstructions: props?.reviewStageTaskWorkerInstructions ?? '',
      reviewStageTaskTimeout: (props?.reviewStageTaskTimeout ?? 0) / 60,
      reviewStageTaskTimeQEChecked: !!props?.reviewStageTaskSubmitEvaluation?.timeQE,
      reviewStageTaskTimeQEDownLimit:
        (props?.reviewStageTaskSubmitEvaluation?.timeQE?.downLimit ?? 0) / 60,
      reviewStageTaskGoogleQEChecked: !!props?.reviewStageTaskSubmitEvaluation?.googleQE,
      reviewStageTaskGoogleQEDownLimit:
        props?.reviewStageTaskSubmitEvaluation?.googleQE?.downLimit ?? 4,
      reviewStageTasGoogleQEUpLimit:
        props?.reviewStageTaskSubmitEvaluation?.googleQE?.upLimit ?? 65,
      reviewStageTaskGoogleQESegmentRatio:
        props?.reviewStageTaskSubmitEvaluation?.googleQE?.segmentRatio ?? 0.5,
    };
  },
};

const mapValuesToSettings = (values) => {
  let primaryStagePreSubmitEvaluation = {};
  if (values?.primaryStageTaskLiveLanguageQEChecked) {
    primaryStagePreSubmitEvaluation.languageQE = {
      minPassScore: !isNaN(parseInt(values?.primaryStageTaskLiveLanguageQEMinPassScore))
        ? parseInt(values.primaryStageTaskLiveLanguageQEMinPassScore)
        : null,
    };
  }

  let primaryStageSubmitEvaluation = {};
  if (values?.primaryStageTaskTimeQEChecked) {
    primaryStageSubmitEvaluation.timeQE = {
      downLimit: !isNaN(parseInt(values?.primaryStageTaskTimeQEDownLimit))
        ? parseInt(values.primaryStageTaskTimeQEDownLimit) * 60
        : null,
    };
  }
  if (values?.primaryStageTaskGoogleQEChecked) {
    primaryStageSubmitEvaluation.googleQE = {
      upLimit: !isNaN(parseInt(values?.primaryStageTaskGoogleQEUpLimit))
        ? parseInt(values.primaryStageTaskGoogleQEUpLimit)
        : null,
      downLimit: !isNaN(parseInt(values?.primaryStageTaskGoogleQEDownLimit))
        ? parseInt(values.primaryStageTaskGoogleQEDownLimit)
        : null,
      segmentRatio: !isNaN(parseFloat(values?.primaryStageTaskGoogleQESegmentRatio))
        ? parseFloat(values.primaryStageTaskGoogleQESegmentRatio)
        : null,
    };
  }
  if (values?.primaryStageTaskLanguageQEChecked) {
    primaryStageSubmitEvaluation.languageQE = {
      minOverallScore: !isNaN(parseInt(values?.primaryStageTaskLanguageQEMinOverallScore))
        ? parseInt(values.primaryStageTaskLanguageQEMinOverallScore)
        : 0,
      segmentRatio: !isNaN(parseFloat(values?.primaryStageTaskLanguageQESegmentRatio))
        ? parseFloat(values.primaryStageTaskLanguageQESegmentRatio)
        : 0,
    };
  }

  let reviewStageSubmitEvaluation = {};
  if (values?.reviewStageTaskTimeQEChecked) {
    reviewStageSubmitEvaluation.timeQE = {
      downLimit: values?.reviewStageTaskTimeQEDownLimit
        ? parseInt(values.reviewStageTaskTimeQEDownLimit) * 60
        : null,
    };
  }

  const hasReviewStage = PROJECT.SETTINGS.REVIEW_METHOD.REVIEW_TASK.sid === values?.reviewMethod;

  return {
    reviewMethod: values?.reviewMethod ?? null,
    segmentChunkPolicy: values?.chunkPolicy ?? null,
    segmentChunkSource: values?.chunkSource ?? null,
    segmentChunkMin: values?.chunkMin ? parseInt(values.chunkMin) : null,
    segmentChunkMax: values?.chunkMax ? parseInt(values.chunkMax) : null,
    segmentChunkAutoReChunk: !!values?.chunkAutoReChunk,

    primaryStageTaskTypeSid: values?.primaryStageTaskType ?? null,
    primaryStageTaskResultsPolicy: values?.primaryStageTaskResultsPolicy ?? null,
    primaryStageTaskTimeout: values?.primaryStageTaskTimeout
      ? parseInt(values.primaryStageTaskTimeout) * 60
      : null,
    primaryStageTaskWorkerInstructions: values?.primaryStageTaskInstructions ?? '',
    primaryStageTaskCreditsPolicy: values?.primaryStageTaskCreditsPolicy ?? null,
    primaryStageTaskCredits: isNaN(parseFloat(values?.primaryStageTaskCredits))
      ? 0
      : parseFloat(values?.primaryStageTaskCredits),
    primaryStageTaskPreSubmitEvaluation: primaryStagePreSubmitEvaluation ?? null,
    primaryStageTaskSubmitEvaluation: primaryStageSubmitEvaluation ?? null,
    primaryStageTaskEvaluationPassThreshold:
      parseInt(values?.primaryStageTaskEvaluationPassThreshold) ?? null,

    tagDefinitions: values?.tagDefinitions ?? null,
    sampleRate: values?.sampleRate ?? null,

    reviewStageTaskTypeSid: hasReviewStage ? values?.reviewStageTaskType ?? null : null,
    reviewStageTaskTimeout: hasReviewStage
      ? values?.reviewStageTaskTimeout
        ? values.reviewStageTaskTimeout * 60
        : 0
      : null,
    reviewStageTaskWorkerInstructions: hasReviewStage
      ? values?.reviewStageTaskInstructions ?? ''
      : null,
    reviewStageTaskCreditsPolicy: hasReviewStage
      ? values?.reviewStageTaskCreditsPolicy ?? null
      : null,
    reviewStageTaskCredits: hasReviewStage
      ? values?.reviewStageTaskCredits
        ? parseFloat(values.reviewStageTaskCredits)
        : 0
      : null,
    reviewStageTaskSubmitEvaluation: hasReviewStage ? reviewStageSubmitEvaluation ?? null : null,
  };
};

const FormView = ({
  scope,
  reviewMethod = null,
  segmentChunkPolicy = null,
  segmentChunkSource = null,
  segmentChunkMin = null,
  segmentChunkMax = null,
  segmentChunkAutoReChunk = false,
  primaryStageTaskTypeSid = null,
  primaryStageTaskResultsPolicy = null,
  primaryStageTaskTimeout = null,
  primaryStageTaskInstructions = null,
  primaryStageTaskCreditsPolicy = null,
  primaryStageTaskCredits = null,
  primaryStageTaskPreSubmitEvaluation = null,
  primaryStageTaskSubmitEvaluation = null,
  primaryStageTaskEvaluationPassThreshold = null,
  reviewStageTaskTypeSid = null,
  reviewStageTaskTimeout = null,
  reviewStageTaskInstructions = null,
  reviewStageTaskCreditsPolicy = null,
  reviewStageTaskCredits = null,
  reviewStageTaskSubmitEvaluation = null,
  onSaveButtonClick = null,
  showCancelButton = false,
  onCancelButtonClick = null,
  hasTasks = false,
  ...props
}) => {
  const reviewMethodValue = Utils.getDefaultOption(
    OPTIONS.reviewMethod,
    props.values?.reviewMethod
  );
  const chunkPolicyValue = Utils.getDefaultOption(OPTIONS.chunkPolicy, props.values?.chunkPolicy);
  const chunkSourceValue = Utils.getDefaultOption(OPTIONS.chunkSource, props.values?.chunkSource);
  const primaryStageTaskTypeValue = Utils.getDefaultOption(
    OPTIONS.primaryStageTaskType,
    props.values?.primaryStageTaskType
  );
  const primaryStageTaskResultsPolicyValue = Utils.getDefaultOption(
    OPTIONS.taskResultsPolicy,
    props.values?.primaryStageTaskResultsPolicy
  );
  const primaryStageCreditsPolicyValue = Utils.getDefaultOption(
    OPTIONS.creditsPolicy,
    props.values?.primaryStageTaskCreditsPolicy
  );
  const reviewStageTaskTypeValue = Utils.getDefaultOption(
    OPTIONS.reviewStageTaskType(getTaskTypeTemplate(props.values.primaryStageTaskType)),
    props.values?.reviewStageTaskType
  );
  const reviewStageCreditsPolicyValue = Utils.getDefaultOption(
    OPTIONS.creditsPolicy,
    props.values?.reviewStageTaskCreditsPolicy
  );
  const sampleRateValue = Utils.getDefaultOption(OPTIONS.sampleRate, props.values?.sampleRate);

  const onGoogleQEDownLimitChange = (value) => {
    value = parseInt(value);
    props.setFieldValue('primaryStageTaskGoogleQEDownLimit', value);
    if (value > props?.values?.primaryStageTaskGoogleQEUpLimit) {
      props.setFieldValue('primaryStageTaskGoogleQEUpLimit', value);
    }
  };
  const onGoogleQEUpLimitChange = (value) => {
    value = parseInt(value);
    props.setFieldValue('primaryStageTaskGoogleQEUpLimit', value);
    if (value < props?.values?.primaryStageTaskGoogleQEDownLimit) {
      props.setFieldValue('primaryStageTaskGoogleQEDownLimit', value);
    }
  };
  const onReviewMethodChange = (field, value = null) => {
    if (PROJECT.SETTINGS.REVIEW_METHOD.REVIEW_TASK.sid === value)
      props.setFieldValue('reviewStageEnabled', true);
    else props.setFieldValue('reviewStageEnabled', false);
    props.setFieldValue(field, value);
  };

  const onSubmit = (keepFormOpen = false) => {
    try {
      props.setSubmitting(true);
      props.validateForm().then(() => {
        if (props.isValid) {
          if (!props?.dirty) onSaveButtonClick(null);
          else {
            let data = mapValuesToSettings(props?.values);
            onSaveButtonClick(data, keepFormOpen);
          }
        } else {
          props.setSubmitting(false);
          Notifier.error(Object.values(props?.errors)?.join('\n'));
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const showGoogleQE = [TASK.TEMPLATE.LINGUISTIC.name].includes(
    getTaskTypeTemplate(props.values.primaryStageTaskType)
  );
  const showLanguageQE = [
    TASK.TEMPLATE.LINGUISTIC.name,
    TASK.TEMPLATE.SPEECH_RECORDING.name,
  ].includes(getTaskTypeTemplate(props.values.primaryStageTaskType));
  const showSegmentChunk = scope === PROJECT.SCOPE.CROWDSOURCING.sid;
  const showSampleRate =
    TASK.TEMPLATE.SPEECH_RECORDING.name === getTaskTypeTemplate(props.values.primaryStageTaskType);

  const languageQEhelpText =
    props.values.primaryStageTaskType === TASK.TYPE.SPEECH_RECORDING.sid
      ? 'Checks speech to text fidelity, comparing an auto-transcription of workers speech with the source text'
      : 'Checks the similarity of worker translations with reference translations';

  const showTagBuilder =
    getTaskTypeTemplate(props.values.primaryStageTaskType) === TASK.TEMPLATE.TEXT_ANNOTATION.name;

  return (
    <form onSubmit={props?.handleSubmit}>
      <div className='row'>
        <div className='col'>
          <div className='card card-default'>
            <div className='card-body'>
              {showSegmentChunk && (
                <>
                  {/* ------------ Segment Chunk ---------------- */}
                  {/* Row */}
                  <h4 className='text-center text-muted pb-3'>Segment Chunk Configuration</h4>
                  <div className='row justify-content-start'>
                    <div className='col-sm-4 col-xs-12'>
                      <SelectWithValidation
                        propertyName={'chunkPolicy'}
                        label={'Segment Chunk Policy'}
                        helpText={'How the source content will be chunked'}
                        defaultOption={chunkPolicyValue}
                        options={OPTIONS.chunkPolicy}
                        error={props?.errors?.chunkPolicy}
                        touched={props?.touched?.chunkPolicy}
                        onChange={props?.setFieldValue}
                        onBlur={props?.setFieldTouched}
                      />
                    </div>
                    <div className='col-sm-4 col-xs-12'>
                      <SelectWithValidation
                        propertyName={'chunkSource'}
                        label={'Segment Chunk Source'}
                        helpText={'Which segments to use for chunking.'}
                        defaultOption={chunkSourceValue}
                        options={OPTIONS.chunkSource}
                        error={props?.errors?.chunkSource}
                        touched={props?.touched?.chunkSource}
                        onChange={props?.setFieldValue}
                        onBlur={props?.setFieldTouched}
                      />
                    </div>
                    <div className='col-sm-4 col-xs-12 pt-4'>
                      <CheckboxWithValidation
                        propertyName={'chunkAutoReChunk'}
                        label={'Auto Re-Chunking'}
                        helpText={
                          'When tasks are reviewed, source segments are re-chunked to new tasks'
                        }
                        defaultValue={props?.values?.chunkAutoReChunk}
                        touched={props?.touched?.chunkAutoReChunk}
                        error={props?.errors?.chunkAutoReChunk}
                        onChange={props?.setFieldValue}
                        onBlur={props?.setFieldTouched}
                      />
                    </div>
                  </div>
                  {/* Row */}
                  <div className='row justify-content-between'>
                    <div className='col-sm-6 col-xs-12'>
                      <InputWithValidation
                        type={'number'}
                        min={0}
                        propertyName={'chunkMin'}
                        label={'Segment Chunk Minimum'}
                        helpText={'The minimum number of words or segments a task can have.'}
                        defaultValue={props?.values?.chunkMin}
                        touched={props?.touched?.chunkMin}
                        error={props?.errors?.chunkMin}
                        onChange={props?.setFieldValue}
                        onBlur={props?.setFieldTouched}
                      />
                    </div>
                    <div className='col-sm-6 col-xs-12'>
                      <InputWithValidation
                        type={'number'}
                        min={0}
                        propertyName={'chunkMax'}
                        label={'Segment Chunk Maximum'}
                        helpText={'The maximum number of words or segments a task can have.'}
                        defaultValue={props?.values?.chunkMax}
                        touched={props?.touched?.chunkMax}
                        error={props?.errors?.chunkMax}
                        onChange={props?.setFieldValue}
                        onBlur={props?.setFieldTouched}
                      />
                    </div>
                  </div>
                </>
              )}
              {/* ------------------- Primary Task ------------------ */}
              {/* Row */}
              <h4 className='text-center text-muted pb-3'>Primary Task Configuration</h4>
              <div className='row justify-content-between'>
                <div className='col-xs-12 col-sm-6'>
                  <SelectWithValidation
                    propertyName={'primaryStageTaskType'}
                    label={'Task Type'}
                    helpText={'Task type'}
                    defaultOption={primaryStageTaskTypeValue}
                    options={OPTIONS.primaryStageTaskType}
                    error={props?.errors?.primaryStageTaskType}
                    touched={props?.touched?.primaryStageTaskType}
                    onChange={props?.setFieldValue}
                    onBlur={props?.setFieldTouched}
                  />
                </div>
                {showSampleRate && (
                  <div className='col-xs-12 col-sm-6'>
                    <Tooltip title='This field is disabled if the project is not empty (has active, in progress or completed tasks)'>
                      <SelectWithValidation
                        propertyName='sampleRate'
                        label='Recording Frequency'
                        helpText='The frequency of the recording'
                        defaultOption={sampleRateValue}
                        isDisabled={!!props?.sampleRate && hasTasks}
                        options={OPTIONS.sampleRate}
                        error={props?.errors?.sampleRate}
                        touched={props?.touched?.sampleRate}
                        onChange={props?.setFieldValue}
                        onBlur={props?.setFieldTouched}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
              {showTagBuilder && (
                <div className='row justify-content-between'>
                  <div className='col-12'>
                    <TagBuilder
                      tagDefinitions={props?.values?.tagDefinitions}
                      error={props?.errors?.tagDefinitions}
                      onChange={props?.setFieldValue}
                      onSave={() => onSubmit(true)}
                    />
                  </div>
                </div>
              )}
              {/* Row */}
              <div className='row justify-content-between'>
                <div className='col-xs-12 col-md-3'>
                  <InputWithValidation
                    type={'number'}
                    min={0}
                    propertyName={'primaryStageTaskTimeout'}
                    label={'Timeout (Min)'}
                    placeholder={'Enter a number...'}
                    helpText={'The time in minutes until the task expires'}
                    defaultValue={props.values?.primaryStageTaskTimeout}
                    touched={props?.touched?.primaryStageTaskTimeout}
                    error={props?.errors?.primaryStageTaskTimeout}
                    onChange={props?.setFieldValue}
                    onBlur={props?.setFieldTouched}
                  />
                </div>
                <div className='col-xs-12 col-md-3'>
                  <SelectWithValidation
                    propertyName={'primaryStageTaskResultsPolicy'}
                    label={'Results Policy'}
                    helpText={'How many results are allowed per task'}
                    defaultOption={primaryStageTaskResultsPolicyValue}
                    options={OPTIONS.taskResultsPolicy}
                    error={props?.errors?.primaryStageTaskResultsPolicy}
                    touched={props?.touched?.primaryStageTaskResultsPolicy}
                    onChange={props?.setFieldValue}
                    onBlur={props?.setFieldTouched}
                  />
                </div>
                <div className='col-xs-12 col-md-3'>
                  <SelectWithValidation
                    propertyName={'primaryStageTaskCreditsPolicy'}
                    label={'Pricing Policy'}
                    helpText={'The way credits will be charged'}
                    defaultOption={primaryStageCreditsPolicyValue}
                    options={OPTIONS.creditsPolicy}
                    error={props?.errors?.primaryStageTaskCreditsPolicy}
                    touched={props?.touched?.primaryStageTaskCreditsPolicy}
                    onChange={props?.setFieldValue}
                    onBlur={props?.setFieldTouched}
                  />
                </div>
                <div className='col-xs-12 col-md-3'>
                  <InputWithValidation
                    type={'number'}
                    min={0}
                    precision={2}
                    step={0.00001}
                    propertyName={'primaryStageTaskCredits'}
                    label={'Credits'}
                    placeholder={'Enter a number...'}
                    helpText={'How many credits charged per type selected'}
                    defaultValue={props.values?.primaryStageTaskCredits ?? 0}
                    touched={props?.touched?.primaryStageTaskCredits}
                    error={props?.errors?.primaryStageTaskCredits}
                    onChange={props?.setFieldValue}
                    onBlur={props?.setFieldTouched}
                  />
                </div>
              </div>
              {/* Row */}
              <div className='row justify-content-between'>
                <div className='col'>
                  <InputWithValidation
                    type={'textarea'}
                    rows={4}
                    propertyName={'primaryStageTaskInstructions'}
                    label={'Instructions'}
                    helpText={'The instructions shown to the user in the task page'}
                    defaultValue={props?.values?.primaryStageTaskInstructions}
                    touched={props?.touched?.primaryStageTaskInstructions}
                    error={props?.errors?.primaryStageTaskInstructions}
                    onChange={props?.setFieldValue}
                    onBlur={props?.setFieldTouched}
                  />
                </div>
              </div>
              {/* ------------------ Primary Task QE --------------------- */}
              {/* Row */}
              {showLanguageQE && (
                <div className='row justify-content-between'>
                  <div className='col-sm-6 col-xs-12 pt-4'>
                    <CheckboxWithValidation
                      propertyName={'primaryStageTaskLiveLanguageQEChecked'}
                      label={'Live Language QE (Pre Submit)'}
                      helpText={languageQEhelpText}
                      defaultValue={props?.values?.primaryStageTaskLiveLanguageQEChecked}
                      touched={props?.touched?.primaryStageTaskLiveLanguageQEChecked}
                      error={props?.errors?.primaryStageTaskLiveLanguageQEChecked}
                      onChange={props?.setFieldValue}
                      onBlur={props?.setFieldTouched}
                    />
                  </div>
                  <div className='col-sm-6 col-xs-12'>
                    <InputWithValidation
                      disabled={!props?.values?.primaryStageTaskLiveLanguageQEChecked}
                      min={0}
                      max={100}
                      type={'number'}
                      propertyName={'primaryStageTaskLiveLanguageQEMinPassScore'}
                      label={'Minimum Segment Pass Score'}
                      helpText={'Minimum score needed for segment to be approved [0,100]'}
                      defaultValue={props.values?.primaryStageTaskLiveLanguageQEMinPassScore}
                      touched={props.touched?.primaryStageTaskLiveLanguageQEMinPassScore}
                      error={props.errors?.primaryStageTaskLiveLanguageQEMinPassScore}
                      onChange={props?.setFieldValue}
                      onBlur={props?.setFieldTouched}
                    />
                  </div>
                </div>
              )}
              {/* Row */}
              <div className='row justify-content-between'>
                <div className='col-sm-6 col-xs-12 pt-4'>
                  <CheckboxWithValidation
                    propertyName={'primaryStageTaskTimeQEChecked'}
                    label={'Submission Time (Post Submit)'}
                    helpText={'Checks the time the worker needed to submit the task'}
                    defaultValue={props?.values?.primaryStageTaskTimeQEChecked}
                    touched={props?.touched?.primaryStageTaskTimeQEChecked}
                    error={props?.errors?.primaryStageTaskTimeQEChecked}
                    onChange={props?.setFieldValue}
                    onBlur={props?.setFieldTouched}
                  />
                </div>
                <div className='col-sm-6 col-xs-12'>
                  <InputWithValidation
                    disabled={!props?.values?.primaryStageTaskTimeQEChecked}
                    min={0}
                    type={'number'}
                    propertyName={'primaryStageTaskTimeQEDownLimit'}
                    label={'Down Limit'}
                    helpText={'The time threshold in minutes in order to consider a task as risky'}
                    defaultValue={props.values?.primaryStageTaskTimeQEDownLimit}
                    touched={props.touched?.primaryStageTaskTimeQEDownLimit}
                    error={props.errors?.primaryStageTaskTimeQEDownLimit}
                    onChange={props?.setFieldValue}
                    onBlur={props?.setFieldTouched}
                  />
                </div>
              </div>
              {
                /* Row */
                showGoogleQE && (
                  <div className='row justify-content-between'>
                    <div className='col-md-12 col-lg-6 row justify-content-between mx-0 px-0'>
                      <div className='col-xs-12 col-sm-6 pt-4'>
                        <CheckboxWithValidation
                          propertyName={'primaryStageTaskGoogleQEChecked'}
                          label={'Machine Translation (Post Submit)'}
                          helpText={
                            'Checks the similarity of worker translations with Google translate'
                          }
                          defaultValue={props?.values?.primaryStageTaskGoogleQEChecked}
                          touched={props?.touched?.primaryStageTaskGoogleQEChecked}
                          error={props?.errors?.primaryStageTaskGoogleQEChecked}
                          onChange={props?.setFieldValue}
                          onBlur={props?.setFieldTouched}
                        />
                      </div>
                      <div className='col-xs-12 col-sm-6'>
                        <InputWithValidation
                          disabled={!props?.values?.primaryStageTaskGoogleQEChecked}
                          min={0}
                          max={1}
                          precision={2}
                          step={0.1}
                          type={'number'}
                          propertyName={'primaryStageTaskGoogleQESegmentRatio'}
                          label={'Minimum Pass Segment Ratio'}
                          helpText={
                            'The ratio of segments passed the QE to the total number of segments.'
                          }
                          defaultValue={props?.values?.primaryStageTaskGoogleQESegmentRatio}
                          touched={props?.touched?.primaryStageTaskGoogleQESegmentRatio}
                          error={props?.errors?.primaryStageTaskGoogleQESegmentRatio}
                          onChange={props?.setFieldValue}
                          onBlur={props?.setFieldTouched}
                        />
                      </div>
                    </div>
                    <div className='col-md-12 col-lg-6 row justify-content-between mx-0 px-0'>
                      <div className='col-xs-12 col-sm-6'>
                        <InputWithValidation
                          disabled={!props?.values?.primaryStageTaskGoogleQEChecked}
                          min={0}
                          type={'number'}
                          propertyName={'primaryStageTaskGoogleQEDownLimit'}
                          label={'Score Down Limit'}
                          helpText={
                            "The lower bound of the segment's edit distance with the google translation so as to consider the segment content as risky"
                          }
                          defaultValue={props?.values?.primaryStageTaskGoogleQEDownLimit}
                          touched={props?.touched?.primaryStageTaskGoogleQEDownLimit}
                          error={props?.errors?.primaryStageTaskGoogleQEDownLimit}
                          onChange={props?.setFieldValue}
                          onBlur={props?.setFieldTouched}
                          onValueChange={onGoogleQEDownLimitChange}
                        />
                      </div>
                      <div className='col-xs-12 col-sm-6'>
                        <InputWithValidation
                          disabled={!props?.values?.primaryStageTaskGoogleQEChecked}
                          min={0}
                          type={'number'}
                          propertyName={'primaryStageTaskGoogleQEUpLimit'}
                          label={'Score Up Limit'}
                          helpText={
                            "The upper bound of the segment's edit distance with the google translation so as to consider the segment content as risky"
                          }
                          defaultValue={props?.values?.primaryStageTaskGoogleQEUpLimit}
                          touched={props?.touched?.primaryStageTaskGoogleQEUpLimit}
                          error={props?.errors?.primaryStageTaskGoogleQEUpLimit}
                          onChange={props?.setFieldValue}
                          onBlur={props?.setFieldTouched}
                          onValueChange={onGoogleQEUpLimitChange}
                        />
                      </div>
                    </div>
                  </div>
                )
              }
              {
                /* Row */
                showLanguageQE && (
                  <div className='row justify-content-between'>
                    <div className='col-md-12 col-lg-6 row justify-content-between mx-0 px-0'>
                      <div className='col-xs-12 col-sm-6 pt-4'>
                        <CheckboxWithValidation
                          propertyName={'primaryStageTaskLanguageQEChecked'}
                          label={'Language QE (Post Submit)'}
                          helpText={languageQEhelpText}
                          defaultValue={props?.values?.primaryStageTaskLanguageQEChecked}
                          touched={props?.touched?.primaryStageTaskLanguageQEChecked}
                          error={props?.errors?.primaryStageTaskLanguageQEChecked}
                          onChange={props?.setFieldValue}
                          onBlur={props?.setFieldTouched}
                        />
                      </div>
                      <div className='col-xs-12 col-sm-6'>
                        <InputWithValidation
                          disabled={!props?.values?.primaryStageTaskLanguageQEChecked}
                          min={0}
                          max={1}
                          precision={2}
                          step={0.1}
                          type={'number'}
                          propertyName={'primaryStageTaskLanguageQESegmentRatio'}
                          label={'Minimum Pass Segment Ratio'}
                          helpText={
                            'The ratio of segments passed the QE to the total number of segments [0,1]'
                          }
                          defaultValue={props?.values?.primaryStageTaskLanguageQESegmentRatio}
                          touched={props?.touched?.primaryStageTaskLanguageQESegmentRatio}
                          error={props?.errors?.primaryStageTaskLanguageQESegmentRatio}
                          onChange={props?.setFieldValue}
                          onBlur={props?.setFieldTouched}
                        />
                      </div>
                    </div>
                    <div className='col-md-12 col-lg-6 row justify-content-between mx-0 px-0'>
                      <div className='col-xs-12 col-sm-6'>
                        <InputWithValidation
                          disabled={!props?.values?.primaryStageTaskLanguageQEChecked}
                          min={0}
                          max={100}
                          type='number'
                          propertyName={'primaryStageTaskLanguageQEMinOverallScore'}
                          label={'Minimum Segment Pass Score'}
                          helpText={'Minimum score needed for segment to be approved [0,100]'}
                          defaultValue={props?.values?.primaryStageTaskLanguageQEMinOverallScore}
                          touched={props?.touched?.primaryStageTaskLanguageQEMinOverallScore}
                          error={props?.errors?.primaryStageTaskLanguageQEMinOverallScore}
                          onChange={props?.setFieldValue}
                          onBlur={props?.setFieldTouched}
                        />
                      </div>
                    </div>
                  </div>
                )
              }
              {/* Row */}
              <div className='row'>
                <div className='col-6'>
                  <InputWithValidation
                    min={0}
                    max={100}
                    precision={0}
                    step={5}
                    type={'number'}
                    propertyName={'primaryStageTaskEvaluationPassThreshold'}
                    label={'Evaluation Pass Threshold (%)'}
                    helpText={
                      'The ratio of approved segments within a task that pass the evaluation'
                    }
                    defaultValue={props?.values?.primaryStageTaskEvaluationPassThreshold}
                    touched={props?.touched?.primaryStageTaskEvaluationPassThreshold}
                    error={props?.errors?.primaryStageTaskEvaluationPassThreshold}
                    onChange={props?.setFieldValue}
                    onBlur={props?.setFieldTouched}
                  />
                </div>
              </div>
              {/* --------------------- Segment Review ------------------- */}
              {/* Row */}
              <h4 className='text-center text-muted pb-3'>Segment Review Configuration</h4>
              <div className='row'>
                <div className='col'>
                  <SelectWithValidation
                    propertyName={'reviewMethod'}
                    label={'Review Method'}
                    helpText={'The way the tasks are reviewed'}
                    defaultOption={reviewMethodValue}
                    options={OPTIONS.reviewMethod}
                    error={props?.errors?.reviewMethod}
                    touched={props?.touched?.reviewMethod}
                    onChange={onReviewMethodChange}
                    onBlur={props?.setFieldTouched}
                  />
                </div>
              </div>
              {!props.values?.reviewStageEnabled ? null : (
                <>
                  {/* ------------------ Review Stage Task ------------------ */}
                  {/*	Row */}
                  <h4 className='text-center text-muted pb-3'>Review Task Configuration</h4>
                  <div className='row justify-content-between'>
                    <div className='col-md-12 col-lg-6 row justify-content-between mx-0 px-0'>
                      <div className='col-xs-12 col-sm-8'>
                        <SelectWithValidation
                          propertyName={'reviewStageTaskType'}
                          label={'Task Type'}
                          helpText={'Task type'}
                          defaultOption={reviewStageTaskTypeValue}
                          options={OPTIONS.reviewStageTaskType(
                            getTaskTypeTemplate(props.values.primaryStageTaskType)
                          )}
                          error={props?.errors?.reviewStageTaskType}
                          touched={props?.touched?.reviewStageTaskType}
                          onChange={props?.setFieldValue}
                          onBlur={props?.setFieldTouched}
                        />
                      </div>
                      <div className='col-xs-12 col-sm-4'>
                        <InputWithValidation
                          type={'number'}
                          min={0}
                          propertyName={'reviewStageTaskTimeout'}
                          label={'Timeout (Min)'}
                          placeholder={'Enter a number...'}
                          helpText={'The time in minutes until the task expires'}
                          defaultValue={props.values?.reviewStageTaskTimeout}
                          touched={props?.touched?.reviewStageTaskTimeout}
                          error={props?.errors?.reviewStageTaskTimeout}
                          onChange={props?.setFieldValue}
                          onBlur={props?.setFieldTouched}
                        />
                      </div>
                    </div>
                    <div className='col-md-12 col-lg-6 row justify-content-between mx-0 px-0'>
                      <div className='col-xs-12 col-sm-8'>
                        <SelectWithValidation
                          propertyName={'reviewStageTaskCreditsPolicy'}
                          label={'Pricing Policy'}
                          helpText={'The way credits will be charged'}
                          defaultOption={reviewStageCreditsPolicyValue}
                          options={OPTIONS.creditsPolicy}
                          error={props?.errors?.reviewStageTaskCreditsPolicy}
                          touched={props?.touched?.reviewStageTaskCreditsPolicy}
                          onChange={props?.setFieldValue}
                          onBlur={props?.setFieldTouched}
                        />
                      </div>
                      <div className='col-xs-12 col-sm-4'>
                        <InputWithValidation
                          type={'number'}
                          min={0}
                          precision={2}
                          step={0.001}
                          propertyName={'reviewStageTaskCredits'}
                          label={'Credits'}
                          placeholder={'Enter a number...'}
                          helpText={'How many credits charged per type selected'}
                          defaultValue={props.values?.reviewStageTaskCredits ?? 0}
                          touched={props?.touched?.reviewStageTaskCredits}
                          error={props?.errors?.reviewStageTaskCredits}
                          onChange={props?.setFieldValue}
                          onBlur={props?.setFieldTouched}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Row */}
                  <div className='row justify-content-between'>
                    <div className='col'>
                      <InputWithValidation
                        type={'textarea'}
                        rows={4}
                        propertyName={'reviewStageTaskInstructions'}
                        label={'Instructions'}
                        helpText={'The instructions shown to the user in the task page'}
                        defaultValue={props?.values?.reviewStageTaskInstructions}
                        touched={props?.touched?.reviewStageTaskInstructions}
                        error={props?.errors?.reviewStageTaskInstructions}
                        onChange={props?.setFieldValue}
                        onBlur={props?.setFieldTouched}
                      />
                    </div>
                  </div>
                  {/* Row */}
                  <div className='row justify-content-between'>
                    <div className='col-sm-6 col-xs-12 pt-4'>
                      <CheckboxWithValidation
                        propertyName={'reviewStageTaskTimeQEChecked'}
                        label={'Enable Time QE'}
                        helpText={'Checks the time the worker needed to submit the task'}
                        defaultValue={props?.values?.reviewStageTaskTimeQEChecked}
                        touched={props?.touched?.reviewStageTaskTimeQEChecked}
                        error={props?.errors?.reviewStageTaskTimeQEChecked}
                        onChange={props?.setFieldValue}
                        onBlur={props?.setFieldTouched}
                      />
                    </div>
                    <div className='col-sm-6 col-xs-12'>
                      <InputWithValidation
                        disabled={!props?.values?.reviewStageTaskTimeQEChecked}
                        min={0}
                        type={'number'}
                        propertyName={'reviewStageTaskTimeQEDownLimit'}
                        label={'Down Limit'}
                        helpText={
                          'The time threshold in minutes in order to consider a task as risky'
                        }
                        defaultValue={props.values?.reviewStageTaskTimeQEDownLimit}
                        touched={props.touched?.reviewStageTaskTimeQEDownLimit}
                        error={props.errors?.reviewStageTaskTimeQEDownLimit}
                        onChange={props?.setFieldValue}
                        onBlur={props?.setFieldTouched}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='row justify-content-end mx-0'>
        {showCancelButton ? (
          <button
            key='cancel'
            type='button'
            className='float-right btn btn-outline-secondary mr-2'
            onClick={onCancelButtonClick}
          >
            Cancel
          </button>
        ) : null}
        <button
          key='save'
          type='button'
          className='float-right btn btn-outline-primary'
          onClick={() => onSubmit(false)}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default withFormik(formikOptions)(FormView);
