import React from 'react';
import { DropzoneComponent } from 'react-dropzone-component';
import './styles/filepicker.css';
import './styles/dropzone.min.css';
import PropTypes from 'prop-types';
import { FormFieldFooter } from '../../components/forms/FormFieldFooter';
import { FormGroup } from 'reactstrap';

export default class DropZone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: 0,
    };

    this.djsConfig = {
      addRemoveLinks: true,
      acceptedFiles: ['text/csv', '.tsv', 'application/vnd.ms-excel'],
      autoProcessQueue: false,
      dictDefaultMessage: 'Drop a file here or click to browse...',
    };

    this.componentConfig = {
      iconFiletypes: ['.csv', '.tsv'],
      showFiletypeIcon: true,
      postUrl: 'no-url',
    };

    this.dropzone = null;
  }

  static defaultProps = {
    onFileRemoved: () => {},
  };

  onFileAdded = (file) => {
    if (this.dropzone.files.length > 1) {
      this.dropzone.removeAllFiles();
      this.dropzone.addFile(file);
    }

    this.props.onFileAdded(file);
  };

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;
    const eventHandlers = {
      init: (dz) => (this.dropzone = dz),
      addedfile: this.onFileAdded,
      removedfile: this.props.onFileRemoved,
    };

    if (this.props.fileParsed && this.dropzone)
      this.dropzone.emit('success', this.props.fileParsed, '');

    return (
      <FormGroup>
        <DropzoneComponent
          config={config}
          eventHandlers={eventHandlers}
          djsConfig={djsConfig}
          className={this.props.className}
        />
        <FormFieldFooter
          helpText={this.props.helpText}
          error={this.props.error}
          touched={this.props.touched}
        />
      </FormGroup>
    );
  }
}

DropZone.propTypes = {
  onFileAdded: PropTypes.func.isRequired,
  onFileRemoved: PropTypes.func,
  fileParsed: PropTypes.object,
};
