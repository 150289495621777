import { get, post, put, remove } from '../http';
import { objectToQueryString } from '../utils/Url';

const BASE_URL = '/api/screener-step';

const getAll = async (parameters = {}) => {
  return get(`${BASE_URL}?${objectToQueryString(parameters)}`);
};

const getStep = async (stepId) => {
  if (!stepId) {
    throw new Error('Invalid screening step id (missing)');
  }
  return get(`${BASE_URL}/${stepId}`);
};

const create = async (data = null) => {
  if (!data) {
    throw new Error('Invalid screening step data (missing)');
  }
  return post(`${BASE_URL}`, data);
};

const update = async (data = null, id = null) => {
  if (!data) {
    throw new Error('Invalid screening step data (missing)');
  }
  if (!id) {
    throw new Error('Invalid screening step id (missing)');
  }
  return put(`${BASE_URL}/${id}`, data);
};

const invalidate = async (id = null) => {
  if (!id) {
    throw new Error('Invalid screening step id (missing)');
  }
  return get(`${BASE_URL}/${id}/invalidate`);
};

const deleteStep = async (id = null) => {
  if (!id) {
    throw new Error('Invalid step id (missing)');
  }
  return remove(`${BASE_URL}/` + id);
};

const completeStep = async (id = null, userId = null) => {
  if (!id) {
    throw new Error('Invalid step id (missing)');
  }
  if (!userId) {
    throw new Error('Invalid user id (missing)');
  }
  return post(`${BASE_URL}/${id}/user/${userId}`);
};

const unCompleteStep = async (id = null, userId = null) => {
  if (!id) {
    throw new Error('Invalid step id (missing)');
  }
  if (!userId) {
    throw new Error('Invalid user id (missing)');
  }
  return remove(`${BASE_URL}/${id}/user/${userId}`);
};

const ScreeningController = {
  getAll,
  getStep,
  create,
  update,
  invalidate,
  delete: deleteStep,
  completeStep,
  unCompleteStep,
};

export default ScreeningController;
