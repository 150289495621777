import { get, post, put, remove } from '../http';
import { objectToQueryString } from '../utils/Url';

const BASE_URL = '/api/user';

const getAll = async (parameters = null, headers = null) => {
  return get(`${BASE_URL}?${objectToQueryString(parameters, { stringifyArrays: true })}`, {
    headers: { ...headers },
  });
};

const search = async (parameters = null) => {
  return get(`${BASE_URL}/search?${objectToQueryString(parameters)}`);
};

const getUser = async (id = null) => {
  if (!id) {
    throw new Error('Invalid user id (missing)');
  }
  return get(`${BASE_URL}/${id}`);
};

const create = async (user = null) => {
  if (!user) {
    throw new Error('Invalid user (missing)');
  }
  return post(`${BASE_URL}`, user);
};

const update = async (user) => {
  if (!user || !user.id) {
    throw new Error('Invalid user id (missing)');
  }
  return put(`${BASE_URL}/${user.id}`, user);
};

const forgotPassword = async (parameters = null) => {
  if (parameters === null) throw new Error('Invalid email (missing)');
  return post(`${BASE_URL}/recover?${objectToQueryString(parameters)}`);
};

const updatePassword = async (token, parameters = null) => {
  if (parameters === null) throw new Error('Invalid email (missing)');
  return put(`${BASE_URL}/recover/${token}?${objectToQueryString(parameters)}`);
};

const deleteUser = async (id) => {
  if (!id) {
    throw new Error('Invalid user id (missing)');
  }
  return remove(`${BASE_URL}/${id}/delete`);
};

const getPersonalProfile = async (id) => {
  if (!id) {
    throw new Error('Invalid user id (missing)');
  }
  return get(`${BASE_URL}/${id}/personal-profile`);
};

const updatePersonalProfile = async (id, profile) => {
  if (!id) {
    throw new Error('Invalid user id (missing)');
  }
  if (!profile) {
    throw new Error('Invalid user update profile (missing)');
  }
  return put(`${BASE_URL}/${id}/personal-profile`, profile);
};

const getPaymentProfile = async (id) => {
  if (!id) {
    throw new Error('Invalid user id (Missing)');
  }
  return get(`${BASE_URL}/${id}/payment-profile`);
};

const register = async (profile) => {
  if (!profile) {
    throw new Error('Invalid user update profile (missing)');
  }
  return post(`/api/taus/register`, profile);
};

const addToRole = async (userId, roleId) => {
  if (!userId) {
    throw new Error('Invalid user ID (missing)');
  }
  if (!roleId) {
    throw new Error('Invalid role ID (missing)');
  }
  return post(`${BASE_URL}/${userId}/role`, roleId);
};

const removeFromRole = async (userId, roleId) => {
  if (!userId) {
    throw new Error('Invalid user ID (missing)');
  }
  if (!roleId) {
    throw new Error('Invalid role ID (missing)');
  }
  return remove(`${BASE_URL}/${userId}/role/${roleId}`);
};

const submitQuestionnaire = (questionnaireId, userMetadata) => {
  if (!questionnaireId) {
    throw new Error('Invalid questionnaireId id (missing)');
  }
  return post(`${BASE_URL}/questionnaire/${questionnaireId}`, userMetadata);
};

const setUserGeolocation = (coordinates) => {
  return put(`${BASE_URL}/geolocation`, coordinates);
};

const UserController = {
  getAll,
  search,
  get: getUser,
  create,
  update,
  forgotPassword,
  updatePassword,
  delete: deleteUser,
  getPersonalProfile,
  updatePersonalProfile,
  register,
  getPaymentProfile,
  addToRole,
  removeFromRole,
  submitQuestionnaire,
  setUserGeolocation,
};
export default UserController;
