import { get, post } from '../http';
import { objectToQueryString } from '../utils/Url';

const BASE_URL = '/api/task';

const getAll = async (parameters = {}, headers = {}) => {
  return get(`${BASE_URL}?${objectToQueryString(parameters)}`, { headers: { ...headers } });
};

const getAvailable = async (parameters = {}) => {
  return get(`${BASE_URL}?${objectToQueryString({ ...parameters, permissions: ['REQUEST'] })}`);
};

const request = async (id) => {
  if (!id) {
    throw new Error('Invalid task id (missing)');
  }
  return post(`${BASE_URL}/${id}/request`);
};

const TaskController = { getAll, getAvailable, request };

export default TaskController;
