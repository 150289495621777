import Utils from '../utils/Utils';

export default class ApiException extends Error {
  constructor(message, exStatus, exCode) {
    super(message);
    this.code = Utils.define(exCode, ApiException.defaultProps.code);
    this.status = Utils.define(exStatus, ApiException.defaultProps.status);
    this.userMessage = Utils.define(message, ApiException.defaultProps.message);
  }

  getDefaultProperties = () => ({ ...ApiException.defaultProps });
  static defaultProps = {
    userMessage: 'Unknown application error.',
    code: 0,
    status: 500,
  };

  // -------------------------------------------------------------------------------
  // ----------------------------- Static ------------------------------------------
  // -------------------------------------------------------------------------------

  static toApiException(error, userMessage, exStatus, exCode) {
    if (Utils.isNull(error)) return new ApiException(userMessage, exStatus, exCode);
    if (error instanceof ApiException) {
      if (!Utils.isNull(userMessage)) error.userMessage = userMessage;
      if (!Utils.isNull(exStatus)) error.status = exStatus;
      if (!Utils.isNull(exCode)) error.code = exCode;
      return error;
    } else {
      let exception = new ApiException(Utils.define(userMessage, error?.message), exStatus, exCode);
      exception.error = error;
      return exception;
    }
  }
}

export class UserAbortRequestException extends ApiException {
  constructor() {
    super('User aborted the request', 200, 20);
  }
}
export class UnauthorizedException extends ApiException {
  constructor(data) {
    let message;
    try {
      message = !!data.error ? data.error : JSON.parse(data.error).error;
      if (message.includes('User is disabled') || message.includes('User account is locked')) {
        message = 'Account not active. Please check your email to activate your account.';
      }
    } catch (_) {}
    super(
      message ?? 'Unauthorized user! Your session must have expired, please login again!',
      401,
      4001
    );
  }
}
export class UnableToParseServerResponseException extends ApiException {
  constructor() {
    super('Unable to parse server response', 500, 5001);
  }
}

export class NotFoundException extends ApiException {
  constructor() {
    super('Not found object requested', 404, 4040);
  }
}

export class MethodNotAllowedException extends ApiException {
  constructor(data) {
    super(data.error, 405, 4040);
  }
}

export class BadRequestException extends ApiException {
  constructor(data) {
    super(data.error ?? 'Bad Request', 400, 4000);
  }
}

export class ConflictException extends ApiException {
  constructor(data) {
    let message = data;
    if (typeof data !== 'string' && data.error) message = data.error;
    super(message, 409, 4090);
  }
}
export class UnknownServerException extends ApiException {
  constructor() {
    super('Unknown server error', 500, 5000);
  }
}
export class UnprocessableEntityException extends ApiException {
  constructor(data) {
    let message = '';
    if (!!data.errors && Object.keys(data.errors).length > 0) {
      for (const error of Object.values(data.errors)) {
        message += error.join(', ');
      }
    } else if (data.error) {
      message = data.error;
    } else if (typeof data === 'string') {
      message = data;
    } else {
      message = data.message;
    }
    super(message, 422, 4220);
  }
}
