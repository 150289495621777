import React, { useRef, useState } from 'react';
import { parse, RECORD_SEP, UNIT_SEP } from 'papaparse';
import {
  Button,
  ButtonDropdown,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Progress,
  Row,
} from 'reactstrap';
import ModalErrors from '../project/components/ModalErrors';
import TextWithIcon from '../project/components/TextWithIcon';
import DropZone from './DropZone';
import PropTypes from 'prop-types';
import Utils from '../../utils/Utils';
import Tooltip from '../../components/Tooltip';
import FileController from '../../controllers/FileController';
import RequestProgressController from '../../controllers/RequestProgressController';
import Notifier from '../../components/Notifier';
import ErrorHandler from '../../utils/ErrorHandler';

const extractLanguageMetadata = (data) => {
  const metadata = {};
  Object.keys(data)
    .filter((key) => key.includes('_lang:'))
    .forEach((key) => {
      const langCode = key.split('_lang:')?.[1] ?? null;
      const metadataName = key.replace('metadata_', '').replace(`_lang:${langCode}`, '') ?? null;
      if (!metadataName) {
        throw new Error(`Invalid metadata property name (${key})`);
      }
      if (!metadata[metadataName]) {
        metadata[metadataName] = { isLinguistic: true };
      }
      metadata[metadataName][langCode] = data[key];
    });
  return metadata;
};

const extractMetadata = (data) => {
  let metadata = extractLanguageMetadata(data);
  Object.keys(data)
    .filter((key) => key.includes('metadata_'))
    .filter((key) => !key.includes('_lang:'))
    .forEach((key) => {
      const metadataName = key.split('metadata_')?.[1] ?? null;
      if (!metadataName) {
        throw new Error(`Invalid metadata property name (${key})`);
      }
      metadata[metadataName] = data[key];
    });
  return metadata;
};

const sourceCSVCalcFunc = (result) => {
  const delimiter = ';';
  return result.map((x) => (x?.id ?? null) + delimiter + (x?.content?.data ?? '')).join('\n');
};

const sourceTXTCalcFunc = (result) => {
  const delimiter = '@@@';
  return result.map((x) => (x?.id ?? null) + delimiter + (x?.content?.data ?? '')).join('\n');
};

const downloadFile = (type, res, fileName) => {
  let mimetype;
  let result;
  if (type === DROPDOWN_VALUE_JSON) {
    mimetype = 'application/json';
    result = res;
  } else if (type === DROPDOWN_VALUE_CSV) {
    mimetype = 'text/csv';
    result = sourceCSVCalcFunc(res);
  } else {
    mimetype = 'text/plain';
    result = sourceTXTCalcFunc(res);
  }
  Utils.downloadFile(result, fileName, mimetype);
};

const DROPDOWN_VALUE_JSON = 'Download as json';
const DROPDOWN_VALUE_CSV = 'Download as csv';
const DROPDOWN_VALUE_TEXT = 'Download as plain text';

const ContentForm = ({ file, isEditMode, refreshFile }) => {
  const contentsTouched = false;

  const [progressPercent, setProgressPercent] = useState(0);
  const [progressMessage, setProgressMessage] = useState('');
  const [isGettingProgress, setIsGettingProgress] = useState(false);
  const [isBilingual, setBilingual] = useState(false);
  const [warningCount, setWarningCount] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    Notifier.info('Please wait while the content is being uploaded...');

    const timeout = Math.max(segments.current.length / 10, 1000);
    FileController.createSegments(file.id, segments.current)
      .then((response) => {
        // Get progress
        setTimeout(() => getProgress(response?.uuid, timeout), timeout);
        setIsGettingProgress(true);
      })
      .catch((error) => {
        ErrorHandler.errorHandle(error);
        setErrors([error.toString()]);
      });
  };

  const onGenerateComplete = (hadErrors) => {
    const message = hadErrors ? 'There was an error!' : 'Completed!';
    setProgressPercent(100);
    setProgressMessage(message);
    setIsGettingProgress(false);
    if (!hadErrors) {
      Notifier.success('Content is now available!');
    }
    setWarningCount(0);
    setBilingual(false);
    setIsSubmitting(false);
    refreshFile(file.id);
  };

  const getResult = (uuid = null) => {
    RequestProgressController.getResponse(uuid)
      .then(() => onGenerateComplete(false))
      .catch((ex) => {
        onGenerateComplete(true);
        setErrors([ex.toString()]);
        ErrorHandler.errorHandle(ex);
      });
  };

  const getProgress = (uuid = null, timeout = 500) => {
    RequestProgressController.getStatus(uuid)
      .then((result) => {
        if (result?.completed) {
          getResult(uuid);
        } else {
          let progress = result?.progress;
          setProgressPercent(progress);
          setProgressMessage(result?.message ?? '');
          setTimeout(() => getProgress(uuid, timeout), timeout);
        }
      })
      .catch((ex) => {
        onGenerateComplete(true);
        setErrors([ex.toString()]);
        ErrorHandler.errorHandle(ex);
      });
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevOpen) => !prevOpen);

  const [dropdownValue, setDropdownValue] = useState(DROPDOWN_VALUE_JSON);
  const changeDropdownValue = (e) => setDropdownValue(e.currentTarget.textContent);

  const [isDownloading, setIsDownloading] = useState(false);
  const onDownload = async () => {
    setIsDownloading(true);
    try {
      const result = await FileController.getSegments(file?.id);
      downloadFile(dropdownValue, result?.page || result, file.name);
    } catch (e) {
      ErrorHandler.errorHandle(e);
    } finally {
      setIsDownloading(false);
    }
  };

  const [errors, setErrors] = useState([]);

  const [fileParsed, setFileParsed] = useState(null);

  const segments = useRef([]);

  const parseCSV = (file) => {
    parse(file, {
      header: true,
      delimitersToGuess: ['\t', '|', ';', RECORD_SEP, UNIT_SEP],
      complete: ({ data }) => {
        setFileParsed(file);
        segments.current = data
          .filter((row) => row.source_content?.length > 0) // excel adds an extra row with empty data
          .map((row) => {
            const newRow = {
              sourceContent: row.source_content.trim(),
              metadata: extractMetadata(row),
            };
            if (!row.target_content || row.target_content.trim().length === 0) {
              if (isBilingual) setWarningCount(warningCount + 1);
            } else {
              newRow.targetContent = row.target_content.trim();
              setBilingual(true);
            }

            return newRow;
          });
        const progressElement = file['previewElement'].querySelector('[data-dz-uploadprogress]');
        progressElement.style.width = '100%';
      },
    });
  };

  const onFileRemoved = () => {
    setFileParsed(null);
    setWarningCount(0);
    setBilingual(false);
  };

  const helperTooltip = (
    <>
      <p>You need to upload a .csv file in the following format: </p>
      Separator (one of):
      <ul>
        <li>\t</li>
        <li>|</li>
        <li>;</li>
        <li>RECORD_SEP (ASCII code 30)</li>
        <li>UNIT_SEP (ASCII code 31)</li>
      </ul>
      Headers:
      <ul>
        <li>
          <strong>source_content</strong>
        </li>
        <li>
          <strong>target_content</strong> if the file is bilingual and have specified the target
          language above
        </li>
        <li>{'metadata_{metadata name} for any metadata'}</li>
        <li>
          {
            'metadata_{metadata name}_lang:{language code} for metadata with language related values'
          }
        </li>
      </ul>
    </>
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Tooltip title={helperTooltip}>
        <TextWithIcon
          text='Content Upload'
          icon='fas fa-file-alt'
          outerClassName='mt-3 mb-3'
          innerClassName='text-center'
          cursor='help'
          size={3}
        />
      </Tooltip>
      {isSubmitting ? (
        <Progress
          animated
          style={{
            height: '34px',
            borderRadius: '10px',
            border: '1px solid rgba(209, 209, 209, 1))',
            backgroundColor: 'rgba(220,220,220,0.3)',
          }}
          className={'mb-3'}
          value={progressPercent}
        >
          {progressMessage}
        </Progress>
      ) : (
        <DropZone
          className={'mb-5'}
          onFileAdded={parseCSV}
          onFileRemoved={onFileRemoved}
          fileParsed={fileParsed}
          touched={contentsTouched}
        />
      )}
      {isBilingual && !file.targetLanguage && (
        <div className='alert alert-warning mt-2 mb-2' role='alert'>
          Found bilingual content but the file does not have a target language! Please select one.
        </div>
      )}
      {warningCount > 0 && (
        <div className='alert alert-warning mt-2 mb-2' role='alert'>
          Found {warningCount} segments that do not have 'target_content'
        </div>
      )}
      <Row>
        <Col md={6}>
          <Button
            type='submit'
            color='primary'
            size='lg'
            className='mr-2'
            disabled={
              !fileParsed ||
              isGettingProgress ||
              isSubmitting ||
              (isBilingual && !file.targetLanguage)
            }
          >
            Upload
          </Button>
          {isEditMode && (
            <Tooltip title='Download what has been uploaded for the file so far. Can choose json format or plain text.'>
              <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <Button
                  className='btn-block'
                  size='lg'
                  color='secondary'
                  onClick={onDownload}
                  disabled={isDownloading}
                >
                  {dropdownValue}
                </Button>
                <DropdownToggle caret color='secondary' disabled={isDownloading} />
                <DropdownMenu>
                  <DropdownItem header>Download Options</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={changeDropdownValue}>{DROPDOWN_VALUE_JSON}</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={changeDropdownValue}>{DROPDOWN_VALUE_CSV}</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={changeDropdownValue}>{DROPDOWN_VALUE_TEXT}</DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Tooltip>
          )}
        </Col>
        <Col md={6}>
          <ModalErrors
            buttonLabel='Errors'
            className='lg float-right'
            hideOnNoErrors={true}
            errors={errors}
            header='API Request Errors'
          />
        </Col>
      </Row>
    </Form>
  );
};

ContentForm.propTypes = {
  isEditMode: PropTypes.bool,
  file: PropTypes.object,
};
export default ContentForm;
