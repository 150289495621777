import { PAGES } from '../pages/index';
import { get, post, put } from '../http';

const updateUserDetails = async (paymentMethod = '', userId = '', body = null) => {
  return put(`/api/user/${userId}/payment-profile/${paymentMethod}`, body);
};

const createStripeOnBoard = async () => {
  return post(`/api/payment/stripe/onboard`, {
    refreshUrl: window.location.origin + PAGES.PROFILE_PAGE.path(),
    returnUrl: window.location.origin + PAGES.PROFILE_PAGE.path(),
  });
};

const getStripeAccount = async (account) => {
  return get(`/api/payment/stripe/account/${account}`);
};

const UserProfileController = { updateUserDetails, createStripeOnBoard, getStripeAccount };

export default UserProfileController;
