import React from 'react';
import PropTypes from 'prop-types';
// Styles
import './ProfileImageNameBioView.scss';

export const ProfileImageView = ({ firstName = null, lastName = null, onEdit = null }) => {
  return (
    <div className='col-sm-12'>
      <div className='col-sm-12 col-md-4 col-lg-3 float-left'>
        <div className='user-avatar rounded-circle' id={`${!firstName ? 'user-icon' : ''}`}>
          {firstName && lastName ? (
            <span id='username-initials' className='align-self-center'>
              {firstName?.[0]?.toUpperCase()}
              {lastName?.[0].toUpperCase()}
            </span>
          ) : null}
        </div>
      </div>
      <div className='col-sm-12 col-md-8 col-lg-9 float-left'>
        <div className='user-name-bio'>
          <span className='user-name'>
            {firstName} {lastName}
            <span className='edit-button' onClick={onEdit}></span>
          </span>
        </div>
      </div>
    </div>
  );
};
ProfileImageView.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
};
export default ProfileImageView;
