import { useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import TaskController from '../../../controllers/TaskController';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import ProjectStatisticsView from '../views/ProjectStatisticsView';
import TaskResultController from '../../../controllers/TaskResultController';

const ProjectStatisticsState = ({ project }) => {
  const { id } = useParams();
  const [tasks, setTasks] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [generatedTasks, setGeneratedTasks] = useState([]);
  const projectType = project.settings.primaryStageTaskResultsPolicy;

  const getTasks = async () => {
    await TaskResultController.getProjectTaskResults(id, { size: 1000000000 }).then(async (r) =>
      setTasks(r.content)
    );
  };

  const getGeneratedTasks = async () => {
    await TaskController.getAll({ projectId: id, pageSize: 1000000000 }).then((r) =>
      setGeneratedTasks(r.page)
    );
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([getTasks(), getGeneratedTasks()]).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <div className='tw-relative tw-h-40'>
          <Loader />
        </div>
      ) : (
        <>
          {tasks.length > 0 || generatedTasks.length > 0 ? (
            <ProjectStatisticsView
              tasks={tasks}
              generatedTasks={generatedTasks}
              projectType={projectType}
            />
          ) : (
            <div>
              <span>No data found.</span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ProjectStatisticsState;
