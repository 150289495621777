import React from 'react';

import IndeterminateCheckbox from '../IndeterminateCheckbox';

export const SelectRowCheckbox = ({ disabled, selected, onToggle }) => {
  return (
    <td>
      <IndeterminateCheckbox
        checked={selected}
        disabled={disabled}
        onChange={(value) => onToggle(value)}
      />
    </td>
  );
};

export const SelectHeaderCheckbox = ({ allSelected, someSelected, onToggle }) => {
  return (
    <th className='d-flex align-items-end justify-content-center'>
      <IndeterminateCheckbox
        checked={allSelected}
        indeterminate={someSelected}
        onChange={(value) => onToggle(value)}
      />
    </th>
  );
};
