// Actions types
export const TYPES = {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_SWITCH: 'USER_SWITCH',
};

export const ACTIONS = {
  userLogin: (session) => ({ type: TYPES.USER_LOGIN, session: session }),
  userLogout: () => ({ type: TYPES.USER_LOGOUT }),
  userSwitch: (session) => ({ type: TYPES.USER_SWITCH, session: session }),
};
