import { get, post, put } from '../http'
import Utils from '../utils/Utils'
import { objectToQueryString } from '../utils/Url'

const BASE_URL = '/api/referral-code';

const findAll = async () => {
  return get(BASE_URL);
};

const adminFindAllWithParams  = async (parameters, headers) => {
  return get(`/api/admin/referral-code?${objectToQueryString(parameters)}`, { headers: { ...headers } });
};

const findByCode = async (code) => {
  if (!code) {
    throw new Error('Invalid referral code (missing)');
  }

  return get(`/api/referral/${code}`);
};

const findByUserId = async (id) => {
  if (!id) {
    throw new Error('Invalid user id (missing)');
  }
  return get(`${BASE_URL}/user`);
};

const createReferee = async (refferalReferee) => {
  if (!refferalReferee) {
    throw new Error('Invalid ref code id or referee email(missing)');
  }
  return post(`/api/referral/referee`, refferalReferee);
};

const findAllCodeRefereesWithParams = async (refCodeId, parameters = null, headers = null) => {
  if (Utils.isNull(refCodeId)) {
    throw new Error('Invalid user code(missing)');
  }
  if (Utils.isNull(parameters.refereeSid)){
    return get(`${BASE_URL}/${refCodeId}/referees?refereeSid=&${objectToQueryString(
      parameters, { stringifyArrays: true })}`, { headers: { ...headers },
    });
  }
  else{
    return get(`${BASE_URL}/${refCodeId}/referees?${objectToQueryString(
      parameters, { stringifyArrays: true })}`, { headers: { ...headers },
    });
  }

};

const UserReferralCodeController = {
  findAll,
  adminFindAllWithParams,
  findByCode,
  findByUserId,
  findAllCodeRefereesWithParams,
  createReferee,
};
export default UserReferralCodeController;
