import Utils from '../../../utils/Utils';
import { useEffect, useState } from 'react';
import ErrorHandler from '../../../utils/ErrorHandler';
import ProjectTrackingView from '../views/ProjectTrackingView';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import TaskResultController from '../../../controllers/TaskResultController';

const ProjectTrackingState = () => {
  const { id } = useParams();
  const [tasks, setTasks] = useState([]);
  const [filters, setFilters] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [sorting, setSorting] = useState([
    {
      columnName: 'id',
      direction: 'desc',
    },
  ]);
  const [pagination, setPagination] = useState({
    page: 0,
    size: 25,
  });

  const getTaskResults = async (params) => {
    setLoading(true);
    try {
      TaskResultController.getProjectTaskResults(id, params).then((r) => {
        setLoading(false);
        setTasks(r.content);
        setTotalElements(r.totalElements);
      });
    } catch (e) {
      ErrorHandler.errorHandle(e);
      setLoading(false);
    }
  };

  const handleDeliverTask = async (taskResultID) => {
    try {
      TaskResultController.deliver(taskResultID).then((r) => {
        setTasks((prev) =>
          prev.map((t) =>
            t.id === taskResultID
              ? { ...t, taskResult: { ...t.taskResult, deliveryStatus: 'PENDING' } }
              : t
          )
        );
      });
    } catch (e) {
      ErrorHandler.errorHandle(e);
    }
  };

  const handleFilterChange = (field, value) => {
    setLoading(true);
    if (Utils.isNull(value) || value === '') {
      setFilters((prev) => {
        const obj = { ...prev };
        delete obj[field];
        return obj;
      });
    } else {
      setFilters((prev) => ({ ...prev, [field]: String(value).trim() }));
    }
    setPagination({ ...pagination, page: 0 });
  };

  const handleClearFilters = () => {
    setFilters({});
  };

  const handleSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPagination({ ...pagination, page: page });
  };

  const handlePageSizeChange = (size) => {
    setLoading(true);
    setPagination({ ...pagination, size: size });
  };

  useEffect(() => {
    let params = {};
    if (!Utils.isNull(pagination)) {
      params['page'] = pagination.page;
      params['size'] = pagination.size;
    }
    if (!Utils.isEmpty(sorting)) {
      params['sort'] = sorting[0].columnName + ',' + sorting[0].direction;
    }
    if (!Utils.isEmpty(filters)) {
      let f = {};
      if (filters['yearOfBirthFrom'] || filters['yearOfBirthTo']) {
        f = {
          ...filters,
          yearOfBirth: `${filters['yearOfBirthFrom'] ? filters['yearOfBirthFrom'] : ''},${
            filters['yearOfBirthTo'] ? filters['yearOfBirthTo'] : ''
          }`,
        };
        delete f['yearOfBirthFrom'];
        delete f['yearOfBirthTo'];
        params = { ...params, ...f };
      } else {
        params = { ...params, ...filters };
      }
    }

    if (
      filters['id']?.length > 0 ||
      filters['taskId']?.length > 0 ||
      filters['worker.sid']?.length > 0
    ) {
      const timer = setTimeout(() => {
        getTaskResults(params);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      getTaskResults(params);
    }
  }, [filters, sorting, pagination]);

  return (
    <ProjectTrackingView
      projectId={id}
      tasks={tasks}
      filters={filters}
      isLoading={isLoading}
      pagination={pagination}
      totalElements={totalElements}
      sorting={sorting}
      handleSorting={handleSorting}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      handleFilterChange={handleFilterChange}
      handleClearFilters={handleClearFilters}
      handleDeliverTask={handleDeliverTask}
    />
  );
};

export default ProjectTrackingState;
