import React from 'react';
import DOMObject from '../../components/DOMObject';

export default class Body extends DOMObject {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className={`row h-100 justify-content-center ${
          this.props?.wrapperClassName ? this.props.wrapperClassName : 'taus-content-wrap'
        }`}
        style={{ minHeight: '100vh' }}
      >
        <div className='col-12 mt-4' style={{ zIndex: 110 }}>{this.props.children}</div>
      </div>
    );
  }
}
