import { useEffect, useState } from 'react';
import deepEqual from 'fast-deep-equal';

import useUndoableState from '../../hooks/useUndoableState';
import AnnotationTargetSegmentHeader from './AnnotationTargetSegmentHeader';
import TokenBucket from './TokenBucket';

const AnnotationTargetSegment = ({
  segmentId,
  targetLanguage,
  reviewStatus,
  isLoading,
  data,
  onDataChanged,
  tagDefinitions,
  root,
  footer,
}) => {
  const { state, setState, index, lastIndex, goBack, goForward } = useUndoableState(data);

  useEffect(() => {
    if (!deepEqual(data, state)) {
      onDataChanged(segmentId, state);
    }
  }, [data, onDataChanged, segmentId, state]);

  const handleMerge = (sourceId, targetId, overrideTags = null) => {
    let originIndex = findIndexById(data, sourceId);
    let targetIndex = findIndexById(data, targetId);

    // We need to extract here because we (possibly) change the originIndex in the next expression
    const { id, tags: originTags } = data[originIndex];
    const { tags: targetTags, tokens: targetTokens } = data[targetIndex];

    // Slice method works from lowest to high index
    if (originIndex > targetIndex) {
      [originIndex, targetIndex] = [targetIndex, originIndex];
    }

    // if the target index contains more tokens, we must expand the grouping to all of the tokens
    targetIndex += data[targetIndex].tokens.length - 1;

    // Get flat tokens from all buckets
    const flatTokens = data.reduce((prev, newVal) => [...prev, ...newVal.tokens], []);

    // Origin 0 tag(s), Target  > 0 tags                     => Keep target tags
    // Origin 1 tag(s), Target >= 0 tags AND single token    => Keep origin tags
    // Origin 1 tag(s), Target  > 0 tags AND multiple tokens => Keep target tags
    // Origin 1 tag(s), Target == 0 tags AND multiple tokens => Keep origin tags
    let tags = originTags;
    if (originTags.length === 0 || (targetTags.length > 0 && targetTokens.length > 1)) {
      tags = targetTags;
    }
    if (overrideTags) {
      tags = overrideTags;
    }

    // Define buckets to be rebuilt based on old buckets and the newest one
    // New group gets the tags from the origin token
    const newBucket = {
      id,
      tokens: flatTokens.slice(originIndex, targetIndex + 1),
      tags,
    };
    const bucketsToRebuild = [
      ...data
        //If the new group is a subgroup of the existing one, don't rebuild the existing one
        .filter(
          (bucket) => bucket.tokens.length > 1 && !isSubGroup(newBucket.tokens, bucket.tokens)
        )
        .map(({ id, tags, tokens: currentTokens }) => ({
          id,
          tags,
          // If a token is contained inside new group, exclude it from the existing group
          tokens: currentTokens.filter((token) => !findTokenWithId(newBucket.tokens, token.id)),
        })),
      newBucket,
    ];

    setState(rebuildBucketsFromFlatTokens(data, flatTokens, bucketsToRebuild));
  };

  const handleSplit = (splittingId) => () => {
    const flatTokens = data.reduce((prev, newVal) => [...prev, ...newVal.tokens], []);

    // Don't rebuild the bucket that we are splitting
    const bucketsToRebuild = data.filter(
      (bucket) => bucket.tokens.length > 1 && bucket.id !== splittingId
    );

    // Rebuild the data from flat tokens taking into consideration the groups to rebuild
    setState(rebuildBucketsFromFlatTokens(data, flatTokens, bucketsToRebuild));
  };

  const handleAnnotateAll = (tags) => {
    handleMerge(data[0].id, data[data.length - 1].id, tags);
  };

  const handleClearAll = () => {
    // Get flat tokens from all buckets and set their tokens to an empty array
    const flatTokensWithNoTags = data
      .reduce((prev, newVal) => [...prev, ...newVal.tokens], [])
      .map((token) => ({ ...token, tags: [] }));

    const dataWithNoTags = data.map((bucket) => ({ ...bucket, tags: [] }));

    // Rebuild the data from flat tokens but with no buckets to rebuild
    setState(rebuildBucketsFromFlatTokens(dataWithNoTags, flatTokensWithNoTags, []));
  };

  const handleSetTagsForBucket = (bucketId, tags) => {
    setState(
      data.map((bucket) => {
        if (bucket.id === bucketId) {
          return { ...bucket, tags };
        }
        return bucket;
      })
    );
  };

  const [dragStartId, setDragStartId] = useState(null);
  const [dragEndId, setDragEndId] = useState(null);

  const handleDragStart = (id) => () => {
    setDragStartId(id);
  };
  const handleDragOver = (id) => (e) => {
    e.preventDefault();
    setDragEndId(id);
  };

  const handleDrop = (targetId) => () => {
    handleMerge(dragStartId, targetId);
    setDragStartId(null);
    setDragEndId(null);
  };

  let dragStartIndex = findIndexById(data, dragStartId);
  let dragEndIndex = findIndexById(data, dragEndId);

  if (dragStartIndex > dragEndIndex) {
    [dragStartIndex, dragEndIndex] = [dragEndIndex, dragStartIndex];
  }

  const dragSliceIds = data.slice(dragStartIndex, dragEndIndex + 1).map((bucket) => bucket.id);

  const canUndo = index > 0;
  const canRedo = index < lastIndex;
  const canClearAll = data.some((bucket) => bucket.tags.length !== 0 || bucket.tokens.length !== 1);

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded((prevExpanded) => !prevExpanded);

  return (
    <div className={`taus-target-segment col-12 ${isLoading && 'whirl sphere'}`}>
      <AnnotationTargetSegmentHeader
        key={new Date().getTime()}
        targetLanguage={targetLanguage}
        reviewStatus={reviewStatus}
        canClearAll={canClearAll}
        root={root}
        tagDefinitions={tagDefinitions}
        onAnnotateAll={(value) => handleAnnotateAll([value])}
        onClearAll={handleClearAll}
        canUndo={canUndo}
        onUndo={goBack}
        canRedo={canRedo}
        onRedo={goForward}
        isExpanded={isExpanded}
        toggleExpanded={toggleExpanded}
      />
      <div className='d-flex align-items-start flex-wrap border-bottom mb-1'>
        {data
          .filter(({ tokens }) => tokens.length > 0) // Don't display buckets with 0 tokens
          .map(({ id, tokens, tags }) => (
            <TokenBucket
              key={id}
              id={id}
              tokens={tokens}
              tags={tags}
              tagDefinitions={tagDefinitions}
              root={root}
              setTagsForBucket={handleSetTagsForBucket}
              onSplit={handleSplit(id)}
              onDragStart={handleDragStart}
              onDragOver={handleDragOver(id)}
              onDrop={handleDrop(id)}
              isHovered={dragSliceIds.includes(id)}
              isExpanded={isExpanded}
            />
          ))}
      </div>
      {footer}
    </div>
  );
};

export default AnnotationTargetSegment;

const isSubGroup = (array, fullArray) =>
  findTokenWithId(fullArray, array[0].id) && findTokenWithId(fullArray, array[array.length - 1].id);

const findTokenWithId = (tokens, id) => !!tokens.find((token) => token.id === id);

const findIndexById = (data, id) => data.findIndex((bucket) => bucket.id === id);

const isFirstElement = (token, tokens) => tokens.findIndex((t) => t.id === token.id) === 0;

const rebuildBucketsFromFlatTokens = (data, flatTokens, bucketsToRebuild) =>
  data.map(({ id, tags: currentTags }, i) => {
    const { tags, tokens } = getNewTagsAndTokensForBucket(
      bucketsToRebuild,
      flatTokens[i],
      currentTags
    );
    return { id, tags, tokens };
  });

const getNewTagsAndTokensForBucket = (groupsToRebuild, correspondingToken, currentTags) => {
  // No groups need to be rebuilt ->
  // The corresponding bucket has only one token
  let tags = currentTags;
  let tokens = [correspondingToken];

  const foundGroup = groupsToRebuild.find((group) =>
    findTokenWithId(group.tokens, correspondingToken.id)
  );

  // Is the current token a part of the group that needs to be rebuilt?
  if (foundGroup) {
    // If there is a grouping of 5 tokens
    // First bucket gets all tokens, and the next 4 are empty
    if (isFirstElement(correspondingToken, foundGroup.tokens)) {
      tokens = foundGroup.tokens;
      tags = [...foundGroup.tags];
    } else {
      tags = [];
      tokens = [];
    }
  }

  return { tags, tokens };
};
