import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';

const MultiLevelSelectBreadcrumbs = ({ breadcrumbs = [], onBack }) => (
  <div style={{ whiteSpace: 'nowrap' }}>
    <button disabled={breadcrumbs.length === 0} onClick={onBack} className='caret-btn mx-1 px-2'>
      <FontAwesomeIcon icon={faCaretLeft} />
    </button>
    <span className='mr-3'>{breadcrumbs.join(' / ')}</span>
  </div>
);

export default MultiLevelSelectBreadcrumbs;
