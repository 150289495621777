import { DropdownItem } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import Tooltip from '../../../../components/Tooltip';

import './MultiLevelSelect.scss';

const MultiLevelSelectOption = ({ label, value, color, onClick, onShowDescription }) => {
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onShowDescription(value);
  };

  return (
    <>
      <DropdownItem
        key={value}
        onClick={onClick}
        toggle={false}
        className='multi-level-select p-0 d-flex align-items-center'
        active={false}
      >
        <span className='ml-1 pl-1 flex-grow-1' style={{ borderLeft: `5px solid ${color}` }}>
          {label}
        </span>
        <span className='mx-2 d-flex' onClick={handleClick}>
          <Tooltip title='Click for more info about this tag'>
            <FontAwesomeIcon icon={faQuestionCircle} />
          </Tooltip>
        </span>
      </DropdownItem>
    </>
  );
};

export default MultiLevelSelectOption;
