import { get, post } from '../http';

const login = async (username, password) => {
  if (!username) {
    throw new Error('Invalid username (missing)');
  }
  if (!password) {
    throw new Error('Invalid password (missing)');
  }

  return post('/api/login', { username, password });
};

const logout = async () => {
  return post('/api/logout');
};

const getSession = async () => {
  return get('/api/session');
};

const authorize = async (provider) => {
  return await get('/api/login/authorize/' + provider)
}

const loginWith = async (provider, params) => {
  return await get('/api/login/' + provider  + params);
}


const SessionController = { login, logout, getSession, authorize, loginWith };

export default SessionController;
