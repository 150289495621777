import { format } from 'date-fns';
import React from 'react';
import { Button } from 'react-bootstrap';
import InputWithValidation from '../../../components/forms/InputWithValidation';

const SignatureBox = ({
  touched,
  error,
  signed,
  onChange,
  onBlur,
  signName,
  signDate,
  onSign,
  disabled,
}) => {
  return signed ? (
    <div className='form-group'>
      Signed by <b>{signName}</b> at{' '}
      <b>{format(signDate, 'dd.MM.yyyy. HH:mm')}</b>
    </div>
  ) : (
    <InputWithValidation
      id='sign_name'
      propertyName='sign_name'
      label='Signature'
      placeholder='Full name'
      defaultValue={signName}
      touched={touched}
      error={error}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      addons={
        <Button type='primary' onClick={onSign} disabled={disabled || !signName || signName.length===2}>
          Sign
        </Button>
      }
    />
  );
};

export default SignatureBox;
