import MultiLevelSelect from '../MultiLevelSelect';
import AnnotationDisplay from './AnnotationDisplay';

const TokenBucket = ({
  id,
  tags,
  tokens,
  setTagsForBucket,
  onSplit,
  tagDefinitions,
  root,
  onDragStart,
  onDragOver,
  onDrop,
  isHovered,
  isExpanded,
}) => {
  const onChange = (newTag) => {
    if (!tags.find((tag) => tagsAreEqual(tag, newTag))) {
      setTagsForBucket(id, [newTag, ...tags]);
    }
  };

  const removeValue = (tagToRemove) => {
    setTagsForBucket(
      id,
      tags.filter((tag) => !tagsAreEqual(tag, tagToRemove))
    );
  };

  const hasMultipleTokens = tokens.length > 1;

  return (
    <AnnotationDisplay
      isExpanded={isExpanded}
      annotations={tags}
      onRemoveValue={removeValue}
      hasMultipleTokens={hasMultipleTokens}
      onSplit={onSplit}
      onDragOver={onDragOver}
      onDrop={onDrop}
      fontSize='16px'
    >
      {tokens.map(({ id: tokenId, value }, i) => {
        const isLast = i === tokens.length - 1;
        const selectClasses = [
          'token',
          isHovered && !hasMultipleTokens && 'hovered',
          hasMultipleTokens && 'grouped',
          !hasMultipleTokens && 'border border-dark',
        ];

        const title = <div className={selectClasses.filter(Boolean).join(' ')}>{value}</div>;

        return (
          <div key={tokenId} className={isLast ? 'flex-grow-1' : ''}>
            <MultiLevelSelect
              fullWidth={isLast}
              key={`${tokenId}-${tags.length}`}
              optionsTree={tagDefinitions}
              root={root}
              onChange={onChange}
              title={title}
              onDragStart={onDragStart(id)}
              draggable={!hasMultipleTokens}
              fontSize='16px'
            />
          </div>
        );
      })}
    </AnnotationDisplay>
  );
};

export default TokenBucket;

const tagsAreEqual = (tags1 = [], tags2 = []) => {
  if (tags1.length === tags2.length) {
    return tags1.every((element, index) => {
      if (element === tags2[index]) {
        return true;
      }
      return false;
    });
  }
  return false;
};
