import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import SelectWithValidation from '../../components/forms/SelectWithValidation';
import { Button, Col, Form, Row } from 'reactstrap';
import InputWithValidation from '../../components/forms/InputWithValidation';
import ModalErrors from '../project/components/ModalErrors';
import TextWithIcon from '../project/components/TextWithIcon';
import PropTypes from 'prop-types';
import FileController from '../../controllers/FileController';
import Notifier from '../../components/Notifier';
import Utils from '../../utils/Utils';
import Tooltip from '../../components/Tooltip';
import ErrorHandler from '../../utils/ErrorHandler';
import CheckboxWithValidation from '../../components/forms/CheckboxWithValidation';

const enhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('File name is required!')
      .nullable(),
    sourceLanguageId: Yup.number().required('Source language is required!').nullable(),
  }),

  mapPropsToValues: (props) => {
    return {
      apiErrors: [],
      name: props?.file?.name ?? '',
      sourceLanguageId: props?.file?.sourceLanguage?.id ?? null,
      targetLanguageId: props?.file?.targetLanguage?.id ?? null,
      tokenizationStatus: !!props?.file?.tokenizationStatus,
    };
  },

  handleSubmit: (values, { props, resetForm, setSubmitting, setFieldValue, setFieldTouched }) => {
    setSubmitting(true);
    const body = {
      name: values.name,
      sourceLanguageId: values.sourceLanguageId,
      targetLanguageId: values.targetLanguageId,
    };

    if (!props.isEditMode) {
      FileController.create(body)
        .then((response) => {
          Notifier.success('File created!');
          resetForm();
          if (props.onFileCreated) {
            props.onFileCreated(response);
          }
        })
        .catch((error) => {
          setFieldValue('apiErrors', [...values.apiErrors, error.toString()]);
          ErrorHandler.errorHandle(error);
        })
        .finally(() => setSubmitting(false));
    } else {
      body.tokenizationStatus = values.tokenizationStatus ? 'ACKNOWLEDGED' : null;

      FileController.update(props?.file?.id, body)
        .then((result) => {
          Notifier.success('File updated!');
          if (props.onFileUpdated) {
            props.onFileUpdated(result);
          }
          // If we reset form the old values are restored because onFileUpdate calls setState (async)
          setFieldTouched('name', false);
          setFieldTouched('sourceLanguageId', false);
          setFieldTouched('targetLanguageId', false);
        })
        .catch((error) => {
          setFieldValue('apiErrors', [...values.apiErrors, error.toString()]);
          ErrorHandler.errorHandle(error);
        })
        .finally(() => setSubmitting(false));
    }
  },

  displayName: 'File Form',
});

class FileForm extends Component {
  render() {
    // Languages options and default value
    let languageOptions = Utils.mapToOption(this.props.languages);
    let sourceLanguageDefaultOption = Utils.getDefaultOption(
      languageOptions,
      this.props.values.sourceLanguageId
    );
    let targetLanguageDefaultOption = Utils.getDefaultOption(
      languageOptions,
      this.props.values.targetLanguageId
    );

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <Tooltip
          title={
            !this.props.isEditMode
              ? "Select the type of the file you want to create, give it a name and select the file's language"
              : "Change a file's name and/or language"
          }
        >
          <TextWithIcon
            text={'Basic Attributes'}
            icon={'fas fa-globe'}
            outerClassName={'mb-3'}
            innerClassName={'text-center'}
            cursor={'help'}
            size={3}
          />
        </Tooltip>
        <Row>
          <Col md={4}>
            <InputWithValidation
              propertyName={'name'}
              label={'Name'}
              placeholder={'Enter a name...'}
              helpText={"The files's name as it will appear in the file lists (3-50 chars)"}
              defaultValue={this.props.values.name}
              touched={this.props.touched.name}
              error={this.props.errors.name}
              onChange={this.props.setFieldValue}
              onBlur={this.props.setFieldTouched}
            />
          </Col>
          <Col md={4}>
            <SelectWithValidation
              propertyName={'sourceLanguageId'}
              label={'Source language'}
              helpText={"The file's source language"}
              defaultOption={sourceLanguageDefaultOption}
              options={languageOptions}
              error={this.props.errors.sourceLanguageId}
              isLoading={this.props.languagesLoading}
              touched={this.props.touched.sourceLanguageId}
              onChange={this.props.setFieldValue}
              onBlur={this.props.setFieldTouched}
            />
          </Col>
          <Col md={4}>
            <SelectWithValidation
              propertyName={'targetLanguageId'}
              label={'Target language'}
              helpText={'Optional target language for bilingual'}
              defaultOption={targetLanguageDefaultOption}
              options={languageOptions}
              error={this.props.errors.targetLanguageId}
              isLoading={this.props.languagesLoading}
              touched={this.props.touched.targetLanguageId}
              onChange={this.props.setFieldValue}
              onBlur={this.props.setFieldTouched}
            />
          </Col>
        </Row>
        <Row className={'ml-1'}>
          <CheckboxWithValidation
            id='tokenizationStatus'
            propertyName='tokenizationStatus'
            label='Tokenize content'
            helpText='Check this if you want the file contents to get tokenized. You must first upload content for the file.
              Once checked, it will no longer be possible to upload new content for the file'
            defaultValue={!!this.props.values.tokenizationStatus}
            touched={this.props.touched.tokenizationStatus}
            error={this.props.errors.tokenizationStatus}
            onChange={this.props.setFieldValue}
            onBlur={this.props.setFieldTouched}
            disabled={
              !this.props.isEditMode ||
              (this.props.file &&
                (this.props.file?.segmentsNum === 0 || !!this.props.file?.tokenizationStatus))
            }
          />
        </Row>
        <Row>
          <Col md={6}>
            <Button
              type='submit'
              color='primary'
              size='lg'
              className='mr-2'
              disabled={this.props.isSubmitting || !this.props.dirty}
            >
              {' '}
              {this.props.isEditMode ? 'Update' : 'Create'}{' '}
            </Button>
            <Button
              color='secondary'
              size='lg'
              onClick={this.props.handleReset}
              disabled={!this.props.dirty || this.props.isSubmitting}
            >
              {' '}
              Reset{' '}
            </Button>
          </Col>
          <Col md={6}>
            <ModalErrors
              buttonLabel='Errors'
              className={'lg float-right'}
              hideOnNoErrors={true}
              errors={this.props.values.apiErrors}
              header='API Request Errors'
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

const EnhancedFileForm = enhancer(FileForm);
EnhancedFileForm.propTypes = {
  isEditMode: PropTypes.bool,
  file: PropTypes.object,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  languagesLoading: PropTypes.bool,
  onFileCreated: PropTypes.func,
  onFileUpdated: PropTypes.func,
};

export default EnhancedFileForm;
