import { Bar } from 'react-chartjs-2';
import { Tooltip } from '@mui/material';
import Utils from '../../../utils/Utils';
import { useEffect, useRef, useState } from 'react';
import TaskResult from '../../../models/TaskResult';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chart as ChartJS, CategoryScale, registerables } from 'chart.js';
ChartJS.register(CategoryScale, ...registerables);

const UserGroupOverviewBarChart = ({ tasks, handleDownload }) => {
  const chartRef = useRef(null);
  const [userGroups, setUserGroups] = useState([]);
  const [totalCounts, setTotalCounts] = useState();
  const [barData, setBarData] = useState({ labels: [], datasets: [] });

  const barOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      title: {
        display: true,
        text: '1b. Total PRIMARY stage task result submissions per user group',
      },
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = [];
            const datasetLabel = String(context.dataset.label).toUpperCase();
            if (datasetLabel === 'TOTAL') {
              const totalTasks = tasks.filter((t) => t.stage === 'PRIMARY').length;
              const userGroupName = context.label;
              const userGroupTotal = context.raw.y;
              const percentageOfTotal = Number((userGroupTotal / totalTasks) * 100).toFixed(2);

              label.push(
                `Total PRIMARY task results submitted by ${userGroupName}: ${userGroupTotal}`
              );
              label.push(
                `% of total PRIMARY task results submitted by ${userGroupName}: ${percentageOfTotal}%`
              );
            } else {
              const userGroupName = context.label;
              const userGroupTotal = totalCounts[userGroupName];
              const userGroupDeliveredTotal = context.raw.y;
              const percentageOfTotal = Number(
                (userGroupDeliveredTotal / userGroupTotal) * 100
              ).toFixed(2);

              label.push(
                `Total PRIMARY task results submitted by ${userGroupName}: ${userGroupTotal}`
              );
              label.push(
                `Total PRIMARY task results delivered by ${userGroupName}: ${userGroupDeliveredTotal}`
              );
              label.push(
                `% of total PRIMARY task results delivered by ${userGroupName}: ${percentageOfTotal}%`
              );
            }

            return label;
          },
        },
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
        formatter: (value, context) => {
          const datasetLabel = String(context.dataset.label).toUpperCase();

          // if (datasetLabel === 'TOTAL') {
          //   const totalTasks = tasks.filter((t) => t.stage === 'PRIMARY').length;
          //   const totalDataset = value.y;
          //   const percentageOfTotal = Number((totalDataset / totalTasks) * 100).toFixed(2);
          //   return `${totalDataset} (${percentageOfTotal}%)`;
          // } else {
          //   const totalDataset = value.y;
          //   const userGroupName = Object.keys(totalCounts)[context.dataIndex];
          //   const userGroupTotal = totalCounts[userGroupName];
          //   const percentageOfTotal = Number((totalDataset / userGroupTotal) * 100).toFixed(2);
          //   return `${totalDataset} (${percentageOfTotal}%)`;
          // }
          return value.y;
        },
      },
    },
  };

  const labels = userGroups;

  useEffect(() => {
    const primaryTasks = tasks.filter((t) => t.stage === 'PRIMARY');
    const total = primaryTasks.flatMap((i) => i.userGroups.flatMap((j) => j.name));
    const delivered = primaryTasks
      .filter((t) => t.taskResult.status === TaskResult.STATUS.DELIVERED)
      .flatMap((i) => i.userGroups.flatMap((j) => j.name));

    const distinctUGs = total.filter(
      (value, index, current_value) => current_value.indexOf(value) === index
    );

    setUserGroups(distinctUGs);

    let totalCounts = total.reduce((acc, val) => {
      acc[val] = acc[val] === undefined ? 1 : (acc[val] += 1);
      return acc;
    }, {});

    let deliveredCounts = delivered.reduce((acc, val) => {
      acc[val] = acc[val] === undefined ? 1 : (acc[val] += 1);
      return acc;
    }, {});

    distinctUGs.forEach((key) => {
      if (!Object.keys(totalCounts).includes(key)) {
        totalCounts = { ...totalCounts, [key]: 0 };
      }
      if (!Object.keys(deliveredCounts).includes(key)) {
        deliveredCounts = { ...deliveredCounts, [key]: 0 };
      }
    });

    setTotalCounts(totalCounts);

    const datasets = {
      total: totalCounts,
      delivered: deliveredCounts,
    };

    setBarData({
      labels: labels,
      datasets: Object.keys(datasets)?.map((set) => {
        return {
          label: Utils.textFirstOnlyUpper(set),
          data: [
            ...Object.keys(datasets[set]).flatMap((key) => ({ x: key, y: datasets[set][key] })),
          ],
          backgroundColor: String(set).toUpperCase() === 'TOTAL' ? '#00d41999' : '#FBEC5D99',
          borderColor: String(set).toUpperCase() === 'TOTAL' ? '#00d419' : '#FBEC5D',
          borderWidth: 0.5,
          minBarLength: 1,
        };
      }),
    });
  }, []);

  return (
    <div className='tw-relative'>
      <div className='tw-absolute tw-right-0 tw-top-0 tw-rounded tw-bg-blue-400 tw-w-7 tw-h-7 tw-flex tw-items-center tw-justify-center tw-cursor-pointer'>
        <Tooltip title='Download chart as image'>
          <FontAwesomeIcon
            icon={faDownload}
            size='lg'
            onClick={() => handleDownload('UsergroupOverviewBarChart', chartRef)}
            className='tw-text-white'
          />
        </Tooltip>
      </div>
      <Bar data={barData} options={barOptions} ref={chartRef} />
    </div>
  );
};

export default UserGroupOverviewBarChart;
