import React, { useState } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import SelectWithValidation from '../../../components/forms/SelectWithValidation';
import Notifier from '../../../components/Notifier';
import PROJECT from '../../../models/Project';
import TASK from '../../../models/TaskResult';

const OPTIONS = {
  policy: Object.values(PROJECT.SETTINGS.PAYMENTS.POLICY).map((obj) => ({
    value: obj.sid,
    label: obj.name,
  })),
  minPayableTaskEvaluationStatus: [
    { value: TASK.EVALUATION_STATUS.NOT_RISKY, label: 'Not Risky' },
    { value: TASK.EVALUATION_STATUS.PASSED, label: 'Passed' },
  ],
};

const formikOptions = {
  validationSchema: Yup.object().shape({
    policy: Yup.string().ensure().required('Required! Please choose an option!'),
    minPayableTaskEvaluationStatus: Yup.string().when(['paymentsPolicy'], {
      is: PROJECT.SETTINGS.PAYMENTS.POLICY.AUTO.sid,
      then: Yup.string().ensure().required('Required! Please choose an option!'),
    }),
  }),
  mapPropsToValues: (props) => {
    return {
      minPayableTaskEvaluationStatus:
        props?.paymentsMinPayableTaskEvaluationStatus ?? TASK.EVALUATION_STATUS.PASSED,
      policy: props?.paymentsPolicy ?? PROJECT.SETTINGS.PAYMENTS.POLICY.MANUAL.sid,
    };
  },
};

const FormView = ({
  paymentsPolicy = null,
  paymentsMinPayableTaskEvaluationStatus = null,
  onSaveButtonClick = null,
  onCancelButtonClick = null,
  ...props
}) => {
  let [isManual, setManual] = useState(
    props.values?.policy === PROJECT.SETTINGS.PAYMENTS.POLICY.MANUAL.sid
  );
  const policyValue = OPTIONS.policy?.find((obj) => obj.value === props.values?.policy);
  const minPayableTaskEvaluationStatusValue = OPTIONS.minPayableTaskEvaluationStatus?.find(
    (obj) => obj.value === props.values?.minPayableTaskEvaluationStatus
  );

  const onPaymentPolicyChanged = (field, value) => {
    props.setFieldValue('policy', value);
    setManual(value === PROJECT.SETTINGS.PAYMENTS.POLICY.MANUAL.sid);
  };

  const onSubmit = () => {
    try {
      props.setSubmitting(true);
      props.validateForm().then((test) => {
        if (props.isValid) {
          if (!props?.dirty && !!onSaveButtonClick) onSaveButtonClick(null);
          else {
            let data = {
              paymentsPolicy: props?.values?.policy ?? null,
              paymentsMinPayableTaskEvaluationStatus:
                props?.values?.minPayableTaskEvaluationStatus ?? null,
            };
            if (!!onSaveButtonClick) onSaveButtonClick(data);
          }
        } else {
          props.setSubmitting(false);
          Notifier.error(Object.values(props?.errors)?.join('\n'));
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <form>
      <div className='row'>
        <div className='col'>
          <div className='card card-default'>
            <div className='card-body'>
              <SelectWithValidation
                propertyName={'policy'}
                label={'Policy'}
                helpText={'If set to manual no payments will get generated in HLP side whatsoever.'}
                defaultOption={policyValue}
                options={OPTIONS.policy}
                error={props?.errors?.policy}
                touched={props?.touched?.policy}
                onChange={onPaymentPolicyChanged}
                onBlur={props?.setFieldTouched}
              />
              <SelectWithValidation
                isDisabled={isManual}
                propertyName={'minPayableTaskEvaluationStatus'}
                label={'Minimum Payable Task Evaluation Status'}
                helpText={
                  "The minimum evaluation status a task should have in order for it's credits to be accumulated in a payment"
                }
                defaultOption={minPayableTaskEvaluationStatusValue}
                options={OPTIONS.minPayableTaskEvaluationStatus}
                error={props?.errors?.minPayableTaskEvaluationStatus}
                touched={props?.touched?.minPayableTaskEvaluationStatus}
                onChange={props?.setFieldValue}
                onBlur={props?.setFieldTouched}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row justify-content-end mx-0'>
        <button
          key='cancel'
          type='button'
          className='float-right btn btn-outline-secondary mr-2'
          onClick={onCancelButtonClick}
        >
          Cancel
        </button>
        <button
          key='save'
          type='button'
          className='float-right btn btn-outline-primary'
          onClick={onSubmit}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default withFormik(formikOptions)(FormView);
