// External libs
import React from 'react';
import { withRouter } from 'react-router-dom';
import { CardBody, ButtonGroup } from 'reactstrap';
import $ from 'jquery';

import Utils from '../../utils/Utils';
import DOMObject from '../../components/DOMObject';
import TaskController from '../../controllers/TaskController';
import { PAGES } from '../index';
import ApiException from '../../models/ApiException';
import Notifier from '../../components/Notifier';
import DataTables from '../../utils/DataTables';
import Datatable from '../../components/datatable/DatatableAjax';
import { Label } from '../../components/parameters/ParameterLabel';
import { Select } from '../../components/parameters/SelectParameter';
import Tooltip from '../../components/Tooltip';
import { connect } from 'react-redux';
import { ACTIONS } from '../../redux/actions/pages';
import { objectToQueryString, parseQueryString } from '../../utils/Url';
import API_STORAGE from '../../storage/ApiStorage';
import TASK from '../../models/Task';
import VisibleColumnsSelect from '../../components/tables/VisibleColumnsSelect';

// Component
class UserTasksAvailable extends DOMObject {
  constructor(props) {
    super(props);

    this.datatable = null;
    this.state = this.loadUserPreferences();

    try {
      const { projectId, requirementTypeId } = this.props.match.params;
      this.state.projectId = requirementTypeId ?? projectId;
      this.state.filters = this.getUrlFilters();
      if (!Utils.isEmpty(this.state.filters)) {
        this.savePageState();
      } else {
        this.setProps({ filters: this.getPropsFilters() });
      }
    } catch (e) {
      this.errorHandler(e);
    }
  }

  static defaultProps = {
    emptyTableMessage:
      'Either there are no available tasks or there are already claimed tasks still in progress (see My Tasks pages)',
    tableColumnsVisible: [
      'claim',
      'typeName',
      'words',
      'sourceLanguageCode',
      'targetLanguageCode',
      'projectName',
      'id',
      'segments',
      'credits',
    ],
    tableColumnsVisibilityPriority: [
      'claim',
      'typeName',
      'words',
      'id',
      'projectName',
      'sourceLanguageCode',
      'targetLanguageCode',
      'segments',
      'credits',
    ],
    tableColumnsFixed: ['claim'],
    tableSort: [['id', 'asc']],
  };

  PAGE_SID = Utils.define(PAGES.AVAILABLE_TASKS_PAGE.sid(), 'AVAILABLE_TASK_PAGE');

  state = {
    projectId: null,
    filters: {},
    tableColumnsVisible: UserTasksAvailable.defaultProps.tableColumnsVisible,
  };

  filters = {
    type: {
      validate: (value = null) => {
        if (!this.filters.type.isValid(value)) {
          return null;
        }
        if (Array.isArray(value)) {
          return Utils.isEmpty(value) ? null : value.map((obj) => obj.trim().toUpperCase());
        } else {
          return Utils.isNull(value) ? null : value.trim().toUpperCase();
        }
      },
      isValid: (value = null) => {
        try {
          if (Array.isArray(value)) {
            return value.every((obj) =>
              Object.values(TASK.TYPE)
                .map((obj) => obj.sid)
                .includes(obj.trim().toUpperCase())
            );
          } else {
            return Utils.isNull(value)
              ? true
              : Object.values(TASK.TYPE)
                  .map((obj) => obj.sid)
                  .includes(value.trim().toUpperCase());
          }
        } catch (e) {
          return false;
        }
      },
    },
  };

  userPreferences = {
    tableColumnsVisible: [],
  };

  // --------------------------------------------------------------------------------------
  // ---------------------------------- ReactJS Methods -----------------------------------
  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    try {
      let newFilters = {};
      let oldFilters = {};
      Object.keys(this?.filters).forEach((key) => {
        newFilters[key] = this.filters?.[key]?.validate(this.state?.filters?.[key]);
        oldFilters[key] = this.filters?.[key]?.validate(prevState?.filters?.[key]);
      });
      let dataTableReload = Object.keys(this?.filters).some(
        (key) =>
          JSON.stringify(Utils.define(oldFilters[key])) !==
          JSON.stringify(Utils.define(newFilters[key]))
      );
      if (dataTableReload === true && !Utils.isNull(this.datatable)) {
        this.datatable.table.draw();
      }

      if (this.props?.location?.search !== prevProps?.location?.search) {
        this.setState({ filters: this.getUrlFilters() });
      }

      if (this.state.tableColumnsVisible !== prevState.tableColumnsVisible) {
        this.saveUserPreferences();
      }
      this.savePageState();
    } catch (e) {
      this.errorHandler(e);
    }
  }

  render() {
    let tableProps = {
      ref: (ref) => {
        this.datatable = ref;
      },
      data: this.state.tasks,
      tableProps: this.dataTableProps(),
    };

    let filterIsEmpty = Object.values(this.state?.filters).every((obj) => {
      return Utils.isEmpty(obj);
    });

    return (
      <CardBody className='col-12 p-0 mt-2 bg-white'>
        <CardBody className='col-12 b'>
          <CardBody className='col-12 row p-0 mx-0 justify-content-between'>
            <div className='col-xs-12 col-md-9 col-xl-10 px-0 pt-2'>
              <h4 className='col-12'>
                Filters
                {filterIsEmpty === true ? null : (
                  <Tooltip title='Clear Filters'>
                    <button
                      id='clearFilters'
                      className='btn btn-secondary border-0 p-0 ml-3'
                      onClick={this.clearFiltersButtonClicked.bind(this)}
                    >
                      <em className='far fa-trash-alt' style={{ fontSize: '17px' }} />
                    </button>
                  </Tooltip>
                )}
              </h4>
              <div className='col-12 row mx-0 px-0'>
                <this.TaskTypeFilter />
              </div>
            </div>
            <ButtonGroup className='col-xs-12 col-md-3 col-xl-2 btn-group justify-content-end pt-2'>
              <VisibleColumnsSelect
                tableColumnsOptions={this.dataTableProps().columns.filter(
                  (column) => column.name !== 'claim'
                )}
                visibleColumns={this.state.tableColumnsVisible}
                tableVisibleColumnsSelectChanged={this.tableVisibleColumnsSelectChanged.bind(this)}
              />
            </ButtonGroup>
          </CardBody>
          <Datatable {...tableProps} className='col-12 px-0 taus-datatable pt-2 whirl sphere' />
        </CardBody>
      </CardBody>
    );
  }

  TaskTypeFilter = () => {
    let props = {
      options: [
        { value: TASK.TYPE.REVIEW.sid, name: TASK.TYPE.REVIEW.name },
        { value: TASK.TYPE.TRANSLATION.sid, name: TASK.TYPE.TRANSLATION.name },
        { value: TASK.TYPE.MSI.sid, name: TASK.TYPE.MSI.name },
        { value: TASK.TYPE.POST_EDITING.sid, name: TASK.TYPE.POST_EDITING.name },
        { value: TASK.TYPE.SPEECH_RECORDING.sid, name: TASK.TYPE.SPEECH_RECORDING.name },
        {
          value: TASK.TYPE.SPEECH_RECORDING_REVIEW.sid,
          name: TASK.TYPE.SPEECH_RECORDING_REVIEW.name,
        },
        { value: TASK.TYPE.TEXT_ANNOTATION.sid, name: TASK.TYPE.TEXT_ANNOTATION.name },
        {
          value: TASK.TYPE.TEXT_ANNOTATION_REVIEW.sid,
          name: TASK.TYPE.TEXT_ANNOTATION_REVIEW.name,
        },
      ],
      defaultValue: Utils.defineToArray(this.state.filters?.type, []),
      onChange: this.typeFilterChanged.bind(this),
      typeOptions: { placeholder: 'Task Type', isMulti: false },
    };
    return (
      <Label
        key={Utils.define(this.state.filters?.type, '')}
        label='Type:'
        style={{ width: '250px' }}
        className='justify-content-start pt-1'
      >
        <Select className='col px-0' {...props} />
      </Label>
    );
  };

  // --------------------------------------------------------------------------------------
  // ---------------------------------- Properties Methods --------------------------------
  // --------------------------------------------------------------------------------------
  dataTableProps() {
    const projectId = this.state?.projectId;

    let getVisiblePriority = (name) => {
      let index = this.props.tableColumnsVisibilityPriority.indexOf(name);
      return index < 0 ? 1000 : index + 1;
    };
    let getVisible = (name) => this.state.tableColumnsVisible.includes(name);

    let thisObject = this;
    let options = {
      responsive: { details: { type: 'none', target: 'tr' } },
      paging: true,
      pageLength: 25,
      ordering: true,
      autoWidth: true,
      scrollX: false,
      order: [[0, 'asc']],
      rowId: (row) => {
        return row.id;
      },
      columns: [
        {
          name: 'id',
          title: 'ID',
          orderable: true,
          className: 'text-center',
          responsivePriority: getVisiblePriority('id'),
          visible: getVisible('id'),
          data: (row, type, set, meta) => {
            return Utils.define(row.id, '');
          },
        },
        {
          name: 'typeName',
          title: 'Type',
          orderable: true,
          className: '',
          responsivePriority: getVisiblePriority('typeName'),
          visible: getVisible('typeName'),
          data: (row, type, set, meta) => row?.type?.name ?? '',
        },
        {
          name: 'projectName',
          title: 'Project',
          orderable: true,
          className: '',
          responsivePriority: getVisiblePriority('projectName'),
          visible: getVisible('projectName'),
          data: (row, type, set, meta) => {
            try {
              return Utils.define(row.project.name, '');
            } catch (e) {
              return '';
            }
          },
        },
        {
          name: 'sourceLanguageCode',
          title: 'Source Language',
          orderable: true,
          className: 'text-center',
          responsivePriority: getVisiblePriority('sourceLanguageCode'),
          visible: getVisible('sourceLanguageCode'),
          data: (row, type, set, meta) => row?.sourceLanguage?.code ?? '',
        },
        {
          name: 'targetLanguageCode',
          title: 'Target Language',
          orderable: true,
          className: 'text-center',
          responsivePriority: getVisiblePriority('targetLanguageCode'),
          visible: getVisible('targetLanguageCode'),
          data: (row, type, set, meta) => {
            try {
              return Utils.define(row?.targetLanguage?.code, '');
            } catch (e) {
              return '';
            }
          },
        },
        {
          name: 'segments',
          title: 'Segments',
          orderable: false,
          className: 'text-center',
          responsivePriority: getVisiblePriority('segments'),
          visible: getVisible('segments'),
          data: (row, type, set, meta) => {
            try {
              return Utils.define(row.inputSegmentNum, '');
            } catch (e) {
              return '';
            }
          },
        },
        {
          name: 'credits',
          title: 'Credits',
          orderable: true,
          className: 'text-center',
          responsivePriority: getVisiblePriority('credits'),
          visible: getVisible('credits'),
          data: (row, type, set, meta) => {
            try {
              return Utils.define(row.credits, '');
            } catch (e) {
              return '';
            }
          },
        },
        {
          name: 'claim',
          orderable: false,
          width: '100px',
          visible: true, // set to 'true' directly since we always need this column to get displayed
          responsivePriority: getVisiblePriority('claim'),
          className: 'text-right',
          data: (row, type, set, meta) => {
            if (type !== 'display') return '';
            return '<button class="task-claim-button btn btn-primary py-2">Claim</button>';
          },
        },
      ],
      language: {
        emptyTable: Utils.define(thisObject.props.emptyTableMessage, ''),
        zeroRecords: Utils.define(thisObject.props.emptyTableMessage, ''),
        lengthMenu: 'Tasks per pages :&nbsp;&nbsp; _MENU_',
        info: '&nbsp;&nbsp;_START_-_END_ of _TOTAL_',
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        select: { rows: { _: '' } },
      },
      rowCallback: (row, data, displayNum, displayIndex, dataIndex) => {
        try {
          // Init the task claim buttons
          $(row).find('.task-claim-button').off('click');
          $(row)
            .find('.task-claim-button')
            .on('click', thisObject.taskClaimButtonClicked.bind(thisObject, row.id, projectId));
          // Set classes for hidden columns
          if (!Utils.isNull(thisObject.table)) {
            if (thisObject.table.getScreenHiddenColumns().length > 0) {
              row.classList.add('taus-text-underline');
              row.classList.add('taus-pointer');
            } else {
              row.classList.remove('taus-text-underline');
              row.classList.remove('taus-pointer');
            }
          }
        } catch (e) {
          this.errorHandler(e);
        }
      },
      serverSide: true,
      ajax: function (data, callback, settings) {
        let filters = Datatable.getAjaxParameters(data);
        filters = { ...filters, projectId: projectId };
        filters = { ...filters, ...thisObject.filtersMapping() };
        thisObject.setBusy(true, '.taus-datatable');
        thisObject
          .getTasks(filters)
          .then((tasks) => {
            callback({
              draw: data.draw,
              recordsFiltered: tasks.totalSize,
              recordsTotal: tasks.totalSize,
              data: tasks.page,
            });
          })
          .catch((e) => {
            thisObject.errorHandler(e, true);
          })
          .finally(() => {
            thisObject.setBusy(false, '.taus-datatable');
          });
      },
    };

    if (Array.isArray(this.props.tableSort))
      options = DataTables.setOptionsOrderByName(options, this.props.tableSort);
    return options;
  }

  // --------------------------------------------------------------------------
  // ------------------------------ User Preferences --------------------------
  // --------------------------------------------------------------------------
  saveUserPreferences() {
    try {
      let updatedKeys = Object.keys(this.userPreferences).filter(
        (key) => this.userPreferences[key] !== this.state[key]
      );
      if (updatedKeys?.length > 0) {
        updatedKeys.forEach((key) => {
          this.userPreferences[key] = this.state[key];
        });
        API_STORAGE.USER_PREFERENCES.add({ [this.PAGE_SID]: this.userPreferences });
      }
    } catch (e) {
      this.errorHandler(e);
    }
  }

  loadUserPreferences() {
    try {
      let prefs = API_STORAGE.USER_PREFERENCES.read()?.[this.PAGE_SID];
      return Utils.isEmpty(prefs) ? this.state : { ...this.state, ...prefs };
    } catch (e) {
      this.errorHandler(e);
    }
  }

  // ---------------------------------------------------------------------
  // ----------------------------- Listeners -----------------------------
  // ---------------------------------------------------------------------
  typeFilterChanged(value) {
    let filter = this.filters.type;
    if (Utils.isNull(filter)) {
      return;
    }

    let filters = { ...this.state.filters };
    if (Utils.isEmpty(value)) {
      delete filters.type;
    } else {
      filters.type = value;
    }

    this.setProps({ filters: filters });
  }

  clearFiltersButtonClicked() {
    this.setProps({ filters: {} });
  }

  filtersMapping() {
    let filters = {};
    if (!Utils.isEmpty(this.state.filters.type)) filters.typeSid = this.state.filters.type;
    return filters;
  }

  tableVisibleColumnsSelectChanged(value) {
    try {
      this.setState({ tableColumnsVisible: value });
      this.datatable.setUserVisibleColumns(this.props.tableColumnsFixed.concat(value));
    } catch (e) {
      this.errorHandler(e, 'Table columns update failed.');
    }
  }

  taskClaimButtonClicked(rowId, projectId) {
    try {
      this.setBusy(true);
      this.userClaimTask(rowId)
        .then((task) => {
          this.props.history.push(PAGES.TASK_RESULT_PAGE.path(task.id), {
            projectId: projectId,
            task: task,
            back: this.props.location,
          });
        })
        .catch((e) => {
          if (e.status === 403 || e.status === 405) {
            this.errorHandler(
              e,
              'The task you just selected has been already claimed by another user'
            );
            this.datatable.table.draw();
          } else {
            this.errorHandler(e, true);
          }
        })
        .finally(() => {
          this.setBusy(false);
        });
    } catch (e) {
      this.errorHandler(e, true);
    }
  }

  // ---------------------------------------------------------------------
  // ----------------------------- Methods -------------------------------
  // ---------------------------------------------------------------------
  userClaimTask(id) {
    return TaskController.request(id);
  }

  getTasks(filters = {}) {
    return TaskController.getAvailable(filters);
  }

  getPropsFilters(props = null) {
    let filters = {};
    try {
      props = Utils.isNull(props) ? this.props : props;
      Object.keys(Utils.define(props?.filters, {})).forEach((key) => {
        if (!Utils.isEmpty(this.filters[key])) {
          filters[key] = props.filters[key];
        }
      });
    } catch (e) {
      this.errorHandler(e);
    }
    return filters;
  }

  getUrlFilters(location = null) {
    try {
      location = Utils.isNull(location) ? this.props?.location : location;
      return parseQueryString(location?.search);
    } catch (e) {
      this.errorHandler(e);
      return {};
    }
  }

  savePageState() {
    if (!Utils.isNull(this.props.saveUserState)) {
      this.props.saveUserState(this.state);
    }
  }

  setProps(props = {}) {
    if (Utils.isDefined(props.filters)) {
      this.props.history.replace({
        pathname: this.props?.location?.pathname,
        search: objectToQueryString(props?.filters),
      });
    }
  }

  // --------------------------------------------------------------------------
  // ------------------------------ Errors ------------------------------------
  // --------------------------------------------------------------------------
  errorHandler(e = null, message = null) {
    try {
      e = ApiException.toApiException(e);
      if (typeof message === 'string') Notifier.error(message);
      else if (message === true) Notifier.error(e.userMessage);
    } finally {
      super.errorHandler(e);
    }
  }
}

// ---------------------------------------------------------------------------
// ------------------------------------ Redux --------------------------------
// ---------------------------------------------------------------------------
const mapStateToProps = (state = {}, ownProps = {}) => {
  return {
    ...ownProps,
    user: state?.session?.user,
    ...Utils.define(state?.pages?.[PAGES.AVAILABLE_TASKS_PAGE.sid()], {}),
  };
};
const mapDispatchToProps = (dispatch = null, ownProps = {}) => {
  return {
    saveUserState: (state) =>
      dispatch(ACTIONS.saveUserState(PAGES.AVAILABLE_TASKS_PAGE.sid(), { filters: state.filters })),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserTasksAvailable));
