import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import Utils from '../../../utils/Utils';
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import RichTextEditor from '../../../components/RichTextEditor';
import { FormFieldError } from '../../../components/forms/FormFieldError';
import { htmlToEditorState } from '../../../utils/RichTextEditor';

const TagEditModal = ({
  row,
  rows,
  isNew,
  onChange,
  onApplyChanges,
  onCancelChanges,
  open,
  toggle,
  tagDefinitions,
  rootKey,
}) => {
  // Cycle check runs only if there are changes in the children of the EXISTING tags
  // We can't do !isNew && useMemo(...) because hooks can't be called conditionally
  // That's why we have to add !isNew check inside of the useMemo call
  const cyclesExist = useMemo(
    () =>
      !isNew &&
      row.value &&
      hasCycles(rootKey, { ...tagDefinitions, [row.value]: { children: row.children } }),
    [isNew, rootKey, row.value, row.children, tagDefinitions]
  );

  let errors = {};

  if (rows.find(({ value, order }) => value !== row.value && order === row.order)) {
    errors.order = 'Order must be a unique value';
  }
  if (row.order < 1) {
    errors.order = 'Order must be a positive number';
  }

  for (const fieldName of ['value', 'label', 'order', 'color']) {
    if (Utils.isEmpty(row[fieldName])) {
      errors[fieldName] = `${Utils.textFirstOnlyUpper(fieldName)} is required`;
    }
  }

  if (isNew && rows.find(({ value }) => value === row.value)) {
    errors.value = 'Value must be unique';
  }
  if (cyclesExist) {
    errors.children = 'Tag definitions must not contain subtag cycles';
  }

  const invalid = Object.keys(errors).length > 0;

  let defaultDescription = '';
  if (typeof row.description === 'string' || row.description instanceof String) {
    defaultDescription = htmlToEditorState(row.description);
  } else {
    defaultDescription = row.description;
  }

  const handleImport = (key) => {
    const tag = tagDefinitions[key];
    Object.keys(tag).forEach((key) => {
      if (key !== 'value') {
        let value = tag[key];
        if (key === 'description') {
          value = htmlToEditorState(tag[key]);
        }
        onChange({ target: { name: key, value } });
      }
    });
  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      onClose={onCancelChanges}
      size='lg'
      backdrop='static'
      className='mt-3'
    >
      <ModalHeader id='form-dialog-title'>Tag Details</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col sm={8}>
              <Label>Import from an existing tag</Label>
              <TagDefinitionImport row={row} tags={rows} onImport={handleImport} />
              <Label>Value</Label>
              <Input name='value' value={row.value ?? ''} onChange={onChange} disabled={!isNew} />
              {errors.value && <FormFieldError error={errors.value} touched={false} />}
              <Label>Label</Label>
              <Input name='label' value={row.label ?? ''} onChange={onChange} />
              {errors.label && <FormFieldError error={errors.label} touched={false} />}
              <Label>Order</Label>
              <Input
                name='order'
                type='number'
                min={1}
                step={1}
                value={row.order ?? ''}
                onChange={(e) =>
                  onChange({ target: { name: 'order', value: parseInt(e.target.value) || '' } })
                }
              />
              {errors.order && <FormFieldError error={errors.order} touched={false} />}

              <Label>Subtags</Label>
              <SubtagsEditor
                row={row}
                value={row.children}
                tags={rows}
                onValueChange={(value) => onChange({ target: { name: 'children', value } })}
              />
              {errors.children && <FormFieldError error={errors.children} touched={false} />}
            </Col>
            <Col sm={4}>
              <Label>Color</Label>
              <HexColorPicker
                className='m-auto'
                color={row.color ?? ''}
                onChange={(value) => onChange({ target: { name: 'color', value } })}
              />
              <HexColorInput
                prefixed
                className='w-100 my-2'
                color={row.color ?? ''}
                onChange={(value) => onChange({ target: { name: 'color', value } })}
              />
              {errors.color && <FormFieldError error={errors.color} touched={false} />}
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Description</Label>
                <RichTextEditor
                  placeholder='Description'
                  value={defaultDescription}
                  onChange={(value) => onChange({ target: { name: 'description', value } })}
                />
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancelChanges} color='secondary'>
          Cancel
        </Button>
        <Button onClick={onApplyChanges} color='primary' disabled={invalid}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TagEditModal;

const SubtagsEditor = ({ row, value = [], onValueChange, tags }) => {
  const tagOptions = tags
    .filter((tag) => tag.value !== row.value)
    .map((tag) => ({ label: tag.label, value: tag.value }));

  const selectedTags = tagOptions.filter((option) => {
    return value.includes(option.value);
  });

  const onChange = (newValue = []) => {
    // react-select sets the newValue as null if there is nothing selected
    onValueChange(newValue?.map((tag) => tag.value) ?? []);
  };

  return (
    <Select
      isMulti
      closeMenuOnSelect={false}
      options={tagOptions}
      value={selectedTags}
      onChange={onChange}
    />
  );
};

const TagDefinitionImport = ({ row, tags, onImport }) => {
  const tagOptions = tags
    .filter((tag) => tag.value !== row.value)
    .map((tag) => ({ label: tag.label, value: tag.value }));

  const [selectedTag, setSelectedTag] = useState();

  const onChange = (newValue) => {
    setSelectedTag(newValue);
  };

  const handleImportClick = () => {
    onImport(selectedTag.value);
  };

  return (
    <div className='d-flex'>
      <Select
        className='flex-grow-1'
        options={tagOptions}
        value={selectedTag}
        onChange={onChange}
      />
      <Button className='ml-2' color='primary' disabled={!selectedTag} onClick={handleImportClick}>
        Import
      </Button>
    </div>
  );
};

const hasCycles = (startingNode, graph) => {
  const visited = new Set();

  const traverseDFS = (key, nodes) => {
    if (visited.has(key)) {
      return true;
    }
    visited.add(key);

    if (nodes[key].children.length > 0) {
      for (const child of nodes[key].children) {
        return traverseDFS(child, nodes);
      }
    }
    return false;
  };

  return traverseDFS(startingNode, graph);
};
