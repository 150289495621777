import React from 'react';
import Tooltip from '../../../components/Tooltip';
import { Button } from 'react-bootstrap';
import {
  PagingState,
  CustomPaging,
  SortingState,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';

const ScreeningStepsTable = ({
  steps,
  pagination,
  sorting,
  handleSorting,
  totalElements,
  handlePageChange,
  handlePageSizeChange,
  handleEdit,
  handleDelete,
  handleInvalidate,
  setFieldValue,
}) => {
  const TableHeaderRowSortLabelComponent = (props) => {
    const sortableColumns = ['id', 'name', 'type'];
    const centerAlignedColumns = ['id', 'controls'];

    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props}>
          {props.column.title}
          <span className='pl-2'>
            <em className={`fas fa-long-arrow-alt-${props?.direction === 'asc' ? 'up' : 'down'}`} />
          </span>
        </TableHeaderRow.SortLabel>
      );
    } else {
      if (centerAlignedColumns.includes(props.column.name)) {
        return (
          <TableHeaderRow.Content {...props}>
            <span className='tw-w-full tw-block tw-text-gray-500 tw-font-bold tw-text-center'>
              {props.column.title}
            </span>
          </TableHeaderRow.Content>
        );
      } else {
        return <span className='tw-text-gray-500 tw-font-bold'>{props.column.title}</span>;
      }
    }
  };

  const resolveControls = (step) => {
    return (
      <div className='tw-flex tw-justify-around tw-justify-center tw-gap-2'>
        <Tooltip
          key='outdated_tooltip'
          title='Marks the step as outdated across projects: all HLP applicants/members of any project including this step will have to complete the step again'
        >
          <Button
            className='btn-secondary tw-flex tw-items-center'
            onClick={() => handleInvalidate(step.id)}
          >
            <em className='far fa-clock' style={{ fontSize: '18px', width: '18px' }} />
          </Button>
        </Tooltip>
        <Tooltip key='edit_tooltip' title='Edit'>
          <Button
            className='btn-secondary tw-flex tw-items-center'
            onClick={() => handleEdit(step, setFieldValue)}
          >
            <em className='fas fa-pencil-alt' style={{ fontSize: '18px', width: '18px' }}></em>
          </Button>
        </Tooltip>
        <Tooltip key='delete_tooltip' title='Delete'>
          <Button
            className='btn-secondary tw-flex tw-items-center'
            onClick={() => handleDelete(step.id)}
          >
            <em className='far fa-trash-alt' style={{ fontSize: '18px', width: '18px' }}></em>
          </Button>
        </Tooltip>
      </div>
    );
  };

  const tableColumns = [
    {
      name: 'id',
      title: 'ID',
      getCellValue: (data) => <span className='tw-block tw-text-center'>{data.id}</span>,
    },
    {
      name: 'name',
      title: 'Name',
      getCellValue: (data) => data.name,
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (data) => data.description,
    },
    {
      name: 'type',
      title: 'Type',
      getCellValue: (data) => data.type,
    },
    {
      name: 'controls',
      title: 'Controls',
      getCellValue: resolveControls,
    },
  ];

  return (
    <Grid rows={steps} columns={tableColumns} getRowId={(row) => row.id}>
      <SortingState
        columnExtensions={[
          { columnName: 'id', sortingEnabled: true },
          { columnName: 'name', sortingEnabled: true },
          { columnName: 'description', sortingEnabled: false },
          { columnName: 'type', sortingEnabled: true },
          { columnName: 'controls', sortingEnabled: false },
        ]}
        sorting={sorting}
        onSortingChange={handleSorting}
      />
      <PagingState
        currentPage={pagination.page}
        pageSize={pagination.size}
        onCurrentPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
      <CustomPaging totalCount={totalElements} />
      <Table
        columnExtensions={[
          { columnName: 'id', wordWrapEnabled: true, width: '60' },
          { columnName: 'name', wordWrapEnabled: true, width: '340' },
          { columnName: 'description', wordWrapEnabled: true, width: 'auto' },
          { columnName: 'type', wordWrapEnabled: true, width: '220' },
          { columnName: 'controls', wordWrapEnabled: true, width: '200' },
        ]}
      />
      <TableHeaderRow
        sortLabelComponent={TableHeaderRowSortLabelComponent}
        showSortingControls={true}
      />
      <PagingPanel pageSizes={[10, 25, 50, 100]} />
    </Grid>
  );
};

export default ScreeningStepsTable;
