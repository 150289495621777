import { PAGES } from '../../index';
import { Button } from 'reactstrap';
import React, { useState } from 'react';
import User from '../../../models/User';
import { Tab, Tabs } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Project from '../../../models/Project';
import Tooltip from '../../../components/Tooltip';
import Toggler from '../../../components/Toggler';
import Notifier from '../../../components/Notifier';
import ProjectFilesState from '../states/ProjectFilesState';
import ProjectMembersState from '../states/ProjectMembersState';
import ProjectActionsState from '../states/ProjectActionsState';
import ProjectTrackingState from '../states/ProjectTrackingState';
import ProjectCampaignsState from '../states/ProjectCampaignsState';
import ProjectScreeningState from '../states/ProjectScreeningState';
import ProjectStatisticsState from '../states/ProjectStatisticsState';
import ProjectController from '../../../controllers/ProjectController';
import ProjectEditSettingsState from '../states/ProjectEditSettingsState';
import ProjectRoleNotificationsState from '../states/ProjectRoleNotificationsState';

export const ProjectPageView = ({
  user = null,
  project = null,
  isEditable = false,
  isDeletable = false,
  projectIsLoading = false,
  metadata = {},
  metadataAreLoading = false,
  roleOptions = [],
  onDeleteButtonClick = null,
  onBackButtonClick = null,
  onProjectUpdate = null,
  defaultActiveTab = 1,
  canSendMessage = false,
  onProjectFilesUpdated = null,
}) => {
  let [activeTab, setActiveTab] = useState(defaultActiveTab ?? 1);
  const [isProjectActive, setIsProjectActive] = useState(project.status === 'ACTIVE');
  const [isProjectPublic, setIsProjectPublic] = useState(
    project?.settings?.visibility === 'PUBLIC'
  );

  const projectCreateDate = !!project?.timestampCreated
    ? new Date(project?.timestampCreated).toUTCString()
    : 'N/A';
  const isWorkerValidation = project?.scope === Project.SCOPE.WORKER_VALIDATION.sid;

  const [tooltipText, setTooltipText] = useState('Copy to clipboard');
  const resetTooltipText = () => setTooltipText('Copy to clipboard');

  const onGetLink = () => {
    if (metadata?.projectFiles.length > 0) {
      const url = window.location.href.split(window.location.pathname)[0];
      navigator.clipboard.writeText(`${url}/tests/${project?.id}`);
      setTooltipText('Copied!');
    } else {
      Notifier.error('You need to associate a file to the project first!');
    }
  };

  const handleToggleActive = async (toggle) => {
    setIsProjectActive(toggle);
    let data = {
      status: toggle ? 'ACTIVE' : 'INACTIVE',
      settings: project?.settings,
    };

    let newProject = await ProjectController.update(project?.id, data);
    onProjectUpdate(newProject);
  };

  const handleTogglePublic = async (toggle) => {
    setIsProjectPublic(toggle);

    let projectSettings = project?.settings;
    projectSettings.visibility = toggle ? 'PUBLIC' : 'PRIVATE';

    let data = {
      settings: projectSettings,
    };

    let newProject = await ProjectController.update(project?.id, data);
    onProjectUpdate(newProject);
  };

  const history = useHistory();

  const [copyProjectTooltipText, setCopyProjectTooltipText] = useState('Create a similar project');

  const onCopyProject = () => {
    let path = PAGES.PROJECT_COPY_PAGE.path(project?.id);
    history.push(path);
  };

  // Copy button visibility
  let ButtonCopyView = (
    <Button outline color='success' size='lg' onClick={onCopyProject}>
      Copy
    </Button>
  );

  return (
    <div className=''>
      <div className='tw-flex tw-justify-between'>
        <div className=''>
          <h1 className='col-12'>{project?.name ?? 'N/A'}</h1>
          <p className='col-12 text-muted'>{project?.description ?? 'N/A Description'}</p>
          <div className='col-12 tw-flex tw-flex-col tw-mb-4'>
            <div className='tw-flex tw-gap-0 tw-items-center tw-mb-1'>
              <div className='tw-inline tw-w-[130px]'>
                <span className='tw-text-base'>Status:</span>{' '}
                <span className='tw-font-medium'>{project?.status ?? 'N/A'}</span>
              </div>
              {User.isAdmin(user) && (
                <div className='tw-inline-block'>
                  <Toggler toggle={isProjectActive} handleToggle={handleToggleActive} />{' '}
                </div>
              )}
            </div>
            <div className='tw-flex tw-gap-0 tw-items-center'>
              <div className='tw-inline tw-w-[130px]'>
                <span className='tw-text-base'>Visibility:</span>{' '}
                <span className='tw-font-medium'>{project?.settings?.visibility ?? 'N/A'}</span>
              </div>
              {User.isAdmin(user) && (
                <div className='tw-inline-block'>
                  <Toggler toggle={isProjectPublic} handleToggle={handleTogglePublic} />{' '}
                </div>
              )}
            </div>
          </div>
          <p className='col-12 text-info text-sm'>
            Created by {project?.owner?.username ?? 'N/A'} on {projectCreateDate}
          </p>
        </div>
        <div className='' onMouseOut={resetTooltipText}>
          <Tooltip title={copyProjectTooltipText}>{ButtonCopyView}</Tooltip>
          {isWorkerValidation && (
            <Tooltip title={tooltipText}>
              <button type='button' className='btn btn-primary btn-lg ml-2' onClick={onGetLink}>
                Get link
              </button>
            </Tooltip>
          )}
          {isDeletable ? (
            <button
              type='button'
              className={'btn btn-outline-danger btn-lg'}
              onClick={onDeleteButtonClick}
              disabled={projectIsLoading}
            >
              {' '}
              Delete{' '}
            </button>
          ) : null}
          {!!onBackButtonClick ? (
            <button
              type='button'
              className='btn btn-secondary btn-lg ml-2'
              onClick={onBackButtonClick}
            >
              {' '}
              Back{' '}
            </button>
          ) : null}
        </div>
      </div>
      <hr />
      <Tabs
        id='project-tabs'
        activeKey={activeTab}
        onSelect={(index) => setActiveTab(index)}
        mountOnEnter
      >
        <Tab eventKey={1} title='Settings'>
          <ProjectEditSettingsState
            project={project}
            onProjectUpdate={onProjectUpdate}
            onProjectFilesUpdated={onProjectFilesUpdated}
            projectIsLoading={projectIsLoading}
            metadataAreLoading={metadataAreLoading}
            metadata={metadata}
          />
        </Tab>
        <Tab title='Files' eventKey={2}>
          <ProjectFilesState
            isBusy={metadataAreLoading}
            project={project}
            isEditable={isEditable}
            files={metadata?.files}
            projectFiles={metadata?.projectFiles}
            onProjectFilesUpdated={onProjectFilesUpdated}
          />
        </Tab>
        <Tab title='Members' eventKey={3}>
          <ProjectMembersState
            project={project}
            isEditable={isEditable}
            canSendMessage={canSendMessage}
          />
        </Tab>
        <Tab title='Screening' eventKey={4}>
          <ProjectScreeningState project={project} roleOptions={roleOptions} />
        </Tab>
        <Tab title='Tracking' eventKey={5}>
          <ProjectTrackingState project={project} roleOptions={roleOptions} />
        </Tab>
        <Tab title='Notifications' eventKey={6}>
          <ProjectRoleNotificationsState
            project={project}
            roleOptions={roleOptions}
            onProjectUpdate={onProjectUpdate}
          />
        </Tab>
        <Tab title='Campaigns' eventKey={7}>
          <ProjectCampaignsState project={project} roleOptions={roleOptions} />
        </Tab>
        {isEditable ? (
          <Tab title='Actions' eventKey={8}>
            <ProjectActionsState
              project={project}
              projectFiles={metadata?.projectFiles}
              onProjectUpdate={onProjectUpdate}
            />
          </Tab>
        ) : null}
        <Tab title='Statistics' eventKey={9}>
          <ProjectStatisticsState project={project} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ProjectPageView;
