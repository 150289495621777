import Utils from './Utils';

/**
    Formats the users and groups to the options format expected by React-Select
*/
export function getUserOptions(users = [], groups = [], filterValue) {
  let options = [];

  if (filterValue === 'All' || filterValue === 'Groups') {
    if (groups.length > 0) {
      options.push({
        label: 'Groups',
        options: groups.map((group) => ({
          label: `${group?.name} by ${group?.owner?.username ?? ''}`,
          value: group?.id ?? null,
        })),
      });
    }
  }

  if (filterValue === 'All' || filterValue === 'Users') {
    if (users.length > 0) {
      options.push({
        label: 'Users',
        options: users.map((user) => ({
          label: user?.username ?? '',
          value: user?.id ?? null,
        })),
      });
    }
  }

  return options;
}

export const findOption = (options, value, byLabel = false) => {
  if (!Utils.isDefined(value) || Utils.isNull(value)) return null;

  let arrayValue;
  if (Array.isArray(value)) {
    arrayValue = value;
  } else if (typeof value === 'number') {
    arrayValue = [value];
  } else {
    arrayValue = (value.toString().indexOf(',') > -1) ? value?.split(',') : [value];
  }

  const optionsFound = options.filter((obj) => {
    return arrayValue.includes(byLabel ? obj.label : obj.value);
  });

  return optionsFound;
};

export const findLabelFromNestedOptions = (options, value) =>
  options.find((option) => !!option.options.find((nestedOption) => nestedOption.value === value));
