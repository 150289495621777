import React, { useEffect, useState } from 'react';
import { DataTypeProvider, EditingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableEditColumn,
  TableEditRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import { SortableContainer, SortableHandle, SortableElement, arrayMove } from 'react-sortable-hoc';
import Select from 'react-select';

import { TableEditColumnOrderLast } from '../../../components/tables/Editor';
import USER from '../../../models/User';
import { Input } from 'reactstrap';

const resolveMember = (row) => row?.user?.sid;
const resolveType = (row) => row?.user?.type;
const resolveRole = (row) => row?.role?.name;
const resolveCompleted = (row) => {
  return Object.values(row?.taskResultCountByStatus).reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);
};
const resolveLimit = (row) => row?.taskLimit ?? 'N/A';

const tableColumns = [
  {
    name: 'reorder',
  },
  {
    name: 'member',
    title: 'Member',
    getCellValue: resolveMember,
  },
  {
    name: 'completed',
    title: 'Claimed',
    getCellValue: resolveCompleted,
  },
  {
    name: 'limit',
    title: 'Limit',
    getCellValue: resolveLimit,
  },
  {
    name: 'type',
    title: 'Type',
    getCellValue: resolveType,
  },
  {
    name: 'role',
    title: 'Role',
    getCellValue: resolveRole,
  },
  {
    name: 'reviewConstraints',
    title: 'Review Constraints',
  },
];

const ReviewConstraintsFormatter = ({ value, row }) => {
  return (
    <div style={{ whiteSpace: 'normal' }}>
      {row.role.sid !== USER.PROJECT_ROLES.WORKER_REVIEWER && <em>Not applicable</em>}
      {value.map((projectMember) => (
        <div key={projectMember.id} className='badge badge-light border font-weight-normal'>
          {resolveMember(projectMember)}
        </div>
      ))}
    </div>
  );
};

const ReviewConstraintsEditor = ({ row, value, onValueChange }, projectMemberOptions) => {
  const optionsWithoutRowReviewer = projectMemberOptions.filter(
    (option) => option.value !== row.id
  );
  const reviewConstraints = optionsWithoutRowReviewer.filter((option) => {
    return value.map((projectMember) => projectMember.id).includes(option.value);
  });

  const onChange = (newValue) => {
    // react-select sets the newValue as null if there is nothing selected
    onValueChange(newValue ?? []);
  };

  return (
    <Select
      isMulti
      isDisabled={row.role.sid !== USER.PROJECT_ROLES.WORKER_REVIEWER}
      options={optionsWithoutRowReviewer}
      menuPlacement='top'
      defaultValue={reviewConstraints}
      onChange={onChange}
      menuPortalTarget={document.body}
    />
  );
};

const LimitEditor = ({ row, value, onValueChange }) => {
  const onChange = (newValue) => {
    onValueChange(newValue);
  };

  return (
    <Input
      type='text'
      id='limit'
      name='limit'
      defaultValue={value === 'N/A' ? '' : value}
      placeholder='Set a limit'
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

const ActionHeaderCell = () => (
  <TableHeaderRow.Cell>
    <div className='m-auto tw-text-gray-500 tw-font-medium'>Actions</div>
  </TableHeaderRow.Cell>
);

const ProjectMembersTable = ({
  projectMembers,
  updateReviewConstraints,
  updateMemberPosition,
  updateLimit,
  deleteMember,
  isEditable,
  isBusy,
}) => {
  const [rows, setRows] = useState([]);

  const projectMemberOptions = projectMembers.map((projectMember) => ({
    value: projectMember.id,
    label: resolveMember(projectMember),
  }));

  const commitChanges = ({ changed, deleted }) => {
    if (changed) {
      for (const [key, value] of Object.entries(changed)) {
        if (value?.reviewConstraints) {
          updateReviewConstraints(
            key,
            value.reviewConstraints.map((option) => option.value)
          );
        }
        if (value?.limit || value?.limit === '') {
          let member = projectMembers?.find(({ id }) => parseInt(id) === parseInt(key));
          let reviewConstraints = member.reviewConstraints?.map((option) => option.id);
          updateLimit(key, reviewConstraints, value?.limit);
        }
      }
    }
    if (deleted) {
      deleted.forEach((memberId) => {
        deleteMember(memberId);
      });
    }
  };

  const DragHandle = SortableHandle(() => (
    <span className='tw-cursor-move'>
      <em className='fas fa-arrows-alt-v' />
    </span>
  ));

  const HeaderCell = (props) => {
    return (
      <Table.Cell {...props}>
        {props.column.name === 'reorder' ? (
          <></>
        ) : (
          <span className='tw-text-gray-500 tw-font-medium'>{props.column.title}</span>
        )}
      </Table.Cell>
    );
  };

  const TableCell = (props) => {
    return (
      <Table.Cell {...props}>
        {props.tableColumn.column.name === 'reviewConstraints' ? (
          ReviewConstraintsFormatter(props)
        ) : props.tableColumn.column.name === 'reorder' ? (
          <DragHandle />
        ) : (
          <span>{props.value}</span>
        )}
      </Table.Cell>
    );
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;

    let oldPosition = oldIndex + 1;
    let newPosition = newIndex + 1;
    let updatedMember = rows.find((row) => row.position === oldPosition);
    setRows(arrayMove(rows, oldIndex, newIndex));

    updateMemberPosition(updatedMember.id, newPosition);
  };

  useEffect(() => {
    setRows(projectMembers);
  }, [projectMembers]);

  return (
    <div className={`${isBusy ? 'sphere whirl' : ''}`}>
      <Grid rows={rows} columns={tableColumns} getRowId={(row) => row.id}>
        <DataTypeProvider
          for={['reviewConstraints']}
          formatterComponent={ReviewConstraintsFormatter}
          editorComponent={(data) => ReviewConstraintsEditor(data, projectMemberOptions)}
        />
        <DataTypeProvider for={['limit']} editorComponent={(data) => LimitEditor(data)} />

        <Table
          columnExtensions={[
            { columnName: 'reorder', wordWrapEnabled: true, width: '40' },
            { columnName: 'member', wordWrapEnabled: true, width: 'auto' },
            { columnName: 'completed', wordWrapEnabled: true, width: '100' },
            { columnName: 'limit', wordWrapEnabled: true, width: '80' },
            { columnName: 'type', wordWrapEnabled: true, width: '125' },
            { columnName: 'role', wordWrapEnabled: true, width: '140' },
            { columnName: 'reviewConstraints', width: 160 },
          ]}
          cellComponent={TableCell}
          bodyComponent={({ row, ...restProps }) => {
            const TableBody = SortableContainer(Table.TableBody);
            return <TableBody {...restProps} onSortEnd={onSortEnd} useDragHandle />;
          }}
          rowComponent={({ row, ...restProps }) => {
            const TableRow = SortableElement(Table.Row);
            return <TableRow {...restProps} index={rows.indexOf(row)} />;
          }}
        />
        <TableHeaderRow cellComponent={HeaderCell} />

        {isEditable && (
          <EditingState
            onCommitChanges={commitChanges}
            columnExtensions={[
              { columnName: 'reorder', editingEnabled: false },
              { columnName: 'member', editingEnabled: false },
              { columnName: 'completed', editingEnabled: false },
              { columnName: 'limit', editingEnabled: true },
              { columnName: 'type', editingEnabled: false },
              { columnName: 'role', editingEnabled: false },
            ]}
          />
        )}
        {isEditable && <TableEditRow />}
        {isEditable && (
          <TableEditColumn
            showEditCommand
            showDeleteCommand
            headerCellComponent={ActionHeaderCell}
          />
        )}
        {isEditable && <TableEditColumnOrderLast />}
      </Grid>
    </div>
  );
};

export default ProjectMembersTable;
