import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMicrophone,
  faTimes,
  faStopCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { extractMinutes, extractSeconds } from '../../../../utils/Recorder';
import Tooltip from '../../../../components/Tooltip';
import '../../../../css/Recorder.scss';

export default function RecorderControls({
  segmentRecDurationMin,
  segmentRecDurationMax,
  recorderState,
  handlers,
  recordingDisabled,
}) {
  const { recordingSeconds, initRecording } = recorderState;
  const { startRecording, saveRecording, cancelRecording } = handlers;

  return (
    <div className='controls-container'>
      <div>
        <span>
          Rec time min/max: {segmentRecDurationMin}-{segmentRecDurationMax}s
        </span>
      </div>
      <div className='tw-flex tw-justify-end'>
        <div className='recorder-display'>
          <div className='position-relative recording-time pr-4'>
            {initRecording && <div className='recording-indicator'></div>}
            {extractMinutes(recordingSeconds)}:{extractSeconds(recordingSeconds)}
            {recordingSeconds > segmentRecDurationMax && (
              <Tooltip
                title={`Your recording must be between ${segmentRecDurationMin} and ${segmentRecDurationMax} seconds long.`}
              >
                <span className='position-absolute animated fadeIn' style={{ right: '0px' }}>
                  <FontAwesomeIcon icon={faExclamationTriangle} color='#fd7e14' />
                </span>
              </Tooltip>
            )}
          </div>
          {initRecording ? (
            <div className='cancel-button-container'>
              <button className='cancel-button' title='Cancel recording' onClick={cancelRecording}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          ) : (
            <div className='cancel-button-container'></div>
          )}
        </div>
        <div className='start-button-container'>
          {initRecording ? (
            <button
              className='start-button tw-flex tw-items-center tw-justify-center'
              title='Save recording'
              disabled={recordingSeconds === 0}
              onClick={saveRecording}
            >
              <FontAwesomeIcon icon={faStopCircle} size='2x' />
            </button>
          ) : (
            <button
              className='start-button'
              title='Start recording'
              onClick={startRecording}
              disabled={recordingDisabled}
            >
              <FontAwesomeIcon icon={faMicrophone} size='2x' />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
