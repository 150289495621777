import {
  CustomPaging,
  DataTypeProvider,
  EditingState,
  PagingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditColumn,
  PagingPanel,
} from '@devexpress/dx-react-grid-bootstrap4';
import { TableEditColumnOrderLast } from '../../../components/tables/Editor';
import Tooltip from '../../../components/Tooltip';

const GroupMembersTable = ({
  members,
  pagination,
  totalElements,
  onCurrentPageChange,
  onPageSizeChange,
  onRemoveMember,
  onApproveMember,
}) => {
  const tableColumns = [
    {
      name: 'email',
      title: 'Email',
      getCellValue: (row) => row.member?.email,
    },
    {
      name: 'fullName',
      title: 'Name',
      getCellValue: (row) => row.member?.fullName,
    },
    {
      name: 'status',
      title: 'Status',
    },
  ];

  return (
    <Grid rows={members} columns={tableColumns} getRowId={(row) => row.member.id}>
      <PagingState
        currentPage={pagination.page}
        pageSize={pagination.size}
        onCurrentPageChange={onCurrentPageChange}
        onPageSizeChange={onPageSizeChange}
      />
      <CustomPaging totalCount={totalElements} />

      <Table
        columnExtensions={[
          { columnName: 'fullName', wordWrapEnabled: true, width: 100 },
          { columnName: 'status', width: 90 },
        ]}
        rowComponent={CustomRow}
      />
      <TableHeaderRow />

      <DataTypeProvider for={['status']} formatterComponent={StatusFormatter} />

      <EditingState />
      <TableEditColumn
        cellComponent={(props) => (
          <CustomEditCell
            onRemoveMember={onRemoveMember}
            onApproveMember={onApproveMember}
            {...props}
          />
        )}
        headerCellComponent={ActionHeaderCell}
      />
      <TableEditColumnOrderLast />
      <PagingPanel pageSizes={[10, 25, 50, 100]} />
    </Grid>
  );
};

const ActionHeaderCell = () => (
  <TableHeaderRow.Cell>
    <div className='m-auto'>Actions</div>
  </TableHeaderRow.Cell>
);

const CustomEditCell = ({ onRemoveMember, onApproveMember, ...props }) => {
  return (
    <TableEditColumn.Cell {...props}>
      {props.row.status === 'PENDING' && (
        <Tooltip title='Approve member'>
          <button
            onClick={() => onApproveMember(props.row.member.id)}
            className='btn bg-transparent btn-secondary-outline border-0 px-2'
          >
            <em className='fas fa-user-plus' style={{ fontSize: '20px', width: '20px' }} />
          </button>
        </Tooltip>
      )}
      <Tooltip title='Remove member'>
        <button
          onClick={() => onRemoveMember(props.row.member.id)}
          className='btn bg-transparent btn-secondary-outline border-0 px-2 text-danger'
        >
          <em className='far fa-trash-alt' style={{ fontSize: '20px', width: '20px' }} />
        </button>
      </Tooltip>
    </TableEditColumn.Cell>
  );
};

const CustomRow = (props) => {
  return (
    <Table.Row {...props} className={!props?.row?.insertedAutomatically ? 'tw-bg-blue-100 ' : ''} />
  );
};

const StatusFormatter = (data) => {
  const badgeClass = data.value === 'PENDING' ? 'badge-warning' : 'badge-success';

  return (
    <span key={data.row.member.id} className={`badge ${badgeClass} border font-weight-normal`}>
      {data.value}
    </span>
  );
};

export default GroupMembersTable;
