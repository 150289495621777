// Actions types
export const TYPES = {
  SAVE_USER_STATE: 'SAVE_USER_STATE',
};

// Actions
export const ACTIONS = {
  saveUserState: (page = null, state = null) => ({
    type: TYPES.SAVE_USER_STATE,
    state: state,
    page: page,
  }),
};
