// CSS
import '../../css/taus.select-parameter.scss';

// External libs
import React from 'react';
import { default as ReactSelect } from 'react-select';
import PropTypes from 'prop-types';

// App libs
import Utils from '../../utils/Utils';
import Parameter from './Parameter';

// ---------------------------------------------------- Utils
/**
 * The default ReactSelect component options
 * @returns {{isMulti: boolean, classNamePrefix: string, hideSelectedOptions: boolean, isDisabled: boolean, placeholder: null, noOptionMessage: string, closeMenuOnSelect: boolean, isClearable: boolean}}
 */
export const reactSelectDefaultProps = () => ({
  classNamePrefix: 'taus-select-parameter',
  isDisabled: false,
  placeholder: null,
  isMulti: false,
  closeMenuOnSelect: true,
  isClearable: true,
  hideSelectedOptions: false,
  noOptionMessage: 'No Options',
});

/**
 * Returns an object with the ReactSelect options for setting up an alternative button for the dropdown
 * @param button
 * @returns {{}|{components: {IndicatorSeparator: (function(): null), ValueContainer: (function(): null), DropdownIndicator: (function(*=): *)}, hideSelectedOptions: boolean, controlShouldRenderValue: boolean, styles: {control: (function(*): {border: string, cursor: string, backgroundColor: string})}, isClearable: boolean}}
 */
export const buttonDropdownProps = (button = null) => {
  if (typeof button !== 'function') return {};
  return {
    controlShouldRenderValue: false,
    isClearable: false,
    hideSelectedOptions: false,
    isSearchable: false,
    styles: {
      control: (provided) => ({
        ...provided,
        border: 'none !important',
        cursor: 'unset !important',
        backgroundColor: 'unset !important',
      }),
      valueContainer: () => ({}),
    },
    components: {
      Placeholder: (props) => button(props),
      Input: () => null,
      IndicatorSeparator: () => null,
      DropdownIndicator: () => null,
    },
  };
};

/**
 * Adds to the props parameter the related options for adding the button passed as parameter as the select dropdown button
 * @param button
 * @param props
 * @returns {*}
 */
export const addButtonDropdownToProps = (button = null, props = {}) => {
  let buttonProps = buttonDropdownProps(button);
  return Utils.isNull(props)
    ? buttonProps
    : {
        ...props,
        ...buttonProps,
        styles: { ...props?.styles, ...buttonProps?.styles },
        components: { ...props?.components, ...buttonProps?.components },
      };
};

// -------------------------------------------------------------------------
// ------------------------------- Functional Component --------------------
// -------------------------------------------------------------------------
export const Select = (props) => {
  // on change listener
  const onChange = (values) => {
    values = Utils.defineToArray(values, [])?.map((obj) => obj.value);
    if (!Utils.isNull(props.onChange)) Promise.resolve(values).then((obj) => props.onChange(obj));
  };

  // setup component props
  let selectProps = {
    ...reactSelectDefaultProps(),
    ...props?.typeOptions,
    onChange: onChange,
    options: Utils.defineArray(
      props?.options?.map((obj) => ({ ...obj, label: obj.name })),
      []
    ),
    isDisabled: props?.disabled === true,
  };
  if (Utils.isNull(selectProps.placeholder))
    selectProps.placeholder = Utils.define(selectProps.options?.[0]?.label, '');

  // setup value
  let value = Utils.isDefined(props?.value)
    ? Utils.defineToArray(props?.value, [])
    : Utils.defineToArray(props?.defaultValue, []);

  let options = [];
  if (selectProps.options.find((opt) => opt?.options?.length > 0)) {
    options = selectProps.options.flatMap((opt) => {
      if (opt?.options?.length > 0) {
        return [...opt.options];
      } else {
        return opt;
      }
    });
  } else {
    options = selectProps.options;
  }

  value = options.filter((obj) => value?.includes(obj.value));
  if (selectProps?.isMulti !== true) value = value?.slice(0, 1);
  if (Utils.isDefined(props?.value)) selectProps.value = value;
  else selectProps.defaultValue = value;

  // setup select button
  if (!Utils.isNull(props?.button))
    selectProps = addButtonDropdownToProps(props?.button, selectProps);

  // add css class
  let className = Utils.define(props?.className, '') + ' taus-select-parameter';
  let styles = { width: 'fit-content', ...props?.style };

  return (
    <div className={className} style={styles}>
      <ReactSelect {...selectProps} />
    </div>
  );
};

export const ReactSelectPropTypes = {
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  isClearable: PropTypes.bool,
  noOptionMessage: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.func),
  components: PropTypes.objectOf(PropTypes.func),
};

Select.propTypes = {
  // properties
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.any, name: PropTypes.string.isRequired })
  ),
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.any]),
  defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.any]),
  disabled: PropTypes.bool,
  button: PropTypes.func,
  typeOptions: PropTypes.shape(ReactSelectPropTypes),
  className: PropTypes.string,
  style: PropTypes.object,
  // Listeners
  onChange: PropTypes.func,
};

// -------------------------------------------------------------------------
// ------------------------------- PureComponent ---------------------------
// -------------------------------------------------------------------------
class SelectParameter extends Parameter {
  constructor(props = {}) {
    super(props);

    this.state.value = Utils.defineToArray(props?.defaultValue, []);
    this.state.value = Utils.defineArray(
      props?.options
        ?.filter((obj) => this.state.value.includes(obj.value))
        ?.map((obj) => obj.value),
      []
    );
  }

  getDefaultProperties = () => ({ ...SelectParameter.defaultProps });

  static defaultProps = {
    // properties
    options: [],
    defaultValue: [],
    disabled: false,
    button: null,
    typeOptions: reactSelectDefaultProps(),
    menuWidth: null,
    className: null,
    style: null,
    // Listeners
    onChange: null,
  };

  state = {
    value: [],
  };

  // -----------------------------------------------------------------
  // -------------------------- ReactJS Methods ----------------------
  // -----------------------------------------------------------------
  render() {
    let defProps = this.getDefaultProperties();
    let props = {
      typeOptions: { ...this.getDefaultProperties()?.typeOptions, ...this.props?.typeOptions },
      options: Utils.defineArray(this.props?.options, defProps?.options),
      value: Utils.defineToArray(this.state.value, defProps.defaultValue),
      disabled: Utils.define(this.props?.disabled, defProps.disabled),
      button: Utils.define(this.props?.button, defProps.button),
      onChange: this.selectChanged.bind(this),
      menuWidth: Utils.define(this.props?.menuWidth),
      className: Utils.define(this.props?.className),
      style: Utils.define(this.props?.style),
    };

    return <Select {...props} />;
  }

  // -----------------------------------------------------------------
  // -------------------------- Listeners ----------------------------
  // -----------------------------------------------------------------
  selectChanged(options) {
    try {
      this.state.value = options;
      this.forceUpdate();
      this.valueChanged();
    } catch (e) {
      this.errorHandler(e);
    }
  }

  // -----------------------------------------------------------------
  // -------------------------- Class Methods ------------------------
  // -----------------------------------------------------------------
  clear() {
    this.setValue([]);
  }
  reset() {
    this.setValue(this.props?.defaultValue);
  }

  getValue() {
    return Array.from(this.state.value);
  }

  setValue(value) {
    try {
      if (!Utils.isDefined(value)) return false;
      if (Utils.isNull(value)) this.setState({ value: [] });
      if (Array.isArray(value) && Utils.isEmpty(value)) this.setState({ value: [] });
      else {
        value = this.validateValues(value);
        if (value.length < 1) return false;
        this.setState({ value: value });
      }
      return true;
    } catch (e) {
      this.errorHandler(e);
      return false;
    }
  }

  getStringValue() {
    return this.getValue().toString();
  }

  validateValues(values = null) {
    values = Utils.defineToArray(values, []);
    return this.props?.options
      ?.map((obj) => obj.value)
      .filter((obj) => values.includes(obj))
      .slice(0, 1);
  }
}

export default SelectParameter;

SelectParameter.propTypes = {
  // properties
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.any, name: PropTypes.string.isRequired })
  ),
  defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.any]),
  disabled: PropTypes.bool,
  button: PropTypes.func,
  typeOptions: PropTypes.shape(ReactSelectPropTypes),
  // Listeners
  onChange: PropTypes.func,
};
