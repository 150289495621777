import React from 'react';
import { NavLink } from 'react-router-dom';

import Utils from '../utils/Utils';
import USER from '../models/User';
import USER_PERMISSION from '../models/UserPermission';
import TASK from '../models/Task';
import PROJECT from '../models/Project';
import FILE from '../models/File';
import User from '../models/User'

export const PAGE_TYPE = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
};

const envMapping = {
  production: '',
  staging: '.stag',
  development: '.dev',
};

class ExternalPage {
  constructor(props = {}) {
    Object.assign(this, props);
  }
  type = () => PAGE_TYPE.EXTERNAL;
  userHasAccess = () => true;
}
// External platform pages
export const EXTERNAL_PAGES = {
  HLP_CONTENT_HOME_PAGE: new ExternalPage({
    sid: () => 'HLP_CONTENT_HOME_PAGE',
    path: () => `https://hlp${envMapping[process.env.REACT_APP_ENV]}.taus.net`,
  }),
  TAUS_PAGE: new ExternalPage({
    sid: () => 'TAUS_PAGE',
    path: () => `https://www${envMapping[process.env.REACT_APP_ENV]}.taus.net`,
  }),
  TAUS_CONTACT_US_PAGE: new ExternalPage({
    sid: () => 'TAUS_CONTACT_US_PAGE',
    path: () => 'https://hlp.taus.net/contact-hlp-team',
  }),
  TAUS_BLOG_PAGE: new ExternalPage({
    sid: () => 'TAUS_BLOG_PAGE',
    path: () => 'https://www.taus.net/resources/blog/',
  }),
  TAUS_PRIVACY_POLICY_PAGE: new ExternalPage({
    sid: () => 'TAUS_PRIVACY_POLICY_PAGE',
    path: () =>
      'https://hlp.taus.net/legal-policies/privacy-cookie-policy',
  }),
  TAUS_WORKERS_AGREEMENT_PAGE: new ExternalPage({
    sid: () => 'TAUS_WORKERS_AGREEMENT_PAGE',
    path: () => 'https://hlp.taus.net/legal-policies/workers-agreement',
  }),
  STRIPE_CONNECT: new ExternalPage({
    sid: () => 'STRIPE_CONNECT',
    path: (stripeURL) => {
      window.location.href = stripeURL;

      return PAGES.REDIRECT_TO_STRIPE.path();
    },
  }),
};

class InternalPage {
  constructor(props = {}) {
    Object.assign(this, props);
  }
  type = () => PAGE_TYPE.INTERNAL;
}

// Internal platform pages
export const PAGES = {
  // ----- PUBLIC PAGES
  SIGN_IN: new InternalPage({
    sid: () => 'SIGN_IN',
    name: () => 'Sign in',
    path: () => '/sign-in',
    userHasAccess: (user) => Utils.isNull(user),
  }),
  USER_ACCOUNT_RECOVER_PAGE: new InternalPage({
    sid: () => 'USER_ACCOUNT_RECOVER_PAGE',
    name: () => 'User account recover',
    path: () => `/user/account/recover`,
    userHasAccess: (user) => Utils.isNull(user),
  }),
  REDIRECT_TO_STRIPE: new InternalPage({
    sid: () => 'REDIRECT_TO_STRIPE',
    name: () => 'Redirect to Stripe',
    path: () => '/redirect-to-stripe',
    userHasAccess: () => true,
  }),
  USER_REGISTER: new InternalPage({
    sid: () => 'USER_REGISTER',
    name: () => 'Register to HLP',
    path: () => '/register',
    userHasAccess: (user) => Utils.isNull(user),
  }),
  USER_ONBOARDING: new InternalPage({
    sid: () => 'USER_ONBOARDING',
    name: () => 'User Onboarding',
    path: () => '/user/onboarding',
    userHasAccess: (user) => {
      return (!Utils.isNull(user) ? !user.isValid : true)
    },
  }),
  HOME_PAGE: new InternalPage({
    sid: () => 'HOME_PAGE',
    name: () => 'Home',
    path: () => '/',
    userHasAccess: (user) => !Utils.isNull(user),
  }),
  // ----- PUBLIC PROJECTS MENU ITEM
  PUBLIC_PROJECTS_LISTING_PAGE: new InternalPage({
    sid: () => 'PUBLIC_PROJECTS_LISTING_PAGE',
    name: () => 'Projects',
    path: () => '/projects',
    userHasAccess: (user) => {
      return !Utils.isNull(user) ? user?.isValid : true;
    }
  }),
  PUBLIC_PROJECT_PAGE: new InternalPage({
    sid: (id = null) => (Utils.isNull(id) ? 'PUBLIC_PROJECT_PAGE' : 'PUBLIC_PROJECT_PAGE_' + id),
    name: (id = null) => (Utils.isNull(id) ? 'Project' : 'Project ' + id),
    path: (id = null) => (id === null ? '/projects/:id([0-9]*)' : '/projects/' + id),
    userHasAccess: (user) => {
      return !Utils.isNull(user) ? user.isValid : true;
    },
  }),
  PUBLIC_PROJECT_LOBBY_PAGE: new InternalPage({
    sid: (id = null) =>
      Utils.isNull(id) ? 'PUBLIC_PROJECT_LOBBY_PAGE' : 'PUBLIC_PROJECT_LOBBY_PAGE_' + id,
    name: (id = null) => (Utils.isNull(id) ? 'Project Lobby' : 'Project Lobby' + id),
    path: (id = null) =>
      id === null ? '/projects/:id([0-9]*)/lobby' : '/projects/' + id + '/lobby',
    userHasAccess: (user) => !Utils.isNull(user),
  }),
  SCREENER_STEP_QUESTIONNAIRE: new InternalPage({
    sid: () => 'SCREENER_STEP_QUESTIONNAIRE',
    name: () => 'Screener Step Questionnaire',
    path: (projectId = null, screenerStepId = null) =>
      !projectId || !screenerStepId
        ? '/projects/:projectId([0-9]*)/screener-step-questionnaire/:screenerStepId([0-9]*)'
        : '/projects/' + projectId + '/screener-step-questionnaire/' + screenerStepId,
    userHasAccess: (user) => User.isValid(user),
  }),
  SCREENER_STEP_PROJECT: new InternalPage({
    sid: () => 'SCREENER_STEP_PROJECT',
    name: () => 'Screener Step Project',
    path: (projectId = null, requirementTypeId = null) =>
      !projectId || !requirementTypeId
        ? '/projects/:projectId([0-9]*)/screener-step-project/:requirementTypeId([0-9]*)'
        : '/projects/' + projectId + '/screener-step-project/' + requirementTypeId,
    userHasAccess: (user) => User.isValid(user),
  }),
  // ----- TASKS MENU ITEM
  TASK_RESULT_PAGE: new InternalPage({
    sid: (id = null) => (Utils.isNull(id) ? 'TASK_RESULT_PAGE' : 'TASK_RESULT_PAGE_' + id),
    name: (id = null) => (Utils.isNull(id) ? 'TaskResult' : 'TaskResult ' + id),
    path: (id = null) => (id === null ? '/task/result/:id([0-9]*)' : '/task/result/' + id),
    userHasAccess: (user) => User.isValid(user),
  }),
  AVAILABLE_TASKS_PAGE: new InternalPage({
    sid: () => 'AVAILABLE_TASK_PAGE',
    name: () => 'Available Tasks',
    path: (projectId = null) =>
      !projectId ? '/task/available/:projectId([0-9]*)' : '/task/available/' + projectId,
    userHasAccess: (user) => (User.isValid(user)),
  }),
  USER_TASKS_PAGE: new InternalPage({
    sid: () => 'USER_TASK_PAGE',
    name: () => 'My Tasks',
    path: () => '/task/user',
    userHasAccess: (user) => (User.isValid(user)),
  }),
  WORKER_TASKS_PAGE: new InternalPage({
    sid: () => 'WORKER_TASKS_PAGE',
    name: () => 'Workers Tasks',
    path: () => '/task/worker',
    userHasAccess: (user) => {
      let permissions = Object.values(TASK.PERMISSIONS).filter(
        (obj) =>
          ![
            TASK.PERMISSIONS.REQUEST,
            TASK.PERMISSIONS.REQUEST_REVIEW,
            TASK.PERMISSIONS.REQUEST_PRIMARY,
          ].includes(obj)
      );
      return (User.isValid(user)) && Utils.define(
        USER.hasAnyPermission(user, USER_PERMISSION.TYPES.TASK, permissions),
        false
      );
    },
  }),
  // ----- USERS MENU ITEM
  USER_REFEREES_PAGE: new InternalPage({
    sid: () => 'USER_REFEREES_PAGE',
    name: (userId) => `User Referees ${!!userId ? userId : ''}`,
    path: (codeId = null) =>
      !codeId ? '/referral/:codeId([0-9]*)/referees' : '/referral/' + codeId + '/referees',
    userHasAccess: (user) => User.isValid(user),
  }),
  USER_REFERRALS_PAGE: new InternalPage({
    sid: () => 'USER_REFERRALS_PAGE',
    name: () => 'User Referrals',
    path: () => '/referral',
    userHasAccess: (user) =>
      ((User.isValid(user)) && USER.hasAnyPermission(user, USER_PERMISSION.TYPES.PROJECT, PROJECT.PERMISSIONS.CREATE)) ??
      false,
  }),
  USER_EDIT_PAGE: new InternalPage({
    sid: () => 'USER_EDIT_PAGE',
    name: (userId) => `User Edit ${!!userId ? userId : ''}`,
    path: (userId = null) => (!userId ? '/user/edit/:userId([0-9]*)' : '/user/edit/' + userId),
    userHasAccess: (user) =>
      Utils.define(USER.hasAnyPermissionOfType(user, USER_PERMISSION.TYPES.USER), false),
  }),
  USERS_PAGE: new InternalPage({
    sid: () => 'USERS_PAGE',
    name: () => 'Users',
    path: () => '/user',
    userHasAccess: (user) =>
      (User.isValid(user)) && Utils.define(USER.hasAnyPermissionOfType(user, USER_PERMISSION.TYPES.USER), false),
  }),
  USERGROUPS_PAGE: new InternalPage({
    sid: () => 'USERGROUPS_PAGE',
    name: () => 'User Groups',
    path: () => '/usergroups',
    userHasAccess: (user) =>
      (User.isValid(user)) && Utils.define(USER.hasAnyPermissionOfType(user, USER_PERMISSION.TYPES.USERGROUP), false),
  }),
  USER_GROUP_PAGE: new InternalPage({
    sid: () => 'USER_GROUP_PAGE',
    name: (id) => `User Group ${!!id ? id : ''}`,
    path: (id = null) => (!id ? '/usergroups/:id([0-9]*)' : '/usergroups/' + id),
    userHasAccess: (user) =>
      Utils.define(USER.hasAnyPermissionOfType(user, USER_PERMISSION.TYPES.USERGROUP), false),
  }),
  // ----- ADMIN MENU ITEM
  PROJECT_CREATE_PAGE: new InternalPage({
    sid: () => 'PROJECT_CREATE_PAGE',
    name: () => 'Create Project',
    path: () => '/admin/project/create/',
    userHasAccess: (user) =>
      USER.hasAnyPermission(user, USER_PERMISSION.TYPES.PROJECT, PROJECT.PERMISSIONS.CREATE) ??
      false,
  }),
  PROJECT_COPY_PAGE: new InternalPage({
    sid: () => 'PROJECT_COPY_PAGE',
    name: () => 'Copy Project',
    path: (id = null) => (!id ? '/admin/projects/:id([0-9]*)/copy' : '/admin/projects/' + id + '/copy'),
    userHasAccess: (user) =>
      USER.hasAnyPermission(user, USER_PERMISSION.TYPES.PROJECT, PROJECT.PERMISSIONS.CREATE) ??
      false,
  }),
  PROJECT_PAGE: new InternalPage({
    sid: (id = null) => (Utils.isNull(id) ? 'PROJECT_PAGE' : 'PROJECT_PAGE_' + id),
    name: (id) => (Utils.isNull(id) ? 'Projects' : 'Projects ' + id),
    path: (id = null) => (!id ? '/admin/projects/:id([0-9]*)' : '/admin/projects/' + id),
    userHasAccess: (user) =>
      USER.hasAnyPermission(user, USER_PERMISSION.TYPES.PROJECT, PROJECT.PERMISSIONS.READ) ?? false,
  }),
  PROJECTS_ADMIN_PAGE: new InternalPage({
    sid: () => 'PROJECTS_ADMIN_PAGE',
    name: () => 'Projects',
    path: () => '/admin/projects',
    userHasAccess: (user) => {
      return (User.isValid(user)) && Utils.define(
        USER.getPermissionOfType(user, USER_PERMISSION.TYPES.PROJECT).filter(
          (per) => per?.sid !== PROJECT.PERMISSIONS.TARGET_SEGMENT_EDIT
        ).length > 0,
        false
      );
    },
  }),
  TEST_LANDING_PAGE: new InternalPage({
    sid: (id = null) => (Utils.isNull(id) ? 'TEST_LANDING_PAGE' : 'TEST_LANDING_PAGE_' + id),
    name: (id) => (Utils.isNull(id) ? 'Test Landing' : 'Test Landing ' + id),
    path: (id = null) => (!id ? '/tests/:id([0-9]*)' : '/tests/' + id),
    userHasAccess: () => true,
  }),
  FILES_PAGE: new InternalPage({
    sid: () => 'FILES_PAGE',
    name: () => 'Files',
    path: () => '/admin/file',
    userHasAccess: (user) =>
      (User.isValid(user)) && Utils.define(USER.hasAnyPermissionOfType(user, USER_PERMISSION.TYPES.FILE), false),
  }),
  FILE_CREATE_PAGE: new InternalPage({
    sid: () => 'FILE_CREATE_PAGE',
    name: () => 'Create File',
    path: () => '/admin/file/create/',
    userHasAccess: (user) =>
      Utils.define(
        USER.hasAnyPermission(user, USER_PERMISSION.TYPES.FILE, FILE.PERMISSIONS.CREATE),
        false
      ),
  }),
  FILE_EDIT_PAGE: new InternalPage({
    sid: (id = null) => (Utils.isNull(id) ? 'FILE_EDIT_PAGE' : 'FILE_EDIT_PAGE_' + id),
    name: (id) => (Utils.isNull(id) ? 'Edit File' : 'Edit File ' + id),
    path: (id = null) =>
      Utils.isNull(id) ? '/admin/file/:id([0-9]*)/edit/' : '/admin/file/' + id + '/edit/',
    userHasAccess: (user) =>
      Utils.define(
        USER.hasAnyPermission(user, USER_PERMISSION.TYPES.FILE, FILE.PERMISSIONS.EDIT),
        false
      ),
  }),
  SCREENING_PAGE: new InternalPage({
    sid: () => 'SCREENING_PAGE',
    name: () => 'Screening',
    path: () => '/admin/screening',
    userHasAccess: (user) => (User.isValid(user)) && Utils.define(USER.isAdmin(user)),
  }),
  // ----- MY PROFILE MENU ITEM
  PROFILE_PAGE: new InternalPage({
    sid: () => 'PROFILE_PAGE',
    name: () => 'My Profile',
    path: () => '/profile',
    userHasAccess: (user) => (User.isValid(user)),
  }),
  // ----- OTHER
  PAGE_NOT_FOUND: new InternalPage({
    sid: () => 'PAGE_NOT_FOUND_PAGE',
    name: () => 'Page Not Found',
    path: () => '',
    userHasAccess: (user) => !Utils.isNull(user),
  }),
  UNAUTHORIZED_REDIRECTION: new InternalPage({
    sid: () => 'UNAUTHORIZED_REDIRECTION',
    name: () => 'Unauthorized',
    path: () => '',
    userHasAccess: (user) => Utils.isNull(user),
  }),
};

// Page header menu
export const MENUS = {
  JOIN_HLP: {
    sid: () => 'JOIN_HLP',
    name: () => 'Join HLP',
    pages: () => [{ ...PAGES.USER_REGISTER, name: () => 'Join HLP' }],
    render: (page = null) => (
      <NavLink className='nav-link pointer text-uppercase' to={page?.path()}>
        <div className='taus-btn taus-btn-med taus-btn-sm taus-white-brdr taus-white-text join-hlp-btn'>
          {page?.name()}
        </div>
      </NavLink>
    ),
  },
  PROJECTS: {
    sid: () => 'PROJECTS',
    name: () => 'Projects',
    pages: () => [PAGES.PUBLIC_PROJECTS_LISTING_PAGE],
  },
  TASKS: {
    sid: () => 'TASKS',
    name: () => 'Tasks',
    pages: () => [PAGES.USER_TASKS_PAGE, PAGES.WORKER_TASKS_PAGE],
    grouped: () => true,
  },
  USERS: {
    sid: () => 'USERS',
    name: () => 'Users',
    pages: () => [PAGES.USERS_PAGE, PAGES.USERGROUPS_PAGE, PAGES.USER_REFERRALS_PAGE],
    grouped: () => true,
  },
  ADMIN: {
    sid: () => 'ADMIN',
    name: () => 'Admin',
    pages: () => [PAGES.PROJECTS_ADMIN_PAGE, PAGES.FILES_PAGE, PAGES.SCREENING_PAGE],
    grouped: () => true,
  },
  PROFILE: {
    sid: () => 'PROFILE',
    name: () => 'My Profile',
    pages: () => [PAGES.PROFILE_PAGE],
  },
  SIGN_IN: {
    sid: () => 'SIGN_IN',
    name: () => 'Sign in',
    pages: () => [PAGES.SIGN_IN],
    render: (page = null) => (
      <NavLink className='nav-link pointer' to={page?.path()}>
        {page?.name()}
      </NavLink>
    ),
  },
};
