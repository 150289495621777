// Import CSS
import 'react-toastify/dist/ReactToastify.css';
// Import dependencies
import { toast } from 'react-toastify';

toast.configure();

export default class Notifier {
  static defaultProps = {
    errorMessage: 'Unknown System Error!',
    successMessage: 'Action Succeed!',
    infoMessage: '',
    warningMessage: '',
    settings: {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
    },
  };

  static error(message = Notifier.defaultProps.errorMessage) {
    toast.error(message, { ...Notifier.defaultProps.settings });
  }

  static success(message = Notifier.defaultProps.successMessage) {
    toast.success(message, { ...Notifier.defaultProps.settings });
  }

  static warning(message = Notifier.defaultProps.warningMessage) {
    toast.warn(message, { ...Notifier.defaultProps.settings });
  }

  static info(message = Notifier.defaultProps.infoMessage) {
    toast.info(message, { ...Notifier.defaultProps.settings });
  }
}
