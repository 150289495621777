// CSS
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import '../styles/styles.scss';

// Dependencies
import React from 'react';
import { Plugin, TemplateConnector } from '@devexpress/dx-react-core';
import {
  DataTypeProvider,
  PagingState,
  CustomPaging,
  SortingState,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableSelection,
  TableEditColumn,
  TableEditRow,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-bootstrap4';
import * as yup from 'yup';
import LANGUAGE from '../../../models/Language';

// Api dependencies
import Utils from '../../../utils/Utils';
import DOMObject from '../../../components/DOMObject';
import { DateTimeFormatter, TextFormatter } from '../../../components/tables/Formatters';
import { SelectHeaderCheckbox, SelectRowCheckbox } from '../../../components/tables/Select';
import {
  TableEditColumnOrderLast,
  TableEditAsyncColumnCommandComponent,
  EditingAsyncState,
} from '../../../components/tables/Editor';
import { TableHeaderRowSortLabelComponent } from '../../../components/tables/Sort';
import {
  TableColumnResponsiveState,
  TableColumnResponsive,
} from '../../../components/tables/Responsive';
import { Text } from '../../../components/parameters/TextParameter';
import Task from '../../../models/TaskResult';
import Tooltip from '../../../components/Tooltip';
import { PAGES } from '../../../pages/index';
import Swal from 'sweetalert2';
import UserController from '../../../controllers/UserController';
import Notifier from '../../../components/Notifier';

// ------------------------------------------------- Formatters
const UsernameEditorFormatter = (data) => {
  return (
    <Plugin>
      <TemplateConnector>
        {({ InValidEditingRows }) => {
          let isValid = Utils.isEmpty(InValidEditingRows?.[data?.row?.id]?.username);
          return (
            <Text
              key={data?.row?.id}
              isValid={isValid}
              placeholder='email (Required)'
              defaultValue={Utils.define(data?.value, '')}
              onChange={(value) => {
                data.onValueChange(value);
              }}
            />
          );
        }}
      </TemplateConnector>
    </Plugin>
  );
};

const LastActiveFormatter = (data) =>
  Utils.isNull(data?.value) ? 'Inactive' : DateTimeFormatter(data);

const CountryFormatter = (data) => data?.value;

const UserLanguagesFormatter = (data) => {
  let languages = Utils.defineArray(data?.value, [])
    .sort((a, b) => {
      return (
        LANGUAGE.getSpokenLanguageLevelCode(b?.level) -
        LANGUAGE.getSpokenLanguageLevelCode(a?.level)
      );
    })
    .map((obj) => (
      <em
        key={obj?.id}
        className='badge badge-light border font-weight-normal'
        title={obj?.name + ' - ' + obj?.level}
      >
        {obj?.name + ' - ' + obj?.level}
      </em>
    ));
  return (
    <div key={data?.row?.id} className='user-groups' style={{ whiteSpace: 'normal' }}>
      {languages}
    </div>
  );
};

const UserLanguagesProvider = (props) => (
  <DataTypeProvider
    formatterComponent={UserLanguagesFormatter}
    editorComponent={UserLanguagesFormatter}
    {...props}
  />
);

const UserGroupsFormatter = (data) => {
  let groups = Utils.defineArray(data?.value, [])
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    })
    .map((obj) => (
      <em key={obj?.id} className='badge badge-light border font-weight-normal'>
        {obj?.name}
      </em>
    ));
  return (
    <div key={data?.row?.id} className='user-groups' style={{ whiteSpace: 'normal' }}>
      {groups}
    </div>
  );
};

export const UserTestsFormatter = (data) => {
  const tests = Utils.defineArray(data?.value, [])
    .filter((test) => test.evaluationScore !== null)
    .sort((a, b) => {
      return a?.project?.name.localeCompare(b?.project?.name);
    });

  return (
    <div key={data?.row?.id} className='user-tests' style={{ whiteSpace: 'normal' }}>
      {tests.map((test) => {
        let badgeClass;
        switch (test.evaluationStatus) {
          case Task.EVALUATION_STATUS.PASSED:
            badgeClass = 'badge-success';
            break;
          case Task.EVALUATION_STATUS.FAILED:
            badgeClass = 'badge-danger';
            break;
          default:
            badgeClass = 'badge-light';
        }
        return (
          <em key={test?.id} className={`badge ${badgeClass} border font-weight-normal`}>
            {test?.project?.name} - {test.evaluationScore}
          </em>
        );
      })}
    </div>
  );
};

// ------------------------------------------------------ Edit Columns
const TableEditAsyncColumnCellComponent = ({ isAdmin = false, onDeleteUser = null, ...props }) => {
  let ColumnCellComponent = (props) => {
    const isEnabled = props?.row?.isEnabled;
    let children = [];

    if (isEnabled) {
      if (isAdmin) {
        children.push(
          <Tooltip title='Edit User Details' key={`edit_user_${props?.row?.id}`}>
            <a
              href={PAGES.USER_EDIT_PAGE.path(props?.row?.id)}
              className='btn bg-transparent btn-secondary-outline border-0 px-2 order-1'
            >
              <em
                className='far fa-edit tw-text-gray-700'
                style={{ fontSize: '20px', width: '20px' }}
              />
            </a>
          </Tooltip>
        );

        children.push(
          <Tooltip title='Delete User' key={`delete_user_${props?.row?.id}`}>
            <a
              className='btn bg-transparent btn-secondary-outline border-0 px-2 order-1'
              onClick={() => onDeleteUser(props?.row?.id)}
            >
              <em
                className='far fa-trash-alt tw-text-gray-700'
                style={{ fontSize: '20px', width: '20px' }}
              />
            </a>
          </Tooltip>
        );
      }
    } else {
      children = (
        <span className='tw-block tw-px-[10px] tw-py-[1px] tw-bg-red-200 tw-rounded-full'>
          DELETED
        </span>
      );
    }

    let container = React.createElement(
      'div',
      { className: 'tw-flex tw-justify-end tw-gap-0' },
      children
    );
    return <TableEditColumn.Cell {...props} children={container} />;
  };

  return (
    <Plugin>
      <TemplateConnector>
        {({ InValidEditingRows }) => (
          <ColumnCellComponent
            {...props}
            errors={Utils.define(InValidEditingRows[props?.row?.id])}
          />
        )}
      </TemplateConnector>
    </Plugin>
  );
};

// ------------------------------------------------------------------------------

class UsersTable extends DOMObject {
  static defaultProps = {
    data: [],
    groups: [],
    defaultPageSize: 25,
    defaultStartPage: 0,
    onSelectionChange: null,
    onAddedRowsChange: null,
    addedRows: null,
    onUserDeleted: null,
  };

  async showDeleteConfirmPopup(userId) {
    return Swal.mixin({
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-outline-danger mx-2 px-5 btn-lg font-weight-bold',
        cancelButton: 'btn btn-outline-secondary mx-2 px-5 btn-lg font-weight-bold',
      },
    }).fire({
      title: 'User delete!',
      text:
        `Are you sure you want to delete this user ${userId}?` +
        '. This action is irreversible (for now).',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });
  }

  async handleDelete(userId) {
    let alert = await this.showDeleteConfirmPopup(userId);
    if (alert.value !== true) return;

    try {
      await UserController.delete(userId);
      this.props.onUserDeleted();
    } catch (e) {
      Notifier.error(e.message);
    }
  }

  componentDidMount() {
    super.componentDidMount();
    try {
      this.DOMObject.querySelector('.table-responsive').style.overflow = 'unset';
    } catch (e) {
      this.errorHandler(e);
    }
  }

  render() {
    let props = this.getTableProps();

    const TableCell = (props) => {
      const isEnabled = props?.row?.isEnabled;
      if (!isEnabled) {
        return <Table.Cell className={'deleted-user'} {...props} />;
      } else {
        return <Table.Cell {...props} />;
      }
    };

    return (
      <Grid
        rows={Utils.defineArray(this.props?.data, [])}
        columns={this.props.tableColumns}
        getRowId={(row) => row?.id}
      >
        <SortingState {...props?.SortingState} />
        <PagingState {...props.PagingState} />
        <SelectionState {...props?.SelectionState} />
        <EditingAsyncState {...props?.EditingAsyncState} />
        <TableColumnResponsiveState {...props.TableResponsiveState} />

        <DataTypeProvider
          for={['username']}
          formatterComponent={TextFormatter}
          editorComponent={UsernameEditorFormatter}
        />
        <DataTypeProvider for={['country']} formatterComponent={CountryFormatter} />
        <DataTypeProvider for={['lastActive']} formatterComponent={LastActiveFormatter} />
        <DataTypeProvider for={['groups']} formatterComponent={UserGroupsFormatter} />
        <DataTypeProvider for={['spokenLanguages']} formatterComponent={UserLanguagesFormatter} />
        <DataTypeProvider for={['tests']} formatterComponent={UserTestsFormatter} />
        <DataTypeProvider
          for={['ethnicGroup', 'gender', 'spokenDialect', 'yearOfBirth']}
          formatterComponent={TextFormatter}
        />

        <UserLanguagesProvider for={['languages']} {...props?.UserLanguagesProvider} />

        <CustomPaging totalCount={this.props?.totalSize} />
        <IntegratedSelection />

        <Table cellComponent={TableCell} />
        <TableColumnResizing {...props?.TableColumnResizing} />
        <TableColumnVisibility {...props?.TableColumnVisibility} />

        <TableHeaderRow
          showSortingControls={true}
          sortLabelComponent={TableHeaderRowSortLabelComponent}
        />
        <TableSelection
          showSelectAll={true}
          cellComponent={SelectRowCheckbox}
          headerCellComponent={SelectHeaderCheckbox}
        />
        <TableEditColumn {...props.TableEditColumn} />
        <TableEditColumnOrderLast />

        <TableColumnResponsive />
        <TableEditRow />
        <PagingPanel pageSizes={[10, 25, 50, 100, 200, 300]} />
      </Grid>
    );
  }

  getTableProps() {
    return {
      SelectionState: {
        selection: this.props?.selection ?? [],
        onSelectionChange: this.props?.onSelectionChange ?? null,
      },
      SortingState: {
        columnExtensions: this.props.tableColumns?.map((obj) => ({
          columnName: obj.name,
          sortingEnabled: Utils.defineBoolean(obj.sort, false),
        })),
        sorting: this.props?.sorting,
        onSortingChange: this.props?.onSortingChange,
      },
      TableResponsiveState: {
        tableDOM: this.DOMObject,
        defaultMinWidth: 150,
        columnsPriority: this.props.tableColumns
          ?.filter((obj) => !Utils.isNull(obj.responsivePriority))
          .map((obj) => ({ columnName: obj.name, priority: obj.responsivePriority })),
      },
      PagingState: {
        currentPage: this.props?.page,
        pageSize: this.props?.pageSize,
        onCurrentPageChange: this.props?.onPageChange,
        onPageSizeChange: this.props?.onPageSizeChange,
      },
      EditingAsyncState: {
        columnExtensions: this.props.tableColumns?.map((obj) => ({
          columnName: obj.name,
          editingEnabled: Utils.defineBoolean(obj.editable, false),
          createRowChange: Utils.define(obj.createRowChange, null),
        })),
        addedRows: Utils.defineArray(this.props?.addedRows),
        onAddedRowsInsert: this.props?.onAddedRowsInsert,
        onAddedRowsCancel: this.props?.onAddedRowsCancel,
        onAddedRowsUpdate: this.props?.onAddedRowsUpdate,
        onRowUpdateCommitAsync: this.props?.onRowUpdateCommitAsync,
        onRowDeleteCommitAsync: this.props?.onRowDeleteCommitAsync,
        onRowInsertCommitAsync: this.props?.onRowInsertCommitAsync,
        validationSchema: yup.object().shape({
          username: yup.string().email('username is not an email').required(),
        }),
        showEditCommand: false,
      },

      Table: {
        columnExtensions: this.props.tableColumns
          ?.map((obj) => ({ columnName: obj.name, align: Utils.define(obj.align, 'left') }))
          .map((obj) =>
            Utils.isNull(obj.minWidth)
              ? { ...obj, width: 'auto' }
              : { ...obj, width: obj.minWidth + 'vmin' }
          ),
        messages: { noData: 'No users' },
      },
      TableColumnResizing: {
        defaultColumnWidths: this.props.tableColumns?.map((obj) => ({
          columnName: obj.name,
          width: Utils.define(obj.width, 'auto'),
        })),
        resizingMode: 'nextColumn',
        minColumnWidth: 150,
      },
      TableColumnVisibility: {
        hiddenColumnNames: this.props.hiddenColumns,
      },
      UserGroupsProvider: {
        groupProps: { editValues: Utils.defineArray(this.props?.groups, []) },
      },
      TableEditColumn: {
        width: 105,
        showEditCommand: true,
        showDeleteCommand: true,
        showAddCommand: false,
        commandComponent: (props) => <TableEditAsyncColumnCommandComponent {...props} />,
        cellComponent: (props) => (
          <TableEditAsyncColumnCellComponent
            {...props}
            isAdmin={this.props?.isAdmin}
            onDeleteUser={this.handleDelete.bind(this)}
          />
        ),
      },
    };
  }
}

export default UsersTable;
