import { useState, useEffect, useRef } from 'react';

import { encodeWAV, float32Flatten } from '../../../utils/Recorder';
import Notifier from '../../../components/Notifier';
import { detectIOS } from '../../../utils/Device';

const initialState = {
  recordingSeconds: 0,
  initRecording: false,
  saveRecording: false,
  mediaStream: null,
  audio: null,
  audioBlob: null,
  unsavedAudio: false,
  message: '',
};

export default function useRecorder(data, sampleRate = 16000, maxRecordingSeconds = 300) {
  const [recorderState, setRecorderState] = useState(() => {
    if (data) {
      const audio = window.URL.createObjectURL(data);
      return { ...initialState, audio, audioBlob: data };
    }
    return initialState;
  });

  const chunks = useRef([]);

  useEffect(() => {
    if (recorderState.message) {
      Notifier.info(recorderState.message);
    }
  }, [recorderState.message]);

  useEffect(() => {
    let recordingIntervalId = null;

    if (recorderState.initRecording)
      recordingIntervalId = setInterval(() => {
        setRecorderState((prevState) => {
          if (prevState.recordingSeconds === maxRecordingSeconds) {
            clearInterval(recordingIntervalId);
            return {
              ...prevState,
              message: 'Max segment recording time reached',
              saveRecording: true,
            };
          } else {
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds + 1,
            };
          }
        });
      }, 1000);
    else {
      clearInterval(recordingIntervalId);
    }
    return () => {
      clearInterval(recordingIntervalId);
    };
  }, [recorderState.initRecording, maxRecordingSeconds]);

  useEffect(() => {
    let context = null;

    if (recorderState.mediaStream) {
      chunks.current = [];
      context = new AudioContext({ sampleRate, latencyHint: 'playback' });
      const source = context.createMediaStreamSource(recorderState.mediaStream);

      if (detectIOS()) {
        const BUFFER_SIZE = 4096;
        const INPUT_CHANNELS = 1;
        const OUTPUT_CHANNELS = 1;

        const scriptProcessorNode = context.createScriptProcessor(
          BUFFER_SIZE,
          INPUT_CHANNELS,
          OUTPUT_CHANNELS
        );
        source.connect(scriptProcessorNode).connect(context.destination);

        scriptProcessorNode.onaudioprocess = (e) => {
          const chunk = new Float32Array(BUFFER_SIZE);
          e.inputBuffer.copyFromChannel(chunk, 0);
          chunks.current.push(chunk);
        };
      } else {
        context.audioWorklet
          .addModule(`${process.env.PUBLIC_URL}/worklet/recorder.worklet.js`)
          .then(() => {
            const recorder = new AudioWorkletNode(context, 'recorder.worklet');
            source.connect(recorder).connect(context.destination);

            recorder.port.onmessage = (e) => {
              chunks.current.push(e.data);
            };
          })
          .catch((error) => {
            Notifier.error(error.message);
            console.log(error);
          });
      }
    }

    return () => {
      if (recorderState.mediaStream) {
        recorderState.mediaStream.getAudioTracks().forEach((track) => {
          track.stop();
        });
        context.close();
      }
    };
  }, [recorderState.mediaStream, sampleRate]);

  useEffect(() => {
    if (recorderState.saveRecording) {
      const audioBlob = new Blob([encodeWAV(float32Flatten(chunks.current), sampleRate)], {
        type: 'audio/wav',
      });
      setRecorderState({
        ...initialState,
        audio: window.URL.createObjectURL(audioBlob),
        audioBlob,
        unsavedAudio: true,
      });
    }

    return () => {
      window.URL.revokeObjectURL(recorderState.audio);
    };
  }, [recorderState.audio, recorderState.saveRecording, sampleRate]);

  return {
    recorderState,
    startRecording: () => startRecording(setRecorderState),
    cancelRecording: () => cancelRecording(setRecorderState),
    saveRecording: () => saveRecording(setRecorderState),
  };
}

const startRecording = async (setRecorderState) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    setRecorderState((prevState) => {
      return {
        ...prevState,
        initRecording: true,
        mediaStream: stream,
      };
    });
  } catch (error) {
    if (error.name === 'OverconstrainedError') {
      Notifier.error('Microphone is not detected');
    } else if (error.name === 'NotAllowedError') {
      Notifier.error('Recording permission was denied');
    }
  }
};

const cancelRecording = (setRecorderState) => {
  setRecorderState((prevState) => ({
    ...initialState,
    audio: prevState.audio,
    audioBlob: prevState.audioBlob,
    unsavedAudio: prevState.unsavedAudio,
  }));
};

const saveRecording = (setRecorderState) => {
  setRecorderState((prevState) => {
    return {
      ...prevState,
      saveRecording: true,
    };
  });
};
