import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Notifier from '../../../components/Notifier';

import UserController from '../../../controllers/UserController';
import UserGroupController from '../../../controllers/UserGroupController';
import ProjectController from '../../../controllers/ProjectController';
import ProjectMembersTabView from '../views/ProjectMembersTabView';

export default class ProjectMembersTabState extends PureComponent {
  state = {
    isBusy: true,
    users: [],
    groups: [],
    roles: [],
    projectMembers: [],
  };

  static propTypes = {
    project: PropTypes.object.isRequired,
    isEditable: PropTypes.bool,
    canSendMessage: PropTypes.bool,
    messageButtonClicked: PropTypes.func,
  };

  static defaultProps = {
    isEditable: false,
    canSendMessage: false,
  };

  componentDidMount() {
    this.initMembers();
  }

  // ------------------------------------------------------------------------------------
  // ----------------------- Listeners --------------------------------------------------
  // ------------------------------------------------------------------------------------
  async onDeleteMemberClicked(id = null) {
    try {
      this.setState({ isBusy: true });
      const member = this.state.projectMembers.find((obj) => obj.id === id);
      await this.removeProjectMember(member).then(() => {
        this.setState({
          projectMembers: this.state.projectMembers.filter((obj) => obj.id !== id),
        });
      });
    } catch (e) {
      this.errorHandler(e);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  async onCreateMemberClick(selectedUsersIds = [], selectedRoleId = null, selectedLimit = null) {
    try {
      this.setState({ isBusy: true });

      const role = this.state.roles?.find((obj) => obj.id === selectedRoleId) ?? null;
      const existingMember =
        this.state.projectMembers.find((obj) => {
          return selectedUsersIds?.includes(obj.id) && obj?.role?.id === selectedRoleId;
        }) ?? null;
      if (!!existingMember) {
        let user = !!existingMember.user.username
          ? existingMember.user.username
          : existingMember.user.name;
        throw new Error(`User ${user} already a ${role?.sid}`);
      }

      await this.addProjectMembers(selectedUsersIds, role?.sid, selectedLimit);
      const projectMembers = await this.getProjectMembers();
      this.setState({
        projectMembers: projectMembers,
      });
    } catch (e) {
      this.errorHandler(e);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  // -------------------------------------------------------------------------
  // ------------------------ Data -------------------------------------------
  // -------------------------------------------------------------------------
  async removeProjectMember(member = null) {
    return ProjectController.deleteMember(this.props.project.id, member?.id);
  }

  async addProjectMembers(members = null, roleSid = null, limit = null) {
    return ProjectController.createMembers(
      this.props.project?.id,
      roleSid,
      members,
      limit === null || parseInt(limit) === 0 ? null : parseInt(limit)
    );
  }

  async removeProjectMembers(members = null) {
    return ProjectController.deleteMembers(this.props.project?.id, members);
  }

  async updateReviewConstraints(reviewerId, newReviewConstraints) {
    try {
      this.setState({ isBusy: true });
      await ProjectController.updateMember(
        this.props.project?.id,
        reviewerId,
        newReviewConstraints
      );
      const projectMembers = await this.getProjectMembers();
      this.setState({ projectMembers });
    } catch (error) {
      Notifier.error(error.message);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  async updateLimit(memberId, newReviewConstraints, limit) {
    try {
      this.setState({ isBusy: true });
      await ProjectController.updateMember(
        this.props.project?.id,
        memberId,
        newReviewConstraints,
        limit === '' ? null : parseInt(limit)
      );
      const projectMembers = await this.getProjectMembers();
      this.setState({ projectMembers });
    } catch (error) {
      Notifier.error(error.message);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  async updateMemberPosition(memberId, newPosition) {
    try {
      this.setState({ isBusy: true });
      await ProjectController.updateMemberPosition(this.props.project?.id, memberId, newPosition);
      const projectMembers = await this.getProjectMembers();
      this.setState({ projectMembers });
    } catch (error) {
      Notifier.error(error.message);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  async getUsers() {
    return UserController.getAll(
      { pageSize: 50000 },
      { 'serialize-permission': false, 'serialize-minimum': true }
    ).then((res) => res?.page ?? []);
  }

  async getGroups() {
    return UserGroupController.getAll({ pageSize: 50000 }, { 'serialize-permission': false }).then(
      (res) => res?.page ?? []
    );
  }

  async getRoles() {
    return ProjectController.getMemberRoles();
  }

  async getProjectMembers() {
    return ProjectController.getMembers(this.props?.project?.id, { 'serialize-permission': false });
  }

  async initMembers() {
    try {
      this.setState({ isBusy: true });
      const [users, groups, projectMembers, roles] = await Promise.all([
        this.getUsers(),
        this.getGroups(),
        this.getProjectMembers(),
        this.getRoles(),
      ]);
      this.setState({
        users: users,
        groups: groups,
        projectMembers: projectMembers,
        roles: roles,
      });
    } catch (e) {
      this.errorHandler(e);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  render() {
    return (
      <>
        <ProjectMembersTabView
          users={this.state.users}
          groups={this.state.groups}
          projectMembers={this.state.projectMembers}
          roles={this.state.roles}
          projectRoleNotifications={this.props.project.roleNotifications}
          isBusy={this.state.isBusy}
          isEditable={this.props.isEditable}
          onMemberAddClick={this.onCreateMemberClick.bind(this)}
          updateReviewConstraints={this.updateReviewConstraints.bind(this)}
          updateMemberPosition={this.updateMemberPosition.bind(this)}
          updateLimit={this.updateLimit.bind(this)}
          onMemberDeleteClick={this.onDeleteMemberClicked.bind(this)}
          canSendMessage={this.props.canSendMessage}
        />
      </>
    );
  }

  errorHandler(e) {
    console.log(e);
    Notifier.error(e['userMessage']);
  }
}
