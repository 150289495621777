import { Select } from '../../../../components/parameters/SelectParameter';
import React, { useState } from 'react';

const WorkerDialectView = ({
  dialects = [],
  selectedDialect = null,
  isEditable = false,
  onSave = null,
}) => {
  const dialectsOptions = dialects?.map((item) => ({ name: item, value: item })) ?? [];
  let [value, setValue] = useState(selectedDialect ? [selectedDialect] : null);

  const onSaveButtonClick = () => {
    if (onSave) onSave(value?.[0] ?? null);
  };

  return (
    <div className='mb-2 d-flex align-items-center'>
      <div className='pr-2 font-weight-bold'>Dialect:</div>
      <Select
        className='pl-0'
        style={{ minWidth: '200px' }}
        options={dialectsOptions}
        disabled={!isEditable}
        value={value}
        typeOptions={{ isSearchable: false, isClearable: false }}
        onChange={setValue}
      />
      {(value?.[0] ?? null) !== selectedDialect && (
        <button
          type='button'
          key='save-button'
          className='btn mx-2 btn-outline-primary'
          onClick={onSaveButtonClick}
        >
          Save
        </button>
      )}
    </div>
  );
};

export default WorkerDialectView;
