import { appStore } from '../redux/stores/App';
import { ACTIONS as SESSION_ACTIONS } from '../redux/actions/session';
import Utils from './Utils';
import ApiException from '../models/ApiException';

/**
 * Class with static methods for handling errors.
 * @see defaultProps
 */
export default class ErrorHandler {
  /**
   * Handles an error according to the properties passes on "options" object
   */
  static errorHandle(e = {}) {
    try {
      e = ApiException.toApiException(e);
      if (Utils.isEmpty(e)) return;
      console.error(e);
      // If the error comes from authentication go to login pages
      if (e.status === 401) appStore.dispatch(SESSION_ACTIONS.userLogout());
    } catch (e) {
      console.error(e);
    }
  }
}
