// Dependencies
import React from 'react';

// Sources
import DOMObject from '../../components/DOMObject';
import { EXTERNAL_PAGES } from '../index';

// Component
export default class Footer extends DOMObject {
  render() {
    return (
      <div className='col-xs-12 col-md-12 px-0 footer taus-footer bg-taus'>
        <div className='container'>
          <ul>
            <li>
              <a
                href={EXTERNAL_PAGES.TAUS_WORKERS_AGREEMENT_PAGE.path()}
                target='blank'
                style={{ color: '#fff' }}
              >
                HLP Worker Agreement
              </a>
            </li>
            <li>
              <a
                href={EXTERNAL_PAGES.TAUS_PRIVACY_POLICY_PAGE.path()}
                target='blank'
                style={{ color: '#fff' }}
              >
                HLP Privacy & Cookies Policy
              </a>
            </li>
            <li>
              <a
                href={EXTERNAL_PAGES.TAUS_CONTACT_US_PAGE.path()}
                target='blank'
                style={{ color: '#fff' }}
              >
                Contact
              </a>
            </li>
            <li>
              <a href={EXTERNAL_PAGES.TAUS_PAGE.path()} target='blank' style={{ color: '#fff' }}>
                About TAUS
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
