import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Notifier from '../components/Notifier';
import UserController from '../controllers/UserController';
import UserGroupController from '../controllers/UserGroupController';
import MessageBox from '../components/messageBox/MessageBox';
import MessageController from '../controllers/MessageController';
import { getUserOptions } from '../utils/Select';
import { editorStateToHtml, htmlToEditorState } from '../utils/RichTextEditor';

export const MessageBoxContext = createContext(null);

export const MessageBoxProvider = ({ children }) => {
  // Box controlls (open, close, minimize)
  const [open, setOpen] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const openMessageBox = useCallback((ids = []) => {
    setMinimized(false);
    setOpen(true);
    if (ids) {
      setRecipientsQueued(ids);
    }
  }, []);
  const handleCancel = () => {
    setOpen(false);
    setRecipients([]);
    setRecipientsQueued([]);
    setOptions([]);
    setSubject('');
    setContent();
  };

  // Erorrs
  const [errors, setErrors] = useState({
    recipients: '',
    subject: '',
  });

  // Subject & Content
  const [subject, setSubject] = useState('');
  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, subject: '' }));
  };
  const [content, setContent] = useState(() => htmlToEditorState(''));

  // Recipient options
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  useEffect(() => {
    if (open) {
      setLoadingOptions(true);
      Promise.all([getUsers(), getGroups()]).then(([users, groups]) => {
        setOptions(getUserOptions(users, groups, 'All'));
        setLoadingOptions(false);
      });
    }
  }, [open]);

  // Recipients
  const [recipients, setRecipients] = useState([]);
  const onRecipientsChange = (options = []) => {
    setRecipients(options);
    setErrors((prevErrors) => ({ ...prevErrors, recipients: '' }));
  };

  const [recipientsQueued, setRecipientsQueued] = useState([]);
  useEffect(() => {
    if (!loadingOptions) {
      setRecipients(
        options
          .flatMap((r) => r.options)
          .filter((option) => recipientsQueued.includes(option.value))
      );
    }
  }, [recipientsQueued, options, loadingOptions]);

  const [loading, setLoading] = useState(false);
  const [headerText, setHeaderText] = useState('New message');
  const handleSend = (e) => {
    e.preventDefault();
    if (!validateInput()) {
      return;
    }
    setLoading(true);
    setHeaderText('Sending a message...');
    MessageController.sendMessage({
      recipientsIds: recipients.map((recipient) => recipient.value),
      subject,
      body: editorStateToHtml(content),
    })
      .then(() => Notifier.success('Message sent!'))
      .catch((e) => Notifier.error(e.message))
      .finally(() => {
        setLoading(false);
        setHeaderText('New message');
      });
  };

  const validateInput = () => {
    let valid = true;
    if (!recipients.length) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        recipients: 'Recipients are required!',
      }));
      valid = false;
    }
    if (!subject) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        subject: 'Subject is required!',
      }));
      valid = false;
    }
    return valid;
  };

  return (
    <MessageBoxContext.Provider value={openMessageBox}>
      {children}
      <MessageBox
        isOpen={open}
        minimized={minimized}
        setMinimized={setMinimized}
        onCancel={handleCancel}
        onSend={handleSend}
        subject={subject}
        onSubjectChange={handleSubjectChange}
        content={content}
        onContentChange={setContent}
        loading={loading}
        loadingOptions={loadingOptions}
        headerText={headerText}
        onRecipientsChange={onRecipientsChange}
        recipients={recipients}
        recipientOptions={options}
        errors={errors}
      />
    </MessageBoxContext.Provider>
  );
};

export const useMessageBox = () => {
  const openMessageBox = useContext(MessageBoxContext);
  if (!openMessageBox) {
    throw new Error(
      'No context provider detected! Please wrap the component with MessageBoxProvider component.'
    );
  }
  return openMessageBox;
};

export const withMessageBox = (Component) => {
  const MessageBoxComponent = (props) => (
    <MessageBoxContext.Consumer>
      {(value) => <Component {...props} openMessageBox={value} />}
    </MessageBoxContext.Consumer>
  );
  MessageBoxComponent.displayName =
    'withMessageBox(' + (Component.displayName || Component.name) + ')';
  return MessageBoxComponent;
};

async function getUsers() {
  return UserController.getAll(
    { pageSize: 50000 },
    { 'serialize-permission': false, 'serialize-minimum': true }
  ).then((res) => res?.page ?? []);
}

async function getGroups() {
  return UserGroupController.getAll({ pageSize: 50000 }, { 'serialize-permission': false }).then(
    (res) => res?.page ?? []
  );
}
