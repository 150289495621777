// Css
import './SegmentViewStyles.scss';

// External dependencies
import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';

// Internal dependencies
import LANGUAGE from '../../../models/Language';
import Utils from '../../../utils/Utils';
import SEGMENT from '../../../models/Segment';
import Tooltip from '../../../components/Tooltip';
import LanguageDisplay from './LanguageDisplay';
import Button from '../../../components/Button';

const reviewToClass = {
  [SEGMENT.REVIEWS.APPROVED]: 'text-success',
  [SEGMENT.REVIEWS.REJECTED]: 'text-danger',
  [SEGMENT.REVIEWS.UNREVIEWED]: '',
};

// ------------------------------------------------------------------------------------------
// ----------------------------------- Source Segment ---------------------------------------
//-------------------------------------------------------------------------------------------

export const SourceSegmentSingle = ({ source = null, className = null }) => {
  const { contentHtml, glossaryHtml } = applyGlossary(source);

  return (
    <div className={!className ? 'taus-source-segment-single pb-3 pr-sm-3 flex-fill' : className}>
      <label className='font-weight-bold'>
        <LanguageDisplay language={source?.language} />
      </label>
      <div
        className='mb-2 d-flex p-2 border rounded'
        style={{ minHeight: '79px' }}
        dangerouslySetInnerHTML={{ __html: contentHtml }}
      />
      {!source?.metadata?.description ? null : <div>{source.metadata.description}</div>}
      {!!glossaryHtml ? <div dangerouslySetInnerHTML={{ __html: glossaryHtml }} /> : null}
    </div>
  );
};

const applyGlossary = (source) => {
  let content = SEGMENT.getContentData(source);
  if (!source.glossary) return { contentHtml: content, glossaryHtml: null };

  let words = [];
  Object.keys(source.glossary).forEach((key) => {
    let regex = new RegExp(`\\b${key}\\b`, 'gmi');
    let res = content?.match(regex);
    if (res?.length > 0) {
      words.push(`<span class="text-bold">${key}</span>:${source.glossary[key]}`);
      let alreadyFound = {};
      res.forEach((val) => {
        if (!alreadyFound[val]) {
          content = content.replaceAll(
            val,
            `<span class="text-bold" style="background:#FFFF00">&nbsp;${val}&nbsp;</span>`
          );
          alreadyFound[val] = true;
        }
      });
    }
  });
  words = words.length === 0 ? null : `<span>Glossary: <br/>${words.join('<br/>')}</span>`;
  return {
    contentHtml: `<span>${content}</span>`,
    glossaryHtml: words,
  };
};

export const SourceSegmentMulti = ({
  segment = null,
  sources = null,
  activeSource = null,
  className = null,
}) => (
  <div className={!className ? 'taus-source-segment-multi pb-3 pr-md-3 flex-fill' : className}>
    <Tabs id={segment?.id} key={activeSource?.id} defaultActiveKey={activeSource?.id}>
      {sources?.map((obj) => {
        return (
          <Tab
            title={
              <>
                <LanguageDisplay language={obj?.language} />
              </>
            }
            eventKey={obj?.id}
            key={obj?.id}
          >
            <textarea
              readOnly={true}
              rows='3'
              className='form-control bg-white'
              defaultValue={Utils.define(SEGMENT.getContentData(obj), '')}
            />
          </Tab>
        );
      })}
    </Tabs>
  </div>
);

// ------------------------------------------------------------------------------------------
// ----------------------------------- Target Segment ---------------------------------------
//-------------------------------------------------------------------------------------------

export const TargetSegment = ({
  segment = null,
  isBusy = false,
  readOnly = true,
  review = null,
  isUpdated = false,
  onChange = null,
  footer = null,
  info = null,
  className = null,
}) => {
  let bgColor =
    review === SEGMENT.REVIEWS.APPROVED
      ? '#d4edda'
      : review === SEGMENT.REVIEWS.REJECTED
      ? '#f8d7da'
      : !!isUpdated
      ? '#e9ecef'
      : '#ffffff';

  const rtlLangCode = LANGUAGE.getRtlCode(segment.language);

  let rtlTextDirection = rtlLangCode ? 'rtl' : null;
  let rtlTextLineHeight = rtlLangCode === 'UR-PK' ? 1.8 : null;

  className = !className ? 'taus-target-segment flex-fill' : className;
  if (isBusy) className = className + ' whirl sphere';

  const reviewStatus = (!!segment?.content) ? ( SEGMENT.isReviewed(segment) ? segment?.status :SEGMENT.REVIEWS.UNREVIEWED) : null;

  return (
    <>
      <div className={className}>
        <div className={`${!!reviewStatus && 'tw-flex tw-flex-row tw-gap-2 tw-justify-between tw-items-center'}`}>
          {!!reviewStatus && <span className={reviewToClass[reviewStatus]}>Status: {reviewStatus}</span> }
          <label className='font-weight-bold'>
            <LanguageDisplay language={segment?.language} />
          </label>
        </div>
        <textarea
          id='TranslationBox-targetText'
          readOnly={readOnly}
          rows='3'
          className='form-control mb-2'
          style={{
            backgroundColor: bgColor,
            direction: rtlTextDirection,
            lineHeight: rtlTextLineHeight,
          }}
          placeholder={
            Utils.isEmpty(SEGMENT.getContentData(segment))
              ? ''
              : '(Last Saved) ' + SEGMENT.getContentData(segment)
          }
          defaultValue={Utils.define(SEGMENT.getContentData(segment), '')}
          onChange={Utils.define(onChange)}
        />
        {Utils.define(footer)}
      </div>
      {info}
    </>
  );
};

export const TargetSegmentTranslationFooter = ({
  isValid = true,
  errorMessage = null,
  isChanged = false,
  onSaveButtonClick = null,
  className = null,
}) => {
  const disabled = !isValid || !isChanged;
  const tooltipTitle = !isValid
    ? 'The Save button will be enabled once all the issues flagged in the red warning messages are fixed'
    : '';

  return (
    <div className={!className ? 'row justify-content-between col-12 px-0 mx-0' : className}>
      {!!isValid ? (
        <div className='col px-0' />
      ) : (
        <div className='text-danger px-1 col' style={{ fontSize: 'small' }}>
          {Utils.define(errorMessage, '')}
        </div>
      )}
      {isChanged && (
        <div>
          <Tooltip title={tooltipTitle}>
            <Button
              label='Save'
              priority='secondary'
              disabled={disabled}
              classes='ml-2 tw-cursor-pointer disabled:tw-cursor-not-allowed'
              handleClick={Utils.define(onSaveButtonClick)}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export const TargetSegmentReviewFooter = ({
  isValid = true,
  errorMessage = null,
  isChanged = false,
  review = null,
  onRejectButtonClick = null,
  onApproveButtonClick = null,
  className = null,
}) => (
  <div className={!className ? 'row justify-content-between col-12 px-0 mx-0 mt-3' : className}>
    <div className='text-danger px-1 col' style={{ fontSize: 'small' }}>
      {Utils.define(errorMessage, '')}
    </div>
    <div className='row col-auto px-0 mx-0'>
      <Button
        label='Reject'
        priority='danger'
        disabled={review === SEGMENT.REVIEWS.REJECTED}
        classes='ml-2 col-auto tw-cursor-pointer disabled:tw-cursor-not-allowed'
        handleClick={Utils.define(onRejectButtonClick)}
      />
      <Button
        label={isChanged === true ? 'Update & Approve' : 'Approve'}
        priority='success'
        disabled={isValid !== true || (review === SEGMENT.REVIEWS.APPROVED && isChanged !== true)}
        classes='ml-2 col-auto tw-cursor-pointer disabled:tw-cursor-not-allowed'
        handleClick={Utils.define(onApproveButtonClick)}
      />
    </div>
  </div>
);

export const TargetSegmentGoogleQEInfo = ({ editDistance = null, className = null }) => (
  <div className={!className ? 'pl-3' : className}>
    <p className='font-weight-bold'>Info</p>
    <p>Machine Translation: {editDistance ?? 'N/A'}</p>
  </div>
);
