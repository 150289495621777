export default class UserProfile {
  static getFormattedDate(timestamp = null) {
    const date = new Date(timestamp);

    const formattedDate =
      date.getFullYear() +
      '-' +
      (date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
      '-' +
      date.getDate();

    return formattedDate;
  }

  static PROFILE_PREFIX = {
    MR: 'Mr.',
    MS: 'Ms.',
  };

  static COUNTRIES = {
    AFGHANISTAN: { name: 'Afghanistan' },
    ALAN_ISLANDS: { name: 'Aland Islands' },
    ALBANIA: { name: 'Albania' },
    ALGERIA: { name: 'Algeria' },
    AMERICAN_SAMOA: { name: 'American Samoa' },
    ANDORRA: { name: 'Andorra' },
    ANGOLA: { name: 'Angola' },
    ANGUILLA: { name: 'Anguilla' },
    ANTARCTICA: { name: 'Antarctica' },
    ANTIGUA_AND_BARBUDA: { name: 'Antigua and Barbuda' },
    ARGENTINA: { name: 'Argentina' },
    ARMENIA: { name: 'Armenia' },
    ARUBA_NL_ANTILLES: { name: 'Aruba (Netherlands Antilles)' },
    ASHMORE_AND_CARTIER_ISLANDS: { name: 'Ashmore and Cartier Islands' },
    AUSTRALIA: { name: 'Australia' },
    AUSTRIA: { name: 'Austria' },
    AZERBAIJAN: { name: 'Azerbaijan' },
    BAHAMAS: { name: 'Bahamas' },
    BAHRAIN: { name: 'Bahrain' },
    BANGLADESH: { name: 'Bangladesh' },
    BARBADOS: { name: 'Barbados' },
    BASSAS_DA_INDIA: { name: 'Bassas da India' },
    BELARUS: { name: 'Belarus' },
    BELGIUM: { name: 'Belgium' },
    BELIZE: { name: 'Belize' },
    BENIN: { name: 'Benin' },
    BERMUDA: { name: 'Bermuda' },
    BHUTAN: { name: 'Bhutan' },
    BOLIVIA: { name: 'Bolivia' },
    BONAIRE_NL_ANTILLES: { name: 'Bonaire (Netherlands Antilles)' },
    BOSNIA_AND_HERZEGOVINA: { name: 'Bosnia and Herzegovina' },
    BOTSWANA: { name: 'Botswana' },
    BOUVET_ISLAND: { name: 'Bouvet Island' },
    BRAZIL: { name: 'Brazil' },
    BRITISH_INDIAN_OCEAN_TERRITORY: { name: 'British Indian Ocean Territory' },
    BRUNEI: { name: 'Brunei' },
    BULGARIA: { name: 'Bulgaria' },
    BURKINA_FASO: { name: 'Burkina Faso' },
    BURUNDI: { name: 'Burundi' },
    CAMBODIA: { name: 'Cambodia' },
    CAMEROON: { name: 'Cameroon' },
    CANADA: { name: 'Canada' },
    CAPE_VERDE: { name: 'Cape Verde' },
    CAYMAN_ISLANDS: { name: 'Cayman Islands' },
    CENTRAL_AFRICAN_REPUBLIC: { name: 'Central African Republic' },
    CHAD: { name: 'Chad' },
    CHILE: { name: 'Chile' },
    CHINA: { name: 'China' },
    CHRISTMAS_ISLAND: { name: 'Christmas Island' },
    CLIPPERTON_ISLAND: { name: 'Clipperton Island' },
    COCOS_KEELING_ISLANDS: { name: 'Cocos (Keeling) Islands' },
    COLOMBIA: { name: 'Colombia' },
    COMOROS: { name: 'Comoros' },
    CONGO_DEMOCRATIC_REPUBLIC_OF_THE: { name: 'Congo, Democratic Republic of the' },
    CONGO_REPUBLIC_OF_THE: { name: 'Congo, Republic of the' },
    COOK_ISLANDS: { name: 'Cook Islands' },
    CORAL_SEA_ISLANDS: { name: 'Coral Sea Islands' },
    COSTA_RICA: { name: 'Costa Rica' },
    COTE_DIVOIRE: { name: "Cote d'Ivoire" },
    CROATIA: { name: 'Croatia' },
    CUBA: { name: 'Cuba' },
    CURACAO_NL_ANTILLES: { name: 'Curacao (Netherlands Antilles)' },
    CYPRUS: { name: 'Cyprus' },
    CZECH_REPUBLIC: { name: 'Czech Republic' },
    DENMARK: { name: 'Denmark' },
    DHEKELIA: { name: 'Dhekelia' },
    DJIBOUTI: { name: 'Djibouti' },
    DOMINICA: { name: 'Dominica' },
    DOMINICAN_REPUBLIC: { name: 'Dominican Republic' },
    ECUADOR: { name: 'Ecuador' },
    EGYPT: { name: 'Egypt' },
    EL_SALVADOR: { name: 'El Salvador' },
    EQUATORIAL_GUINEA: { name: 'Equatorial Guinea' },
    ERITREA: { name: 'Eritrea' },
    ESTONIA: { name: 'Estonia' },
    ETHIOPIA: { name: 'Ethiopia' },
    EUROPA_ISLAND: { name: 'Europa Island' },
    FALKLAND_ISLANDS_MALVINAS: { name: 'Falkland Islands (Malvinas)' },
    FAROE_ISLANDS: { name: 'Faroe Islands' },
    FIJI: { name: 'Fiji' },
    FINLAND: { name: 'Finland' },
    FRANCE: { name: 'France' },
    FRENCH_GUIANA: { name: 'French Guiana' },
    FRENCH_POLYNESIA: { name: 'French Polynesia' },
    FRENCH_SOUTHERN_AND_ANTARCTIC_LANDS: { name: 'French Southern and Antarctic Lands' },
    GABON: { name: 'Gabon' },
    GAMBIA: { name: 'Gambia' },
    GAZA_STRIP: { name: 'Gaza Strip' },
    GEORGIA: { name: 'Georgia' },
    GERMANY: { name: 'Germany' },
    GHANA: { name: 'Ghana' },
    GIBRALTAR: { name: 'Gibraltar' },
    GLORIOSO_ISLANDS: { name: 'Glorioso Islands' },
    GREECE: { name: 'Greece' },
    GREENLAND: { name: 'Greenland' },
    GRENADA: { name: 'Grenada' },
    GUADELOUPE: { name: 'Guadeloupe' },
    GUAM: { name: 'Guam' },
    GUATEMALA: { name: 'Guatemala' },
    GUERNSEY: { name: 'Guernsey' },
    GUINEA: { name: 'Guinea' },
    GUINEA_BISSAU: { name: 'Guinea-Bissau' },
    GUYANA: { name: 'Guyana' },
    HAITI: { name: 'Haiti' },
    HEARD_ISLAND_AND_MCDONALD_ISLANDS: { name: 'Heard Island and McDonald Islands' },
    HOLY_SEE_VATICAN_CITY: { name: 'Vatican City' },
    HONDURAS: { name: 'Honduras' },
    HONG_KONG: { name: 'Hong Kong' },
    HUNGARY: { name: 'Hungary' },
    ICELAND: { name: 'Iceland' },
    INDIA: { name: 'India' },
    INDONESIA: { name: 'Indonesia' },
    IRAN: { name: 'Iran' },
    IRAQ: { name: 'Iraq' },
    IRELAND: { name: 'Ireland' },
    ISLE_OF_MAN: { name: 'Isle of Man' },
    ISRAEL: { name: 'Israel' },
    ITALY: { name: 'Italy' },
    JAMAICA: { name: 'Jamaica' },
    JAN_MAYEN: { name: 'Jan Mayen' },
    JAPAN: { name: 'Japan' },
    JERSEY: { name: 'Jersey' },
    JORDAN: { name: 'Jordan' },
    JUAN_DE_NOVA_ISLAND: { name: 'Juan de Nova Island' },
    KAZAKHSTAN: { name: 'Kazakhstan' },
    KENYA: { name: 'Kenya' },
    KIRIBATI: { name: 'Kiribati' },
    KOREA_NORTH: { name: 'North Korea' },
    KOREA_SOUTH: { name: 'South Korea' },
    KUWAIT: { name: 'Kuwait' },
    KYRGYZSTAN: { name: 'Kyrgyzstan' },
    LAOS: { name: 'Laos' },
    LATVIA: { name: 'Latvia' },
    LEBANON: { name: 'Lebanon' },
    LESOTHO: { name: 'Lesotho' },
    LIBERIA: { name: 'Liberia' },
    LIBYA: { name: 'Libya' },
    LIECHTENSTEIN: { name: 'Liechtenstein' },
    LITHUANIA: { name: 'Lithuania' },
    LUXEMBOURG: { name: 'Luxembourg' },
    MACAU: { name: 'Macau' },
    NORTH_MACEDONIA: { name: 'North Macedonia' },
    MADAGASCAR: { name: 'Madagascar' },
    MALAWI: { name: 'Malawi' },
    MALAYSIA: { name: 'Malaysia' },
    MALDIVES: { name: 'Maldives' },
    MALI: { name: 'Mali' },
    MALTA: { name: 'Malta' },
    MARSHALL_ISLANDS: { name: 'Marshall Islands' },
    MARTINIQUE: { name: 'Martinique' },
    MAURITANIA: { name: 'Mauritania' },
    MAURITIUS: { name: 'Mauritius' },
    MAYOTTE: { name: 'Mayotte' },
    MEXICO: { name: 'Mexico' },
    MICRONESIA: { name: 'Micronesia' },
    MOLDOVA: { name: 'Moldova' },
    MONACO: { name: 'Monaco' },
    MONGOLIA: { name: 'Mongolia' },
    MONTENEGRO: { name: 'Montenegro' },
    MONTSERRAT: { name: 'Montserrat' },
    MOROCCO: { name: 'Morocco' },
    MOZAMBIQUE: { name: 'Mozambique' },
    MYANMAR: { name: 'Myanmar' },
    NAMIBIA: { name: 'Namibia' },
    NAURU: { name: 'Nauru' },
    NAVASSA_ISLAND: { name: 'Navassa Island' },
    NEPAL: { name: 'Nepal' },
    NETHERLANDS: { name: 'Netherlands' },
    NEW_CALEDONIA: { name: 'New Caledonia' },
    NEW_ZEALAND: { name: 'New Zealand' },
    NICARAGUA: { name: 'Nicaragua' },
    NIGER: { name: 'Niger' },
    NIGERIA: { name: 'Nigeria' },
    NIUE: { name: 'Niue' },
    NORFOLK_ISLAND: { name: 'Norfolk Island' },
    NORTHERN_MARIANA_ISLANDS: { name: 'Northern Mariana Islands' },
    NORWAY: { name: 'Norway' },
    OMAN: { name: 'Oman' },
    PAKISTAN: { name: 'Pakistan' },
    PALAU: { name: 'Palau' },
    PALESTINE: { name: 'Palestine' },
    PANAMA: { name: 'Panama' },
    PAPUA_NEW_GUINEA: { name: 'Papua New Guinea' },
    PARACEL_ISLANDS: { name: 'Paracel Islands' },
    PARAGUAY: { name: 'Paraguay' },
    PERU: { name: 'Peru' },
    PHILIPPINES: { name: 'Philippines' },
    PITCAIRN_ISLANDS: { name: 'Pitcairn Islands' },
    POLAND: { name: 'Poland' },
    PORTUGAL: { name: 'Portugal' },
    PUERTO_RICO: { name: 'Puerto Rico' },
    QATAR: { name: 'Qatar' },
    REUNION: { name: 'Reunion' },
    ROMANIA: { name: 'Romania' },
    RUSSIA: { name: 'Russia' },
    RWANDA: { name: 'Rwanda' },
    SABA_NL_ANTILLES: { name: 'Saba (Netherlands Antilles)' },
    SAINT_BARTHS: { name: 'Saint Barths' },
    SAINT_EUSTACE_NL_ANTILLES: { name: 'Saint Eustace (Netherlands Antilles)' },
    SAINT_HELENA: { name: 'Saint Helena' },
    SAINT_KITTS_AND_NEVIS: { name: 'Saint Kitts and Nevis' },
    SAINT_LUCIA: { name: 'Saint Lucia' },
    SAINT_MARTIN_FRANCE: { name: 'Saint Martin (France)' },
    SAINT_MARTIN_NL_ANTILLES: { name: 'Saint Martin (Netherlands Antilles)' },
    SAINT_PIERRE_AND_MIQUELON: { name: 'Saint Pierre and Miquelon' },
    SAINT_VINCENT_AND_THE_GRENADINES: { name: 'Saint Vincent and the Grenadines' },
    SAMOA: { name: 'Samoa' },
    SAN_MARINO: { name: 'San Marino' },
    SAO_TOME_AND_PRINCIPE: { name: 'Sao Tome and Principe' },
    SAUDI_ARABIA: { name: 'Saudi Arabia' },
    SENEGAL: { name: 'Senegal' },
    SERBIA: { name: 'Serbia' },
    SEYCHELLES: { name: 'Seychelles' },
    SIERRA_LEONE: { name: 'Sierra Leone' },
    SINGAPORE: { name: 'Singapore' },
    SLOVAKIA: { name: 'Slovakia' },
    SLOVENIA: { name: 'Slovenia' },
    SOLOMON_ISLANDS: { name: 'Solomon Islands' },
    SOMALIA: { name: 'Somalia' },
    SOUTH_AFRICA: { name: 'South Africa' },
    SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS: {
      name: 'South Georgia and the South Sandwich Islands',
    },
    SPAIN: { name: 'Spain' },
    SPRATLY_ISLANDS: { name: 'Spratly Islands' },
    SRI_LANKA: { name: 'Sri Lanka' },
    SUDAN: { name: 'Sudan' },
    SOUTH_SUDAN: { name: 'South Sudan' },
    SURINAME: { name: 'Suriname' },
    SVALBARD: { name: 'Svalbard' },
    ESWATINI_SWAZILAND: { name: 'Eswatini (Swaziland)' },
    SWEDEN: { name: 'Sweden' },
    SWITZERLAND: { name: 'Switzerland' },
    SYRIA: { name: 'Syria' },
    TAIWAN: { name: 'Taiwan' },
    TAJIKISTAN: { name: 'Tajikistan' },
    TANZANIA: { name: 'Tanzania' },
    THAILAND: { name: 'Thailand' },
    TIMOR_LESTE: { name: 'Timor-Leste' },
    TOGO: { name: 'Togo' },
    TOKELAU: { name: 'Tokelau' },
    TONGA: { name: 'Tonga' },
    TRINIDAD_AND_TOBAGO: { name: 'Trinidad and Tobago' },
    TROMELIN_ISLAND: { name: 'Tromelin Island' },
    TUNISIA: { name: 'Tunisia' },
    TURKEY: { name: 'Turkey' },
    TURKMENISTAN: { name: 'Turkmenistan' },
    TURKS_AND_CAICOS_ISLANDS: { name: 'Turks and Caicos Islands' },
    TUVALU: { name: 'Tuvalu' },
    UGANDA: { name: 'Uganda' },
    UKRAINE: { name: 'Ukraine' },
    UNITED_ARAB_EMIRATES: { name: 'United Arab Emirates' },
    UNITED_KINGDOM: { name: 'United Kingdom' },
    UNITED_STATES: { name: 'United States' },
    URUGUAY: { name: 'Uruguay' },
    UZBEKISTAN: { name: 'Uzbekistan' },
    VANUATU: { name: 'Vanuatu' },
    VENEZUELA: { name: 'Venezuela' },
    VIETNAM: { name: 'Vietnam' },
    VIRGIN_ISLANDS_UK: { name: 'Virgin Islands (UK)' },
    VIRGIN_ISLANDS_USA: { name: 'Virgin Islands (USA)' },
    WAKE_ISLAND: { name: 'Wake Island' },
    WALLIS_AND_FUTUNA: { name: 'Wallis and Futuna' },
    WEST_BANK: { name: 'West Bank' },
    WESTERN_SAHARA: { name: 'Western Sahara' },
    YEMEN: { name: 'Yemen' },
    ZAMBIA: { name: 'Zambia' },
    ZIMBABWE: { name: 'Zimbabwe' },
  };
}
