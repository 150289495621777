import PropTypes from 'prop-types';

const IndeterminateCheckbox = ({ label, checked, indeterminate, disabled, onChange }) => {
  return (
    <>
      <label
        className='checkbox c-checkbox m-0'
        onClick={(e) => {
          e.preventDefault();
          onChange(!checked);
        }}
      >
        <input
          type='checkbox'
          checked={checked}
          className='form-check-input'
          onChange={() => {}}
          ref={(inputRef) => {
            if (inputRef) {
              inputRef.indeterminate = indeterminate;
            }
          }}
          disabled={disabled}
        />
        <span className='fa fa-check m-0' />
        <span className='fas fa-minus m-0' />
      </label>
      {label && <span className='ml-2'>{label}</span>}
    </>
  );
};

export default IndeterminateCheckbox;

IndeterminateCheckbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

IndeterminateCheckbox.defaultProps = {
  label: '',
  indeterminate: false,
  disabled: false,
};
