import React from 'react'

import Segment from '../../../models/Segment'
import TASK_RESULT from '../../../models/TaskResult'
import Button from '../../../components/Button'
import Utils from '../../../utils/Utils'


const ReviewerToolbar = ({
  task,
  isAdmin,
  scope: propScope,
  handleSubmit,
  handleResubmit,
  handleRollback,
  handleComplete,
  handleApproveAll,
  handleRejectAll,
  enabledHandleAllForWorkers,
}) => {
  const scope = propScope ?? TASK_RESULT.USER_SCOPE.WORKER
  const permissions = TASK_RESULT.getUserPermission(task) ?? []
  const isStatusRolledback = task?.status === TASK_RESULT.STATUS.ROLLED_BACK

  const isTaskSubmittable =
    scope === TASK_RESULT.USER_SCOPE.WORKER && TASK_RESULT.getUserCanSubmit(task)

  const isUserReviewer = scope !== TASK_RESULT.USER_SCOPE.WORKER
  const canTaskBeReviewed =
    isUserReviewer &&
    [TASK_RESULT.STATUS.SUBMITTED, TASK_RESULT.STATUS.PENDING_CHECK].includes(task?.status) &&
    permissions.includes(TASK_RESULT.PERMISSIONS.EDIT)

  const areTargetsEditable = task?.targets?.every((obj) =>
    obj?.permissions?.includes(Segment.PERMISSIONS.EDIT),
  )

  const showReviewAllButtons =
    areTargetsEditable ||
    (permissions.includes(TASK_RESULT.PERMISSIONS.DATA_EDIT) && enabledHandleAllForWorkers)

  const numEmptyTargets = Utils.define(
    task?.targets?.filter(
      (target) => Utils.define(target?.content?.review?.status, target?.status) ===
        Segment.REVIEWS.UNREVIEWED)?.length,
    0,
  )

  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-4 ">
      <div className={ numEmptyTargets > 0 ? 'mb-4 text-danger' : 'mb-4 ' }>
        Pending Target Segments: { numEmptyTargets } of { task?.targets?.length }
      </div>
      <div
        className="d-flex flex-wrap justify-content-sm-start justify-content-md-end align-items-center mb-4 flex-fill">
        { canTaskBeReviewed && (
          <Button
            key="checked"
            label="Check Completed"
            priority="primary"
            classes="mr-2 mb-2"
            handleClick={ handleComplete }
          />
        ) }
        { showReviewAllButtons && (
          <>
            <Button
              key="approveAll"
              label="Approve All"
              priority="success"
              classes="mr-2 mb-2"
              handleClick={ handleApproveAll }
            />
            <Button
              key="rejectAll"
              label="Reject All"
              priority="danger"
              classes="mr-2 mb-2"
              handleClick={ handleRejectAll }
            />
          </>
        ) }
        { isTaskSubmittable && (
          <Button
            key="submit"
            label="Submit"
            priority="primary"
            classes="mr-2 mb-2"
            handleClick={ handleSubmit }
          />
        ) }
        { isAdmin && task.status === 'REVIEWED' && (
          <Button
            key="resubmit"
            label="Resubmit"
            priority="primary"
            classes="mr-2 mb-2"
            handleClick={ handleResubmit }
          />
        ) }
        { isAdmin && (
          <Button
            key="rollback"
            label={ isStatusRolledback ? 'RolledBack' : 'Rollback' }
            priority="warning"
            classes="mb-2"
            disabled={ isStatusRolledback }
            handleClick={ handleRollback }
          />
        ) }
      </div>
    </div>
  )
}

export default ReviewerToolbar
