export default class UserPermission {
  static TYPES = {
    TASK: 'TASK',
    PROJECT: 'PROJECT',
    USER: 'USER',
    USERGROUP: 'USERGROUP',
    SEGMENT: 'SEGMENT',
    FILE: 'FILE',
    MESSAGE: 'MESSAGE',
  };

  static SCOPES = {
    PROJECT: 'PROJECT',
    API: 'API',
  };
}
