import { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import ErrorHandler from '../utils/ErrorHandler';

export default class DOMObject extends PureComponent {
  constructor(props) {
    super(props);
    this.DOMObject = null;
  }

  componentDidMount() {
    this.DOMObject = ReactDOM.findDOMNode(this);
  }

  componentDidUpdate() {
    this.DOMObject = ReactDOM.findDOMNode(this);
  }

  errorHandler(e, options) {
    ErrorHandler.errorHandle(e);
  }

  getAttributes(defaultProps = {}) {
    try {
      let defProps = Object.keys(defaultProps);
      let attrs = {};
      Object.keys(this.props).forEach((obj) => {
        if (!defProps.includes(obj)) attrs[obj] = this.props[obj];
      });
      return attrs;
    } catch (e) {
      this.errorHandler(e);
    }
  }

  setBusy(state = false, selector = null) {
    if (this.DOMObject === null) return;
    let domObject = selector === null ? this.DOMObject : this.DOMObject.querySelector(selector);
    if (domObject === null) return;

    if (state === true) {
      domObject.classList.add('whirl');
      domObject.classList.add('sphere');
    } else {
      domObject.classList.remove('whirl');
      domObject.classList.remove('sphere');
    }
  }

  static getExtraAttributes(props = {}, defaultProps = {}) {
    try {
      let defProps = Object.keys(defaultProps);
      let attrs = {};
      Object.keys(props).forEach((obj) => {
        if (!defProps.includes(obj)) attrs[obj] = props[obj];
      });
      return attrs;
    } catch (e) {
      console.error(e);
    }
  }
}
