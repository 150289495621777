import Utils from './Utils';
import $ from 'jquery';

export default class DataTables {
  // ------------------------------------------------------------------------------
  // -------------------------------- Options Methods -----------------------------
  // ------------------------------------------------------------------------------

  /**
   * This method takes a datatable options object and the order option with column names instead of indexex (ex. [["col1Name","asc"],["col2Name","asc"]])
   * and adds to the options object the order one as should be (column index instead of name)
   * @param {Object} options The datatable options object to add the order option as needed
   * @param {Array} order The datatable options order one with names instead of indexes
   * @returns {Object} The options object with the order option set.
   */
  static setOptionsOrderByName(options, order) {
    if (Utils.isNull(options)) throw new Error('table options cannot be null!');
    if (!Array.isArray(order)) throw new Error('order parameter must be an Array!');

    let columns = Utils.defineArray(options.columns, []);
    let newOrder = [];
    order.forEach((pair) => {
      if (!Array.isArray(pair)) throw new Error('Invalid element type within the order parameter!');
      let index = -1;
      columns.forEach((obj, i) => {
        if (obj.name === pair[0]) index = i;
      });
      if (index >= 0) {
        let newPair = [];
        newPair.push(index);
        newPair.push(pair[1]);
        newOrder.push(newPair);
      }
    });

    options.order = newOrder;
    return options;
  }

  /**
   * Hides the header column of the dataTable within the 'object'.
   * @param {Element} object : The DOM Element containing the dataTable.
   * @returns {Boolean} : true when dataTable header found and set to hidden.
   * @returns {Boolean} : false when dataTable header not found or error occur.
   */
  static hideHeaders(object) {
    try {
      let header = object.querySelectorAll('.dataTables_scrollHead');
      if (header.length === 0) return false;

      header.forEach((obj) => {
        obj.classList.add('d-none');
      });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  /**
   * Sets the dataTable order option using column names instead of column index.
   * @param {dataTable} table : The dataTable to use
   * @param {Array<Array<String,String>>} order : The order parameter ex[["colName1","asc"],["colName2","desc"]]
   * @returns true if succeeds, false if fails
   */
  static setOrderByName(table, order) {
    try {
      if (Utils.isNull(table)) throw new Error('table parameter cannot be null!');
      if (!Array.isArray(order)) throw new Error('order parameter must be an Array!');

      let newOrder = [];
      order.forEach((pair) => {
        if (!Array.isArray(pair))
          throw new Error('Invalid element type within the order parameter!');
        let newPair = [];
        newPair.push(table.column(pair[0] + ':name').index());
        newPair.push(pair[1]);
        newOrder.push(newPair);
      });
      table.order(newOrder);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  /**
   * Returns an array with the indexes of the given columns names.
   * @param {dataTable} table : dataTable to use
   * @param {String|Array<String>} columnName : The names of the columns to retrieve their indexes
   */
  static getColumnIndexByName(table, columnName) {
    try {
      if (Utils.isNull(table)) throw new Error('table parameter cannot be null!');
      if (Utils.isNull(columnName)) throw new Error('columnName parameter cannot be null!');
      if (!Array.isArray(columnName)) columnName = [columnName];

      let indexes = [];
      columnName.forEach((name) => {
        indexes.push(table.column(name + ':name').index());
      });
      return indexes;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   * Makes the checkboxes header only the checkbox clickable and not the hole column header area.
   * @param {dataTable} table : dataTable to use
   * @param {String} columnName : The column selector of the checkboxes.
   */
  static checkboxesHeaderFix(table, columnName) {
    try {
      let header = table.column(columnName).header();
      $(header).on('click', (e) => {
        if (!header.querySelector('div.checkbox').contains(e.target)) {
          e.stopPropagation();
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  // ----------------------------------------------------------------------
  // --------------------------- Listeners Methods ------------------------
  // ----------------------------------------------------------------------
  /**
   * Adds a de-select row listener in the dataTable that calls the 'listener" parameter method
   * with the dataTable deselected rows as parameters.
   * @param {dataTable} table : dataTable to add the listener
   * @param {function} listener : Function to be called.
   * @returns {Boolean} : true if succeed, false on error.
   */
  static addDeSelectRowListener(table, listener) {
    try {
      if (Utils.isNull(table) || typeof listener !== 'function') return null;
      table.on('deselect', function (e, dt, type, indexes) {
        listener(dt.rows(indexes));
      });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  /**
   * Adds a select row listener in the dataTable that calls the 'listener" parameter method
   * with the dataTable selected rows as parameters.
   * @param {dataTable} table : dataTable to add the listener
   * @param {function} listener : Function to be called.
   * @returns {Boolean} : true if succeed, false on error.
   */
  static addSelectRowListener(table, listener) {
    try {
      if (Utils.isNull(table) || typeof listener !== 'function') return null;
      table.on('select', function (e, dt, type, indexes) {
        listener(dt.rows(indexes));
      });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  /**
   * Adds a user clicked row listener in the dataTable that calls the 'listener" parameter method
   * with the dataTable clicked dataTable.row() object as parameters. This listener prevents the row to be selected.
   * @param {dataTable} table : dataTable to add the listener
   * @param {function} listener : Function to be called.
   * @param {Array<string>=} ignoreCols : columns indexes to ignore.
   * @returns {Boolean} : true if succeed, false on error.
   */
  static addUserClickRowListener(table, listener, ignoreCols) {
    try {
      if (Utils.isNull(table) || typeof listener !== 'function') return false;
      ignoreCols = Utils.defineArray(ignoreCols, []);

      table.on('user-select', function (e, dt, type, cell, originalEvent) {
        if (ignoreCols.includes(cell.index().column)) return;
        e.stopPropagation();
        e.preventDefault();
        listener(dt.row(cell.index().row));
      });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  // ----------------------------------------------------------------------
  // ------------------------ Data Methods --------------------------------
  // ----------------------------------------------------------------------
  /**
   * Creates a unique value for a table column. The Unique value will have the format of {templateValue} + {counter}
   * @param {dataTable} table : dataTable to use
   * @param {String} columnName : table column name to create the unique value
   * @param {String=} templateValue : unique value template. Default value is empty.
   */
  static createUniqueValue(table, column, templateValue) {
    try {
      if (Utils.isNull(table)) throw new Error('table parameter cannot be null!');
      if (Utils.isEmpty(column)) throw new Error('columnName parameter cannot be empty!');
      templateValue = Utils.define(templateValue, '');

      let counter = 1;
      let value = templateValue;
      while (DataTables.valueExists(table, column, value)) {
        value = templateValue + counter;
        counter++;
      }
      return value;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   * Checks if the parameter 'value' exists in the column named as the 'columnName' parameter.
   * @param {dataTable} table : dataTable to use
   * @param {String} columnName : the name of the column to check
   * @param {object} value : The value to check
   * @returns true if the 'value' exists, false if it does not.
   */
  static valueExists(table, column, value) {
    try {
      if (Utils.isNull(table)) throw new Error('table parameter cannot be null!');
      if (Utils.isEmpty(column)) throw new Error('columnName parameter cannot be empty!');

      if (
        table
          .column(column)
          .data()
          .filter((data) => {
            return typeof value === 'string'
              ? data.toLowerCase() === value.toLowerCase()
              : data === value;
          }).length > 0
      )
        return true;
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  /**
   * It returns an array with all the dataTable row (datatable.row()) objects that the data object property is equal to the parameter 'value'
   * @param {dataTable} table : dataTable to use
   * @param {String} property : The data object property name to compare
   * @param {object} value : The value of the data object property to compare
   * @returns {Array<dataTable.row>} : An array of dataTable.row() objects which match the cretiria.
   */
  static getRowsByProperty(table, property, value) {
    try {
      if (Utils.isNull(table))
        throw new Error('table parameter cannot be null on QDDataTable.getTableRowsByData()!');
      if (Utils.isEmpty(property))
        throw new Error(
          'columnName parameter cannot be empty on QDDataTable.getTableRowsByData()!'
        );

      let rows = [];
      table
        .rows()
        .indexes()
        .filter((index) => {
          let data = table.row(index).data();
          if (data[property] === value) rows.push(table.row(index));
          else if (typeof value === 'string') {
            if (value.toLowerCase() === data[property].toLowerCase()) rows.push(table.row(index));
          }
          return true;
        });
      return rows;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   * Returns an array with all the values of the objects that is passed as data in the table.
   * @param {dataTable} table : datatable to use
   * @param {String} columnName : The name of the data object property
   * @returns {Array<Object>} : All the values of the column
   */
  static getColumnData(table, column) {
    try {
      if (Utils.isNull(table))
        throw new Error('table parameter cannot be null on QDDataTable.getTableColumnData()!');
      if (Utils.isEmpty(column))
        throw new Error(
          'columnName parameter cannot be empty on QDDataTable.getTableColumnData()!'
        );

      let data = [];
      table
        .column(column)
        .data()
        .filter((val) => {
          data.push(val);
          return true;
        });
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   * Returns an array with the selected rows data.
   * @param {datatable} table : dataTable to use
   * @returns {Array<object>} : The selected rows data.
   * @returns null : On error.
   */
  static getSelectedData(table) {
    try {
      if (Utils.isNull(table)) throw new Error('table parameter cannot be null!');

      let selected = [];
      Array.from(table.rows({ selected: true }).eq(0)).forEach((index) => {
        selected.push(table.row(index).data());
      });
      return selected;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   * Returns an array with the selected dataTable.row() objects.
   * @param {datatable} table : dataTable to use
   * @returns {Array<object>} : The selected dataTable.rows() objects.
   * @returns null : On error.
   */
  static getSelectedRows(table) {
    try {
      if (Utils.isNull(table)) throw new Error('table parameter cannot be null!');

      let selected = [];
      Array.from(table.rows({ selected: true }).eq(0)).forEach((index) => {
        selected.push(table.row(index));
      });
      return selected;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   * Returns an object with the data of the dataTable row passed as parameter
   * @param {dataTable} table : Starting from 0
   * @param {number} index : Starting from 0
   * @returns {object} : an object with the datatable row data.
   * @returns null : when object not found
   */
  static copyRowData(table, index) {
    try {
      if (Utils.isNull(table) || Utils.isNull(index)) return null;
      let data = table.row(index).data();
      if (Utils.isNull(data)) return null;
      return { ...data };
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static isSelected(table, row) {
    try {
      return table.row(row).node().classList.contains('selected');
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  static checkboxSet(table, row, column, state) {
    try {
      table.cell(row, column).node().querySelector('input').checked = state;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
