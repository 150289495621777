import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Notifier from '../../components/Notifier';

import UserGroupController from '../../controllers/UserGroupController';
import { getLastNYears } from '../../utils/DateHelper';
import CriteriaBuilder from './components/CriteriaBuilder';
import GroupDetailsForm from './components/GroupDetailsForm';
import GroupMembersTable from './components/GroupMembersTable';
import useFilterOptions from './hooks/useFilterOptions';

const yearOfBirthOptions = getLastNYears(100).map((year) => ({
  value: year,
  name: year.toString(),
}));

const UserGroupPage = () => {
  const [group, setGroup] = useState();
  const [members, setMembers] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, size: 10, sort: ['id', 'desc'] });
  const [totalElements, setTotalElements] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(Math.random());
  const { id } = useParams();

  const updateCriteriaList = (criteriaList) =>
    setCriteriaOptions(criteriaList.map(({ id, name }) => ({ value: id, label: name })));

  const getMembers = useCallback(() => {
    UserGroupController.listMembers(id, pagination).then((response) => {
      setMembers(response.content);
      setTotalElements(response.totalElements);
    });
  }, [id, pagination]);

  const getGroup = async () => {
    const response = await UserGroupController.get(id);
    setGroup(response);
    updateCriteriaList(response.criteriaList);
  };

  useEffect(() => {
    getGroup();
  }, [id]);

  useEffect(() => {
    getMembers();
  }, [id, pagination, getMembers]);

  const handleSaveGroupDetails = (values) => {
    try {
      UserGroupController.update(parseInt(id), values).then((response) => setGroup(response));
    } catch (error) {
      Notifier.error(error.message);
    }
  };

  const handleSaveCriteria = async (filters) => {
    let response;
    const payload = {
      ...filters,
      name: selectedCriteria.label,
    };
    if (selectedCriteria.__isNew__) {
      response = await UserGroupController.createCriteria(id, payload);
      updateCriteriaList(response.criteriaList);
      setSelectedCriteria(null);
    } else {
      response = await UserGroupController.updateCriteria(id, selectedCriteria.value, payload);
      getGroup();
      setForceUpdate(Math.random());
    }
    setGroup(response);
    getMembers();
  };

  const [selectedCriteria, setSelectedCriteria] = useState();
  const [criteriaOptions, setCriteriaOptions] = useState([]);

  const selectedCriteriaFilters = group?.criteriaList?.find(
    (criteria) => criteria.id === selectedCriteria?.value
  );

  const handleChangeCriteria = (option, { action }) => {
    if (action === 'create-option') {
      setCriteriaOptions((prev) => [...prev, option]);
    }
    setSelectedCriteria(option);
  };

  const {
    loading: loadingOptions,
    languages: languageOptions,
    languageLevels: languageLevelOptions,
    userEthnicGroups: userEthnicGroupOptions,
    userGenders: userGenderOptions,
    userDialects: userDialectOptions,
    geolocation: geolocationOptions,
    tests: testOptions,
    evaluationScores: evaluationScoreOptions,
  } = useFilterOptions();

  const handleRemoveCriteria = async () => {
    try {
      if (selectedCriteria.__isNew__) {
        setCriteriaOptions((prevOptions) => prevOptions.filter((option) => !option.__isNew__));
      } else {
        UserGroupController.deleteCriteria(id, selectedCriteria.value).then((response) => {
          updateCriteriaList(response.criteriaList);
          getMembers();
          setGroup(response);
          setSelectedCriteria(null);
        });
      }
    } catch (error) {
      Notifier.error(error.message);
    }
  };

  const handleCurrentPageChange = async (page) => {
    setPagination((prev) => ({ ...prev, page }));
  };

  const handlePageSizeChange = async (size) => {
    setPagination({ page: 0, size });
  };

  const handleRemoveMember = (memberId) => {
    UserGroupController.removeMembers(id, [memberId]).then(() =>
      UserGroupController.get(id).then((response) => {
        getMembers();
        setGroup(response);
        setForceUpdate(Math.random());
      })
    );
  };

  const handleAddUser = (memberId) => {
    UserGroupController.addMembers(id, [memberId]).then(() =>
      UserGroupController.get(id).then((response) => {
        getMembers();
        setGroup(response);
        setForceUpdate(Math.random());
      })
    );
  };

  const handleApproveMember = (memberId) => {
    UserGroupController.approveMember(id, memberId).then(() => getMembers());
  };

  const handleInvalidateGroup = () => {
    if (
      window.confirm(
        'Are you sure you want to invalidate this group? All members will get repopulated based on current criteria'
      )
    ) {
      UserGroupController.invalidateGroup(id).then(() => getMembers());
    }
  };

  const handleCriteriaNameChange = (field, value) => {
    setSelectedCriteria((prev) => ({ ...prev, label: String(value) }));
  }

  return (
    <div>
      {group && (
        <div className='tw-grid tw-grid-cols-5 tw-gap-4'>
          <div className='tw-col-span-2'>
            <h4>Basic details</h4>
            <GroupDetailsForm
              group={group}
              onSaveGroupDetails={handleSaveGroupDetails}
              onInvalidateGroup={handleInvalidateGroup}
            />
            <h4>Members {totalElements}</h4>
            <GroupMembersTable
              members={members}
              pagination={pagination}
              totalElements={totalElements}
              onCurrentPageChange={handleCurrentPageChange}
              onPageSizeChange={handlePageSizeChange}
              onRemoveMember={handleRemoveMember}
              onApproveMember={handleApproveMember}
            />
          </div>
          <div className='tw-col-span-3'>
            <CriteriaBuilder
              key={selectedCriteria?.value ?? 0}
              forceUpdate={forceUpdate}
              isRowDisabled={(row) =>
                row.userAuth.groups.map((group) => group.id).includes(group.id)
              }
              onAddUser={handleAddUser}
              criteriaOptions={criteriaOptions}
              onSaveCriteria={handleSaveCriteria}
              onChangeCriteria={handleChangeCriteria}
              onRemoveCriteria={handleRemoveCriteria}
              selectedCriteria={selectedCriteria}
              selectedCriteriaFilters={selectedCriteriaFilters}
              isLoadingOptions={loadingOptions}
              languageOptions={languageOptions}
              languageLevelOptions={languageLevelOptions}
              userEthnicGroupOptions={userEthnicGroupOptions}
              userGenderOptions={userGenderOptions}
              userDialectOptions={userDialectOptions}
              geolocationOptions={geolocationOptions}
              yearOfBirthOptions={yearOfBirthOptions}
              testOptions={testOptions}
              evaluationScoreOptions={evaluationScoreOptions}
              handleCriteriaNameChange={handleCriteriaNameChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserGroupPage;
