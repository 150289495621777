import {
  CustomPaging,
  DataTypeProvider,
  EditingState,
  PagingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableEditColumn,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import { TableEditColumnOrderLast } from '../../../components/tables/Editor';
import Tooltip from '../../../components/Tooltip';
import Project from '../../../models/Project';
import { UserTestsFormatter } from './UsersTable';

const UserLanguagesFormatter = (data) => {
  return (
    <div key={data.row.id} className='user-groups' style={{ whiteSpace: 'normal' }}>
      {[...data.value]
        .sort((a, b) => {
          return b.level.id - a.level.id;
        })
        .map((obj) => (
          <em
            key={obj.id}
            className='badge badge-light border font-weight-normal'
            title={obj.language.name + ' - ' + obj.level.name}
          >
            {obj.language.name + ' - ' + obj.level.name}
          </em>
        ))}
    </div>
  );
};

const UserPreviewTable = ({
  users = [],
  filters,
  isRowDisabled,
  onAddUser,
  pagination,
  totalElements,
  onCurrentPageChange,
  onPageSizeChange,
}) => {
  const tableColumns = [
    {
      name: 'email',
      title: 'Email',
      shouldBeRendered: () => true,
      getCellValue: (row) => row.sid,
    },
    {
      name: 'fullName',
      title: 'Name',
      shouldBeRendered: () => true,
      getCellValue: (row) => row.userAuth.fullName,
    },
    {
      name: 'country',
      title: 'Country',
      shouldBeRendered: (filters) => !!filters.country && filters.country.length > 0,
      getCellValue: (row) => row.userAuth.country,
    },
    {
      name: 'tests',
      title: 'Tests',
      shouldBeRendered: (filters) =>
        (!!filters.projectIds && filters.projectIds.length > 0) ||
        (filters.evaluationScore !== null && filters.evaluationScore !== undefined),
      getCellValue: (row) =>
        row.userAuth.taskResults?.filter(
          (taskResult) => taskResult.reviewedMethod === Project.SETTINGS.REVIEW_METHOD.TAUS_TES.sid
        ),
    },
    {
      name: 'spokenLanguages',
      title: 'Spoken Languages',
      shouldBeRendered: (filters) =>
        (!!filters.languageIds && filters.languageIds.length > 0) ||
        (!!filters.languageLevelIds && filters.languageLevelIds.length > 0),
      getCellValue: (row) => row.languages,
    },
    {
      title: 'Ethnic Group',
      shouldBeRendered: (filters) => !!filters.ethnicGroupIds && filters.ethnicGroupIds.length > 0,
      getCellValue: (row) => row.metadata?.ethnicGroup,
    },
    {
      name: 'gender',
      title: 'Gender',
      shouldBeRendered: (filters) => !!filters.genderIds && filters.genderIds.length > 0,
      getCellValue: (row) => row.metadata?.gender,
    },
    {
      name: 'dialect',
      title: 'Dialect',
      shouldBeRendered: (filters) => !!filters.dialectIds && filters.dialectIds.length > 0,
      getCellValue: (row) => row.metadata?.spokenDialect,
    },
    {
      name: 'yearOfBirth',
      title: 'Year of Birth',
      shouldBeRendered: (filters) => !!filters.yearOfBirthFrom || !!filters.yearOfBirthTo,
      getCellValue: (row) => row.metadata?.yearOfBirth,
    },
  ];

  return (
    <Grid
      rows={users}
      columns={tableColumns.filter((column) => column.shouldBeRendered(filters))}
      getRowId={(row) => row.userAuth.id}
    >
      <PagingState
        currentPage={pagination.page}
        pageSize={pagination.size}
        onCurrentPageChange={onCurrentPageChange}
        onPageSizeChange={onPageSizeChange}
      />
      <CustomPaging totalCount={totalElements} />

      <Table rowComponent={(props) => <CustomRow {...props} isRowDisabled={isRowDisabled} />} />
      <TableHeaderRow />

      <DataTypeProvider for={['spokenLanguages']} formatterComponent={UserLanguagesFormatter} />
      <DataTypeProvider for={['tests']} formatterComponent={UserTestsFormatter} />

      <EditingState />
      <TableEditColumn
        cellComponent={(props) => (
          <CustomEditCell onAddUser={onAddUser} isRowDisabled={isRowDisabled} {...props} />
        )}
        headerCellComponent={ActionHeaderCell}
      />
      <TableEditColumnOrderLast />
      <PagingPanel pageSizes={[10, 25, 50, 100]} />
    </Grid>
  );
};

const CustomRow = ({ isRowDisabled, ...props }) => {
  const isDisabled = isRowDisabled(props.row);
  return <Table.Row {...props} className={isDisabled ? 'tw-text-gray-300 tw-bg-gray-100 ' : ''} />;
};

const ActionHeaderCell = () => (
  <TableHeaderRow.Cell>
    <div className='m-auto'>Actions</div>
  </TableHeaderRow.Cell>
);

const CustomEditCell = ({ onAddUser, isRowDisabled, ...props }) => {
  const userId = props.row.userAuth.id;
  const isDisabled = isRowDisabled(props.row);
  return (
    <TableEditColumn.Cell {...props}>
      {!isDisabled && (
        <Tooltip title='Add user manually'>
          <button
            onClick={() => onAddUser(userId)}
            className='btn bg-transparent btn-secondary-outline border-0 px-2'
          >
            <em className='fas fa-user-plus' style={{ fontSize: '20px', width: '20px' }} />
          </button>
        </Tooltip>
      )}
    </TableEditColumn.Cell>
  );
};

export default UserPreviewTable;
