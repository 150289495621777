import { PAGES } from '.';
import Swal from 'sweetalert2';
import { Formik } from 'formik';
import { useState } from 'react';
import { object, string } from 'yup';
import { Button } from 'react-bootstrap';
import Notifier from '../components/Notifier';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import GoogleLogin from '../components/GoogleLogin';
import FacebookLogin from '../components/FacebookLogin';
import ButtonSpinner from '../components/ButtonSpinner';
import UserController from '../controllers/UserController';
import SessionController from '../controllers/SessionController';
import { ACTIONS as SESSION_ACTIONS } from '../redux/actions/session';
import InputWithValidation from '../components/forms/InputWithValidation';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async ({ email, password }, { setErrors }) => {
    try {
      const session = await SessionController.login(email, password);
      dispatch(SESSION_ACTIONS.userLogin(session));
      history.push(PAGES.USER_ONBOARDING.path());
    } catch (e) {
      setErrors({ general: e.message });
    }
  };

  const handleRecover = async (email) => {
    setLoading(true);
    try {
      const body = {
        email: email,
      };
      await UserController.forgotPassword(body).finally(() => {
        setLoading(false);
        showAccountRecoverConfirmation(email);
      });
    } catch (e) {
      Notifier.error(e.message);
    }
  };

  const showAccountRecoverConfirmation = async (email) => {
    return Swal.mixin({
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-outline-secondary mx-2 px-5 btn-lg font-weight-bold',
      },
    }).fire({
      title: 'Trouble with logging in?',
      text: 'We sent you a link to get back into your account.',
      confirmButtonText: 'Close',
      reverseButtons: true,
    });
  };

  return (
    <Formik
      loading={loading}
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={object().shape({
        email: string().required('Email is required').email('Not a valid email'),
        password: string().required('Password is required'),
      })}
    >
      {(props) => (
        <div className='tw-max-w-lg tw-p-6 tw-mx-auto tw-border tw-border-solid tw-border-gray-300 tw-rounded'>
          <h2 className='tw-text-black'>Sign in</h2>
          <form className='tw-grid tw-gap-4' onSubmit={props.handleSubmit}>
            <InputWithValidation
              id='email'
              label='Email'
              propertyName='email'
              className='taus-input tw-rounded'
              defaultValue={props.values.email ?? ''}
              touched={props.touched.email}
              error={props.errors.email}
              onChange={props.setFieldValue}
              onBlur={props.setFieldTouched}
              autoComplete='email'
              isLogin={true}
            />
            <InputWithValidation
              id='password'
              label='Password'
              type='password'
              propertyName='password'
              className='taus-input tw-rounded'
              defaultValue={props.values.password ?? ''}
              touched={props.touched.password}
              error={props.errors.password}
              onChange={props.setFieldValue}
              onBlur={props.setFieldTouched}
              autoComplete='password'
              isLogin={true}
            />
            {props.errors.general && <div className='tw-text-red-700'>{props.errors.general}</div>}
            <Button
              type='submit'
              className='taus-btn-primary tw-flex tw-items-center'
              disabled={props.isSubmitting || loading}
            >
              Sign in
              {(props.isSubmitting || loading) && <ButtonSpinner />}
            </Button>
            <GoogleLogin title={'Sign in'} />
            <FacebookLogin title={'Sign in'} />
            <div>
              <div className='tw-float-left'>
                {"Don't have an account yet? "}
                <Link to={PAGES.USER_REGISTER.path()} className='text-muted'>
                  Join Now
                </Link>
              </div>
              <Button
                disabled={props.errors.hasOwnProperty('email')}
                onClick={() => handleRecover(props.values.email)}
                target='_blank'
                rel='noreferrer'
                className='tw-inline-block md:tw-float-right text-muted tw-bg-transparent tw-border-0 tw-b-transparent tw-p-0'
              >
                Forgot your password?
              </Button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default Login;
