import { PAGES } from '../../';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Notifier from '../../../components/Notifier';
import { useCallback, useEffect, useState } from 'react';
import TaskController from '../../../controllers/TaskController';
import UserController from '../../../controllers/UserController';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import ProjectController from '../../../controllers/ProjectController';
import PublicProjectLobbyPageView from '../views/PublicProjectLobbyPageView';
import TaskResultController from '../../../controllers/TaskResultController';

const PublicProjectLobbyPageState = () => {
  const history = useHistory();
  const { id: projectId } = useParams();
  const [project, setProject] = useState();
  const [isMember, setIsMember] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isApplicant, setIsApplicant] = useState();
  const [screenerSteps, setScreenerSteps] = useState([]);
  const user = useSelector((state) => state?.session?.user);
  const [allStepsCompleted, setAllStepsCompleted] = useState(false);

  const redirectToTask = useCallback(
    (id) => history.replace(PAGES.TASK_RESULT_PAGE.path(id)),
    [history]
  );

  const getGeolocation = async () => {
    try {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
      );
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const addApplicant = () => {
    setLoading(true);
    try {
      ProjectController.addApplicant(projectId).then((r) => {
        setIsApplicant(true);
      });
    } catch (e) {
      Notifier.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const getProject = () => {
    setLoading(true);
    try {
      ProjectController.getProject(projectId).then((r) => {
        setProject(r.project);
        setIsMember(r.isMember);
        setIsApplicant(r.isApplicant);

        !r.isApplicant && addApplicant();
      });
    } catch (e) {
      Notifier.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const getScreenerSteps = () => {
    setLoading(true);
    try {
      ProjectController.getScreenerSteps(projectId).then((r) => {
        setScreenerSteps(r);
        setAllStepsCompleted(r.every((ss) => ss.isCompleted));
      });
    } catch (e) {
      Notifier.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const startWorkerTest = async (projectId, stepId) => {
    const taskResult = await TaskResultController.getAll({ projectId }).then(
      (res) => res?.page?.[0] ?? null
    );
    if (taskResult) return taskResult;

    await ProjectController.prepareWorker(projectId, stepId);
    const task = await TaskController.getAll({ projectId }).then((res) => res?.page?.[0] ?? null);
    if (!task) {
      Notifier.error('Could not find a task to complete!');
      return null;
    }
    return await TaskController.request(task.id);
  };

  const handleScreenerStepRedirection = async (projectId, stepId, type, requirementTypeId) => {
    if (type === 'ScreenerStepGeolocation') {
      setLoading(true);

      getGeolocation()
        .then((position) => {
          let coordinates = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          try {
            UserController.setUserGeolocation(coordinates).then((r) => {
              getScreenerSteps();
              setLoading(false);
            });
          } catch (error) {
            console.error(error);
          }
        })
        .catch((err) => {
          console.error(err.message);
          setLoading(false);
        });
    } else if (type === 'ScreenerStepQuestionnaire') {
      history.push(PAGES.SCREENER_STEP_QUESTIONNAIRE.path(projectId, stepId), {
        from: {
          pathname: PAGES.PUBLIC_PROJECT_LOBBY_PAGE.path(projectId),
        },
      });
    } else {
      const taskResult = await startWorkerTest(requirementTypeId, stepId);
      if (taskResult) redirectToTask(taskResult.id);
    }
  };

  useEffect(() => {
    getProject();
    getScreenerSteps();

    if (allStepsCompleted && isMember) {
      history.push(PAGES.AVAILABLE_TASKS_PAGE.path(projectId), {
        from: {
          pathname: PAGES.PUBLIC_PROJECT_LOBBY_PAGE.path(projectId),
        },
      });
    }
  }, [allStepsCompleted, isMember]);

  return (
    <PublicProjectLobbyPageView
      project={project}
      isMember={isMember}
      isLoading={isLoading}
      isApplicant={isApplicant}
      screenerSteps={screenerSteps}
      allStepsCompleted={allStepsCompleted}
      handleScreenerStepRedirection={handleScreenerStepRedirection}
    />
  );
};

export default PublicProjectLobbyPageState;
