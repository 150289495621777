import React, { useState } from 'react';
import { Button, Col, CustomInput, Input, Row } from 'reactstrap';
import Select from 'react-select';
import WindowedSelect from 'react-windowed-select';
import Tooltip from '../../../components/Tooltip';
import ProjectMembersTable from '../components/ProjectMembersTable';
import { useMessageBox } from '../../../contexts/MessageBoxContext';
import { getUserOptions } from '../../../utils/Select';

const EditToolbar = ({
  projectMembers = [],
  users = [],
  groups = [],
  roles = [],
  projectRoleNotifications = [],
  onCreateButtonClick,
}) => {
  let [filterValue, setFilterValue] = useState('All');
  let [selectedRoleId, setSelectedRoleId] = useState(null);
  let [selectedLimit, setSelectedLimit] = useState(null);
  let [selectedUsersIds, setSelectedUsersId] = useState([]);
  let [notificationWarning, setNotificationWarning] = useState(false);

  const onUserFilterChange = (e) => {
    setFilterValue(e.target.value ?? 'All');
  };
  const onSelectedUserChange = (option = []) => {
    setSelectedUsersId(option.map((obj) => obj?.value));
  };
  const onSelectedRoleChange = (option = null) => {
    const roleId = option?.value ?? null;
    setSelectedRoleId(roleId);
    setNotificationWarning(
      roleId && !projectRoleNotifications.find((prc) => prc.role.id === roleId)
    );
  };
  const onSelectedLimitChange = (option = null) => {
    setSelectedLimit(option);
  };
  const onButtonClick = () => {
    onCreateButtonClick(selectedUsersIds, selectedRoleId, selectedLimit);
  };

  const userOptions = getUserOptions(users, groups, filterValue);
  const roleOptions = roles?.map((obj) => ({
    label: obj?.name ?? '',
    value: obj?.id ?? null,
  }));
  const isSelectionValid =
    !selectedRoleId ||
    (selectedUsersIds?.length ?? 0) === 0 ||
    (projectMembers?.length ?? 0) === 0 ||
    projectMembers?.every(
      (obj) => selectedRoleId !== obj?.role?.id || !selectedUsersIds?.includes(obj?.user?.id)
    );

  const isButtonEnabled =
    isSelectionValid && !!selectedRoleId && !!selectedUsersIds && selectedUsersIds?.length > 0;

  return (
    <>
      <div className={'text-center mb-2'} onChange={onUserFilterChange}>
        <CustomInput
          type='radio'
          id='rbAll'
          name='userFilter'
          label='All'
          value='All'
          defaultChecked
          inline
        />
        <CustomInput
          type='radio'
          id='rbGroups'
          name='userFilter'
          label='Groups'
          value='Groups'
          inline
          disabled={groups.length === 0}
        />
        <CustomInput
          type='radio'
          id='rbUsers'
          name='userFilter'
          label='Users'
          value='Users'
          inline
          disabled={users.length === 0}
        />
      </div>
      <Row className='align-items-center mb-2'>
        <Col md={4} className='tw-p-1'>
          <WindowedSelect
            id='selectUsers'
            placeholder='Select one or more users/groups...'
            onChange={onSelectedUserChange}
            isClearable={true}
            isSearchable={true}
            options={userOptions}
            isMulti={true}
            noResultsText='No users found'
            menuPlacement='auto'
          />
        </Col>
        <Col md={4} className='tw-p-1'>
          <Select
            id='selectRoles'
            placeholder='Select one or more roles...'
            onChange={onSelectedRoleChange}
            isClearable={true}
            isSearchable={true}
            options={roleOptions}
            isMulti={false}
            noResultsText='No roles found'
            menuPlacement='auto'
          />
        </Col>
        <Col md={2} className='tw-p-1'>
          <Input
            type='number'
            id='limit'
            name='limit'
            placeholder='Set a limit'
            onChange={(e) => onSelectedLimitChange(e.target.value)}
          />
        </Col>
        <Col md={2} className='tw-p-1'>
          {isSelectionValid ? (
            <Button block color='success' onClick={onButtonClick} disabled={!isButtonEnabled}>
              Associate
            </Button>
          ) : (
            <Tooltip title='One or more of your selection combinations are already associated with the project'>
              <div>
                <Button block color='warning' disabled>
                  Nope!
                </Button>
              </div>
            </Tooltip>
          )}
        </Col>
      </Row>
      {notificationWarning && (
        <div className='alert alert-warning mt-2' role='alert'>
          Selected role does not have an automated notification set! Please set-up the role's
          notification in the 'Notifications' tab if you want members to get notified about their
          project participation.
        </div>
      )}
    </>
  );
};

export const ProjectMembersTabView = ({
  roles = [],
  users = [],
  groups = [],
  projectMembers = [],
  projectRoleNotifications = [],
  isBusy = false,
  isEditable = false,
  onMemberAddClick,
  updateReviewConstraints,
  updateMemberPosition,
  updateLimit,
  onMemberDeleteClick,
  canSendMessage = false,
}) => {
  const openMessageBox = useMessageBox();
  const messageButtonClicked = () => openMessageBox(projectMembers.map((pm) => pm?.user?.id));

  return (
    <>
      {canSendMessage && (
        <div className='text-right'>
          <Tooltip key='message_users_tooltip' title='Send a message to the project members'>
            <button className='btn btn-secondary border-0 px-2' onClick={messageButtonClicked}>
              <em className='fas fa-envelope' style={{ fontSize: '24px' }} />
            </button>
          </Tooltip>
        </div>
      )}
      {isEditable && (
        <EditToolbar
          users={users}
          groups={groups}
          projectMembers={projectMembers}
          roles={roles}
          projectRoleNotifications={projectRoleNotifications}
          onCreateButtonClick={onMemberAddClick}
        />
      )}
      <ProjectMembersTable
        projectMembers={projectMembers}
        updateReviewConstraints={updateReviewConstraints}
        updateMemberPosition={updateMemberPosition}
        updateLimit={updateLimit}
        deleteMember={onMemberDeleteClick}
        isEditable={isEditable}
        isBusy={isBusy}
      />
    </>
  );
};

export default ProjectMembersTabView;
