import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.css';
import 'datatables.net-responsive-bs4/css/responsive.bootstrap4.css';
import '../../css/taus.datatables.scss';

import React from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net-responsive-bs4';
import 'datatables.net-colreorder-bs4';

import Utils from '../../utils/Utils';
import DOMObject from '../DOMObject';

export default class DataTableAjax extends DOMObject {
  constructor(props) {
    super(props);

    this.table = null;
    try {
      this.state.isResponsive =
        Utils.define(props.tableProps.responsive, false) === false ? false : true;
      this.state.userVisibleColumns = props.tableProps.columns
        .filter((obj) => {
          return obj.visible;
        })
        .map((obj) => {
          return obj.name;
        });
      this.state.responsivePriority = Array.from(props.tableProps.columns)
        .sort((a, b) => {
          return (
            Utils.define(a.responsivePriority, 1000) - Utils.define(b.responsivePriority, 1000)
          );
        })
        .map((obj) => {
          return obj.name;
        });
    } catch (e) {
      this.errorHandler(e);
    }
  }

  static defaultProps = {
    data: [],
    tableClass: 'table table-stripped w-100',
    tableProps: {
      paging: true,
      pageLength: 25,
      ordering: true,
      autoWidth: true,
      scrollX: false,
      //scrollCollapse: false,
      dom: '<"col-12 px-0" t> <"row mx-0 col-12 px-0" <"col-sm-12 col-md-6 px-0 pt-2" <"col-auto px-0" l><"col-auto px-0 h-100 d-flex align-items-center" i>> <"col-sm-12 col-md-6 px-0 pt-2" p> >',
      language: {
        lengthMenu: 'Items per pages :&nbsp;&nbsp; _MENU_',
        info: '&nbsp;&nbsp;_START_-_END_ of _TOTAL_',
        infoEmpty: '&nbsp;&nbsp;_START_-_END_ of _TOTAL_',
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        select: { rows: { _: '' } },
      },
    },
  };

  state = {
    userVisibleColumns: [],
    screenVisibleColumns: [],
    isResponsive: false,
    responsivePriority: [],
  };

  // --------------------------------------------------------------------------------------------------
  // --------------------------------------- ReactJS --------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  setState(newState = {}) {
    try {
      // screen visible columns
      if (
        Array.isArray(newState.screenVisibleColumns) &&
        newState.screenVisibleColumns !== this.state.screenVisibleColumns
      ) {
        this.state.screenVisibleColumns = newState.screenVisibleColumns;
      }

      // user visible columns
      if (
        Array.isArray(newState.userVisibleColumns) &&
        newState.userVisibleColumns !== this.state.userVisibleColumns
      ) {
        this.props.tableProps.columns.forEach((obj) => {
          this.props.tableProps.columns.forEach((obj) => {
            let isVisible = newState.userVisibleColumns.includes(obj.name);
            if (isVisible !== this.table.column(obj.name + ':name').visible())
              this.table.column(obj.name + ':name').visible(isVisible);
          });
        });
        this.state.userVisibleColumns = newState.userVisibleColumns;
      }

      if (this.state.isResponsive) this.responsiveRebuild();
    } catch (e) {
      this.errorHandler(e);
    }
  }

  componentDidMount() {
    try {
      super.componentDidMount();
      let thisObject = this;
      // Initialize dataTable
      let tableProps = { ...DataTableAjax.defaultProps.tableProps, ...this.props.tableProps };
      this.table = $(this.DOMObject).find('table').DataTable(tableProps);

      if (this.state.isResponsive === true) {
        this.table.on('responsive-resize', (e, datatable, columns) => {
          if (Utils.isNull(thisObject.table)) return;
          let screenVisible = thisObject.getScreenVisibleColumns();
          thisObject.setState({ screenVisibleColumns: screenVisible });
        });

        this.table.on('draw', () => {
          if (Utils.isNull(thisObject.table)) return;
          thisObject.table.responsive.recalc();
        });
      }
    } catch (e) {
      this.errorHandler(e);
    }
  }

  render() {
    let attrs = this.getRootAttrs();
    let tableClass = Utils.define(this.props.tableClass, '');

    return (
      <div {...attrs}>
        <table className={tableClass} />
      </div>
    );
  }

  getRootAttrs() {
    try {
      let attrs = {};
      let defProps = Object.keys(DataTableAjax.defaultProps);
      Object.keys(this.props).forEach((prop) => {
        if (!defProps.includes(prop)) attrs[prop] = this.props[prop];
      });
      return attrs;
    } catch (e) {
      this.errorHandler(e);
      return {};
    }
  }

  // -----------------------------------------------------------------------------------------------
  // ----------------------------------- Methods ---------------------------------------------------
  // -----------------------------------------------------------------------------------------------
  responsiveRebuild() {
    if (this.DOMObject.querySelector('table').offsetWidth > this.DOMObject.offsetWidth)
      this.table.draw();
    else if (this.state.screenVisibleColumns.length === this.state.userVisibleColumns)
      this.table.columns.adjust();
  }

  getDataTable() {
    return this.table;
  }

  getUserVisibleColumns() {
    let visible = [];
    this.props.tableProps.columns.forEach((obj) => {
      if (this.table.column(obj.name + ':name').visible() === true) visible.push(obj.name);
    });
    return visible;
  }

  setUserVisibleColumns(visible) {
    try {
      this.setState({ userVisibleColumns: Utils.defineArray(visible, []) });
    } catch (e) {
      this.errorHandler(e);
    }
  }

  getScreenVisibleColumns() {
    let visible = [];
    this.props.tableProps.columns.forEach((obj) => {
      if (this.table.column(obj.name + ':name').responsiveHidden() === true) visible.push(obj.name);
    });
    return visible;
  }

  getScreenHiddenColumns() {
    let hidden = [];
    this.props.tableProps.columns.forEach((obj) => {
      if (this.table.column(obj.name + ':name').responsiveHidden() === false) hidden.push(obj.name);
    });
    hidden = hidden.filter((obj) => {
      return this.state.userVisibleColumns.includes(obj);
    });
    return hidden;
  }

  // -----------------------------------------------------------------------------------------------
  // ----------------------------------- STATIC ----------------------------------------------------
  // -----------------------------------------------------------------------------------------------
  static getAjaxParameters(data = {}) {
    let order = '';
    if (Array.isArray(data?.order)) {
      data.order.forEach((obj) => {
        if (order !== '') order = order + ', ';
        order = data.columns[obj.column].name + ' ' + obj.dir;
      });
    }

    let filters = {};
    if (!Utils.isNull(data?.length)) filters.pageSize = data.length;
    if (!Utils.isNull(data?.start) && !Utils.isNull(data?.length)) {
      filters.page = Math.floor(data.start / data.length) + 1;
    }
    // if( !Utils.isNull(data?.start) ) filters.firstRow = data.start + 1;
    if (order !== '') filters.orderBy = order;

    return filters;
  }
}
