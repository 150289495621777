/**
 *
 */

// External libs
import $ from 'jquery';
import React from 'react';
import moment from 'moment';
import 'eonasdan-bootstrap-datetimepicker-bootstrap4beta';

// Css
import 'eonasdan-bootstrap-datetimepicker-bootstrap4beta/build/css/bootstrap-datetimepicker.css';

// libs
import Utils from '../../utils/Utils';
//import Date from '../../utils/Date';
import Parameter from './Parameter';
try {
  moment.locale(window.navigator.language);
} catch (e) {}

export default class DateParameter extends Parameter {
  constructor(props = {}) {
    super(props);
    try {
      if (props.updateValueFromProps === true) this.state.value = Utils.define(props.value, '');
      else this.state.value = Utils.define(props.defaultValue, '');
      if (props.skipFormatInit) {
        this.state.value = props.defaultValue;
        return;
      }

      if (this.state.value !== '') {
        if (moment(this.state.value, this.getValidFormats(), true).isValid())
          this.state.value = moment(this.state.value, this.getValidFormats()).format(
            this.getFormat()
          );
        else throw new Error('Invalid value property (Invalid date format)');
      }
    } catch (e) {
      this.errorHandler(e);
      this.state.value = '';
    }
  }

  static defaultProps = {
    // properties
    defaultValue: '',
    value: '',
    updateValueFromProps: false,
    placeholder: '',
    skipFormatInit: false,
    typeOptions: {
      format: 'DD/MM/YYYY',
      extraFormats: ['D/MM/YYYY', 'DD/M/YYYY', 'D/M/YYYY'],
      showTodayButton: true,
      showClear: true,
      useCurrent: false,
      useStrict: true,
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-crosshairs',
        clear: 'fa fa-trash',
      },
    },
    // listeners
    onChange: null,
  };

  state = {
    value: '',
  };

  getFormat() {
    try {
      return Utils.define(
        this.props.typeOptions.format,
        DateParameter.defaultProps.typeOptions.format
      );
    } catch (e) {
      this.errorHandler(e);
      return 'DD/MM/YYYY';
    }
  }

  getValidFormats() {
    try {
      let formats = Array.from(
        Utils.defineArray(DateParameter.defaultProps.typeOptions.extraFormats, [])
      );
      try {
        formats = Utils.defineArray(this.props.typeOptions.extraFormats, formats);
      } catch (e) {}
      formats.push(this.getFormat());
      return formats;
    } catch (e) {
      this.errorHandler(e);
      return [];
    }
  }

  // -----------------------------------------------------------------
  // -------------------------- ReactJS Methods ----------------------
  // -----------------------------------------------------------------
  state = {
    value: '',
  };

  initState(props) {
    try {
      // Create default value if exists (supports codes)
      this.state.value = Utils.define(props.defaultValue, '');
      if (this.state.value === '') return;
      if (props.skipFormatInit) {
        this.state.value = props.defaultValue;
        return;
      }

      if (moment(this.state.value, this.getValidFormats(), true).isValid())
        this.state.value = moment(this.state.value).format(this.getFormat());
      else {
        let date = Date.codeToDate(this.state.value);
        if (date.isValid()) this.state.value = date.format(this.getFormat());
      }
    } catch (e) {
      this.errorHandler(e);
      this.state.value = '';
    }
  }

  // -----------------------------------------------------------------
  // -------------------------- ReactJS Methods ----------------------
  // -----------------------------------------------------------------
  componentDidMount() {
    super.componentDidMount();
    let thisObject = this;

    // Setup dateTimePicker object options
    let options = Object.assign(
      { ...DateParameter.defaultProps.typeOptions },
      this.props.typeOptions
    );
    //options.format = "DD/MM/YYYY";
    options.keyBinds = {
      enter: () => {
        this.DOMObject.querySelector('input').blur();
      },
    };
    $(this.DOMObject).datetimepicker(options);
    // Get placeholder
    try {
      this.DOMObject.querySelector('input').value = this.state.value;
      this.DOMObject.querySelector('input').placeholder = options.format;
    } catch (e) {
      this.errorHandler(e);
    }

    // Add error format event listener
    $(this.DOMObject).on('dp.error', function (e) {
      thisObject.clear(true);
    });
    // Add value change event listener
    $(this.DOMObject).on('dp.change', function (e) {
      thisObject.valueChanged();
    });
  }

  render() {
    let attrs = this.getAttributes(DateParameter.defaultProps);
    attrs.className = Utils.define(attrs.className, '') + ' input-group date DateParameter';

    if (this.props?.updateValueFromProps === true) this.setValue(this.props?.value);

    return (
      <div {...attrs}>
        <input className='form-control' type='text' />
        <span className='input-group-addon input-group-append'>
          <span className='input-group-text far fa-calendar-alt' />
        </span>
      </div>
    );
  }

  // -----------------------------------------------------------------
  // -------------------------- Class Methods ------------------------
  valueChanged() {
    try {
      let value = moment(this.getValue(), this.getValidFormats(), true);
      if (!value.isValid()) this.clear();
      else this.state.value = value.format(this.getFormat());
      super.valueChanged();
    } catch (e) {
      this.errorHandler(e);
    }
  }

  clear(createEvent) {
    try {
      if (!Utils.isNull(this.DOMObject)) this.DOMObject.querySelector('input').value = '';
      this.state.value = '';
      if (createEvent === true) this.valueChanged();
    } catch (e) {
      this.errorHandler(e);
    }
  }

  getValue() {
    try {
      return Utils.isNull(this.DOMObject)
        ? this.state.value
        : this.DOMObject.querySelector('input').value;
    } catch (e) {
      this.errorHandler(e);
      return null;
    }
  }

  setValue(value) {
    try {
      value = moment(value, this.getValidFormats(), true);
      if (!value.isValid()) {
        this.clear();
        return false;
      }
      value = value.format(this.getFormat());
      if (!Utils.isNull(this.DOMObject)) this.DOMObject.querySelector('input').value = value;
      this.state.value = value;
      return true;
    } catch (e) {
      this.errorHandler(e);
      return false;
    }
  }

  getStringValue() {
    return this.getValue();
  }
}
