import { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader } from 'reactstrap';
import MultiLevelSelectBreadcrumbs from './MultiLevelSelectBreadcrumbs';
import MultiLevelSelectOption from './MultiLevelSelectOption';

const MultiLevelSelect = ({
  fullWidth,
  optionsTree,
  root,
  onChange,
  title,
  onDragStart,
  draggable = false,
  fontSize = 14,
}) => {
  const [idChain, setIdChain] = useState([]);

  const pushToChain = (id) => {
    const newChain = [...idChain, id];
    if (hasOptionsForLevel(optionsTree, id)) {
      setIdChain(newChain);
    } else {
      onChange(newChain.map((id) => optionsTree[id]));
    }
  };
  const popFromChain = () =>
    setIdChain((prevChain) => [...prevChain.slice(0, prevChain.length - 1)]);

  const currentLevel = idChain[idChain.length - 1] ?? root;
  const options = getOptionsForLevel(optionsTree, currentLevel).sort(
    (tagDefinitionA, tagDefinitionB) => tagDefinitionA.order - tagDefinitionB.order
  );

  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    // Clicking on the modal fires this event
    // We need to check if modal is open in order not to close the options dropdown
    if (!!modalContent) {
      return;
    }
    if (open) {
      setIdChain([]);
    }
    setOpen((prevOpen) => !prevOpen);
  };
  const displayValue = idChain.map((id) => optionsTree[id].label);

  const [modalContent, setModalContent] = useState();
  const handleShowDescription = (id) => {
    setModalContent(optionsTree[id]);
  };
  const handleHideDescription = () => {
    setModalContent(null);
  };

  return (
    <>
      <Dropdown isOpen={open} toggle={handleToggle}>
        <DropdownToggle
          className={`${fullWidth ? 'w-100' : ''}`}
          style={{ fontSize }}
          draggable={draggable}
          onDragStart={onDragStart}
          tag='div'
        >
          {title}
        </DropdownToggle>
        {/* The key prop here exists in order to reset the menu posiiton on options change */}
        <DropdownMenu style={{ fontSize }} key={options.length}>
          {/* Don't render the menu until the dropdown is actually open */}
          {open && (
            <>
              <MultiLevelSelectBreadcrumbs breadcrumbs={displayValue} onBack={popFromChain} />
              {options.map(({ value, label, color }) => (
                <MultiLevelSelectOption
                  key={value}
                  value={value}
                  label={label}
                  color={color}
                  onClick={() => pushToChain(value)}
                  onShowDescription={handleShowDescription}
                />
              ))}
            </>
          )}
        </DropdownMenu>
      </Dropdown>
      <Modal isOpen={!!modalContent} toggle={handleHideDescription}>
        <ModalHeader style={{ color: modalContent?.color }} toggle={handleHideDescription}>
          {modalContent?.label}
        </ModalHeader>
        <ModalBody dangerouslySetInnerHTML={{ __html: modalContent?.description }}></ModalBody>
      </Modal>
    </>
  );
};

export default MultiLevelSelect;

const hasOptionsForLevel = (optionsTree, level) => !!getOptionsForLevel(optionsTree, level).length;

const getOptionsForLevel = (optionsTree, level) => {
  const optIds = optionsTree[level]?.children ?? [];
  return optIds.map((id) => optionsTree[id]);
};
