export const base64toBlob = (base64) => {
  // Split into two parts
  const parts = base64.split(';base64,');

  // Hold the content type
  const contentType = parts[0].split(':')[1];

  // Decode Base64 string
  const decodedData = window.atob(parts[1]);

  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);

  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  // Return BLOB after conversion
  return new Blob([uInt8Array], { type: contentType });
};

export const blobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });

export const float32Flatten = (chunks) => {
  //get the total number of frames on the new float32array
  const nFrames = chunks.reduce((acc, elem) => acc + elem.length, 0);

  //create a new float32 with the correct number of frames
  const result = new Float32Array(nFrames);

  //insert each chunk into the new float32array
  let currentFrame = 0;
  chunks.forEach((chunk) => {
    result.set(chunk, currentFrame);
    currentFrame += chunk.length;
  });
  return result;
};

export function encodeWAV(samples, sampleRate, format = 1, numChannels = 1, bitDepth = 16) {
  var bytesPerSample = bitDepth / 8;
  var blockAlign = numChannels * bytesPerSample;

  var buffer = new ArrayBuffer(44 + samples.length * bytesPerSample);
  var view = new DataView(buffer);

  /* RIFF identifier */
  writeString(view, 0, 'RIFF');
  /* RIFF chunk length */
  view.setUint32(4, 36 + samples.length * bytesPerSample, true);
  /* RIFF type */
  writeString(view, 8, 'WAVE');
  /* format chunk identifier */
  writeString(view, 12, 'fmt ');
  /* format chunk length */
  view.setUint32(16, 16, true);
  /* sample format (raw) */
  view.setUint16(20, format, true);
  /* channel count */
  view.setUint16(22, numChannels, true);
  /* sample rate */
  view.setUint32(24, sampleRate, true);
  /* byte rate (sample rate * block align) */
  view.setUint32(28, sampleRate * blockAlign, true);
  /* block align (channel count * bytes per sample) */
  view.setUint16(32, blockAlign, true);
  /* bits per sample */
  view.setUint16(34, bitDepth, true);
  /* data chunk identifier */
  writeString(view, 36, 'data');
  /* data chunk length */
  view.setUint32(40, samples.length * bytesPerSample, true);
  if (format === 1) {
    // Raw PCM
    floatTo16BitPCM(view, 44, samples);
  } else {
    writeFloat32(view, 44, samples);
  }

  return buffer;
}

function writeFloat32(output, offset, input) {
  for (var i = 0; i < input.length; i++, offset += 4) {
    output.setFloat32(offset, input[i], true);
  }
}

function floatTo16BitPCM(output, offset, input) {
  for (var i = 0; i < input.length; i++, offset += 2) {
    var s = Math.max(-1, Math.min(1, input[i]));
    output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
}

function writeString(view, offset, string) {
  for (var i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}

export const extractMinutes = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  return minutes < 10 ? `0${minutes}` : minutes;
};

export const extractSeconds = (totalSeconds) => {
  const seconds = (totalSeconds % 60);
  return seconds < 10 ? `0${seconds}` : seconds;
};

export const calculateAudioLength = (audio, sampleRate = 16000) => {
  return (audio.size - 44) / (2 * sampleRate);
};
