import React from 'react';
import AudioWaveform from '../AudioWaveform';

export default function RecordingDisplay({ audio, isSaved, segmentId=null, isAdmin, onAudioChange, sampleRate }) {
  return (
    <div>
      {audio ? (
        <div>
          { isAdmin ?  <AudioWaveform audio={audio} segmentId={segmentId} onAudioChange={onAudioChange} sampleRate={sampleRate}/> :
          <audio
            className={`tw-w-full ${
              isSaved ? 'tw-border-2 tw-border-solid tw-border-green-400 tw-rounded-full' : null
            }`}
            controls
            src={audio}
            controlsList='nodownload'
          />
          }
        </div>
      ) : (
        <div className='text-center pt-4'>No recording saved.</div>
      )}
    </div>
  );
}
