import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PAGES } from '../../index';

const TaskSubmitSuccessModal = ({ projectId, scope, isOpen, isRollback, onClose }) => {
  return (
    <Modal show={isOpen} backdrop='static' keyboard={false}>
      {isRollback ? (
        <>
          <Modal.Header className='tw-relative tw-bg-gray-800'>
            <Modal.Title className='tw-w-full tw-text-center tw-text-white'>
              Task Rolledback
            </Modal.Title>
            <button
              className='close tw-text-white tw-absolute tw-right-0 tw-mr-0'
              onClick={onClose}
            >
              &times;
            </button>
          </Modal.Header>
          <Modal.Body>
            <span className='tw-block tw-text-base tw-mb-4'>
              The task is rolled back. You will be redirected back to the{' '}
              <Link className='tw-text-blue-500' to={PAGES.WORKER_TASKS_PAGE.path()}>
                Workers Tasks page
              </Link>
            </span>
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Header className='tw-relative tw-bg-gray-800'>
            <Modal.Title className='tw-w-full tw-text-center tw-text-white'>
              Task Submitted
            </Modal.Title>
            <button
              className='close tw-text-white tw-absolute tw-right-0 tw-mr-0'
              onClick={onClose}
            >
              &times;
            </button>
          </Modal.Header>
          <Modal.Body>
            <span className='tw-block tw-text-center tw-text-lg tw-font-semibold tw-mb-6 tw-mt-6'>
              Thank you for submitting the task!
            </span>
            <span className='tw-block tw-text-base tw-mb-4'>
              Soon it will be reviewed. You can check your task status in the <br />
              <Link className='tw-text-blue-500' to={PAGES.USER_TASKS_PAGE.path()}>
                My Tasks
              </Link>{' '}
              page
            </span>
            {scope === 'CROWDSOURCING' && (
              <div>
                <span className='tw-block tw-text-base tw-mb-4'>
                  If <u>passed</u>, it will be eligible for payment. Payment is normally processed
                  on the following Monday, unless otherwise agreed upon project start.
                </span>
                <span className='tw-block tw-text-base tw-mb-10'>
                  In the{' '}
                  <Link className='tw-text-blue-500' to={PAGES.PUBLIC_PROJECTS_LISTING_PAGE.path()}>
                    Projects
                  </Link>{' '}
                  page you can see if there are more tasks for you to claim.
                </span>
              </div>
            )}
            {scope === 'WORKER_VALIDATION' && (
              <span className='tw-block tw-text-base tw-mb-10'>
                If passed, you'll qualify to be part of the project. <br />
                Please check the relevant{' '}
                <Link className='tw-text-blue-500' to={PAGES.PUBLIC_PROJECTS_LISTING_PAGE.path()}>
                  Project
                </Link>{' '}
                page to see if there are tasks for you to claim. For any question you can turn to
                hlp@taus.net.
              </span>
            )}
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

export default TaskSubmitSuccessModal;
